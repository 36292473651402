import React, { useState, useEffect } from 'react';
import { getRowClassName } from 'src/app/common/utils/methods';
import * as CONST from '../constants';
import CarouselMoreOptionBox from 'src/app/components/atoms/CarouselMoreOptionBox';
import Kebab from 'src/app/assets/logos/Kebab.svg';
import ClickAwayListener from 'react-click-away-listener';
import Vertmore from 'src/app/assets/logos/VertMore.svg';
import FilePickerIcon from 'src/app/assets/images/FilePickerIcon.svg';

export const TagRow = ({
	data,
	id,
	toggleEditModal,
	toggleBucketDescPopup,
	deleteAll,
	setDeleteAll,
	setUncheckedOnSelectOne,
	selectedCards,
	length,
	onSelecting,
	is_curated,
}) => {
	const [showOptionDropdown, setShowOptionDropdown] = useState(false);
	const [selectionValue, setSelectionValue] = useState('');

	const sectionOneEl = document.querySelector('#scrollableDivTags');
	sectionOneEl?.addEventListener('scroll', (ev) => {
		if (showOptionDropdown) {
			setShowOptionDropdown(false);
		}
	});

	useEffect(() => {
		if (selectedCards?.length > 0) {
			let index = selectedCards.findIndex((item) => item.tag_id === id);
			if (index > -1) {
				setSelectionValue(true);
			} else {
				setSelectionValue(false);
			}
		}
	}, [selectedCards, selectionValue]);

	useEffect(() => {
		setSelectionValue(deleteAll);
	}, [deleteAll]);

	const onSelectionChange = (e) => {
		if (deleteAll && !e.target.checked) {
			setUncheckedOnSelectOne(false);
			setDeleteAll(false);
		}
		if (deleteAll && e.target.checked) {
			if (selectedCards?.length + 1 === length) {
				setUncheckedOnSelectOne(true);
			}
		}
		setSelectionValue(e.target.checked);
		onSelecting({ tag_id: id, is_curated: e.target.checked });
	};

	const handleSelect = (option) => {
		if (option?.value === 'edit') {
			toggleEditModal(data);
		}
		setShowOptionDropdown(false);
	};

	const addDefaultSrc = (ev) => {
		ev.target.className = 'tag-icon-error';
		ev.target.src = FilePickerIcon;
	};

	return (
		<tr className={`${getRowClassName(data?.index)} `}>
			<td>
				<span className="checkbox">
					<input
						id={id}
						type="checkbox"
						className="name-checkbox-row"
						value={selectionValue}
						onChange={onSelectionChange}
						alt="selection"
						checked={selectionValue}
					/>

					<div className="tag-name-container">
						<div className="tag-image-container">
							<img
								src={data.thumbnail || FilePickerIcon}
								alt={'Tag Image'}
								onError={addDefaultSrc}
								className={`${data.thumbnail ? '' : 'tag-icon-error'}`}
							/>
						</div>
						<p className="tag-name">{data?.tag_name}</p>
					</div>
				</span>
			</td>
			<td className="bucket-count-row">
				<div className="bucket-count">{data?.bucket_count}</div>
			</td>
			<td className="bucket-name">
				<div className="bucket-name-container">
					<div
						className="bucket-name-wrapper"
						onClick={() => toggleBucketDescPopup(data)}>
						{data?.bucket_count === 1 && (
							<div className="bucket-names">{`${data?.buckets[0]}`}</div>
						)}
						{data?.bucket_count >= 2 && (
							<div className="bucket-names">{`${data?.buckets[0]}, ${data?.buckets[1]}`}</div>
						)}
					</div>
					{data?.bucket_count - 2 > 0 && (
						<div className="remain-count" onClick={() => toggleBucketDescPopup(data)}>
							<span className="remain-count-span">{`+${data?.bucket_count - 2}`}</span>
						</div>
					)}
				</div>
			</td>
			<td className="row-options">
				<ClickAwayListener onClickAway={() => setShowOptionDropdown(false)}>
					<div>
						<div className={`options ${showOptionDropdown ? 'padding-more' : ''}`}>
							{!showOptionDropdown ? (
								<img
									src={Vertmore}
									alt="dots"
									className="dots"
									onClick={() => setShowOptionDropdown(!showOptionDropdown)}
								/>
							) : (
								<img
									src={Kebab}
									className="selected-dots"
									alt="dots"
									onClick={() => setShowOptionDropdown(!showOptionDropdown)}
								/>
							)}
						</div>
						{showOptionDropdown && (
							<div className="kebab-dropdown">
								<CarouselMoreOptionBox
									from="carousel"
									options={CONST.EDIT_OPTION}
									handleSelect={handleSelect}
								/>
							</div>
						)}
					</div>
				</ClickAwayListener>
			</td>
		</tr>
	);
};

export default TagRow;
