import React, { useState, useEffect, forwardRef } from 'react';
import Grid from 'src/app/components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	GET_LIVE_CATEGORY_INITIAL,
	GET_LIVE_CATEGORY_DATA,
} from 'src/app/pages/Dashboard/actionTypes';
import {
	getCommonMethod,
	postCommonMethod,
	putCommonMethod,
} from 'src/app/pages/Dashboard/Service';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import CategoryGrid from './CategoryGrid';
import * as CONST from 'src/app/pages/LiveCategory/CategoryConstants';
import './styles.scss';
import { Loader } from 'src/app/components/Loader';
import Popup from 'reactjs-popup';
import { createCategoryPopupStyle } from 'src/app/common/constants/PopupStyles';
import CreateLiveChannelPopup from 'src/app/components/CreateLiveChannelPopup';
import { isEmptyString } from 'src/app/common/utils/methods';
import Skeleton from '@mui/material/Skeleton';
import { ReactSortable } from 'react-sortablejs';

const CustomComponent = forwardRef((props, ref) => {
	return (
		<Grid fromCategoryScreen={true} innerRef={ref}>
			{props.children}
		</Grid>
	);
});

export const GridContainer = ({
	showAddCategoryPopup,
	handleCreate,
	iconKey,
	setIconKey,
	categoryName,
	setCategoryName,
	buttonLoader,
	setButtonLoader,
	setToastInfo,
	enableSelection,
	onSelecting,
	isEditPopup,
	setIsEditPopup,
	prevCategoryName,
	setPrevCategoryName,
}) => {
	const [offset, setOffset] = useState(0);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const dispatch = useDispatch();
	const categoryData = useSelector((state) => state.liveChannel?.liveCategories);
	const [categoryDisplayData, setCategoryDisplayData] = useState([]);
	const [disableButton, setDisableButton] = useState(false);
	const [loaderOnUpdate, setLoaderOnUpdate] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const [liveCategoryId, setLiveCategoryId] = useState(null);
	const [sequence, setSequence] = useState(null);

	useEffect(() => {
		if (categoryData) {
			setLoading(false);
			setCategoryDisplayData([...categoryData]);
		}
	}, [categoryData, setLoading]);

	useEffect(() => {
		if (!isEditPopup && iconKey && categoryName !== '') {
			setTimeout(() => setDisableButton(true));
		} else {
			setDisableButton(false);
		}
	}, [iconKey, categoryName, isEditPopup]);

	useEffect(() => {
		if (
			isEditPopup &&
			isEmptyString(categoryName) &&
			isEmptyString(iconKey) &&
			(prevCategoryName !== categoryName || iconKey !== thumbnail)
		) {
			setDisableButton(true);
		} else {
			setDisableButton(false);
		}
	}, [isEditPopup, iconKey, categoryName, prevCategoryName, thumbnail]);

	const fetchData = async () => {
		if (offset > 0) {
			const response = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CATEGORY}`,
				{
					offset: offset,
					perPage: CONST.RECORD_TO_FETCH,
					orderBy: GLOBAL_CONST.ORDER_BY.sequence,
					orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
				},
			);
			await handleHasMore(response);
		} else {
			setLoading(true);
			const response = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CATEGORY}`,
				{
					offset: 0,
					perPage: CONST.RECORD_TO_FETCH,
					orderBy: GLOBAL_CONST.ORDER_BY.sequence,
					orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
				},
			);
			await handleHasMore(response);
			setLoaderOnUpdate(false);
		}
	};

	const callDispatch = (response) => {
		if (offset > 0) {
			dispatch({ type: GET_LIVE_CATEGORY_DATA, payload: response.data });
		} else {
			dispatch({ type: GET_LIVE_CATEGORY_INITIAL, payload: response.data });
		}
	};

	const handleHasMore = async (response) => {
		if (response && response.data && response.data.length > 0) {
			if (response.data.length === CONST.RECORD_TO_FETCH) {
				setHasMore(true);
			} else {
				setHasMore(false);
			}
			callDispatch(response);
		} else {
			setHasMore(false);
		}
	};

	const fetchMoreCategory = () => {
		if (hasMore) {
			setOffset(categoryDisplayData.length);
		}
	};

	const addCategory = async () => {
		setButtonLoader(true);
		try {
			const methodToBeCalled = isEditPopup ? putCommonMethod : postCommonMethod;
			const url = isEditPopup
				? `${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.EDIT_LIVE_CATEGORY}`
				: `${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.CREATE_LIVE_CATEGORY}`;
			const params = {
				name: categoryName,
				thumbnail: iconKey,
			};
			if (isEditPopup) {
				params.live_category_id = parseInt(liveCategoryId);
				params.sequence = sequence;
			}
			const res = await methodToBeCalled(url, params);
			if (res && res.data && res.data.message) {
				setLoaderOnUpdate(true);
				offset > 0 ? setOffset(0) : setOffset((val) => val - 1);
				setToastInfo(true, false, res.data.message);
			} else if (res && res.status && res.message) {
				setLoaderOnUpdate(true);
				offset > 0 ? setOffset(0) : setOffset((val) => val - 1);
				setToastInfo(true, false, res.message);
			}
		} catch (error) {
			setToastInfo(true, true, error.response.data.message || error.message);
		}
		handleCreate(false);
		setIconKey(null);
		setCategoryName('');
	};

	const openEditLiveCategory = (data) => {
		handleCreate(true);
		setIsEditPopup(true);
		setCategoryName(data?.name);
		setPrevCategoryName(data?.name);
		setIconKey(data?.thumbnail);
		setThumbnail(data?.thumbnail);
		setLiveCategoryId(data?.live_category_id);
		setSequence(data?.sequence);
	};

	useEffect(() => {
		fetchData();
	}, [offset, setLoading]);

	const dragEndHandler = async (e) => {
		try {
			let newSequence;
			if (e.newIndex === 0) {
				newSequence = categoryDisplayData[e.newIndex + 1]['sequence'] + 1;
			} else if (e.newIndex === categoryDisplayData.length - 1) {
				newSequence = categoryDisplayData[e.newIndex - 1]['sequence'] - 1;
			} else {
				newSequence =
					(categoryDisplayData[e.newIndex - 1]['sequence'] +
						categoryDisplayData[e.newIndex + 1]['sequence']) /
					2;
			}
			const params = {
				name: categoryDisplayData[e.newIndex]['name'],
				thumbnail: categoryDisplayData[e.newIndex]['thumbnail'],
				live_category_id: categoryDisplayData[e.newIndex]['live_category_id'],
				sequence: newSequence,
			};
			const res = await putCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.EDIT_LIVE_CATEGORY}`,
				params,
			);
			if (res.status) {
				categoryDisplayData[e.newIndex]['sequence'] = newSequence;
				dispatch({ type: GET_LIVE_CATEGORY_INITIAL, payload: categoryDisplayData });
			}
		} catch (e) {
			setToastInfo(true, true, GLOBAL_CONST.ERROR_MESSAGE);
		}
	};

	return (
		<div>
			<div id="scrollableDivLiveCategory">
				<InfiniteScroll
					dataLength={categoryData.length}
					next={fetchMoreCategory}
					hasMore={hasMore}
					loader={!loaderOnUpdate && <Loader loadMore={true} />}
					scrollableTarget="scrollableDivLiveCategory">
					{loading ? (
						<Grid>
							{[...Array(20)].map((item, index) => {
								return (
									<Skeleton
										key={index}
										variant="rectangular"
										width={'100%'}
										style={{ borderRadius: 6 }}
										height={162}
										sx={{
											bgcolor: 'rgba(63, 65, 69, 0.4)',
											animation: 'animation-c7515d 3.0s ease-in-out infinite;',
										}}
									/>
								);
							})}
						</Grid>
					) : (
						<ReactSortable
							tag={CustomComponent}
							list={categoryDisplayData}
							setList={setCategoryDisplayData}
							onEnd={dragEndHandler}>
							{categoryDisplayData &&
								categoryDisplayData.length > 0 &&
								categoryDisplayData.map((item) => {
									return (
										<CategoryGrid
											key={item?.live_category_id}
											data={item}
											enableSelection={enableSelection}
											onSelecting={onSelecting}
											openEditLiveCategory={openEditLiveCategory}
										/>
									);
								})}
						</ReactSortable>
					)}
				</InfiniteScroll>
			</div>
			<Popup
				open={showAddCategoryPopup}
				closeOnDocumentClick
				onClose={() => handleCreate(false)}
				contentStyle={createCategoryPopupStyle}>
				<CreateLiveChannelPopup
					message={
						isEditPopup ? CONST.CREATE_POPUP.EDIT_HEADING : CONST.CREATE_POPUP.HEADING
					}
					inputHeading={CONST.CREATE_POPUP.INPUT_LABEL}
					inputPlaceholder={CONST.CREATE_POPUP.INPUT_PLACEHOLDER}
					thumbnailHeading={CONST.CREATE_POPUP.THUMBNAIL_HEADING}
					thumbnailText={CONST.CREATE_POPUP.THUMBNAIL_TEXT}
					fromCategoryScreen
					setIconKey={setIconKey}
					iconKey={iconKey}
					bucketName={categoryName}
					setBucketName={setCategoryName}
					isDisable={disableButton}
					onAdd={addCategory}
					onCancel={() => handleCreate(false)}
					loading={buttonLoader}
					isEdit={isEditPopup}
					thumbnail={thumbnail}
					buttonText={isEditPopup ? CONST.CREATE_POPUP.UPDATE : CONST.CREATE_POPUP.ADD}
				/>
			</Popup>
		</div>
	);
};

export default GridContainer;
