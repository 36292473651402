export const BUCKET_TABLE_COLUMNS_CONFIG = [
	{ key: 'name_key', title: 'Name', className: 'name', spanClassName: 'name-span' },
	{
		key: 'dateRange_key',
		title: 'Date Range',
		className: 'dateRange',
		spanClassName: 'date-span',
	},
	{
		key: 'desc_key',
		title: 'Description',
		className: 'description',
		spanClassName: 'desc-span',
	},
	{
		key: 'videoCount_key',
		title: 'Videos',
		className: 'videoCount',
		spanClassName: 'video-span',
	},
	{
		key: 'screens_key',
		title: 'Screens',
		className: 'screens',
		spanClassName: 'screen-span',
	},
	{
		key: 'actions',
		title: 'Actions',
		className: 'actions-buck',
		spanClassName: 'actions-span',
	},
];

export const BUCKET_TABLE_COLUMNS_CONFIG_LIVE_SCREEN = [
	{ key: 'name_key', title: 'Name', className: 'name', spanClassName: 'name-span' },
	{
		key: 'dateRange_key',
		title: 'Date Range',
		className: 'dateRange',
		spanClassName: 'date-span',
	},
	{
		key: 'desc_key',
		title: 'Description',
		className: 'description',
		spanClassName: 'desc-span',
	},
	{
		key: 'videoCount_key',
		title: 'Live Channels',
		className: 'liveCount',
		spanClassName: 'video-span',
	},
	{
		key: 'actions',
		title: 'Actions',
		className: 'actions-buck',
		spanClassName: 'actions-span',
	},
];

export const BUCKET_TABLE_COLUMNS_CONFIG_ARCHIVED = [
	{ key: 'name_key', title: 'Name', className: 'name', spanClassName: 'name-span' },
	{
		key: 'dateRange_key',
		title: 'Date Range',
		className: 'dateRange-archived',
		spanClassName: 'date-span',
	},
	{
		key: 'desc_key',
		title: 'Description',
		className: 'description-archived',
		spanClassName: 'desc-span',
	},
	{
		key: 'videoCount_key',
		title: 'Videos',
		className: 'videoCount',
		spanClassName: 'video-span',
	},
	{
		key: 'actions',
		title: 'Actions',
		className: 'actions-buck',
		spanClassName: 'actions-span',
	},
];
