import React, { useState, useEffect } from 'react';
import './styles.scss';
import * as CONST from '../Constant';
import Popup from 'reactjs-popup';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'src/app/components/Loader';
import Table from 'src/app/components/Table';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import { videoPopupStyle } from 'src/app/common/constants/PopupStyles';
import VideoPlayer from 'src/app/components/VideoPlayer/VideoPlayerIndex';
import { putCommonMethod } from 'src/app/pages/Dashboard/Service';
import Row from './Row';

const TableContainer = ({
	tableData,
	setTableData,
	shuffle,
	hasMore,
	fetchMoreData,
	handleAddButton,
	handleToast,
	deleteAll,
	selectedCards,
	setDeleteAll,
	onSelecting,
	selectToDeleteAll,
	setUncheckedOnSelectOne,
	unCheckedOnSelectOne,
	handleChangeOnEdit,
	handleDeleteChannelFromBucket,
	loading,
	setLoading,
	showSubCategoryData,
	addSingleChannelToBucket,
	bucketId,
	contentType,
	handleSubCategory,
}) => {
	const [tableDisplayData, setTableDisplayData] = useState([]);
	// const [loading, setLoading] = useState(true);
	const [videoPopup, setVideoPopup] = useState(false);
	const [videoUrl, setVideoUrl] = useState(null);
	const [tableConfig, setTableConfig] = useState(
		CONST.BUCKET_DETAILS_TABLE_COLUMNS_CONFIG,
	);

	useEffect(() => {
		if (contentType === CONST.CONTENT_TYPE.live_channel) {
			setTableConfig(CONST.BUCKET_DETAILS_TABLE_COLUMNS_CONFIG_FOR_CATEGORY);
		} else {
			setTableConfig(CONST.BUCKET_DETAILS_TABLE_COLUMNS_CONFIG);
		}
	}, [contentType]);

	useEffect(() => {
		if (contentType === CONST.CONTENT_TYPE.live_channel) {
			if (showSubCategoryData) {
				setTableConfig(CONST.BUCKET_DETAILS_TABLE_COLUMNS_CONFIG_FOR_CATEGORY_CHANNELS);
			} else {
				setTableConfig(CONST.BUCKET_DETAILS_TABLE_COLUMNS_CONFIG_FOR_CATEGORY);
			}
		} else {
			setTableConfig(CONST.BUCKET_DETAILS_TABLE_COLUMNS_CONFIG);
		}
	}, [contentType, showSubCategoryData]);

	useEffect(() => {
		if (tableData) {
			setTableDisplayData([...tableData]);
			if (tableData?.length > 0) {
				setLoading(false);
			}
		}
	}, [tableData, setLoading]);

	const handleVideoPopup = (url) => {
		if (url && url?.length > 0) {
			setVideoPopup(true);
			setVideoUrl(url);
		}
	};

	const closeVideoPopup = () => {
		setVideoPopup(false);
		setVideoUrl(null);
	};

	const dragEndHandler = async (result) => {
		if (!result.destination) return;

		const items = Array.from(tableDisplayData);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setTableDisplayData([...items]);

		const draggedItem = tableDisplayData[result.source?.index];
		let videoRank;
		if (result.destination?.index === 0) {
			videoRank =
				items[result.destination?.index + 1]['content_sequence'] > 1
					? items[result.destination?.index + 1]['content_sequence'] - 1 || 0.5
					: items[result.destination?.index + 1]['content_sequence'] / 1.01;
		} else if (result.destination?.index === tableDisplayData.length - 1) {
			videoRank = items[result.destination?.index - 1]['content_sequence'] + 1 || 2;
		} else {
			videoRank =
				(items[result.destination?.index - 1]['content_sequence'] +
					items[result.destination?.index + 1]['content_sequence']) /
					2 || 1;
		}

		try {
			const resp = await putCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.LIVE_BUCKET_REORDER}`,
				{
					content_id: draggedItem?.content_id,
					bucket_id: bucketId,
					content_sequence: videoRank,
				},
			);
			const arr = [...items];
			arr[result.destination?.index]['content_sequence'] = videoRank;
			setTableData(arr);
		} catch (e) {
			handleToast(true, GLOBAL_CONST.ERROR_MESSAGE, true);
		}
	};

	const renderTable = () => {
		if (loading) {
			return <EmptyState columns={tableConfig} renderColumns={2} />;
		} else {
			return tableDisplayData.map((item, index) => {
				return (
					<Row
						key={item && `${item.content_id}`}
						data={{ ...item, index }}
						id={item?.content_id}
						shuffleMode={shuffle}
						handleAddButton={handleAddButton}
						deleteAll={deleteAll}
						setUncheckedOnSelectOne={setUncheckedOnSelectOne}
						selectedCards={selectedCards}
						length={tableDisplayData?.length}
						onSelecting={onSelecting}
						setDeleteAll={setDeleteAll}
						handleChangeOnEdit={() => handleChangeOnEdit(item)}
						handleVideoPopup={() => handleVideoPopup(item?.url)}
						showSubCategoryData={showSubCategoryData}
						addSingleChannelToBucket={addSingleChannelToBucket}
						contentType={contentType}
						handleSubCategory={handleSubCategory}
						handleDeleteChannelFromBucket={() =>
							handleDeleteChannelFromBucket(item?.content_id)
						}
					/>
				);
			});
		}
	};

	return (
		<>
			{shuffle ? (
				<DragDropContext onDragEnd={dragEndHandler}>
					<Table isRenderable={true} shuffleMode={shuffle} changeBG={true}>
						<Droppable droppableId="table">
							{(provided, snapshot) => (
								<div
									id="scrollableDivLiveBucketList"
									className="table"
									ref={provided.innerRef}
									{...provided.droppableProps}>
									<InfiniteScroll
										dataLength={tableData?.length}
										next={fetchMoreData}
										hasMore={hasMore}
										scrollThreshold={0.2}
										loader={<Loader loadMore={true} />}
										scrollableTarget="scrollableDivLiveBucketList">
										<table className="table-bucket-live-details" id="">
											<thead>
												<tr>
													{tableConfig.map((column) => {
														return (
															<Column
																key={column.key}
																config={column}
																shuffleMode={shuffle}
																isWaitlist={false}
																fromBuck={true}
																selectToDeleteAll={selectToDeleteAll}
																deleteAll={deleteAll}
																unCheckedOnSelectOne={unCheckedOnSelectOne}
															/>
														);
													})}
												</tr>
											</thead>

											<tbody>
												{tableDisplayData.map((item, index) => {
													return (
														<Draggable
															draggableId={item.content_id.toString()}
															index={index}
															key={item.content_id}>
															{(provided, snapshot) => (
																<Row
																	key={item && `${item.content_id}`}
																	data={{ ...item, index }}
																	id={item?.content_id}
																	shuffleMode={shuffle}
																	innerref={provided.innerRef}
																	styleset={provided.draggableProps.style}
																	deleteAll={deleteAll}
																	snap={snapshot}
																	handleAddButton={handleAddButton}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	setUncheckedOnSelectOne={setUncheckedOnSelectOne}
																	selectedCards={selectedCards}
																	length={tableDisplayData?.length}
																	onSelecting={onSelecting}
																	setDeleteAll={setDeleteAll}
																	showSubCategoryData={showSubCategoryData}
																	addSingleChannelToBucket={addSingleChannelToBucket}
																	contentType={contentType}
																	handleSubCategory={handleSubCategory}
																/>
															)}
														</Draggable>
													);
												})}
											</tbody>
										</table>
									</InfiniteScroll>
								</div>
							)}
						</Droppable>
					</Table>
				</DragDropContext>
			) : (
				<Table isRenderable={true} shuffleMode={shuffle} changeBG={true}>
					<div id="scrollableDivLiveBucketList">
						<InfiniteScroll
							dataLength={tableData?.length || 20}
							next={fetchMoreData}
							hasMore={hasMore}
							scrollThreshold={0.5}
							loader={<Loader loadMore={true} />}
							scrollableTarget="scrollableDivLiveBucketList">
							<table className="table-bucket-live-details">
								<thead>
									<tr>
										{tableConfig.map((column) => {
											return (
												<Column
													key={column.key}
													config={column}
													shuffleMode={shuffle}
													isWaitlist={false}
													fromBuck={true}
													selectToDeleteAll={selectToDeleteAll}
													deleteAll={deleteAll}
													unCheckedOnSelectOne={unCheckedOnSelectOne}
												/>
											);
										})}
									</tr>
								</thead>
								<tbody>{renderTable()}</tbody>
							</table>
						</InfiniteScroll>
					</div>
				</Table>
			)}
			<Popup
				className="popup-content-container-digest"
				open={videoPopup}
				closeOnDocumentClick
				onClose={closeVideoPopup}
				contentStyle={videoPopupStyle}>
				<VideoPlayer url={videoUrl} closePopup={closeVideoPopup} />
			</Popup>
		</>
	);
};

export default TableContainer;
