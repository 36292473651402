import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { Home } from '../Home';
import { HomeLogin } from '../HomeLogin';
import { Dashboard } from '../Dashboard/Components/Dummy';
import { Bucket } from '../Dashboard/Components/Bucket';
import { Union } from '../Dashboard/Components/Union';
import { WaitlistUsers } from '../Dashboard/Components/WaitlistUsers';
import { NotFound } from '../NotFound';
import { ProtectedRoute } from './ProtectedRoute';
import ActiveRouteProvider from '../../contexts/ActiveRouteProvider';
import Section from '../Dashboard/Components/Section';
import BucketRedirectSection from '../Dashboard/Components/BucketRedirectSection';
import { DashboardWrapper } from '../Dashboard/Components/DashboardWrapper';
import PublishedBucket from '../PublishedBucket';
import { Redirect } from 'react-router-dom';
import { ContentManagement } from '../ContentManagement';
import { QwedDigest } from 'src/app/pages/QwedDigest';
import { QewdDigestPrepopulate } from '../QwedDigest/AutoPopulate/AutoPopulate';
import { isValidSession } from 'src/app/common/utils/sessionUtils';
import { PreviewScreen } from '../QwedDigest/PreviewScreen';
import LiveChannels from 'src/app/pages/LiveChannel';
import { LiveStreams } from 'src/app/pages/LiveStream';
import LiveCategory from 'src/app/pages/LiveCategory';
import { LiveStreamsInChannel } from 'src/app/pages/LiveStreamsInChannel';
import LiveSubCategory from 'src/app/pages/LiveSubCategories';
import AllUsers from 'src/app/pages/AllUsers';
import ReportedVideos from 'src/app/pages/ReportedVideos';
import CastCrew from 'src/app/pages/CastCrew';
import SettingsScreen from 'src/app/pages/SettingsScreen';
import ListForYou from 'src/app/pages/ListForYou';
import YoutubeVideos from 'src/app/pages/YoutubeVideos';
import VideoDetailScreen from 'src/app/components/VideoDetailScreen';
import LiveBucketDetail from 'src/app/pages/LiveBucketDetail';

export const routes = {
	home: '/',
	dashboard: '/dashboard',
	movies: '/movies',
	bucket: '/bucket',
	shows: '/shows',
	published: '/published',
	digest: '/watchlist',
	digestManual: '/watchlist/manual',
	preview: '/watchlist/manual/preview',
	contentManagement: '/contentManagement',
	// waitlist: '/waitlist',
	liveChannel: '/live/subCategory',
	liveStreams: '/live/Channels',
	liveCategory: '/live',
	allUsers: '/all-users',
	reportedVideos: '/reported-videos',
	castCrew: '/cast-crew',
	settings: '/settings',
	listForYou: '/list-for-you',
	youtubeVideos: '/youtube-videos',
	detail: '/detail',
	liveBucketDetail: '/live-bucket',
};

export const AppRoutes = () => {
	return (
		<ActiveRouteProvider>
			<Router>
				<Switch>
					{/* By Default: This is a public Route */}
					<Route exact path={routes.home}>
						<HomeLogin />
					</Route>

					<PrivateRoute exact path={routes.dashboard} component={Dashboard} />

					<PrivateRoute
						path={`${routes.dashboard}/:bucket`}
						component={BucketRedirectSection}
					/>

					<PrivateRoute
						exact
						path={routes.movies}
						component={Section}
						redirectTo={true}
					/>

					<PrivateRoute path={`${routes.movies}/:section`} component={Section} />

					<PrivateRoute exact path={routes.bucket} component={Bucket} />
					<PrivateRoute
						path={`${routes.bucket}/:bucket`}
						component={BucketRedirectSection}
					/>

					<PrivateRoute
						exact
						path={routes.shows}
						component={Section}
						redirectToShows={true}
					/>
					<PrivateRoute path={`${routes.shows}/:section`} component={Section} />

					<PrivateRoute exact path={routes.published} component={PublishedBucket} />
					<PrivateRoute
						path={`${routes.published}/:bucket`}
						component={BucketRedirectSection}
					/>

					<PrivateRoute exact path={routes.digest} component={QewdDigestPrepopulate} />

					<PrivateRoute exact path={routes.digestManual} component={QwedDigest} />

					<PrivateRoute path={routes.preview} component={PreviewScreen} />

					<PrivateRoute path={routes.contentManagement} component={ContentManagement} />

					{/* <PrivateRoute path={routes.waitlist} component={WaitlistUsers} /> */}

					<PrivateRoute exact path={routes.liveStreams} component={LiveStreams} />

					<PrivateRoute exact path={routes.liveChannel} component={LiveChannels} />

					<PrivateRoute
						path={`${routes.liveChannel}/:channelId`}
						component={LiveStreamsInChannel}
					/>

					<PrivateRoute
						path={`${routes.liveCategory}/:channelId/:categoryId`}
						component={LiveStreamsInChannel}
					/>

					<PrivateRoute exact path={routes.liveCategory} component={LiveCategory} />

					<PrivateRoute
						exact
						path={`${routes.liveCategory}/:categoryId`}
						component={LiveSubCategory}
					/>
					<PrivateRoute exact path={routes.allUsers} component={AllUsers} />
					<PrivateRoute exact path={routes.reportedVideos} component={ReportedVideos} />
					<PrivateRoute exact path={routes.castCrew} component={CastCrew} />
					<PrivateRoute exact path={routes.settings} component={SettingsScreen} />
					<PrivateRoute exact path={routes.listForYou} component={ListForYou} />
					<PrivateRoute exact path={routes.youtubeVideos} component={YoutubeVideos} />
					<PrivateRoute exact path={`${routes.detail}`} component={VideoDetailScreen} />
					<PrivateRoute
						path={`${routes.liveBucketDetail}/:bucket`}
						component={LiveBucketDetail}
					/>

					{/* default route: if the requested route doesn't match with any of the above routes then this page will be displayed */}
					<Route component={NotFound} />
				</Switch>
			</Router>
		</ActiveRouteProvider>
	);
};
