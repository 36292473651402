import React, { useState, useEffect } from 'react';
import './styles.scss';
import * as CONST from './constants';
import CustomInputPopup from 'src/app/components/atoms/CustomInputPopup';
import CustomSelectPopup from 'src/app/components/atoms/CustomSelectPopup';
import CustomFilePicker from 'src/app/components/atoms/CustomFilePicker';
import { ButtonLoader } from 'src/app/components/ButtonLoader';
import { Loader } from 'src/app/components/Loader';
import { Line } from 'rc-progress';

export const CreateLiveChannelPopup = ({
	message,
	buttonText,
	onCancel,
	onAdd,
	loading,
	setCategoryOffset,
	categoryLabelsDemo,
	hasMoreCategory,
	category,
	isDisable,
	bucketName,
	setBucketName,
	setSelectCategoryToAddChannel,
	setIconKey,
	fromCategoryScreen = false,
	fromSubCategoryScreen = false,
	inputHeading,
	inputPlaceholder,
	thumbnailHeading,
	thumbnailText,
	iconKey = null,
	isEdit,
	thumbnail,
}) => {
	const [errorState, setErrorState] = useState([]);
	const [progress, setProgress] = useState(0);
	const [finalProgress, setFinalProgress] = useState(0);
	const [uploadingError, setUploadingError] = useState(false);
	const [uploadErrorMessage, setUploadErrorMessage] = useState(
		CONST.CREATE_LIVE_CHANNEL_POPUP_CUSTOM_POPUP.UPLOAD_DEFAULT_ERROR_MESSAGE,
	);
	const [disableButton, setDisableButton] = useState(!isDisable);

	const handleValueChange = (field, e) => {
		if (field === 'bucketName') {
			setBucketName(e);
		} else {
			if (typeof e === 'object' && Array.isArray(e)) {
				setSelectCategoryToAddChannel(e.map((item) => item.live_category_id));

				if (isEdit) {
					setTimeout(() => {
						const element = document.querySelector('.select-popup-error-wrapper');
						if (element) {
							element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
						}
					}, 500);
				}
			} else {
				setSelectCategoryToAddChannel(e.live_category_id);
			}
		}
	};

	const fetchMoreCategory = () => {
		if (hasMoreCategory) {
			setCategoryOffset(categoryLabelsDemo.length);
		}
	};
	useEffect(() => {
		finalProgress > 0 ? setDisableButton(true) : setDisableButton(!isDisable);
		if (finalProgress < 0) {
			setDisableButton(true);
		}
	}, [finalProgress, isDisable]);

	return (
		<div className="create-live-popup-container">
			<div className="create-live-popup-wrapper">
				<div className="create-live-popup-message">
					{message || CONST.CREATE_LIVE_CHANNEL_POPUP.HEADING}
				</div>
				<div className="create-live-popup-seperator"></div>
				<div className="create-live-popup-input-wrapper">
					<CustomInputPopup
						label={
							inputHeading || CONST.CREATE_LIVE_CHANNEL_POPUP_CUSTOM_POPUP.INPUT_LABEL
						}
						defaultValue={bucketName}
						handleValueChanges={handleValueChange}
						placeholder={
							inputPlaceholder ||
							CONST.CREATE_LIVE_CHANNEL_POPUP_CUSTOM_POPUP.INPUT_PLACEHOLDER
						}
						hasError={errorState.includes('bucketName') ? true : false}
						errorVerbose="Bucket name is required"
						id="bucketName"
						fromLiveScreen={true}
					/>
				</div>
				{!fromCategoryScreen && (!fromSubCategoryScreen || isEdit) && (
					<div className="create-live-popup-category">
						<CustomSelectPopup
							label={CONST.CREATE_LIVE_CHANNEL_POPUP_CUSTOM_POPUP.LABEL}
							defaultValue={category}
							from="popup"
							placeholder={CONST.CREATE_LIVE_CHANNEL_POPUP_CUSTOM_POPUP.PLACEHOLDER}
							handleValueChanges={handleValueChange}
							menuItems={categoryLabelsDemo}
							hasError={false}
							errorVerbose={CONST.CREATE_LIVE_CHANNEL_POPUP_CUSTOM_POPUP.ERROR_VERBOSE}
							idFieldName="live_category_id"
							id="category"
							selectType={isEdit ? 'multi' : 'single'}
							isfromLiveScreen={true}
							fetchMoreCategory={fetchMoreCategory}
							hasMoreCategory={hasMoreCategory}
							pills={isEdit}
						/>
					</div>
				)}
				<div
					className={`create-live-popup-file-picker ${
						uploadingError
							? fromCategoryScreen
								? 'file-picker-on-error-cat'
								: 'file-picker-on-error'
							: ''
					} ${fromCategoryScreen ? 'upload-margin' : ''}`}>
					<CustomFilePicker
						setIconKey={setIconKey}
						setProgress={setProgress}
						setFinalProgress={setFinalProgress}
						setUploadingError={setUploadingError}
						setUploadErrorMessage={setUploadErrorMessage}
						label={thumbnailHeading}
						uploadMessage={thumbnailText}
						fromCategoryScreen={fromCategoryScreen}
						iconKey={iconKey}
						isEdit={isEdit}
						thumbnail={thumbnail}
						fromSubCategoryScreen={fromSubCategoryScreen}
					/>
					{finalProgress > 0 ? (
						<div
							className={`${
								fromCategoryScreen
									? 'create-live-popup-progressbar-category'
									: 'create-live-popup-progressbar'
							}`}>
							{fromCategoryScreen ? (
								<Line
									percent={progress * CONST.PROGRESS_BAR_PERCENTAGE}
									{...CONST.PROGRESS_BAR_CATEGORY}
								/>
							) : (
								<Line
									percent={progress * CONST.PROGRESS_BAR_PERCENTAGE}
									{...CONST.PROGRESS_BAR}
								/>
							)}
						</div>
					) : null}
					{uploadingError && <div className="upload-error-div">{uploadErrorMessage}</div>}
				</div>
				<div className="create-live-popup-buttons">
					<button onClick={onCancel} className="create-live-popup-cancel">
						{CONST.CREATE_LIVE_CHANNEL_POPUP.CANCEL}
					</button>
					{loading ? (
						<ButtonLoader />
					) : (
						<button
							onClick={onAdd}
							className={`${disableButton ? 'create-live-popup-disable-button' : ''}`}>
							{buttonText || CONST.CREATE_LIVE_CHANNEL_POPUP.ADD}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default CreateLiveChannelPopup;
