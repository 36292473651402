const LIVE_CHANNEL_POPUP = {
	HEADING: 'Select category to add sub-category',
	CANCEL: 'Cancel',
	ADD: 'Add',
};

export const CUSTOM_OPTION_POPUP = {
	LABEL: 'SELECT CATEGORY',
	PLACEHOLDER: 'Category Name',
	ERROR_VERBOSE: 'Category is required',
};

export default LIVE_CHANNEL_POPUP;
