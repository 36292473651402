import React from 'react';
import './styles.scss';

const CustomDot = ({ onClick, isMounted, setFocus, ...rest }) => {
  const { active, index } = rest;
  return (
    <div
      onClick={() => {
        setFocus(index);
        isMounted.current = true;
        onClick();
      }}
      className={active ? "active-dot" : "inactive-dot"}
    />
  );
};

export default CustomDot;