import React from 'react';
import Filter from './Filter';

const FilterContainer = ({
	onChangeFilter,
	onChangeSearchFilter,
	applyFilters,
	searchRef,
	isFilterVisible,
	movieCastRef,
	onChangeMovieCast,
	filtersApplied,
	clearAllFilters,
	onChangeDirectors,
	toggleSearchInput,
	openServicesSearch,
	filterButtonEnable,
	handleFilter,
	sortBy,
	disabled = false,
	shuffle = false,
}) => {
	const handleSearch = (e) => {};
	return (
		<Filter
			onChangeFilter={onChangeFilter}
			onChangeSearchFilter={onChangeSearchFilter}
			handleSearch={handleSearch}
			applyFilters={applyFilters}
			searchRef={searchRef}
			movieCastRef={movieCastRef}
			isFilterVisible={isFilterVisible}
			onChangeMovieCast={onChangeMovieCast}
			filtersApplied={filtersApplied}
			clearAllFilters={clearAllFilters}
			onChangeDirectors={onChangeDirectors}
			toggleSearchInput={toggleSearchInput}
			openServicesSearch={openServicesSearch}
			filterButtonEnable={filterButtonEnable}
			handleFilter={handleFilter}
			sortBy={sortBy}
			disabled={disabled}
			shuffle={shuffle}
		/>
	);
};

export default FilterContainer;
