import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import moment from 'moment';
import Table from 'src/app/components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import InfiniteScroll from 'react-infinite-scroll-component';
import { infinteScrollEndMessage } from 'src/app/pages/ComponentConstant';
import { Loader } from 'src/app/components/Loader';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import * as CONST from 'src/app/pages/AllUsers/constants';
import {
	getCommonMethod,
	putCommonMethod,
	postCommonMethod,
	deleteData,
} from 'src/app/pages/Dashboard/Service';
import * as GLOBAL_CONSTANT from 'src/app/common/constants/AppConstants';
import {
	GET_USER_DATA_INITIAL,
	GET_USER_DATA,
	EDIT_USER_DATA_ON_DELETE,
} from 'src/app/pages/Dashboard/actionTypes';
import Row from './Row';
import { isEmptyString, capitalizeFirstLetter } from 'src/app/common/utils/methods';
import Popup from 'reactjs-popup';
import { verifyUserPopup } from 'src/app/common/constants/PopupStyles';
import UserVerifyModal from 'src/app/components/UserVerifyModal';
import { settingConfirmationStyle } from 'src/app/common/constants/PopupStyles';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';

export const TableContainer = ({
	offset,
	setOffset,
	loading,
	setLoading,
	shuffleMode = false,
	setTotalUserCount,
	setToastInfo,
	debouncedValue,
	selectedSort,
	showConfirmation,
	setShowConfirmation,
	date,
}) => {
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.allUsers?.allUsers);
	const [hasMore, setHasMore] = useState(false);
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const [userVerifyPopup, setUserVerifyPopup] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [loadingVerify, setLoadingVerify] = useState(false);
	const [error, setError] = useState(false);
	const [dropDownValue, setDropDownValue] = useState({
		name: false,
		email: false,
		'total-videos': false,
		followers: false,
		'remaining-invites': false,
	});
	const [filter, setFilter] = useState({
		key: 'created_at',
		value: false,
	});
	const highlighted = useRef(null);
	const userData = useRef([]);
	const [activateLoader, setActivateLoader] = useState(false);
	const [userActId, setUserActId] = useState(null);

	useEffect(() => {
		if (tableData) {
			setLoading(false);
			setTableDisplayData([...tableData]);
		}
	}, [tableData, setLoading]);

	useEffect(() => {
		if (tableDisplayData) {
			let verify_array = [];
			tableDisplayData.map((item) => {
				let val = {
					user_id: item.user_id,
					is_verified: item.is_verified,
					account_type: item.account_type,
					is_deactivate: item?.is_deactivate,
				};
				verify_array.push(val);
			});
			userData.current = verify_array;
		}
	}, [tableDisplayData]);

	const closeDeleteConfirmationModal = () => {
		setShowConfirmation(false);
	};
	const openDeleteConfirmationModal = (user_id) => {
		setUserActId(user_id);
		setShowConfirmation(true);
	};

	const openUserVerifyPopup = (data) => {
		setUserInfo(data);
		setUserVerifyPopup(true);
	};

	const closeUserVerifyPopup = () => {
		setError(false);
		setLoadingVerify(false);
		setUserInfo(null);
		setUserVerifyPopup(false);
	};

	const onDeleteUser = async () => {
		try {
			setActivateLoader(true);
			const deleteResponse = await deleteData(
				`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.DELETE_USER}`,
				{
					user_ids: [userActId],
				},
			);
			if (deleteResponse) {
				dispatch({ type: EDIT_USER_DATA_ON_DELETE, payload: userActId });
				setShowConfirmation(false);
				setToastInfo(true, false, CONST.USER_DELETE_SUCCESS_MESSAGE);
			}
		} catch (e) {
			console.log(e);
			setShowConfirmation(false);
			setToastInfo(true, false, GLOBAL_CONSTANT.ERROR_MESSAGE);
		} finally {
			setActivateLoader(false);
			setUserActId(null);
		}
	};

	const deactivateUser = async (userId, val, first_name, last_name) => {
		try {
			setActivateLoader(true);
			setUserActId(userId);
			const resp = await postCommonMethod(
				`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.DEACTIVATE_USER}`,
				{
					deactivate_user_id: userId,
					is_deactivate: val,
				},
			);
			if (resp.status) {
				const findIndex = userData.current.findIndex((item) => item.user_id === userId);

				const newVal = {
					user_id: userId,
					is_verified: userData.current[findIndex]?.is_verified,
					account_type: userData.current[findIndex]?.account_type,
					is_deactivate: val ? 1 : 0,
				};

				const newData = [...userData.current];
				newData[findIndex] = newVal;
				userData.current = newData;
				setToastInfo(
					true,
					false,
					`You have ${val ? 'deactivate' : 'activate'} ${capitalizeFirstLetter(
						first_name,
					)} ${capitalizeFirstLetter(last_name)} on Qewd`,
				);
			}
		} catch (e) {
			setToastInfo(true, true, GLOBAL_CONSTANT.ERROR_MESSAGE);
		}
		setActivateLoader(false);
		setUserActId(null);
	};

	const verifyUser = async (user_id, is_verified, account_type, username, deactivate) => {
		let val;
		if (userData.current[userInfo?.index]?.is_verified === 1) {
			val = true;
		} else {
			val = false;
		}

		if (
			val !== is_verified ||
			userData.current[userInfo?.index]?.account_type !== account_type
		) {
			setLoadingVerify(true);
			try {
				const response = await putCommonMethod(
					`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.GET_USERS}`,
					{
						user_id: user_id,
						is_verified: is_verified,
						account_type: account_type,
					},
				);

				const findIndex = userData.current.findIndex((item) => item.user_id === user_id);
				const newVal = {
					user_id: user_id,
					is_verified: is_verified === true ? 1 : 0,
					account_type: account_type,
					is_deactivate: deactivate,
				};
				const newData = [...userData.current];
				newData[findIndex] = newVal;
				userData.current = newData;

				setLoadingVerify(false);
				closeUserVerifyPopup();

				setToastInfo(
					true,
					false,
					`You have changed ${capitalizeFirstLetter(username)}'s account configuration`,
				);
			} catch (e) {
				setError(true);
				setLoadingVerify(false);
				console.log(e);
			}
		} else {
			closeUserVerifyPopup();
		}
	};

	const handleHasMore = (response) => {
		if (response?.data?.length > 0 && response?.data?.length === CONST.PER_PAGE) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	useEffect(() => {
		fetchUserData();
	}, [offset, debouncedValue, selectedSort]);

	useEffect(() => {
		if (date) {
			offset > 0 ? setOffset(0) : setOffset((val) => val - 1);
		}
	}, [date]);

	const handleDrop = async (k) => {
		setLoading(true);
		setFilter({
			key: k,
			value: !dropDownValue[k],
		});
		offset > 0 ? setOffset(0) : setOffset((val) => val - 1);
	};

	const fetchUserData = async () => {
		try {
			const params = {
				offset: offset > 0 ? offset : 0,
				perPage: CONST.PER_PAGE,
				order: filter.value
					? GLOBAL_CONSTANT.ORDER_TYPE.ASC
					: GLOBAL_CONSTANT.ORDER_TYPE.DESC,
				orderBy: CONST.mapper[filter.key],
			};
			if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
				const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
				params.text = modifiedDebounceValue;
			}
			if (date && date?.startDate && date?.endDate) {
				const localTime1 = moment(date?.startDate).format('YYYY-MM-DD'); // store localTime
				const startDate = date?.startDate ? localTime1 + 'T00:00:00.000Z' : null;

				const localTime2 = moment(date?.endDate).format('YYYY-MM-DD'); // store localTime
				const endDate = date?.endDate ? localTime2 + 'T23:59:59.000Z' : null;

				params.from_date = startDate;
				params.to_date = endDate;
			}
			if (offset > 0) {
				const response = await getCommonMethod(
					`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.GET_USERS}`,
					params,
				);
				dispatch({ type: GET_USER_DATA, payload: response.data });
				setTotalUserCount(response?.total_record_count);
				handleHasMore(response);
			} else {
				setLoading(true);
				const response = await getCommonMethod(
					`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.GET_USERS}`,
					params,
				);
				dispatch({ type: GET_USER_DATA_INITIAL, payload: response.data });
				setTotalUserCount(response?.total_record_count);
				handleHasMore(response);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const fetchMoreData = () => {
		if (hasMore) {
			setOffset(tableDisplayData?.length);
		}
	};
	const handleAllCheck = () => {};

	const updateInviteCount = async (updatedCount, userId, userType, firstName) => {
		try {
			const response = await putCommonMethod(
				`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.GET_USERS}`,
				{
					user_id: userId,
					invite_count: updatedCount,
					user_type: userType,
				},
			);
			setToastInfo(
				true,
				false,
				`You have changed ${capitalizeFirstLetter(firstName)}'s invites successfully.`,
			);
		} catch (e) {
			setToastInfo(true, true, GLOBAL_CONSTANT.ERROR_MESSAGE);
		}
	};

	const renderTable = () => {
		if (loading) {
			return (
				<EmptyState columns={CONST.ALL_USERS_TABLE_COLUMNS_CONFIG} renderColumns={2} />
			);
		} else {
			return tableDisplayData.map((item, index) => {
				return (
					<Row
						key={item && `${item.user_id}`}
						data={{ ...item, index }}
						updateInviteCount={updateInviteCount}
						setToastInfo={setToastInfo}
						onHandleClick={openUserVerifyPopup}
						userData={userData.current}
						deactivateUser={deactivateUser}
						activateLoader={activateLoader}
						userActId={userActId}
						onDeleteUser={onDeleteUser}
						showConfirmation={showConfirmation}
						setShowConfirmation={setShowConfirmation}
						closeDeleteConfirmationModal={closeDeleteConfirmationModal}
						openDeleteConfirmationModal={openDeleteConfirmationModal}
					/>
				);
			});
		}
	};

	return (
		<div>
			<Table isRenderable={true}>
				<div id="scrollableDivAllUsers">
					<InfiniteScroll
						dataLength={tableData.length}
						next={fetchMoreData}
						hasMore={hasMore}
						loader={<Loader loadMore={true} />}
						scrollableTarget="scrollableDivAllUsers"
						endMessage={loading ? null : infinteScrollEndMessage()}>
						<table className="table-all-users">
							<thead>
								<tr>
									{CONST.ALL_USERS_TABLE_COLUMNS_CONFIG.map((column) => {
										return (
											<Column
												key={column.key}
												config={column}
												handleAllCheck={(event) => handleAllCheck(event)}
												shuffleMode={shuffleMode ?? false}
												isWaitlist={true}
												status={true}
												fromAllUser={true}
												dropDownValue={dropDownValue}
												setDropDownValue={setDropDownValue}
												handledrop={handleDrop}
												highlighted={highlighted}
											/>
										);
									})}
								</tr>
							</thead>
							<tbody>{renderTable()}</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</Table>
			<Popup
				className="popup-content-container-digest"
				open={userVerifyPopup}
				closeOnDocumentClick
				onClose={closeUserVerifyPopup}
				contentStyle={verifyUserPopup}>
				<UserVerifyModal
					closePopup={closeUserVerifyPopup}
					userInfo={userInfo}
					verifyUser={verifyUser}
					loading={loadingVerify}
					isError={error}
					userData={userData.current}
				/>
			</Popup>
			<Popup
				className="popup-content-container"
				open={showConfirmation}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={settingConfirmationStyle}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmationModal}
					onDelete={onDeleteUser}
					loading={activateLoader}
					message={CONST.DELETE_CONFIRMATION_MESSAGE}
				/>
			</Popup>
		</div>
	);
};

export default TableContainer;
