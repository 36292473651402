import * as types from './actionTypes';
import {
	fetchProducts,
	getCommonMethod,
	postCommonMethod,
	getTableData,
	getData,
	postData,
	putCommonMethod,
	putDigestVideos,
	deleteData,
	getCommonMethodWithBody,
} from './Service';

export const toggleSidebarExpansion = (flag) => {
	return { type: types.SET_EXPANDED_SIDEBAR, payload: flag };
};

// when any reservations screen header tab is pressed, we store it in global state to handle header tab animations.
export const setPressedHeaderTab = (pressedHeaderTab) => {
	return { type: types.SET_PRESSED_HEADER_TAB, payload: pressedHeaderTab };
};

export const setTotalProducts = (url) => async (dispatch) => {
	getData(url).then((res) => {
		dispatch({ type: types.SET_PRODUCTS, payload: res });
	});
};

export const setSelectedBucket = (status) => async (dispatch) => {
	dispatch({ type: types.SET_SELECTED_BUCKET, payload: { status } });
};

export const getCarouselMetadata = (url) => async (dispatch) => {
	getCommonMethod(url).then((res) => {
		dispatch({ type: types.GET_CAROUSEL_METADATA, payload: res });
	});
};

export const getAllBucket = (url, params, apiBody) => async (dispatch) => {
	getCommonMethod(url, params).then((res) => {
		dispatch({ type: types.GET_ALL_BUCKETS, payload: res });
	});
};

export const replaceAllBucket = (url, params) => async (dispatch) => {
	getCommonMethod(url, params).then((res) => {
		dispatch({ type: types.REPLACE_ALL_BUCKETS, payload: res });
	});
};

export const getBucketTableData = (url, params) => async (dispatch) => {
	getTableData(url, params).then((res) => {
		dispatch({ type: types.GET_TABLE_DATA, payload: { res, params } });
	});
};

export const getTableDataOnOperation = (url, params, callBack) => async (dispatch) => {
	getTableData(url, params).then((res) => {
		callBack(res);
		dispatch({
			type: types.GET_TABLE_DATA_ON_OPERATION,
			payload: { res, params },
		});
	});
};

export const getBucketTableDataOnTabChange =
	(url, params, callBack) => async (dispatch) => {
		getTableData(url, params).then((res) => {
			callBack(res);
			dispatch({
				type: types.GET_TABLE_DATA_ON_TAB_CHANGE,
				payload: { res, params },
			});
		});
	};

export const getBucketTableDataOnScreenChange =
	(url, params, callBack) => async (dispatch) => {
		getTableData(url, params).then((res) => {
			callBack(res);
			dispatch({
				type: types.GET_TABLE_DATA_ON_SCREEN_CHANGE,
				payload: { res, params },
			});
		});
	};

export const getCarouselData = (url, apiBody) => async (dispatch) => {
	postCommonMethod(url, apiBody).then((res) => {
		dispatch({ type: types.CAROUSEL_DATA, payload: res });
	});
};

export const addVideoToBucket = (url, apiBody, callBack) => async (dispatch) => {
	postCommonMethod(url, apiBody)
		.then((res) => {
			callBack(res);
			dispatch({ type: types.ADD_VIDEO_TO_BUCKET, payload: res });
		})
		.catch((err) => callBack(err));
};

export const createBucket = (url, data, callBack) => async (dispatch) => {
	postData(url, data)
		.then((res) => {
			callBack(res);
		})
		.catch((err) => callBack(err));
};

export const getScreens = (url) => async (dispatch) => {
	getData(url).then((res) => {
		dispatch({ type: types.GET_SCREENS, payload: res });
	});
};

export const updateBucketRankById = (url, data, callback) => async (dispatch) => {
	putCommonMethod(url, data).then((res) => {
		callback(res);
	});
};

/** to handle page number of each tab */
export const setPageOffset = (tab, pageNumber) => {
	return { type: types.SET_PAGE_OFFSET, payload: { tab, pageNumber } };
};

export const getSubSectionData = (url, params, callback) => {
	getCommonMethod(url, params).then((res) => {
		callback(res);
	});
};

/** getting dashboard filters */
export const getFilters = (url, params, callback) => {
	getCommonMethod(url, params).then((res) => {
		callback(res);
	});
};

export const deleteVideoFromBucket = (url, body, callback) => {
	deleteData(url, body).then((res) => {
		callback(res);
	});
};

export const resetSubSectionData = async (url, params, callback) => {
	getCommonMethod(url, params).then((res) => {
		callback(res);
	});
};

export const getCardSizes = (url, params) => async (dispatch) => {
	getCommonMethod(url, params).then((res) => {
		dispatch({ type: types.GET_CARD_SIZES, payload: res?.data });
	});
};

export const getCategories = (url, params) => async (dispatch) => {
	getCommonMethod(url, params).then((res) => {
		dispatch({ type: types.GET_CATEGORIES, payload: res?.data });
	});
};
export const getPublishedTableData = (url, params) => async (dispatch) => {
	getTableData(url, params).then((res) => {
		dispatch({ type: types.GET_PUBLISHED_DATA, payload: res?.data });
	});
};

export const getPublishedTableDataOnOperation = (url, params) => async (dispatch) => {
	getTableData(url, params).then((res) => {
		dispatch({
			type: types.GET_PUBLISHED_DATA_ON_OPERATION,
			payload: res?.data,
		});
	});
};

export const updatePublishBucketData = (data) => async (dispatch) => {
	dispatch({
		type: types.GET_PUBLISHED_DATA_ON_ORDER_CHANGE,
		payload: data,
	});
};

export const getPublisheddTableDataOnScreenChange = (url, params) => async (dispatch) => {
	getTableData(url, params).then((res) => {
		dispatch({
			type: types.GET_PUBLISHED_DATA_ON_SCREEN_CHANGE,
			payload: res?.data,
		});
	});
};

export const getWaitlistUserData = (url, params) => async (dispatch) => {
	getTableData(url, params).then((res) => {
		dispatch({
			type: types.GET_WAITLIST_USER_DATA,
			payload: res?.data,
		});
	});
};

export const getWaitlistUserDataInitial = (url, params) => async (dispatch) => {
	getTableData(url, params).then((res) => {
		dispatch({
			type: types.GET_WAITLIST_USER_DATA_INITIAL,
			payload: res?.data,
		});
	});
};

export const updateBucketDetails = (url, body, callback) => {
	putCommonMethod(url, body)
		.then((res) => {
			callback(res);
		})
		.catch((err) => callback(err));
};

export const editTableData = (url, params, callback) => {
	getTableData(url, params).then((res) => {
		callback(res);
	});
};

export const deleteBucket = (url, body, callback) => {
	deleteData(url, body)
		.then((res) => {
			callback(res);
		})
		.catch((err) => callback(err));
};

export const fetchDataFromGetApi = (url, params, callback) => {
	getCommonMethod(url, params)
		.then((res) => {
			callback(res);
		})
		.catch((error) => callback(error));
};

export const updateDataFromApi = (url, apiBody, callback) => {
	putCommonMethod(url, apiBody)
		.then((res) => {
			callback(res);
		})
		.catch((error) => callback(error));
};

export const postDataToApi = (url, apiBody, callback) => {
	postCommonMethod(url, apiBody)
		.then((res) => {
			callback(res);
		})
		.catch((error) => callback(error));
};

export const getBucketDetails = (url, params) => async (dispatch) => {
	getCommonMethod(url, params).then((res) => {
		dispatch({ type: types.GET_BUCKET_DETAILS, payload: res?.data });
	});
};

export const setBucketDetails = (payload) => async (dispatch) => {
	dispatch({ type: types.SET_BUCKET_DETAILS, payload });
};

export const setDigestData = (payload) => async (dispatch) => {
	dispatch({ type: types.SET_DIGEST_DATA, payload });
};

export const updateQwedVideos = (url, apiBody, callback) => {
	putDigestVideos(url, apiBody)
		.then((res) => {
			callback(res);
		})
		.catch((error) => callback(error));
};

export const publishDigest = (url, apiBody, callback) => {
	putCommonMethod(url, apiBody)
		.then((res) => {
			callback(res);
		})
		.catch((error) => callback(error));
};
