const AWS_CONFIG = {
	Auth: {
		region: process.env.REACT_APP_REGION,
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

		oauth: {
			domain: process.env.REACT_APP_OAUTH_DOMAIN,
			scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
			redirectSignIn: process.env.REACT_APP_REDIRECT_URL, //REDIRECT URI FOR SOCIAL LOGIN.
			redirectSignOut: process.env.REACT_APP_REDIRECT_URL,
			responseType: 'code',
			grant_type: 'authorization_code',
		},
	},
	Storage: {
		AWSS3: {
			bucket: 'qewd-test-live-category',
			region: process.env.REACT_APP_REGION,
		},
	},
};

export default AWS_CONFIG;
