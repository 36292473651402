import React, { useState, useEffect } from 'react';
import './styles.scss';
import * as CONST from '../constants';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import InfiniteScroll from 'react-infinite-scroll-component';
import { infinteScrollEndMessage } from 'src/app/pages/ComponentConstant';
import { Loader } from 'src/app/components/Loader';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import Table from 'src/app/components/Table';
import Row from './Row';
import { getCommonMethod } from 'src/app/pages/Dashboard/Service';
import * as GLOBAL_CONSTANT from 'src/app/common/constants/AppConstants';

export const RightPanel = ({
	setDisableSave,
	selectedService,
	setSelectedService,
	setNewServices,
	newServices,
	setProviderName,
}) => {
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [hasMore, setHasMore] = useState(false);

	useEffect(() => {
		if (tableData) {
			setLoading(false);
		}
	}, [tableData, setLoading]);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setSelectedService(newServices);
	}, [newServices]);

	const fetchData = async () => {
		try {
			setLoading(true);
			const res = await getCommonMethod(
				`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.PROVIDER_FREE}`,
				{
					type: CONST.FREE_PROVIDERS,
				},
			);
			setTableData([...res?.data]);
			let newService = [];
			res?.data?.map((item) => {
				if (item?.is_playable_on_qewd) {
					newService.push(item?.provider_id);
				}
			});
			setNewServices(newService);
		} catch (error) {
			setLoading(false);
		}
	};

	const fetchMoreData = () => {};

	useEffect(() => {
		if (JSON.stringify(selectedService.sort()) !== JSON.stringify(newServices.sort())) {
			let array = [];
			selectedService?.map((item) => {
				const index = tableData.findIndex((data) => data?.provider_id === item);
				array.push(tableData[index]?.display_name);
			});
			setProviderName([...array]);
			setDisableSave(true);
		} else {
			setDisableSave(false);
		}
	}, [selectedService]);

	const handleClick = (e, service_id) => {
		if (e.target.checked === false) {
			const index = selectedService.findIndex((item) => item === service_id);
			let array = [...selectedService];
			if (index > -1) {
				array.splice(index, 1);
				setSelectedService([...array]);
			}
		}
		if (e.target.checked === true) {
			if (!selectedService.includes(service_id)) {
				setSelectedService([...selectedService, service_id]);
			}
		}
	};

	const renderTable = () => {
		if (loading) {
			return (
				<EmptyState columns={CONST.WEBVIEW_TABLE_COLUMNS_CONFIG} renderColumns={1} />
			);
		} else {
			return tableData.map((item, index) => {
				return (
					<Row
						key={item && `${item.provider_id}`}
						data={{ ...item, index }}
						handleClick={(event) => handleClick(event, item.provider_id)}
					/>
				);
			});
		}
	};

	return (
		<div className="right-panel-container">
			<div className="right-panel-table-wrapper">
				<Table isRenderable={true}>
					<div id="scrollableDivWebviewList">
						<InfiniteScroll
							dataLength={tableData?.length}
							next={fetchMoreData}
							hasMore={hasMore}
							loader={<Loader loadMore={true} />}
							scrollableTarget="scrollableDivWebviewList"
							endMessage={null}>
							<table className="table-webview">
								<thead>
									<tr>
										{CONST.WEBVIEW_TABLE_COLUMNS_CONFIG.map((column) => {
											return <Column key={column.key} config={column} />;
										})}
									</tr>
								</thead>
								<tbody>{renderTable()}</tbody>
							</table>
						</InfiniteScroll>
					</div>
				</Table>
			</div>
		</div>
	);
};

export default RightPanel;
