import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as CONSTANTS from './constants';
import { AppButton } from 'src/app/components/AppButton';
import AddImage from 'src/app/assets/images/Add.svg';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import TableContainer from './component/TableContainer';
import './styles.scss';
import { Toast } from 'src/app/components/Toast';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import { deleteData } from 'src/app/pages/Dashboard/Service';
import { EDIT_DATA_ON_LIVE_CHANNEL_TABLE } from 'src/app/pages/Dashboard/actionTypes';
import { editTableData } from 'src/app/pages/Dashboard/actionCreators';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { RECORD_TO_FETCH } from 'src/app/common/constants/BucketListingConstant';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import ScrollToTop from 'src/app/components/atoms/ScrollToTop';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import BreadCrumbs from 'src/app/components/BreadCrumbs';

export const LiveSubCategories = () => {
	const history = useHistory();
	const path = useLocation();
	const params = path && path.pathname && path.pathname.split('/');
	const channelId = params && params[params.length - 1];
	const [isChecked, setChecked] = useState(false);
	const [offset, setOffset] = useState(0);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [countMultipleCheck, setCountMultipleCheck] = useState(0);
	const [countOnAllUnselected, setCountOnAllUnselected] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [loading, setLoading] = useState(false);
	const [liveChannelId, setLiveChannelId] = useState(null);
	const searchRef = useRef('');
	const [apiBody, setApiBody] = useState([]);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [addButtonLoader, setAddButtonLoader] = useState(false);
	const [createPopup, setCreatePopup] = useState(false);
	const [categoryOffset, setCategoryOffset] = useState(0);
	const [isDisable, setDisable] = useState(false);
	const [bucketName, setBucketName] = useState('');
	const [selectCategoryToAddChannel, setSelectCategoryToAddChannel] = useState(null);
	const [counterToShowDelete, setCounterToShowDelete] = useState(0);
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [defaultDeleteMessage, setdefaultDeleteMessage] = useState(true);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [channelName, setChannelName] = useState('');
	const [deleteChannelId, setDeleteChannelId] = useState(null);
	const [isEditPopup, setIsEditPopup] = useState(false);
	const [category, setCategory] = useState([]);
	const debouncedValue = useDebounce(searchText, 500);
	const [allCategoryData, setAllCategoryData] = useState([]);
	const [totalCount, setTotalCount] = useState(-1);

	useEffect(() => {
		if (countMultipleCheck < 1) {
			setChecked(false);
			setIsAllChecked(false);
		} else {
			setChecked(true);
			setIsAllChecked(true);
		}
	}, [countMultipleCheck, setCountMultipleCheck]);

	useEffect(() => {
		if (apiBody.length < 1) {
			setChecked(false);
		}
	}, [apiBody]);

	useEffect(() => {
		counterToShowDelete > 0 ? setShowDeleteButton(true) : setShowDeleteButton(false);
	}, [counterToShowDelete]);

	const handleViewChannelCategory = () => {
		history.push(`/live/subCategory`);
	};

	const handleCreate = (val) => {
		setDisable(false);
		setAddButtonLoader(false);
		setBucketName('');
		setCategory([]);
		setSelectCategoryToAddChannel(parseInt(channelId));
		if (val) {
			setTimeout(() => {
				const element = document.querySelector('.select-popup-error-wrapper');
				if (element) {
					element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
				}
			}, 500);
		}
		setCreatePopup(val);
		setIsEditPopup(false);
	};

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#scrollableDivLiveSubCategories');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const handleDelete = () => {
		setdefaultDeleteMessage(false);
		setShowDeletePopup(true);
	};

	const closeDeleteConfirmationModal = () => {
		setShowDeletePopup(false);
		setdefaultDeleteMessage(true);
	};

	const onDeleteChannel = async () => {
		setAddButtonLoader(true);
		let apiData = [];
		if (deleteChannelId) {
			apiData.push(deleteChannelId);
		} else {
			apiBody.map((item) => {
				apiData.push(item.live_channel_id);
			});
		}
		await deleteChannels(apiData);
	};

	const removeDeletedItemFromRedux = (form, apiData) => {
		editTableData(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CHANNEL_BY_CATEGORY}`,
			form,
			({ data }) => {
				const copyOfLiveChannels = [...allCategoryData];
				apiData.map((val) => {
					const index = copyOfLiveChannels.findIndex(
						(item) => val === item?.live_channel_id,
					);
					copyOfLiveChannels.splice(index, 1);
				});
				setAllCategoryData(copyOfLiveChannels);
			},
		);
	};

	const deleteChannels = async (apiData) => {
		try {
			const res = await deleteData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.DELETE_LIVE_CHANNEL_FROM_CATEGORY}`,
				{
					live_category_id: parseInt(channelId),
					live_channel_ids: apiData,
				},
			);
			if (res.status) {
				let form = {
					offset: allCategoryData?.length,
					perPage: RECORD_TO_FETCH,
					orderBy: GLOBAL_CONST.ORDER_BY.createdAt,
					orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
					live_category_id: channelId,
				};
				removeDeletedItemFromRedux(form, apiData);
				setCountMultipleCheck(0);
				setCountOnAllUnselected([]);
				setIsAllChecked(false);
				setCounterToShowDelete(0);
				setToastInfo(true, false, res.message);
				if (apiBody.length === allCategoryData?.length && !deleteChannelId) {
					setOffset(-1);
				}
				setApiBody([]);
			}
		} catch (error) {
			setToastInfo(true, true, error.response.message);
		}
		setAddButtonLoader(false);
		setShowDeletePopup(false);
		setChannelName('');
		setdefaultDeleteMessage(true);
		setDeleteChannelId(null);
	};

	const handleChange = (e) => {
		setOffset(0);
		setSearchText(e.target.value);
	};
	const emptySearchBox = () => {
		setOffset(0);
		setSearchText('');
		searchRef.current.value = '';
	};

	const handleLiveChannelId = (id) => {
		setLiveChannelId(id);
	};

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	return (
		<div className="live-channel-right-container">
			<div className="live-channel-header-parent-block">
				<div className="live-header-block-channel">
					<h1>{`Sub Category - ${path.state?.name}`}</h1>
					{totalCount > -1 ? (
						<div className="count-header-wrapper">&nbsp;( {totalCount} )</div>
					) : null}
				</div>
				<BreadCrumbs value={`Sub Category - ${path.state?.name}`} />
				<div className="live-search-box-container">
					<div className="live-search-with-cancel">
						<DashboardSearchBox
							handleChange={handleChange}
							className="live-channel-search"
							ref={searchRef}
							placeholder={CONSTANTS.SEARCH_BOX_PLACEHOLDER}
						/>
						{searchText !== '' && (
							<Button
								label="Cancel"
								labelClassName="cancel-button-label"
								className="cancel-button"
								onClick={emptySearchBox}
							/>
						)}
					</div>
					<div className="live-header-buttons">
						{showDeleteButton && (
							<Tooltip
								overlay="Delete Live Sub-Category"
								placement="bottom"
								overlayInnerStyle={GLOBAL_CONST.overlayStyle}
								align={{
									offset: [-8, -2],
								}}>
								<div>
									<Button
										imageInfo={{
											src: DeleteBucket,
										}}
										className="live-delete-button"
										onClick={handleDelete}
									/>
								</div>
							</Tooltip>
						)}

						<Tooltip
							overlay="Create Live Sub-Category"
							placement="bottom"
							overlayInnerStyle={GLOBAL_CONST.overlayStyle}
							align={{
								offset: [-8, -2],
							}}>
							<div>
								<Button
									imageInfo={{
										src: AddImage,
									}}
									className="live-add-button"
									onClick={() => handleCreate(true)}
								/>
							</div>
						</Tooltip>
						<AppButton
							label="View All Sub-categories"
							className="live-app-button"
							innerClass="live-app-button-text"
							onClick={handleViewChannelCategory}
						/>
					</div>
				</div>
			</div>
			<div className="content-block">
				<TableContainer
					offset={offset}
					setOffset={setOffset}
					loading={loading}
					setLoading={setLoading}
					setChecked={setChecked}
					isAllChecked={isAllChecked}
					liveChannelId={liveChannelId}
					setIsAllChecked={setIsAllChecked}
					countMultipleCheck={countMultipleCheck}
					setCountMultipleCheck={setCountMultipleCheck}
					countOnAllUnselected={countOnAllUnselected}
					setCountOnAllUnselected={setCountOnAllUnselected}
					handleLiveChannelId={handleLiveChannelId}
					apiBody={apiBody}
					setApiBody={setApiBody}
					setToastInfo={setToastInfo}
					addButtonLoader={addButtonLoader}
					setAddButtonLoader={setAddButtonLoader}
					createPopup={createPopup}
					handleCreate={handleCreate}
					categoryOffset={categoryOffset}
					setCategoryOffset={setCategoryOffset}
					isDisable={isDisable}
					setDisable={setDisable}
					bucketName={bucketName}
					setBucketName={setBucketName}
					selectCategoryToAddChannel={selectCategoryToAddChannel}
					setSelectCategoryToAddChannel={setSelectCategoryToAddChannel}
					setCounterToShowDelete={setCounterToShowDelete}
					defaultDeleteMessage={defaultDeleteMessage}
					counterToShowDelete={counterToShowDelete}
					showDeletePopup={showDeletePopup}
					setShowDeletePopup={setShowDeletePopup}
					channelName={channelName}
					setChannelName={setChannelName}
					setDeleteChannelId={setDeleteChannelId}
					closeDeleteConfirmationModal={closeDeleteConfirmationModal}
					onDeleteChannel={onDeleteChannel}
					isEditPopup={isEditPopup}
					setIsEditPopup={setIsEditPopup}
					category={category}
					setCategory={setCategory}
					debouncedValue={debouncedValue}
					live_category_id={channelId}
					allCategoryData={allCategoryData}
					setAllCategoryData={setAllCategoryData}
					setTotalCount={setTotalCount}
				/>
			</div>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
			{allCategoryData?.length > 20 && (
				<ScrollToTop handleScrollToTop={handleScrollToTop} />
			)}
		</div>
	);
};

export default LiveSubCategories;
