import React, { useState } from 'react';
import './styles.scss';
import Kebab from '../../../assets/logos/Kebab.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import VerticalTile from '../../atoms/VericalTile';
import CarouselMoreOptionBox from '../../atoms/CarouselMoreOptionBox';
import HorizontalTile from '../../atoms/HorizontalTile';
import ClickAwayListener from 'react-click-away-listener';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useMediaQuery } from 'react-responsive';
import { EDIT_OPTION } from '../../../common/constants/BucketListingConstant';
import {
	IS_DESKTOP_OR_LAPTOP_MEDIA,
	RESPONSIVE_VERTICAL,
	RESPONSIVE_HORIZONTAL,
	TILES_TYPE,
} from 'src/app/common/constants/CarouselScreenConstant';
import {
	getBucketDetails,
	setBucketDetails,
} from 'src/app/pages/Dashboard/actionCreators';
import { ROOT_URL, BUCKET_DETAIL_URL } from 'src/app/common/constants/AppConstants';
import Arrow from 'src/app/assets/images/NavigateCategory.svg';
import ArrowActive from 'src/app/assets/images/NavigateCategoryActive.svg';

const CarouselData = ({
	bucketDetail,
	tileType,
	compId,
	carouselData,
	handleVideo,
	openEditBucketModal,
	handleClick,
}) => {
	const [showOptionDropdown, setShowOptionDropdown] = useState(false);
	const [icon, setIcon] = useState(Arrow);
	const isDesktopOrLaptop = useMediaQuery({
		query: `(min-width: ${IS_DESKTOP_OR_LAPTOP_MEDIA})`,
	});
	const dispatch = useDispatch();
	const history = useHistory();

	const handleBucketRedirect = (id, title) => {
		dispatch(setBucketDetails({}));
		let path = `/dashboard/${title}?bucket_id=${id}`;
		history.push(path);
	};

	const handleSelect = (option) => {
		if (option?.value === 'edit') {
			openEditBucketModal();
		}
		setShowOptionDropdown(false);
	};

	const renderSwitch = (tiles) => {
		return tiles === TILES_TYPE.vertical ? (
			<Carousel
				additionalTransfrom={0}
				responsive={RESPONSIVE_VERTICAL}
				centerMode={false}
				slidesToSlide={isDesktopOrLaptop ? 3 : 5}
				swipeable={!isDesktopOrLaptop}
				draggable={!isDesktopOrLaptop}
				minimumTouchDrag={1}
				containerClass="carousel-container"
				itemClass="carousel-item">
				{carouselData.data[bucketDetail?.title]?.map(function (movieItem) {
					return (
						<VerticalTile
							key={movieItem?.video_id}
							tileDetails={movieItem}
							handleVideo={handleVideo}
							handleClick={() =>
								handleClick(movieItem?.video_id, movieItem?.content_type)
							}
						/>
					);
				})}
			</Carousel>
		) : (
			<Carousel
				centerMode={false}
				additionalTransfrom={0}
				swipeable={!isDesktopOrLaptop}
				draggable={!isDesktopOrLaptop}
				slidesToSlide={isDesktopOrLaptop ? 3 : 5}
				minimumTouchDrag={1}
				responsive={RESPONSIVE_HORIZONTAL}
				containerClass="carousel-container"
				itemClass="carousel-item">
				{carouselData.data[bucketDetail?.title]?.map((movieItem) => (
					<HorizontalTile
						key={movieItem?.video_id}
						tileDetails={movieItem}
						handleVideo={handleVideo}
						fromYt={true}
						handleClick={() => handleClick(movieItem?.video_id, movieItem?.content_type)}
					/>
				))}
			</Carousel>
		);
	};
	return (
		<div className="carousel-wrapper">
			<div className="carousel-header-block">
				<div
					className="heading"
					onMouseEnter={() => setIcon(ArrowActive)}
					onMouseLeave={() => setIcon(Arrow)}
					onClick={() =>
						handleBucketRedirect(bucketDetail?.bucketId, bucketDetail?.title)
					}>
					<h3 className={bucketDetail?.title}>{bucketDetail?.title}</h3>
					<span
						onClick={() =>
							handleBucketRedirect(bucketDetail?.bucketId, bucketDetail?.title)
						}>
						<img src={icon} alt="Navigate to subcategory" className="navigate-icon" />
					</span>
				</div>
				<div className="right-aligned-options">
					<ClickAwayListener onClickAway={() => setShowOptionDropdown(false)}>
						<div onClick={() => setShowOptionDropdown(!showOptionDropdown)}>
							<div className="options">
								<img src={Kebab} />
							</div>
							{showOptionDropdown && (
								<div className="kebab-dropdown">
									<CarouselMoreOptionBox
										from="carousel"
										options={EDIT_OPTION}
										handleSelect={handleSelect}
									/>
								</div>
							)}
						</div>
					</ClickAwayListener>
				</div>
			</div>

			<div className="carousel-cardWrap">
				<div id={`content-block` + compId} className="carousel-content-block">
					{renderSwitch(tileType)}
				</div>
			</div>
		</div>
	);
};

export default CarouselData;
