import { SET_CONTENT_MANAGEMENT_DATA, IS_CONTENT_PUBLISHED } from "../Dashboard/actionTypes";
import CONTENT_MANAGEMENT_INITIAL_STATE from "./initialState";

const contentManagementReducer = (
  state = CONTENT_MANAGEMENT_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SET_CONTENT_MANAGEMENT_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case IS_CONTENT_PUBLISHED:
      return {
        ...state,
        isContentPublished: action.payload
      }
    default:
      return state;
  }
};

export default contentManagementReducer;
