import React, { useState, useEffect, useRef } from 'react';
import CustomDateRangePicker from 'src/app/components/atoms/CustomDateRangePicker';
import { getCurrentDate } from 'src/app/common/utils/dateUtils';
import './styles.scss';
import * as CONSTANTS from './constants';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import TableContainer from './component/TableContainer';
import { Toast } from 'src/app/components/Toast';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import IconTextButton from 'src/app/components/atoms/IconTextButton';
import { getCommonMethod, getData } from '../Dashboard/Service';

export const AllUsers = () => {
	const searchRef = useRef('');
	const [offset, setOffset] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [loading, setLoading] = useState(false);
	const [totalUserCount, setTotalUserCount] = useState(-1);
	const [showToast, setShowToast] = useState(false);
	const [isExportInProgress, setExportInProgress] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const debouncedValue = useDebounce(searchText, 500);
	const [selectedSort, setSelectedSort] = useState({
		key: 'created_at',
		label: 'Created At',
	});
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showDateFilter, setShowDateFilter] = useState(false);
	const [date, setDate] = useState(null);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	const handleChange = (e) => {
		setOffset(0);
		setSearchText(e.target.value);
	};
	const emptySearchBox = () => {
		setOffset(0);
		setSearchText('');
		searchRef.current.value = '';
	};

	const handleOptionChange = (val) => {
		setLoading(true);
		setSelectedSort(val);
	};

	const openDateFilter = () => {
		setShowDateFilter(true);
	};

	const handleDateChange = (date) => {
		setDate(date);
	};

	const exportUsers = () => {
		setExportInProgress(true);
		getCommonMethod('/user/export', undefined)
			.then((d) => {
				let status = d.status;
				let message = d.message;
				setToastInfo(true, !status, message);
				console.log(d);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setExportInProgress(false);
			});
	};

	return (
		<div className="all-users-right-container">
			<div className="all-users-header-parent-block">
				<div className="all-users-header-block">
					<h1>{CONSTANTS.ALL_USERS_HEADING}</h1>
					{totalUserCount > 0 && <div className="count-user">{totalUserCount}</div>}
				</div>
			</div>
			<div className="all-users-search-with-cancel">
				<div className="all-users-search">
					<DashboardSearchBox
						handleChange={handleChange}
						className="all-users-search"
						ref={searchRef}
						placeholder={CONSTANTS.SEARCH_BOX_PLACEHOLDER}
					/>
					{searchText !== '' && (
						<Button
							label="Cancel"
							labelClassName="cancel-button-label"
							className="cancel-button"
							onClick={emptySearchBox}
						/>
					)}
				</div>
				<div className="date-button-block">
					{date && date?.startDate && date?.endDate && (
						<span
							className={`date-placeholder ${date ? '' : 'no-display'}`}
							onClick={(e) => {
								setShowDateFilter(true);
							}}>
							<div>
								<p className={date?.startDate ? 'color-white' : null}>
									{date?.startDate
										? `${getCurrentDate(date.startDate)} - ${getCurrentDate(
												date.endDate,
										  )}`
										: ''}
								</p>
							</div>
						</span>
					)}
					<div className={'date-filter-button'}>
						<IconTextButton
							buttonText="Filter by date"
							handleClick={() => openDateFilter()}
							disabled={false}
						/>
					</div>
					<div className={'date-filter-button'}>
						<IconTextButton
							showLoader={isExportInProgress}
							buttonText="Export Users"
							handleClick={exportUsers}
							disabled={false}
						/>
					</div>
					<CustomDateRangePicker
						defaultValue={date}
						isVisible={showDateFilter}
						handleDateChange={handleDateChange}
						handleVisibiltyChange={(flag) => setShowDateFilter(flag)}
						fromAllUser={true}
					/>
				</div>
			</div>
			<div className="content-block">
				<TableContainer
					offset={offset}
					setOffset={setOffset}
					loading={loading}
					setLoading={setLoading}
					setTotalUserCount={setTotalUserCount}
					setToastInfo={setToastInfo}
					debouncedValue={debouncedValue}
					selectedSort={selectedSort}
					showConfirmation={showConfirmation}
					setShowConfirmation={setShowConfirmation}
					date={date}
				/>
			</div>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

export default AllUsers;
