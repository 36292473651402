import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { getCommonMethod, getYouTubeData } from 'src/app/pages/Dashboard/Service';
import * as CONST from './constant';
import {
	ROOT_URL,
	GET_VIDEOS_URL,
	BUCKET_DETAIL_URL,
} from 'src/app/common/constants/AppConstants';
import { fetchDataFromGetApi } from 'src/app/pages/Dashboard/actionCreators';
import './styles.scss';
import VideoDetails from './VideoDetails';

const VideoDetailScreen = () => {
	const useQuery = () => {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	};
	let query = useQuery();
	const title = query.get('title');
	const fromBucket = query.get('fromBucket');
	const bucketId = query.get('bucket_id');
	const [videoDetail, setVideoDetail] = useState([]);
	const [bucketTags, setBucketTags] = useState(null);
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(false);
	const [offset, setOffset] = useState(null);
	const [metaData, setMetaData] = useState(null);

	useEffect(() => {
		if (title?.length > 0 && fromBucket === 'false') {
			fetchDataForTitle();
		}
	}, [title, fromBucket]);

	useEffect(() => {
		if (fromBucket === 'true' && bucketId) {
			fetchData();
			setOffset(0);
		}
	}, [bucketId, fromBucket]);

	useEffect(() => {
		if (fromBucket === 'true' && bucketId && offset != null) {
			fetchDataFromBucket();
		}
	}, [offset]);

	const fetchData = () => {
		fetchDataFromGetApi(
			`${ROOT_URL}${BUCKET_DETAIL_URL}`,
			{
				bucket_id: bucketId,
			},
			({ data }) => {
				if (data) {
					setMetaData(data);
					if (data?.tags) {
						setBucketTags(data?.tags);
					} else {
						setBucketTags([]);
					}
				}
			},
		);
	};

	const updateResults = () => {
		fetchData();
	};

	const fetchDataForTitle = async () => {
		try {
			const res = await getYouTubeData(
				process.env.REACT_APP_YOUTUBE_SEARCH_AND_DOWNLOAD,
				{ hl: 'en', gl: 'US', type: CONST.TITLE_MAPPER[title] },
				false,
			);
			if (res) {
				const { contents } = res;
				const transformedData = contents?.map((item) => {
					return {
						channelId: item?.video?.channelId,
						channelTitle: item?.video?.channelName,
						publishTime: item?.video?.publishedTimeText,
						title: item?.video?.title,
						videoId: item?.video.videoId,
						thumbnail: item?.video.thumbnails[item?.video.thumbnails?.length - 1].url,
					};
				});
				const uniqueTransformedValue = _.uniqBy(transformedData, 'videoId');
				setVideoDetail(uniqueTransformedValue);
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const fetchDataFromBucket = async () => {
		try {
			const res = await getCommonMethod(`${ROOT_URL}${GET_VIDEOS_URL}`, {
				bucket_id: bucketId,
				content_type: 'video',
				orderBy: 'video_sequence',
				orderType: 'asc',
				orderOn: 'bv',
				perPage: CONST.RECORD_TO_FETCH,
				offset: offset > 0 ? offset : 0,
			});
			if (res) {
				if (offset > 0) {
					setVideoDetail([...videoDetail, ...res?.data]);
				} else {
					setVideoDetail(res?.data);
				}
				handleHasMore(res);
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const handleHasMore = (response) => {
		if (
			response &&
			response.data &&
			response.data.length > 0 &&
			response.data.length === CONST.RECORD_TO_FETCH
		) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const fetchMoreData = () => {
		if (hasMore) {
			setOffset(videoDetail?.length);
		}
	};

	return (
		<VideoDetails
			videoDetail={videoDetail}
			title={metaData?.name || title}
			bucketTags={bucketTags}
			fromBucket={fromBucket === 'false' ? false : true}
			loading={loading}
			setLoading={setLoading}
			fetchMoreData={fetchMoreData}
			hasMore={hasMore}
			setHasMore={setHasMore}
			bucketId={bucketId}
			updateResults={updateResults}
			metaData={metaData}
			setVideoDetail={setVideoDetail}
			offset={offset}
			setOffset={setOffset}
		/>
	);
};

export default VideoDetailScreen;
