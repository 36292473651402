import React, { useEffect, useState } from 'react';
import './styles.scss';
import { CUSTOM_FILEPICKER } from './constants';
import FilePickerIcon from 'src/app/assets/images/FilePickerIcon.svg';
import Upload from 'src/app/assets/logos/UploadIcon.svg';
import Remove from 'src/app/assets/logos/Remove.svg';
import { Storage } from 'aws-amplify';
import { ICON_URL, CATEGORY_THUMNAIL_URL } from 'src/app/common/constants/AppConstants';

const CustomFilePicker = ({
	label,
	setIconKey,
	setProgress,
	setFinalProgress,
	setUploadingError,
	setUploadErrorMessage,
	uploadMessage,
	fromCategoryScreen = false,
	iconKey,
	isEdit,
	thumbnail,
}) => {
	const [imageLabel, setImageLabel] = useState(null);
	const [count, setCount] = useState(0);
	const [displayButton, setDisplayButton] = useState(false);
	const [displayFile, setDisplayFile] = useState(true && !isEdit);
	const onSelectingIcon = async (e) => {
		if (e.target.files && e.target.files[0]) {
			const urlNameCheck = isEdit && e.target.files[0].name === thumbnail;
			if (!urlNameCheck) {
				if (
					(e.target.files[0].type === 'image/jpeg' ||
						e.target.files[0].type === 'image/png') &&
					e.target.files[0].size < CUSTOM_FILEPICKER.size
				) {
					setUploadingError(false);
					setUploadErrorMessage(CUSTOM_FILEPICKER.upload_default_error_message);
					setImageLabel(URL.createObjectURL(e.target.files[0]));
					const file = e.target.files[0];
					try {
						setFinalProgress(1);
						let res = '';
						if (fromCategoryScreen) {
							res = await Storage.put(`${file.name}`, file, {
								progressCallback(progress) {
									if (count < 1) {
										setFinalProgress(progress.total);
									}
									setProgress(progress.loaded / progress.total);
								},
								contentType: 'image/png', // contentType is optional
								customPrefix: {
									public: '',
								},
							});
						} else {
							res = await Storage.put(`live-channel-icons/${file.name}`, file, {
								progressCallback(progress) {
									if (count < 1) {
										setFinalProgress(progress.total);
									}
									setProgress(progress.loaded / progress.total);
								},
								contentType: 'image/png', // contentType is optional
								customPrefix: {
									public: '',
								},
							});
						}

						if (res.key) {
							setIconKey(res.key);
							setCount(0);
							setFinalProgress(0);
							setProgress(0);
						}
					} catch (error) {
						setUploadingError(true);
					}
				} else {
					setUploadingError(true);
					e.target.files[0].size < 2000001
						? setUploadErrorMessage(CUSTOM_FILEPICKER.invalid_file_message)
						: setUploadErrorMessage(CUSTOM_FILEPICKER.size_invalidation);
				}
			}
		}
	};

	const addDefaultSrc = (ev) => {
		ev.target.src = FilePickerIcon;
	};

	useEffect(() => {
		if (isEdit && fromCategoryScreen) {
			thumbnail
				? setImageLabel(`${CATEGORY_THUMNAIL_URL}${thumbnail}`)
				: setImageLabel(`${FilePickerIcon}`);
			setDisplayFile(true);
		} else if (isEdit && !fromCategoryScreen) {
			thumbnail && thumbnail !== 'NULL'
				? setImageLabel(`${thumbnail}`)
				: setImageLabel(`${FilePickerIcon}`);
		}
	}, [isEdit, fromCategoryScreen]);

	const onRemove = async (e) => {
		setFinalProgress(-1);
		setDisplayFile(false);
		try {
			const res = await Storage.remove(iconKey);
			if (res) {
				setImageLabel(null);
				setDisplayFile(true);
				setIconKey(null);
			}
		} catch (error) {
			setUploadingError(true);
		}
	};

	return (
		<div className="custom-file-picker-wrapper">
			<div className="custom-file-picker-label">
				<span>{label || CUSTOM_FILEPICKER.label}</span>
			</div>
			{!fromCategoryScreen && (
				<div className="custom-file-picker-input-wrapper">
					<label className="custom-file-label">
						<input
							type="file"
							className="custom-file-picker-input"
							onChange={onSelectingIcon}
						/>
						<div className="file-picker-outerdiv">
							<img
								src={imageLabel ? imageLabel : FilePickerIcon}
								alt="file-picker-icon"
								className={`${
									imageLabel ? 'file-picker-icon-selected' : 'file-picker-icon'
								}`}
								onError={addDefaultSrc}
							/>
							<span
								className={`file-picker-span ${
									imageLabel ? 'file-picker-span-white' : ''
								}`}>
								{imageLabel
									? imageLabel === FilePickerIcon
										? CUSTOM_FILEPICKER.browse_message
										: CUSTOM_FILEPICKER.image_selected
									: uploadMessage || CUSTOM_FILEPICKER.browse_message}
							</span>
						</div>
					</label>
				</div>
			)}
			{fromCategoryScreen && (
				<div className="cat-custom-file-picker-input-wrapper">
					<label className="cat-custom-file-label">
						{displayFile && (
							<input
								type="file"
								className="cat-custom-file-picker-input"
								onChange={onSelectingIcon}
							/>
						)}
						{imageLabel ? (
							<div
								className="cat-file-picker-backdrop"
								onMouseEnter={() => setDisplayButton(true)}
								onMouseLeave={() => setDisplayButton(false)}>
								<img
									src={imageLabel}
									alt="file-picker-backdrop"
									className={`${
										displayButton && iconKey ? 'blur-parent' : ''
									} cat-file-picker-backdrop-image`}
									onError={addDefaultSrc}
								/>
								{displayButton && iconKey && (
									<div className="cat-file-picker-backdrop-span">
										<div className="cat-file-picker-backdrop-button">
											<img src={Upload} className="cat-file-picker-backdrop-button-img" />
											<span className="cat-file-picker-backdrop-button-span">
												Upload Image
											</span>
										</div>

										<div
											className="cat-file-picker-backdrop-button"
											onClick={(event) => onRemove(event)}>
											<img src={Remove} className="cat-file-picker-backdrop-button-img" />
											<span className="cat-file-picker-backdrop-button-span">Remove</span>
										</div>
									</div>
								)}
							</div>
						) : (
							<div className="cat-file-picker-outerdiv">
								<img
									src={FilePickerIcon}
									alt="file-picker-icon"
									className="cat-file-picker-icon"
								/>
								<span
									className={`cat-file-picker-span ${
										imageLabel ? 'cat-file-picker-span-white' : ''
									}`}>
									{uploadMessage || CUSTOM_FILEPICKER.browse_message}
								</span>
							</div>
						)}
					</label>
				</div>
			)}
		</div>
	);
};

export default CustomFilePicker;
