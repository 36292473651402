import { COLORS } from 'src/app/common/constants/AppConstants';
export const popupContentStyle = {
	width: 596,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	right: '0px',
	height: '100vh',
	margin: '0',
	position: 'absolute',
};

export const addStreamToChannelPopup = {
	width: '90vw',
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	right: '0px',
	height: '100vh',
	margin: '0',
	position: 'absolute',
};

export const deleteConfirmationPopupStyle = {
	width: 327,
	height: 176,
	background: COLORS.background,
	marginTop: 300,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
};

export const digestPopupStyle = {
	width: 686,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	right: '0px',
	height: '100vh',
	margin: '0',
	position: 'absolute',
};
export const frequencyPopupStyle = {
	width: 439,
	height: 340,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	borderRadius: 12,
};

export const liveChannelPopupStyle = {
	width: 374,
	height: 273,
	background: COLORS.background,
	marginTop: 300,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
	zIndex: 101,
};

export const createChannelPopupStyle = {
	width: 483,
	height: 464,
	background: COLORS.background,
	marginTop: 200,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
};

export const deletePopupStyle = {
	width: 327,
	height: 'auto',
	background: COLORS.background,
	marginTop: 300,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
};

export const createCategoryPopupStyle = {
	width: 374,
	height: 380,
	background: COLORS.background,
	marginTop: 200,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
};
export const addUrlPopupStyle = {
	width: 434,
	height: 364,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	borderRadius: 12,
};

export const previewPopupStyle = {
	width: 535,
	height: 680,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	borderRadius: 12,
};

export const tagsPopupStyle = {
	width: 704,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	borderRadius: 12,
};

export const reportVideoPopupStyle = {
	width: 470,
	maxHeight: 525,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	borderRadius: 12,
};

export const videoDetailsPopupStyle = {
	width: 500,
	height: 625,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	borderRadius: 12,
};

export const videoPopupStyle = {
	width: 1000,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	borderRadius: 12,
};

export const verifyUserPopup = {
	width: 600,
	height: 714,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	borderRadius: 12,
};

export const castEditPopup = {
	width: 600,
	height: 510,
	background: COLORS.background,
	boxShadow: ' 0px 0px 20px rgba(0, 0, 0, 0.25)',
	borderRadius: 12,
};

export const settingConfirmationStyle = {
	width: 450,
	background: COLORS.background,
	marginTop: 300,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
	paddingBottom: 20,
	paddingTop: 10,
};

export const bucketDescTagsPopup = {
	width: 750,
	maxHeight: 442,
	background: COLORS.background,
	boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
	borderRadius: 12,
};

export const popupContentStyleBucketDetails = {
	width: 327,
	height: 176,
	background: '#2A2A31',
	marginTop: 300,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
};

export const youtubeVideoDetailsPopupStyle = {
	width: 700,
	height: 700,
	background: COLORS.background,
	boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.35)',
	borderRadius: 12,
};
