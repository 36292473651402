import React, { useState, useEffect } from 'react';
import AddIcon from '../../../assets/logos/Add.svg';
import { IMAGE_PLACEHOLDER_VERTICAL } from 'src/app/common/constants/AppConstants';
import './styles.scss';
import { getImage } from 'src/app/common/utils/methods';

const VerticalTile = ({ tileDetails, handleVideo, fromSearch = false, handleClick }) => {
	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_VERTICAL;
		ev.target.className = 'cardImgHor cardImgPlaceholder';
	};

	const handleAddButton = () => {
		fromSearch ? handleVideo(tileDetails) : handleVideo(tileDetails?.video_id);
	};
	return (
		<div className={`carousel-content-tile ${fromSearch ? 'vertical-search' : ''}`}>
			<div className={'image-parent-block'}>
				{tileDetails?.new && (
					<span className={'new-icon'}>
						<p>NEW</p>
					</span>
				)}
				{tileDetails?.content_type === 'show' && fromSearch && (
					<div className="tv-batch">TV</div>
				)}
				{true && (
					<span className={'add-icon'} onClick={handleAddButton}>
						<img src={AddIcon} />
					</span>
				)}
				<img
					onError={addDefaultSrc}
					className="cardImg"
					src={getImage(tileDetails?.thumbnail) || IMAGE_PLACEHOLDER_VERTICAL}
					onClick={handleClick}
				/>
			</div>
			<div className={'tile-label'}>
				<p>{tileDetails?.title}</p>
			</div>
		</div>
	);
};

export default VerticalTile;
