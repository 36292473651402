import React from 'react';
import './styles.scss';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';

export const TagPills = ({ data, crossIcon = false, handleCrossPress }) => {
	const handleCrossPressed = (e) => {
		if (crossIcon) {
			handleCrossPress(e);
		}
	};

	return (
		<div className="tag-pill-container">
			<div className="tags-wrap">
				<p className="tag-pills">{data}</p>
				{crossIcon && (
					<img
						id={data}
						src={CrossIcon}
						alt="Cross-Icon"
						className="img-pills"
						onClick={(e) => handleCrossPressed(e)}
					/>
				)}
			</div>
		</div>
	);
};

export default TagPills;
