import Grid from 'src/app/components/Grid';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './styles.scss';
import HorizontalTile from 'src/app/components/atoms/HorizontalTile';
import { getCommonMethod } from '../Dashboard/Service';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import { AppButton } from 'src/app/components/AppButton';
import Tab from 'src/app/components/molecules/Tab';
import CustomFilterDropDown from 'src/app/components/CustomFilterDropdown';
import { DROP_DOWN_ITEMS, HEADING, TABS, NO_RESULT_FOUND_MESSAGE } from './constant';
import IndividualTabContent from 'src/app/components/atoms/IndividualTabContent';
import { Loader } from 'src/app/components/Loader';
import Thumbnails from 'src/app/components/Thumbnails';
import { QEWD_VIDEOS, ROOT_URL } from 'src/app/common/constants/AppConstants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { deleteConfirmationPopupStyle } from 'src/app/common/constants/PopupStyles';
import { useDispatch } from 'react-redux';
import { ADD_THUMBNAILS } from '../Dashboard/actionTypes';
import { useSelector } from 'react-redux';
import ScrollToTop from 'src/app/components/atoms/ScrollToTop';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import { isEmptyString } from 'src/app/common/utils/methods';
import EmptyImage from 'src/app/assets/images/empty-state.png';
import { SkeletonLoader } from 'src/app/components/SkeletonLoader';
import Skeleton from '@mui/material/Skeleton';

export const QwedDigest = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState('all');
	const selectedThumbnails = useSelector((state) => state.qewdDigest.thumbnails);
	const [thumbnails, setThumbnails] = useState(selectedThumbnails || []);
	const [searchText, setSearchText] = useState('');
	const [optionDetails, setOptionDetails] = useState({
		key: 'recently-added',
		label: 'Recently Added',
	});
	const [offset, setOffset] = useState(0);
	const [onLoad, setOnLoad] = useState('mount');
	const [page, setPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const contentType = useRef('all');
	const [sortKey, setSortKey] = useState('video_id');
	const [sortOrder, setSortOrder] = useState('desc');
	const [showCountAlert, setShowCountAlert] = useState(false);
	const searchRef = useRef('');
	const [newData, setNewData] = useState({
		all: [],
		movies: [],
		shows: [],
		shorts: [],
	});
	const [allScroll, setAllScroll] = useState(0);
	const [moviesScroll, setMoviesScroll] = useState(0);
	const [showsScroll, setShowsScroll] = useState(0);
	const [shortsScroll, setShortsScroll] = useState(0);
	const debouncedValue = useDebounce(searchText, 500);

	// for searching by title use key 'text'
	const callApi = useCallback(async () => {
		const body = {
			offset: offset,
			perPage: 20,
			sortKey: sortKey,
			sortOrder: sortOrder,
		};
		if (contentType.current && contentType.current !== 'all') {
			body.content_type =
				contentType.current !== 'short_form' ? contentType.current : 'video';
		}
		if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
			const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
			body.text = modifiedDebounceValue;
		}
		const response = await getCommonMethod(`${ROOT_URL}${QEWD_VIDEOS}`, body);
		if (onLoad === 'mount') {
			if (
				response &&
				response.data &&
				response.data.length > 0 &&
				response.data.length === 20
			) {
				setHasMore(true);
			} else {
				setHasMore(false);
			}
		} else if (onLoad === 'page') {
			if (response && response.data && response.data.length > 0) {
				setHasMore(true);
			} else {
				setHasMore(false);
			}
		}
		return response;
	}, [contentType, offset, sortKey, sortOrder, onLoad, debouncedValue]);

	const scroller = document.querySelector('#qwed-digest-content-block');
	if (scroller) {
		scroller.addEventListener('scroll', (event) => {
			if (contentType.current === 'all') {
				setAllScroll(scroller.scrollTop);
			} else if (contentType.current === 'movie') {
				setMoviesScroll(scroller.scrollTop);
			} else if (contentType.current === 'show') {
				setShowsScroll(scroller.scrollTop);
			} else if (contentType.current === 'short_form') {
				setShortsScroll(scroller.scrollTop);
			}
		});
	}

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#qwed-digest-content-block');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const fetchData = useCallback(async () => {
		setLoading(true);
		const response = await callApi();
		if (contentType.current === 'all' && newData.all?.length < 20) {
			setNewData({ ...newData, all: response.data });
		} else if (contentType.current === 'movie' && newData.movies?.length < 20) {
			setNewData({ ...newData, movies: response.data });
		} else if (contentType.current === 'show' && newData.shows?.length < 20) {
			setNewData({ ...newData, shows: response.data });
		} else if (contentType.current === 'short_form' && newData.shorts?.length < 20) {
			setNewData({ ...newData, shorts: response.data });
		}
		setTimeout(() => setLoading(false), 200);
	}, [callApi, newData, debouncedValue]);

	useEffect(() => {
		dispatch({ type: ADD_THUMBNAILS, payload: thumbnails });
	}, [thumbnails]);

	const onAddButton = (id, thumb, title, description_alias, operation, copy) => {
		const objectToBeAdded = { id, thumbnail: thumb, title, description_alias };
		let copyOfThumbnails = [...thumbnails];
		if (copy && copy.length > 0) {
			copyOfThumbnails = [...copy];
		}
		const ifExists = copyOfThumbnails.findIndex((item) => item.id === id);
		if (ifExists === -1) {
			copyOfThumbnails = [...thumbnails, objectToBeAdded];
		}
		if (copyOfThumbnails.length <= 12) {
			const element = document.getElementById(`tile-${id}`);
			const addIcon = document.getElementById(`add-icon-${id}`);
			const addImage = document.getElementById(`image-${id}`);
			const index = copyOfThumbnails.findIndex((copy) => copy.id === id);
			if (element) {
				element.classList.add('selected-on-add');
			}
			if (addImage) {
				addImage.classList.add('display-none');
			}
			let count = document.getElementById(`count-${id}`);
			let text = document.getElementById(`text-${id}`);
			if (copy && copy.length > 0 && text) {
				text.textContent = index + 1;
			} else {
				if (!count) {
					count = document.createElement('div');
					count.id = `count-${id}`;
				} else {
					count.classList.remove('display-none');
				}
				if (!text) {
					text = document.createElement('div');
					text.id = `text-${id}`;
				}
				text.textContent = index + 1;
				count.classList.add('count-on-add');
				text.classList.add('count-on-add-text');
				count.appendChild(text);
				if (addIcon) {
					addIcon.appendChild(count);
				}
			}
			setThumbnails(copyOfThumbnails);
		} else if (operation === 'onAdd') {
			setShowCountAlert(true);
		}
	};

	const handleChangeTab = (tab) => {
		contentType.current = tab;
		setTimeout(() => {
			const element = document.querySelector('#qwed-digest-content-block');
			if (element) {
				if (contentType.current === 'all') {
					element.scrollTo({ top: allScroll, behavior: 'auto' });
				} else if (contentType.current === 'movie') {
					element.scrollTo({ top: moviesScroll, behavior: 'auto' });
				} else if (contentType.current === 'show') {
					element.scrollTo({ top: showsScroll, behavior: 'auto' });
				} else if (contentType.current === 'short_form') {
					element.scrollTo({ top: shortsScroll, behavior: 'auto' });
				}
			}
		}, 50);
		if (contentType.current === 'all') {
			setOffset(newData.all?.length);
			newData.all?.length / 20 > 0 ? setPage(newData.all?.length / 20) : setPage(1);
		} else if (contentType.current === 'movie') {
			setOffset(newData.movies?.length);
			newData.movies?.length / 20 > 0 ? setPage(newData.movies?.length / 20) : setPage(1);
		} else if (contentType.current === 'show') {
			setOffset(newData.shows?.length);
			newData.shows?.length / 20 > 0 ? setPage(newData.shows?.length / 20) : setPage(1);
		} else if (contentType.current === 'short_form') {
			setOffset(newData.shorts?.length);
			newData.shorts?.length / 20 > 0 ? setPage(newData.shorts?.length / 20) : setPage(1);
		}
		setOnLoad('mount');
		const element = document.getElementById('qwed-digest-content-block');
		element.scrollTo(0, 0);
		setActiveTab(tab);
	};

	const handleChange = (e) => {
		setNewData({ all: [], movies: [], shows: [], shorts: [] });
		setLoading(true);
		setOffset(0);
		setPage(1);
		setOnLoad('mount');
		setSearchText(e.target.value);
	};
	const emptySearchBox = () => {
		setNewData({ all: [], movies: [], shows: [], shorts: [] });
		setLoading(true);
		setOffset(0);
		setPage(1);
		setSearchText('');
		setOnLoad('mount');
		searchRef.current.value = '';
	};

	const fetchMoreData = () => {
		if (contentType.current === 'all') {
			setOffset(newData.all?.length);
		} else if (contentType.current === 'movie') {
			setOffset(newData.movies?.length);
		} else if (contentType.current === 'show') {
			setOffset(newData.shows?.length);
		} else if (contentType.current === 'short_form') {
			setOffset(newData.shorts?.length);
		}
		setPage((prev) => prev + 1);
		setOnLoad('page');
	};

	const onDelete = (item) => {
		const copy = [...thumbnails];
		const element = document.getElementById(`tile-${item.id}`);
		const addImage = document.getElementById(`image-${item.id}`);
		const count = document.getElementById(`count-${item.id}`);
		const index = copy.findIndex((i) => i.id === item.id);
		copy.splice(index, 1);
		if (element) {
			element.classList.remove('selected-on-add');
		}
		if (count) {
			count.textContent = '';
			count.classList.remove('count-on-add');
		}
		if (addImage) {
			addImage.classList.remove('display-none');
		}
		copy.forEach(({ id, thumb, title, description_alias }) => {
			onAddButton(id, thumb, title, description_alias, 'onDelete', copy);
		});
		setThumbnails(copy);
	};

	const applyFilters = (option) => {
		setPage(1);
		setOffset(0);
		setOnLoad('mount');
		if (option.key === 'recently-added') {
			setSortKey('video_id');
			setSortOrder('desc');
		} else if (option.key === 'title-a-z') {
			setSortKey('title');
			setSortOrder('asc');
		} else if (option.key === 'title-z-a') {
			setSortKey('title');
			setSortOrder('desc');
		}
	};

	const handleOptionChange = (option) => {
		setOptionDetails(option);
		applyFilters(option);
	};

	const redirectToPreviewPage = () => {
		dispatch({ type: ADD_THUMBNAILS, payload: thumbnails });
		history.push(`${location.pathname}/preview`, { thumbnails: thumbnails });
	};

	const fetchMoreAndAppendData = useCallback(async () => {
		const response = await callApi();
		if (contentType.current === 'all') {
			setNewData({ ...newData, all: [...newData.all, ...response.data] });
		} else if (contentType.current === 'movie') {
			setNewData({ ...newData, movies: [...newData.movies, ...response.data] });
		} else if (contentType.current === 'show') {
			setNewData({ ...newData, shows: [...newData.shows, ...response.data] });
		} else if (contentType.current === 'short_form') {
			setNewData({ ...newData, shorts: [...newData.shorts, ...response.data] });
		}
	}, [callApi, newData, debouncedValue]);

	useEffect(() => {
		if (activeTab && thumbnails && thumbnails.length > 0 && !loading) {
			thumbnails.forEach(({ id, thumb, title, description }) => {
				onAddButton(id, thumb, title, description, 'onTabChange');
			});
		}
	}, [activeTab, loading, page, offset, newData]);

	useEffect(() => {
		if (page === 1 && onLoad === 'mount') {
			fetchData();
		} else if (onLoad === 'page') {
			fetchMoreAndAppendData();
		}
	}, [onLoad, page, activeTab, sortKey, sortOrder, debouncedValue]);

	const redirectToDigest = () => {
		history.push(`/watchlist`);
	};

	return (
		<div className="qwed-digest-right-wrapper">
			<div>
				<h1>{HEADING}</h1>
			</div>
			<div className="search-box-container">
				<div className="search-with-cancel">
					<DashboardSearchBox
						handleChange={handleChange}
						className="qwed-digest-search"
						ref={searchRef}
						digestButton
					/>
					{searchText !== '' && (
						<Button
							label="Cancel"
							labelClassName="cancel-button-label"
							className="cancel-button"
							onClick={emptySearchBox}
						/>
					)}
				</div>
				<AppButton
					label="Pre Populate Videos"
					className="pre-populate"
					innerClass="pre-populate-text"
					onClick={redirectToDigest}
				/>
			</div>
			{thumbnails && thumbnails.length > 0 && (
				<div className="qwed-digest-thumbnails">
					{thumbnails.map((thumbnail) => {
						return (
							<Thumbnails
								key={thumbnail.id}
								thumbnail={thumbnail}
								onCrossIconClick={onDelete}
							/>
						);
					})}
				</div>
			)}
			<div className="tab-button-block">
				<div>
					<Tab
						tabs={TABS}
						active={activeTab}
						handleTabChange={handleChangeTab}
						className="qwed-digest-tabs"
						fromDigest={true}
					/>
				</div>

				<div className="right-button-block">
					<div className="sort-by">Sort by:</div>
					<CustomFilterDropDown
						placeholder={optionDetails.label}
						dropDownMenuItems={DROP_DOWN_ITEMS}
						handleValueChanges={handleOptionChange}
						defaultValue={optionDetails}
						idFieldName="key"
					/>
				</div>
			</div>
			<div className="qwed-digest-content-block" id="qwed-digest-content-block">
				{!loading && (
					<IndividualTabContent id="all" activeTab={activeTab}>
						<InfiniteScrollWithGrid
							data={newData.all}
							hasMore={hasMore}
							fetchMoreData={fetchMoreData}
							onAddButton={onAddButton}
							loading={loading}
							debouncedValue={debouncedValue}
						/>
					</IndividualTabContent>
				)}
				{!loading && (
					<IndividualTabContent id="movie" activeTab={activeTab}>
						<InfiniteScrollWithGrid
							data={newData.movies}
							hasMore={hasMore}
							fetchMoreData={fetchMoreData}
							onAddButton={onAddButton}
							loading={loading}
							debouncedValue={debouncedValue}
						/>
					</IndividualTabContent>
				)}
				{!loading && (
					<IndividualTabContent id="show" activeTab={activeTab}>
						<InfiniteScrollWithGrid
							data={newData.shows}
							hasMore={hasMore}
							fetchMoreData={fetchMoreData}
							onAddButton={onAddButton}
							loading={loading}
							debouncedValue={debouncedValue}
						/>
					</IndividualTabContent>
				)}
				{!loading && (
					<IndividualTabContent id="short_form" activeTab={activeTab}>
						<InfiniteScrollWithGrid
							data={newData.shorts}
							hasMore={hasMore}
							fetchMoreData={fetchMoreData}
							onAddButton={onAddButton}
							loading={loading}
							debouncedValue={debouncedValue}
						/>
					</IndividualTabContent>
				)}
				{loading && (
					<Grid>
						{[...Array(20)].map((i) => {
							return (
								<Skeleton
									variant="rectangular"
									width={'100%'}
									style={{ borderRadius: 6, aspectRatio: 1.7 }}
									height={180}
									sx={{
										bgcolor: 'rgba(63, 65, 69, 0.4)',
										animation: 'animation-c7515d 2.0s ease-in-out infinite;',
									}}
								/>
							);
						})}
					</Grid>
				)}
			</div>
			{thumbnails && thumbnails.length > 0 && (
				<div className="qwed-bottom-buttons">
					{/**@TODO will be needing this in future */}
					{/* <AppButton
						label="Cancel"
						className="cancel-button"
						innerClass="cancel-button-text"
					/> */}
					<AppButton
						label={`Create ${process.env.REACT_APP_QEWD_DIGEST_TITLE}`}
						className="create-qwed-digest"
						innerClass="create-qwed-digest-text"
						onClick={redirectToPreviewPage}
					/>
				</div>
			)}
			<Popup
				open={showCountAlert}
				className="count-alert-container"
				contentStyle={deleteConfirmationPopupStyle}
				closeOnDocumentClick
				onClose={() => setShowCountAlert(false)}>
				<div className="count-alert-content">
					<div className="title">You cannot select more than 12 videos at a time.</div>
					<AppButton
						label="Ok"
						onClick={() => setShowCountAlert(false)}
						className="app-button-style"
					/>
				</div>
			</Popup>
			{page > 1 && (
				<ScrollToTop handleScrollToTop={handleScrollToTop} fromDigestScreen={true} />
			)}
		</div>
	);
};

const InfiniteScrollWithGrid = ({
	data,
	hasMore,
	fetchMoreData,
	onAddButton,
	loading,
	debouncedValue,
}) => {
	return (
		<div>
			<InfiniteScroll
				dataLength={data.length}
				hasMore={hasMore}
				next={fetchMoreData}
				loader={<Loader loadMore={true} />}
				scrollableTarget="qwed-digest-content-block">
				<Grid>
					{data &&
						!loading &&
						data.length > 0 &&
						data.map((movie) => {
							return (
								<HorizontalTile
									key={movie.video_id}
									tileDetails={{
										title: movie.title,
										horizontal_thumbnail: movie.horizontal_thumbnail,
										video_id: movie.video_id,
										description_alias: movie.description_alias,
									}}
									handleVideo={onAddButton}
									fromYt={true}
									movieLabel={
										movie.content_type === 'video' ? 'Short Form' : movie.content_type
									}
								/>
							);
						})}
				</Grid>
			</InfiniteScroll>
			{data?.length === 0 && !loading && debouncedValue && (
				<div className="empty-image">
					<img src={EmptyImage} alt="no results found" />
					<h3 className="no-filter-found">{NO_RESULT_FOUND_MESSAGE}</h3>
				</div>
			)}
		</div>
	);
};
