import React, { useState, useEffect } from 'react';
import './styles.scss';
import { profileBackgroundColor } from 'src/app/pages/AllUsers/constants';
import { getDate } from 'src/app/common/utils/methods';

export const ReportContent = ({ item }) => {
	const [isAvatar, setIsAvatar] = useState(false);

	useEffect(() => {
		if (item?.avatar) {
			setIsAvatar(true);
		}
	}, [item]);

	const getProfileBackground = (name) => {
		const nameIndex = (name && name.length > 0 ? name[0].charCodeAt(0) : 1) % 7;
		return profileBackgroundColor[nameIndex];
	};

	const addDefaultSrc = (ev) => {
		setIsAvatar(false);
		ev.target.className = 'user-icon-error';
	};

	return (
		<div className="report-content-wrapper">
			{isAvatar ? (
				<span className="reporters-avatar" key={item?.avatar}>
					<img src={item?.avatar} onError={addDefaultSrc} />
				</span>
			) : (
				<div
					className={`reporters-avatar user-icon-error`}
					style={{ background: `${getProfileBackground(item?.first_name)}` }}>
					<span className="user-name-error-span">{`${
						item?.first_name?.charAt(0)?.toUpperCase() || ''
					}${item?.last_names?.charAt(0)?.toUpperCase() || ''}`}</span>
				</div>
			)}
			<div className="desc-wrapper">
				<div className="popup-name">{`${item?.first_name} ${item?.last_names}`}</div>
				<div className="popup-desc">{item?.reason}</div>
				<div className="popup-time">{getDate(item?.reported_at)}</div>
			</div>
		</div>
	);
};

export default ReportContent;
