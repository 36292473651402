export const READ_MORE = 'Read More...';
export const READ_LESS = 'Read Less';
export const DESC_LENGTH = 50;
export const SINGLE_COUNT = 1;
export const NOT_AVAILABLE = 'Not Available';
export const ACTORS = 'Actors';
export const ACTOR = 'Actor';
export const DIRECTORS = 'Directors';
export const DIRECTOR = 'Director';
export const PROVIDER_AVAILIBILTY_MAPPER = {
	rent_buy: 'Rent/Buy',
	subscription: 'Subscription',
	tv_everywhere: 'TV Everywhere',
	free: 'Free',
};
