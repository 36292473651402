import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './styles.scss';
import DataTableKebabMenu from '../../../../../components/molecules/DataTableKebabMenu';
import CustomStatusField from '../../../../../components/atoms/CustomStatusField';
import GrabIcon from '../../../../../assets/logos/Grab.svg';
import {
	transitionOccured,
	bucketRedirectPath,
	bucketRedirectPathForLive,
} from '../../../../../common/utils/methods';
import { newDateRange } from '../../../../../common/utils/dateUtils';
import {
	DRAFT_UNPUBLISH_OPTIONS,
	ARCHIVE_UNPUBLISH_OPTIONS,
	OPTIONS,
	LIVE_OPTIONS,
	LIVE_UNPUBLISH_OPTIONS,
	UNPUBLISH_OPTIONS,
} from '../../../../../common/constants/BucketListingConstant';
import {
	getBucketDetails,
	setBucketDetails,
} from 'src/app/pages/Dashboard/actionCreators';
import { ROOT_URL, BUCKET_DETAIL_URL } from 'src/app/common/constants/AppConstants';
import { Button } from 'src/app/components/Button';
import ShowMoreText from 'react-show-more-text';

export const Row = ({
	data,
	isAllChecked,
	shuffleMode,
	adjustPaddingBottomStatus,
	adjustPaddingBottomScreen,
	adjustPaddingBottomMoreOption,
	isLast,
	openEditBucketModal,
	openDeleteModal,
	status,
	openChangeStatusModal,
	bucket_id,
	deleteAll,
	setUncheckedOnSelectOne,
	selectedCards,
	length,
	onSelecting,
	screen_id,
	livescreen = false,
}) => {
	const [showScreenDropdown, setShowScreenDropdown] = useState(false);
	const [shuffleClass, setShuffleClass] = useState('');
	const [description, setDescription] = useState(null);
	const [show, setShow] = useState(false);
	const [selectionValue, setSelectionValue] = useState('');

	useEffect(() => {
		if (selectedCards?.length > 0) {
			const index = selectedCards.findIndex(
				(item) => item.bucket_id === bucket_id.toString() && item.screen_id === screen_id,
			);
			if (index > -1) {
				setSelectionValue(true);
			} else {
				setSelectionValue(false);
			}
		} else {
			setSelectionValue(false);
		}
	}, [selectedCards, selectionValue]);

	useEffect(() => {
		if (data?.is_predefined || data?.is_user_type) {
			setSelectionValue(false);
		} else {
			setSelectionValue(deleteAll);
		}
	}, [deleteAll]);

	const onSelectionChange = (e, screen_id) => {
		if (deleteAll && !e.target.checked) {
			setUncheckedOnSelectOne(false);
		}
		if (deleteAll && e.target.checked) {
			if (selectedCards?.length + 1 === length) {
				setUncheckedOnSelectOne(true);
			}
		}
		setSelectionValue(e.target.checked);
		onSelecting({ bucket_id: e.target.id.toString(), screen_id: screen_id });
	};

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (data?.description) {
			setDescription(data?.description);
		} else {
			setDescription('');
		}
	}, [data]);

	useEffect(() => {
		if (shuffleMode) {
			if (transitionOccured('rowShuffle', 'get') && data.index === 1) {
				setTimeout(() => {
					setShuffleClass('translateX');
				}, 100);
				setTimeout(() => {
					setShuffleClass('');
				}, 1000);
				transitionOccured('rowShuffle', 'set');
			}
		}
	}, [shuffleMode]);
	useEffect(() => {
		const arrowEl = document.getElementById(`select-screen-row-down-arrow-${data.index}`);
		if (!showScreenDropdown) {
			arrowEl?.classList.remove('select-box-down-arrow-rotate');
			arrowEl?.classList.add('select-box-down-arrow-unrotate');
		} else {
			arrowEl?.classList.remove('select-box-down-arrow-unrotate');
			arrowEl?.classList.add('select-box-down-arrow-rotate');
		}
	}, [showScreenDropdown]);

	const handlePaddingBottomStatus = (isVisible) => {
		if (isLast) {
			if (isVisible) {
				adjustPaddingBottomStatus(true);
			} else {
				adjustPaddingBottomStatus(false);
			}
		}
	};
	const handlePaddingBottomMore = (isVisible) => {
		if (isLast) {
			if (isVisible) {
				adjustPaddingBottomMoreOption(true);
			} else {
				adjustPaddingBottomMoreOption(false);
			}
		}
	};
	const handleScreenDropdownChange = (toggle = true) => {
		if (!toggle) {
			if (showScreenDropdown) {
				adjustPaddingBottomScreen(!showScreenDropdown);
				setShowScreenDropdown(false);
			}
		} else {
			if (isLast) adjustPaddingBottomScreen(!showScreenDropdown);
			setShowScreenDropdown(!showScreenDropdown);
		}
	};

	const handleBucketRedirect = (id, title, content_type, screen_name) => {
		dispatch(setBucketDetails({}));
		if (content_type === 'video') {
			const path = `/detail?title=${title}&fromBucket=${true}&bucket_id=${id}`;
			history.push(path);
		} else {
			const path = livescreen
				? bucketRedirectPathForLive(id, title, 'live-bucket', content_type)
				: screen_name === 'Live TV'
				? bucketRedirectPathForLive(id, title, 'live-bucket', content_type)
				: bucketRedirectPath(id, title);
			history.push(path);
		}
	};

	return (
		<tr
			id={`${status}-id-${data.index + 1}`}
			className={`${(data.index + 1) % 2 === 0 ? 'even' : 'odd'} ${shuffleClass}`}>
			<td>
				<span className="checkbox">
					{shuffleMode ? (
						<img src={GrabIcon} className="grab-icon" alt="Grab Icon" />
					) : (
						!livescreen && (
							<input
								id={bucket_id}
								type="checkbox"
								className={`name-checkbox-row ${
									data?.is_predefined || data?.is_user_type ? 'disable' : ''
								}`}
								value={selectionValue}
								onChange={(e) => onSelectionChange(e, data?.screen_id)}
								alt="selection"
								checked={selectionValue}
								disabled={data?.is_predefined || data?.is_user_type}
							/>
						)
					)}

					<span
						className="bucket-name"
						onClick={() =>
							handleBucketRedirect(
								data?.bucket_id,
								data?.name,
								data?.content_type,
								data?.screen_name,
							)
						}>
						{`${data.name}${data?.is_predefined || data?.is_user_type ? '  *' : ''}`}
					</span>
					<span className="ranked-name">{data?.is_ranked_fashion ? 'Ranked' : ''}</span>
				</span>
			</td>
			<td
				className={`${
					status === 'archived' ? 'row-dateRange-archived' : 'row-dateRange'
				}`}>
				{newDateRange(data)}
			</td>
			<td
				className={`${
					status === 'archived' ? 'row-description-archived' : 'row-description'
				}`}>
				<ShowMoreText
					lines={2}
					more="More"
					less="Less"
					className="show-description"
					anchorClass="more"
					onClick={() => {
						setShow(!show);
					}}
					expanded={false}
					width={280}
					truncatedEndingComponent={'... '}>
					{description}
				</ShowMoreText>
			</td>
			<td className={`${livescreen ? 'row-liveCount' : 'row-videoCount'}`}>
				{data.videoCount}
			</td>
			{status !== 'archived' && !livescreen && (
				<td>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							position: 'relative',
						}}
						onClick={() => handleScreenDropdownChange()}>
						{data.screen_name}
					</div>
				</td>
			)}
			<td className={`${status !== 'archived' ? 'row-icon' : 'remove-transform'}`}>
				<div className="three-dots">
					<DataTableKebabMenu
						openEditBucketModal={openEditBucketModal}
						openConfirmationModal={openDeleteModal}
						openChangeStatusModal={openChangeStatusModal}
						visibilityStatus={handlePaddingBottomMore}
						isStatusPassed={true}
						status={status}
						optionNotVisible={{
							value: data.is_user_type || data.is_predefined,
							column: 'delete',
						}}
						options={
							status === 'published'
								? livescreen
									? LIVE_OPTIONS
									: OPTIONS
								: status === 'archived'
								? ARCHIVE_UNPUBLISH_OPTIONS
								: status === 'unpublished'
								? livescreen
									? LIVE_UNPUBLISH_OPTIONS
									: UNPUBLISH_OPTIONS
								: DRAFT_UNPUBLISH_OPTIONS
						}
						divId={'#scrollableDiv'}
					/>
				</div>
			</td>
		</tr>
	);
};

Row.propTypes = {
	data: PropTypes.object.isRequired,
};
