import React from 'react';
import Popup from 'reactjs-popup';
import { addStreamToChannelPopup as bucketStyle } from '../../../common/constants/PopupStyles';
import './EditBucketModal.styles.scss';
import LiveStreams from 'src/app/pages/LiveStream';
import LiveChannels from 'src/app/pages/LiveChannel';

const AddStreamsToChannelModal = ({
	isOpen,
	closeModal,
	channelId,
	name,
	addToBucket = false,
	contentType = 'live_stream',
}) => {
	return (
		<Popup
			className="edit-modal-popup-animation"
			open={isOpen}
			closeOnDocumentClick={false}
			onClose={closeModal}
			contentStyle={bucketStyle}>
			<div className="live-stream-on-popup">
				{contentType === 'live_stream' && (
					<LiveStreams
						onPopup
						channelId={channelId}
						onClosePopup={closeModal}
						name={name}
						addToBucket={addToBucket}
					/>
				)}
				{contentType === 'live_channel' && (
					<LiveChannels
						onPopup
						channelId={channelId}
						onClosePopup={closeModal}
						name={name}
					/>
				)}
			</div>
		</Popup>
	);
};

export { AddStreamsToChannelModal };
