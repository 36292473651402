import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import CustomOptionsBox from 'src/app/components/atoms/CustomOptionsBox';
import DownArrow from 'src/app/assets/logos/DownArrow.svg';
import useOutsideAlerter from 'src/app/customHooks/ClickAway';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';
import { OTHER_THUMB_SIZE_MAPPER } from 'src/app/components/atoms/CustomOptionsBox/constant';

const CustomSelectPopup = ({
	id,
	label,
	placeholder,
	menuItems,
	defaultValue,
	handleValueChanges,
	from,
	idFieldName,
	hasError,
	errorVerbose,
	labels,
	selectType,
	scrollIntoView,
	scrollTop,
	isfromLiveScreen = false,
	fetchMoreCategory,
	hasMoreCategory,
	pills,
}) => {
	const [selectedValues, setSelectedValues] = useState(defaultValue || []);
	const [showOptionDropdown, setShowOptionDropdown] = useState(false);
	const selectWrapperRef = useRef(null);

	useOutsideAlerter(selectWrapperRef, () => {
		handleDropdownChange(false);
	});

	useEffect(() => {
		setSelectedValues(defaultValue);
	}, [defaultValue]);

	const handleSelect = (selectedOptions) => {
		setSelectedValues(selectedOptions);
		handleValueChanges(id, selectedOptions);
	};
	const handleDropdownChange = (state) => {
		setShowOptionDropdown((prev) => {
			const arrowEl = document.getElementById(`select-popup-down-arrow-${id}`);
			if (prev) {
				arrowEl?.classList.remove('select-box-down-arrow-rotate');
				arrowEl?.classList.add('select-box-down-arrow-unrotate');
				return !prev;
			} else {
				if (state) {
					arrowEl?.classList.remove('select-box-down-arrow-unrotate');
					arrowEl?.classList.add('select-box-down-arrow-rotate');
					return !prev;
				} else return false;
			}
		});
	};
	const getSelectedValuesLabel = () => {
		let selectedValuesLabel = '';
		if (Array.isArray(selectedValues) && selectedValues.length > 0) {
			selectedValues?.forEach((e) => {
				selectedValuesLabel += `${e.name}, `;
			});
			return selectedValuesLabel.slice(0, selectedValuesLabel.length - 2);
		} else if (Array.isArray(selectedValues) && selectedValues.length === 0) {
			return placeholder;
		} else if (typeof selectedValues === 'object' && selectedValues !== null) {
			if (OTHER_THUMB_SIZE_MAPPER[selectedValues.name]) {
				selectedValuesLabel = OTHER_THUMB_SIZE_MAPPER[selectedValues.name];
			} else {
				selectedValuesLabel = selectedValues.name;
			}

			return selectedValuesLabel;
		} else return placeholder;
	};

	const onFocus = () => {
		if (scrollIntoView) {
			const element = document.getElementById(id);
			setTimeout(() => {
				element.scrollIntoView({
					block: 'start',
					inline: 'end',
					behavior: 'smooth',
				});
			}, 400);
		} else if (scrollTop) {
			const modalContainerContent = document.querySelector('.modal-container-content');
			setTimeout(() => {
				modalContainerContent.scrollTop = modalContainerContent.scrollHeight;
			}, 400);
		}
	};

	const removePill = (id) => {
		const copyOfSelectedValues = [...selectedValues];
		const index = copyOfSelectedValues.findIndex((item) => item.live_category_id === id);
		copyOfSelectedValues.splice(index, 1);
		setSelectedValues(copyOfSelectedValues);
		handleValueChanges('', copyOfSelectedValues);
	};

	const getPills = () => {
		if (Array.isArray(selectedValues) && selectedValues.length > 0) {
			return selectedValues.map((item) => {
				return (
					<div className="pill" id={item.live_category_id}>
						<span>{item.name}</span>
						<span>
							<img
								src={CrossIcon}
								alt="delete"
								className="cross"
								onClick={(e) => {
									e.stopPropagation();
									removePill(item.live_category_id);
								}}
							/>
						</span>
					</div>
				);
			});
		} else return placeholder;
	};

	return (
		<div id={id} className="custom-select-popup-wrapper" onClick={onFocus}>
			<div className="popup-select-label">
				<p className="placeholder">{label}</p>
			</div>
			<div className="select-popup-error-wrapper">
				<div ref={selectWrapperRef} className="width-fit-content">
					<span className="custom-select-dropdown-wrapper" onClick={handleDropdownChange}>
						<div className="names">
							<p
								className={`${
									selectedValues && selectedValues.length > 0 ? 'color-white' : ''
								} ${
									typeof selectedValues === 'object' && selectedValues.name
										? 'color-white'
										: ''
								}`}>
								{pills ? getPills() : getSelectedValuesLabel()}
							</p>
						</div>
						<div className="down-arrow">
							<img
								src={DownArrow}
								id={`select-popup-down-arrow-${id}`}
								alt="Down Arrow"
							/>
						</div>
					</span>
					{showOptionDropdown && (
						<CustomOptionsBox
							fromScreen={from}
							options={menuItems}
							defaultValue={selectedValues}
							selectType={selectType ?? 'multi'}
							idFieldName={idFieldName}
							labels={labels}
							closeDropdown={(flag) => setShowOptionDropdown(flag)}
							handleSelect={handleSelect}
							isfromLiveScreen={isfromLiveScreen}
							handleRequestChange={fetchMoreCategory}
							hasMoreCategory={hasMoreCategory}
						/>
					)}
				</div>
				{hasError && selectedValues.length === 0 && (
					<div className={'error-state-field screen-error-div'}>
						<p>{errorVerbose}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default CustomSelectPopup;
