export const POPUP = {
	PUBLISHED_MESSAGE:
		'Are you sure you want to delete this published bucket as other users are dependent on it?',
	MESSAGE: 'Are you sure you want to delete the bucket?',
	PUBLISH_STATUS: 'Are you sure you want to publish this bucket?',
	UNPUBLISH_STATUS: 'Are you sure you want to unpublish this bucket?',
	PUBLISH_ALL_UPDATES: 'Are you sure you want to publish all the updates?',
	CONTENT_SUCCESSFULLLY_PUBLISHED:
		'Updated content has been published on the app successfully.',
	YOU_HAVE_UNSAVED_CHANGES: 'You have unsaved changes, do you want to save it?',
	ARCHIVE_STATUS: 'Are you sure you want to archive this bucket?',
};
