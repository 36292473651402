import React, { useState, useRef, useEffect } from 'react';
import { Toast } from 'src/app/components/Toast';
import { Button } from 'src/app/components/Button';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import './styles.scss';
import * as CONST from './constant';
import TableContainer from './TableContainer';

const ListForYou = () => {
	const searchRef = useRef('');
	const [offset, setOffset] = useState(0);
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const debouncedValue = useDebounce(searchText, 500);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	const handleChange = (e) => {
		setOffset(0);
		setSearchText(e.target.value);
	};
	const emptySearchBox = () => {
		setOffset(0);
		setSearchText('');
		searchRef.current.value = '';
	};

	return (
		<div className="list-for-you-right-container">
			<div className="header-parent-block">
				<div className="bucket-header-block">
					<h1>{CONST.LIST_FOR_YOU_HEADING}</h1>
				</div>
			</div>
			<div className="search-with-cancel">
				<DashboardSearchBox
					handleChange={handleChange}
					className="search"
					ref={searchRef}
					placeholder={CONST.SEARCH_BOX_PLACEHOLDER}
				/>
				{searchText !== '' && (
					<Button
						label="Cancel"
						labelClassName="cancel-button-label"
						className="cancel-button"
						onClick={emptySearchBox}
					/>
				)}
			</div>
			<div className="content-block">
				<TableContainer
					offset={offset}
					setOffset={setOffset}
					loading={loading}
					setLoading={setLoading}
					setToastInfo={setToastInfo}
					debouncedValue={debouncedValue}
				/>
			</div>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

export default ListForYou;
