import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteBucket,
	editTableData,
	getTableDataOnOperation,
	getScreens,
	updateBucketDetails,
} from '../../actionCreators';
import Tab from '../../../../components/molecules/Tab';
import IndividualTabContent from '../../../../components/atoms/IndividualTabContent';
import CustomSelectBucket from '../../../../components/atoms/CustomSelectBucket';
import { Table } from './Table/Table';
import {
	BUCKET_TABLE_COLUMNS_CONFIG,
	BUCKET_TABLE_COLUMNS_CONFIG_ARCHIVED,
	BUCKET_TABLE_COLUMNS_CONFIG_LIVE_SCREEN,
} from './Table/constant';
import * as GLOBAL_CONST from '../../../../common/constants/AppConstants';
import { EditBucketModal } from '../../../../components/Modals/EditBucketModal';
import { Toast } from '../../../../components/Toast';
import * as CONST from './constant';
import { RECORD_TO_FETCH } from '../../../../common/constants/BucketListingConstant';
import {
	EDIT_DATA_IN_TABLE,
	DELETE_DATA_FROM_TABLE,
	GET_PARENT_BUCKET_DATA,
} from '../../actionTypes';
import Popup from 'reactjs-popup';
import DeleteConfirmation from '../../../../components/molecules/DeleteConfirmation';
import TOAST_ERROR_MESSAGE from '../../../../common/constants/ToastMessages';
import { POPUP } from '../../../../common/constants/PopupMessages';
import ScrollToTop from 'src/app/components/atoms/ScrollToTop';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import { isEmptyString } from 'src/app/common/utils/methods';
import IconTextButton from 'src/app/components/atoms/IconTextButton';
import CreateBucket from 'src/app/components/CreateBucket';
import ArchiveIcon from 'src/app/assets/logos/ArchiveIcon.svg';
import { getCommonMethod, postCommonMethod, putCommonMethod } from '../../Service';

export const Bucket = ({ livescreen = false }) => {
	const dispatch = useDispatch();
	const screensSelector = useSelector((state) => state.reservations.screens);
	const tableData = useSelector((state) => state.reservations?.tableData);
	const totalBucket = useSelector((state) => state.reservations?.totalBucket);
	const newBucketTableData = useSelector((state) => state.reservations?.bucketTable);
	const newBucketLength = useSelector((state) => state.reservations?.totalBucketCount);
	const newDataLength = useSelector((state) => state.reservations?.newDataLength);
	const parentBucket = useSelector((state) => state.reservations.parentBucket);
	const [isUpdatedData, setUpdatedData] = useState(false);
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const [status, setStatus] = useState(CONST.STATUS.published);
	const [, setActive] = useState(CONST.STATUS.published);
	const [selectedScreenDetails, setSelectedScreenDetails] = useState({
		screen_id: 0,
		name: 'All Screens',
	});
	const [shuffleButtonStatus, setShuffleButtonStatus] = useState('disabled');
	const [openEditBucketModal, setOpenEditBucketModal] = useState();
	const [bucketId, setBucketId] = useState(null);
	const [screenBucketId, setScreenBucketId] = useState(null);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		isError: false,
	});
	const [isOpenDeleteComfirmation, setIsOpenDeleteComfirmation] = useState(false);
	const [isOpenChangeStatus, setIsOpenChangeStatus] = useState(false);
	const [noAutoScroll, setNoAutoScroll] = useState(false);
	const [onDeleteLoading, setOnDeleteLoading] = useState(false);
	const [onStatusChangeLoading, setOnStatusChangeLoading] = useState(false);
	const [screenId, setScreenId] = useState();
	const [onLoad, setOnLoad] = useState('page');
	const [isBucketNameDisable, setBucketNameDisable] = useState(false);
	const [count, setCount] = useState(1);
	const [loading, setLoading] = useState(true);
	const [publishScroll, setPublishScroll] = useState(0);
	const [unpublishScroll, setUnpublishScroll] = useState(0);
	const [draftScroll, setDraftScroll] = useState(0);
	const [archivedScroll, setArchivedScroll] = useState(0);
	const [onOperations, setOnOperations] = useState(false);
	const [bucketCount, setBuckectCount] = useState({
		published: -1,
		unpublished: -1,
		draft: -1,
		archived: -1,
	});

	const activeTab = useRef(CONST.STATUS.published);
	const screen = useSelector((state) => state.reservations.screens);
	const searchRef = useRef('');
	const [searchText, setSearchText] = useState('');
	const debouncedValue = useDebounce(searchText, 500);
	const [debCount, setDebCount] = useState(1);
	const [archiveOperation, setArchiveOperation] = useState(CONST.STATUS.published);
	const [selectedCards, setSelectedCards] = useState([]);
	const [deleteAll, setDeleteAll] = useState(false);
	const [unCheckedOnSelectOne, setUncheckedOnSelectOne] = useState(deleteAll);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (parentBucket.length === 0) {
			fetchParentBucket();
		}
	}, []);

	/**
	 * For Live Screen table we have done this to bring data only from this screen.
	 */
	useEffect(() => {
		if (livescreen && screensSelector?.data?.length > 0) {
			const liveTV = screensSelector?.data?.find((item) => item.screen_id === 4);
			setSelectedScreenDetails(liveTV);
		}
	}, [livescreen, screensSelector]);

	useEffect(() => {
		if (screen.length === 0) {
			dispatch(getScreens(`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.SCREEN_URL}`));
		}
	}, [dispatch]);

	useEffect(() => {
		setStatus(activeTab.current);
	}, [activeTab.current]);

	useEffect(() => {
		if (selectedCards.length === newBucketLength[status] && selectedCards.length) {
			setDeleteAll(true);
			setUncheckedOnSelectOne(true);
		}
	}, [selectedCards]);

	useEffect(() => {
		if (showToast.show) {
			const interval = setInterval(() => {
				setShowToast({ show: false, message: '' });
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	useEffect(() => {
		if (selectedScreenDetails?.screen_id) {
			if (shuffleButtonStatus === 'disabled') {
				setShuffleButtonStatus('enabled');
			}
		}
		if (selectedScreenDetails?.screen_id === 0) {
			setShuffleButtonStatus('disabled');
		}
	}, [selectedScreenDetails]);

	useEffect(() => {
		if (deleteAll && unCheckedOnSelectOne) {
			let arr = [];
			tableDisplayData.map((item, index) => {
				if (!item?.is_user_type && !item?.is_predefined) {
					arr.push({ bucket_id: item?.bucket_id.toString(), screen_id: item?.screen_id });
				}
			});
			setSelectedCards([...arr]);
		}
	}, [tableDisplayData]);

	const fetchParentBucket = async () => {
		try {
			const res = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.PARENT_BUCKET_URL}`,
			);
			dispatch({ type: GET_PARENT_BUCKET_DATA, payload: res?.data });
		} catch (e) {}
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const newBucketCreated = ({ response, message }) => {
		if (message) {
			if (activeTab.current === 'draft') {
				setCount(0);
			}
			setShowToast({ show: true, message: message, isError: false });
		} else if (response) {
			setShowToast({ show: true, message: response?.data?.message, isError: true });
		}
	};

	const toggleModal = () => {
		setIsOpen(!isOpen);
	};

	const onSelectingCards = (card) => {
		const copyOfSelectedCards = [...selectedCards];
		if (card) {
			const filteredCards = copyOfSelectedCards.filter((cardItem) => {
				return (
					cardItem.bucket_id === card.bucket_id && cardItem.screen_id === card.screen_id
				);
			});
			if (filteredCards.length === 0) {
				setSelectedCards([...copyOfSelectedCards, card]);
			} else {
				const index = copyOfSelectedCards.indexOf(filteredCards[0]);
				if (index >= 0) {
					copyOfSelectedCards.splice(index, 1);
					setSelectedCards(copyOfSelectedCards);
				}
			}
		}
	};

	const handleArchive = async () => {
		try {
			const bucket_data = [];
			selectedCards?.map((item) => {
				bucket_data.push(parseInt(item?.bucket_id));
			});
			const res = await putCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ARCHIVE_MULTIPLE}`,
				{
					status: 'archived',
					bucket_ids: bucket_data,
				},
			);
			setOnLoad('tabChange');
			count === 0 ? setCount(1) : setCount(0);
			setSelectedCards([]);
			setDeleteAll(false);
			setUncheckedOnSelectOne(false);
			openToast(TOAST_ERROR_MESSAGE.BUCKET_SUCCESSFULLY_ARCHIVED, false, true);
		} catch (e) {
			openToast(GLOBAL_CONST.ERROR_MESSAGE, true, true);
		}
	};

	const selectToDeleteAll = (e) => {
		if (e.target.checked) {
			setDeleteAll(true);
			setUncheckedOnSelectOne(true);
			let arr = [];
			tableDisplayData.map((item, index) => {
				if (!item?.is_user_type && !item?.is_predefined) {
					arr.push({ bucket_id: item?.bucket_id.toString(), screen_id: item?.screen_id });
				}
			});
			setSelectedCards([...arr]);
		} else {
			setDeleteAll(false);
			setUncheckedOnSelectOne(false);
			setSelectedCards([]);
		}
	};

	const setTabCount = (response) => {
		if (status === CONST.STATUS.draft) {
			setBuckectCount({ ...bucketCount, draft: response.data?.length });
		} else if (status === CONST.STATUS.unpublished) {
			setBuckectCount({ ...bucketCount, unpublished: response.data?.length });
		} else if (status === CONST.STATUS.published) {
			setBuckectCount({ ...bucketCount, published: response.data?.length });
		} else if (status === CONST.STATUS.archived) {
			setBuckectCount({ ...bucketCount, archived: response.data?.length });
		}
	};

	const handleChange = (e) => {
		setSearchText(e.target.value);
		setCount(1);
		setDebCount(1);
	};
	const emptySearchBox = () => {
		setSearchText('');
		searchRef.current.value = '';
		setCount(1);
		setDebCount(1);
	};

	const handleChangeTab = (tab) => {
		setOnLoad('tabChange');
		setDebCount(1);
		activeTab.current = tab;
		setActive(tab);
		setStatus(tab);
		setSelectedCards([]);
		setDeleteAll(false);
		setUncheckedOnSelectOne(false);
		if (tab === CONST.STATUS.published) {
			if (newBucketTableData.published.length === 0 || onOperations) {
				setCount(1);
				setOnOperations(false);
			} else {
				const remainder = Math.floor(newBucketTableData.published.length / 20);
				const modulo = newBucketTableData.published?.length % 20;
				modulo === 0 ? setCount(remainder) : setCount(remainder + 1);
			}
		} else if (tab === CONST.STATUS.unpublished) {
			if (newBucketTableData.unpublished?.length === 0 || onOperations) {
				setCount(1);
				setOnOperations(false);
			} else {
				const remainder = Math.floor(newBucketTableData.unpublished?.length / 20);
				const modulo = newBucketTableData.unpublished?.length % 20;
				modulo === 0 ? setCount(remainder) : setCount(remainder + 1);
			}
		} else if (tab === CONST.STATUS.draft) {
			if (newBucketTableData.draft.length === 0 || onOperations) {
				setCount(1);
				setOnOperations(false);
			} else {
				const remainder = Math.floor(newBucketTableData.draft?.length / 20);
				const modulo = newBucketTableData.draft?.length % 20;
				modulo === 0 ? setCount(remainder) : setCount(remainder + 1);
			}
		} else if (tab === CONST.STATUS.archived) {
			if (newBucketTableData.archived.length === 0 || onOperations) {
				setCount(1);
				setOnOperations(false);
			} else {
				const remainder = Math.floor(newBucketTableData.archived?.length / 20);
				const modulo = newBucketTableData.archived?.length % 20;
				modulo === 0 ? setCount(remainder) : setCount(remainder + 1);
			}
		}
	};

	const openEditModal = (id, bucketid, is_predefined, is_user_type) => {
		setBucketNameDisable(false);
		if (is_user_type) {
			if (is_user_type > 0) {
				setBucketNameDisable(true);
			}
		} else if (is_predefined) {
			if (is_predefined > 0) {
				setBucketNameDisable(true);
			}
		}

		setBucketId(id);
		setScreenBucketId(bucketid);
		setOpenEditBucketModal(true);
		setNoAutoScroll(true);
	};

	const closeEditModal = (val) => {
		setOpenEditBucketModal(val);
	};

	const openToast = (message, isError, show) => {
		setShowToast({ show: show, message: message, isError: isError });
	};

	const handleToastDismiss = () => {
		setShowToast({ show: false, message: '' });
	};

	const findIndexOfBucket = () => {
		let indexOfDataToBeUpdated;
		if (status === CONST.STATUS.published) {
			indexOfDataToBeUpdated = newBucketTableData.published.findIndex(
				(data) => data.bucket_id === bucketId,
			);
		} else if (status === CONST.STATUS.unpublished) {
			indexOfDataToBeUpdated = newBucketTableData.unpublished.findIndex(
				(data) => data.bucket_id === bucketId,
			);
		} else if (status === CONST.STATUS.archived) {
			indexOfDataToBeUpdated = newBucketTableData.archived.findIndex(
				(data) => data.bucket_id === bucketId,
			);
		} else {
			indexOfDataToBeUpdated = newBucketTableData.draft.findIndex(
				(data) => data.bucket_id === bucketId,
			);
		}
		return indexOfDataToBeUpdated;
	};

	const editTable = (form, homeType, discoverType, isPublishTab, operation) => {
		console.log(
			'form, homeType, discoverType, isPublishTab, operation----->\n',
			form,
			homeType,
			discoverType,
			isPublishTab,
			operation,
		);
		if (operation === 'onUpdate') {
			editTableData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_FILTER_URL}`,
				form,
				({ data }) => {
					let updatedBucketData = data?.data.filter(
						(item) => item.bucket_id === bucketId,
					);
					updatedBucketData =
						updatedBucketData.length > 0 ? updatedBucketData.pop() : null;
					dispatch({
						type: EDIT_DATA_IN_TABLE,
						payload: {
							home: {
								type: homeType,
								bucketId,
							},
							discover: {
								type: discoverType,
								bucketId,
							},
							updatedBucketData,
							isPublishTab: isPublishTab,
							newData: data,
							status: status,
						},
					});
				},
			);
			setTimeout(() => {
				dispatch(
					getTableDataOnOperation(
						`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_FILTER_URL}`,
						form,
						(response) => {
							if (response.status && response.data?.data) {
								setTabCount(response);
							}
						},
					),
				);
			}, 100);
		} else if (operation === 'onDelete') {
			dispatch({
				type: DELETE_DATA_FROM_TABLE,
				payload: {
					home: {
						type: homeType,
						bucketId,
					},
					discover: {
						type: discoverType,
						bucketId,
					},
					updatedBucketData: null,
					isPublishTab: isPublishTab,
					status: status,
				},
			});

			dispatch(
				getTableDataOnOperation(
					`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_FILTER_URL}`,
					form,
					(response) => {
						if (response.status && response.data?.data) {
							setTabCount(response);
						}
					},
				),
			);
		} else if (operation === 'onPublish' || operation === 'onUnpublish') {
			editTableData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_FILTER_URL}`,
				form,
				({ data }) => {
					dispatch({
						type: EDIT_DATA_IN_TABLE,
						payload: {
							home: {
								type: homeType,
								bucketId: screenId === 1 ? bucketId : bucketId, //:null
							},
							discover: {
								type: discoverType,
								bucketId: screenId === 2 ? bucketId : null,
							},
							updatedBucketData: null,
							isPublishTab: isPublishTab,
							newData: data,
							status: status,
						},
					});
				},
			);
			dispatch(
				getTableDataOnOperation(
					`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_FILTER_URL}`,
					form,
					(response) => {
						if (response.status && response.data?.data) {
							if (status === CONST.STATUS.archived) {
								setBuckectCount({
									...bucketCount,
									archived: response.data?.length,
									published:
										bucketCount.published > -1
											? bucketCount.published + 1
											: bucketCount.published,
								});
							} else if (status === CONST.STATUS.unpublished) {
								setBuckectCount({
									...bucketCount,
									unpublished: response.data?.length,
									published:
										bucketCount.published > -1
											? bucketCount.published + 1
											: bucketCount.published,
								});
							} else if (status === CONST.STATUS.published) {
								setBuckectCount({
									...bucketCount,
									published: response.data?.length,
									unpublished:
										bucketCount.unpublished > -1
											? bucketCount.unpublished + 1
											: bucketCount.unpublished,
								});
							} else if (status === CONST.STATUS.draft) {
								setBuckectCount({
									...bucketCount,
									draft: response.data?.length,
									published:
										bucketCount.published > -1
											? bucketCount.published + 1
											: bucketCount.published,
								});
							}
						}
					},
				),
			);
		}
		setOnOperations(true);
		setArchiveOperation(null);
	};

	const updateResults = (isDiscoverScreenPreset, isHomeScreenPreset, screenTo) => {
		setUpdatedData(true);
		const homeStatus = screenTo.home[0]?.status.toLowerCase();
		const discoverStatus =
			screenTo &&
			screenTo.discover?.length > 0 &&
			screenTo.discover[0]?.status.toLowerCase();
		const indexOfDataToBeUpdated = findIndexOfBucket();
		const page = Math.ceil(indexOfDataToBeUpdated / RECORD_TO_FETCH);
		let form = {
			page: indexOfDataToBeUpdated === 0 ? page + 1 : page,
			perPage: RECORD_TO_FETCH,
			status: status,
			...(livescreen && { screen_ids: 4 }),
		};
		if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
			const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
			form.searchTerm = modifiedDebounceValue;
		}
		let homeType;
		const isPublishTab = activeTab.current === CONST.STATUS.published;
		if (isHomeScreenPreset && homeStatus === activeTab.current) {
			homeType = 'no operation';
		} else if (isHomeScreenPreset && homeStatus !== activeTab.current && isPublishTab) {
			homeType = 'remove';
		} else if (isHomeScreenPreset && homeStatus !== activeTab.current) {
			homeType = 'add';
		} else {
			homeType = 'remove';
		}
		let discoverType;
		if (isDiscoverScreenPreset && discoverStatus === activeTab.current) {
			discoverType = 'no operation';
		} else if (
			isDiscoverScreenPreset &&
			discoverStatus !== activeTab.current &&
			isPublishTab
		) {
			discoverType = 'remove';
		} else if (isDiscoverScreenPreset && discoverStatus !== activeTab.current) {
			discoverType = 'add';
		} else {
			discoverType = 'remove';
		}
		if (livescreen) {
			editTable(form, 'no operation', discoverType, isPublishTab, 'onUpdate');
		} else {
			editTable(form, homeType, discoverType, isPublishTab, 'onUpdate');
		}
	};

	const closeDeleteConfirmationModal = () => {
		setIsOpenDeleteComfirmation(false);
		setIsOpenChangeStatus(false);
	};

	const openDeleteModal = (id, bucketid) => {
		setBucketId(id);
		setScreenBucketId(bucketid);
		setIsOpenDeleteComfirmation(true);
	};

	const openChangeStatusModal = (id, screenid) => {
		setBucketId(id);
		setScreenId(screenid);
		setIsOpenChangeStatus(true);
	};

	const openChangeStatusModalArchived = (id, screenid, val) => {
		setArchiveOperation(val);
		setBucketId(id);
		setScreenId(screenid);
		setIsOpenChangeStatus(true);
	};

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#scrollableDiv');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const onDeleteBucket = () => {
		setOnDeleteLoading(true);
		deleteBucket(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.BUCKET_URL}`,
			{
				bucket_id: bucketId,
			},
			({ message, status: responseStatus }, response) => {
				setOnDeleteLoading(false);
				if (responseStatus && message) {
					closeDeleteConfirmationModal();
					const indexOfDataToBeUpdated = findIndexOfBucket();
					const page = Math.ceil(indexOfDataToBeUpdated / RECORD_TO_FETCH);
					let form = {
						page: indexOfDataToBeUpdated === 0 ? page + 1 : page,
						perPage: RECORD_TO_FETCH,
						status: status,
						...(livescreen && { screen_ids: 4 }),
					};
					if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
						const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
						form.searchTerm = modifiedDebounceValue;
					}
					editTable(form, 'remove', 'remove', true, 'onDelete');
					openToast(TOAST_ERROR_MESSAGE.BUCKET_SUCCESSFULLY_DELETED, false, true);
				} else if (response) {
					setUpdatedData(false);
					openToast(response.data?.message, true, true);
				}
			},
		);
	};

	const onPublishAndUnpublishBucket = () => {
		setOnStatusChangeLoading(true);
		updateBucketDetails(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.UPDATE_STATUS_BY_SCREEN}`,
			{
				bucket_id: bucketId,
				status: status !== CONST.STATUS.draft ? archiveOperation : CONST.STATUS.published,
				screen_id: screenId,
			},
			({ message, status: responseStatus, response: resp }, response) => {
				setOnStatusChangeLoading(false);
				if (responseStatus && message) {
					setUpdatedData(true);
					closeDeleteConfirmationModal();
					const indexOfDataToBeUpdated = findIndexOfBucket();
					const page = Math.ceil(indexOfDataToBeUpdated / RECORD_TO_FETCH);
					let form = {
						page: indexOfDataToBeUpdated === 0 ? page + 1 : page,
						perPage: RECORD_TO_FETCH,
						status: status,
						...(livescreen && { screen_ids: 4 }),
					};
					const isPublishTab = activeTab.current === CONST.STATUS.published;
					editTable(
						form,
						archiveOperation === CONST.STATUS.archived
							? 'remove'
							: isPublishTab && (screenId === 1 || screenId === 4)
							? 'remove'
							: 'add',
						archiveOperation === CONST.STATUS.archived
							? 'remove'
							: isPublishTab && screenId === 2
							? 'remove'
							: 'add',
						isPublishTab,
						'onPublish',
					);
					openToast(TOAST_ERROR_MESSAGE.BUCKET_STATUS_CHANGED, false, true);
				} else if (response) {
					setUpdatedData(false);
					openToast(response.data?.message, true, true);
				} else if (resp) {
					setUpdatedData(false);
					openToast(resp?.data?.message, true, true);
					closeDeleteConfirmationModal();
				}
			},
		);
	};

	const markBucketAsListForYou = async () => {
		try {
			const listForYou = parentBucket.find(
				(item) => item.slug === CONST.LIST_FOR_YOU_SLUG,
			);
			let val = [];
			selectedCards?.map((item) => {
				val.push(parseInt(item?.bucket_id));
			});

			const res = await postCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.MARK_LIST_FOR_YOU}`,
				{
					parent_id: listForYou?.bucket_id,
					bucket_ids: val,
				},
			);
			setSelectedCards([]);
			setDeleteAll(false);
			setUncheckedOnSelectOne(false);
			openToast(TOAST_ERROR_MESSAGE.BUCKET_MARKED_AS_LIST_FOR_YOU, false, true);
		} catch (e) {
			openToast(GLOBAL_CONST.ERROR_MESSAGE, true, true);
		}
	};

	return (
		<div className="bucket-right-container">
			<div className={'header-parent-block'}>
				{!livescreen && (
					<div className={'bucket-header-block'}>
						<h1>{CONST.BUCKET.HEADING}</h1>
					</div>
				)}
				<div className="create-bucket">
					{!livescreen && activeTab.current === CONST.STATUS.published && (
						<div className="right-button-block">
							<CustomSelectBucket
								defaultValue={selectedScreenDetails}
								from="popup"
								placeholder="Select Screen"
								handleValueChanges={(value) => {
									setOnLoad('screenChange');
									setCount(1);
									setSelectedScreenDetails(value);
								}}
								menuItems={screensSelector?.data ?? []}
								idFieldName="screen_id"
							/>
						</div>
					)}

					<IconTextButton
						buttonText="+ Create Bucket"
						handleClick={() => toggleModal()}
					/>
				</div>
				<div className="searchbox-wrapper">
					<DashboardSearchBox
						handleChange={handleChange}
						className="live-channel-search"
						ref={searchRef}
						placeholder={'Search Bucket by Name'}
					/>
					{searchText !== '' && (
						<Button
							label="Cancel"
							labelClassName="cancel-button-label"
							className="cancel-button"
							onClick={emptySearchBox}
						/>
					)}
				</div>
				<div className={'tab-button-block'}>
					<div>
						<Tab
							tabs={livescreen ? CONST.TABS_LIVE : CONST.TABS}
							active={activeTab.current}
							handleTabChange={handleChangeTab}
							className="tab-global-container"
							bucketCount={bucketCount}
						/>
					</div>

					{!livescreen && (
						<div className="wrapper-right">
							<div className="create-bucket-list">
								<Button
									onClick={markBucketAsListForYou}
									label={CONST.LIST_FOR_YOU_HEADING}
									className={`adjust-screen adjust-padding  ${
										selectedCards.length > 0
											? 'button-pink'
											: 'button-black disable-button'
									}`}
									labelClassName={`${
										selectedCards.length > 0 ? 'label-white' : 'label-grey'
									}`}
									disabled={selectedCards?.length > 0 ? false : true}
								/>
							</div>
							{(activeTab.current === CONST.STATUS.published ||
								activeTab.current === CONST.STATUS.unpublished) && (
								<Button
									onClick={handleArchive}
									imageInfo={{
										src: ArchiveIcon,
										label: 'select to Archive',
									}}
									label={CONST.ARCHIVE_CONST.archiveLabel}
									className={`adjust-screen adjust-padding ${
										selectedCards.length > 0
											? 'button-pink'
											: 'button-black disable-button'
									}`}
									labelClassName={`${
										selectedCards.length > 0 ? 'label-white' : 'label-grey'
									}`}
									disabled={selectedCards?.length > 0 ? false : true}
								/>
							)}
						</div>
					)}
				</div>
			</div>

			<div className={'content-block'}>
				<IndividualTabContent id={CONST.STATUS.published} activeTab={activeTab.current}>
					<div className={'bucket-content-block'}>
						<Table
							columnsConfig={
								livescreen
									? BUCKET_TABLE_COLUMNS_CONFIG_LIVE_SCREEN
									: BUCKET_TABLE_COLUMNS_CONFIG
							}
							statusHere={status}
							selectedScreen={selectedScreenDetails}
							shuffleMode={shuffleButtonStatus === 'selected' ? true : false}
							openEditBucketModal={openEditModal}
							tableData={tableData}
							totalBucket={totalBucket}
							newBucketTableData={newBucketTableData}
							newBucketLength={newBucketLength}
							tableDisplayData={tableDisplayData}
							setTableDisplayData={setTableDisplayData}
							newDataLength={newDataLength}
							setUpdatedData={setUpdatedData}
							isUpdatedData={isUpdatedData}
							openDeleteModal={openDeleteModal}
							openChangeStatusModal={openChangeStatusModal}
							openChangeStatusModalArchived={openChangeStatusModalArchived}
							onLoad={onLoad}
							setOnLoad={setOnLoad}
							count={count}
							setCount={setCount}
							loading={loading}
							setLoading={setLoading}
							publishScroll={publishScroll}
							setPublishScroll={setPublishScroll}
							unpublishScroll={unpublishScroll}
							setUnpublishScroll={setUnpublishScroll}
							draftScroll={draftScroll}
							setDraftScroll={setDraftScroll}
							archivedScroll={archivedScroll}
							setArchivedScroll={setArchivedScroll}
							setTabCount={setTabCount}
							debouncedValue={debouncedValue}
							debCount={debCount}
							setDebCount={setDebCount}
							openToast={openToast}
							deleteAll={deleteAll}
							setUncheckedOnSelectOne={setUncheckedOnSelectOne}
							selectedCards={selectedCards}
							onSelectingCards={onSelectingCards}
							selectToDeleteAll={selectToDeleteAll}
							unCheckedOnSelectOne={unCheckedOnSelectOne}
							livescreen={livescreen}
						/>
					</div>
				</IndividualTabContent>
				<IndividualTabContent id={CONST.STATUS.unpublished} activeTab={activeTab.current}>
					<div className={'bucket-content-block'}>
						<Table
							columnsConfig={
								livescreen
									? BUCKET_TABLE_COLUMNS_CONFIG_LIVE_SCREEN
									: BUCKET_TABLE_COLUMNS_CONFIG
							}
							statusHere={status}
							openEditBucketModal={openEditModal}
							tableData={tableData}
							totalBucket={totalBucket}
							tableDisplayData={tableDisplayData}
							newBucketTableData={newBucketTableData}
							newBucketLength={newBucketLength}
							setTableDisplayData={setTableDisplayData}
							newDataLength={newDataLength}
							setUpdatedData={setUpdatedData}
							isUpdatedData={isUpdatedData}
							openDeleteModal={openDeleteModal}
							openChangeStatusModal={openChangeStatusModal}
							openChangeStatusModalArchived={openChangeStatusModalArchived}
							onLoad={onLoad}
							setOnLoad={setOnLoad}
							count={count}
							setCount={setCount}
							loading={loading}
							setLoading={setLoading}
							publishScroll={publishScroll}
							setPublishScroll={setPublishScroll}
							unpublishScroll={unpublishScroll}
							setUnpublishScroll={setUnpublishScroll}
							draftScroll={draftScroll}
							setDraftScroll={setDraftScroll}
							archivedScroll={archivedScroll}
							setArchivedScroll={setArchivedScroll}
							setTabCount={setTabCount}
							debouncedValue={debouncedValue}
							debCount={debCount}
							setDebCount={setDebCount}
							openToast={openToast}
							deleteAll={deleteAll}
							setUncheckedOnSelectOne={setUncheckedOnSelectOne}
							selectedCards={selectedCards}
							onSelectingCards={onSelectingCards}
							selectToDeleteAll={selectToDeleteAll}
							unCheckedOnSelectOne={unCheckedOnSelectOne}
							livescreen={livescreen}
						/>
					</div>
				</IndividualTabContent>
				<IndividualTabContent id={CONST.STATUS.draft} activeTab={activeTab.current}>
					<div className={'bucket-content-block'}>
						<Table
							columnsConfig={
								livescreen
									? BUCKET_TABLE_COLUMNS_CONFIG_LIVE_SCREEN
									: BUCKET_TABLE_COLUMNS_CONFIG
							}
							statusHere={status}
							openEditBucketModal={openEditModal}
							tableData={tableData}
							totalBucket={totalBucket}
							newBucketTableData={newBucketTableData}
							newBucketLength={newBucketLength}
							tableDisplayData={tableDisplayData}
							setTableDisplayData={setTableDisplayData}
							newDataLength={newDataLength}
							setUpdatedData={setUpdatedData}
							isUpdatedData={isUpdatedData}
							openDeleteModal={openDeleteModal}
							openChangeStatusModal={openChangeStatusModal}
							openChangeStatusModalArchived={openChangeStatusModalArchived}
							onLoad={onLoad}
							setOnLoad={setOnLoad}
							count={count}
							setCount={setCount}
							loading={loading}
							setLoading={setLoading}
							publishScroll={publishScroll}
							setPublishScroll={setPublishScroll}
							unpublishScroll={unpublishScroll}
							setUnpublishScroll={setUnpublishScroll}
							draftScroll={draftScroll}
							setDraftScroll={setDraftScroll}
							archivedScroll={archivedScroll}
							setArchivedScroll={setArchivedScroll}
							setTabCount={setTabCount}
							debouncedValue={debouncedValue}
							debCount={debCount}
							setDebCount={setDebCount}
							openToast={openToast}
							deleteAll={deleteAll}
							setUncheckedOnSelectOne={setUncheckedOnSelectOne}
							selectedCards={selectedCards}
							onSelectingCards={onSelectingCards}
							selectToDeleteAll={selectToDeleteAll}
							unCheckedOnSelectOne={unCheckedOnSelectOne}
							livescreen={livescreen}
						/>
					</div>
				</IndividualTabContent>
				<IndividualTabContent id={CONST.STATUS.archived} activeTab={activeTab.current}>
					<div className={'bucket-content-block'}>
						<Table
							columnsConfig={BUCKET_TABLE_COLUMNS_CONFIG_ARCHIVED}
							statusHere={status}
							openEditBucketModal={openEditModal}
							tableData={tableData}
							totalBucket={totalBucket}
							newBucketTableData={newBucketTableData}
							newBucketLength={newBucketLength}
							tableDisplayData={tableDisplayData}
							setTableDisplayData={setTableDisplayData}
							newDataLength={newDataLength}
							setUpdatedData={setUpdatedData}
							isUpdatedData={isUpdatedData}
							openDeleteModal={openDeleteModal}
							openChangeStatusModal={openChangeStatusModal}
							openChangeStatusModalArchived={openChangeStatusModalArchived}
							onLoad={onLoad}
							setOnLoad={setOnLoad}
							count={count}
							setCount={setCount}
							loading={loading}
							setLoading={setLoading}
							publishScroll={publishScroll}
							setPublishScroll={setPublishScroll}
							unpublishScroll={unpublishScroll}
							setUnpublishScroll={setUnpublishScroll}
							draftScroll={draftScroll}
							setDraftScroll={setDraftScroll}
							archivedScroll={archivedScroll}
							setArchivedScroll={setArchivedScroll}
							setTabCount={setTabCount}
							debouncedValue={debouncedValue}
							debCount={debCount}
							setDebCount={setDebCount}
							openToast={openToast}
							deleteAll={deleteAll}
							setUncheckedOnSelectOne={setUncheckedOnSelectOne}
							selectedCards={selectedCards}
							onSelectingCards={onSelectingCards}
							selectToDeleteAll={selectToDeleteAll}
							unCheckedOnSelectOne={unCheckedOnSelectOne}
							livescreen={livescreen}
						/>
					</div>
				</IndividualTabContent>
			</div>
			<CreateBucket
				isOpen={isOpen}
				closeModal={closeModal}
				toggleModal={toggleModal}
				newBucketCreated={newBucketCreated}
			/>
			<EditBucketModal
				bucketId={bucketId}
				isOpen={openEditBucketModal}
				setIsOpen={closeEditModal}
				showToast={openToast}
				isEdit
				noAutoScroll={noAutoScroll}
				setNoAutoScroll={setNoAutoScroll}
				callback={updateResults}
				screenBucketId={screenBucketId}
				isDisable={isBucketNameDisable}
				livescreen={livescreen}
			/>
			<Popup
				className="popup-content-container"
				open={isOpenDeleteComfirmation || isOpenChangeStatus}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={
					isOpenDeleteComfirmation && status === CONST.STATUS.published
						? CONST.publishedPopupStyles
						: CONST.popupContentStyle
				}>
				{isOpenDeleteComfirmation && (
					<DeleteConfirmation
						onClose={closeDeleteConfirmationModal}
						onDelete={onDeleteBucket}
						loading={onDeleteLoading}
						fromBucketListing={true}
						message={
							status === CONST.STATUS.published ? POPUP.PUBLISHED_MESSAGE : POPUP.MESSAGE
						}
					/>
				)}
				{isOpenChangeStatus && (
					<DeleteConfirmation
						onClose={closeDeleteConfirmationModal}
						onDelete={onPublishAndUnpublishBucket}
						loading={onStatusChangeLoading}
						fromBucketListing={true}
						message={
							archiveOperation === CONST.STATUS.unpublished
								? POPUP.UNPUBLISH_STATUS
								: archiveOperation === CONST.STATUS.archived
								? POPUP.ARCHIVE_STATUS
								: POPUP.PUBLISH_STATUS
						}
						buttonText={
							status !== CONST.STATUS.draft
								? `${
										archiveOperation === CONST.STATUS.archived
											? archiveOperation.slice(0, -1)
											: archiveOperation.slice(0, -2)
								  }`
								: 'Yes, Publish'
						}
					/>
				)}
			</Popup>
			{showToast.show ? (
				<Toast
					message={showToast.message}
					onDismiss={handleToastDismiss}
					isError={showToast.isError}
				/>
			) : null}
			{!openEditBucketModal && count > 1 && (
				<ScrollToTop handleScrollToTop={handleScrollToTop} />
			)}
		</div>
	);
};
