import React, { useState, useEffect } from "react";
import "./styles.scss";
import ThumbnailPicker from "../ThumbnailPicker";
import ExploreIcon from "../../../assets/logos/ExploreIcon.svg";

const CustomFileInputPopup = ({
  id,
  defaultValue,
  label,
  placeholder,
  handleValueChanges,
  toggleThumbnailPicker,
  isVisible,
  isEdit,
}) => {
  const [isIconSelected, setIsIconSelected] = useState(false);
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(defaultValue);

  useEffect(() => {
    setSelectedIcon(defaultValue);
    if (!defaultValue) setIsIconSelected(false);
  }, [defaultValue]);
  useEffect(() => {
    if (!isEdit) {
      toggleThumbnailPicker(isVisible || isPickerVisible);
    }
  }, [isPickerVisible, toggleThumbnailPicker, isVisible, isEdit]);

  const handleChange = (e) => {
    setSelectedIcon(e);
    handleValueChanges(id, e);
  };
  const handleIconSelection = (data, iconSelected = true) => {
    if (iconSelected) {
      setIsIconSelected(true);
      handleChange(data);
      setIsPickerVisible(!isPickerVisible);
    } else {
      setIsIconSelected(false);
      handleChange(null);
      setIsPickerVisible(!isPickerVisible);
    }
  };

  const IconNotSelected = () => {
    return (
      <span className={"file-input-span"}>
        <img src={ExploreIcon} alt="" />
        <p className={"input-box"}>{placeholder}</p>
      </span>
    );
  };
  const IconSelected = () => {
    return (
      <span className={"file-input-span"}>
        <img src={selectedIcon?.img} alt="" />
        <p className={"input-box"}>Icon Selected</p>
      </span>
    );
  };

  return (
    <div className={"custom-file-input-wrapper"}>
      <p className={"placeholder"}>{label}</p>
      <div
        className={"file-input-browse-wrapper"}
        onClick={() => setIsPickerVisible(true)}
      >
        {isVisible || isPickerVisible ? (
          <ThumbnailPicker
            handleIconSelection={handleIconSelection}
            defaultSelected={selectedIcon}
          />
        ) : isIconSelected ? (
          <IconSelected />
        ) : (
          <IconNotSelected />
        )}
      </div>
    </div>
  );
};
export default CustomFileInputPopup;
