import React, { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'src/app/components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import IconTextButton from 'src/app/components/atoms/IconTextButton';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import CustomSelectBox from 'src/app/components/atoms/CustomSelectBox';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import { Button } from 'src/app/components/Button';
import CreateBucket from 'src/app/components/CreateBucket';
import { youtubeVideoDetailsPopupStyle } from 'src/app/common/constants/PopupStyles';
import ShortformVideoPopup from 'src/app/components/atoms/ShortformVideoPopup';
import YoutubeCarousal from './YoutubeCarousal';
import { Toast } from 'src/app/components/Toast/index';
import HorizontalTile from 'src/app/components/atoms/HorizontalTile';
import { getCardSizes } from 'src/app/pages/Dashboard/actionCreators';
import {
	ROOT_URL,
	GET_CARD_SIZE,
	ADD_SHORTFORM,
	ERROR_MESSAGE,
} from 'src/app/common/constants/AppConstants';
import { youtubeData } from './youtubeDataMapper';
import EmptyImage from 'src/app/assets/images/empty-state.png';
import { putCommonMethod } from 'src/app/pages/Dashboard/Service';
import * as CONST from './constant';
import './styles.scss';

const YoutubeVideos = () => {
	const dispatch = useDispatch();
	const selectedBucket = useSelector((state) => state.reservations.selectedBucket);
	const cardSize = useSelector((state) => state.reservations.cardSizes);
	const searchRef = useRef('');
	const [searchText, setSearchText] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const debouncedValue = useDebounce(searchText, 500);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [bucketListUpdateFlag, setBucketListUpdateFlag] = useState(false);
	const [viewDetail, setViewDetail] = useState(false);
	const [videoDetailId, setVideoDetailId] = useState(null);
	const [addVideoId, setAddVideoId] = useState(null);
	const [hasMoreYTVideo, setHasMoreYTVideo] = useState(false);
	const [ytPageToken, setYtPageToken] = useState('');
	const [searchData, setSearchData] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!cardSize) {
			dispatch(getCardSizes(`${ROOT_URL}${GET_CARD_SIZE}`, {}));
		}
	}, []);

	useEffect(() => {
		if (addVideoId) {
			postAdd();
		}
	}, [addVideoId]);

	useEffect(() => {
		setSearchData([]);
		setTimeout(() => {
			const element = document.querySelector('#content-block-ytSearch');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
		setLoading(true);
		setYtPageToken('');
		if (ytPageToken === '') {
			fetchDataFromYt();
		}
	}, [debouncedValue]);

	useEffect(() => {
		fetchDataFromYt();
	}, [ytPageToken]);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setShowToast(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const fetchDataFromYt = async () => {
		if (debouncedValue && debouncedValue?.length > 0) {
			try {
				const params = {
					maxResults: CONST.RECORD_TO_FETCH,
					q: debouncedValue,
					...(hasMoreYTVideo &&
						ytPageToken?.length > 0 && {
							pageToken: ytPageToken,
						}),
				};
				const res = await youtubeData(params);
				if (res) {
					if (res?.length === 0) {
						setHasMoreYTVideo(false);
					} else {
						setHasMoreYTVideo(true);
					}
					if (ytPageToken && ytPageToken?.length > 0) {
						setSearchData([...searchData, ...res]);
					} else {
						setSearchData(res);
					}
				}
				// }
			} catch (error) {
			} finally {
				setLoading(false);
			}
		}
	};

	const fetchMoreData = () => {
		if (hasMoreYTVideo) {
			setYtPageToken(searchData[searchData?.length - 1]?.nextPageToken);
		}
	};

	const handleToast = (errorVal, message, showToast) => {
		setToastError(errorVal);
		setShowToastMessage(message);
		setShowToast(showToast);
	};

	const toggleModal = () => {
		setIsOpen(!isOpen);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const handleToastDismiss = () => {
		handleToast(false, '', false);
	};

	const openDetailPopup = (id) => {
		setViewDetail(true);
		setVideoDetailId(id);
	};

	const closeDetailPopup = () => {
		setViewDetail(false);
		setVideoDetailId(null);
	};

	const handleChange = (e) => {
		setTimeout(() => {
			const element = document.querySelector('.content-block');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 20);
		setSearchText(e.target.value);
	};

	const addVideoToBucket = (id) => {
		setAddVideoId(id);
	};

	const postAdd = async () => {
		if (selectedBucket?.bucket_id) {
			try {
				const res = await putCommonMethod(`${ROOT_URL}${ADD_SHORTFORM}`, {
					video_urls: [`https://youtu.be/${addVideoId}`],
					bucket_id: selectedBucket?.bucket_id,
				});
				if (res) {
					handleToast(false, CONST.SUCCESS_ADD, true);
				}
			} catch (error) {
				handleToast(true, error?.response?.data?.message || ERROR_MESSAGE, true);
			}
		} else {
			handleToast(true, 'Please select bucket to add video(s)', true);
		}
	};

	const emptySearchBox = () => {
		setLoading(true);
		setSearchData([]);
		setSearchText('');
		searchRef.current.value = '';
	};

	const newBucketCreated = ({ response, message }) => {
		setBucketListUpdateFlag(!bucketListUpdateFlag);
		if (message) {
			handleToast(false, message, true);
		} else if (response) {
			handleToast(true, response.data?.message, true);
		}
	};

	return (
		<div className="youtubeContainer">
			<div className="header-parent-block">
				<div className="header-block">
					<div>
						<h1 className="heading">{CONST.PAGE_HEADER}</h1>
					</div>
					<div className="dashboard-button-block">
						<IconTextButton
							buttonText="+ Create Bucket"
							handleClick={() => toggleModal()}
						/>
					</div>
				</div>
				<div className="search-box-block">
					<div className="live-search-with-cancel">
						<DashboardSearchBox
							value={searchText}
							handleChange={handleChange}
							className="live-channel-search"
							ref={searchRef}
							placeholder={CONST.SEARCHBAR_PLACEHOLDER}
						/>
						{searchText !== '' && (
							<Button
								label="Cancel"
								labelClassName="cancel-button-label"
								className="cancel-button"
								onClick={emptySearchBox}
							/>
						)}
					</div>

					<CustomSelectBox
						fromScreen="dashboard"
						handleToastDismiss={handleToastDismiss}
						updateFlag={bucketListUpdateFlag}
						idFieldName={'bucket_id'}
						ytCategory={true}
						enableDropdown
						searchEnable={true}
					/>
				</div>
			</div>
			<div className="content-block">
				{!debouncedValue &&
					debouncedValue?.length === 0 &&
					CONST.CAROUSAL_DATA.map((carousals, index) => {
						return (
							<YoutubeCarousal
								key={carousals?.id}
								data={carousals}
								compId={index}
								openDetailPopup={openDetailPopup}
								addVideo={addVideoToBucket}
							/>
						);
					})}
				{debouncedValue && debouncedValue?.length !== 0 && searchData?.length > 0 && (
					<div id="content-block-ytSearch" className="content-block-ytSearch">
						<InfiniteScroll
							dataLength={searchData?.length}
							next={fetchMoreData}
							hasMore={hasMoreYTVideo}
							loader={
								<div className="infinite-loader-container">
									<Loader loadMore={true} />
								</div>
							}
							endMessage={null}
							scrollableTarget="content-block-ytSearch">
							<div className="grid-container">
								{searchData?.length > 0 &&
									searchData?.map((movieItem, index) => {
										return (
											<HorizontalTile
												key={index}
												handleVideo={() => addVideoToBucket(movieItem?.videoId)}
												handleClick={() => openDetailPopup(movieItem?.videoId)}
												fromYt={true}
												tileDetails={{
													video_id: movieItem?.videoId,
													horizontal_thumbnail: movieItem?.thumbnail,
													title: movieItem?.title,
													description_alias: movieItem?.description,
												}}
												fromSearch={true}
											/>
										);
									})}
							</div>
						</InfiniteScroll>
					</div>
				)}
				{debouncedValue && debouncedValue?.length !== 0 && loading && (
					<div className="loader-wrap">
						<Loader />
					</div>
				)}
				{debouncedValue?.length > 2 && searchData.length === 0 && !loading && (
					<div className="empty-image">
						<img src={EmptyImage} alt="no results found" />
						<h3 className="no-filter-found">{CONST.EMPTY_STATE_MESSAGE}</h3>
					</div>
				)}
			</div>
			<CreateBucket
				isOpen={isOpen}
				closeModal={closeModal}
				toggleModal={toggleModal}
				newBucketCreated={newBucketCreated}
				fromYt={true}
			/>
			<Popup
				className="yt-video-popup"
				open={viewDetail}
				closeOnDocumentClick
				onClose={closeDetailPopup}
				contentStyle={youtubeVideoDetailsPopupStyle}>
				<ShortformVideoPopup id={videoDetailId} onClose={closeDetailPopup} />
			</Popup>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

export default YoutubeVideos;
