import React, { useState, useEffect } from 'react';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import TableContainer from './TableContainer/Table';
import { getCommonMethod, postCommonMethod } from '../Dashboard/Service';
import EmptyImage from 'src/app/assets/images/empty-state.png';
import IconTextButton from 'src/app/components/atoms/IconTextButton';
import { mapLiveStreamSubCategory } from './ApiDataMapper';
import BackArrowWhite from 'src/app/assets/images/BackArrowWhite.svg';
import * as CONST from './Constant';
import './styles.scss';

const ContentContainer = ({
	bucketId,
	handleToast,
	handleChangeOnEdit,
	offset,
	setOffset,
	loading,
	setLoading,
	selectedCards,
	setSelectedCards,
	handleDeleteChannelFromBucket,
	tableData,
	setTableData,
	setOpenAddNewChannel,
	deleteAll,
	setDeleteAll,
	unCheckedOnSelectOne,
	setUncheckedOnSelectOne,
	shuffle,
	handleShuffle,
	showSubCategoryData,
	subcategoryId,
	contentType,
	handleSubCategory,
	handleBack,
}) => {
	const [hasMore, setHasMore] = useState(false);
	const [enableSelection, setEnableSelection] = useState(false);

	useEffect(() => {
		setOffset(0);
	}, [bucketId]);

	// useEffect(() => {
	// 	if (subcategoryId) {

	// 	}
	// }, [showSubCategoryData]);

	useEffect(() => {
		if (subcategoryId) {
			fetchSubCategoryChannels();
		} else {
			fetchBucketLiveChannels();
		}
	}, [offset]);

	useEffect(() => {
		if (selectedCards.length > 0) {
			setEnableSelection(true);
		} else {
			setEnableSelection(false);
		}

		if (selectedCards?.length === tableData?.length && selectedCards?.length) {
			setDeleteAll(true);
			setUncheckedOnSelectOne(true);
		}
	}, [selectedCards]);

	useEffect(() => {
		fetchAllDeleteData(tableData, false);
	}, [deleteAll]);

	const fetchAllDeleteData = (data, fetchingMoreVideo) => {
		if (deleteAll) {
			let val = [];
			if (fetchingMoreVideo) {
				val = [...selectedCards];
			}
			data.map((movieItem) => {
				val.push({ id: movieItem.content_id.toString(), value: enableSelection });
			});
			setSelectedCards([...val]);
		}
	};

	const selectToDeleteAll = (e) => {
		if (e.target.checked) {
			setDeleteAll(true);
			setUncheckedOnSelectOne(true);
		} else {
			setDeleteAll(false);
			setUncheckedOnSelectOne(false);
			setSelectedCards([]);
		}
	};

	const unSelectAllSelectedItems = () => {
		if (selectedCards.length > 0) {
			selectedCards.forEach((card) => {
				const cardElement = document.getElementById(card.id);
				const cardContainer = document.getElementById(`card-container-${card.id}`);
				if (cardElement) {
					cardElement.checked = false;
				}
				if (cardContainer) {
					cardContainer.style.border = 'none';
				}
			});
		}
		setSelectedCards([]);
		setEnableSelection(false);
		setDeleteAll(false);
		setUncheckedOnSelectOne(false);
	};

	const addSingleChannelToBucket = async (content_id) => {
		try {
			const resp = await postCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_LIVE_CHANNEL_IN_BUCKET}`,
				{
					content_ids: [content_id],
					bucket_id: bucketId,
					content_type: 'live_stream',
				},
			);
			if (resp) {
				handleToast(resp?.data?.message, false, true);
			}
		} catch (error) {
			handleToast(GLOBAL_CONST.ERROR_MESSAGE, true, true);
		}
	};

	const onDeleteVideo = () => {};

	const onSelectingCards = (card) => {
		const copyOfSelectedCards = [...selectedCards];
		if (card) {
			const filteredCards = copyOfSelectedCards.filter((cardItem) => {
				return cardItem.id === card.id;
			});
			if (filteredCards.length === 0) {
				setSelectedCards([...copyOfSelectedCards, card]);
			} else {
				const index = copyOfSelectedCards.indexOf(filteredCards[0]);
				if (index >= 0) {
					copyOfSelectedCards.splice(index, 1);
					setSelectedCards(copyOfSelectedCards);
				}
			}
		}
	};

	const fetchSubCategoryChannels = async () => {
		try {
			const params = {
				offset: offset > 0 ? offset : 0,
				perPage: CONST.RECORD_TO_FETCH,
				live_channel_id: subcategoryId,
				orderBy: GLOBAL_CONST.ORDER_BY.sequence,
				orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
			};
			// if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
			// 	const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
			// 	params.searchTerm = modifiedDebounceValue;
			// }
			const response = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.LIVE_STREAM_BY_CHANNEL}`,
				params,
			);

			if (response) {
				const resp = mapLiveStreamSubCategory(response);
				if (offset > 0) {
					setTableData([...tableData, ...resp.data]);
					handleHasMore(resp);
				} else {
					setTableData(resp.data);
					handleHasMore(resp);
				}
			}
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};

	const fetchBucketLiveChannels = async () => {
		try {
			const res = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_BUCKET}`,
				{
					offset: offset > 0 ? offset : 0,
					limit: CONST.RECORD_TO_FETCH,
					bucket_id: bucketId,
				},
			);
			if (res) {
				if (offset > 0) {
					setTableData([...tableData, ...res?.data]);
					handleHasMore(res);
				} else {
					setLoading(true);
					setTableData(res?.data);
					handleHasMore(res);
				}
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const fetchMoreData = () => {
		setOffset(tableData?.length);
	};

	const handleHasMore = (response) => {
		if (
			response &&
			response.data &&
			response.data.length > 0 &&
			response.data.length === CONST.RECORD_TO_FETCH
		) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	return (
		<div className="content-container-wrapper">
			{!showSubCategoryData && contentType === CONST.CONTENT_TYPE.live_stream && (
				<div className={`${tableData?.length ? 'shuffle-mode' : 'shuffle-mode-none'} `}>
					<p className="ordinary">
						{shuffle
							? CONST.SHUFFLE_CONST.reorder_done
							: CONST.SHUFFLE_CONST.reorder_message}
					</p>
					<p className="highlighted" onClick={handleShuffle}>
						&nbsp;
						{shuffle ? CONST.SHUFFLE_CONST.click_back : CONST.SHUFFLE_CONST.click_shuffle}
					</p>
				</div>
			)}
			{showSubCategoryData && contentType === CONST.CONTENT_TYPE.live_channel && (
				<div className="backIcon" onClick={handleBack}>
					<img src={BackArrowWhite} alt={'Back'} className="arrow" />
				</div>
			)}
			{
				<TableContainer
					tableData={tableData}
					setTableData={setTableData}
					hasMore={hasMore}
					fetchMoreData={fetchMoreData}
					shuffle={shuffle}
					handleAddButton={() => {}}
					handleToast={handleToast}
					deleteAll={deleteAll}
					selectedCards={selectedCards}
					setSelectedCards={setSelectedCards}
					enableSelection={enableSelection}
					onSelecting={onSelectingCards}
					setUncheckedOnSelectOne={setUncheckedOnSelectOne}
					selectToDeleteAll={selectToDeleteAll}
					unCheckedOnSelectOne={unCheckedOnSelectOne}
					setDeleteAll={setDeleteAll}
					handleChangeOnEdit={handleChangeOnEdit}
					handleDeleteChannelFromBucket={handleDeleteChannelFromBucket}
					loading={loading}
					setLoading={setLoading}
					showSubCategoryData={showSubCategoryData}
					addSingleChannelToBucket={addSingleChannelToBucket}
					bucketId={bucketId}
					contentType={contentType}
					handleSubCategory={handleSubCategory}
				/>
			}
			{!loading && tableData?.length === 0 && (
				<div className="empty-state-container empty-image">
					<img src={EmptyImage} alt="no results found" />
					<h3 className="no-filter-found">
						{showSubCategoryData
							? CONST.EMPTY_STATE_MESSAGE.NO_SUBCATEGORY_DATA
							: contentType === CONST.CONTENT_TYPE.live_stream
							? CONST.EMPTY_STATE_MESSAGE.NO_DATA_FOUND
							: CONST.EMPTY_STATE_MESSAGE.NO_DATA_FOUND_LIVE_SUB}
					</h3>
					{!showSubCategoryData && (
						<div className="add-channel">
							<IconTextButton
								buttonText={`${
									contentType === CONST.CONTENT_TYPE.live_stream
										? 'Add Channels'
										: 'Add Sub-categories'
								}`}
								handleClick={() => setOpenAddNewChannel(true)}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ContentContainer;
