import { ADD_THUMBNAILS } from '../Dashboard/actionTypes';
import QEWD_DIGEST_INITIAL_STATE from './initialState';

const qewdDigestReducer = (state = QEWD_DIGEST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_THUMBNAILS:
			return {
				...state,
				thumbnails: action.payload,
			};
		default:
			return state;
	}
};

export default qewdDigestReducer;
