import {
	GET_REPORTED_VIDEO_DATA_INITIAL,
	GET_REPORTED_VIDEO_DATA,
	EDIT_DATA_ON_REPORTED_TABLE,
} from 'src/app/pages/Dashboard/actionTypes';
import REPORTED_VIDEOS_INITIAL_STATE from './initialState';

const reportedVideosReducer = (state = REPORTED_VIDEOS_INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_REPORTED_VIDEO_DATA_INITIAL:
			return {
				...state,
				reportedVideos: action.payload || [],
			};
		case GET_REPORTED_VIDEO_DATA:
			return {
				...state,
				reportedVideos: [...state.reportedVideos, ...action.payload],
			};
		case EDIT_DATA_ON_REPORTED_TABLE:
			const copyOfReportedVideos = [...state.reportedVideos];
			action?.payload.apiData.map((val) => {
				const index = copyOfReportedVideos.findIndex((item) => val === item?.video_id);
				copyOfReportedVideos.splice(index, 1);
			});
			return {
				...state,
				reportedVideos: copyOfReportedVideos,
			};
		default:
			return state;
	}
};

export default reportedVideosReducer;
