import React, { useState, useEffect } from 'react';
import './styles.scss';
import Check from '../../../assets/logos/Check.svg';
import { CAROUSEL_MORE_OPTIONS } from '../../../pages/Dashboard/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../Loader/index';
import GreenDot from '../../../assets/logos/GreenDot.svg';
import OrangeDot from '../../../assets/logos/OrangeDot.svg';
import RedDot from '../../../assets/logos/RedDot.svg';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { OTHER_THUMB_SIZE_MAPPER } from './constant';

const CustomOptionsBox = ({
	fromScreen,
	selectType,
	closeDropdown,
	options,
	handleSelect,
	defaultValue,
	handleRequestChange,
	selectedOption,
	idFieldName,
	labels,
	totalBucket,
	isfromLiveScreen,
	hasMoreCategory,
	bucketScreen = false,
	searchEnable,
	searchText,
	searchRef,
	handleChange,
	debouncedValue,
}) => {
	const [activeOption, setActiveOption] = useState('');
	// const [hasMore, setHasMore] = useState(true);
	const [menuItems, setMenuItems] = useState(options || CAROUSEL_MORE_OPTIONS);
	const [selectedOptions, setSelectedOptions] = useState(defaultValue);

	useEffect(() => {
		if (debouncedValue && debouncedValue?.length > 0) {
			searchRef.current.value = debouncedValue;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedValue]);

	const searchChange = (e) => {
		handleChange(e);
	};

	const getImage = (status) => {
		switch (status) {
			case 'published':
				return <img src={GreenDot} alt="green" className={'img-status'} />;
			case 'unpublished':
				return <img src={RedDot} alt="red" className={'img-status'} />;
			default:
				return <img src={OrangeDot} alt="orange" className={'img-status'} />;
		}
	};

	useEffect(() => {
		if (selectType === 'multi') setSelectedOptions(defaultValue);
		else setActiveOption(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		setMenuItems(options);
	}, [options]);

	const fetchMoreData = () => {
		handleRequestChange();
	};

	// triggers on onClick of single select li
	const handleOptionChange = (option) => {
		setActiveOption(option[idFieldName]);
		const arrowEl = document.getElementById('select-box-dashboard-down-arrow');
		arrowEl?.classList.remove('select-box-down-arrow-rotate');
		arrowEl?.classList.add('select-box-down-arrow-unrotate');
		if (handleSelect) handleSelect(option);
		if (closeDropdown) closeDropdown(false);
	};

	// triggers on onClick of single select li
	const handleMultiOptionChange = (option) => {
		let multipleOptionArray = [];
		// check if the clicked option is already selected
		if (isAlreadySelectedOption(option)) {
			// remove the already selected option from the list
			multipleOptionArray = selectedOptions.filter(
				(item) => item[idFieldName] !== option[idFieldName],
			);
		} else {
			// add the freshly selected option to the list
			multipleOptionArray = [...selectedOptions, option];
		}
		// set the unique elemental array to the selected options
		setSelectedOptions([...new Set(multipleOptionArray)]);
		if (handleSelect) handleSelect([...new Set(multipleOptionArray)]);
	};

	// checks wheter the provided option is already selected or not;
	const isAlreadySelectedOption = (option) => {
		let isAlreadySelected = false;
		Array.isArray(selectedOptions) &&
			selectedOptions?.forEach((_option) => {
				if (option[idFieldName] === _option[idFieldName])
					return (isAlreadySelected = true);
			});
		return isAlreadySelected;
	};

	// get the dorpdown width class
	const wrapperSizeClass = () => {
		switch (fromScreen) {
			case 'dashboard': {
				return 'dashboard-option-wrapper-size';
			}

			case 'popup': {
				return 'popup-option-wrapper-size';
			}

			case 'popupModal': {
				return 'popupModal-option-wrapper-size';
			}

			default:
				return 'option-wrapper-size';
		}
	};

	const renderMultipleSelection = (option, status) => {
		return (
			<li onClick={() => handleMultiOptionChange(option)}>
				<div className={'option-li-wrapper'}>
					<div>
						<p>{option.name}</p>
						{status && (
							<span>
								{getImage(status)} {status}
							</span>
						)}
					</div>
					{/* appends the check box if selected */}
					{isAlreadySelectedOption(option) && (
						<div>
							<img src={Check} alt="check" />
						</div>
					)}
				</div>
			</li>
		);
	};

	const singleSelect = () => (
		<div className={`option-box-wrapper ${wrapperSizeClass()}`}>
			{searchEnable && (
				<div className="searchWrapper">
					<DashboardSearchBox
						value={searchText}
						handleChange={searchChange}
						className="dropdown-search"
						ref={searchRef}
						placeholder={'Search bucket'}
					/>
				</div>
			)}
			<ul>
				<InfiniteScroll
					dataLength={menuItems?.length}
					next={fetchMoreData}
					className="infinite-scroll-option-box"
					hasMore={isfromLiveScreen ? hasMoreCategory : options.length < totalBucket}
					loader={<Loader loadMore />}
					height={isfromLiveScreen ? 300 : labels ? 250 : bucketScreen ? 480 : null}>
					{labels && labels.length > 0
						? labels.map((label, index) => {
								return (
									<div className="list-items" key={index}>
										<div className="label">{label}</div>
										<div>
											{menuItems?.map((option) => {
												if (
													option.name.split('-')[0] === label.split(' ')[0].toLowerCase()
												) {
													return (
														<li
															key={option.id || option?.live_category_id}
															className={
																activeOption === option[idFieldName]
																	? 'active-option'
																	: null
															}
															onClick={() => handleOptionChange(option)}>
															<p className="list-items-name">
																{option.name.split('-')[1]}
															</p>
														</li>
													);
												} else if (
													label === 'Others' &&
													option.name.split('-')[0] !== 'vertical' &&
													option.name.split('-')[0] !== 'horizontal'
												) {
													return (
														<li
															key={option.id || option?.live_category_id}
															className={
																activeOption === option[idFieldName]
																	? 'active-option'
																	: null
															}
															onClick={() => handleOptionChange(option)}>
															<p className="list-items-name">
																{OTHER_THUMB_SIZE_MAPPER[option.name] || option.name}
															</p>
														</li>
													);
												}
												return null;
											})}
										</div>
									</div>
								);
						  })
						: menuItems?.map((option) => {
								return (
									<li
										key={option[idFieldName]}
										className={
											activeOption === option[idFieldName] ? 'active-option' : null
										}
										onClick={() => handleOptionChange(option)}>
										{(selectedOption && selectedOption[idFieldName]) ===
										option[idFieldName] ? (
											<p style={{ color: '#F82C5E' }}>{option.name}</p>
										) : (
											<p>{option.name}</p>
										)}
									</li>
								);
						  })}
				</InfiniteScroll>
			</ul>
		</div>
	);

	const multiSelect = () => (
		<div className={`option-box-wrapper ${wrapperSizeClass()}`}>
			{searchEnable && (
				<div className="searchWrapper">
					<DashboardSearchBox
						value={searchText}
						handleChange={searchChange}
						className="dropdown-search"
						ref={searchRef}
						placeholder={'Search Bucket'}
					/>
				</div>
			)}
			<ul>
				<InfiniteScroll
					dataLength={menuItems?.length}
					next={fetchMoreData}
					className="infinite-scroll-option-box"
					hasMore={isfromLiveScreen ? hasMoreCategory : options.length < totalBucket}
					loader={<Loader loadMore />}
					height={isfromLiveScreen ? 300 : labels ? 250 : null}>
					{menuItems?.map((option) => {
						const item = defaultValue.filter((item) => item.name === option.name);
						const status = item && item.length > 0 && item[0].status;
						{
							return renderMultipleSelection(option, status);
						}
					})}
				</InfiniteScroll>
			</ul>
		</div>
	);

	if (selectType === 'multi') {
		return multiSelect();
	} else {
		return singleSelect();
	}
};

export default CustomOptionsBox;
