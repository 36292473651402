import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import {
	addUrlPopupStyle,
	createChannelPopupStyle,
} from 'src/app/common/constants/PopupStyles';
import { ButtonLoader } from '../ButtonLoader';
import { ADD_URL_POPUP } from './constant';
import CustomFilePicker from 'src/app/components/atoms/CustomFilePicker';
import * as CONST from 'src/app/components/CreateLiveChannelPopup/constants';
import './style.scss';
import { Line } from 'rc-progress';

export const AddUrlPopup = ({
	isOpen,
	closeModal,
	message,
	channelName,
	onChangeName,
	channelUrl,
	onChangeUrl,
	onCancel,
	onAdd,
	isDisable,
	loading,
	buttonText,
	urlNotValid,
	isEdit,
	setIconKey,
	thumbnailHeading,
	thumbnailText,
	iconKey = null,
	thumbnail,
	fromCategoryScreen = false,
}) => {
	const [progress, setProgress] = useState(0);
	const [finalProgress, setFinalProgress] = useState(0);
	const [uploadingError, setUploadingError] = useState(false);
	const [uploadErrorMessage, setUploadErrorMessage] = useState(
		ADD_URL_POPUP.UPLOAD_URL_ERROR,
	);
	const [disableButton, setDisableButton] = useState(!isDisable);

	useEffect(() => {
		finalProgress > 0 ? setDisableButton(true) : setDisableButton(!isDisable);
		if (finalProgress < 0) {
			setDisableButton(true);
		}
	}, [finalProgress, isDisable]);

	return (
		<Popup
			className="add-url-popup"
			open={isOpen}
			closeOnDocumentClick
			onClose={closeModal}
			contentStyle={createChannelPopupStyle}>
			<div className="add-url-popup-content-container">
				<div className="add-url-popup-message">{message}</div>
				<div className="add-url-popup-seperator"></div>
				<div className="add-url-inputs">
					<div className="add-url-channel-name">{ADD_URL_POPUP.CHANNEL}</div>
					<input
						placeholder="Enter Channel Name"
						className={`add-url-name-input`}
						type="text"
						value={channelName}
						onChange={onChangeName}
					/>
					<div className="add-url-name">{ADD_URL_POPUP.URL}</div>
					<input
						placeholder="Paste Your Url Below"
						className="add-url-input"
						type="text"
						value={channelUrl}
						onChange={onChangeUrl}
					/>
					{urlNotValid && (
						<div className="error">Invalid Url format. Use .m3u8 format only.</div>
					)}
				</div>
				<div
					className={`create-live-popup-file-picker ${
						uploadingError
							? fromCategoryScreen
								? 'file-picker-on-error-cat'
								: 'file-picker-on-error'
							: ''
					} ${fromCategoryScreen ? 'upload-margin' : ''}`}>
					<CustomFilePicker
						setIconKey={setIconKey}
						setProgress={setProgress}
						setFinalProgress={setFinalProgress}
						setUploadingError={setUploadingError}
						setUploadErrorMessage={setUploadErrorMessage}
						label={thumbnailHeading}
						uploadMessage={thumbnailText}
						fromCategoryScreen={fromCategoryScreen}
						iconKey={iconKey}
						isEdit={isEdit}
						thumbnail={thumbnail}
					/>
					{finalProgress > 0 ? (
						<div className={`reate-live-popup-progressbar`}>
							{fromCategoryScreen ? (
								<Line
									percent={progress * CONST.PROGRESS_BAR_PERCENTAGE}
									{...CONST.PROGRESS_BAR_CATEGORY}
								/>
							) : (
								<Line
									percent={progress * CONST.PROGRESS_BAR_PERCENTAGE}
									{...CONST.PROGRESS_BAR}
								/>
							)}
						</div>
					) : null}
					{uploadingError && <div className="upload-error-div">{uploadErrorMessage}</div>}
				</div>
				<div className="buttons">
					<button onClick={onCancel} className="cancel">
						{ADD_URL_POPUP.CANCEL}
					</button>
					{loading ? (
						<ButtonLoader />
					) : (
						<button
							onClick={() => {
								setUploadingError(false);
								onAdd();
							}}
							className={`${disableButton ? 'disable-button' : ''}`}>
							{buttonText || ADD_URL_POPUP.ADD}
						</button>
					)}
				</div>
			</div>
		</Popup>
	);
};
