import React, { useRef, useState, useEffect } from 'react';
import BigCard from 'src/app/components/BigCard';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import { AppButton } from 'src/app/components/AppButton';
import EditImage from 'src/app/assets/images/Edit.png';
import DeleteButton from 'src/app/assets/images/delete.png';
import { HEADING } from '../constant';
import { FrequencyPopup } from 'src/app/components/FrequencyPopup';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'src/app/components/Toast';
import Popup from 'reactjs-popup';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import { deleteConfirmationPopupStyle } from 'src/app/common/constants/PopupStyles';
import { ADD_THUMBNAILS } from '../../Dashboard/actionTypes';
import { EditDigestModal } from 'src/app/components/Modals/EditDigestModal';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import {
	overlayStyle,
	BUCKET_DETAIL_URL,
	ROOT_URL,
} from 'src/app/common/constants/AppConstants';
import { PreviewPopup } from 'src/app/components/PreviewPopup';
import { getCommonMethod } from 'src/app/pages/Dashboard/Service';
import { DEFAULT_MESSAGE__BODY } from 'src/app/pages/QwedDigest/AutoPopulate/constant';

export const PreviewScreen = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const searchRef = useRef('');
	const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
	const [selectedId, setSelectedId] = useState(null);
	const [showToast, setToast] = useState({ message: '', isError: false, show: false });
	const [toggleFrequncyPopup, setToggleFrequncyPopup] = useState(false);
	const thumbnails = useSelector(({ qewdDigest }) => qewdDigest.thumbnails);
	const [openModal, setOpenModal] = useState(false);
	const [openPreviewModal, setOpenPreviewModal] = useState(false);
	const [updateBigCards, setUpdateBigCard] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [descData, setDescData] = useState(DEFAULT_MESSAGE__BODY);

	const fetchQewdDesc = async () => {
		try {
			const res = await getCommonMethod(`${ROOT_URL}${BUCKET_DETAIL_URL}`, {
				bucket_id: process.env.REACT_APP_QEWD_DIGEST_BUCKET_ID,
			});
			if (res?.data?.description) {
				setDescData(res?.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchQewdDesc();
	}, []);
	const handleChange = (e) => {
		searchRef.current = e.target.value;
	};

	const toggleFrequencyPopup = (value) => {
		setToggleFrequncyPopup(value);
	};

	useEffect(() => {
		if (showToast.show) {
			const interval = setInterval(() => {
				setToast({ show: false, message: '' });
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const redirectToManualScreen = () => {
		history.goBack();
	};

	const onSelection = (id) => {
		if (id) {
			setSelectedId(id);
		} else {
			setSelectedId(null);
		}
	};

	const toggleDeleteConfirmation = (value) => {
		setToggleConfirmationModal(value);
	};

	const handleEditModal = () => {
		setOpenModal(true);
	};

	const onDelete = () => {
		const copyOfData = [...thumbnails];
		const index = copyOfData.findIndex((item) => item.id === selectedId);
		if (index !== -1) {
			copyOfData.splice(index, 1);
		}
		dispatch({ type: ADD_THUMBNAILS, payload: copyOfData });
		toggleDeleteConfirmation(false);
	};

	const openPreviewForMobile = () => {
		setOpenPreviewModal(true);
	};

	return (
		<div className="preview-screen-container">
			<div className="preview-screen-heading">
				<h1>{HEADING}</h1>
				<AppButton
					label={`Publish Draft`}
					className="publish-qwed-digest"
					innerClass="publish-qwed-digest-text"
					onClick={() => toggleFrequencyPopup(true)}
				/>
			</div>
			<div className="search-box-container">
				<div className="search-with-cancel">
					<DashboardSearchBox
						handleChange={handleChange}
						className="qwed-digest-search"
						ref={searchRef}
						digestButton
					/>
					{/* {searchRef.current && (
						<Button
							label="Cancel"
							labelClassName="cancel-button-label"
							className="cancel-button"
							onClick={emptySearchBox}
						/>
					)} */}
				</div>
				<div className="header-buttons">
					<Tooltip
						overlay={`Edit ${process.env.REACT_APP_QEWD_DIGEST_TITLE}`}
						placement="bottom"
						overlayInnerStyle={overlayStyle}
						align={{
							offset: [8, -2],
						}}>
						<div>
							<Button
								imageInfo={{
									src: EditImage,
								}}
								className="edit-button"
								onClick={handleEditModal}
							/>
						</div>
					</Tooltip>

					<AppButton
						label={`Preview ${process.env.REACT_APP_QEWD_DIGEST_TITLE}`}
						className="preview-qwed-digest"
						innerClass="preview-qwed-digest-text"
						onClick={openPreviewForMobile}
					/>
					{thumbnails && thumbnails.length < 10 && (
						<AppButton
							label="Add More +"
							className="preview-qwed-digest"
							innerClass="preview-qwed-digest-text"
							onClick={redirectToManualScreen}
						/>
					)}
				</div>
			</div>

			<EditDigestModal
				isOpen={openModal}
				setIsOpen={setOpenModal}
				setUpdateBigCard={setUpdateBigCard}
				isFrequency={true}
				fromPreviewScreen={true}
				descData={descData}
				setDescData={setDescData}
			/>

			<PreviewScreenContent
				thumbnails={thumbnails}
				onSelection={onSelection}
				toggleDeleteConfirmation={toggleDeleteConfirmation}
			/>
			<FrequencyPopup
				isOpen={toggleFrequncyPopup}
				closeModal={() => toggleFrequencyPopup(false)}
				message={`Are you sure you want to publish ${process.env.REACT_APP_QEWD_DIGEST_TITLE}?`}
				setToast={setToast}
			/>
			<Popup
				open={toggleConfirmationModal}
				closeOnDocumentClick
				onClose={() => toggleDeleteConfirmation(false)}
				contentStyle={deleteConfirmationPopupStyle}>
				<DeleteConfirmation
					message="Are you sure you want to delete this video?"
					onClose={() => toggleDeleteConfirmation(false)}
					onDelete={onDelete}
				/>
			</Popup>
			{showToast.show ? (
				<Toast
					message={showToast.message}
					onDismiss={() => setToast({ message: '', isError: false, show: false })}
					isError={showToast.isError}
				/>
			) : null}
			<PreviewPopup
				isOpen={openPreviewModal}
				closeModal={() => setOpenPreviewModal(false)}
				descData={descData}
			/>
		</div>
	);
};

const PreviewScreenContent = ({ thumbnails, onSelection, toggleDeleteConfirmation }) => {
	return (
		<div className="preview-screen-content-container">
			<div className="cards">
				{thumbnails &&
					thumbnails.length > 0 &&
					thumbnails.map((item, index) => {
						return (
							<BigCard
								key={item.id}
								cardDetails={{
									...item,
									horizontal_thumbnail: item.thumbnail,
								}}
								index={index}
								onSelection={onSelection}
								id={item.id}
								toggleDeleteConfirmation={toggleDeleteConfirmation}
								noTap
							/>
						);
					})}
			</div>
		</div>
	);
};
