import React, { useState, useRef } from 'react';
import './styles.scss';
import CustomInputPopup from 'src/app/components/atoms/CustomInputPopup';
import TagPills from 'src/app/components/atoms/TagPills';
import * as CONST from './constant';

export const CustomAddTags = ({ tagName, setTagName }) => {
	const [errorState, setErrorState] = useState(false);
	const messagesEndRef = useRef(null);

	const handleValueChange = (field, e) => {};

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const handleEnterKeyPress = (e) => {
		const tag_value = e.target.value?.trim();
		if (tag_value?.length > 1) {
			setErrorState(false);
			if (!tagName.includes(e.target.value)) {
				setTagName([e.target.value, ...tagName]);
			}

			setTimeout(() => {
				scrollToBottom();
			}, 200);
		} else {
			setErrorState(true);
		}
	};

	const handleCrossPress = (e) => {
		let newTag = [...tagName];
		const index = newTag.indexOf(e.target.id);
		newTag.splice(index, 1);
		setTagName(newTag);
	};

	return (
		<div className="tags-wrapper">
			<CustomInputPopup
				label="ADD TAGS"
				defaultValue={''}
				handleValueChanges={handleValueChange}
				placeholder={CONST.PLACEHOLDER}
				hasError={false}
				errorVerbose=""
				id="tags"
				isDisable={false}
				onEnter={true}
				handleEnterKeyPress={handleEnterKeyPress}
			/>
			{errorState && (
				<div className="error-state-field ">
					<p>{CONST.ERROR_MESSAGE}</p>
				</div>
			)}
			<div className="pill-block-popup">
				{tagName.map((items, index) => {
					return (
						<TagPills
							key={index}
							id={items}
							data={items}
							crossIcon={true}
							handleCrossPress={handleCrossPress}
						/>
					);
				})}
			</div>
			<div ref={messagesEndRef} />
		</div>
	);
};

export default CustomAddTags;
