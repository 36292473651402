const published = {
  heading: "Published Buckets",
};

export default published;

export const popupContentStyle = {
  width: 570,
  background: "#2A2A31",
  boxShadow: "0px 18px 26px 0px #00000059",
  borderRadius: 12,
  margin: "auto",
};

export const popupContentStyleOnCalendarOpen = {
  width: 570,
  background: "#2A2A31",
  boxShadow: "0px 18px 26px 0px #00000059",
  borderRadius: 12,
  margin: "auto",
};

export const deleteConfirmationModalStyle = {
  width: 327,
  background: "#2A2A31",
  marginTop: 300,
  boxShadow: "0px 18px 26px 0px #00000059",
  borderRadius: 12,
  paddingBottom: '20px',
};