import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
	LOADING_COLOR,
	HIGHLIGHTING_COLOR,
} from '../../common/constants/BucketListingConstant';
export const SkeletonLoader = ({ width, height }) => {
	return (
		<SkeletonTheme color={LOADING_COLOR} highlightColor={HIGHLIGHTING_COLOR}>
			<Skeleton width={width} height={height} />
		</SkeletonTheme>
	);
};
