import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	createBucket,
	getCategories,
	getScreens,
	getSubSectionData,
	updateBucketDetails,
	replaceAllBucket,
} from '../../../pages/Dashboard/actionCreators';
import Close from '../../../assets/logos/Close.svg';
import CustomInputPopup from '../../atoms/CustomInputPopup';
import CustomDateRangePopup from '../../atoms/CustomDateRangePopup';
import CustomSelectPopup from '../../atoms/CustomSelectPopup';
import CustomFileInputPopup from '../../atoms/CustomFileInputPopup';
import PrimaryTextButton from '../../atoms/PrimaryTextButton';
import ResetAllButton from '../../atoms/ResetAllButton';
import ClickAwayListener from 'react-click-away-listener';
import {
	BUCKET_DETAIL_URL,
	BUCKET_URL,
	DATE_FORMATS,
	GET_CATEGORY,
	ROOT_URL,
	SCREEN_URL,
} from '../../../common/constants/AppConstants';
import { useCallback } from 'react';
import POPUP_CONSTANT, {
	DISCOVER_STATUS,
	HOME_STATUS,
	LABELS,
	PUBLISH_DESC,
	SCREEN_CONTENT_MAPPER,
	RANKED_BUCK,
} from './constant';
import moment from 'moment';
import { IconList } from '../../../components/atoms/ThumbnailPicker/constants';
import CustomRadioGroup from 'src/app/components/CustomRadioGroup';
import AddButton from 'src/app/components/AddButton';
import CustomAddTags from 'src/app/components/atoms/CustomAddTags';
import _ from 'lodash';

const USER_ID = '00139af8-c334-4c69-a180-a7e010e2359c';

const PopupModal = ({
	handleClose,
	show,
	toggleCalendar,
	toggleThumbnailPicker,
	newBucketCreated,
	isEdit,
	bucketId,
	isDisable,
	fromYt,
	livescreen,
}) => {
	const dispatch = useDispatch();
	const screensSelector = useSelector((state) => state.reservations.screens);
	const cardSizes = useSelector((state) => state.reservations?.cardSizes);
	const categories = useSelector((state) => state.reservations?.categories);
	const [categoryLabels, setCategoriesLabels] = useState([]);
	const [cardSizesItems, setCardSizesItems] = useState();
	const [bucketName, setBucketName] = useState('');
	const [screenNames, setScreenNames] = useState([]);
	const [thumbnail, setThumbnail] = useState(null);
	const [dateRange, setDateRange] = useState(null);
	const [errorState, setErrorState] = useState([]);
	const [showBucketCreateLoader, setShowBucketCreateLoader] = useState(false);
	const [cardSize, setCardSize] = useState();
	const [category, setCategory] = useState([]);
	const [statusScreens, setStatusScreens] = useState([]);
	const [switchValue, setSwitchValue] = useState();
	const [defaultThumbSize, setDefaultThumbSize] = useState({
		name: 'vertical-medium',
	});
	const [screenTo, setScreenTo] = useState({ home: [], discover: [] });
	const [ifAddButtonClicked, setIfAddButtonClicked] = useState(false);
	const [ifHomeAddClicked, setHomeAddClicked] = useState(false);
	const [resetThumb, setResetThumb] = useState({
		name: '',
	});
	const screen = useSelector((state) => state.reservations.screens);
	const [tagName, setTagName] = useState([]);
	const [bucketDesc, setBucketDesc] = useState('');
	const [publishDesc, setPublishDesc] = useState(false);
	const [letterCount, setLetterCount] = useState(0);
	const textarea = document.querySelector('#autoresizing');
	const [initialState, setInitialState] = useState(null);
	const [disableOnEdit, setDisableOnEdit] = useState(true);
	const [liveSceenData, setLiveScreenData] = useState([]);
	const [menuCategoryData, setMenuCategoryData] = useState(categoryLabels);
	const [markedAsRanked, setMarkedAsRanked] = useState(false);

	useEffect(() => {
		if (!bucketDesc || bucketDesc?.length < 1) {
			setPublishDesc(false);
		}
	}, [bucketDesc]);

	useEffect(() => {
		if (screen.length === 0) {
			dispatch(getScreens(`${ROOT_URL}${SCREEN_URL}`));
		}
	}, [dispatch]);

	useEffect(() => {
		const filterCategories = categories.filter(
			(category) => category.name.toLowerCase() !== 'all',
		);
		setCategoriesLabels(filterCategories);
		setMenuCategoryData(filterCategories);
		if (!isEdit) {
			setCategory(categories[0]);
		}
	}, [categories]);

	useEffect(() => {
		screenCategoryFilterSelector();
	}, [screenNames, categoryLabels]);

	const screenCategoryFilterSelector = () => {
		if (screenNames[0]?.screen_id === 4) {
			const arr = categoryLabels?.filter(
				(item) => item?.general_category_id === 5 || item?.general_category_id === 3,
			);
			arr.sort(function (a, b) {
				return a?.general_category_id - b?.general_category_id;
			});
			const index = arr.findIndex((item) => {
				return item?.general_category_id === category?.general_category_id;
			});

			if (index === -1) {
				if (isEdit) {
					setCategory({
						content_type: arr[0]?.slug,
						general_category_id: SCREEN_CONTENT_MAPPER[arr[0]?.slug]?.general_category_id,
						name: SCREEN_CONTENT_MAPPER[arr[0]?.slug]?.name,
					});
				} else {
					setCategory(arr[0]);
				}
			}
			setMenuCategoryData(arr);
		} else {
			const arr = categoryLabels?.filter(
				(item) => item?.general_category_id !== 5 && item?.general_category_id !== 3,
			);
			arr.sort(function (a, b) {
				return a?.general_category_id - b?.general_category_id;
			});
			const index = arr.findIndex((item) => {
				return item?.general_category_id === category?.general_category_id;
			});
			if (index === -1) {
				if (isEdit) {
					setCategory({
						content_type: arr[0]?.slug,
						general_category_id: SCREEN_CONTENT_MAPPER[arr[0]?.slug]?.general_category_id,
						name: SCREEN_CONTENT_MAPPER[arr[0]?.slug]?.name,
					});
				} else {
					setCategory(arr[0]);
				}
			}
			setMenuCategoryData(arr);
		}
	};

	const setDefaultScreenName = useCallback(
		(name = 'Home') => {
			if (typeof screensSelector === 'object' && screensSelector?.data?.length > 0) {
				setScreenNames(screensSelector?.data.filter((screen) => screen.name === name));
			}
		},
		[screensSelector],
	);

	useEffect(() => {
		if (!isEdit) {
			setDefaultScreenName();
		}
	}, [screensSelector, setDefaultScreenName, isEdit]);

	useEffect(() => {
		if (isEdit) {
			getSubSectionData(
				`${ROOT_URL}${BUCKET_DETAIL_URL}`,
				{
					bucket_id: bucketId,
				},
				(response) => {
					if (response && response.data) {
						const data = response.data;
						const ifDiscoverPresentInScreen = data?.screens.find(
							(screen) => screen.screen_id === 2,
						);
						const ifHomePresentInScreen = data?.screens.find(
							(screen) => screen.screen_id === 1,
						);
						const cardVal = Object.entries(cardSizes).filter(
							(cardSize) => cardSize[1] === data?.size,
						);
						if (ifDiscoverPresentInScreen) {
							setIfAddButtonClicked(true);
						}
						if (ifHomePresentInScreen) {
							setHomeAddClicked(true);
						}
						setScreenNames(data?.screens);
						let newTags = [];
						data?.tags?.map((items) => {
							newTags.push(items?.tag_name);
						});
						setTagName(newTags);
						setBucketName(data?.name);
						setBucketDesc(data?.description);
						setPublishDesc(data?.is_description_published === 1 ? true : false);
						setMarkedAsRanked(data?.is_ranked_fashion === 1 ? true : false);
						setLetterCount(data?.description?.length || 0);
						setCardSize({
							name: cardVal[0][0],
							value: cardVal[0][1],
							id: cardVal[0][0],
						});

						if (data && data.content_type) {
							setCategory([
								{
									content_type: data?.content_type,
									general_category_id:
										SCREEN_CONTENT_MAPPER[data?.content_type]?.general_category_id,
									name: SCREEN_CONTENT_MAPPER[data?.content_type]?.name,
								},
							]);
						}
						const homeScreens = data?.screens.filter((screen) => screen.name === 'Home');
						const discoverScreens = data?.screens.filter(
							(screen) => screen.name === 'Discover',
						);
						const liveScreens = data?.screens.filter(
							(screen) => screen.name === 'Live TV',
						);
						setLiveScreenData(liveScreens || []);
						setScreenTo({ home: homeScreens, discover: discoverScreens });

						const val = moment(data?.to_date).utc().format('ddd MMM DD YYYY ');
						const proposedDate = val + '00:00:00';
						const val2 = moment(data?.from_date).utc().format('ddd MMM DD YYYY ');
						const proposedDate2 = val2 + '00:00:00';

						setDateRange({
							endDate: data?.to_date ? proposedDate : null,
							startDate: data?.from_date ? proposedDate2 : null,
						});

						setStatusScreens(data?.screens);
						if (cardSizes && data && data.size) {
							setDefaultThumbSize({
								name: Object.entries(cardSizes).filter(
									(cardSize) => cardSize[1] === data?.size,
								)[0][0],
							});
							setResetThumb({
								name: Object.entries(cardSizes).filter(
									(cardSize) => cardSize[1] === data?.size,
								)[0][0],
							});
						}
						let iconId = 0;
						if (IconList && IconList.length > 0) {
							const iconlist = IconList.filter((icon) => icon.img === data?.icon);
							if (iconlist.length > 0) {
								iconId = iconlist[0].id;
							}
						}
						setThumbnail({
							id: iconId,
							img: data?.icon,
							addRightMargin: true,
						});

						setInitialState({
							bucket_id: data?.bucket_id,
							name: data?.name,
							tag_name: newTags,
							description: data?.description,
							is_description_published:
								data?.is_description_published === 1 ? true : false,
							from_date: data?.from_date,
							to_date: data?.to_date,
							icon: data?.icon,
							size: {
								name: cardVal[0][0],
								value: cardVal[0][1],
								id: cardVal[0][0],
							},
							is_ranked_fashion: data?.is_ranked_fashion === 1 ? true : false,
							date_range: {
								endDate: data?.to_date ? proposedDate : null,
								startDate: data?.from_date ? proposedDate2 : null,
							},
							thumbnail: {
								id: iconId,
								img: data?.icon,
								addRightMargin: true,
							},
							screen_in: data?.screens,
							category: [
								{
									content_type: data?.content_type,
									general_category_id:
										SCREEN_CONTENT_MAPPER[data?.content_type]?.general_category_id,
									name: SCREEN_CONTENT_MAPPER[data?.content_type]?.name,
								},
							],
							screen_to: { home: homeScreens, discover: discoverScreens },
						});
					}
				},
			);
		}
	}, [bucketId, cardSizes, isEdit]);

	const handleValueChange = (field, e) => {
		switch (field) {
			case 'bucketName': {
				setBucketName(e);
				break;
			}
			case 'screenNames': {
				setScreenNames([e]);
				break;
			}
			case 'dateRange': {
				setDateRange(e);
				break;
			}
			case 'thumbnail': {
				setThumbnail(e);
				break;
			}
			case 'cardSize':
				setCardSize(e);
				break;
			case 'category':
				setCategory([
					{
						general_category_id: e?.general_category_id,
						name: e?.name,
						content_type: e?.slug,
					},
				]);
				break;
			case 'bucketDescription':
				setBucketDesc(e);
				break;
			default:
				break;
		}
	};

	// we change every value of '' to null.
	const mapEmptyValueToNull = (object) => {
		if (object === '') {
			return null;
		}
		return object;
	};

	useEffect(() => {
		if (isEdit && initialState) {
			const start_date = dateRange?.startDate?.toString().slice(0, 24);
			const end_date = dateRange?.endDate?.toString().slice(0, 24);
			const obj = {
				endDate: end_date || null,
				startDate: start_date || null,
			};
			if (
				!_.isEqual(initialState.name, bucketName) ||
				!_.isEqual(
					mapEmptyValueToNull(initialState?.description),
					mapEmptyValueToNull(bucketDesc),
				) ||
				!_.isEqual(initialState?.tag_name, tagName) ||
				!_.isEqual(initialState?.screen_to, screenTo) ||
				!_.isEqual(initialState?.category, category) ||
				!_.isEqual(initialState?.size?.value, cardSize?.value) ||
				!_.isEqual(
					mapEmptyValueToNull(initialState?.thumbnail.img),
					mapEmptyValueToNull(thumbnail.img),
				) ||
				!_.isEqual(initialState.date_range, obj) ||
				!_.isEqual(initialState?.screen_in, screenNames) ||
				!_.isEqual(initialState.is_description_published, publishDesc) ||
				!_.isEqual(initialState.is_ranked_fashion, markedAsRanked)
			) {
				setDisableOnEdit(false);
			} else {
				setDisableOnEdit(true);
			}
		}
	}, [
		bucketDesc,
		bucketName,
		category,
		cardSize,
		thumbnail,
		dateRange,
		screenNames,
		publishDesc,
		screenTo,
		tagName,
		markedAsRanked,
	]);

	const handleCheck = (e) => {
		if (e.target.checked === false) {
			setPublishDesc(false);
		}
		if (e.target.checked === true) {
			setPublishDesc(true);
		}
	};

	const handleRankedBucket = (e) => {
		if (e.target.checked === false) {
			setMarkedAsRanked(false);
		}
		if (e.target.checked === true) {
			setMarkedAsRanked(true);
		}
	};

	const handleResetClick = () => {
		setErrorState([]);
		setBucketName('');
		setBucketDesc('');
		setTimeout(() => {
			textarea.value = '';
			textarea.style.height = 32 + 'px';
		}, 200);
		setPublishDesc(false);
		setMarkedAsRanked(false);
		setLetterCount(0);
		setScreenTo({ home: [], discover: [] });
		setDefaultScreenName();
		setDateRange(null);
		setDefaultThumbSize(resetThumb);
		setCategory([]);
		setTagName([]);
	};

	const checkValidations = () => {
		let errors = [];
		if (bucketName === '') {
			errors.push('bucketName');
		}
		if (screenNames.length === 0) {
			errors.push('screenNames');
		}
		setErrorState(errors);
		if (errors.length > 0) {
			return false;
		} else {
			return true;
		}
	};
	const getForm = () => {
		const processedScreenNames = screenNames?.map((screen) => {
			delete screen.__v;
			// delete screen
			return { screen_id: screen.screen_id };
		});

		const localTime1 = moment(dateRange?.startDate).format('YYYY-MM-DD'); // store localTime
		const startDate = dateRange?.startDate ? localTime1 + 'T00:00:00.000Z' : null;

		const localTime2 = moment(dateRange?.endDate).format('YYYY-MM-DD'); // store localTime
		const endDate = dateRange?.endDate ? localTime2 + 'T00:00:00.000Z' : null;

		let formObj = {
			name: bucketName,
			screens: processedScreenNames,
			from_date: startDate,
			to_date: endDate,
			icon: thumbnail?.img,
			size: cardSize?.value ?? cardSizes[defaultThumbSize.name],
			description: bucketDesc,
			is_description_published: publishDesc,
			content_type: category[0]?.content_type
				? category[0]?.content_type
				: processedScreenNames[0]?.screen_id === 4
				? 'live_stream'
				: 'movie_show_video',
			is_ranked_fashion: markedAsRanked,
		};
		if (tagName.length > 0) {
			formObj.tag_name = tagName;
		}
		if (isEdit) {
			const screens = [];
			if (screenTo && screenTo.home.length > 0) {
				screens.push({
					screen_id: screenTo.home[0].screen_id,
					bucket_status: screenTo.home[0].status,
				});
			}
			if (screenTo && screenTo.discover.length > 0) {
				screens.push({
					screen_id: screenTo.discover[0].screen_id,
					bucket_status: screenTo.discover[0].status,
				});
			}
			if (liveSceenData?.length > 0) {
				screens.push({
					screen_id: liveSceenData[0].screen_id,
					bucket_status: liveSceenData[0].status,
				});
			}
			console.log('Screens', screens);
			formObj.bucket_id = bucketId;
			formObj.screens = screens;
			formObj.tag_name = tagName;
		} else {
			formObj.user_id = USER_ID;
		}
		//  return non-falsy keys in the object
		return Object.keys(formObj)
			.filter((k) => formObj[k] != null)
			.reduce((a, k) => ({ ...a, [k]: formObj[k] }), {});
	};
	const handleCreate = () => {
		if (checkValidations()) {
			setShowBucketCreateLoader(true);
			dispatch(
				createBucket(`${ROOT_URL}${BUCKET_URL}`, getForm(), (res) => {
					dispatch(
						replaceAllBucket(`${ROOT_URL}${BUCKET_URL}`, {
							page: 1,
							perPage: 31,
							...(fromYt && { content_type: 'video' }),
							...(!fromYt && { content_type: 'movie_show_video, movie, show' }),
						}),
					);
					setShowBucketCreateLoader(false);
					newBucketCreated(res);
					handleClose();
				}),
			);
		}
		return;
	};

	const handleEdit = () => {
		if (checkValidations()) {
			setShowBucketCreateLoader(true);
			updateBucketDetails(`${ROOT_URL}${BUCKET_URL}`, getForm(), (response) => {
				const isDiscoverScreenPreset = screenTo.discover.length > 0;
				const isHomeScreenPreset = screenTo.home.length > 0;
				setShowBucketCreateLoader(false);
				newBucketCreated(response, isDiscoverScreenPreset, isHomeScreenPreset, screenTo);
				handleClose();
			});
		}
	};

	const handleToggle = () => {
		setSwitchValue((prev) => {
			if (prev === -1 || prev === 0) {
				return 1;
			} else {
				return 0;
			}
		});
	};

	const onClickScreenTo = (value, e) => {
		switch (e.target.name) {
			case 'home':
				setScreenTo({ ...screenTo, home: [value] });
				break;
			case 'discover':
				setScreenTo({ ...screenTo, discover: [value] });
				break;
			default:
				break;
		}
	};

	const onAddScreen = (value) => {
		if (value === 'home') {
			setHomeAddClicked(true);
			setScreenTo({
				...screenTo,
				home: [{ status: 'draft', screen_id: 1, name: 'Home' }],
			});
		} else {
			setIfAddButtonClicked(true);
			setScreenTo({
				...screenTo,
				discover: [{ status: 'draft', screen_id: 2, name: 'Discover' }],
			});
		}
	};

	useEffect(() => {
		if (categories?.length === 0) {
			dispatch(getCategories(`${ROOT_URL}${GET_CATEGORY}`, {}));
		}
	}, [dispatch]);

	useEffect(() => {
		if (cardSizes) {
			const cardSizesItems = Object.keys(cardSizes).map((card) => {
				return {
					name: card,
					value: cardSizes[card],
					id: `${card}`,
				};
			});
			setCardSizesItems(cardSizesItems);
		}
	}, [cardSizes]);

	if (show)
		return (
			<ClickAwayListener onClickAway={() => handleClose()}>
				<div className="modal-container">
					<img
						className="close-button"
						alt="close"
						src={Close}
						onClick={() => handleClose()}
					/>
					<p className="modal-header">
						{isEdit ? POPUP_CONSTANT.EDIT_HEADING : POPUP_CONSTANT.CREATE_HEADING}
					</p>
					<div className="modal-container-content">
						<CustomInputPopup
							label="BUCKET NAME"
							defaultValue={bucketName}
							handleValueChanges={handleValueChange}
							placeholder="Bucket Name"
							hasError={errorState.includes('bucketName') ? true : false}
							errorVerbose="Bucket name is required"
							id="bucketName"
							isDisable={false}
						/>
						{!isEdit && (
							<CustomSelectPopup
								label="SCREEN TO"
								defaultValue={screenNames}
								from="popup"
								placeholder="Screen name"
								handleValueChanges={handleValueChange}
								menuItems={screensSelector?.data}
								hasError={errorState.includes('screenNames') ? true : false}
								errorVerbose="Screen is required"
								idFieldName="screen_id"
								//changed to single
								selectType="single"
								id="screenNames"
							/>
						)}
						{isEdit && !livescreen && liveSceenData?.length === 0 && (
							<>
								<p className="screen-to-label">{LABELS.SCREEN_TO}</p>
								<div className="custom-radio-buttons">
									{screenTo && screenTo.home && screenTo.home.length > 0 && (
										<div className="home-radio-buttons">
											<h3 className="home-heading">{LABELS.HOME}</h3>
											<CustomRadioGroup
												values={HOME_STATUS}
												name="home"
												onChange={onClickScreenTo}
												defaultValue={screenTo && screenTo.home}
											/>
										</div>
									)}

									{ifAddButtonClicked &&
									screenTo &&
									screenTo.discover &&
									screenTo.discover.length > 0 ? (
										<div className="discover-radio-buttons">
											<h3 className="discover-heading">{LABELS.DISCOVER}</h3>
											<CustomRadioGroup
												values={DISCOVER_STATUS}
												name="discover"
												onChange={onClickScreenTo}
												defaultValue={screenTo && screenTo.discover}
											/>
										</div>
									) : (
										<AddButton
											onClick={() => onAddScreen('discover')}
											name="Add Screen"
										/>
									)}
									{!ifHomeAddClicked && (
										<div className="add-screen">
											<AddButton onClick={() => onAddScreen('home')} name="Add Screen" />
										</div>
									)}
								</div>
							</>
						)}
						<CustomSelectPopup
							label="SELECT CATEGORY"
							defaultValue={category}
							from="popup"
							placeholder="Select category for your bucket"
							handleValueChanges={handleValueChange}
							menuItems={menuCategoryData} //{categoryLabels}
							hasError={errorState.includes('category') ? true : false}
							errorVerbose="Category is required"
							idFieldName="general_category_id"
							id="category"
							selectType="single"
						/>
						<div className="date-range-container">
							<CustomDateRangePopup
								label="DATE RANGE"
								placeholder="mm/dd/yy - mm/dd/yy "
								id="dateRange"
								defaultValue={dateRange}
								handleValueChanges={handleValueChange}
								toggleCalendar={toggleCalendar}
							/>
						</div>
						<CustomSelectPopup
							label="SELECT THUMB SIZE"
							defaultValue={defaultThumbSize}
							from="popupModal"
							placeholder="Select the proportion of your cards"
							handleValueChanges={handleValueChange}
							menuItems={cardSizesItems}
							hasError={errorState.includes('cardSize') ? true : false}
							errorVerbose="Card size is required"
							idFieldName="id"
							id="cardSize"
							selectType="single"
							labels={['Vertical Cards', 'Horizontal Cards', 'Others']}
							scrollTop
						/>
						<CustomInputPopup
							label="BUCKET DESCRIPTION"
							defaultValue={bucketDesc}
							handleValueChanges={handleValueChange}
							placeholder="Enter description"
							hasError={errorState.includes('bucketDescription') ? true : false}
							id="bucketDescription"
							isDisable={false}
							forDesc={true}
							publishDesc={publishDesc}
							setPublishDesc={setPublishDesc}
							letterCount={letterCount}
							setLetterCount={setLetterCount}
						/>

						<div className="container-checkbox">
							<span className="span-checkbox">{PUBLISH_DESC}</span>
							<label className={`switch`}>
								<input
									type="checkbox"
									id="publish-checkbox"
									onChange={handleCheck}
									checked={publishDesc}
									disabled={bucketDesc && bucketDesc?.length > 0 ? false : true}
								/>
								<span className="slider round"></span>
							</label>
						</div>

						<div className="ranked-bucket-checkbox">
							<span className="checkbox">
								<input
									type="checkbox"
									name="ranked-check"
									id="ranked-check"
									onChange={handleRankedBucket}
									checked={markedAsRanked}
								/>
								<label htmlFor="ranked-check" className="ranked-label">
									{RANKED_BUCK}
								</label>
							</span>
						</div>

						<CustomFileInputPopup
							label="SELECT ICON"
							toggleThumbnailPicker={toggleThumbnailPicker}
							handleValueChanges={handleValueChange}
							defaultValue={thumbnail}
							placeholder="Click here to choose icon"
							id="thumbnail"
							isVisible={thumbnail ? true : false}
							isEdit={isEdit}
						/>
						<CustomAddTags tagName={tagName} setTagName={setTagName} />
					</div>
					<div className="action-button">
						<ResetAllButton handleClick={() => handleResetClick()} />
						<PrimaryTextButton
							disabledButton={isEdit ? disableOnEdit : false}
							label={isEdit ? 'Update' : 'Create'}
							main={true}
							handleClick={isEdit ? handleEdit : handleCreate}
							showLoader={showBucketCreateLoader}
						/>
					</div>
				</div>
			</ClickAwayListener>
		);
	else return null;
};

export default PopupModal;
