export const INFO_TITLES = {
	list: 'List',
	followers: 'Followers',
	following: 'Following',
	shares: 'Shares',
};

export const TAGS = 'TAGS';
export const BA_HEADING = 'Business Account';
export const VU_HEADING = 'Verify User';

export const BUTTON_HEADING = {
	cancel: 'Cancel',
	verify: 'Done',
};
