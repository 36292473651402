const BUCKET_TABLE_COLUMNS_CONFIG = [
	{
		key: 'name_key',
		title: 'Name',
		className: 'name',
		spanClassName: 'name-span',
	},
	{
		key: 'dateRange_key',
		title: 'Date Range',
		className: 'dateRange',
		spanClassName: 'date-span',
	},
	{
		key: 'videoCount_key',
		title: 'No. of videos',
		className: 'videoCount',
		spanClassName: 'video-span',
	},
	{
		key: 'screens_key',
		title: 'Screens',
		className: 'screens',
		spanClassName: 'screen-span',
	},
];

export const SCREEN_CHANGE_ENUM = {
	screenId: 'screenId',
	update: 'update',
	page: 'page',
};

export default BUCKET_TABLE_COLUMNS_CONFIG;
