import React, { useState, useRef, useEffect } from 'react';
import {
	CATEGORY_THUMNAIL_URL,
	IMAGE_PLACEHOLDER_HORIZONTAL,
} from 'src/app/common/constants/AppConstants';
import EditLiveChannel from 'src/app/assets/images/edit-live-channel.png';
import Arrow from 'src/app/assets/images/NavigateCategory.svg';
import { useHistory, useLocation } from 'react-router-dom';

export const CategoryGrid = ({
	data,
	enableSelection,
	onSelecting,
	openEditLiveCategory,
	loader,
}) => {
	const [selectionValue, setSelectionValue] = useState();
	const imageContainerRef = useRef();
	const checkboxRef = useRef();
	const history = useHistory();
	const location = useLocation();

	const redirectToChannel = (id, name) => {
		history.push(`${location.pathname}/${id}`, { name });
	};

	const onSelectionChange = (e) => {
		if (enableSelection) {
			if (e.type === 'click' && Number(e.target.id) !== data?.live_category_id) {
				if (!checkboxRef.current.checked) {
					checkboxRef.current.checked = true;
				} else {
					checkboxRef.current.checked = false;
				}
			}
			if (e.target.checked || checkboxRef.current.checked) {
				imageContainerRef.current.style.border = '3px solid #F82C5E';
				imageContainerRef.current.style.borderRadius = '6px';
			} else {
				imageContainerRef.current.style.border = 'none';
			}
			if (e.type === 'click' && e.target.id !== data?.live_category_id) {
				setSelectionValue(checkboxRef.current.checked);
				onSelecting({ id: checkboxRef.current.id, value: checkboxRef.current.checked });
			} else {
				setSelectionValue(e.target.checked);
				onSelecting({ id: e.target.id, value: e.target.checked });
			}
		}
	};

	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
	};

	return (
		<div
			className="category-grid-container"
			ref={imageContainerRef}
			id={`category-grid-container-${data.live_category_id}`}
			onClick={onSelectionChange}>
			<img
				onError={addDefaultSrc}
				className="category-grid-container-img"
				src={`${CATEGORY_THUMNAIL_URL}${data?.thumbnail}`}
				alt="category thumbnail"
			/>
			<div className="category-grid-bottom-container">
				<div className="category-grid-title">{data?.name}</div>
				<div className="category-grid-count-container">
					<div className="category-grid-count">{data?.channel_count}</div>
				</div>
			</div>
			{enableSelection && (
				<div className="selection-button">
					<input
						id={data?.live_category_id}
						type="checkbox"
						value={selectionValue}
						onChange={onSelectionChange}
						alt="selection"
						ref={checkboxRef}
					/>
				</div>
			)}
			{!enableSelection && (
				<div className="overlay-edit">
					<span onClick={() => openEditLiveCategory(data)}>
						<img
							src={EditLiveChannel}
							alt="edit-live-channel"
							className="hover-image-round"
						/>
					</span>
					<span onClick={() => redirectToChannel(data?.live_category_id, data?.name)}>
						<img
							src={Arrow}
							alt="Navigate to subcategory"
							className="hover-image-round navigate-icon"
						/>
					</span>
				</div>
			)}
		</div>
	);
};

export default CategoryGrid;
