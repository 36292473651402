import React from 'react';
import "./styles.scss";

const IndividualTabContent = ({id, activeTab,children}) => {
    if(id===activeTab){
        return <>{children}</>
    }
    else return <></>
}

export default IndividualTabContent