import { AppRoutes } from './pages/AppRoutes';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { Auth, Amplify } from 'aws-amplify';
import AWS_CONFIG from 'src/app/common/constants/AwsConstants';
import { PersistGate } from 'redux-persist/integration/react';

const App = () => {
	Amplify.configure(AWS_CONFIG);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<AppRoutes />
			</PersistGate>
		</Provider>
	);
};

export default App;
