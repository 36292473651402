import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from './constants';
import { AppButton } from 'src/app/components/AppButton';
import AddImage from 'src/app/assets/images/add-stream.png';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import TableContainer from './component/TableContainer';
import './styles.scss';
import { useHistory } from 'react-router';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';
import {
	deleteData,
	getCommonMethod,
	postCommonMethod,
	getTableData,
	putCommonMethod,
} from 'src/app/pages/Dashboard/Service';
import { EDIT_DATA_ON_LIVE_STREAM_TABLE } from 'src/app/pages/Dashboard/actionTypes';
import { editTableData } from 'src/app/pages/Dashboard/actionCreators';
import { GET_LIVE_STREAM_DATA_INITIAL } from 'src/app/pages/Dashboard/actionTypes';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { RECORD_TO_FETCH } from 'src/app/common/constants/BucketListingConstant';
import { Toast } from 'src/app/components/Toast';
import { LiveChannelCategoryPopup } from 'src/app/components/LiveChannelCategoryPopup';
import Popup from 'reactjs-popup';
import { liveChannelPopupStyle } from 'src/app/common/constants/PopupStyles';
import { AddUrlPopup } from 'src/app/components/AddUrlPopup';
import { isEmptyString, isValidURL, isValidM3U8URL } from 'src/app/common/utils/methods';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import ScrollToTop from 'src/app/components/atoms/ScrollToTop';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import BreadCrumbs from 'src/app/components/BreadCrumbs';
import Tab from 'src/app/components/molecules/Tab';

export const LiveStreams = ({ onPopup, channelId, onClosePopup, name, addToBucket }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.liveChannel?.liveStreams);
	const totalStreamCount = useSelector((state) => state.liveChannel?.totalStreams);
	const [isChecked, setChecked] = useState(false);
	const [offset, setOffset] = useState(0);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [countMultipleCheck, setCountMultipleCheck] = useState(0);
	const [countOnAllUnselected, setCountOnAllUnselected] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [loading, setLoading] = useState(false);
	const [liveChannelId, setLiveChannelId] = useState(null);
	const searchRef = useRef('');
	const [apiBody, setApiBody] = useState([]);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [counterToShowDelete, setCounterToShowDelete] = useState(0);
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [defaultDeleteMessage, setdefaultDeleteMessage] = useState(true);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [channelName, setChannelName] = useState('');
	const [deleteChannelId, setDeleteChannelId] = useState(null);
	const [addButtonLoader, setAddButtonLoader] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [openAddToStreamPopup, setOpenAddToStreamPopup] = useState(false);
	const [allLiveChannels, setAllLiveChannels] = useState([]);
	const [channel, setChannel] = useState();
	const [selectedChannelToAdd, setSelectedChannelToAdd] = useState(null);
	const [hasMoreCategory, setHasMoreCategory] = useState(false);
	const [channelOffset, setChannelOffset] = useState(0);
	const [toggleAddButton, setToggleAddButton] = useState(false);
	const [openAddUrlPopup, setOpenAddUrlPopup] = useState(false);
	const [newChannelName, setNewChannelName] = useState('');
	const [channelUrl, setChannellUrl] = useState('');
	const [urlNotValid, setUrlNotValid] = useState(false);
	const [isEditPopup, setIsEditPopup] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [prevName, setPrevName] = useState();
	const [prevUrl, setPrevUrl] = useState();
	const [isSearchEmpty, setIsSearchEmpty] = useState(false);
	const debouncedValue = useDebounce(searchText, 500);
	const [iconKey, setIconKey] = useState(null);
	const [thumbnail, setThumbnail] = useState(null);
	const [prevThumbnail, setPrevThumbnail] = useState(null);
	const [createChannelApiBody, setCreateChannelApiBody] = useState(null);
	const liveChannelsFromRedux = useSelector((state) => state.liveChannel?.liveChannels);
	const activeTab = useRef('all');
	const [tab, setTab] = useState('all');
	const [liveCount, setLiveCount] = useState({
		all: -1,
		unmapped: -1,
		deleted: -1,
	});

	useEffect(() => {
		if (countMultipleCheck < 1) {
			setChecked(false);
			setIsAllChecked(false);
		} else {
			setChecked(true);
			setIsAllChecked(true);
		}
	}, [countMultipleCheck, setCountMultipleCheck]);

	useEffect(() => {
		if (apiBody.length < 1) {
			setChecked(false);
		}
	}, [apiBody]);

	const setTabCount = (response) => {
		if (tab === CONSTANTS.TABS[0].key) {
			setLiveCount({ ...liveCount, all: response?.total_record_count });
		} else if (tab === CONSTANTS.TABS[1].key) {
			setLiveCount({ ...liveCount, unmapped: response?.total_record_count });
		} else if (tab === CONSTANTS.TABS[2].key) {
			setLiveCount({ ...liveCount, deleted: response?.total_record_count });
		}
	};

	const handleHasMoreCategory = (response) => {
		if (
			response &&
			response.data &&
			response.data.length > 0 &&
			response.data.length === 20
		) {
			setHasMoreCategory(true);
		} else {
			setHasMoreCategory(false);
		}
	};

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#scrollableDivLiveStream');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const fetchLiveChannels = async () => {
		if (liveChannelsFromRedux?.length > 0 && channelOffset === 0) {
			setAllLiveChannels([...liveChannelsFromRedux]);
			if (liveChannelsFromRedux.length > 0 && liveChannelsFromRedux.length === 20) {
				setHasMoreCategory(true);
			}
		} else {
			const response = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CHANNEL_URL}`,
				{
					offset: channelOffset,
					perPage: RECORD_TO_FETCH,
					orderBy: GLOBAL_CONST.ORDER_BY.createdAt,
					orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
				},
			);

			if (channelOffset > 0) {
				setAllLiveChannels([...allLiveChannels, ...response.data]);
				handleHasMoreCategory(response);
			} else {
				setAllLiveChannels(response.data);
				handleHasMoreCategory(response);
			}
		}
	};

	useEffect(() => {
		fetchLiveChannels();
	}, [channelOffset]);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	useEffect(() => {
		counterToShowDelete > 0 ? setShowDeleteButton(true) : setShowDeleteButton(false);
	}, [counterToShowDelete]);

	const handleDelete = () => {
		setdefaultDeleteMessage(false);
		setShowDeletePopup(true);
	};
	const closeDeleteConfirmationModal = () => {
		setShowDeletePopup(false);
		setdefaultDeleteMessage(true);
	};

	const onDeleteChannel = async () => {
		setAddButtonLoader(true);
		let apiData = [];
		if (deleteChannelId) {
			apiData.push(deleteChannelId);
		} else {
			apiBody.map((item) => {
				apiData.push(item.live_stream_id);
			});
		}
		if (tab === CONSTANTS.TABS[0].key) {
			setLiveCount({
				...liveCount,
				deleted: liveCount.deleted + apiData?.length,
				all: liveCount.all > -1 ? liveCount.all - apiData?.length : apiData?.length,
			});
		} else if (tab === CONSTANTS.TABS[1].key) {
			setLiveCount({
				...liveCount,
				deleted: liveCount.deleted + apiData?.length,
				unmapped:
					liveCount.unmapped > -1
						? liveCount.unmapped - apiData?.length
						: apiData?.length,
			});
		}
		await deleteChannels(apiData);
	};

	const removeApprovedItemFromRedux = (form, apiData) => {
		editTableData(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_STREAM}`,
			form,
			({ data }) => {
				dispatch({
					type: EDIT_DATA_ON_LIVE_STREAM_TABLE,
					payload: {
						apiData,
					},
				});
			},
		);
	};

	const deleteChannels = async (apiData) => {
		try {
			const res = await deleteData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.DELETE_LIVE_STREAMS}`,
				{
					live_stream_ids: apiData,
				},
			);
			if (res.status) {
				let form = {
					offset: tableData?.length,
					perPage: RECORD_TO_FETCH,
					orderBy: GLOBAL_CONST.ORDER_BY.name,
					orderType: GLOBAL_CONST.ORDER_TYPE.ASC,
				};
				removeApprovedItemFromRedux(form, apiData);
				setCountMultipleCheck(0);
				setCountOnAllUnselected([]);
				setIsAllChecked(false);
				setCounterToShowDelete(0);
				setToastInfo(true, false, res.message);
				if (apiBody.length === tableData?.length && !deleteChannelId) {
					setOffset(-1);
				}
				setApiBody([]);
			}
		} catch (error) {
			setToastInfo(true, true, error.response.message);
		}
		setAddButtonLoader(false);
		setShowDeletePopup(false);
		setChannelName('');
		setdefaultDeleteMessage(true);
		setDeleteChannelId(null);
	};

	const toggleAddToStreamPopup = (value) => {
		setOpenAddToStreamPopup(value);
	};

	const handleAddChannel = () => {
		toggleAddToStreamPopup(true);
	};

	const handleViewChannel = () => {
		if (debouncedValue) {
			dispatch({ type: GET_LIVE_STREAM_DATA_INITIAL, payload: { data: [] } });
		}
		history.push(`/live/channels`);
	};

	const handleCreate = () => {
		setOpenAddUrlPopup(true);
		setIsEditPopup(false);
	};

	const handleChange = (e) => {
		setOffset(0);
		setSearchText(() => {
			if (e.target.value === '') {
				setIsSearchEmpty(true);
			}
			return e.target.value;
		});
	};
	const emptySearchBox = () => {
		setOffset(0);
		setSearchText('');
		setIsSearchEmpty(true);
		searchRef.current.value = '';
	};

	const handleLiveChannelId = (id) => {
		setLiveChannelId(id);
	};

	const addChannelToBucket = async () => {
		try {
			const contentIds = [];
			apiBody.map((item) => {
				contentIds.push(item.live_stream_id);
			});
			setAddButtonLoader(true);
			const resp = await postCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_LIVE_CHANNEL_IN_BUCKET}`,
				{
					content_ids: contentIds,
					bucket_id: channelId,
					content_type: 'live_stream',
				},
			);
			if (resp) {
				setCountMultipleCheck(0);
				setCountOnAllUnselected([]);
				setIsAllChecked(false);
				setCounterToShowDelete(0);
				setChecked(false);
				setApiBody([]);
				setToastInfo(true, false, resp.data.message);
			}
		} catch (e) {
			setToastInfo(
				true,
				true,
				e.response.data.message === CONSTANTS.LIVE_MSSG.live_stream_mssg
					? CONSTANTS.LIVE_MSSG.live_channel_mssg
					: e.response.data.message,
			);
		} finally {
			setAddButtonLoader(false);
			toggleAddToStreamPopup(false);
			// onClosePopup();
		}
	};

	const addStreamToChannel = async () => {
		setAddButtonLoader(true);
		try {
			const response = await postCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_STREAM_TO_CHANNEL}`,
				apiBody.map((item) => {
					return {
						...item,
						live_channel_id: onPopup ? Number(channelId) : channel,
					};
				}),
			);
			if (response && response.data && response.data.message) {
				setCountMultipleCheck(0);
				setCountOnAllUnselected([]);
				setIsAllChecked(false);
				setCounterToShowDelete(0);
				setChecked(false);
				setApiBody([]);
				setToastInfo(true, false, response.data.message);
			}
		} catch (error) {
			setToastInfo(true, true, error.response.data.message);
		}
		setAddButtonLoader(false);
		toggleAddToStreamPopup(false);
	};

	const selectedDropDownValue = (value) => {
		setChannel(value);
	};

	const closeModal = () => {
		setOpenAddUrlPopup(false);
	};

	const onChangeName = (e) => {
		setNewChannelName(() => {
			return e.target.value;
		});
	};

	const onChangeUrl = (e) => {
		setChannellUrl(() => {
			return e.target.value.trim();
		});
	};

	const clearPopupInput = () => {
		setNewChannelName('');
		setChannellUrl('');
		setUrlNotValid(false);
	};

	const fetchNewData = async () => {
		setLoading(true);
		const response = await getTableData(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_STREAM}`,
			{
				offset: 0,
				perPage: RECORD_TO_FETCH,
				orderBy: GLOBAL_CONST.ORDER_BY.name,
				orderType: GLOBAL_CONST.ORDER_TYPE.ASC,
			},
		);
		dispatch({ type: GET_LIVE_STREAM_DATA_INITIAL, payload: response });
	};

	const onCancelAddPopup = () => {
		setOpenAddUrlPopup(false);
		clearPopupInput();
	};

	const onAddUrl = async () => {
		setAddButtonLoader(true);

		// if (isValidM3U8URL(channelUrl)) {
		try {
			const medthodToBeCalled = isEditPopup ? putCommonMethod : postCommonMethod;
			const url = isEditPopup
				? `${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.EDIT_STREAM_URL}`
				: `${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_STREAM_URL}`;

			let params = {
				live_stream_name: newChannelName,
				live_stream_url: channelUrl,
				logo: `${GLOBAL_CONST.CATEGORY_THUMNAIL_URL}${iconKey}`,
			};
			if (isEditPopup) {
				params = { ...createChannelApiBody };
			}
			const response = await medthodToBeCalled(url, params);
			if (!isEditPopup && response && response.data && response.data.message) {
				dispatch({ type: GET_LIVE_STREAM_DATA_INITIAL, payload: { data: [] } });
				fetchNewData();
				clearPopupInput();
				setOffset(0);
				setToastInfo(true, false, response.data.message);
				setAddButtonLoader(false);
				setOpenAddUrlPopup(false);
				if (tab === CONSTANTS.TABS[0].key) {
					setLiveCount({ ...liveCount, all: liveCount.all + 1 });
				} else if (tab === CONSTANTS.TABS[1].key) {
					setLiveCount({ ...liveCount, unmapped: liveCount.unmapped + 1 });
				}
			} else if (isEditPopup && response && response.status) {
				dispatch({ type: GET_LIVE_STREAM_DATA_INITIAL, payload: { data: [] } });
				fetchNewData();
				clearPopupInput();
				setOffset(0);
				setToastInfo(true, false, response.message);
				setAddButtonLoader(false);
				setOpenAddUrlPopup(false);
			}
			setPrevName(newChannelName);
			setPrevUrl(channelUrl);
			setPrevThumbnail(iconKey);
			setIconKey(null);
		} catch (error) {
			setAddButtonLoader(false);
			setToastInfo(true, true, error.response.data.message);
		}
		// }
		// else {
		// 	setUrlNotValid(true);
		// 	setAddButtonLoader(false);
		// }
	};

	const handleChangeOnEdit = (data) => {
		setOpenAddUrlPopup(true);
		if (data) {
			setLiveChannelId(data.live_stream_id);
			setChannellUrl(data.url);
			setNewChannelName(data?.display_name);
			setThumbnail(data?.logo);
			setPrevThumbnail(data?.logo?.length > 0 ? data?.logo : null);
			setIconKey(data?.logo);
			setPrevUrl(data?.url);
			setPrevName(data?.display_name);
			setIsEditPopup(true);
		}
	};

	useEffect(() => {
		if (
			isEditPopup &&
			isEmptyString(newChannelName) &&
			isEmptyString(channelUrl) &&
			isEmptyString(iconKey) &&
			isEmptyString(prevThumbnail) &&
			isValidURL(channelUrl) &&
			(prevName !== newChannelName || prevUrl !== channelUrl || prevThumbnail !== iconKey)
		) {
			setIsDisabled(true);
			if (prevThumbnail === iconKey) {
				setCreateChannelApiBody({
					name: newChannelName,
					url: channelUrl,
					live_stream_id: liveChannelId,
					logo: iconKey,
				});
			} else {
				setCreateChannelApiBody({
					name: newChannelName,
					url: channelUrl,
					live_stream_id: liveChannelId,
					logo: `${GLOBAL_CONST.CATEGORY_THUMNAIL_URL}${iconKey}`,
				});
			}
		} else {
			setIsDisabled(false);
		}
	}, [
		channelUrl,
		isEditPopup,
		newChannelName,
		prevName,
		prevUrl,
		prevThumbnail,
		iconKey,
		thumbnail,
	]);

	useEffect(() => {
		return () => {
			if (!isSearchEmpty && debouncedValue) {
				dispatch({ type: GET_LIVE_STREAM_DATA_INITIAL, payload: { data: [] } });
			}
		};
	}, [isSearchEmpty, debouncedValue]);

	const handleChangeTab = (tab) => {
		activeTab.current = tab;
		setTab(tab);
		offset > -1 ? setOffset(-1) : setOffset((val) => val - 1);
		setLoading(true);
	};

	return (
		<div className="live-channel-right-container">
			<div className="live-channel-header-parent-block">
				<div className="live-header-block">
					<div className="header-wrapper">
						<h1>
							{onPopup
								? `${CONSTANTS.ADD_LIVE_STREAM}${name}`
								: CONSTANTS.LIVE_STREAM_HEADING}
						</h1>
						{/* {totalStreamCount > -1 ? (
							<div className="count-header-wrapper">&nbsp;( {totalStreamCount} )</div>
						) : null} */}
					</div>
					{onPopup && (
						<img
							src={CrossIcon}
							alt="close"
							className="cross-icon"
							onClick={() => {
								setIsSearchEmpty(true);
								dispatch({ type: GET_LIVE_STREAM_DATA_INITIAL, payload: { data: [] } });
								onClosePopup();
							}}
						/>
					)}
				</div>
				{!onPopup && <BreadCrumbs value="All Live Channels" />}

				<div className="live-search-box-container">
					<div className="live-search-with-cancel">
						<DashboardSearchBox
							handleChange={handleChange}
							className={
								onPopup ? 'live-stream-in-channel-search' : 'live-channel-search'
							}
							ref={searchRef}
							digestButton
							placeholder="Search by name or keywords"
						/>
						{searchText !== '' && (
							<Button
								label="Cancel"
								labelClassName="cancel-button-label"
								className="cancel-button"
								onClick={emptySearchBox}
							/>
						)}
					</div>
					<div className="live-header-buttons">
						{!onPopup && showDeleteButton && (
							<Tooltip
								overlay="Delete Live Channel"
								placement="bottom"
								overlayInnerStyle={GLOBAL_CONST.overlayStyle}
								align={{
									offset: [-8, -2],
								}}>
								<div>
									<Button
										imageInfo={{
											src: DeleteBucket,
										}}
										className="live-delete-button"
										onClick={handleDelete}
									/>
								</div>
							</Tooltip>
						)}
						{tab !== CONSTANTS.TABS[2].key
							? !onPopup && (
									<Tooltip
										overlay="Create Live Channel"
										placement="bottom"
										overlayInnerStyle={GLOBAL_CONST.overlayStyle}
										align={{
											offset: [-8, -2],
										}}>
										<div>
											<Button
												imageInfo={{
													src: AddImage,
												}}
												className="live-add-button"
												onClick={handleCreate}
											/>
										</div>
									</Tooltip>
							  )
							: null}

						{tab !== CONSTANTS.TABS[2].key && (
							<AppButton
								label={onPopup ? 'Add' : 'Add to Sub-Categories'}
								className="live-app-button"
								innerClass="live-app-button-text"
								isDisabled={!isChecked}
								onClick={() =>
									addToBucket
										? addChannelToBucket()
										: onPopup
										? addStreamToChannel()
										: handleAddChannel()
								}
								autoCaptialize={false}
								isLoading={onPopup && addButtonLoader}
							/>
						)}
						{/* {!onPopup && (
							<AppButton
								label="View Channels"
								className="live-app-button"
								innerClass="live-app-button-text"
								onClick={handleViewChannel}
							/>
						)} */}
					</div>
				</div>
				{!onPopup && (
					<div>
						<Tab
							tabs={CONSTANTS.TABS}
							active={activeTab.current}
							handleTabChange={handleChangeTab}
							className="live-channels-tab"
							bucketCount={liveCount}
						/>
					</div>
				)}
			</div>
			<div className="content-block">
				<TableContainer
					offset={offset}
					setOffset={setOffset}
					loading={loading}
					setLoading={setLoading}
					setChecked={setChecked}
					isAllChecked={isAllChecked}
					liveChannelId={liveChannelId}
					setIsAllChecked={setIsAllChecked}
					countMultipleCheck={countMultipleCheck}
					setCountMultipleCheck={setCountMultipleCheck}
					countOnAllUnselected={countOnAllUnselected}
					setCountOnAllUnselected={setCountOnAllUnselected}
					handleLiveChannelId={handleLiveChannelId}
					apiBody={apiBody}
					setApiBody={setApiBody}
					openTooltip={openTooltip}
					setOpenTooltip={setOpenTooltip}
					setCounterToShowDelete={setCounterToShowDelete}
					defaultDeleteMessage={defaultDeleteMessage}
					counterToShowDelete={counterToShowDelete}
					showDeletePopup={showDeletePopup}
					setShowDeletePopup={setShowDeletePopup}
					channelName={channelName}
					setChannelName={setChannelName}
					setDeleteChannelId={setDeleteChannelId}
					closeDeleteConfirmationModal={closeDeleteConfirmationModal}
					onDeleteChannel={onDeleteChannel}
					addButtonLoader={addButtonLoader}
					setAddButtonLoader={setAddButtonLoader}
					channel={channel}
					handleChangeOnEdit={handleChangeOnEdit}
					setLiveChannelId={setLiveChannelId}
					onPopup={onPopup}
					debouncedValue={debouncedValue}
					isSearchEmpty={isSearchEmpty}
					setIsSearchEmpty={setIsSearchEmpty}
					tab={tab}
					setTabCount={setTabCount}
				/>
			</div>

			<Popup
				open={openAddToStreamPopup}
				contentStyle={liveChannelPopupStyle}
				onClose={() => toggleAddToStreamPopup(false)}
				closeOnDocumentClick>
				<LiveChannelCategoryPopup
					message="Select Sub-Category to add Live Channels"
					dropdownLabel="SELECT SUB-CATEGORY"
					placeholder="Sub-Category Name"
					categoryLabelsDemo={allLiveChannels}
					setSelectedCategory={selectedDropDownValue}
					category={channel}
					onCancel={() => toggleAddToStreamPopup(false)}
					onAdd={addStreamToChannel}
					hasMoreCategory={hasMoreCategory}
					setDisable={setToggleAddButton}
					isDisable={toggleAddButton}
					id="channel"
					setCategoryOffset={setChannelOffset}
					loading={addButtonLoader}
				/>
			</Popup>

			<AddUrlPopup
				isOpen={openAddUrlPopup}
				closeModal={closeModal}
				message={isEditPopup ? 'Edit Live Channel URL' : 'Add Live Channel URL'}
				channelName={newChannelName}
				channelUrl={channelUrl}
				onChangeName={onChangeName}
				onChangeUrl={onChangeUrl}
				isDisable={
					isEditPopup
						? isDisabled
						: isEmptyString(newChannelName) &&
						  isValidURL(channelUrl) &&
						  isEmptyString(iconKey) &&
						  iconKey
				}
				onAdd={onAddUrl}
				onCancel={onCancelAddPopup}
				loading={addButtonLoader}
				urlNotValid={urlNotValid}
				isEdit={isEditPopup}
				buttonText={isEditPopup ? 'Update' : 'Add'}
				iconKey={iconKey}
				setIconKey={setIconKey}
				thumbnail={thumbnail}
			/>

			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
			{tableData?.length > 20 && !onPopup && (
				<ScrollToTop handleScrollToTop={handleScrollToTop} />
			)}
		</div>
	);
};

export default LiveStreams;
