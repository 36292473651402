import React, { useState } from 'react';
import DashboardSearchBox from '../atoms/DashboardSearchBox';
import FILTERS from './constant';
import { sortByFilterValues } from './data.js';
import { removeAllSpecialCharacters } from '../../common/utils/methods';
import Search from '../../assets/logos/Search.svg';
import './Filter.styles.scss';
import FilterCheckBox from './FilterCheckBox';
import { useSelector } from 'react-redux';
import CustomFilterDropDown from '../CustomFilterDropdown';

const Filter = ({
	handleSearch,
	onChangeFilter,
	onChangeSearchFilter,
	applyFilters,
	searchRef,
	movieCastRef,
	isFilterVisible,
	onChangeMovieCast,
	filtersApplied,
	clearAllFilters,
	onChangeDirectors,
	toggleSearchInput,
	openServicesSearch,
	handleFilter,
	sortBy,
	disabled = false,
	shuffle = false,
}) => {
	const {
		filters: { genres, services },
	} = useSelector((state) => state.reservations);
	const [showAllOptions, setShowOptions] = useState(false);
	return (
		<aside className={`filter-container  ${disabled ? 'disable-button' : ''}`}>
			<div className="filter-container-header">
				<h5 className="heading">{FILTERS.heading}</h5>
				{isFilterVisible && (
					<div className="filter-buttons">
						<button className={`filter-button margin-right`} onClick={applyFilters}>
							Apply Filters
						</button>
						<button className="filter-button" onClick={clearAllFilters}>
							Clear All
						</button>
					</div>
				)}
			</div>
			<div className={`contentWrapper ${shuffle ? 'fix-height' : ''}`}>
				<div className="content">
					<div className="header">
						<h4 className={`content-title Popular Movies`}>Sort By</h4>
					</div>

					<CustomFilterDropDown
						dropDownMenuItems={sortByFilterValues && sortByFilterValues.options}
						idFieldName="option_id"
						placeholder="Select"
						itemClassName="sortByItems"
						optionClassName="sortByOptions"
						dropDownContainerClass="sortByDropDown"
						handleValueChanges={handleFilter}
						defaultValue={sortBy}
					/>

					<div className="header">
						<h4 className={`content-title ${removeAllSpecialCharacters('Genres')}`}>
							Genres
						</h4>
					</div>
					<div className={`${removeAllSpecialCharacters('Genres')}-container`}>
						<div className={`${removeAllSpecialCharacters('Genres')}-${2}`}>
							{genres &&
								genres.map(({ name, genre_id: option_id }, index) => {
									return (
										<FilterCheckBox
											key={option_id}
											id={option_id}
											label={name}
											name={name}
											type={'pills'}
											onChangeFilter={onChangeFilter}
											index={index}
										/>
									);
								})}
						</div>
					</div>

					<div className="header">
						<h4 className={`content-title ${removeAllSpecialCharacters('Services')}`}>
							Services
						</h4>

						<>
							<div className="search-icon" onClick={() => toggleSearchInput(true)}>
								<img src={Search} alt="search" />
							</div>

							<DashboardSearchBox
								type="filter"
								id="services-search"
								className={`services-search-container ${
									openServicesSearch ? 'visible' : 'hidden'
								}`}
								placeholder="Search by services"
								closeIcon
								onClose={() => toggleSearchInput(false)}
							/>
						</>
					</div>
					<div className={`${removeAllSpecialCharacters('Services')}-container`}>
						<div className={`${removeAllSpecialCharacters('Services')}-${3}`}>
							{services &&
								services.map(({ display_name, name, provider_id: option_id }, index) => {
									return (
										<FilterCheckBox
											key={option_id}
											id={option_id}
											label={display_name}
											name={name}
											type={'checkbox'}
											onChangeFilter={onChangeFilter}
											index={index}
										/>
									);
								})}
							{!showAllOptions && (
								<div className="extra-options">
									<div className="spacer" />
									<div
										className="more-than-10"
										role="button"
										onClick={() => {
											setShowOptions(true);
											const element = document.querySelector('.Services-3');
											element.style.height = 'max-content';
											element.style.overflow = 'visible';
										}}>{`${services && services.length - 10}+`}</div>
								</div>
							)}
						</div>
					</div>

					<div className="header">
						<h4 className={`content-title ${removeAllSpecialCharacters('Directors')}`}>
							Directors
						</h4>
					</div>

					<DashboardSearchBox
						ref={searchRef}
						id={'Director'}
						type="filter"
						placeholder="Search by keyword"
						handleChange={onChangeDirectors}
						handleEnterKeyPress={() => {}}
					/>

					<div className="header">
						<h4 className={`content-title ${removeAllSpecialCharacters('Movie Cast')}`}>
							Movie Cast
						</h4>
					</div>

					<DashboardSearchBox
						ref={movieCastRef}
						id={'Movie Cast'}
						type="filter"
						placeholder="Search by keyword"
						handleChange={onChangeMovieCast}
						handleEnterKeyPress={() => {}}
					/>
				</div>
			</div>
		</aside>
	);
};

export default Filter;
