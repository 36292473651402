import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RECORD_TO_FETCH } from 'src/app/common/constants/BucketListingConstant';
import * as CONSTANT from '../constants';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { isEmptyString } from 'src/app/common/utils/methods';
import { getTableData } from '../../Dashboard/Service';
import {
	GET_LIVE_STREAM_DATA,
	GET_LIVE_STREAM_DATA_INITIAL,
	LIVE_STREAM_SCROLL,
} from 'src/app/pages/Dashboard/actionTypes';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import InfiniteScroll from 'react-infinite-scroll-component';
import { infinteScrollEndMessage } from 'src/app/pages/ComponentConstant';
import { Loader } from 'src/app/components/Loader';
import { Row } from './Row';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import Table from 'src/app/components/Table';
import Popup from 'reactjs-popup';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import { deletePopupStyle, videoPopupStyle } from 'src/app/common/constants/PopupStyles';
import './styles.scss';
import VideoPlayer from 'src/app/components/VideoPlayer/VideoPlayerIndex';

const TableContainer = ({
	offset,
	setOffset,
	loading,
	setLoading,
	setChecked,
	handleLiveChannelId,
	liveChannelId,
	apiBody,
	setApiBody,
	isAllChecked,
	setIsAllChecked,
	countMultipleCheck,
	setCountMultipleCheck,
	countOnAllUnselected,
	setCountOnAllUnselected,
	shuffleMode = false,
	openTooltip,
	setCounterToShowDelete,
	defaultDeleteMessage,
	counterToShowDelete,
	showDeletePopup,
	setShowDeletePopup,
	channelName,
	setChannelName,
	setDeleteChannelId,
	closeDeleteConfirmationModal,
	onDeleteChannel,
	addButtonLoader,
	handleChangeOnEdit,
	channel,
	onPopup,
	debouncedValue,
	isSearchEmpty,
	setIsSearchEmpty,
	tab,
	setTabCount,
}) => {
	const mount = useRef();
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.liveChannel?.liveStreams);
	const liveScroll = useSelector((state) => state.liveChannel?.liveStreamScrollOffset);
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [count, setCount] = useState(0);
	const [val, setVal] = useState(false);
	const [id, setId] = useState([]);
	const [delVal, setDelVal] = useState(0);
	const [liveStreamScroll, setLiveStreamScroll] = useState(0);
	const [openTooltipRow, setOpenTooltipRow] = useState(false);
	const [videoPopup, setVideoPopup] = useState(false);
	const [videoUrl, setVideoUrl] = useState(null);

	const scroller = document.querySelector('#scrollableDivLiveStream');
	if (scroller && tab === CONSTANT.TABS[0].key) {
		scroller.addEventListener('scroll', (event) => {
			setLiveStreamScroll(scroller.scrollTop);
		});
	}

	useEffect(() => {
		setTableDisplayData([]);
		setHasMore(false);
		if (tab === CONSTANT.TABS[0].key) {
			setHasMore(true);
			setLoading(false);
			setTableDisplayData([...tableData]);
		}
	}, [tab]);

	useEffect(() => {
		if (tableData?.length > 0) {
			setOffset(tableData.length);
			setTimeout(() => {
				const element = document.querySelector('#scrollableDivLiveStream');
				if (element) {
					element.scrollTo({ top: liveScroll, behavior: 'auto' });
				}
			}, 50);
		}
	}, []);

	useEffect(() => {
		return () => {
			dispatch({ type: LIVE_STREAM_SCROLL, payload: liveStreamScroll });
		};
	}, [liveStreamScroll]);

	useEffect(() => {
		if (countMultipleCheck === 0) {
			setId([]);
		}
		if (isAllChecked) {
			let allCheckedValues = [];
			setCountMultipleCheck(tableData.length - delVal);
			setCounterToShowDelete(tableData.length - delVal);
			tableData.map((item) => {
				if (!id.includes(item.live_stream_id)) {
					let bodyObj = {
						live_stream_id: item.live_stream_id,
						live_category_id: null,
					};
					allCheckedValues.push(bodyObj);
				}
			});
			setApiBody([...allCheckedValues]);
		}
	}, [tableData]);

	const closeToolTip = () => {
		handleLiveChannelId(null);
		setOpenTooltipRow(false);
	};
	const handleMoreClick = (e, id) => {
		handleLiveChannelId(id);
		if (openTooltipRow) {
			handleLiveChannelId(null);
		}
		setOpenTooltipRow(!openTooltipRow);
	};

	const handleChangeOnDelete = (channelId, channelName) => {
		setShowDeletePopup(true);
		setChannelName(channelName);
		setDeleteChannelId(channelId);
		handleLiveChannelId(null);
	};

	const handleClick = (e, channel_id) => {
		if (e.target.checked === false) {
			const index = apiBody.findIndex((item) => item.live_stream_id === channel_id);
			apiBody.splice(index, 1);
			setApiBody([...apiBody]);
			setId([...id, channel_id]);
			setVal(false);
			setCountMultipleCheck((value) => value - 1);
			setCounterToShowDelete((value) => value - 1);
			setDelVal((v) => v + 1);
		}
		if (e.target.checked === true) {
			const index = id.findIndex((item) => item === channel_id);
			id.splice(index, 1);
			let values = {
				live_stream_id: channel_id,
				live_channel_id: channel,
			};
			setVal(false);
			setApiBody([...apiBody, values]);
			setCountMultipleCheck((value) => value + 1);
			setCounterToShowDelete((value) => value + 1);
			setDelVal((v) => v - 1);
		}
	};

	const handleAllCheck = (e) => {
		setId([]);
		setDelVal(0);
		if (e.target.checked === true) {
			setChecked(true);
			setIsAllChecked(true);
			let allCheckedValues = [];
			setCountMultipleCheck(tableData.length);
			setCounterToShowDelete(tableData.length);
			tableData.map((item) => {
				let bodyObj = {
					live_stream_id: item.live_stream_id,
				};
				allCheckedValues.push(bodyObj);
			});
			setApiBody([...allCheckedValues]);
		} else {
			setChecked(false);
			setCountMultipleCheck(0);
			setCounterToShowDelete(0);
			setIsAllChecked(false);
			setCount(0);
			setApiBody([]);
		}
		setCountOnAllUnselected([]);
	};

	const handleCheck = (e, id) => {
		if (e.target.checked === true) {
			countOnAllUnselected.push(id);
			setCountOnAllUnselected(countOnAllUnselected);
			setCounterToShowDelete((val) => val + 1);
			setCount((prev) => prev + 1);
			let val = {
				live_stream_id: id,
			};
			setApiBody([...apiBody, val]);
		} else {
			let indexOnAllUnselected = countOnAllUnselected.findIndex((item) => item === id);
			countOnAllUnselected.splice(indexOnAllUnselected, 1);
			setCountOnAllUnselected(countOnAllUnselected);
			setCounterToShowDelete((val) => val - 1);
			setCount((prev) => prev - 1);
			const index = apiBody.findIndex((item) => item.live_stream_id === id);
			apiBody.splice(index, 1);
			setApiBody([...apiBody]);
		}
	};

	useEffect(() => {
		if (count > 0) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	}, [count]);

	const fetchMoreData = () => {
		if (hasMore) {
			goToNextPage();
		}
	};

	useEffect(() => {
		if (tableData) {
			setLoading(false);
			setTableDisplayData([...tableData]);
		}
	}, [tableData, setLoading]);

	const handleHasMore = (response) => {
		if (
			response &&
			response.data &&
			response.data.length > 0 &&
			response.data.length === 20
		) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const fetchData = async () => {
		const params = {
			offset: offset > 0 ? offset : 0,
			perPage: RECORD_TO_FETCH,
			orderBy: GLOBAL_CONST.ORDER_BY.name,
			orderType: GLOBAL_CONST.ORDER_TYPE.ASC,
		};

		if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
			const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
			params.searchTerm = modifiedDebounceValue;
		}
		if (tab === CONSTANT.TABS[0].key) {
			if (offset > 0) {
				const response = await getTableData(
					`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_STREAM}`,
					params,
				);
				handleHasMore(response);
				setTabCount(response);
				dispatch({ type: GET_LIVE_STREAM_DATA, payload: response });
			} else {
				if (tableData.length === 0 || debouncedValue || isSearchEmpty) {
					setLoading(true);
					const response = await getTableData(
						`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_STREAM}`,
						params,
					);
					handleHasMore(response);
					setTabCount(response);
					dispatch({ type: GET_LIVE_STREAM_DATA_INITIAL, payload: response });
					setIsSearchEmpty(false);
				}
			}
		} else if (tab === CONSTANT.TABS[1].key) {
			if (offset > 0) {
				const response = await getTableData(
					`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_UNMAPPED_LIVE_CHANNEL}`,
					params,
				);
				setTableDisplayData([...tableDisplayData, ...response?.data]);
				handleHasMore(response);
				setTabCount(response);
			} else {
				setLoading(true);
				const response = await getTableData(
					`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_UNMAPPED_LIVE_CHANNEL}`,
					params,
				);
				setTableDisplayData([...response?.data]);
				handleHasMore(response);
				setLoading(false);
				setTabCount(response);
				setIsSearchEmpty(false);
			}
		} else if (tab === CONSTANT.TABS[2].key) {
			params.orderBy = 'updated_at';
			params.orderType = 'desc';
			params.deletedBy = 'all';
			if (offset > 0) {
				const response = await getTableData(
					`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_DELETED_LIVE_CHANNEL}`,
					params,
				);
				setTableDisplayData([...tableDisplayData, ...response?.data]);
				setTabCount(response);
				handleHasMore(response);
			} else {
				setLoading(true);
				const response = await getTableData(
					`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_DELETED_LIVE_CHANNEL}`,
					params,
				);
				setTableDisplayData([...response?.data]);
				setTabCount(response);
				handleHasMore(response);
				setLoading(false);
				setIsSearchEmpty(false);
			}
		}
	};

	const handleVideoPopup = (url) => {
		setVideoPopup(true);
		setVideoUrl(url);
	};

	const closeVideoPopup = () => {
		setVideoPopup(false);
		setVideoUrl(null);
	};

	useEffect(() => {
		fetchData();
	}, [dispatch, offset, debouncedValue]);

	const goToNextPage = () => {
		setOffset(tableDisplayData?.length);
	};

	useEffect(() => {
		mount.current = 'mount';
		return () => (mount.current = 'unmount');
	}, []);

	const renderTable = () => {
		if (loading) {
			return (
				<EmptyState
					columns={CONSTANT.LIVE_STREAM_TABLE_COLUMNS_CONFIG}
					renderColumns={2}
				/>
			);
		} else {
			return tableDisplayData.map((item, index) => {
				return (
					<Row
						key={item && `${item.live_stream_id}`}
						data={{ ...item, index }}
						isAllChecked={isAllChecked}
						handleCheck={(event) =>
							handleCheck(event, item?.live_stream_id, item?.display_name)
						}
						handleLiveChannelId={() => {
							handleLiveChannelId(item.live_stream_id);
						}}
						countOnAllUnselected={countOnAllUnselected}
						val={val}
						handleClick={(event) => handleClick(event, item.live_stream_id)}
						id={id}
						handleChangeOnDelete={handleChangeOnDelete}
						handleChangeOnEdit={() => handleChangeOnEdit(item)}
						onPopup={onPopup}
						tab={tab}
						openTooltip={openTooltipRow}
						closeToolTip={closeToolTip}
						liveChannelId={liveChannelId}
						handleMoreClick={handleMoreClick}
						handleVideoPopup={() => handleVideoPopup(item?.url)}
					/>
				);
			});
		}
	};

	return (
		<div>
			<Table isRenderable={true}>
				<div
					id="scrollableDivLiveStream"
					className={`${openTooltip ? 'disableScroll' : ''} ${
						onPopup ? 'increaseheight' : ''
					}`}>
					<InfiniteScroll
						dataLength={tableDisplayData.length}
						next={fetchMoreData}
						hasMore={hasMore}
						loader={<Loader loadMore={true} />}
						scrollableTarget="scrollableDivLiveStream"
						endMessage={loading ? null : infinteScrollEndMessage()}>
						<table className="table-live-stream">
							<tbody>
								<tr>
									{CONSTANT.LIVE_STREAM_TABLE_COLUMNS_CONFIG.map((column, index) => {
										if (
											(onPopup || tab === CONSTANT.TABS[2].key) &&
											index === CONSTANT.LIVE_STREAM_TABLE_COLUMNS_CONFIG.length - 1
										) {
											return null;
										}
										if (
											(onPopup || tab !== CONSTANT.TABS[0].key) &&
											index === CONSTANT.LIVE_STREAM_TABLE_COLUMNS_CONFIG.length - 3
										) {
											return null;
										}
										return (
											<Column
												key={column.key}
												config={column}
												handleAllCheck={(event) => handleAllCheck(event)}
												shuffleMode={shuffleMode ?? false}
												isAllChecked={isAllChecked}
												countMultipleCheck={countMultipleCheck}
												isWaitlist={true}
												onPopup={onPopup || tab === CONSTANT.TABS[2].key}
												status={tab === CONSTANT.TABS[2].key ? true : false}
												tab={tab}
											/>
										);
									})}
								</tr>
								{renderTable()}
							</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</Table>
			<Popup
				className="popup-content-container-digest"
				open={showDeletePopup}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={deletePopupStyle}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmationModal}
					onDelete={onDeleteChannel}
					loading={addButtonLoader}
					message={`${
						defaultDeleteMessage
							? `Are you sure you want to delete this ${channelName} live Channel?`
							: `Are you sure you want to delete these ${counterToShowDelete} live Channels?`
					}`}
					fromLiveScreen={true}
				/>
			</Popup>
			<Popup
				className="popup-content-container-digest"
				open={videoPopup}
				closeOnDocumentClick
				onClose={closeVideoPopup}
				contentStyle={videoPopupStyle}>
				<VideoPlayer url={videoUrl} closePopup={closeVideoPopup} />
			</Popup>
		</div>
	);
};

export default TableContainer;
