export const SUCCESS_MESSAGE = 'Successfully Added to Bucket';
export const ERROR_MESSAGE = 'Please Select Bucket First';
export const PER_PAGE_ELEMENT = 30;
export const PAGE = 1;
export const PLACEHOLDER = 'Select Bucket for adding videos';
export const SCREEN_OPTIONS = {
	dashboard: 'dashboard',
};
export const TILES_TYPE = {
	vertical: 'vertical',
	horizontal: 'horizontal',
};
export const IS_DESKTOP_OR_LAPTOP_MEDIA = '1224px';

export const RESPONSIVE_VERTICAL = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 1350 },
		items: 7.35,
	},
	desktop: {
		breakpoint: { max: 1350, min: 1024 },
		items: 5.63,
		paritialVisibilityGutter: 26,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 5,
		paritialVisibilityGutter: 26,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 2,
		paritialVisibilityGutter: 26,
	},
};
export const RESPONSIVE_HORIZONTAL = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 2300 },
		items: 5.16,
	},
	desktop: {
		breakpoint: { max: 2300, min: 1600 },
		items: 5.16,
		paritialVisibilityGutter: 26,
	},
	tablet: {
		breakpoint: { max: 1599, min: 1024 },
		items: 3.8,
		paritialVisibilityGutter: 26,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 2,
		paritialVisibilityGutter: 26,
	},
};
