export const SEARCH_TOAST_CONSTANTS = {
	'Vidoes are added in bucket': 'Video(s) is successfully added to your bucket.',
	'Video is added in bucket': 'Video is successfully added to your bucket.',
	'Vidoes will be added in bucket shortly':
		'We are fetching all the details of this video(s). It will be added to your bucket soon.',
};

export const ERROR_VERBOSE =
	'Some error occured. Please try again with a different search query';
export const EMPTY_STATE_MESSAGE = 'No Results Found';
export const SEARCHBAR_PLACEHOLDER = 'Search your shows and movies by name';

export const FETCH_TILES_COUNT = 21;
