import React, { useState, useEffect } from 'react';
import './styles.scss';
import * as CONST from './constants';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';
import { ButtonLoader } from 'src/app/components/ButtonLoader';
import { Storage } from 'aws-amplify';
import { ERROR_MESSAGE } from 'src/app/common/constants/AppConstants';
import { getCommonMethod } from 'src/app/pages/Dashboard/Service';

import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import greenTick from 'src/app/assets/images/greenTick.svg';
import { profileBackgroundColor } from 'src/app/pages/AllUsers/constants';
import * as GLOBAL_CONSTANT from 'src/app/common/constants/AppConstants';
import { getJwtToken } from 'src/app/common/utils/sessionUtils';
import { getProfileInitials, getProfileBackground } from 'src/app/common/utils/methods';

export const EditCastProfile = ({
	data,
	closePopup,
	updatePopularity,
	buttonLoader,
	setToastInfo,
	fromTags = false,
}) => {
	const [urlValue, setUrlValue] = useState('');
	const [disableButton, setDisableButton] = useState(true);
	const [iconKey, setIconKey] = useState(null);
	const [isAvatar, setIsAvatar] = useState(false);
	const [thumbnail, setThumbnail] = useState(data?.thumbnail);
	const [progress, setProgress] = useState(0);
	const [finalProgress, setFinalProgress] = useState(0);
	const [showTick, setShowTick] = useState(false);
	const [finalAnim, setFinalAnim] = useState(false);
	const [tickAnim, setTickAnim] = useState('tick-class-open');
	const [showError, setShowError] = useState({ error: false, message: '', type: null });

	useEffect(() => {
		if (data?.thumbnail) {
			setIsAvatar(true);
		}
	}, [data]);

	useEffect(() => {
		if (finalAnim) {
			const interval = setInterval(() => {
				setFinalAnim(false);
			}, 3000);
			return () => clearInterval(interval);
		}
	}, [finalAnim]);

	useEffect(() => {
		if (progress === 0) {
			setDisableButton(true);
		}
	}, [progress]);

	useEffect(() => {
		if (tickAnim === 'tick-class-close') {
			setTimeout(() => {
				setIsAvatar(true);
				const bucket = fromTags
					? process.env.REACT_APP_TAGS_BUCKET
					: process.env.REACT_APP_CAST_BUCKET;
				setThumbnail(`${bucket}${iconKey}`);
				setShowTick(false);
				setFinalAnim(true);
				setTickAnim('tick-class-open');
			}, 900);
		}
	}, [tickAnim]);

	const addDefaultSrc = (ev) => {
		setIsAvatar(false);
		ev.target.className = 'cast-icon-error';
	};

	const onChangeUrl = (e) => {
		setUrlValue(() => {
			return e.target.value.trim();
		});
	};

	const onSelectingProfile = async (e) => {
		if (e.target.files && e.target.files[0]) {
			if (
				(e.target.files[0].type === 'image/jpeg' ||
					e.target.files[0].type === 'image/png') &&
				e.target.files[0].size < CONST.SIZE
			) {
				const file = e.target.files[0];
				try {
					setProgress(0);
					setFinalProgress(1);
					setShowTick(true);
					setUrlValue('');
					setShowError({ error: false, message: '', type: null });
					let res = '';
					res = await Storage.put(
						`${process.env.REACT_APP_CAST_BUCKET_ENV}/${file.name}`,
						file,
						{
							progressCallback(progress) {
								setFinalProgress(progress.total);
								setProgress(progress.loaded / progress.total);
							},
							bucket: fromTags ? CONST.PUBLIC_BUCKET : CONST.CAST_BUCKET,
							contentType: 'image/png', // contentType is optional
							customPrefix: {
								public: '',
							},
						},
					);
					if (res.key) {
						setIconKey(res.key);
						setTickAnim('tick-class-close');
						setFinalProgress(0);
						setDisableButton(false);
					}
				} catch (error) {
					console.log(error);
					setIconKey(null);
				}
			} else {
				setShowError({ error: true, message: CONST.SIZE_ERROR, type: 'size_error' });
			}
		}
	};

	const onCancel = () => {
		setUrlValue('');
		closePopup();
	};

	const handleKeyPress = async (e) => {
		if (e.key === 'Enter') {
			await copyAndUpload();
		}
	};

	const copyAndUpload = async () => {
		try {
			let response;
			setProgress(0);
			setShowError({ error: false, message: '', type: null });
			const token = await getJwtToken();
			try {
				response = await fetch(
					`${GLOBAL_CONSTANT.ROOT_URL}${
						GLOBAL_CONSTANT.CAST_AVATAR
					}?url=${encodeURIComponent(urlValue)}`,
					{
						headers: new Headers({
							Authorization: 'Bearer ' + token,
						}),
					},
				).then((x) => {
					if (x.status !== 200) {
						return Promise.reject(x.statusText);
					}
					return x;
				});
			} catch (e) {
				response = await fetch(`${urlValue}`);
			}

			const blob = await response.blob();
			const d = new Date();
			const time = d.getTime();
			let fileName;
			fromTags
				? (fileName = `${process.env.REACT_APP_CAST_BUCKET_ENV}/${data?.tag_id}-${time}.jpeg`)
				: (fileName = `${process.env.REACT_APP_CAST_BUCKET_ENV}/${data?.cast_id}-${time}.jpeg`);

			if (blob?.type !== `text/html`) {
				setShowTick(true);
				setFinalProgress(1);
				const res = await Storage.put(fileName, blob, {
					progressCallback(progress) {
						setFinalProgress(progress.total);
						setProgress(progress.loaded / progress.total);
					},
					bucket: fromTags ? CONST.PUBLIC_BUCKET : CONST.CAST_BUCKET,
					contentType: 'image/jpeg',
					customPrefix: {
						public: '',
					},
				});
				if (res.key) {
					setIconKey(res.key);
					setTickAnim('tick-class-close');
					setFinalProgress(0);
					setDisableButton(false);
				}
			} else {
				setShowError({ error: true, message: CONST.NON_IMAGE_ERROR, type: 'cors_error' });
			}
		} catch (e) {
			setShowError({ error: true, message: CONST.CORS_ERROR, type: 'cors_error' });
			setUrlValue('');
			setIconKey(null);
		}
	};

	const browseFile = () => {
		return (
			<div className="browse-file-wrapper">
				<div className="browse-heading">{CONST.BROWSE_HEADING}</div>
				<div className="browser">
					<label className="cast-file-picker">
						<input
							type="file"
							className="cast-file-picker-input"
							onChange={onSelectingProfile}
						/>
						<div className="file-picker-cast-outerdiv">
							<span className="file-picker-span">{CONST.BROWSE_FILE}</span>
						</div>
					</label>
				</div>
				{showError?.error && showError.type === 'size_error' && (
					<div className="cors-error">{showError.message}</div>
				)}
			</div>
		);
	};

	const copyUrl = () => {
		return (
			<div className="copy-url">
				<div className="browse-heading">{CONST.COPY_URL_HEADING}</div>
				<input
					placeholder={CONST.COPY_URL_PLACEHOLDER}
					className="add-url-input"
					type="text"
					value={urlValue}
					onChange={onChangeUrl}
					onKeyPress={(e) => handleKeyPress(e)}
				/>
				{showError?.error && showError.type === 'cors_error' && (
					<div className="cors-error">{showError.message}</div>
				)}
			</div>
		);
	};

	const showProfile = () => {
		return (
			<div className={`profile-container ${showTick ? 'profile-deamed' : ''}`}>
				{isAvatar ? (
					<img
						className={`image-profile ${data?.thumbnail ? '' : 'cast-icon-error'} ${
							finalAnim && !showTick ? 'image-profile-anim' : ''
						}`}
						alt="cast-profile"
						src={thumbnail}
						onError={addDefaultSrc}
					/>
				) : (
					<div
						className={`image-profile cast-icon-error`}
						style={{
							background: `${
								fromTags
									? getProfileBackground(data?.tag_name)
									: getProfileBackground(data?.name)
							}`,
						}}>
						<span className="cast-name-error-span">
							{fromTags
								? getProfileInitials(data?.tag_name)
								: getProfileInitials(data?.name)}
						</span>
					</div>
				)}
			</div>
		);
	};

	const showProgress = () => {
		return (
			<div className={`progress-bar-wrapper`}>
				{showProfile()}
				<div className="progress-bar-div">
					<CircularProgressbarWithChildren
						value={progress}
						maxValue={1}
						strokeWidth={5}
						styles={buildStyles({
							textColor: 'white',
							pathColor: '#4FD471',
						})}>
						{progress !== 1 && (
							<div className="progress-style">{`${Math.floor(progress * 100)}%`}</div>
						)}
						{progress === 1 && (
							<div>
								<img
									alt="green-tick"
									src={greenTick}
									className={`tick-class ${tickAnim}`}
								/>
							</div>
						)}
					</CircularProgressbarWithChildren>
				</div>
			</div>
		);
	};

	return (
		<div className="edit-cast-container">
			<div className="edit-cast-heading">{`Upload ${
				fromTags ? data?.tag_name : data?.name
			}'s Image`}</div>
			<img
				src={CrossIcon}
				alt="close"
				className="cross-icon-popup"
				onClick={() => {
					closePopup();
				}}
			/>
			<div className="seprator"></div>

			{showTick ? showProgress() : showProfile()}
			{browseFile()}
			{<div className="or-option">{CONST.OR}</div>}
			{copyUrl()}

			<div className="buttons">
				<button onClick={onCancel} className="cancel">
					{CONST.CANCEL}
				</button>
				{buttonLoader ? (
					<ButtonLoader />
				) : (
					<button
						onClick={() => {
							fromTags
								? updatePopularity(data?.tag_id, iconKey)
								: updatePopularity(data?.cast_id, data?.popularity, iconKey, data?.name);
						}}
						className={`${disableButton ? 'disable-button' : ''}`}>
						{CONST.UPLOAD}
					</button>
				)}
			</div>
		</div>
	);
};

export default EditCastProfile;
