import React, { useState, useEffect } from "react";
import "./styles.scss";
import DownArrow from "../../../assets/logos/DownArrow.svg";
import ClickAwayListener from "react-click-away-listener";

// to be used in screen selection of bucket
const CustomSelectBucket = ({
  placeholder,
  menuItems,
  defaultValue,
  handleToastDismiss,
  handleValueChanges,
  idFieldName,
  screenType,
}) => {
  const allScreenObj = { name: "All Screens" };
  allScreenObj[idFieldName ?? "_id"] = 0;
  const [showOptionDropdown, setShowOptionDropdown] = useState(false);
  const [activeOption, setActiveOption] = useState({ ...allScreenObj });
  const [dropDownItems, setDropDownItems] = useState([]);

  useEffect(() => {
    setActiveOption(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    let cancel = false;
    if (cancel) return;

    screenType !== "published"
      ? setDropDownItems([{ ...allScreenObj }, ...menuItems])
      : setDropDownItems([...menuItems]);

    return () => {
      cancel = true;
    };
  }, [menuItems]);

  const handleSelect = (selectedOptions) => {
    setActiveOption(selectedOptions);
    handleValueChanges(selectedOptions);
  };
  const handleDropdownChange = (state) => {
    if (handleToastDismiss) handleToastDismiss();
    setShowOptionDropdown((prev) => {
      const arrowEl = document.getElementById("select-box-bucket-down-arrow");
      if (prev) {
        arrowEl?.classList.remove("select-box-down-arrow-rotate");
        arrowEl?.classList.add("select-box-down-arrow-unrotate");
        return !prev;
      } else {
        if (state) {
          arrowEl?.classList.remove("select-box-down-arrow-unrotate");
          arrowEl?.classList.add("select-box-down-arrow-rotate");
          return !prev;
        } else return false;
      }
    });
  };
  // triggers on onClick of single select li
  const handleOptionChange = (option) => {
    if (handleSelect) handleSelect(option);
    handleDropdownChange(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => handleDropdownChange(false)}>
        <div>
          <span className="select-span" onClick={handleDropdownChange}>
            <div>
              <p className="selected-value">
                {activeOption?.name ? activeOption?.name : placeholder}
              </p>
            </div>
            <div>
              <img
                src={DownArrow}
                id="select-box-bucket-down-arrow"
                alt="Down Arrow"
              />
            </div>
          </span>
          {showOptionDropdown && (
            <div className="option-box-wrapper bucket-option-wrapper-size">
              <ul>
                {dropDownItems?.map((option, key) => (
                  <li
                    key={key}
                    className={
                      activeOption[idFieldName] === option[idFieldName]
                        ? "active-option"
                        : null
                    }
                    onClick={() => handleOptionChange(option)}
                  >
                    {activeOption[idFieldName] === option[idFieldName] ? (
                      <p style={{ color: "#F82C5E" }}>{option.name}</p>
                    ) : (
                      <p>{option.name}</p>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};

export default CustomSelectBucket;
