import React, { useState, useEffect } from 'react';
import './styles.scss';
import Vertmore from '../../../assets/logos/VertMore.svg';
import Kebab from '../../../assets/logos/Kebab.svg';
import CarouselMoreOptionBox from '../../atoms/CarouselMoreOptionBox';
import ClickAwayListener from 'react-click-away-listener';
import { STATUS_ARRAY } from './constant';
import { STATUS } from 'src/app/pages/Dashboard/Components/Bucket/constant';

const DataTableKebabMenu = ({
	visibilityStatus,
	openEditBucketModal,
	optionNotVisible,
	options,
	openConfirmationModal,
	openChangeStatusModal,
	isStatusPassed = false,
	status,
	divId = null,
}) => {
	const [showOptionDropdown, setShowOptionDropdown] = useState(false);

	useEffect(() => {
		if (divId) {
			const sectionOneEl = document.querySelector(divId);
			sectionOneEl?.addEventListener('scroll', (ev) => {
				if (showOptionDropdown) {
					setShowOptionDropdown(false);
				}
			});
		}
	}, [divId, showOptionDropdown]);

	const handleDropdownChange = (toggle = true) => {
		if (!toggle) {
			if (showOptionDropdown) {
				setShowOptionDropdown(false);
			}
		} else {
			setShowOptionDropdown(!showOptionDropdown);
		}
		visibilityStatus(!showOptionDropdown);
	};

	const handleSelect = (option) => {
		if (option?.value === 'edit') {
			openEditBucketModal();
		}
		if (option?.value === 'delete') {
			openConfirmationModal();
		}
		if (
			option?.value === 'published' ||
			option?.value === 'unpublished' ||
			option?.value === 'archived'
		) {
			if (status !== 'draft') {
				if (status !== STATUS.draft) {
					openChangeStatusModal(option?.value);
				} else {
					openChangeStatusModal();
				}
			} else {
				openChangeStatusModal();
			}
		}
		setShowOptionDropdown(false);
	};

	return (
		<ClickAwayListener onClickAway={() => handleDropdownChange(false)}>
			<div>
				<div
					className={`kebab-menu-icon-container ${isStatusPassed ? 'more-styling' : ''}`}
					onClick={() => handleDropdownChange(!showOptionDropdown)}>
					{!showOptionDropdown ? (
						<img src={Vertmore} alt="dots" className="dots" />
					) : (
						<img src={Kebab} className="selected-dots" alt="dots" />
					)}
				</div>
				{showOptionDropdown && (
					<div
						className={`table-kebab-dropdown ${
							isStatusPassed ? 'more-styling-dropdown' : ''
						}`}>
						<CarouselMoreOptionBox
							handleSelect={handleSelect}
							from={'carousel'}
							optionNotVisible={optionNotVisible}
							options={options}
						/>
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
};
export default DataTableKebabMenu;
