import React, { useEffect, useState, useCallback } from 'react';
import ContentManagementPresentation from './ContentManagementPresentation';
import InfiniteScroll from 'react-infinite-scroll-component';
import './ContentManagement.styles.scss';
import {
	fetchDataFromGetApi,
	postDataToApi,
	updateDataFromApi,
} from '../Dashboard/actionCreators';
import { ROOT_URL, CONTENT } from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
	IS_CONTENT_PUBLISHED,
	SET_CONTENT_MANAGEMENT_DATA,
} from '../Dashboard/actionTypes';
import { Loader } from '../../components/Loader';
import { AppButton } from '../../components/AppButton';
import Popup from 'reactjs-popup';
import DeleteConfirmation from '../../components/molecules/DeleteConfirmation';
import { POPUP } from '../../common/constants/PopupMessages';
import { PER_PAGE, popupContentStyle } from './constant';
import { Toast } from '../../components/Toast';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmptyString } from 'src/app/common/utils/methods';

const ContentManagement = () => {
	const dispatch = useDispatch();
	const buttonRef = useRef();
	const [isContentSaveError, setContentSaveError] = useState(false);
	const isMounted = useRef(false);
	const isSaveClicked = useRef(false);
	const [page, setPage] = useState(1);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		isError: false,
	});
	const [publishModalOpen, setPublishModalOpen] = useState(false);
	const [onPublishLoading, setOnPublishLoading] = useState(false);
	const [enablePublishButton, setEnablePublishButton] = useState(false);
	const [loading, setLoading] = useState(false);
	const [focus, setFocus] = useState(100);
	const [unsavedPopup, setUnsavedPopup] = useState({
		value: false,
		draftContent: '',
		screenId: '',
	});
	const isDataUpdated = useRef(false);
	const data = useSelector((state) => state?.contentManagement?.data);
	const isPublished = useSelector(
		({ contentManagement }) => contentManagement.isContentPublished,
	);
	const [redirectURL, setRedirectURL] = useState(null);
	const [isDraftState, setIsDraftState] = useState(0);
	const history = useHistory();

	const getData = useCallback(() => {
		if (!isDataUpdated.current) {
			setLoading(true);
		}
		fetchDataFromGetApi(
			`${ROOT_URL}${CONTENT}`,
			{
				page: page,
				perPage: PER_PAGE,
			},
			({ data, status }) => {
				if (status && data?.data) {
					setLoading(false);
					dispatch({ type: SET_CONTENT_MANAGEMENT_DATA, payload: data?.data });
				}
			},
		);
	}, [dispatch, page]);

	useEffect(() => {
		getData();
	}, [dispatch, getData, page]);

	useEffect(() => {
		if (data && data.results && data.results.length > 0) {
			const publish = [];
			data.results.forEach((item) => {
				if (item && item.screens) {
					const publishable = item.screens.filter((screen) => {
						return screen.contents[0].draft_content !== '';
					});
					if (publishable.length > 0) {
						publish.push(publishable);
					}
				}
			});
			if (publish) {
				setIsDraftState(publish.length);
			}
		}
	}, [data]);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setShowToast(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const handleToastDismiss = () => {
		setShowToast({ show: false, message: '' });
	};

	const fetchMoreData = () => {
		setPage((prev) => prev + 1);
	};

	const openPublishModal = () => {
		setPublishModalOpen(true);
	};

	const closeDeleteConfirmationModal = () => {
		setPublishModalOpen(false);
		isSaveClicked.current = false;
		if (redirectURL) {
			let path = redirectURL;
			history.push(path);
		}
	};

	const saveUnsavedChanges = () => {
		setPublishModalOpen(false);
		isSaveClicked.current = true;
		updateDataFromApi(
			`${ROOT_URL}${CONTENT}`,
			{
				screen_content_id: unsavedPopup.screenId,
				draft_content: unsavedPopup.draftContent,
			},
			({ status, message, response }) => {
				if (status && message) {
					isDataUpdated.current = true;
					setEnablePublishButton(true);
					setUnsavedPopup({ value: false, screenId: '', draftContent: '' });
					getData();
					setShowToast({
						message,
						isError: false,
						show: true,
					});
					dispatch({ type: IS_CONTENT_PUBLISHED, payload: false });
				} else {
					setUnsavedPopup({ value: false, screenId: '', draftContent: '' });
					setShowToast({
						message: response?.data?.message,
						isError: true,
						show: true,
					});
				}
			},
		);
		let path = redirectURL;
		history.push(path);
	};

	const onBlurEditableTextBox = (screenId, draftContent) => {
		if (window.event && window.event.relatedTarget) {
			window.event.preventDefault();
			setUnsavedPopup({ value: true, screenId, draftContent });
			setPublishModalOpen(true);
			setRedirectURL(window.event.relatedTarget.pathname);
			return;
		}
		updateDataFromApi(
			`${ROOT_URL}${CONTENT}`,
			{
				screen_content_id: screenId,
				draft_content: draftContent,
			},
			({ status, message, response }) => {
				if (status && message) {
					isDataUpdated.current = true;
					setEnablePublishButton(true);
					getData();
					setShowToast({
						message,
						isError: false,
						show: true,
					});
				} else {
					setContentSaveError(true);
					setShowToast({
						message: response?.data?.message,
						isError: true,
						show: true,
					});
				}
			},
		);

		dispatch({ type: IS_CONTENT_PUBLISHED, payload: false });
	};

	const onPublishContent = () => {
		setOnPublishLoading(true);
		postDataToApi(`${ROOT_URL}${CONTENT}`, {}, ({ status }, response) => {
			setOnPublishLoading(false);
			closeDeleteConfirmationModal();
			setEnablePublishButton(false);
			if (status) {
				setIsDraftState(0);
				setShowToast({
					message: POPUP.CONTENT_SUCCESSFULLLY_PUBLISHED,
					isError: false,
					show: true,
				});
			} else if (response) {
				setShowToast({
					message: response?.message,
					isError: true,
					show: true,
				});
			}
		});
		dispatch({ type: IS_CONTENT_PUBLISHED, payload: true });
	};

	const onFocus = (value) => {
		setFocus(value);
	};

	return (
		<div className="content-management-container">
			<div className="header-block">
				<h1 className="header">Content</h1>
				<AppButton
					label="Publish"
					onClick={openPublishModal}
					className="publish-button"
					isDisabled={
						isDraftState > 0 || !isPublished ? false : !enablePublishButton || isPublished
					}
				/>
			</div>
			<div className="content-block">
				{loading && (
					<div className="loader">
						<Loader />
					</div>
				)}
				{data && data.results && (
					<InfiniteScroll
						dataLength={data && data.results && data.results.length}
						next={fetchMoreData}
						hasMore={true}
						loader={<Loader />}
						scrollableTarget="content-block"
						className="infinite-scroll">
						{data &&
							data?.results.map(({ module_name, module_id, screens }) => {
								return (
									<ContentManagementPresentation
										key={module_id}
										module_name={module_name}
										module_id={module_id}
										screens={screens}
										onBlurEditableTextBox={onBlurEditableTextBox}
										focus={focus}
										setFocus={onFocus}
										isMounted={isMounted}
										buttonRef={buttonRef}
										isSaveClicked={isSaveClicked}
										isContentSaveError={isContentSaveError}
										setContentSaveError={setContentSaveError}
									/>
								);
							})}
					</InfiniteScroll>
				)}
			</div>
			<Popup
				className="popup-content-container"
				open={publishModalOpen}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={popupContentStyle}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmationModal}
					onDelete={() => {
						unsavedPopup.value ? saveUnsavedChanges() : onPublishContent();
					}}
					loading={onPublishLoading}
					message={
						unsavedPopup.value
							? POPUP.YOU_HAVE_UNSAVED_CHANGES
							: POPUP.PUBLISH_ALL_UPDATES
					}
					buttonText={unsavedPopup.value ? 'Save' : 'Publish'}
				/>
			</Popup>
			{showToast.show ? (
				<Toast
					message={showToast.message}
					onDismiss={handleToastDismiss}
					isError={showToast.isError}
				/>
			) : null}
		</div>
	);
};

export default ContentManagement;
