export const BUCKET_NAME_MAPPER = {
	new: 'new', //'New Arrivals',
	'top-rated': 'imdb', //'IMDB Top Rated',
	trending: 'trending',
	popular: 'popular',
	new: 'New Arrivals',
	imdb: 'IMDB Top Rated',
	'trending shows': 'trending-shows',
	'trending movies': 'trending-movies',
	'popular movies': 'popular-movies',
	'popular shows': 'popular-shows',
	'new shows': 'new-shows',
	'new movies': 'new-movies',
	'top-rated movies': 'imdb-top-rated-movies',
	'top-rated shows': 'imdb-top-rated-shows',
	'free shows': 'shows-on-free-services',
	'free movies': 'movies-on-free-services',
};
