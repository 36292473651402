import React, { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import './styles.scss';
import PrimaryTextButton from '../../atoms/PrimaryTextButton';
import ResetAllButton from '../../atoms/ResetAllButton';
import moment from 'moment';
import { DATE_FORMATS } from '../../../common/constants/AppConstants';

const CustomDateRangePicker = ({
	defaultValue,
	isVisible,
	handleDateChange,
	handleVisibiltyChange,
	fromAllUser = false,
}) => {
	const [state, setState] = useState(null);
	useEffect(() => {
		defaultValue
			? setState([
					{
						startDate: new Date(defaultValue?.startDate),
						endDate: new Date(defaultValue?.endDate),
						key: 'selection',
					},
			  ])
			: setState([
					{
						startDate: null,
						endDate: null,
						key: 'selection',
					},
			  ]);
	}, [defaultValue]);

	// when calendar value is changed
	const onChange = (value) => {
		if (value.selection.startDate !== value.selection.endDate && !fromAllUser) {
			handleDateChange(value.selection);
		}
		setState([value.selection]);
	};

	const handleSave = () => {
		if (state) {
			handleDateChange(state[0]);
		}
		handleClose();
	};

	const handleClose = (type = 'save', flag = false) => {
		if (type === 'cancel') {
			setState([
				{
					startDate: null,
					endDate: null,
					key: 'selection',
				},
			]);
			handleDateChange({
				startDate: null,
				endDate: null,
				key: 'selection',
			});
		}
		const buttons = document.querySelector('.action-button');
		if (buttons) {
			buttons.style.opacity = 1;
			buttons.style.pointerEvents = 'all';
			buttons.style.cursor = 'cursor';
		}
		handleVisibiltyChange(flag);
	};

	return (
		<>
			{isVisible && (
				<div
					className={`date-range-div ${fromAllUser ? 'date-range-div-all-users' : ''}`}>
					{!fromAllUser && (
						<DateRange
							onChange={onChange}
							className={'dateRangeWrapper'}
							showSelectionPreview={true}
							showMonthAndYearPickers={false}
							months={2}
							ranges={state}
							color={'#F82C5E'}
							rangeColors={['#1F1F23']}
							direction="horizontal"
							minDate={new Date()}
							shownDate={new Date()}
						/>
					)}
					{fromAllUser && (
						<DateRange
							onChange={onChange}
							className={'dateRangeWrapper'}
							showSelectionPreview={true}
							showMonthAndYearPickers={false}
							months={2}
							ranges={state}
							color={'#F82C5E'}
							rangeColors={['#1F1F23']}
							direction="horizontal"
							maxDate={new Date()}
							shownDate={new Date()}
						/>
					)}
					<div className={'action-buttons'}>
						<ResetAllButton label={'Cancel'} handleClick={() => handleClose('cancel')} />
						<PrimaryTextButton
							disabled={false}
							label={'Save'}
							main={true}
							handleClick={() => handleSave()}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default CustomDateRangePicker;
