import React, { useState, useEffect } from 'react';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'src/app/components/Loader';
import Table from 'src/app/components/Table';
import Row from './Row';
import { putCommonMethod } from 'src/app/pages/Dashboard/Service';
import * as CONST from '../constant';
import './Table.styles.scss';

const TableContainer = ({
	shuffle,
	tableData,
	fetchMoreData,
	hasMore,
	deleteAll,
	selectedCards,
	setDeleteAll,
	onSelecting,
	selectToDeleteAll,
	setUncheckedOnSelectOne,
	unCheckedOnSelectOne,
	handleAddButton,
	fromBucket,
	bucketId,
	handleToast,
	setVideoDetail,
	openDetail,
}) => {
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tableConfig, setTableConfig] = useState(
		CONST.VIDEO_DETAILS_TABLE_COLUMNS_CONFIG_CHANNEL_DATA,
	);

	useEffect(() => {
		if (tableData) {
			setTableDisplayData([...tableData]);
			setLoading(false);
		}
	}, [tableData, setLoading]);

	useEffect(() => {
		if (fromBucket) {
			setTableConfig(CONST.VIDEO_DETAILS_TABLE_COLUMNS_CONFIG);
		} else {
			setTableConfig(CONST.VIDEO_DETAILS_TABLE_COLUMNS_CONFIG_CHANNEL_DATA);
		}
	}, [fromBucket]);

	const dragEndHandler = async (result) => {
		if (!result.destination) return;

		const items = Array.from(tableDisplayData);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setTableDisplayData([...items]);

		const draggedItem = tableDisplayData[result.source?.index];
		let videoRank;
		if (result.destination?.index === 0) {
			videoRank =
				items[result.destination?.index + 1]['video_sequence'] > 1
					? items[result.destination?.index + 1]['video_sequence'] - 1 || 0.5
					: items[result.destination?.index + 1]['video_sequence'] / 1.01;
		} else if (result.destination?.index === tableDisplayData.length - 1) {
			videoRank = items[result.destination?.index - 1]['video_sequence'] + 1 || 2;
		} else {
			videoRank =
				(items[result.destination?.index - 1]['video_sequence'] +
					items[result.destination?.index + 1]['video_sequence']) /
					2 || 1;
		}

		try {
			const resp = await putCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.UPDATE_VIDEO_RANK}`,
				{
					bucket_id: bucketId,
					video_id: draggedItem?.videoId || draggedItem?.video_id,
					video_rank: videoRank,
				},
			);
			const arr = [...items];
			arr[result.destination?.index]['video_sequence'] = videoRank;
			setVideoDetail(arr);
		} catch (e) {
			handleToast(true, GLOBAL_CONST.ERROR_MESSAGE, true);
		}
	};

	const renderTable = () => {
		if (loading) {
			return <EmptyState columns={tableConfig} renderColumns={2} />;
		} else {
			if (tableDisplayData?.length > 0) {
				return tableDisplayData?.map((item, index) => {
					return (
						<Row
							key={item && `${item.video_id || item.videoId}`}
							data={{ ...item, index }}
							id={item?.video_id || item.videoId}
							shuffleMode={shuffle}
							handleAddButton={handleAddButton}
							deleteAll={deleteAll}
							setUncheckedOnSelectOne={setUncheckedOnSelectOne}
							selectedCards={selectedCards}
							length={tableDisplayData?.length}
							onSelecting={onSelecting}
							setDeleteAll={setDeleteAll}
							fromBucket={fromBucket}
							openDetail={openDetail}
						/>
					);
				});
			}
		}
	};

	if (shuffle) {
		return (
			<DragDropContext onDragEnd={dragEndHandler}>
				<Table isRenderable={true} shuffleMode={shuffle} changeBG={true}>
					<Droppable droppableId="table">
						{(provided, snapshot) => (
							<div
								id="scrollableDivVideoList"
								className="table"
								ref={provided.innerRef}
								{...provided.droppableProps}>
								<InfiniteScroll
									dataLength={tableData?.length}
									next={fetchMoreData}
									hasMore={hasMore}
									scrollThreshold={0.2}
									loader={<Loader loadMore={true} />}
									scrollableTarget="scrollableDivVideoList">
									<table className="table-bucket-details-yt" id="">
										<thead>
											<tr>
												{tableConfig?.map((column) => {
													return (
														<Column
															key={column.key}
															config={column}
															shuffleMode={shuffle}
															isWaitlist={false}
															fromBuck={true}
															selectToDeleteAll={selectToDeleteAll}
															deleteAll={deleteAll}
															unCheckedOnSelectOne={unCheckedOnSelectOne}
														/>
													);
												})}
											</tr>
										</thead>

										<tbody>
											{tableDisplayData.map((item, index) => {
												return (
													<Draggable
														draggableId={
															item.video_id.toString() || item?.videoId.toString()
														}
														index={index}
														key={item.video_id || item?.videoId}>
														{(provided, snapshot) => (
															<Row
																key={item && `${item.video_id || item?.videoId}`}
																data={{ ...item, index }}
																id={item?.video_id || item?.videoId}
																shuffleMode={shuffle}
																innerref={provided.innerRef}
																styleset={provided.draggableProps.style}
																deleteAll={deleteAll}
																snap={snapshot}
																handleAddButton={handleAddButton}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																setUncheckedOnSelectOne={setUncheckedOnSelectOne}
																selectedCards={selectedCards}
																length={tableDisplayData?.length}
																onSelecting={onSelecting}
																setDeleteAll={setDeleteAll}
																fromBucket={fromBucket}
																openDetail={openDetail}
															/>
														)}
													</Draggable>
												);
											})}
										</tbody>
									</table>
								</InfiniteScroll>
							</div>
						)}
					</Droppable>
				</Table>
			</DragDropContext>
		);
	} else {
		return (
			<Table isRenderable={true} shuffleMode={shuffle} changeBG={true}>
				<div id="scrollableDivVideoList">
					<InfiniteScroll
						dataLength={tableData?.length}
						next={fetchMoreData}
						hasMore={hasMore}
						scrollThreshold={0.5}
						loader={<Loader loadMore={true} />}
						scrollableTarget="scrollableDivVideoList">
						<table className="table-bucket-details-yt">
							<thead>
								<tr>
									{tableConfig?.map((column) => {
										return (
											<Column
												key={column.key}
												config={column}
												shuffleMode={shuffle}
												isWaitlist={false}
												fromBuck={true}
												selectToDeleteAll={selectToDeleteAll}
												deleteAll={deleteAll}
												unCheckedOnSelectOne={unCheckedOnSelectOne}
												status={!fromBucket}
											/>
										);
									})}
								</tr>
							</thead>
							<tbody>{renderTable()}</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</Table>
		);
	}
};

export default TableContainer;
