import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ReactPlayer from 'react-player';
import { getYouTubeData } from 'src/app/pages/Dashboard/Service';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';
import { IMAGE_PLACEHOLDER_HORIZONTAL } from 'src/app/common/constants/AppConstants';
import BackArrowWhite from 'src/app/assets/images/BackArrowWhite.svg';
import YoutubeIcon from 'src/app/assets/images/YoutubeIcon.svg';
import './styles.scss';

const ShortformVideoPopup = ({ id, onClose }) => {
	const [data, setData] = useState({});
	const [playVideo, setPlayVideo] = useState(false);

	useEffect(() => {
		fetchVideoDetails();
	}, [id]);

	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
	};

	const handlePlay = () => {
		setPlayVideo(!playVideo);
	};

	const fetchVideoDetails = async () => {
		try {
			const resp = await getYouTubeData(
				`${process.env.REACT_APP_RAPID_API_VIDEO_DETAILS}`,
				{ id: id },
				true,
			);
			if (resp) {
				setData(resp?.items[0]);
			}
		} catch (error) {}
	};

	return (
		<div className="yt-video-popup">
			<div className="cross-icon-wrapper" onClick={onClose}>
				<img src={CrossIcon} alt="close" className="cross-icon" />
			</div>
			{!_.isEmpty(data) && (
				<div className="content-container">
					<div className="thumbnail">
						{!playVideo && (
							<>
								<div className="play-icon" onClick={handlePlay}>
									<img src={YoutubeIcon} alt="Play" className="play-icon" />
								</div>
								<img
									src={
										data?.snippet?.thumbnails?.maxres?.url ||
										data?.snippet?.thumbnails?.high?.url
									}
									alt={data?.snippet?.title}
									className="video-thumb"
									onError={addDefaultSrc}
								/>
							</>
						)}
						{playVideo && (
							<>
								<div className="backIcon" onClick={handlePlay}>
									<img src={BackArrowWhite} alt={'Back'} className="arrow" />
								</div>
								<ReactPlayer
									url={`https://www.youtube.com/watch?v=${data?.id}`}
									playing={true}
									controls={true}
									playsinline={true}
									width={700}
									height={350}
								/>
							</>
						)}
					</div>
					<div className="video-details">
						<div className="video-details-heading">{data?.snippet?.title}</div>
						<div className="video-details-channel-name">
							{`- By ${data?.snippet?.channelTitle}`}
						</div>
						<div className="desc">{data?.snippet?.description}</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ShortformVideoPopup;
