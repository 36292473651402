import React, { useState, useEffect } from 'react';
import { profileBackgroundColor } from 'src/app/pages/AllUsers/constants';
import { getProfileInitials, getProfileBackground } from 'src/app/common/utils/methods';
import './styles.scss';

export const CastProfile = ({ data }) => {
	const [isAvatar, setIsAvatar] = useState(false);

	useEffect(() => {
		if (data?.thumbnail) {
			setIsAvatar(true);
		}
	}, [data]);

	const addDefaultSrc = (ev) => {
		setIsAvatar(false);
		ev.target.className = 'user-icon-error';
	};

	return (
		<div className="cast-profile-wrapper">
			{isAvatar ? (
				<img
					className="profile-img"
					alt="image"
					src={data?.thumbnail}
					onError={addDefaultSrc}
				/>
			) : (
				<div
					className={`profile-img user-icon-error`}
					style={{ background: `${getProfileBackground(data?.name)}` }}>
					<span className="user-name-error-span">{getProfileInitials(data?.name)}</span>
				</div>
			)}
			<div className="cast-name">{data?.name}</div>
		</div>
	);
};

export default CastProfile;
