import React, { useState, useRef } from 'react';
import { RECORD_TO_FETCH } from '../../../../common/constants/BucketListingConstant';
import {
	getPublisheddTableDataOnScreenChange,
	getPublishedTableData,
} from '../../../../pages/Dashboard/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../../components/Table';
import { useEffect } from 'react';
import BUCKET_TABLE_COLUMNS_CONFIG, { SCREEN_CHANGE_ENUM } from './constant';
import {
	ROOT_URL,
	PUBLISHED_BUCKETS_URL,
} from '../../../../common/constants/AppConstants';
import './styles.scss';

const TableContainer = ({
	selectedScreenId,
	setScreenChange,
	screenChange,
	page,
	setPage,
	loading,
	setLoading,
	openEditModal,
	openDeleteModal,
	isUpdatedData,
	openChangeStatusModal,
}) => {
	const mount = useRef();
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.reservations?.publishedData);
	const totalBucket = useSelector((state) => state.reservations?.totalBucket);
	const newDataLength = useSelector(
		(state) => state.reservations?.publishedNewDataLength,
	);
	const [tableDisplayData, setTableDisplayData] = useState([]);

	const goToNextPage = () => {
		setScreenChange('page');
		setPage((prev) => prev + 1);
	};

	useEffect(() => {
		if (tableData && tableData.length > 0) {
			setLoading(false);
			setTableDisplayData([...tableData]);
		}
	}, [tableData, setLoading]);

	useEffect(() => {
		if (screenChange === SCREEN_CHANGE_ENUM.page && page > 1) {
			dispatch(
				getPublishedTableData(`${ROOT_URL}${PUBLISHED_BUCKETS_URL}`, {
					page: page,
					perPage: RECORD_TO_FETCH,
					screen_id: selectedScreenId,
				}),
			);
		} else if (
			screenChange === SCREEN_CHANGE_ENUM.screenId ||
			screenChange === SCREEN_CHANGE_ENUM.update
		) {
			setLoading(true);
			dispatch(
				getPublisheddTableDataOnScreenChange(`${ROOT_URL}${PUBLISHED_BUCKETS_URL}`, {
					page: page,
					perPage: RECORD_TO_FETCH,
					screen_id: selectedScreenId,
				}),
			);
		}
	}, [dispatch, page, selectedScreenId, screenChange, setLoading]);

	useEffect(() => {
		mount.current = 'mount';
		setLoading(true);

		return () => (mount.current = 'unmount');
	}, [setLoading, setScreenChange]);

	return mount.current === 'mount' ? (
		<Table
			tableDisplayData={tableDisplayData}
			setTableDisplayData={setTableDisplayData}
			loading={loading}
			shuffleMode
			columnsConfig={BUCKET_TABLE_COLUMNS_CONFIG}
			totalLength={totalBucket}
			goToNextPage={goToNextPage}
			setScreenChange={setScreenChange}
			openEditModal={openEditModal}
			openDeleteModal={openDeleteModal}
			newDataLength={newDataLength}
			isUpdatedData={isUpdatedData}
			tableData={tableData}
			openChangeStatusModal={openChangeStatusModal}
			className="published-table"
		/>
	) : null;
};

export default TableContainer;
