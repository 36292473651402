import { getYouTubeData } from 'src/app/pages/Dashboard/Service';

export const youtubeData = async (params) => {
	try {
		const res = await getYouTubeData(process.env.REACT_APP_YOUTUBE_SEARCH, params, true);
		const transformedData = youtubeSearch(res);
		return transformedData;
	} catch (error) {}
};

const youtubeSearch = (res) => {
	const { items } = res || {};
	const transformedData = items?.map((item) => {
		return {
			channelId: item?.snippet?.channelId,
			channelTitle: item?.snippet?.channelTitle,
			description: item?.snippet?.description,
			liveBroadcastContent: item?.snippet?.liveBroadcastContent,
			publishTime: item?.snippet?.publishTime,
			publishedAt: item?.snippet?.publishedAt,
			title: item?.snippet?.title,
			videoId: item?.id.videoId,
			thumbnail:
				item?.snippet.thumbnails?.high?.url ||
				item?.snippet.thumbnails?.medium?.url ||
				item?.snippet.thumbnails?.default?.url,
			nextPageToken: res?.nextPageToken || '',
		};
	});

	return transformedData;
};
