import React, { useState, useEffect } from 'react';
import './styles.scss';
import { getRowClassName, getYear } from 'src/app/common/utils/methods';
import * as CONST from '../Constant';
import AddIcon from 'src/app/assets/logos/Add.svg';
import FilePickerIcon from 'src/app/assets/images/FilePickerIcon.svg';
import { transitionOccured, selectCards } from 'src/app/common/utils/methods';
import GrabIcon from 'src/app/assets/logos/Grab.svg';

export const Row = ({
	data,
	id,
	shuffleMode,
	innerref,
	styleset,
	snap,
	handleAddButton,
	deleteAll,
	setUncheckedOnSelectOne,
	selectedCards,
	length,
	onSelecting,
	setDeleteAll,
	...other
}) => {
	const [shuffleClass, setShuffleClass] = useState('');
	const [selectionValue, setSelectionValue] = useState('');

	useEffect(() => {
		if (selectedCards?.length > 0) {
			const cards = selectCards(selectedCards, id);
			setSelectionValue(cards);
		}
	}, [selectedCards, selectionValue]);

	useEffect(() => {
		setSelectionValue(deleteAll);
	}, [deleteAll]);

	const onSelectionChange = (e) => {
		if (deleteAll && !e.target.checked) {
			setUncheckedOnSelectOne(false);
			setDeleteAll(false);
		}
		if (deleteAll && e.target.checked) {
			if (selectedCards?.length + 1 === length) {
				setUncheckedOnSelectOne(true);
			}
		}
		setSelectionValue(e.target.checked);
		onSelecting({ id: e.target.id, value: e.target.checked });
	};

	useEffect(() => {
		if (shuffleMode) {
			if (transitionOccured('rowShuffle', 'get') && data.index === 1) {
				setTimeout(() => {
					setShuffleClass('translateX');
				}, 100);
				setTimeout(() => {
					setShuffleClass('');
				}, 1000);
				transitionOccured('rowShuffle', 'set');
			}
		}
	}, [shuffleMode]);

	const addDefaultSrc = (ev) => {
		ev.target.className = 'tag-icon-error';
		ev.target.src = FilePickerIcon;
	};

	function getStyle(style, snapshot) {
		if (!snapshot.isDropAnimating) {
			return style;
		}
		return {
			...style,
			// cannot be 0, but make it super tiny
			transitionDuration: `0.001s`,
		};
	}

	return (
		<tr
			className={`${getRowClassName(data?.index)} ${shuffleClass}`}
			ref={innerref}
			style={shuffleMode ? getStyle(styleset, snap) : null}
			{...other}>
			<td>
				<span className="checkbox">
					{shuffleMode ? (
						<img src={GrabIcon} className="grab-icon" alt="Grab Icon" />
					) : (
						<input
							id={id}
							type="checkbox"
							className="name-checkbox-row"
							value={selectionValue}
							onChange={onSelectionChange}
							alt="selection"
							checked={selectionValue}
						/>
					)}

					<div className="title-container">
						<div className="title-image-container">
							<img
								src={data.thumbnail || FilePickerIcon}
								alt={'Title Image'}
								onError={addDefaultSrc}
								className={`${data.thumbnail ? '' : 'icon-error'}`}
							/>
						</div>
						<p className="title-name">{data?.title}</p>
					</div>
				</span>
			</td>

			<td className="year-row">
				<div className="year">{getYear(data?.released_date)}</div>
			</td>

			<td className="ratings-row">
				<div className="rating">{JSON.parse(data?.metadata_json)?.pg_rating}</div>
			</td>

			<td className="imdb-row">
				<div className="imdb-rating">{data?.imdb_rating}</div>
			</td>

			<td className="row-options">
				<img
					src={AddIcon}
					alt={'add icon'}
					onClick={() => handleAddButton(data?.video_id || data)}
				/>
			</td>
		</tr>
	);
};

export default Row;
