import React, { useState } from "react";
import "./styles.scss";

const CarouselMoreOptionsBox = ({
  from,
  closeDropdown,
  options,
  handleSelect,
  optionNotVisible,
}) => {
  const [activeOption, setActiveOption] = useState("");
  const columns = optionNotVisible && optionNotVisible.value && optionNotVisible.column.split(",");
  const optionItems =
    optionNotVisible && optionNotVisible.value
      ? options.filter((option) => !columns.includes(option.value.toLowerCase()))
      : options;
  const [menuItems] = useState(optionItems);

  const handleOptionChange = (option) => {
    setActiveOption(option.value);
    if (handleSelect) handleSelect(option);
    if (closeDropdown) closeDropdown(false);
  };
  return (
    <div
      className={`popup-options ${
        from === "dashboard"
          ? "dashboard-option-wrapper-size"
          : "option-wrapper-size"
      }`}
    >
      <ul>
        {menuItems?.map((option) => (
          <li
            key={option.value}
            className={activeOption === option.value ? "active-option" : null}
            onClick={() => handleOptionChange(option)}
          >
            <p>{option.label}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CarouselMoreOptionsBox;
