import moment from 'moment';
import 'moment-timezone';
import { DATE_FORMATS } from '../constants/AppConstants';

// get current date
export const getCurrentDateInDateObj = () => {
	return new Date();
};

// get passed date in date obj
export const getDateInDateObj = (date) => {
	return new Date(date);
};

export const getFormattedDate = (date, format) => {
	if (date) {
		const date_object = getDateInDateObj(date);
		return moment(date_object).format(format);
	}
};
/** as it does not have the timestamp value it is been converted into the next day there need to subtract 1 day */
export const getCurrentDate = (date_object = getCurrentDateInDateObj()) => {
	return moment(date_object).format('MM/DD/YY');
};

/** get increment dates by the passed arg in date obj */
export const getIncrementDateByXinDateObj = (incrementBy) => {
	const currDate = getCurrentDateInDateObj();
	const tomorrowsDate = currDate.setDate(currDate.getDate() + incrementBy);
	return getDateInDateObj(tomorrowsDate);
};

// get timezone region
export const getTimezoneOffsetRegion = () => {
	return moment.tz.guess('Z');
};

/**
 *
 * @description getDateRange is only used when there is no timestamp in the date format, therefore need to subtract 1 day to the date as the moment will consider time to be always 12 which will change the date to the next day.
 * @returns date range in string
 */
export const getDateRange = (data) => {
	let dateRange = '';
	const fromDate = moment(data.from_date)
		.subtract(1, 'days')
		.format(DATE_FORMATS.monthDayYear);
	if (data.from_date) {
		dateRange = fromDate;
	}
	if (data.from_date && data.to_date) {
		dateRange = `${fromDate} - ${moment(data.to_date)
			.subtract(1, 'days')
			.format(DATE_FORMATS.monthDayYear)}`;
	}
	return dateRange;
};

export const newDateRange = (data) => {
	let dateRange = '';
	const fromDate = moment(data?.from_date || data?.from_Date)
		.utc()
		.format(DATE_FORMATS.monthDayYear); // store localTime
	if (data?.from_date || data?.from_Date) {
		dateRange = fromDate;
	}
	if ((data?.from_date || data?.from_Date) && data?.to_date) {
		dateRange = `${fromDate} - ${moment(data.to_date)
			.utc()
			.format(DATE_FORMATS.monthDayYear)}`;
	}
	return dateRange;
};
