import { AppButton } from '../../components/AppButton';
import React, {
	useEffect,
	useCallback,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from 'react';
import Carousel from 'react-multi-carousel';
import { responsiveVertical } from './constant';
import CustomDot from '../../components/CustomDot';
import { isEmptyString } from 'src/app/common/utils/methods';

const ContentManagementPresentation = ({
	module_name,
	module_id,
	screens,
	onBlurEditableTextBox,
	focus,
	setFocus,
	isMounted,
	buttonRef,
	isSaveClicked,
	isContentSaveError,
	setContentSaveError,
}) => {
	const carouselRef = useRef();

	return (
		<div className="content">
			<div className="left-content">
				<div key={module_id} className="screen-details">
					<h3 className="heading">{module_name}</h3>
					<div className="sub-heading">
						For edit your content, click on the textual area
					</div>
					<div className="screens">
						{screens.map(({ screen_module_id, screen_name, contents }, index) => {
							return (
								<div key={screen_module_id}>
									<div className="screen-name">{screen_name}</div>
									<div
										className={`screen-content ${
											focus === index ? 'border' : 'no-border'
										}`}
										id={`screen-content-${index}`}>
										{contents.map(({ draft_content, content, screen_content_id }) => {
											return (
												<EditableContentTextBox
													key={screen_content_id}
													data={isEmptyString(draft_content) ? draft_content : content}
													id={`text-${screen_content_id}`}
													onBlurEditableTextBox={onBlurEditableTextBox}
													index={index}
													buttonRef={buttonRef}
													carouselRef={carouselRef}
													isSaveClicked={isSaveClicked}
													isContentSaveError={isContentSaveError}
													setContentSaveError={setContentSaveError}
												/>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div></div>
			<div className="right-content">
				<Carousel
					ref={carouselRef}
					additionalTransfrom={0}
					responsive={responsiveVertical}
					centerMode={false}
					slidesToSlide={1}
					swipeable={false}
					draggable={false}
					minimumTouchDrag={1}
					containerClass="carousel-container"
					itemClass="carousel-item"
					showDots
					customRightArrow={
						<CustomArrow
							isMounted={isMounted}
							setFocus={setFocus}
							focus={focus}
							label="Next"
							ref={buttonRef}
						/>
					}
					customLeftArrow={
						<CustomArrow
							isMounted={isMounted}
							setFocus={setFocus}
							focus={focus}
							label="Previous"
							ref={buttonRef}
						/>
					}
					customDot={<CustomDot isMounted={isMounted} setFocus={setFocus} />}>
					{screens
						.filter(({ thumbnail }) => {
							return thumbnail !== null;
						})
						.map(({ screen_module_id, thumbnail, contents }, index) => {
							return (
								<>
									<img
										key={screen_module_id}
										id={`image-${screen_module_id}`}
										className={`slide-image-${index} screen-content-${index}`}
										src={thumbnail}
										alt="screen"
									/>
									<div className="image-content">
										{contents &&
										contents.length > 0 &&
										isEmptyString(contents[0].draft_content)
											? contents[0].draft_content
											: contents[0].content}
									</div>
								</>
							);
						})}
				</Carousel>
			</div>
		</div>
	);
};

const EditableContentTextBox = ({
	data,
	id,
	onBlurEditableTextBox,
	index,
	buttonRef,
	carouselRef,
	isSaveClicked,
	isContentSaveError,
	setContentSaveError,
}) => {
	const [enableEditing, setEnableEditing] = useState(false);
	const [content, setContent] = useState(data);
	const textAreaAdjust = (element) => {
		element.style.height = element.scrollHeight + 'px';
	};

	const onBlur = (e) => {
		if (content !== data) {
			onBlurEditableTextBox(id && id.split('-')[1], content, e);
		}
	};

	const updateDataInComponent = useCallback(() => {
		const element = document.querySelector(`#${id}-copy`);
		const editableElement = document.querySelector(`#${id}`);
		if (element) {
			/** Change  data.includes to content.includes
			 * and data.replace to content.replace in order to fix the problem of
			 * Privacy Policy here not changing instantly.
			 */
			if (!enableEditing && content.includes('Privacy Policy here')) {
				element.innerHTML = content.replace(
					'Privacy Policy here',
					`<span class="privacy-policy-link">Privacy Policy here</span>`,
				);
			}
			textAreaAdjust(element);
		}
		// when there is any error in saving content, reset it with the data from api.
		if (isContentSaveError) {
			setContent(data);
			setContentSaveError(false);
			editableElement.value = `${data}`;
		}

		if (editableElement) {
			if (!isContentSaveError) {
				editableElement.textContent = `${content}`;
			}
		}

		const replacedElement = document.querySelector(`.privacy-policy-link`);
		if (replacedElement) {
			replacedElement.addEventListener('click', (e) => {
				e.stopPropagation();
			});
		}
	}, [content, data, enableEditing, id, isContentSaveError]);

	useEffect(() => {
		updateDataInComponent();
	}, [updateDataInComponent]);

	const makeElement = useCallback(() => {
		const div = document.createElement('div');
		div.className = 'inline-words';
		div.innerText = content.slice(0, 300);
		div.style.color = 'white';
		div.style.position = 'absolute';
		div.style.zIndex = 3;
		div.style.top = '80px';
		div.style.padding = '20px';
		div.style.wordBreak = 'break-word';
		return div;
	}, [content]);

	useEffect(() => {
		const focussedScreen = document.getElementById(`screen-content-${index}`);
		const image = document.querySelector(`.slide-image-${index}`);
		let imageIndex;
		let imageParent;
		if (image) {
			imageParent = image.parentElement;
			imageIndex = parseInt(imageParent.getAttribute('data-index'));
		}
		const allOtherScreens = Array.from(
			document.querySelectorAll(`.screen-content`),
		).filter((screen) => screen.id !== focussedScreen.id);
		const div = makeElement();
		if (enableEditing) {
			const element = document.getElementById(id);
			if (element) {
				textAreaAdjust(element);
				if (
					focussedScreen &&
					focussedScreen.classList &&
					focussedScreen.classList.length > 0 &&
					focussedScreen.classList.contains('no-border')
				) {
					focussedScreen.classList.remove('no-border');
					focussedScreen.classList.add('border');
					if (
						carouselRef.current &&
						index === imageIndex &&
						image.classList[1] === focussedScreen.id
					) {
						carouselRef.current.goToSlide(index, true);
						if (
							imageParent.childNodes.length > 0 &&
							window.event &&
							window.event.relatedTarget &&
							isSaveClicked.current
						) {
							imageParent.appendChild(div);
						}
					}
					allOtherScreens.forEach((screen) => {
						screen.classList.remove('border');
					});
				}
				element.focus();
			}
		} else {
			if (imageParent && Array.from(imageParent.childNodes).length > 1) {
				if (imageParent.lastChild && isSaveClicked.current) {
					imageParent.childNodes.forEach(() => {
						imageParent.removeChild(imageParent.lastChild);
					});
				}
				if (window.event && window.event.relatedTarget && isSaveClicked.current) {
					imageParent.appendChild(div);
				}
			}
			if (
				focussedScreen &&
				focussedScreen.classList &&
				focussedScreen.classList.length > 0 &&
				focussedScreen.classList.contains('border')
			) {
				focussedScreen.classList.add('no-border');
				focussedScreen.classList.remove('border');
				allOtherScreens.forEach((screen) => {
					screen.classList.remove('border');
				});
			}
		}
	}, [buttonRef, carouselRef, enableEditing, id, index, isSaveClicked, makeElement]);

	const storeContent = (e) => {
		if (isContentSaveError.current) {
			isContentSaveError.current = false;
		}
		setContent(e.target.value);
	};

	return (
		<>
			<div
				className="editable-container"
				onClick={(e) => {
					e.stopPropagation();
					setEnableEditing(true);
				}}>
				<div
					id={`${id}-copy`}
					className={`screen-text ${enableEditing ? 'hidden' : 'visible'}`}>
					{content}
				</div>
			</div>
			<textarea
				id={id}
				className={`screen-text ${enableEditing ? 'visible' : 'hidden'}`}
				onKeyUp={(e) => {
					textAreaAdjust(e.target);
					storeContent(e);
				}}
				onKeyDown={(e) => {
					textAreaAdjust(e.target);
					storeContent(e);
				}}
				onBlur={(e) => {
					onBlur(e);
					setEnableEditing(false);
				}}
			/>
		</>
	);
};

const CustomArrow = forwardRef(
	({ label, isMounted, onClick, setFocus, focus, ...rest }, ref) => {
		const { carouselState } = rest;
		useImperativeHandle(ref, () => ({
			onChangeSlide: () => {
				onClick();
			},
		}));

		const getClass = () => {
			if (label === 'Previous') {
				if (
					carouselState &&
					carouselState.totalItems === carouselState.currentSlide + 1
				) {
					return 'only-previous';
				} else {
					return label;
				}
			}
			return label;
		};

		useEffect(() => {
			if (isMounted.current) {
				setFocus(carouselState.currentSlide);
			}
		}, [carouselState.currentSlide, isMounted, rest, setFocus]);

		return (
			<AppButton
				label={label}
				onClick={() => {
					isMounted.current = true;
					onClick();
				}}
				innerClass="slide-button"
				className={getClass()}
			/>
		);
	},
);

export default ContentManagementPresentation;
