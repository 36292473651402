import React from 'react';
import { useRef } from 'react';
import { useImperativeHandle } from 'react';
import { useState, useEffect } from 'react';
import AddIcon from 'src/app/assets/logos/Add.svg';
import './Card.styles.scss';
import { IMAGE_PLACEHOLDER_VERTICAL } from 'src/app/common/constants/AppConstants';
import { SkeletonLoader } from 'src/app/components/SkeletonLoader';
import Skeleton from '@mui/material/Skeleton';
import Progress from 'src/app/assets/images/In-Progress.gif';
import { getImage, selectCards } from 'src/app/common/utils/methods';

const Card = ({
	tileDetails,
	handleAddButton,
	enableSelection,
	onSelecting,
	id,
	loader = false,
	fromSearch = false,
	fromRedirectionScreen,
	deleteAll = false,
	fromProgress = false,
	setUncheckedOnSelectOne,
	selectedCards = [],
	carouselData = [],
	handleClick,
	setDeleteAll,
	fromVideoDetail = false,
	fromBucket = false,
}) => {
	const imageContainerRef = useRef();
	const [selectionValue, setSelectionValue] = useState('');

	useEffect(() => {
		if (selectedCards?.length > 0) {
			const cards = selectCards(selectedCards, id);
			setSelectionValue(cards);
		}
	}, [selectedCards, selectionValue]);

	useEffect(() => {
		setSelectionValue(deleteAll);
	}, [deleteAll]);

	const onSelectionChange = (e) => {
		if (deleteAll && !e.target.checked) {
			setUncheckedOnSelectOne(false);
			setDeleteAll(false);
		}
		if (deleteAll && e.target.checked) {
			if (selectedCards?.length + 1 === carouselData?.length) {
				setUncheckedOnSelectOne(true);
			}
		}
		setSelectionValue(e.target.checked);
		onSelecting({ id: e.target.id, value: e.target.checked });
	};

	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_VERTICAL;
	};

	const handleAdd = () => {
		if (fromBucket) {
			handleAddButton(tileDetails?.url?.replace('https://www.youtube.com/watch?v=', ''));
		} else {
			handleAddButton(tileDetails?.video_id || tileDetails);
		}
	};

	return (
		<div className="card-container" id={`card-wrapper-${id}`}>
			{loader && (
				<Skeleton
					variant="rectangular"
					width={173}
					style={{ borderRadius: 6 }}
					height={258}
					sx={{
						bgcolor: 'rgba(63, 65, 69, 0.4)',
						animation: `${
							fromSearch
								? 'animation-c7515d 3.0s ease-in-out infinite;'
								: 'animation-c7515d 1.0s ease-in-out infinite;'
						}`,
					}}
				/>
			)}
			{!loader && (
				<>
					<div
						ref={imageContainerRef}
						id={`card-container-${id}`}
						className={`${
							selectionValue && !fromProgress ? 'on-delete-all ' : 'on-unselecting'
						} ${fromVideoDetail ? 'card-container-image-yt' : 'card-container-image'}`}>
						{tileDetails?.new && (
							<span className="new-icon">
								<p>NEW</p>
							</span>
						)}

						{enableSelection && !fromProgress && (
							<div className="selection-button">
								<input
									id={id}
									type="checkbox"
									value={selectionValue}
									onChange={onSelectionChange}
									alt="selection"
									checked={selectionValue}
								/>
							</div>
						)}
						{!enableSelection && !fromProgress && (
							<div className="add-icon" role="button" onClick={handleAdd}>
								<img src={AddIcon} alt="add-button" />
							</div>
						)}
						{tileDetails?.content_type === 'show' && fromRedirectionScreen && (
							<div className="tv-batch">TV</div>
						)}
						<img
							onError={addDefaultSrc}
							className={`cardImg ${fromProgress ? 'blur-image-progress' : ''} ${
								fromVideoDetail ? 'videoDetail' : ''
							}`}
							src={getImage(tileDetails?.thumbnail)}
							alt="card"
							onClick={handleClick}
						/>
						<div className="overlay" onClick={handleClick}>
							{fromProgress && (
								<div className="show-progress">
									{tileDetails?.status === 'progress' ? (
										<div className="inprogress">
											<span>In-Progress</span>
											<img
												src={Progress}
												alt="In-progress"
												className="in-progress-image"
											/>
										</div>
									) : (
										<div className="failed">
											<span className="failed-mssg">Failed</span>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Card;
