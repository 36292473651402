export const popupContentStyle = {
  background: "#2A2A31",
  boxShadow: "0px 18px 26px 0px #00000059",
  borderRadius: 12,
  margin: "auto",
  paddingBottom: "32px",
  width: "327px",
};

export const PER_PAGE = 2;

export const responsiveVertical = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1350 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1350, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 26,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 26,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 26,
  },
};