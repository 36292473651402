import {
	GET_CAST_DATA_INITIAL,
	GET_CAST_DATA,
} from 'src/app/pages/Dashboard/actionTypes';
import ALL_CAST_INITIAL_STATE from './initialState';

const castReducer = (state = ALL_CAST_INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_CAST_DATA_INITIAL:
			return {
				...state,
				cast: action.payload || [],
			};
		case GET_CAST_DATA:
			return {
				...state,
				cast: [...state.cast, ...action.payload],
			};
		default:
			return state;
	}
};

export default castReducer;
