import React, { useState, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import {
	getCommonMethod,
	deleteData,
	putCommonMethod,
} from 'src/app/pages/Dashboard/Service';
import {
	GET_PARENT_BUCKET_DATA,
	GET_LIST_FOR_YOU,
	GET_LIST_FOR_YOU_INITIAL,
	REMOVE_DATA_LIST_FOR_YOU,
} from 'src/app/pages/Dashboard/actionTypes';
import Table from 'src/app/components/Table';
import { LIST_FOR_YOU_TABLE_COLUMNS_CONFIG } from '../constant';
import { Column } from 'src/app/components/Table/Column';
import InfiniteScroll from 'react-infinite-scroll-component';
import { infinteScrollEndMessage } from 'src/app/pages/ComponentConstant';
import { Loader } from 'src/app/components/Loader';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import { isEmptyString, capitalizeFirstLetter } from 'src/app/common/utils/methods';
import { ReactSortable } from 'react-sortablejs';
import Row from './Row';
import * as CONST from '../constant';
import './styles.scss';

const CustomComponent = forwardRef((props, ref) => {
	return <tbody ref={ref}>{props.children}</tbody>;
});

const TableContainer = ({
	loading,
	setLoading,
	offset,
	setOffset,
	debouncedValue,
	setToastInfo,
}) => {
	const dispatch = useDispatch();
	const parentBucket = useSelector((state) => state.reservations.parentBucket);
	const tableData = useSelector((state) => state.reservations.listForYou);
	const [hasMore, setHasMore] = useState(false);
	const [tableDisplayData, setTableDisplayData] = useState([]);

	useEffect(() => {
		if (parentBucket.length === 0) {
			fetchParentBucket();
		}
	}, []);

	useEffect(() => {
		if (parentBucket.length > 0) {
			fetchListForYouData();
		}
	}, [parentBucket, offset, debouncedValue]);

	useEffect(() => {
		if (tableData) {
			setLoading(false);
			setTableDisplayData([...tableData]);
		}
	}, [tableData, setLoading]);

	const fetchParentBucket = async () => {
		try {
			const res = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.PARENT_BUCKET_URL}`,
			);
			dispatch({ type: GET_PARENT_BUCKET_DATA, payload: res?.data });
		} catch (e) {}
	};

	const handleHasMore = (response) => {
		if (response?.data?.length > 0 && response?.data?.length === CONST.RECORD_TO_FETCH) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const fetchListForYouData = async () => {
		try {
			if (offset <= 0) {
				setLoading(true);
			}
			const listForYou = parentBucket?.find(
				(item) => item.slug === CONST.LIST_FOR_YOU_SLUG,
			);
			const params = {
				parent_id: listForYou?.bucket_id,
				offset: offset || 0,
				limit: CONST.RECORD_TO_FETCH,
			};
			if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
				const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
				params.searchTerm = modifiedDebounceValue;
			}

			const res = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIST_OF_YOU}`,
				params,
			);

			if (offset > 0) {
				dispatch({
					type: GET_LIST_FOR_YOU,
					payload: res?.data,
				});
				handleHasMore(res);
			} else {
				dispatch({
					type: GET_LIST_FOR_YOU_INITIAL,
					payload: res?.data,
				});
				handleHasMore(res);
			}
		} catch (e) {}
	};

	const fetchMoreData = () => {
		if (hasMore) {
			setOffset(tableDisplayData?.length);
		}
	};

	const handleRemove = async (buck_id) => {
		try {
			const listForYou = parentBucket?.find(
				(item) => item.slug === CONST.LIST_FOR_YOU_SLUG,
			);
			const res = await deleteData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.REMOVE_LIST_FOR_YOU}`,
				{
					parent_id: listForYou?.bucket_id,
					child_ids: [buck_id],
				},
			);
			dispatch({ type: REMOVE_DATA_LIST_FOR_YOU, payload: buck_id });
			setToastInfo(true, false, `Removed successfully.`);
		} catch (e) {}
	};

	const dragEndHandler = async (e) => {
		const listForYou = parentBucket?.find(
			(item) => item.slug === CONST.LIST_FOR_YOU_SLUG,
		);
		const draggedItem = tableDisplayData[e.newIndex];
		let bucketRank;
		let newtableData = [];
		if (e.newIndex === 0) {
			bucketRank =
				tableDisplayData[e.newIndex + 1]['bucket_rank'] > 1
					? tableDisplayData[e.newIndex + 1]['bucket_rank'] - 1
					: tableDisplayData[e.newIndex + 1]['bucket_rank'] / 1.01;
		} else if (e.newIndex === tableDisplayData.length - 1) {
			bucketRank = tableDisplayData[e.newIndex - 1]['bucket_rank'] + 1;
		} else {
			bucketRank =
				(tableDisplayData[e.newIndex - 1]['bucket_rank'] +
					tableDisplayData[e.newIndex + 1]['bucket_rank']) /
				2;
		}
		try {
			const res = await putCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.REORDER_LIST_FOR_YOU}`,
				{
					child_id: draggedItem.bucket_id,
					parent_id: listForYou?.bucket_id,
					bucket_rank: bucketRank,
				},
			);
			if (res) {
				tableDisplayData.forEach((item) => {
					if (item.bucket_id === draggedItem.bucket_id) item.bucket_rank = bucketRank;
					newtableData.push(item);
				});
				setTableDisplayData(newtableData);
				dispatch({ type: GET_LIST_FOR_YOU_INITIAL, payload: newtableData });
			}
		} catch (e) {}
	};

	const renderTable = () => {
		if (loading) {
			return (
				<tbody>
					<EmptyState columns={LIST_FOR_YOU_TABLE_COLUMNS_CONFIG} renderColumns={2} />
				</tbody>
			);
		} else {
			return (
				<ReactSortable
					tag={CustomComponent}
					list={tableDisplayData}
					setList={setTableDisplayData}
					onEnd={dragEndHandler}>
					{tableDisplayData.map((item, index) => {
						return (
							<Row
								key={item && `${item.bucket_id}-${item?.screen_id}`}
								data={{ ...item, index }}
								handleRemove={handleRemove}
							/>
						);
					})}
				</ReactSortable>
			);
		}
	};

	return (
		<div>
			<Table isRenderable={true}>
				<div id="scrollableDivListForYou">
					<InfiniteScroll
						dataLength={tableData?.length}
						next={fetchMoreData}
						hasMore={hasMore}
						loader={<Loader loadMore={true} />}
						scrollableTarget="scrollableDivListForYou"
						endMessage={infinteScrollEndMessage()}>
						<table className="table-list-for-you">
							<thead>
								<tr>
									{LIST_FOR_YOU_TABLE_COLUMNS_CONFIG.map((column) => {
										return (
											<Column
												key={column.key}
												config={column}
												shuffleMode={true}
												handleAllCheck={() => {}}
											/>
										);
									})}
								</tr>
							</thead>
							{renderTable()}
						</table>
					</InfiniteScroll>
				</div>
			</Table>
		</div>
	);
};

export default TableContainer;
