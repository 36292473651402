export const LOADING_COLOR = '#363541';
export const HIGHLIGHTING_COLOR = '#000';
export const ROW_LOADING_ANIMATION_WIDTH = '80%';
export const ROW_LOADING_ANIMATION_HEIGHT = 10;
export const RECORD_TO_FETCH = 20;

export const OPTIONS = [
	{ value: 'edit', label: 'Edit' },
	{ value: 'unpublished', label: 'Unpublish' },
	{ value: 'archived', label: 'Archive' },
	{ value: 'delete', label: 'Delete' },
];
export const LIVE_OPTIONS = [
	{ value: 'edit', label: 'Edit' },
	{ value: 'unpublished', label: 'Unpublish' },
	{ value: 'delete', label: 'Delete' },
];

export const STATUS_OPTIONS = [
	{ value: 'published', label: 'Published' },
	{ value: 'unpublished', label: 'Unpublished' },
	{ value: 'draft', label: 'Draft' },
];

export const SCREEN_OPTIONS = [
	{ value: 'home', label: 'Home' },
	{ value: 'discover', label: 'Discover' },
];

export const DRAFT_UNPUBLISH_OPTIONS = [
	{ value: 'edit', label: 'Edit' },
	{ value: 'published', label: 'Publish' },
	{ value: 'delete', label: 'Delete' },
];

export const UNPUBLISH_OPTIONS = [
	{ value: 'edit', label: 'Edit' },
	{ value: 'published', label: 'Publish' },
	{ value: 'archived', label: 'Archive' },
	{ value: 'delete', label: 'Delete' },
];

export const LIVE_UNPUBLISH_OPTIONS = [
	{ value: 'edit', label: 'Edit' },
	{ value: 'published', label: 'Publish' },
	{ value: 'delete', label: 'Delete' },
];

export const ARCHIVE_UNPUBLISH_OPTIONS = [
	{ value: 'edit', label: 'Edit' },
	{ value: 'published', label: 'Publish' },
	{ value: 'unpublished', label: 'Unpublish' },
	{ value: 'delete', label: 'Delete' },
];

export const EDIT_OPTION = [{ value: 'edit', label: 'Edit' }];
