import React, { useState, forwardRef } from 'react';
import { SearchBoxIcons } from 'src/app/components/atoms/SearchBoxIcons';
import SearchIcon from 'src/app/assets/logos/Search.svg';
import './styles.scss';

const DashboardSearchBox = forwardRef((props, ref) => {
	const handleEnterKeyPress = (e) => {
		if (e.key === 'Enter' && props.handleEnterKeyPress) {
			props?.handleEnterKeyPress(e);
		}
	};
	return (
		<div className={`dashboard-search ${props.className}`}>
			{!props.searchIcon && <img src={SearchIcon} alt="search-icon" />}
			<input
				ref={ref}
				id={props.type === 'filter' ? props.id : ''}
				onChange={props.handleChange}
				className={props.type === 'filter' ? 'filter-box' : `search-box`}
				onKeyPress={(e) => handleEnterKeyPress(e)}
				placeholder={
					props.placeholder ? props.placeholder : `Search your movies and shows`
				}
			/>
		</div>
	);
});

export default DashboardSearchBox;
