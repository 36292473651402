import React from 'react';
import './styles.scss';
import VideoPlayer from 'react-video-js-player';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';

export const VideoPlayerIndex = ({ url, closePopup }) => {
	const proxy_url = 'https://api-dev.qewd.com/stream';
	const video_url = url;
	const file_extension = '.m3u8';
	const PROXY = `${proxy_url}/${btoa(video_url)}${file_extension}`;

	return (
		<div className="video-popup-container">
			<img
				src={CrossIcon}
				alt="close"
				className="cross-icon-popup"
				onClick={() => {
					closePopup();
				}}
			/>
			<VideoPlayer
				controls={true}
				src={PROXY}
				width="1000"
				height="620"
				autoplay={true}
				hideControls={['fullscreen']}
			/>
		</div>
	);
};

export default VideoPlayerIndex;
