import React from 'react';
import './styles.scss';
import LIVE_CHANNEL_POPUP, { CUSTOM_OPTION_POPUP } from './constants';
import { ButtonLoader } from 'src/app/components/ButtonLoader';
import CustomSelectPopup from 'src/app/components/atoms/CustomSelectPopup';

export const LiveChannelCategoryPopup = ({
	message,
	buttonText,
	onCancel,
	onAdd,
	loading,
	setSelectedCategory,
	setCategoryOffset,
	categoryLabelsDemo,
	hasMoreCategory,
	category,
	isDisable,
	setDisable,
	dropdownLabel,
	placeholder,
	id,
}) => {
	const handleValueChange = (field, e) => {
		if (field === 'category') {
			setSelectedCategory(e.live_category_id);
		} else if (field === 'channel') {
			setSelectedCategory(e.live_channel_id);
		}
		setDisable(true);
	};

	const fetchMoreCategory = () => {
		if (hasMoreCategory) {
			setCategoryOffset(categoryLabelsDemo.length);
		}
	};

	return (
		<div className="live-popup-wrapper">
			<div className="live-popup-message">{message || LIVE_CHANNEL_POPUP.HEADING}</div>
			<div className="live-popup-seperator"></div>
			<div className="live-popup-category">
				<CustomSelectPopup
					label={dropdownLabel || CUSTOM_OPTION_POPUP.LABEL}
					// defaultValue={category}
					from="popup"
					placeholder={placeholder || CUSTOM_OPTION_POPUP.PLACEHOLDER}
					handleValueChanges={handleValueChange}
					menuItems={categoryLabelsDemo}
					hasError={false}
					errorVerbose={CUSTOM_OPTION_POPUP.ERROR_VERBOSE}
					idFieldName={id === 'channel' ? 'live_channel_id' : 'live_category_id'}
					id={id || 'category'}
					selectType="single"
					isfromLiveScreen={true}
					fetchMoreCategory={fetchMoreCategory}
					hasMoreCategory={hasMoreCategory}
				/>
			</div>
			<div className="buttons">
				<button onClick={onCancel} className="cancel">
					{LIVE_CHANNEL_POPUP.CANCEL}
				</button>
				{loading ? (
					<ButtonLoader />
				) : (
					<button onClick={onAdd} className={`${!isDisable ? 'disable-button' : ''}`}>
						{buttonText || LIVE_CHANNEL_POPUP.ADD}
					</button>
				)}
			</div>
		</div>
	);
};

export default LiveChannelCategoryPopup;
