import React, { useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_BREADCRUMB } from 'src/app/pages/Dashboard/actionTypes';
import './styles.scss';

export const BreadCrumbs = ({ value }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const routes = useSelector((state) => state.breadCrumb?.breadCrumbRoute);

	useEffect(() => {
		dispatch({ type: ADD_BREADCRUMB, payload: { location, value } });
	}, []);

	const handleNavlinkClick = () => {
		console.log('value');
	};
	return (
		<div className="breadcrumb-container">
			{routes?.length > 1 &&
				routes.map((val, index) => {
					return (
						<NavLink
							to={{
								pathname: val?.path,
								state: { name: val?.name },
							}}
							activeClassName={'active-breadcrumb'}
							onClick={handleNavlinkClick}
							key={index}>
							<span
								className={`breadcrumb-label  ${
									index === routes.length - 1 ? 'active-breadcrumb' : ''
								}`}
								key={index}>
								{index === 0 ? val.breadcrumb : `/ ${val.breadcrumb}`}&nbsp;
							</span>
						</NavLink>
					);
				})}
		</div>
	);
};

export default BreadCrumbs;
