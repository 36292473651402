import React from "react";
import CrossIcon from "../../assets/images/CrossIcon.svg";
import "./FilterTags.styles.scss";

const FilterTags = ({ filters, removeFilter, removeFilterFromMovieCast, removeSortByFilter }) => {
  return (
    <div className="filter-tag-container">
      {filters &&
        filters.map(({ value, id, type }) => {
          return (
            <div key={`${type}-${id}`} className="filter-tag">
              <div className="tag-name">{value}</div>
              <div
                className="cross-icon-container"
                role="presentation"
                onClick={() => {
                  if (type === "dropdown") {
                    removeSortByFilter();
                  }
                  else if (type !== "movieCast") {
                    removeFilter(id, type);
                  }
                  else if (type === "movieCast") {
                    removeFilterFromMovieCast(id, type);
                  }
                }}
              >
                <img
                  className="cross-icon"
                  src={CrossIcon}
                  alt="cross-icon"
                  role="button"
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export { FilterTags };
