import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const Column = ({ config, handleAllCheck, shuffleMode }) => {
  return (
    <th className={config.className}>
      {config.className === 'name' ? (
        <span className="checkbox">
          {shuffleMode ? <span className={'empty-space'}></span> : <input
            type="checkbox"
            id="name-checkbox"
            value="all"
            onClick={() => handleAllCheck()}
          />}
          <span className={config.spanClassName}>{config.title} </span>
        </span>
      ) : (
        <span className={config.spanClassName}>{config.title} </span>
      )}
    </th>
  );
};

Column.propTypes = {
  config: PropTypes.object.isRequired,
};
