import React, { useState } from 'react';
import './styles.scss';
import ScrollToTopWhiteIcon from 'src/app/assets/images/ScrollWhite.svg';
import ScrollToTopIcon from 'src/app/assets/images/ScrollMagenta.svg';
import { useStore } from 'react-redux';

export const ScrollToTop = ({ handleScrollToTop, fromDigestScreen = false }) => {
	const [scrollIcon, setScrollIcon] = useState(ScrollToTopWhiteIcon);
	return (
		<div
			className={`scroll-to-top-div ${fromDigestScreen ? 'qewd-margin' : ''}`}
			onMouseEnter={() => setScrollIcon(ScrollToTopIcon)}
			onMouseLeave={() => setScrollIcon(ScrollToTopWhiteIcon)}
			onClick={handleScrollToTop}>
			<img className="scroll-to-top-image" src={scrollIcon} alt="scroll-to-top" />
		</div>
	);
};

export default ScrollToTop;
