import React, { useState, useEffect } from 'react';
import './styles.scss';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';
import Phone from 'src/app/assets/images/Phone.svg';
import Mail from 'src/app/assets/images/Mail.svg';
import { ButtonLoader } from 'src/app/components/ButtonLoader';
import * as CONST from './constant';
import TagPills from 'src/app/components/atoms/TagPills';
import { profileBackgroundColor } from 'src/app/pages/AllUsers/constants';
import { ROOT_URL, GET_USER_TAGS } from 'src/app/common/constants/AppConstants';
import { getCommonMethod } from 'src/app/pages/Dashboard/Service';
import Linkify from 'react-linkify';

export const UserVerifyModal = ({
	loading,
	closePopup,
	userInfo,
	verifyUser,
	userData,
	isError = false,
}) => {
	const [isAvatar, setIsAvatar] = useState(false);
	const [tags, setTags] = useState([]);
	const [checked, setChecked] = useState(false);
	const [checkedVerify, setCheckedVerify] = useState(false);
	const [isBusinessAccount, setBusinessAccount] = useState(
		userData[userInfo?.index]?.account_type,
	);
	const [isVerified, setVerified] = useState(
		userData[userInfo?.index]?.is_verified === 1 ? true : false,
	);
	const [tagsLoader, setTagsLoader] = useState(true);

	useEffect(() => {
		if (userInfo?.avatar) {
			setIsAvatar(true);
		}
		if (userInfo?.user_id) {
			fetchTagData();
		}
		if (userData[userInfo?.index]?.account_type === 'Business') {
			setChecked(true);
		}
		if (
			userData[userInfo?.index]?.is_verified === 1 ||
			userData[userInfo?.index]?.is_verified === true
		) {
			setCheckedVerify(true);
		}
	}, [userInfo]);

	const fetchTagData = async () => {
		try {
			const params = {
				offset: 1,
				perPage: 10,
				user_id: userInfo.user_id,
			};
			const tagResp = await getCommonMethod(`${ROOT_URL}${GET_USER_TAGS}`, params);
			setTags(tagResp?.data);
			setTagsLoader(false);
		} catch (e) {
			console.log(e);
		}
	};

	const addDefaultSrc = (ev) => {
		setIsAvatar(false);
		ev.target.className = 'user-icon-error';
	};

	const handleToggle = (e) => {
		setChecked(false);
		if (e.target.checked) {
			setBusinessAccount('Business');
		} else {
			setBusinessAccount('Normal');
		}
	};

	const handleVerify = (e) => {
		setCheckedVerify(false);
		if (e.target.checked) {
			setVerified(true);
		} else {
			setVerified(false);
		}
	};

	const getProfileBackground = (name) => {
		const nameIndex = (name && name.length > 0 ? name[0].charCodeAt(0) : 1) % 7;
		return profileBackgroundColor[nameIndex];
	};

	const getProfileInitials = () => {
		return `${userInfo?.first_name?.charAt(0)?.toUpperCase() || ''}${
			userInfo?.last_name?.charAt(0)?.toUpperCase() || ''
		}`;
	};

	const showUserAvatar = () => {
		return (
			<div className="outer-border">
				{isAvatar ? (
					<img
						src={userInfo?.avatar}
						alt="Avatar"
						className={`avatar ${userInfo?.avatar ? '' : 'user-icon-error'}`}
						onError={addDefaultSrc}
					/>
				) : (
					<div
						className={`avatar user-icon-error`}
						style={{ background: `${getProfileBackground(userInfo?.first_name)}` }}>
						<span className="user-name-error-span">{getProfileInitials()}</span>
					</div>
				)}
			</div>
		);
	};

	const showUserInfo = () => {
		return (
			<div className="user-info">
				<div className="username">{`${userInfo?.first_name} ${userInfo?.last_name}`}</div>
				<div className="contact-info">
					{userInfo?.phone_number && (
						<div className="mobile">
							<img src={Phone} alt="Phone" className="contact-icon" />
							<span className="contact-number">{userInfo?.phone_number}</span>
						</div>
					)}
					{userInfo?.phone_number && <div className="vertical-seprator"></div>}
					{userInfo?.user_email && (
						<div className="email">
							<img src={Mail} alt="Email" className="contact-icon" />
							<span className="email-address">{userInfo?.user_email}</span>
						</div>
					)}
				</div>
				<div className="qewd-info">
					<div className="qewd-info-subcontainer">
						<div className="info-count">{userInfo?.playlists}</div>
						<div className="info-type">{CONST.INFO_TITLES.list}</div>
					</div>
					<div className="qewd-info-subcontainer">
						<div className="info-count">{userInfo?.followers}</div>
						<div className="info-type">{CONST.INFO_TITLES.followers}</div>
					</div>
					<div className="qewd-info-subcontainer">
						<div className="info-count">{userInfo?.following}</div>
						<div className="info-type">{CONST.INFO_TITLES.following}</div>
					</div>
					<div className="qewd-info-subcontainer">
						<div className="info-count">{userInfo?.shares || 0}</div>
						<div className="info-type">{CONST.INFO_TITLES.shares}</div>
					</div>
				</div>
				<div className="seprator"></div>
			</div>
		);
	};

	const showDesc = () => {
		return (
			<Linkify properties={{ target: '_blank', style: { color: '#F82C5E' } }}>
				<div className="user-desc">
					<span className="user-desc-span">{userInfo?.description}</span>
				</div>
			</Linkify>
		);
	};

	const showTagContainer = () => {
		return (
			<div className="tag-container">
				<div className="tag-heading">{CONST.TAGS}</div>
				<div className="pill-block">
					{tagsLoader ? (
						<ButtonLoader fromVerifyPopup={true} />
					) : tags.length > 0 ? (
						tags.map((items) => {
							return <TagPills fromUser={true} key={items} id={items} data={items} />;
						})
					) : (
						<span className="no-tags">No Tags Available</span>
					)}
				</div>
			</div>
		);
	};

	const showSwitches = () => {
		return (
			<div className="switch-wrapper">
				<div className="business-account">
					<div className="ba-heading">{CONST.BA_HEADING}</div>
					<label
						className={`switch ${
							userData[userInfo?.index]?.is_deactivate === 1 ? 'disable-input' : ''
						}`}>
						{checked ? (
							<input
								type="checkbox"
								checked
								onChange={handleToggle}
								disabled={userData[userInfo?.index]?.is_deactivate === 1 ? true : false}
							/>
						) : (
							<input
								type="checkbox"
								onChange={handleToggle}
								disabled={userData[userInfo?.index]?.is_deactivate === 1 ? true : false}
							/>
						)}

						<span className="slider round"></span>
					</label>
				</div>

				<div className="verify-user">
					<div className="vu-heading">{CONST.VU_HEADING}</div>
					<label
						className={`switch ${
							userData[userInfo?.index]?.is_deactivate === 1 ? 'disable-input' : ''
						}`}>
						{checkedVerify ? (
							<input
								type="checkbox"
								checked
								onChange={handleVerify}
								disabled={userData[userInfo?.index]?.is_deactivate === 1 ? true : false}
							/>
						) : (
							<input
								type="checkbox"
								onChange={handleVerify}
								disabled={userData[userInfo?.index]?.is_deactivate === 1 ? true : false}
							/>
						)}

						<span className="slider round"></span>
					</label>
				</div>
			</div>
		);
	};

	const showButtons = () => {
		return (
			<div className="action-button">
				<button className="cancel" onClick={closePopup}>
					{CONST.BUTTON_HEADING.cancel}
				</button>
				{loading ? (
					<ButtonLoader />
				) : (
					<button
						disabled={userData[userInfo?.index]?.is_deactivate === 1 ? true : false}
						onClick={() =>
							verifyUser(
								userInfo?.user_id,
								isVerified,
								isBusinessAccount,
								`${userInfo?.first_name} ${userInfo?.last_name}`,
								userInfo?.is_deactivate,
							)
						}
						className={`${
							userData[userInfo?.index]?.is_deactivate === 1 ? 'disable' : ''
						}`}>
						{CONST.BUTTON_HEADING.verify}
					</button>
				)}
			</div>
		);
	};

	return (
		<div className="verify-container">
			{isError && (
				<div className="error">Something Went Wrong, Please Try Again later</div>
			)}
			<img
				src={CrossIcon}
				alt="close"
				className="cross-icon-popup"
				onClick={() => {
					closePopup();
				}}
			/>
			{showUserAvatar()}
			{showUserInfo()}
			{userInfo?.description ? showDesc() : null}
			{showTagContainer()}
			{showSwitches()}
			{showButtons()}
		</div>
	);
};

export default UserVerifyModal;
