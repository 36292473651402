import React, { useState, useEffect } from 'react';
import './style.scss';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';
import { IMAGE_PLACEHOLDER_HORIZONTAL } from 'src/app/common/constants/AppConstants';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { postCommonMethod } from 'src/app/pages/Dashboard/Service';
import { Loader } from '../Loader';

export const TagBucketDesc = ({ tagData, toggleBucketDescPopup, bucketIds }) => {
	const [data, setData] = useState([]);
	useEffect(() => {
		if (bucketIds) {
			fetchData();
		}
	}, [bucketIds]);

	const fetchData = async () => {
		try {
			const res = await postCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.BUCKET_METADATA}`,
				{ bucket_ids: bucketIds },
			);
			setData(res?.data?.data);
		} catch (e) {}
	};

	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
	};

	const renderImage = (imgSrc) => {
		return (
			<img
				src={imgSrc || IMAGE_PLACEHOLDER_HORIZONTAL}
				alt="Bucket Thumbnail"
				onError={addDefaultSrc}
			/>
		);
	};

	const renderGridImage = (banner) => {
		return banner?.map((banner_img) => {
			return renderImage(banner_img);
		});
	};

	const renderTripleImage = (banner) => {
		return (
			<div className="image-thumb-container">
				<div className="image-top-wrap">
					{renderImage(banner[0])}
					{renderImage(banner[1])}
				</div>
				<div className="image-bottom-wrap">{renderImage(banner[2])}</div>
			</div>
		);
	};

	const renderDoubleImage = (banner) => {
		return (
			<div className="image-thumb-container-double">
				<div className="image-top-wrap-double">{renderImage(banner[0])}</div>
				<div className="image-bottom-wrap-double">{renderImage(banner[1])}</div>
			</div>
		);
	};

	const renderThumbnails = (length, banner_images) => {
		switch (length) {
			case 4:
				return <div className="buck-thumb"> {renderGridImage(banner_images)}</div>;
			case 3:
				return renderTripleImage(banner_images);
			case 2:
				return renderDoubleImage(banner_images);
			case 1:
				return <div className="buck-single-thumb">{renderImage(banner_images)}</div>;
			default:
				return <div className="buck-single-thumb">{renderImage(null)}</div>;
		}
	};

	const renderCards = () => {
		return data?.map((item) => {
			return (
				<div className="bucket-card" key={item?.bucket_id}>
					<div className="buck-thumbnail-wrapper ">
						{renderThumbnails(item?.banner_images.length, item?.banner_images)}
					</div>

					<div className="buck-desc">
						<div className="buck-name">{item?.name}</div>
						<div className="buck-count">{`${item?.video_count} Videos`}</div>
					</div>
				</div>
			);
		});
	};

	return (
		<div className="buck-desc-container">
			<div className="buck-desc-heading-name">{tagData?.tag_name}</div>
			<div className="buck-desc-heading-count">{`${tagData?.bucket_count} Buckets`}</div>
			<img
				src={CrossIcon}
				alt="close"
				className="cross-icon-popup"
				onClick={() => {
					toggleBucketDescPopup();
				}}
			/>
			<div className="seprator"></div>

			<div className="content-wrapper">
				{data?.length ? (
					<div className="content-div">{renderCards()}</div>
				) : (
					<div className="loader">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

export default TagBucketDesc;
