import React, { useState } from 'react';
import { removeAllSpecialCharacters } from 'src/app/common/utils/methods';
import 'src/app/components/Filter/Filter.styles.scss';
import FilterCheckBox from 'src/app/components/Filter/FilterCheckBox';
import { useSelector } from 'react-redux';

const FilterContainerLive = ({
	isFilterVisible,
	applyFilters,
	clearAllFilters,
	disabled,
	shuffle,
	onChangeFilter,
}) => {
	const { sub_category } = useSelector((state) => state.liveBuckReducer);
	const [showAllOptions, setShowOptions] = useState(false);

	return (
		<aside className={`filter-container  ${disabled ? 'disable-button' : ''}`}>
			<div className="filter-container-header">
				<h5 className="heading">{'FILTERS'}</h5>
				{isFilterVisible && (
					<div className="filter-buttons">
						<button className={`filter-button margin-right`} onClick={applyFilters}>
							Apply Filters
						</button>
						<button className="filter-button" onClick={clearAllFilters}>
							Clear All
						</button>
					</div>
				)}
			</div>
			<div className={`contentWrapper ${shuffle ? 'fix-height' : ''}`}>
				<div className="content">
					<div className="header">
						<h4 className={`content-title ${removeAllSpecialCharacters('subCategory')}`}>
							Sub-Categories
						</h4>
					</div>
					<div className={`${removeAllSpecialCharacters('subCategory')}-container`}>
						<div className={`${removeAllSpecialCharacters('subCategory')}-${2}`}>
							{sub_category &&
								sub_category.map(({ name, live_channel_id: live_channel_id }, index) => {
									return (
										<div className="filter-pill">
											<div
												aria-checked={false}
												id={`subcategory-${live_channel_id}`}
												role="checkbox"
												className="pill"
												htmlFor={`subcategory-${live_channel_id}`}
												onClick={onChangeFilter}>
												{name}
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</div>
		</aside>
	);
};

export default FilterContainerLive;
