import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Column } from './Column';
import { Row } from './Row';
import './styles.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmptyState } from './EmptyState';
import {
	getBucketTableData,
	getBucketTableDataOnTabChange,
	getBucketTableDataOnScreenChange,
	updateBucketRankById,
} from '../../../actionCreators';
import { RECORD_TO_FETCH } from '../../../../../common/constants/BucketListingConstant';
import { ReactSortable } from 'react-sortablejs';
import { infinteScrollEndMessage } from '../../../../ComponentConstant';
import {
	ROOT_URL,
	GET_FILTER_URL,
	UPDATE_BUCKET_URL,
} from '../../../../../common/constants/AppConstants';
import { getLoader } from '../../../../../common/utils/methods';
import {
	BUCKET_TABLE_COLUMNS_CONFIG,
	BUCKET_TABLE_COLUMNS_CONFIG_ARCHIVED,
} from './constant';
import { Loader } from 'src/app/components/Loader';
import {
	isEmptyString,
	debounceCheck,
	debounceValue,
} from 'src/app/common/utils/methods';

const CustomComponent = forwardRef((props, ref) => {
	return <tbody ref={ref}>{props.children}</tbody>;
});

export const Table = ({
	columnsConfig,
	statusHere,
	shuffleMode,
	selectedScreen,
	openEditBucketModal,
	tableDisplayData,
	setTableDisplayData,
	tableData,
	totalBucket,
	setUpdatedData,
	newDataLength,
	isUpdatedData,
	openDeleteModal,
	openChangeStatusModal,
	openChangeStatusModalArchived,
	onLoad,
	setOnLoad,
	count,
	setCount,
	newBucketTableData,
	newBucketLength,
	loading,
	setLoading,
	publishScroll,
	setPublishScroll,
	unpublishScroll,
	setUnpublishScroll,
	draftScroll,
	setDraftScroll,
	archivedScroll,
	setArchivedScroll,
	setTabCount,
	debouncedValue,
	debCount,
	setDebCount,
	openToast,
	deleteAll,
	setUncheckedOnSelectOne,
	selectedCards,
	onSelectingCards,
	selectToDeleteAll,
	unCheckedOnSelectOne,
	livescreen = false,
}) => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState(statusHere);
	const [hasMore, setHasMore] = useState(true);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [increasePaddingBottomStatus, setIncreasePaddingBottomStatus] = useState(false);
	const [increasePaddingBottomScreen, setIncreasePaddingBottomScreen] = useState(false);
	const [increasePaddingBottomMoreOption, setIncreasePaddingBottomMoreOption] =
		useState(false);
	const [stopScroll, setStopScroll] = useState(true);

	const getScreenId = useCallback(() => {
		if (status === 'published') {
			if (selectedScreen.screen_id !== 0) {
				return selectedScreen.screen_id;
			}
		} else return false;
	}, [selectedScreen, status]);

	useEffect(() => {
		setStatus(statusHere);
	}, [statusHere]);

	const scroller = document.querySelector('#scrollableDiv');
	if (scroller) {
		scroller.addEventListener('scroll', (event) => {
			if (status === 'published') {
				setPublishScroll(scroller.scrollTop);
			} else if (status === 'unpublished') {
				setUnpublishScroll(scroller.scrollTop);
			} else if (status === 'archived') {
				setArchivedScroll(scroller.scrollTop);
			} else {
				setDraftScroll(scroller.scrollTop);
			}
		});
	}

	const scroll = () => {
		setTimeout(() => {
			const element = document.querySelector('#scrollableDiv');
			if (element) {
				if (status === 'published') {
					element.scrollTo({ top: publishScroll, behavior: 'auto' });
				} else if (status === 'unpublished') {
					element.scrollTo({ top: unpublishScroll, behavior: 'auto' });
				} else if (status === 'archived') {
					element.scrollTo({ top: archivedScroll, behavior: 'auto' });
				} else {
					element.scrollTo({ top: draftScroll, behavior: 'auto' });
				}
			}
		}, 50);
	};

	useEffect(() => {
		setLoading(false);
		if (status === 'published') {
			setTableDisplayData([...newBucketTableData.published]);
		} else if (status === 'unpublished') {
			setTableDisplayData([...newBucketTableData.unpublished]);
		} else if (status === 'archived') {
			setTableDisplayData([...newBucketTableData.archived]);
		} else {
			setTableDisplayData([...newBucketTableData.draft]);
		}
	}, [setTableDisplayData, newBucketTableData]);

	useEffect(() => {
		let cancel = false;
		if (cancel) return;

		setUpdatedData(false);
		let form = {
			page: onLoad === 'page' ? count : 1,
			perPage: RECORD_TO_FETCH,
			status: status,
			...(livescreen && { screen_ids: 4 }),
		};
		if (debounceCheck(debouncedValue)) {
			form.searchTerm = debounceValue(debouncedValue);
		}
		if (!livescreen) {
			if (getScreenId()) form.screen_ids = getScreenId();
		}

		if (debounceCheck(debouncedValue)) {
			if (count !== 1 && debCount > 1) {
				if (onLoad === 'page') {
					dispatch(status && getBucketTableData(`${ROOT_URL}${GET_FILTER_URL}`, form));
					setStopScroll(true);
				} else if (onLoad === 'tabChange' && stopScroll) {
					scroll();
				}
			} else if (onLoad === 'screenChange') {
				setLoading(true);
				dispatch(
					status &&
						getBucketTableDataOnScreenChange(
							`${ROOT_URL}${GET_FILTER_URL}`,
							form,
							(response) => {
								if (response.status && response.data?.data) {
									setLoading(false);
									setTabCount(response);
								}
							},
						),
				);
			} else if (onLoad === 'tabChange' || count === 1 || debCount === 1) {
				setLoading(true);
				dispatch(
					status &&
						getBucketTableDataOnTabChange(
							`${ROOT_URL}${GET_FILTER_URL}`,
							form,
							(response) => {
								if (response.status && response.data?.data) {
									setLoading(false);
									setTabCount(response);
								}
							},
						),
				);
			}
		} else {
			if (count !== 1 && count !== 0) {
				if (onLoad === 'page') {
					dispatch(status && getBucketTableData(`${ROOT_URL}${GET_FILTER_URL}`, form));
					setStopScroll(true);
				} else if (onLoad === 'tabChange' && stopScroll) {
					scroll();
				}
			} else if (onLoad === 'screenChange') {
				setLoading(true);
				dispatch(
					status &&
						getBucketTableDataOnScreenChange(
							`${ROOT_URL}${GET_FILTER_URL}`,
							form,
							(response) => {
								if (response.status && response.data?.data) {
									setLoading(false);
									setTabCount(response);
								}
							},
						),
				);
			} else if (onLoad === 'tabChange' || count === 1 || count === 0) {
				setLoading(true);
				dispatch(
					status &&
						getBucketTableDataOnTabChange(
							`${ROOT_URL}${GET_FILTER_URL}`,
							form,
							(response) => {
								if (response.status && response.data?.data) {
									setLoading(false);
									setTabCount(response);
								}
							},
						),
				);
			}
		}
		return () => {
			cancel = true;
		};
	}, [dispatch, count, setUpdatedData, onLoad, status, getScreenId, debouncedValue]);

	const setValuesOnFetch = () => {
		if (debounceCheck(debouncedValue)) {
			setDebCount((val) => val + 1);
		}
		setCount((val) => val + 1);
		setOnLoad('page');
	};

	const fetchMoreData = () => {
		setStopScroll(false);
		if (status === 'published') {
			if (newBucketTableData.published.length < newBucketLength.published) {
				setValuesOnFetch();
			}
		} else if (status === 'unpublished') {
			if (newBucketTableData.unpublished.length < newBucketLength.unpublished) {
				setValuesOnFetch();
			}
		} else if (status === 'archived') {
			if (newBucketTableData.archived.length < newBucketLength.archived) {
				setValuesOnFetch();
			}
		} else {
			if (newBucketTableData.draft.length < newBucketLength.draft) {
				setValuesOnFetch();
			}
		}
	};

	const handleAllCheck = () => {
		setIsAllChecked(!isAllChecked);
	};

	const dragEndHandler = (e) => {
		const draggedItem = tableDisplayData[e.newIndex];
		let bucketRank;
		if (e.newIndex === 0) {
			bucketRank =
				tableDisplayData[e.newIndex + 1]['bucket_rank'] > 1
					? tableDisplayData[e.newIndex + 1]['bucket_rank'] - 1
					: tableDisplayData[e.newIndex + 1]['bucket_rank'] / 1.01;
		} else if (e.newIndex === tableDisplayData.length - 1) {
			bucketRank = tableDisplayData[e.newIndex - 1]['bucket_rank'] + 1;
		} else {
			bucketRank =
				(tableDisplayData[e.newIndex - 1]['bucket_rank'] +
					tableDisplayData[e.newIndex + 1]['bucket_rank']) /
				2;
		}

		dispatch(
			updateBucketRankById(
				`${ROOT_URL}${UPDATE_BUCKET_URL}`,
				{
					bucket_id: draggedItem?.bucket_id,
					screen_id: draggedItem?.screen_id,
					bucket_rank: bucketRank,
				},
				() => {
					let newtableData = [];
					tableDisplayData.forEach((item) => {
						if (item.bucket_id === draggedItem.bucket_id) item.bucketRank = bucketRank;
						newtableData.push(item);
					});
					setTableDisplayData([...newtableData]);
				},
			),
		);
	};

	const renderTable = () => {
		if (loading) {
			return (
				<EmptyState
					columns={
						status === 'archived'
							? BUCKET_TABLE_COLUMNS_CONFIG_ARCHIVED
							: BUCKET_TABLE_COLUMNS_CONFIG
					}
					renderColumns={2}
				/>
			);
		} else {
			if (shuffleMode) {
				return (
					<ReactSortable
						tag={CustomComponent}
						list={tableDisplayData}
						setList={setTableDisplayData}
						onEnd={dragEndHandler}>
						{tableDisplayData.map((item, index) => {
							return (
								<Row
									key={item && item.screen_bucket_id}
									data={{ ...item, index }}
									isAllChecked={isAllChecked}
									shuffleMode={shuffleMode ?? false}
									status={statusHere}
									deleteAll={deleteAll}
									setUncheckedOnSelectOne={setUncheckedOnSelectOne}
									selectedCards={selectedCards}
									length={tableDisplayData?.length}
									onSelecting={onSelectingCards}
									bucket_id={item?.bucket_id}
									screen_id={item?.screen_id}
									livescreen={livescreen}
								/>
							);
						})}
					</ReactSortable>
				);
			} else {
				return tableDisplayData.map((item, index) => {
					return (
						<Row
							key={item && `${item.screen_bucket_id}-${item.screen_name}`}
							data={{ ...item, index }}
							isLast={index + 1 === tableDisplayData.length ? true : false}
							isAllChecked={isAllChecked}
							shuffleMode={shuffleMode ?? false}
							adjustPaddingBottomStatus={(state) => setIncreasePaddingBottomStatus(state)}
							adjustPaddingBottomMoreOption={(state) =>
								setIncreasePaddingBottomMoreOption(state)
							}
							adjustPaddingBottomScreen={(state) => setIncreasePaddingBottomScreen(state)}
							openEditBucketModal={() => {
								openEditBucketModal(
									item.bucket_id,
									item.screen_bucket_id,
									item.is_predefined,
									item.is_user_type,
								);
							}}
							openDeleteModal={() => {
								openDeleteModal(item.bucket_id, item.screen_bucket_id);
							}}
							openChangeStatusModal={
								status !== 'draft'
									? (val) => {
											openChangeStatusModalArchived(item.bucket_id, item.screen_id, val);
									  }
									: () => {
											openChangeStatusModal(item.bucket_id, item.screen_id);
									  }
							}
							status={statusHere}
							deleteAll={deleteAll}
							setUncheckedOnSelectOne={setUncheckedOnSelectOne}
							selectedCards={selectedCards}
							length={tableDisplayData?.length}
							onSelecting={onSelectingCards}
							bucket_id={item?.bucket_id}
							screen_id={item?.screen_id}
							livescreen={livescreen}
						/>
					);
				});
			}
		}
	};

	return (
		<div
			id="scrollableDiv"
			className={
				increasePaddingBottomStatus ||
				increasePaddingBottomScreen ||
				increasePaddingBottomMoreOption
					? 'padding-bottom-increase'
					: ''
			}>
			<InfiniteScroll
				dataLength={
					status === 'published'
						? newBucketTableData.published.length
						: status === 'unpublished'
						? newBucketTableData.unpublished.length
						: status === 'archived'
						? newBucketTableData.archived.length
						: newBucketTableData.draft.length
				}
				next={fetchMoreData}
				hasMore={
					status === 'published'
						? newBucketTableData.published.length < newBucketLength.published
						: status === 'unpublished'
						? newBucketTableData.unpublished.length < newBucketLength.unpublished
						: status === 'archived'
						? newBucketTableData.archived.length < newBucketLength.archived
						: newBucketTableData.draft.length < newBucketLength.draft
				}
				loader={<Loader />}
				className={livescreen ? 'infinite-scroll-live-box' : 'infinite-scroll-buck-box'}
				scrollableTarget="scrollableDiv"
				endMessage={infinteScrollEndMessage()}>
				<table className="table">
					<tbody>
						<tr>
							{columnsConfig.map((column) => {
								if (column.key === 'screens_key' && status === 'archived') {
									return null;
								} else {
									return (
										<Column
											key={column.key}
											config={column}
											handleAllCheck={handleAllCheck}
											shuffleMode={shuffleMode ?? false}
											fromBuck={true}
											selectToDeleteAll={selectToDeleteAll}
											deleteAll={deleteAll}
											unCheckedOnSelectOne={unCheckedOnSelectOne}
											status={livescreen}
											marginClassName={'margin-no'}
										/>
									);
								}
							})}
							<th className="info-width"></th>
						</tr>
						{renderTable()}
					</tbody>
				</table>
			</InfiniteScroll>
		</div>
	);
};
