import React from 'react';
import './styles.scss';
import TagPills from 'src/app/components/atoms/TagPills';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';

export const TagsPopup = ({ data, closePopup, bucketName, handleAddMoreTags }) => {
	return (
		<div className="tag-popup-container">
			<div className="tag-popup-cross" onClick={closePopup}>
				<img src={CrossIcon} alt="Cross-Icon" />
			</div>
			<div className="tag-main-block">
				<div className="tag-header">
					<div className="heading">{`${bucketName} Tags`}</div>
					<div className="add-tags" onClick={handleAddMoreTags}>
						Add More Tags
					</div>
				</div>
				<div className="seprator"></div>
				<div className="pill-block">
					{data.map((items) => {
						return (
							<TagPills key={items?.tag_id} id={items?.tag_id} data={items?.tag_name} />
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default TagsPopup;
