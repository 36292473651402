import { combineReducers } from 'redux';
import { reservationsReducer } from '../pages/Dashboard/reducer';
import { sidebarReducer } from '../pages/Dashboard/sidebarReducer';
import contentManagementReducer from '../pages/ContentManagement/reducer';
import qewdDigestReducer from 'src/app/pages/QwedDigest/reducer';
import liveChannelReducer from 'src/app/pages/LiveChannel/reducer';
import breadCrumbReducer from 'src/app/components/BreadCrumbs/reducer';
import allUserReducer from 'src/app/pages/AllUsers/reducer';
import reportedVideosReducer from 'src/app/pages/ReportedVideos/reducer';
import castReducer from 'src/app/pages/CastCrew/reducer';
import settingReducer from 'src/app/pages/SettingsScreen/reducer';
import liveBuckReducer from 'src/app/pages/LiveBucketDetail/reducer';

export const rootReducer = combineReducers({
	reservations: reservationsReducer,
	sidebar: sidebarReducer,
	contentManagement: contentManagementReducer,
	qewdDigest: qewdDigestReducer,
	liveChannel: liveChannelReducer,
	breadCrumb: breadCrumbReducer,
	allUsers: allUserReducer,
	reportedVideo: reportedVideosReducer,
	castReducer: castReducer,
	settingReducer: settingReducer,
	liveBuckReducer: liveBuckReducer,
});
