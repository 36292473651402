import React, { useState, useEffect } from 'react';
import './styles.scss';
import DownArrow from 'src/app/assets/logos/DownArrow.svg';
import ClickAwayListener from 'react-click-away-listener';

const CustomFilterDropDown = ({
	placeholder,
	idFieldName,
	handleToastDismiss,
	dropDownMenuItems,
	handleValueChanges,
	defaultValue,
	itemClassName,
	optionClassName,
	dropDownContainerClass,
}) => {
	const [activeOption, setActiveOption] = useState();
	const [showOptionDropdown, setShowOptionDropdown] = useState();
	const [dropDownItems, setDropDownItems] = useState(dropDownMenuItems);

	useEffect(() => {
		setActiveOption(defaultValue);
		return () => {
			setActiveOption(null);
		};
	}, [defaultValue]);

	const handleDropdownChange = (state) => {
		if (handleToastDismiss) handleToastDismiss();
		setShowOptionDropdown((prev) => {
			const arrowEl = document.getElementById('select-box-bucket-down-arrow');
			if (prev) {
				arrowEl?.classList.remove('select-box-down-arrow-rotate');
				arrowEl?.classList.add('select-box-down-arrow-unrotate');
				return !prev;
			} else {
				if (state) {
					arrowEl?.classList.remove('select-box-down-arrow-unrotate');
					arrowEl?.classList.add('select-box-down-arrow-rotate');
					return !prev;
				} else return false;
			}
		});
	};

	const handleSelect = (selectedOptions) => {
		setActiveOption(selectedOptions);
		handleValueChanges(selectedOptions);
	};

	const handleOptionChange = (option) => {
		if (handleSelect) handleSelect(option);
		handleDropdownChange(false);
	};

	return (
		<ClickAwayListener onClickAway={() => handleDropdownChange(false)}>
			<div className={dropDownContainerClass ? dropDownContainerClass : ''}>
				<span className="select-span" onClick={handleDropdownChange}>
					<div>
						<p className="selected-value">
							{activeOption?.name ? activeOption?.name : placeholder}
						</p>
					</div>
					<div>
						<img src={DownArrow} id="select-box-bucket-down-arrow" alt="Down Arrow" />
					</div>
				</span>
				{showOptionDropdown && (
					<div className="option-box-wrapper bucket-option-wrapper-size">
						<ul>
							{dropDownItems?.map((option, key) => (
								<li
									key={key}
									className={`${itemClassName ? itemClassName : ''}
										${
											activeOption && activeOption[idFieldName] === option[idFieldName]
												? 'active-option'
												: ''
										}`}
									onClick={() => handleOptionChange(option)}>
									{activeOption && activeOption[idFieldName] === option[idFieldName] ? (
										<p
											className={optionClassName ? optionClassName : ''}
											style={{ color: '#F82C5E' }}>
											{option.name}
										</p>
									) : (
										<p className={optionClassName ? optionClassName : ''}>
											{option.name}
										</p>
									)}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
};

export default CustomFilterDropDown;
