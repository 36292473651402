import React, { useState, useEffect } from 'react';
import AddIcon from '../../../assets/logos/Add.svg';
import { IMAGE_PLACEHOLDER_HORIZONTAL } from 'src/app/common/constants/AppConstants';
import './styles.scss';
import { getImage } from 'src/app/common/utils/methods';

const HorizontalTile = ({
	tileDetails,
	handleVideo,
	movieLabel,
	handleClick,
	fromYt = false,
	fromSearch = false,
	fromBucket = false,
}) => {
	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
		ev.target.className = 'cardImgHor placeholderImage';
	};

	const handleAddButton = () => {
		if (fromYt) {
			handleVideo(
				tileDetails?.video_id,
				tileDetails?.horizontal_thumbnail,
				tileDetails?.title,
				tileDetails?.description_alias,
				'onAdd',
			);
		}
	};
	return (
		<div className={`carousel-horizontal-content-tile ${fromSearch ? 'ytSearch' : ''}`}>
			<div className={'image-parent-block horizontal-box'}>
				{true && (
					<span
						id={`add-icon-${tileDetails.video_id}`}
						className={'add-icon'}
						onClick={handleAddButton}>
						<img id={`image-${tileDetails.video_id}`} src={AddIcon} alt="add" />
					</span>
				)}
				<img
					onError={addDefaultSrc}
					id={`tile-${tileDetails.video_id}`}
					className="cardImgHor"
					src={
						getImage(tileDetails?.horizontal_thumbnail) || IMAGE_PLACEHOLDER_HORIZONTAL
					}
					alt="thumbnail"
					onClick={handleClick}
				/>
			</div>
			<div className={'tile-horizontal-label'}>
				<p>{tileDetails?.title}</p>
			</div>
			{movieLabel && (
				<div className="movie-label">
					<div className="movie-label-text">{movieLabel}</div>
				</div>
			)}
		</div>
	);
};

export default HorizontalTile;
