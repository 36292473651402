import React from 'react';
import './styles.scss';

const CustomRadioGroup = ({ name, values, defaultValue, onChange }) => {
	return values.map(({ id, value, name: valueName, screen_id }) => {
		const screen =
			defaultValue &&
			defaultValue.length > 0 &&
			defaultValue.filter((screen) => screen.screen_id === screen_id)[0];
		let valueToBeChecked;
		if (screen) {
			valueToBeChecked = screen.status.toLowerCase() === value.toLowerCase();
		}
		else {
			valueToBeChecked = false;
		}
		return (
			<div key={id} className="custom-radio-button-container">
				<input
					className="radio-button"
					type="radio"
					id={id}
					name={name}
					value={value}
					onChange={(e) =>
						onChange(
							{
								status: value.toLowerCase(),
								name: valueName,
								screen_id,
							},
							e,
						)
					}
					checked={valueToBeChecked}
				/>
				<label htmlFor={id}>{value}</label>
				<div className="check" />
			</div>
		);
	});
};

export default CustomRadioGroup;
