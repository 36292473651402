import {
	GET_TAGS_DATA_INITIAL,
	GET_TAGS_DATA,
} from 'src/app/pages/Dashboard/actionTypes';
import SETTING_INITIAL_STATE from './initialState';

const settingReducer = (state = SETTING_INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_TAGS_DATA_INITIAL:
			return {
				...state,
				tags: action.payload || [],
			};
		case GET_TAGS_DATA:
			return {
				...state,
				tags: [...state.tags, ...action.payload],
			};
		default:
			return state;
	}
};

export default settingReducer;
