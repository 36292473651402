import { ButtonLoader } from 'src/app/components/ButtonLoader';
import React, { useEffect } from 'react';
import './styles.scss';
import * as CONSTANTS from './constants';
import { useState } from 'react';
import PrimaryTextButton from 'src/app/components/atoms/PrimaryTextButton';

const PublishModal = ({
	onClose,
	onDelete,
	loading,
	message,
	buttonText,
	getSelectionValue,
	frequencyBoxes,
	setFrequencyChecked,
	disabledButton = false,
	publishButtonLoader = false,
	currentFrequency = 0,
}) => {
	const forwardSelection = (value) => {
		getSelectionValue(value);
	};

	return (
		<div className="publish-confirmation-container">
			<div className="title">{message || CONSTANTS.PUBLISH_MESSAGE}</div>
			{frequencyBoxes && (
				<div className="frequency-blocks">
					<FrequencyCheckbox
						frequency={CONSTANTS.FREQUENCY_LABEL}
						forwardSelection={forwardSelection}
						setFrequencyChecked={setFrequencyChecked}
						freq={currentFrequency}
					/>
				</div>
			)}
			<div className="buttons">
				<button onClick={onClose} className="cancel">
					{CONSTANTS.CANCEL}
				</button>
				<PrimaryTextButton
					disabledButton={disabledButton}
					label={buttonText || CONSTANTS.PUBLISH}
					main={true}
					handleClick={onDelete}
					showLoader={publishButtonLoader}
				/>
			</div>
		</div>
	);
};

const FrequencyCheckbox = ({
	frequency,
	forwardSelection,
	setFrequencyChecked,
	freq,
}) => {
	const [check, setCheck] = useState(CONSTANTS.FREQUENCY_MAPPER[freq]);

	useEffect(() => {
		if (freq) {
			setFrequencyChecked(true);
		}
	}, []);

	const clicked = (e) => {
		setCheck(null);
		if (e.target.checked) {
			setCheck(e.target.name);
			forwardSelection(e.target.name);
			setFrequencyChecked(true);
		} else {
			setFrequencyChecked(false);
		}
	};

	return (
		<div className="wrapper-frequency">
			{frequency.map((item) => {
				return (
					<div
						className={`frequency-container ${
							check === `${item}` ? 'active-input-container' : ''
						}`}
						key={`key-${item}`}>
						<input
							type="checkbox"
							id="checkbox-publish-row"
							className="input-publish"
							name={`${item}`}
							checked={check === `${item}`}
							onChange={(e) => clicked(e)}
						/>
						<label htmlFor={`id-${item}`} className="label-publish">
							{item}
						</label>
					</div>
				);
			})}
		</div>
	);
};

export default PublishModal;
