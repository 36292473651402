import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import DownArrow from 'src/app/assets/logos/DownArrow.svg';
import IMDb from 'src/app/assets/logos/IMDb.svg';

export const Column = ({
	config,
	handleAllCheck,
	shuffleMode,
	countMultipleCheck,
	isWaitlist = false,
	onPopup = false,
	status = false,
	tab = null,
	marginClassName = null,
	fromAllUser = false,
	fromCast = false,
	dropDownValue,
	setDropDownValue,
	handledrop,
	highlighted,
	tagsCount = null,
	fromBuck = false,
	selectToDeleteAll,
	deleteAll,
	unCheckedOnSelectOne,
	livescreen = false,
}) => {
	const handleDropDown = (e, val, key) => {
		highlighted.current = key;
		document
			.getElementById('down-name')
			?.classList?.remove('select-box-down-arrow-rotate');
		document
			.getElementById('down-email')
			?.classList?.remove('select-box-down-arrow-rotate');
		document
			.getElementById('down-total-videos')
			?.classList?.remove('select-box-down-arrow-rotate');
		document
			.getElementById('down-followers')
			?.classList?.remove('select-box-down-arrow-rotate');
		document
			.getElementById('down-remaining-invites')
			?.classList?.remove('select-box-down-arrow-rotate');
		document
			.getElementById('down-popularity')
			?.classList?.remove('select-box-down-arrow-rotate');

		if (key === 'name' && !fromCast) {
			setDropDownValue({
				name: !dropDownValue.name,
				email: false,
				'total-videos': false,
				followers: false,
				'remaining-invites': false,
			});
			handledrop('name');
		} else if (key === 'name' && fromCast) {
			setDropDownValue({
				name: !dropDownValue.name,
				popularity: false,
			});
			handledrop('name');
		} else if (key === 'email') {
			setDropDownValue({
				name: false,
				email: !dropDownValue.email,
				'total-videos': false,
				followers: false,
				'remaining-invites': false,
			});
			handledrop('email');
		} else if (key === 'total-videos') {
			setDropDownValue({
				name: false,
				email: false,
				'total-videos': !dropDownValue['total-videos'],
				followers: false,
				'remaining-invites': false,
			});
			handledrop('total-videos');
		} else if (key === 'followers') {
			setDropDownValue({
				name: false,
				email: false,
				'total-videos': false,
				followers: !dropDownValue.followers,
				'remaining-invites': false,
			});
			handledrop('followers');
		} else if (key === 'remaining-invites') {
			setDropDownValue({
				name: false,
				email: false,
				'total-videos': false,
				followers: false,
				'remaining-invites': !dropDownValue['remaining-invites'],
			});
			handledrop('remaining-invites');
		} else if (key === 'popularity' && fromCast) {
			setDropDownValue({
				name: false,
				popularity: !dropDownValue.popularity,
			});
			handledrop('popularity');
		}
	};

	const renderAction = () => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<span
					className={`${config.spanClassName} ${
						fromAllUser && config.key === highlighted.current ? 'change-color' : ''
					}`}>
					{config.title}
				</span>
				{fromAllUser &&
					config.className !== 'action' &&
					config.className !== 'movies-shows' &&
					renderImage()}
			</div>
		);
	};

	const renderNonCheckboxColumnNames = () => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<span
					className={`${marginClassName ? marginClassName : 'extra-margin-left'} ${
						config.spanClassName
					} ${fromAllUser && config.key === highlighted.current ? 'change-color' : ''}`}>
					{config.title}
				</span>
				{fromAllUser && renderImage()}
			</div>
		);
	};

	const renderCheckboxes = () => {
		return (
			<span className="checkbox">
				{shuffleMode ? (
					<span className={'empty-space'}></span>
				) : fromBuck ? (
					<input
						id="name-checkbox"
						type="checkbox"
						onChange={selectToDeleteAll}
						alt="selection"
						checked={deleteAll && unCheckedOnSelectOne}
					/>
				) : isWaitlist ? (
					<input
						type="checkbox"
						id="name-checkbox"
						value="all"
						onClick={handleAllCheck}
						checked={countMultipleCheck < 1 ? false : true}
					/>
				) : (
					<input
						type="checkbox"
						id="name-checkbox"
						value="all"
						onClick={handleAllCheck}
					/>
				)}
				<span
					className={`${config.spanClassName} ${
						fromAllUser && config.key === highlighted.current ? 'change-color' : ''
					}`}>
					{config.title}
					{tagsCount ? ` (${tagsCount})` : ''}
				</span>
				{fromAllUser && renderImage()}
			</span>
		);
	};

	const renderImage = () => {
		return (
			<img
				src={DownArrow}
				className={`down-arrow ${
					dropDownValue[config.key]
						? 'select-box-down-arrow-rotate'
						: 'select-box-down-arrow-unrotate'
				}`}
				id={`down-${config.key}`}
				alt="Down Arrow"
				onClick={(e) => handleDropDown(e, `down-${config.key}`, config.key)}
			/>
		);
	};

	const renderImdbImage = () => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img alt="IMDb" src={IMDb} />
			</div>
		);
	};

	return (
		<th
			key={config.key}
			className={`${onPopup ? config.popUpClassName : config.className} ${
				tab === 'deleted' ? config.deleteClassName : ''
			}`}>
			{config.className === 'imdb' && renderImdbImage()}
			{config.className === 'name' || config.className === 'name-approved' ? (
				!status ? (
					renderCheckboxes()
				) : (
					renderNonCheckboxColumnNames()
				)
			) : isWaitlist && status && config.className === 'actions' ? (
				<span className={config.spanClassName}>Date</span>
			) : (
				config.className !== 'imdb' && renderAction()
			)}
		</th>
	);
};

Column.propTypes = {
	config: PropTypes.object.isRequired,
};
