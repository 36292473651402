export const RECORD_TO_FETCH = 20;
export const BUCKET_DETAILS_TABLE_COLUMNS_CONFIG = [
	{
		key: 'name',
		title: 'Name',
		className: 'name',
		spanClassName: 'name-span',
	},
	{
		key: 'url',
		title: 'Channel URL',
		className: 'url',
		spanClassName: 'url-span',
	},
	{
		key: 'logo',
		title: 'Logo',
		className: 'logo',
		spanClassName: 'logo-span',
	},
	{
		key: 'action',
		title: 'Actions',
		className: 'action',
		spanClassName: 'action-span',
	},
];

export const BUCKET_DETAILS_TABLE_COLUMNS_CONFIG_FOR_CATEGORY = [
	{
		key: 'name',
		title: 'Name',
		className: 'name',
		spanClassName: 'name-category-span',
	},
	{
		key: 'url',
		title: '',
		className: 'url',
		spanClassName: 'url-span',
	},
	{
		key: 'logo',
		title: 'Logo',
		className: 'logo',
		spanClassName: 'logo-span',
	},
	{
		key: 'action',
		title: 'Actions',
		className: 'action',
		spanClassName: 'action-span',
	},
];

export const BUCKET_DETAILS_TABLE_COLUMNS_CONFIG_FOR_CATEGORY_CHANNELS = [
	{
		key: 'name',
		title: 'Name',
		className: 'name',
		spanClassName: 'name-category-span',
	},
	{
		key: 'url',
		title: '',
		className: 'url',
		spanClassName: 'url-span',
	},
	{
		key: 'logo',
		title: 'Logo',
		className: 'logo',
		spanClassName: 'logo-span',
	},
];

export const EMPTY_STATE_MESSAGE = {
	NO_DATA_FOUND: `This bucket does not contains\n any live channel(s)`,
	NO_SUBCATEGORY_DATA: `This sub category does not\n contains any live channels`,
	NO_DATA_FOUND_LIVE_SUB: `This bucket does not contains\n any live sub-category(s)`,
};

export const SHUFFLE_CONST = {
	reorder_message: 'Want to re-order listing?',
	click_shuffle: 'Click to Shuffle',
	reorder_done: 'Done with reordering?',
	click_back: 'Back to Normal View',
};

export const LIVE_MSSG = {
	live_stream_mssg: 'live_stream is already exist in bucket!',
	live_channel_mssg: 'live channel(s) is/are already exist in bucket',
};

export const CONTENT_TYPE = {
	live_stream: 'live_stream',
	live_channel: 'live_channel',
};

export const CONTENT_TYPE_DISPLAY = {
	live_stream: 'live channel(s)',
	live_channel: 'live sub-category(s)',
};

export const TOOLTIP_CONST = {
	live_stream_add: 'Add Live Channel',
	live_stream_delete: 'Delete Live Channel',
	live_channel_add: 'Add Live Sub-category',
	live_channel_delete: 'Delete Live Sub-category',
};
