const BUCKET_URL = 'https://images.qewd.com/bucket-icons';
export const IconList = [
	{
		id: 1,
		img: BUCKET_URL + '/tv.svg',
		addRightMargin: false,
	},
	{
		id: 2,
		img: BUCKET_URL + '/more_kebab.svg',
		addRightMargin: false,
	},
	{
		id: 3,
		img: BUCKET_URL + '/people.svg',
		addRightMargin: true,
	},
	{
		id: 4,
		img: BUCKET_URL + '/fire.svg',
		addRightMargin: false,
	},
	{
		id: 5,
		img: BUCKET_URL + '/cap.svg',
		addRightMargin: false,
	},
	{
		id: 6,
		img: BUCKET_URL + '/masks.svg',
		addRightMargin: true,
	},
	{
		id: 7,
		img: BUCKET_URL + '/medal.svg',
		addRightMargin: false,
	},
	{
		id: 8,
		img: BUCKET_URL + '/camera.svg',
		addRightMargin: true,
	},
	{
		id: 9,
		img: BUCKET_URL + '/home.svg',
		addRightMargin: false,
	}, //home
	{
		id: 10,
		img: BUCKET_URL + '/youtube.svg',
		addRightMargin: true,
	},
	{
		id: 11,
		img: BUCKET_URL + '/diamond.svg',
		addRightMargin: true,
	},
	{
		id: 12,
		img: BUCKET_URL + '/reel.svg',
		addRightMargin: false,
	}, //reel
	{
		id: 13,
		img: BUCKET_URL + '/multi_screen.svg',
		addRightMargin: false,
	},
	{
		id: 14,
		img: BUCKET_URL + '/monitor.svg',
		addRightMargin: false,
	},
	{
		id: 15,
		img: BUCKET_URL + '/person_location.svg',
		addRightMargin: false,
	},
	{
		id: 16,
		img: BUCKET_URL + '/handbag.svg',
		addRightMargin: false,
	}, //secondline finish
	{
		id: 17,
		img: BUCKET_URL + '/music.svg',
		addRightMargin: true,
	},
	{
		id: 18,
		img: BUCKET_URL + '/bus.svg',
		addRightMargin: false,
	},
	{
		id: 19,
		img: BUCKET_URL + '/cycle.svg',
		addRightMargin: true,
	},
	{
		id: 20,
		img: BUCKET_URL + '/pizza.svg',
		addRightMargin: false,
	},
	{
		id: 21,
		img: BUCKET_URL + '/chat.svg',
		addRightMargin: false,
	},
	{
		id: 22,
		img: BUCKET_URL + '/mic.svg',
		addRightMargin: true,
	},
	{
		id: 23,
		img: BUCKET_URL + '/piggy.svg',
		addRightMargin: false,
	},
	{
		id: 24,
		img: BUCKET_URL + '/notebook.svg',
		addRightMargin: true,
	},
	{
		id: 25,
		img: BUCKET_URL + '/fb.svg',
		addRightMargin: false,
	},
	{
		id: 26,
		img: BUCKET_URL + '/insta.svg',
		addRightMargin: true,
	},
	{
		id: 27,
		img: BUCKET_URL + '/twitter.svg',
		addRightMargin: true,
	},
	{
		id: 28,
		img: BUCKET_URL + '/linkedin.svg',
		addRightMargin: false,
	},
	{
		id: 29,
		img: BUCKET_URL + '/folder.svg',
		addRightMargin: false,
	},
	{
		id: 30,
		img: BUCKET_URL + '/bulb.svg',
		addRightMargin: false,
	},
	{
		id: 31,
		img: BUCKET_URL + '/gallery.svg',
		addRightMargin: false,
	},
	{
		id: 32,
		img: BUCKET_URL + '/whatsapp.svg',
		addRightMargin: false,
	},
	{
		id: 33,
		img: BUCKET_URL + '/open_folder.svg',
		addRightMargin: true,
	},
	{
		id: 34,
		img: BUCKET_URL + '/star.svg',
		addRightMargin: false,
	},
	{
		id: 35,
		img: BUCKET_URL + '/ball.svg',
		addRightMargin: true,
	},
	{
		id: 36,
		img: BUCKET_URL + '/fork_and_spoon.svg',
		addRightMargin: false,
	},
	{
		id: 37,
		img: BUCKET_URL + '/smiley.svg',
		addRightMargin: false,
	},
	{
		id: 38,
		img: BUCKET_URL + '/heart.svg',
		addRightMargin: true,
	},
	{
		id: 39,
		img: BUCKET_URL + '/sun.svg',
		addRightMargin: false,
	},
	{
		id: 40,
		img: BUCKET_URL + '/Category-Icon40.svg',
		addRightMargin: true,
	},
];
