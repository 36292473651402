import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import DeleteBucketActive from 'src/app/assets/images/DeleteIconActive.svg';
import EditIcon from 'src/app/assets/images/EditActionButton.svg';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { overlayStyle } from 'src/app/common/constants/AppConstants';
import * as CONSTANT from '../constants';
import ClickAwayListener from 'react-click-away-listener';
import { getRowClassName } from 'src/app/common/utils/methods';

export const Row = ({
	data,
	isAllChecked,
	handleCheck,
	handleLiveChannelId,
	val,
	handleClick,
	id,
	handleChangeOnEdit,
	handleChangeOnDelete,
	countOnAllUnselected,
	onPopup,
	tab,
	openTooltip,
	closeToolTip,
	liveChannelId,
	handleMoreClick,
	handleVideoPopup,
}) => {
	const [icon, setIcon] = useState(DeleteBucket);
	const findValues = id.includes(data?.live_stream_id);
	const valueOnAllUnchecked = countOnAllUnselected.includes(data?.live_stream_id);
	const addDefaultSrc = (ev) => {
		ev.target.className = 'row-icon-img row-icon-img-none';
		ev.target.src = '';
	};

	const onMouseEnterOnDeleteButton = () => {
		setIcon(DeleteBucketActive);
	};

	const onMouseLeaveOnDeleteButton = () => {
		setIcon(DeleteBucket);
	};

	const rowIconClass =
		onPopup || tab === CONSTANT.TABS[2].key
			? 'row-icon-popup'
			: tab === CONSTANT.TABS[0].key && !onPopup
			? 'row-icon-all'
			: 'row-icon';

	const rowActionClass = tab === CONSTANT.TABS[0].key && !onPopup ? 'extra-padding' : '';

	const renderTooltipData = () => {
		return (
			<div className="tooltip-wrapper">
				{data?.sub_categories.map((item) => {
					return (
						<div className="category-capsule-outer-wrapper">
							<div className="category-capsule" key={item.live_sub_category_id}>
								<span className="category-capsule-inner-text">{item?.name}</span>
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<tr className={getRowClassName(data?.index)}>
			<td className={`${tab === CONSTANT.TABS[2].key ? 'extra-margin-name' : ''}`}>
				{tab !== CONSTANT.TABS[2].key ? (
					<span className="checkbox">
						{isAllChecked ? (
							findValues ? (
								<input
									type="checkbox"
									id="name-checkbox-row"
									value="all"
									checked={val}
									onChange={handleClick}
								/>
							) : (
								<input
									type="checkbox"
									id="name-checkbox-row"
									value="all"
									checked={isAllChecked}
									onChange={handleClick}
								/>
							)
						) : (
							<input
								type="checkbox"
								id="name-checkbox-row"
								value={data?.display_name}
								onChange={handleCheck}
								checked={valueOnAllUnchecked}
							/>
						)}

						<span className="bucket-name">{data?.display_name}</span>
					</span>
				) : (
					<span className="bucket-name">{data?.display_name}</span>
				)}
			</td>
			<td
				className={`${
					onPopup || tab === CONSTANT.TABS[2].key
						? 'row-url-popup'
						: tab === CONSTANT.TABS[0].key && !onPopup
						? 'row-url-all'
						: 'row-url'
				}`}
				onClick={handleVideoPopup}>
				{data?.url}
			</td>
			{!onPopup && tab === CONSTANT.TABS[0].key && (
				<td className="row-category">
					<div className="row-category-div">
						{data?.sub_categories?.length < 3 ? (
							data?.sub_categories.map((item) => {
								return (
									<div className="category-capsule" key={item.live_sub_category_id}>
										<span className="category-capsule-inner-text">{item?.name}</span>
									</div>
								);
							})
						) : (
							<div className="row-category-div">
								<div className="category-capsule">
									<span className="category-capsule-inner-text">
										{data?.sub_categories[0]?.name}
									</span>
								</div>
								<div className="category-capsule">
									<span className="category-capsule-inner-text">
										{data?.sub_categories[1]?.name}
									</span>
								</div>
								{data?.live_stream_id === liveChannelId && openTooltip ? (
									<ClickAwayListener onClickAway={() => closeToolTip()}>
										<div>
											<div
												className="category-more"
												onClick={(e) => handleMoreClick(e, data?.live_stream_id)}>
												{`+${data?.sub_categories?.length - 2} More`}
												<div className={`${openTooltip ? 't-one' : null}`}>
													<div className={`tooltip ${openTooltip ? 'tool' : null}`}>
														{renderTooltipData()}
													</div>
												</div>
											</div>
										</div>
									</ClickAwayListener>
								) : (
									<div
										className="category-more"
										onClick={(e) => handleMoreClick(e, data?.live_stream_id)}
										data-tip
										data-for="registerTip">
										{`+${data?.sub_categories.length - 2} More`}
									</div>
								)}
							</div>
						)}
					</div>
				</td>
			)}
			<td className={rowIconClass}>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<img
						onError={addDefaultSrc}
						className="row-icon-img"
						src={`${data?.logo}`}
						alt="icon"
					/>
				</div>
			</td>
			{!onPopup && tab !== CONSTANT.TABS[2].key && (
				<td className={`row-actions ${rowActionClass}`}>
					<Tooltip
						overlay="Delete Live Channel"
						placement="bottom"
						overlayInnerStyle={overlayStyle}
						align={{
							offset: [-4, -2],
						}}>
						<span
							className="live-stream-action-button"
							onClick={() =>
								handleChangeOnDelete(data?.live_stream_id, data?.display_name)
							}
							onMouseEnter={() => onMouseEnterOnDeleteButton()}
							onMouseLeave={() => onMouseLeaveOnDeleteButton()}>
							<img src={icon} className="live-stream-action-button-image" alt="delete" />
						</span>
					</Tooltip>
					<Tooltip
						overlay="Edit Live Channel"
						placement="bottom"
						overlayInnerStyle={overlayStyle}
						align={{
							offset: [8, -2],
						}}>
						<span className="live-stream-action-button" onClick={handleChangeOnEdit}>
							<img
								src={EditIcon}
								className="live-stream-action-button-image-exta-styling"
								alt="edit"
							/>
						</span>
					</Tooltip>
				</td>
			)}
		</tr>
	);
};

Row.propTypes = {
	data: PropTypes.object.isRequired,
};
