import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import GrabIcon from 'src/app/assets/logos/Grab.svg';
import { transitionOccured, bucketRedirectPath } from 'src/app/common/utils/methods';
import CarouselMoreOptionBox from 'src/app/components/atoms/CarouselMoreOptionBox';
import Kebab from 'src/app/assets/logos/Kebab.svg';
import ClickAwayListener from 'react-click-away-listener';
import Vertmore from 'src/app/assets/logos/VertMore.svg';
import { newDateRange } from 'src/app/common/utils/dateUtils';
import { EDIT_OPTION } from '../constant';
import { setBucketDetails } from 'src/app/pages/Dashboard/actionCreators';

const Row = ({ data, shuffleMode, handleRemove }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [shuffleClass, setShuffleClass] = useState('');
	const [showOptionDropdown, setShowOptionDropdown] = useState(false);

	const classNameConditon = (data.index + 1) % 2 === 0 ? 'even' : 'odd';
	const sectionOneEl = document.querySelector('#scrollableDivTags');
	sectionOneEl?.addEventListener('scroll', (ev) => {
		if (showOptionDropdown) {
			setShowOptionDropdown(false);
		}
	});

	useEffect(() => {
		if (shuffleMode) {
			if (transitionOccured('rowShuffle', 'get') && data.index === 1) {
				setTimeout(() => {
					setShuffleClass('translateX');
				}, 100);
				setTimeout(() => {
					setShuffleClass('');
				}, 1000);
				transitionOccured('rowShuffle', 'set');
			}
		}
	}, [shuffleMode]);

	const handleSelect = (buck_id) => {
		handleRemove(buck_id);
		setShowOptionDropdown(false);
	};

	const handleBucketRedirect = (id, title) => {
		dispatch(setBucketDetails({}));
		const path = bucketRedirectPath(id, title);
		history.push(path);
	};

	return (
		<tr className={`${classNameConditon} ${shuffleClass}`}>
			<td className="row-bucketName">
				<span className="checkbox">
					<img src={GrabIcon} className="grab-icon" alt="Grab Icon" />
					<span
						className="bucket-name"
						onClick={() => handleBucketRedirect(data?.bucket_id, data?.name)}>
						{`${data.name}${data?.is_predefined || data?.is_user_type ? '  *' : ''}`}
					</span>
				</span>
			</td>
			<td className="row-dateRange">{newDateRange(data)}</td>
			<td className="row-videoCount">
				{data?.video_count}
				{/* <span className="row-videoCount-span">{data.videoCount}</span> */}
			</td>
			<td className="row-icon">
				<ClickAwayListener onClickAway={() => setShowOptionDropdown(false)}>
					<div>
						<div className={`options ${showOptionDropdown ? 'padding-more' : ''}`}>
							{!showOptionDropdown ? (
								<img
									src={Vertmore}
									alt="dots"
									className="dots"
									onClick={() => setShowOptionDropdown(!showOptionDropdown)}
								/>
							) : (
								<img
									src={Kebab}
									className="selected-dots"
									alt="dots"
									onClick={() => setShowOptionDropdown(!showOptionDropdown)}
								/>
							)}
						</div>
						{showOptionDropdown && (
							<div className="kebab-dropdown">
								<CarouselMoreOptionBox
									from="carousel"
									options={EDIT_OPTION}
									handleSelect={() => handleSelect(data?.bucket_id)}
								/>
							</div>
						)}
					</div>
				</ClickAwayListener>
			</td>
		</tr>
	);
};

export default Row;
