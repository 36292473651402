import React from 'react';
import { useHistory } from 'react-router-dom';
import notFound from 'src/app/assets/images/not-found.png';
import { AppButton } from 'src/app/components/AppButton';
import './styles.scss';

export const NotFound = () => {
	const history = useHistory();

	const handleClick = () => {
		history.push('/dashboard');
	};

	return (
		<div className="not-found-page">
			<div className="not-found-wrapper">
				<div className="not-found-desc">
					<span className="not-found-text">
						The page you are looking for could not be found.
					</span>
					<AppButton label="Return to Home" onClick={handleClick} />
				</div>
			</div>
		</div>
	);
};
