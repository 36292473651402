import React from 'react';
import EditIcon from '../../../../../../assets/images/Edit.svg';
import './EditButton.styles.scss';
const EditButton = ({ disabled, handleClick }) => {
	return (
		<div
			className={`edit-button-container ${disabled ? 'disabled' : ''}`}
			onClick={handleClick}>
			<img src={EditIcon} alt="edit" />
		</div>
	);
};

export default EditButton;
