import {
    SET_EXPANDED_SIDEBAR
} from './actionTypes';
import { SIDEBAR_INITIAL_STATE } from './initialState';

export function sidebarReducer(state = SIDEBAR_INITIAL_STATE, action) {
    switch (action.type) {
        case SET_EXPANDED_SIDEBAR:
            return { ...state, expanded: action.payload};
        default:
            return state;
    }
}
