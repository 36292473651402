export const mapLiveStreamSubCategory = (response) => {
	const { data } = response;
	const transformedData = data?.map((item) => {
		return {
			content_id: item?.live_stream_id,
			content_type: item?.content_type || 'live_stream',
			name: item?.display_name,
			thumbnail: item?.logo,
			url: item?.url,
			sequence: item?.sequence,
		};
	});

	return {
		data: transformedData,
	};
};
