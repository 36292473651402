import React, { useState } from 'react';
import CloseImage from 'src/app/assets/images/thumbnail-close.svg';
import CloseImageInWhite from 'src/app/assets/images/thumbnail-close-white.svg';
import './styles.scss';

const Thumbnails = ({ thumbnail, onCrossIconClick }) => {
	const [onHover, setOnHover] = useState(false);
	const changeImage = (value) => {
		setOnHover(value);
	};

	return (
		<div
			key={thumbnail.id}
			id={`thumbnail-container-${thumbnail.id}`}
			className="thumbnail-container">
			<img
				id={`thumbnail-${thumbnail.id}`}
				src={thumbnail.thumbnail}
				alt="thumnail"
				className="thumbnail-image"
			/>
			<div
				onMouseEnter={()=>changeImage(true)}
				onMouseLeave={()=>changeImage(false)}
				className="cross-icon"
				onClick={() => onCrossIconClick(thumbnail)}
				role="button">
				<img src={onHover ? CloseImageInWhite : CloseImage} alt="close" />
			</div>
		</div>
	);
};

export default Thumbnails;
