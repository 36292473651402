import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import PopupModal from '../../../components/molecules/PopupModal';
import { popupContentStyle as bucketStyle } from '../../../common/constants/PopupStyles';
import './EditBucketModal.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CARD_SIZE, ROOT_URL } from 'src/app/common/constants/AppConstants';
import { getCardSizes } from 'src/app/pages/Dashboard/actionCreators';

const EditBucketModal = ({
	isOpen,
	setIsOpen,
	bucketId,
	showToast,
	isEdit,
	callback,
	screenBucketId,
	isDisable,
	fromYt = false,
	livescreen = false,
}) => {
	const dispatch = useDispatch();
	const [bucketListUpdateFlag, setBucketListUpdateFlag] = useState(false);
	const cardSize = useSelector((state) => state.reservations.cardSizes);

	const closeModal = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (!cardSize) {
			dispatch(getCardSizes(`${ROOT_URL}${GET_CARD_SIZE}`, {}));
		}
	}, []);

	const toggleCalendar = (isVisible) => {
		const modalContainerContent = document.querySelector('.modal-container-content');
		const calender = document.querySelector('.date-range-container');
		const buttons = document.querySelector('.action-button');
		if (isVisible) {
			if (calender) {
				setTimeout(() => {
					modalContainerContent.scrollTop = modalContainerContent.scrollHeight;
					buttons.classList.add('disabled-edit-button');
				}, 300);
			}
		} else {
			buttons.classList.remove('disabled-edit-button');
			modalContainerContent.scrollTop = 0;
		}
	};
	const toggleThumbnailPicker = (isVisible) => {
		const modalContainerContent = document.querySelector('.modal-container-content');
		if (isVisible) {
			setTimeout(() => {
				modalContainerContent.scrollTop = modalContainerContent.scrollHeight;
			}, 400);
		} else {
			modalContainerContent.scrollTop = 0;
		}
	};

	const newBucketCreated = (
		{ response, message },
		isDiscoverScreenPreset,
		isHomeScreenPreset,
		screenTo,
	) => {
		setBucketListUpdateFlag(!bucketListUpdateFlag);
		if (message) {
			if (callback) {
				callback(isDiscoverScreenPreset, isHomeScreenPreset, screenTo);
			}
			showToast(message, false, true);
		} else if (response) {
			showToast(response.data?.message, true, true);
		}
	};

	return (
		<Popup
			className="edit-modal-popup-animation"
			open={isOpen}
			closeOnDocumentClick
			onClose={closeModal}
			contentStyle={bucketStyle}>
			<PopupModal
				handleClose={closeModal}
				show={isOpen}
				toggleThumbnailPicker={toggleThumbnailPicker}
				toggleCalendar={toggleCalendar}
				newBucketCreated={newBucketCreated}
				isEdit={isEdit}
				bucketId={bucketId}
				screenBucketId={screenBucketId}
				isDisable={isDisable}
				fromYt={fromYt}
				livescreen={livescreen}
			/>
		</Popup>
	);
};

export { EditBucketModal };
