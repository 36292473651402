import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import { ActiveContext } from 'src/app/contexts/ActiveRouteProvider';
import { useLocation } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { overlayStyle } from 'src/app/common/constants/AppConstants';
import { GET_LIVE_STREAM_DATA_INITIAL } from 'src/app/pages/Dashboard/actionTypes';

const NavigationLink = (props) => {
	const dispatch = useDispatch();
	const { activeRoute, setActive } = React.useContext(ActiveContext);
	const location = useLocation();
	const alignConfig = {
		offset: [2, 0],
	};
	const alignConfigSecond = {
		offset: [-80, 0],
	};

	const activeClass =
		props.index === 0 ? 'active-logo' : props.isExpanded ? 'active-expanded' : 'active';

	const getValueFromURL = (pathName) => {
		const firstRange = pathName.indexOf('/');
		const secondRange = pathName.indexOf('/', firstRange + 1);
		return secondRange > -1
			? pathName.slice(firstRange + 1, secondRange)
			: pathName.slice(firstRange + 1);
	};
	const values = getValueFromURL(location.pathname);

	useEffect(() => {
		if (values !== 'live') {
			dispatch({ type: GET_LIVE_STREAM_DATA_INITIAL, payload: { data: [] } });
		}
	}, [values]);

	const isActiveRoute = () => {
		return activeRoute.split('/')[0] === props.tabData.link;
	};

	const handleNavlinkClick = () => {
		setActive(`${props.tabData.link}`);
		checkExpansion(props.tabData.link);
	};

	const logo =
		values === props.tabData.link ? props.tabData.activeLogo : props.tabData.inactiveLogo;

	const checkExpansion = (val) => {
		if (val === 'dashboard') {
			props.expansion(false);
		} else {
			props.expansion();
		}
	};
	React.useEffect(() => {
		setActive(window.location.pathname.slice(1));
	}, [setActive]);
	return !props?.isExpanded ? (
		<Tooltip
			overlay={props.tabData.label || 'Dashboard'}
			placement="right"
			defaultVisible={false}
			overlayInnerStyle={overlayStyle}
			align={props.index === 1 || props.index === 0 ? alignConfig : alignConfigSecond}>
			<NavLink
				to={`/${props.tabData.link}`}
				activeClassName={activeClass}
				onClick={handleNavlinkClick}>
				<span className={`sidebar-link ${isActiveRoute() ? 'active-span' : ''}`}>
					{props.tabData.id === 1 ? (
						<img alt="nav-link" src={logo} />
					) : (
						<img
							alt="nav-link"
							src={logo}
							className={props.isExpanded ? 'expanded-image' : ''}
						/>
					)}
					{props.isExpanded && props.tabData.label ? (
						<p className="expanded-link-text">{props.tabData.label}</p>
					) : null}
				</span>
			</NavLink>
		</Tooltip>
	) : (
		<NavLink
			to={`/${props.tabData.link}`}
			activeClassName={activeClass}
			onClick={handleNavlinkClick}>
			<span className={`sidebar-link ${isActiveRoute() ? 'active-span' : ''}`}>
				{props.tabData.id === 1 ? (
					<img alt="nav-link" src={logo} />
				) : (
					<img
						alt="nav-link"
						src={logo}
						className={props.isExpanded ? 'expanded-image' : ''}
					/>
				)}
				{props.isExpanded && props.tabData.label ? (
					<p className="expanded-link-text">{props.tabData.label}</p>
				) : null}
			</span>
		</NavLink>
	);
};
export default NavigationLink;
