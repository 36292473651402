import React, { useState, useEffect } from 'react';
import './Table.styles.scss';
import { getRowClassName, getYear } from 'src/app/common/utils/methods';
import * as CONST from '../constant';
import AddIcon from 'src/app/assets/logos/Add.svg';
import FilePickerIcon from 'src/app/assets/images/FilePickerIcon.svg';
import { transitionOccured, selectCards } from 'src/app/common/utils/methods';
import GrabIcon from 'src/app/assets/logos/Grab.svg';

const Row = ({
	data,
	id,
	shuffleMode,
	innerref,
	styleset,
	snap,
	handleAddButton,
	deleteAll,
	setUncheckedOnSelectOne,
	selectedCards,
	length,
	onSelecting,
	setDeleteAll,
	fromBucket,
	openDetail,
	...other
}) => {
	const [shuffleClass, setShuffleClass] = useState('');
	const [selectionValue, setSelectionValue] = useState('');

	// useEffect(() => {
	// 	if (data) {
	// 		console.log('DATA---->\n', data?.title, data?.videoId);
	// 	}
	// }, [data]);

	useEffect(() => {
		if (selectedCards?.length > 0) {
			const cards = selectCards(selectedCards, id);
			setSelectionValue(cards);
		}
	}, [selectedCards, selectionValue]);

	useEffect(() => {
		setSelectionValue(deleteAll);
	}, [deleteAll]);

	const onSelectionChange = (e) => {
		if (deleteAll && !e.target.checked) {
			setUncheckedOnSelectOne(false);
			setDeleteAll(false);
		}
		if (deleteAll && e.target.checked) {
			if (selectedCards?.length + 1 === length) {
				setUncheckedOnSelectOne(true);
			}
		}
		setSelectionValue(e.target.checked);
		onSelecting({ id: e.target.id, value: e.target.checked });
	};

	useEffect(() => {
		if (shuffleMode) {
			if (transitionOccured('rowShuffle', 'get') && data.index === 1) {
				setTimeout(() => {
					setShuffleClass('translateX');
				}, 100);
				setTimeout(() => {
					setShuffleClass('');
				}, 1000);
				transitionOccured('rowShuffle', 'set');
			}
		}
	}, [shuffleMode]);

	const addDefaultSrc = (ev) => {
		ev.target.className = 'tag-icon-error';
		ev.target.src = FilePickerIcon;
	};

	function getStyle(style, snapshot) {
		if (!snapshot.isDropAnimating) {
			return style;
		}
		return {
			...style,
			// cannot be 0, but make it super tiny
			transitionDuration: `0.001s`,
		};
	}

	const addVideo = () => {
		if (fromBucket) {
			handleAddButton(data?.url?.replace('https://www.youtube.com/watch?v=', ''));
		} else {
			handleAddButton(data?.video_id || data);
		}
	};

	return (
		<tr
			className={`${getRowClassName(data?.index)} ${shuffleClass}`}
			ref={innerref}
			style={shuffleMode ? getStyle(styleset, snap) : null}
			{...other}>
			<td>
				<span className="checkbox">
					{shuffleMode ? (
						<img src={GrabIcon} className="grab-icon" alt="Grab Icon" />
					) : (
						<input
							id={id}
							type="checkbox"
							className="name-checkbox-row"
							value={selectionValue}
							onChange={onSelectionChange}
							alt="selection"
							checked={selectionValue}
						/>
					)}

					<div className="title-container">
						<div className="title-image-container">
							<img
								src={data.thumbnail || FilePickerIcon}
								alt={'Title Image'}
								onError={addDefaultSrc}
								className={`${data.thumbnail ? '' : 'icon-error'}`}
							/>
						</div>
						<p
							className="title-name"
							onClick={() =>
								openDetail(data?.videoId || data?.video_id, data?.url || '')
							}>
							{data?.title}
						</p>
					</div>
				</span>
			</td>

			{fromBucket && (
				<td className="channel-row">
					<div
						className="channel"
						onClick={() => openDetail(data?.videoId || data?.video_id, data?.url || '')}>
						{`${data?.description?.substring(0, 120)}${
							data?.description?.length > 120 ? '...' : ''
						}` || 'N/A'}
					</div>
				</td>
			)}
			{!fromBucket && (
				<td className="channel-row">
					<div className="channel">{data?.channelTitle || 'N/A'}</div>
				</td>
			)}

			{!fromBucket && (
				<td className="publishTime-row">
					<div className="publishTimeRow">{data?.publishTime || 'N/A'}</div>
				</td>
			)}

			<td className="row-options">
				<img src={AddIcon} alt={'add icon'} onClick={addVideo} />
			</td>
		</tr>
	);
};

export default Row;
