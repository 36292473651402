import React, { useState, useEffect } from 'react';
import './YoutubeCarousal.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router-dom';
import HorizontalTile from 'src/app/components/atoms/HorizontalTile';
import { useMediaQuery } from 'react-responsive';
import { getYouTubeData } from '../Dashboard/Service';
import {
	RESPONSIVE_VERTICAL,
	RESPONSIVE_HORIZONTAL,
	IS_DESKTOP_OR_LAPTOP_MEDIA,
} from 'src/app/common/constants/CarouselScreenConstant';
import Arrow from 'src/app/assets/images/NavigateCategory.svg';
import ArrowActive from 'src/app/assets/images/NavigateCategoryActive.svg';

const YoutubeCarousal = ({ data, compId, openDetailPopup, addVideo }) => {
	const history = useHistory();
	const [ytData, setYtData] = useState([]);
	const [icon, setIcon] = useState(Arrow);
	const isDesktopOrLaptop = useMediaQuery({
		query: `(min-width: ${IS_DESKTOP_OR_LAPTOP_MEDIA})`,
	});

	useEffect(() => {
		fetchData();
	}, [data]);

	const handleRedirection = (val) => {
		let path = `/detail?title=${val}&fromBucket=${false}`;
		history.push(path);
	};

	const fetchData = async () => {
		try {
			const res = await getYouTubeData(
				process.env.REACT_APP_YOUTUBE_SEARCH_AND_DOWNLOAD,
				{ hl: 'en', gl: 'US', type: data?.type },
				false,
			);
			if (res && res?.contents) {
				setYtData(res?.contents);
			}
		} catch (error) {}
	};

	const renderSwitch = () => {
		return (
			<Carousel
				centerMode={false}
				additionalTransfrom={0}
				swipeable={!isDesktopOrLaptop}
				draggable={!isDesktopOrLaptop}
				slidesToSlide={isDesktopOrLaptop ? 3 : 5}
				minimumTouchDrag={1}
				responsive={RESPONSIVE_HORIZONTAL}
				containerClass="carousel-container"
				itemClass="carousel-item">
				{ytData?.length > 0 &&
					ytData?.slice(0, 21).map((movieItem, index) => {
						return (
							<HorizontalTile
								key={index}
								handleVideo={() => addVideo(movieItem?.video?.videoId)}
								handleClick={() => openDetailPopup(movieItem?.video?.videoId)}
								fromYt={true}
								tileDetails={{
									video_id: movieItem?.video?.videoId,
									horizontal_thumbnail:
										movieItem?.video?.thumbnails[movieItem?.video?.thumbnails?.length - 1]
											?.url,
									title: movieItem?.video?.title,
									description_alias: movieItem?.video?.publishedTimeText,
								}}
							/>
						);
					})}
			</Carousel>
		);
	};

	return (
		<div className="carousel-wrapper">
			<div className="carousel-header-block">
				<div
					className="heading"
					onMouseEnter={() => setIcon(ArrowActive)}
					onMouseLeave={() => setIcon(Arrow)}
					onClick={() => handleRedirection(data?.heading)}>
					<h3 className={'heading'}>{data?.heading}</h3>
					<span onClick={() => {}}>
						<img src={icon} alt="Navigate to subcategory" className="navigate-icon" />
					</span>
				</div>
			</div>

			<div className="carousel-cardWrap">
				<div id={`content-block` + compId} className="carousel-content-block">
					{renderSwitch()}
				</div>
			</div>
		</div>
	);
};

export default YoutubeCarousal;
