import React, { useState } from 'react';
import { popupContentStyle } from 'src/app/common/constants/PopupStyles';
import PopupModal from 'src/app/components/molecules/PopupModal';
import Popup from 'reactjs-popup';

export const CreateBucket = ({
	isOpen,
	closeModal,
	toggleModal,
	newBucketCreated,
	fromYt = false,
}) => {
	const [isCalendarVisible, setIsCalendarVisible] = useState(false);
	const [isThumbnailPickerVisible, setIsThumbnailPickerVisible] = useState(false);

	const toggleThumbnailPicker = (isVisible) => {
		setIsThumbnailPickerVisible(isVisible);
		const modalContainerContent = document.querySelector('.modal-container-content');
		if (isVisible) {
			setTimeout(() => {
				modalContainerContent.scrollTop = modalContainerContent.scrollHeight;
			}, 400);
		}
	};
	const toggleCalendar = (isVisible) => {
		const modalContainerContent = document.querySelector('.modal-container-content');
		const calender = document.querySelector('.date-range-container');
		const buttons = document.querySelector('.action-button');
		setIsCalendarVisible(() => {
			return isVisible;
		});
		if (isVisible) {
			if (calender) {
				setTimeout(() => {
					modalContainerContent.scrollTop = modalContainerContent.scrollHeight;
					buttons.classList.add('disabled-edit-button');
				}, 500);
			}
		} else {
			buttons.classList.remove('disabled-edit-button');
			modalContainerContent.scrollTop = 0;
		}
	};

	return (
		<Popup
			className="popup-content-container"
			open={isOpen}
			closeOnDocumentClick
			onClose={closeModal}
			contentStyle={popupContentStyle}>
			<PopupModal
				handleClose={() => toggleModal()}
				show={isOpen}
				toggleThumbnailPicker={toggleThumbnailPicker}
				toggleCalendar={toggleCalendar}
				newBucketCreated={newBucketCreated}
				fromYt={fromYt}></PopupModal>
		</Popup>
	);
};

export default CreateBucket;
