import { Loader } from '../../components/Loader';
import moment from 'moment';
import { profileBackgroundColor } from 'src/app/pages/AllUsers/constants';

export function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function validatePhoneNumber(phoneNumber) {
	const matchpattern = /^\+[1-9]{1}[0-9]{1,14}$/;
	return matchpattern.test(phoneNumber);
}

export function validatePassword(password) {
	if (password.length > 7) return true;
	else return false;
}
export const transitionOccured = (key = 'bucketShuffle', type = 'get') => {
	if (type === 'get') {
		if (localStorage.getItem(key)) {
			return false;
		} else {
			return true;
		}
	} else {
		localStorage.setItem(key, true);
	}
};
export const removeAllSpecialCharacters = (str) => {
	return str.replace(/[^a-zA-Z_-]/g, '');
};

export const isEmptyString = (str) => {
	return !/^\s*$/.test(str);
};

export const isEmptyArray = (arr) => {
	return arr.length === 0;
};

export const transformValuesForParams = (filters) => {
	let result = { providers: [], genres: [], directors: [], casts: [] };
	filters.forEach((item) => {
		if (item.type === 'providers') {
			result['providers'].push(item.id.split('-')[1]);
		} else if (item.type === 'genres') {
			result['genres'].push(item.id.split('-')[1]);
		} else if (item.type === 'directors') {
			result['directors'].push(item.id);
		} else if (item.type === 'movieCast') {
			result['casts'].push(item.id);
		}
	});
	return {
		providers: result['providers'].toString(),
		genres: result['genres'].toString(),
		directors: result['directors'].toString(),
		casts: result['casts'].toString(),
	};
};

export const getLoader = (
	loading,
	isUpdatedData,
	newDataLength,
	totalLength,
	tableData,
) => {
	if (loading) {
		return <Loader loadMore={true} />;
	} else if (isUpdatedData && newDataLength < totalLength) {
		return <Loader loadMore={true} />;
	} else if (!isUpdatedData && tableData && tableData.length < totalLength) {
		return <Loader loadMore={true} />;
	} else {
		return null;
	}
};

export const isValidURL = (str) => {
	const matchpattern =
		/^https?:\/\/(?:www\.|(?!www))+([-a-zA-Z0-9@:/%._\+~#=,]{1,256})\.+([a-zA-Z0-9()]{1,6})\b([-a-zA-Z0-9()@:%_\+.~#?&//=,]*)$/gm;
	return matchpattern.test(str);
};

export const isValidM3U8URL = (str) => {
	const matchpattern =
		/^https?:\/\/(?:www\.|(?!www))+([-a-zA-Z0-9@:/%._\+~#=]{1,256})\.+((m3u8){1,6})\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
	return matchpattern.test(str);
};

export const modifiedDebounceValue = (debouncedValue) => {
	return debouncedValue.replace(/\s+/g, ' ').trim();
};

export const dateFormatWaitlist = (data) => {
	return moment(data.updated_at).format('MMM DD, YYYY ');
};

export const getDate = (time) => {
	return moment(time).format('h:mm A | MMM D, YYYY');
};

export const capitalizeFirstLetter = (string) => {
	return string?.charAt(0)?.toUpperCase() + string?.slice(1) || '';
};

export const getRowClassName = (index) => {
	if ((index + 1) % 2 === 0) {
		return 'even';
	} else {
		return 'odd';
	}
};

export const debounceCheck = (debouncedValue) => {
	if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
		return true;
	}
	return false;
};

export const debounceValue = (debouncedValue) => {
	if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
		const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
		return modifiedDebounceValue;
	}
};
export const getImage = (url) => {
	let s = url || '';
	if (url) {
		// in case of url is coming as string null return empty
		if (url === 'NULL') {
			return '';
		}
		if (!(url?.startsWith('https') || url?.startsWith('http'))) {
			s = url;
		}
		if (s.includes('backdrop-1920.jpg')) {
			s = s.replace('backdrop-1920.jpg', 'backdrop-1280.webp');
		} else if (s.includes('poster-780.jpg')) {
			s = s.replace('poster-780.jpg', 'poster-342.webp');
		}
	}
	return s;
};
export const removePlusFromMobile = (value) => {
	return value.replace('+', '');
};

export const getProfileInitials = (dataName) => {
	const name = dataName.split(' ');
	if (name.length > 1) {
		return (
			`${name[0]?.charAt(0)?.toUpperCase() || ''}${
				name[1]?.charAt(0)?.toUpperCase() || ''
			}` || ''
		);
	} else {
		return `${name[0]?.charAt(0)?.toUpperCase() || ''}`;
	}
};

export const getProfileBackground = (name) => {
	const nameIndex = (name && name.length > 0 ? name[0].charCodeAt(0) : 1) % 7;
	return profileBackgroundColor[nameIndex];
};

export const getYear = (val) => {
	const date = new Date(val);
	return date.getFullYear();
};

export const selectCards = (selectedCards, id) => {
	const index = selectedCards.findIndex((item) => item.id === id.toString());
	return index > -1;
};

export const removeSingleEntryFromArray = (userArray, toMatchValue) => {
	const arr = [...userArray];
	const index = arr.findIndex((item) => item?.user_id === toMatchValue);
	if (index > -1) {
		arr.splice(index, 1);
	}
	return arr;
};

export const bucketRedirectPath = (id, title, route = 'bucket') => {
	let path;
	if (title.includes('?')) {
		path = `/${route}/${title.split('?').join('%3F')}?bucket_id=${id}`;
	} else {
		path = `/${route}/${title}?bucket_id=${id}`;
	}
	return path;
};

export const bucketRedirectPathForLive = (id, title, route, content_type) => {
	let path;
	if (title.includes('?')) {
		path = `/${route}/${title
			.split('?')
			.join('%3F')}?bucket_id=${id}&content_type=${content_type}`;
	} else {
		path = `/${route}/${title}?bucket_id=${id}&content_type=${content_type}`;
	}
	return path;
};
