import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RECORD_TO_FETCH } from 'src/app/common/constants/BucketListingConstant';
import * as CONST from '../constants';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import {
	getTableData,
	postCommonMethod,
	getCommonMethod,
	putCommonMethod,
} from '../../Dashboard/Service';
import {
	GET_LIVE_CHANNEL_DATA_INITIAL,
	GET_LIVE_CHANNEL_DATA,
} from 'src/app/pages/Dashboard/actionTypes';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import InfiniteScroll from 'react-infinite-scroll-component';
import { infinteScrollEndMessage } from 'src/app/pages/ComponentConstant';
import { Loader } from 'src/app/components/Loader';
import { Row } from './Row';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import Table from 'src/app/components/Table';
import {
	liveChannelPopupStyle,
	createChannelPopupStyle,
	deletePopupStyle,
} from 'src/app/common/constants/PopupStyles';
import LiveChannelCategoryPopup from 'src/app/components/LiveChannelCategoryPopup';
import CreateLiveChannelPopup from 'src/app/components/CreateLiveChannelPopup';
import Popup from 'reactjs-popup';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import { isEmptyString } from 'src/app/common/utils/methods';

const TableContainer = ({
	offset,
	setOffset,
	loading,
	setLoading,
	setChecked,
	handleLiveChannelId,
	liveChannelId,
	apiBody,
	setApiBody,
	isAllChecked,
	setIsAllChecked,
	countMultipleCheck,
	setCountMultipleCheck,
	countOnAllUnselected,
	setCountOnAllUnselected,
	shuffleMode = false,
	openTooltip,
	setOpenTooltip,
	addChannelCategoryPopup,
	handleAddChannelCategory,
	setToastInfo,
	addButtonLoader,
	setAddButtonLoader,
	handleCreate,
	createPopup,
	categoryOffset,
	setCategoryOffset,
	isDisable,
	setDisable,
	bucketName,
	setBucketName,
	selectCategoryToAddChannel,
	setSelectCategoryToAddChannel,
	iconKey,
	setIconKey,
	setCounterToShowDelete,
	defaultDeleteMessage,
	counterToShowDelete,
	showDeletePopup,
	setShowDeletePopup,
	channelName,
	setChannelName,
	setDeleteChannelId,
	closeDeleteConfirmationModal,
	onDeleteChannel,
	selectedCategory,
	isEditPopup,
	setIsEditPopup,
	category,
	setCategory,
	prevSelectCategoryToAddChannel,
	prevBucketName,
	setPrevBucketName,
	setPrevSelectCategoryToAddChannel,
	debouncedValue,
	setTotalCount,
	onPopup = false,
}) => {
	const mount = useRef();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const tableData = useSelector((state) => state.liveChannel?.liveChannels);
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [count, setCount] = useState(0);
	const [val, setVal] = useState(false);
	const [id, setId] = useState([]);
	const [delVal, setDelVal] = useState(0);
	const [addCategory, setAddCategory] = useState(false);
	const [categoryLabelsDemo, setCategoryLabelsDemo] = useState([]);
	const [hasMoreCategory, setHasMoreCategory] = useState(false);
	const [createChannelApiBody, setCreateChannelApiBody] = useState(null);
	const [thumbnail, setThumbnail] = useState(null);
	const [prevThumbnail, setPrevThumbnail] = useState(null);
	const liveCategoryFromRedux = useSelector((state) => state.liveChannel?.liveCategories);
	const [tableConfig, setTableConfig] = useState(CONST.LIVE_CHANNEL_TABLE_COLUMNS_CONFIG);

	useEffect(() => {
		if (onPopup) {
			setTableConfig(CONST.LIVE_CHANNEL_TABLE_COLUMNS_CONFIG_FOR_POPUP);
		} else {
			setTableConfig(CONST.LIVE_CHANNEL_TABLE_COLUMNS_CONFIG);
		}
	}, [onPopup]);

	useEffect(() => {
		if (tableData) {
			setLoading(false);
			setTableDisplayData([...tableData]);
		}
	}, [tableData, setLoading]);

	useEffect(() => {
		if (count > 0) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	}, [count]);

	useEffect(() => {
		getCategory();
	}, [categoryOffset]);

	const handleMoreClick = (e, id) => {
		handleLiveChannelId(id);
		if (openTooltip) {
			handleLiveChannelId(null);
		}
		setOpenTooltip(!openTooltip);
	};

	const closeToolTip = () => {
		handleLiveChannelId(null);
		setOpenTooltip(false);
	};

	const handleChangeOnDelete = (channelId, channelName) => {
		setShowDeletePopup(true);
		setChannelName(channelName);
		setDeleteChannelId(channelId);
		handleLiveChannelId(null);
		setOpenTooltip(false);
	};

	const handleChangeOnEdit = (data) => {
		handleLiveChannelId(null);
		setOpenTooltip(false);
		handleCreate(true);
		setIsEditPopup(true);
		if (data) {
			setBucketName(data.name);
			setPrevBucketName(data.name);
			setThumbnail(data.thumbnail);
			setPrevThumbnail(data.thumbnail);
			setIconKey(data.thumbnail);
			handleLiveChannelId(data.live_channel_id);
			setSelectCategoryToAddChannel(
				data.categories.map((item) => parseInt(item.live_category_id)),
			);
			setPrevSelectCategoryToAddChannel(
				data.categories.map((item) => parseInt(item.live_category_id)),
			);
			setCategory(
				data.categories.map((item) => {
					return {
						name: item.name,
						live_category_id: parseInt(item.live_category_id),
					};
				}),
			);
		}
	};
	const setSelectedCategory = (val) => {
		selectedCategory.current = val;
		let newApiBody = [];
		apiBody.map((items) => {
			let bodyObj = {
				live_channel_id: items.live_channel_id,
				live_category_id: val,
			};
			newApiBody.push(bodyObj);
		});
		setApiBody([...newApiBody]);
	};

	useEffect(() => {
		if (!isEditPopup) {
			if (bucketName !== '' && selectCategoryToAddChannel && iconKey) {
				setDisable(true);
				const value = iconKey.lastIndexOf('/');
				const finalIconKey = iconKey.slice(value + 1);
				setCreateChannelApiBody({
					name: bucketName,
					thumbnail: finalIconKey,
					live_category_id: selectCategoryToAddChannel,
				});
			} else {
				setDisable(false);
			}
		}
	}, [bucketName, selectCategoryToAddChannel, iconKey, isEditPopup]);

	useEffect(() => {
		if (isEditPopup) {
			if (
				isEmptyString(bucketName) &&
				isEmptyString(prevBucketName) &&
				isEmptyString(iconKey) &&
				isEmptyString(prevThumbnail) &&
				selectCategoryToAddChannel &&
				selectCategoryToAddChannel.length > 0 &&
				prevSelectCategoryToAddChannel &&
				(prevBucketName !== bucketName ||
					prevSelectCategoryToAddChannel.toString() !==
						selectCategoryToAddChannel.toString() ||
					prevThumbnail !== iconKey)
			) {
				const value = iconKey?.lastIndexOf('/');
				const finalIconKey = iconKey?.slice(value + 1);
				setDisable(true);
				setCreateChannelApiBody({
					name: bucketName,
					thumbnail: finalIconKey,
					live_category_ids: selectCategoryToAddChannel,
				});
			} else {
				setDisable(false);
			}
		}
	}, [
		bucketName,
		iconKey,
		isEditPopup,
		prevBucketName,
		prevSelectCategoryToAddChannel,
		prevThumbnail,
		selectCategoryToAddChannel,
		setDisable,
		thumbnail,
	]);

	useEffect(() => {
		if (countMultipleCheck === 0) {
			setId([]);
		}
		if (isAllChecked) {
			let allCheckedValues = [];
			setCountMultipleCheck(tableData.length - delVal);
			setCounterToShowDelete(tableData.length - delVal);
			tableData.map((item) => {
				if (!id.includes(item.live_channel_id)) {
					let bodyObj = {
						live_channel_id: item.live_channel_id,
						live_category_id: null,
					};
					allCheckedValues.push(bodyObj);
				}
			});
			setApiBody([...allCheckedValues]);
		}
	}, [tableData]);

	const handleClick = (e, channel_id) => {
		if (e.target.checked === false) {
			const index = apiBody.findIndex((item) => item.live_channel_id === channel_id);
			apiBody.splice(index, 1);
			setApiBody([...apiBody]);
			setId([...id, channel_id]);
			setVal(false);
			setCountMultipleCheck((value) => value - 1);
			setCounterToShowDelete((value) => value - 1);
			setDelVal((v) => v + 1);
		}
		if (e.target.checked === true) {
			const index = id.findIndex((item) => item === channel_id);
			id.splice(index, 1);
			let values = {
				live_channel_id: channel_id,
				live_category_id: null,
			};
			setVal(false);
			setApiBody([...apiBody, values]);
			setCountMultipleCheck((value) => value + 1);
			setCounterToShowDelete((value) => value + 1);
			setDelVal((v) => v - 1);
		}
	};

	const handleAllCheck = (e) => {
		setId([]);
		setDelVal(0);
		if (e.target.checked === true) {
			setChecked(true);
			setIsAllChecked(true);
			let allCheckedValues = [];
			setCountMultipleCheck(tableData.length);
			setCounterToShowDelete(tableData.length);
			tableData.map((item) => {
				let bodyObj = {
					live_channel_id: item.live_channel_id,
					live_category_id: null,
				};
				allCheckedValues.push(bodyObj);
			});
			setApiBody([...allCheckedValues]);
		} else {
			setChecked(false);
			setCountMultipleCheck(0);
			setCounterToShowDelete(0);
			setIsAllChecked(false);
			setCount(0);
			setApiBody([]);
		}
		setCountOnAllUnselected([]);
	};

	const handleCheck = (e, id) => {
		if (e.target.checked === true) {
			countOnAllUnselected.push(id);
			setCountOnAllUnselected(countOnAllUnselected);
			setCounterToShowDelete((val) => val + 1);
			setCount((prev) => prev + 1);
			let val = {
				live_channel_id: id,
				live_category_id: null,
			};
			setApiBody([...apiBody, val]);
		} else {
			let indexOnAllUnselected = countOnAllUnselected.findIndex((item) => item === id);
			countOnAllUnselected.splice(indexOnAllUnselected, 1);
			setCountOnAllUnselected(countOnAllUnselected);
			setCounterToShowDelete((val) => val - 1);
			setCount((prev) => prev - 1);
			const index = apiBody.findIndex((item) => item.live_channel_id === id);
			apiBody.splice(index, 1);
			setApiBody([...apiBody]);
		}
	};

	const fetchMoreData = () => {
		if (hasMore) {
			setOffset(tableDisplayData?.length);
		}
	};

	const handleHasMore = (response) => {
		if (response?.data?.length > 0 && response?.data?.length === 20) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const handleAddCategory = () => {
		setAddCategory(true);
		setAddButtonLoader(true);
		setTimeout(() => setCategoryOffset(0), 400);
	};

	useEffect(() => {
		if (addCategory) {
			addCategoryData();
		}
	}, [addCategory]);

	useEffect(() => {
		fetchData();
	}, [dispatch, offset, debouncedValue]);

	const addCategoryData = async () => {
		try {
			const res = await postCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_CHANNEL_TO_CATEGORY}`,
				apiBody,
			);
			if (offset > 0) {
				setOffset(-1);
			} else {
				setOffset((currentVal) => currentVal - 1);
			}
			setAddButtonLoader(false);
			setAddCategory(false);
			handleAddChannelCategory(false);
			setApiBody([]);
			setCountMultipleCheck(0);
			setCounterToShowDelete(0);
			setCountOnAllUnselected([]);
			setIsAllChecked(false);
			setChecked(false);
			selectedCategory.current = null;
			setToastInfo(true, false, res.data.message);
		} catch (error) {
			setAddButtonLoader(false);
			setAddCategory(false);
			handleAddChannelCategory(false);
			selectedCategory.current = null;
			setToastInfo(true, true, error.response.data.message);
		}
	};

	const fetchData = async () => {
		const params = {
			offset: offset > 0 ? offset : 0,
			perPage: RECORD_TO_FETCH,
			orderBy: GLOBAL_CONST.ORDER_BY.createdAt,
			orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
		};
		if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
			const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
			params.searchTerm = modifiedDebounceValue;
		}
		if (offset > 0) {
			const response = await getTableData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CHANNEL_URL}`,
				params,
			);
			handleHasMore(response);
			dispatch({ type: GET_LIVE_CHANNEL_DATA, payload: response.data });
		} else {
			setLoading(true);
			const response = await getTableData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CHANNEL_URL}`,
				params,
			);
			handleHasMore(response);
			setTotalCount(response?.total_record_count);
			dispatch({ type: GET_LIVE_CHANNEL_DATA_INITIAL, payload: response.data });
		}
	};

	const handleAddChannels = async () => {
		setAddButtonLoader(true);
		const medthodToBeCalled = isEditPopup ? putCommonMethod : postCommonMethod;
		const url = isEditPopup
			? `${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.EDIT_LIVE_CHANNELS}`
			: `${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.CREATE_LIVE_CHANNELS}`;

		const params = { ...createChannelApiBody };
		if (isEditPopup) {
			params.live_channel_id = liveChannelId;
		}
		try {
			const response = await medthodToBeCalled(url, params);
			if (response && response.data && response.data.status) {
				if (offset > 0) {
					setOffset(-1);
				} else {
					setOffset((currentVal) => currentVal - 1);
				}
				setToastInfo(true, false, response.data.message);
			} else if (response && response.status) {
				if (offset > 0) {
					setOffset(-1);
				} else {
					setOffset((currentVal) => currentVal - 1);
				}
				setToastInfo(true, false, response.message);
			}
		} catch (error) {
			setToastInfo(true, true, error.response.data.message);
		}
		setAddButtonLoader(false);
		if (!isEditPopup) {
			setIconKey(null);
		}
		setBucketName((prev) => {
			setPrevBucketName(prev);
			return '';
		});
		setSelectCategoryToAddChannel((prev) => {
			setPrevSelectCategoryToAddChannel(prev);
			return null;
		});
		handleCreate(false);
	};

	const handleHasMoreCategory = (response) => {
		if (
			response &&
			response.data &&
			response.data.length > 0 &&
			response.data.length === 20
		) {
			setHasMoreCategory(true);
		} else {
			setHasMoreCategory(false);
		}
	};

	const getCategory = async () => {
		if (liveCategoryFromRedux?.length > 0 && categoryOffset === 0) {
			setCategoryLabelsDemo([...liveCategoryFromRedux]);
			if (liveCategoryFromRedux.length > 0 && liveCategoryFromRedux.length === 20) {
				setHasMoreCategory(true);
			}
		} else {
			const res = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CATEGORY}`,
				{
					offset: categoryOffset,
					perPage: RECORD_TO_FETCH,
					orderBy: GLOBAL_CONST.ORDER_BY.createdAt,
					orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
				},
			);
			if (categoryOffset > 0) {
				setCategoryLabelsDemo([...categoryLabelsDemo, ...res.data]);
				handleHasMoreCategory(res);
			} else {
				setCategoryLabelsDemo([...res.data]);
				handleHasMoreCategory(res);
			}
		}
	};

	const redirectToChannel = (id, name) => {
		history.push(`${location.pathname}/${id}`, { name });
	};

	useEffect(() => {
		mount.current = 'mount';
		setLoading(true);

		return () => (mount.current = 'unmount');
	}, [setLoading]);

	const renderTable = () => {
		if (loading) {
			return <EmptyState columns={tableConfig} renderColumns={2} />;
		} else {
			return tableDisplayData.map((item, index) => {
				return (
					<Row
						key={item && `${item.live_channel_id}`}
						data={{ ...item, index }}
						isAllChecked={isAllChecked}
						handleCheck={(event) => handleCheck(event, item?.live_channel_id, item?.name)}
						handleLiveChannelId={() => {
							handleLiveChannelId(item.live_channel_id);
						}}
						val={val}
						handleClick={(event) => handleClick(event, item.live_channel_id)}
						id={id}
						countOnAllUnselected={countOnAllUnselected}
						handleMoreClick={handleMoreClick}
						openTooltip={openTooltip}
						closeToolTip={closeToolTip}
						liveChannelId={liveChannelId}
						handleChangeOnEdit={() => handleChangeOnEdit(item)}
						handleChangeOnDelete={handleChangeOnDelete}
						redirectToChannel={redirectToChannel}
						onPopup={onPopup}
					/>
				);
			});
		}
	};

	return (
		<div>
			<Table isRenderable={true}>
				<div id="scrollableDivLiveChannel">
					<InfiniteScroll
						dataLength={tableData.length}
						next={fetchMoreData}
						hasMore={hasMore}
						loader={<Loader loadMore={true} />}
						scrollableTarget="scrollableDivLiveChannel"
						endMessage={loading ? null : infinteScrollEndMessage()}>
						<table className="table-live">
							<thead>
								<tr>
									{tableConfig.map((column) => {
										return (
											<Column
												key={column.key}
												config={column}
												handleAllCheck={(event) => handleAllCheck(event)}
												shuffleMode={shuffleMode ?? false}
												isAllChecked={isAllChecked}
												countMultipleCheck={countMultipleCheck}
												isWaitlist={true}
											/>
										);
									})}
								</tr>
							</thead>
							<tbody>{renderTable()}</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</Table>
			<Popup
				open={addChannelCategoryPopup}
				closeOnDocumentClick
				onClose={() => handleAddChannelCategory(false)}
				contentStyle={liveChannelPopupStyle}>
				<LiveChannelCategoryPopup
					onCancel={() => handleAddChannelCategory(false)}
					onAdd={handleAddCategory}
					loading={addButtonLoader}
					setSelectedCategory={setSelectedCategory}
					setCategoryOffset={setCategoryOffset}
					categoryLabelsDemo={categoryLabelsDemo}
					hasMoreCategory={hasMoreCategory}
					category={category}
					isDisable={isDisable}
					setDisable={setDisable}
				/>
			</Popup>
			<Popup
				open={createPopup}
				closeOnDocumentClick
				onClose={() => handleCreate(false)}
				contentStyle={createChannelPopupStyle}>
				<CreateLiveChannelPopup
					onCancel={() => handleCreate(false)}
					setCategoryOffset={setCategoryOffset}
					categoryLabelsDemo={categoryLabelsDemo}
					hasMoreCategory={hasMoreCategory}
					category={category}
					isDisable={isDisable}
					setDisable={setDisable}
					bucketName={bucketName}
					setBucketName={setBucketName}
					setSelectCategoryToAddChannel={setSelectCategoryToAddChannel}
					setIconKey={setIconKey}
					onAdd={handleAddChannels}
					loading={addButtonLoader}
					isEdit={isEditPopup}
					message={isEditPopup ? 'Edit Sub-Category' : null}
					buttonText={isEditPopup ? 'Update' : 'Add'}
					thumbnail={thumbnail}
				/>
			</Popup>
			<Popup
				className="popup-content-container-digest"
				open={showDeletePopup}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={deletePopupStyle}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmationModal}
					onDelete={onDeleteChannel}
					loading={addButtonLoader}
					message={`${
						defaultDeleteMessage
							? `Are you sure you want to delete this ${channelName} live sub-category?`
							: `Are you sure you want to delete these ${counterToShowDelete} live sub-category?`
					}`}
					fromLiveScreen={true}
				/>
			</Popup>
		</div>
	);
};

export default TableContainer;
