import React, { useState, useEffect } from 'react';
import './styles.scss';

const CustomInputPopup = ({
	id,
	defaultValue,
	label,
	placeholder,
	handleValueChanges,
	hasError,
	errorVerbose,
	isDisable = false,
	fromLiveScreen = false,
	onEnter = false,
	handleEnterKeyPress,
	forDesc = false,
	letterCount,
	setLetterCount,
}) => {
	const [value, setValue] = useState(defaultValue || '');

	const textarea = document.querySelector('#autoresizing');
	textarea?.addEventListener('input', autoResize, false);

	function autoResize() {
		this.style.height = 'auto';
		this.style.height = this.scrollHeight + 'px';
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && onEnter) {
			handleEnterKeyPress(e);
			setValue('');
		}
	};

	const onBlur = (e) => {
		handleValueChanges(id, e.target.value);
	};

	useEffect(() => {
		setValue(defaultValue);
		if (textarea) {
			setTimeout(() => {
				textarea.style.height = textarea.scrollHeight + 'px';
			}, 200);
		}
	}, [defaultValue]);

	const handleChange = (e) => {
		setValue(e.target.value);
		if (forDesc) {
			setLetterCount(e.target.value.length);
		}
		handleValueChanges(id, e.target.value);
	};

	return (
		<div className={`custom-input-wrapper ${forDesc ? 'desc-input-wrapper' : ''}`}>
			<p className={'placeholder'}>
				{label}
				{forDesc ? ` - ${letterCount}/300 LETTERS` : ''}
			</p>
			<div className={'input-error-wrapper'}>
				{!forDesc && (
					<input
						className={`input-box ${value?.length > 0 ? 'color-white' : null} ${
							isDisable ? 'disableInput' : null
						} ${fromLiveScreen ? 'input-live' : null} ${forDesc ? 'desc-style' : null}`}
						disabled={isDisable}
						value={value}
						onChange={(e) => handleChange(e)}
						placeholder={placeholder}
						onKeyPress={(e) => handleKeyPress(e)}
					/>
				)}
				{forDesc && (
					<textarea
						className={`${forDesc ? 'desc-style' : null}`}
						onChange={handleChange}
						placeholder={placeholder}
						maxLength={300}
						rows="1"
						defaultValue={value}
						onBlur={onBlur}
						id="autoresizing"></textarea>
				)}

				{hasError && value.length === 0 && (
					<div className={'error-state-field'}>
						<p>{errorVerbose}</p>
					</div>
				)}
			</div>
		</div>
	);
};
export default CustomInputPopup;
