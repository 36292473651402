import React, { useState } from 'react';
import './styles.scss';
import * as CONSTANTS from './constant';

import TableContainer from './component/TableContainer';

export const ReportedVideos = () => {
	const [loading, setLoading] = useState(false);
	return (
		<div className="reported-right-container">
			<div className="reported-header-parent-block">
				<div className="reported-header-block">
					<h1>{CONSTANTS.REPORTED_VIDEOS_HEADING}</h1>
				</div>
			</div>
			<div className="content-block">
				<TableContainer loading={loading} setLoading={setLoading} />
			</div>
		</div>
	);
};

export default ReportedVideos;
