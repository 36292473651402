import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import DigestModal from 'src/app/components/DigestModal';
import { digestPopupStyle as bucketStyle } from '../../../common/constants/PopupStyles';
import './EditDigestModal.styles.scss';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import { setDigestData } from 'src/app/pages/Dashboard/actionCreators';
import * as CONSTANTS from './constants';
import { updateQwedVideos, publishDigest } from 'src/app/pages/Dashboard/actionCreators';
import { ROOT_URL, UPDATE_QEWD_VIDEOS } from 'src/app/common/constants/AppConstants';
import { Toast } from 'src/app/components/Toast';

const EditDigestModal = ({
	isOpen,
	setIsOpen,
	setUpdateBigCard,
	isFrequency,
	setOpenPublishModal,
	setMessage,
	fromPreviewScreen = false,
	descData,
	setDescData,
}) => {
	const [isUpdate, setUpdate] = useState(false);
	const [checkUpdate, setCheckUpdate] = useState(false);
	const [buttonLoader, setButtonLoader] = useState(false);
	const content = useSelector((state) => state.reservations?.digestContentData);
	const thumbnails = useSelector(({ qewdDigest }) => qewdDigest.thumbnails);
	const dispatch = useDispatch();
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);

	const onUpdateData = async () => {
		setButtonLoader(true);
		let apiBody = [];
		if (fromPreviewScreen) {
			thumbnails.map((item) => {
				apiBody.push({
					video_id: item?.id,
					description_alias: item.description_alias,
				});
			});
		} else {
			content.map((item) => {
				apiBody.push({
					video_id: item?.video_id,
					description_alias: item.description_alias,
				});
			});
		}
		await updateQwedVideos(
			`${ROOT_URL}${UPDATE_QEWD_VIDEOS}`,
			apiBody,
			({ response, status }) => {
				if (status === true) {
					dispatch(setDigestData({ content: [], index: [] }));
					setToastInfo(true, false, CONSTANTS.DESC_MESSAGE);
					setUpdateBigCard(true);
					if (!isFrequency) {
						setTimeout(() => {
							setMessage(null);
							setOpenPublishModal(true);
						}, 2100);
					}
				} else {
					setToastInfo(true, true, CONSTANTS.ERROR_MESSAGE);
				}
			},
		);
		setTimeout(() => {
			setButtonLoader(false);
			setUpdate(false);
			setCheckUpdate(false);
			setIsOpen(false);
		}, 300);
	};

	const cancelUpdate = async () => {
		if (checkUpdate) {
			setUpdate(true);
		}
		setIsOpen(false);
	};

	const closeDeleteConfirmationModal = async () => {
		await dispatch(setDigestData({ content: [], index: [] }));
		setUpdate(false);
		setCheckUpdate(false);
		setIsOpen(false);
	};

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const closeModal = () => {
		setIsOpen(false);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '');
	};

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setShowToast(toastDisplayVal);
		setToastError(errorVal);
		setShowToastMessage(messageVal);
	};

	return (
		<div>
			<Popup
				className="edit-modal-popup-animation-digest"
				open={isOpen}
				onClose={closeModal}
				contentStyle={bucketStyle}
				closeOnDocumentClick={false}>
				<DigestModal
					show={isOpen}
					setCheckUpdate={setCheckUpdate}
					onUpdateData={onUpdateData}
					closeDeleteConfirmationModal={cancelUpdate}
					checkUpdate={checkUpdate}
					content={fromPreviewScreen ? thumbnails : content}
					buttonLoader={buttonLoader}
					fromPreviewScreen={fromPreviewScreen}
					descData={descData}
					setDescData={setDescData}
					setToastInfo={setToastInfo}
				/>
			</Popup>

			<Popup
				className="popup-content-container-digest"
				open={checkUpdate && isUpdate}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={CONSTANTS.popupContentStyleDeleteion}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmationModal}
					onDelete={onUpdateData}
					loading={false}
					message={'YOU HAVE UNSAVED CHANGES'}
					buttonText={'Update'}
				/>
			</Popup>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

export { EditDigestModal };
