import React from 'react';
import './styles.scss';
import { ButtonLoader } from '../../ButtonLoader/index';

const PrimaryTextButton = ({
	label,
	handleClick,
	main,
	showLoader,
	disabledButton = false,
}) => {
	return (
		<span
			className={`primary-text-button ${main ? 'padding-main' : 'padding-secondary'} ${
				disabledButton ? 'disableInputButton' : ''
			}`}
			onClick={() => handleClick()}>
			{showLoader ? <ButtonLoader /> : <p>{label}</p>}
		</span>
	);
};

export default PrimaryTextButton;
