import React, { useEffect } from 'react';
import './styles.scss';
import EditIcon from 'src/app/assets/images/Union.svg';
import OtpInput from 'react-otp-input';
import { HOME_CONSTANTS, OTP_CONSTANTS, TOAST_MESSAGES, LOGIN_FORM } from '../constants';
import { ButtonLoader } from 'src/app/components/ButtonLoader';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

export const OTPScreen = ({
	value,
	setVerify,
	isLoggedIn,
	handleEditClick,
	seconds,
	setSeconds,
	minute,
	setMinute,
	disableInput,
	setDisableInput,
	disableButton,
	setDisableButton,
	otp,
	setOtp,
	onVerifyClick,
	setOtpExpire,
	disableResend,
	setDisableResend,
	setShowToast,
	handleResend,
	isButtonLoader,
	verifyButtonLoader,
	setVerifyButtonLoader,
}) => {
	const prepend = (n) => {
		return n < OTP_CONSTANTS.OTP_Seconds_Check ? '0' + n : n;
	};

	useEffect(() => {
		let interval = null;
		if (isLoggedIn && (seconds || minute > 0)) {
			interval = setInterval(() => {
				if (seconds === 1 && minute === 0) {
					setOtpExpire(true);
					setDisableInput(true);
					setShowToast({
						message: TOAST_MESSAGES.EXPIRE_OTP,
						isError: true,
						show: true,
					});
				}
				if (seconds === 11 && minute === 2) {
					setDisableResend(false);
				}
				if (seconds === 0) {
					setMinute((min) => min - 1);
					setSeconds(HOME_CONSTANTS.remainingTimeSeconds);
				} else {
					setSeconds((sec) => sec - 1);
				}
			}, 1000);
		} else if (!isLoggedIn && seconds !== 0) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [seconds, minute, isLoggedIn]);

	const handleClick = async (event) => {
		event.preventDefault(true);
		setVerifyButtonLoader(true);
		const num = otp.toString();
		if (num.length === OTP_CONSTANTS.OTP_Length) {
			onVerifyClick();
		}
	};

	const handleChange = (otp) => setOtp(otp);

	useEffect(() => {
		if (disableInput) {
			setOtp(null);
		}
	}, [disableInput]);

	useEffect(() => {
		if (otp) {
			const num = otp.toString();
			num.length === OTP_CONSTANTS.OTP_Length
				? setDisableButton(false)
				: setDisableButton(true);
		}
	}, [otp]);

	return (
		<div
			className={`otpScreenContainer ${
				isLoggedIn === true ? '' : 'otpScreenContainerTransform'
			}`}>
			<div className="otpFormWrapper">
				<span className="user-num">{LOGIN_FORM.label}</span>
				<div className="emailEdit">
					<PhoneInput
						international
						withCountryCallingCode={false}
						placeholder={LOGIN_FORM.placeholder}
						value={value}
						disabled
						defaultCountry="US"
					/>
					<span onClick={handleEditClick} className="edit-icon">
						<img src={EditIcon}></img>
					</span>
				</div>
				<form id="form">
					<div className="otpBoxes">
						<OtpInput
							value={otp}
							onChange={handleChange}
							numInputs={OTP_CONSTANTS.OTP_Length}
							inputStyle={'inputStyling'}
							placeholder={OTP_CONSTANTS.OTP_Placeholder}
							isInputNum={true}
							isDisabled={disableInput}
						/>
					</div>
					<div className="timeRemainingDiv">
						<span className="timeRemainingSpan">{`${
							OTP_CONSTANTS.OTP_TimeRemaining
						}${minute}:${prepend(seconds)}s`}</span>
					</div>
					<div className="verify-button-wrapper">
						<div className="verify-button-container">
							<button
								className={`verify-button  ${
									disableInput || disableButton ? 'disabled' : ''
								} `}
								type="submit"
								onClick={handleClick}>
								{verifyButtonLoader ? (
									<ButtonLoader />
								) : (
									<span className="otp-button-style">{OTP_CONSTANTS.OTP_Button}</span>
								)}
							</button>
						</div>
						{isButtonLoader ? (
							<ButtonLoader />
						) : (
							<span
								className={`resendSpan  ${disableResend ? 'disabled' : ''} `}
								onClick={handleResend}>
								{OTP_CONSTANTS.OTP_Resend}
							</span>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};
