import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import DeleteBucketActive from 'src/app/assets/images/DeleteIconActive.svg';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { ROW_TOOLTIP, PROFILE_ICON_COUNT } from 'src/app/pages/ReportedVideos/constant';
import { overlayStyle } from 'src/app/common/constants/AppConstants';
import { profileBackgroundColor } from 'src/app/pages/AllUsers/constants';
import { IMAGE_PLACEHOLDER_HORIZONTAL } from 'src/app/common/constants/AppConstants';
import { getDate, getRowClassName } from 'src/app/common/utils/methods';

export const Row = ({
	data,
	isAllChecked,
	handleCheck,
	handleClick,
	val,
	countOnAllUnselected,
	id,
	openReportedPopup,
	handleChangeOnDelete,
	status,
}) => {
	const [icon, setIcon] = useState(DeleteBucket);
	const [isAvatar, setIsAvatar] = useState([]);

	useEffect(() => {
		const avatarVal = [];
		data?.users.map((item, index) => {
			if (item?.avatar) {
				avatarVal.push(true);
			} else {
				avatarVal.push(false);
			}
		});
		setIsAvatar(avatarVal);
	}, [data]);

	const onMouseEnterOnDeleteButton = () => {
		setIcon(DeleteBucketActive);
	};
	const onMouseLeaveOnDeleteButton = () => {
		setIcon(DeleteBucket);
	};

	const valueOnAllUnchecked = countOnAllUnselected.includes(data?.video_id);
	const findValues = id.includes(data?.video_id);

	const addDefaultSrc = (ev) => {
		let markers = [...isAvatar];
		markers[Number(ev.target.id)] = false;
		setIsAvatar(markers);
		ev.target.className = 'user-icon-error';
	};

	const errorThumbnail = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
	};

	const getProfileBackground = (name) => {
		const nameIndex = (name && name.length > 0 ? name[0].charCodeAt(0) : 1) % 7;
		return profileBackgroundColor[nameIndex];
	};

	const renderUserIcon = (index, data) => {
		return (
			<span className="reporters-avatar" key={data?.users[index]?.avatar}>
				<img src={data?.users[index]?.avatar} onError={addDefaultSrc} />
			</span>
		);
	};

	const renderError = (index, data) => {
		return (
			<div
				className={`reporters-avatar user-icon-error`}
				key={index}
				style={{
					background: `${getProfileBackground(
						`${data?.users[index]?.first_name} ${data?.users[index]?.last_names}`,
					)}`,
				}}>
				<span className="user-name-error-span">{`${
					data?.users[index]?.first_name?.charAt(0)?.toUpperCase() || ''
				}${data?.users[index]?.last_names?.charAt(0)?.toUpperCase() || ''}`}</span>
			</div>
		);
	};

	const renderProfile = (data) => {
		return data?.users?.length < 4 ? (
			data?.users.map((item, index) => {
				return isAvatar[index] ? (
					<span className="reporters-avatar" key={index}>
						<img src={item?.avatar} id={index} onError={addDefaultSrc} />
					</span>
				) : (
					<div
						className={`reporters-avatar user-icon-error`}
						key={index}
						style={{
							background: `${getProfileBackground(
								`${item?.first_name}${item?.last_names}`,
							)}`,
						}}>
						<span className="user-name-error-span">{`${
							item?.first_name?.charAt(0)?.toUpperCase() || ''
						}${item?.last_names?.charAt(0)?.toUpperCase() || ''}`}</span>
					</div>
				);
			})
		) : (
			<div className="more-wrapper">
				{data?.users.slice(0, PROFILE_ICON_COUNT).map((item, index) => {
					if (item?.avatar) {
						return renderUserIcon(index, data);
					} else {
						return renderError(index, data);
					}
				})}
				<span className="remaining-count">{`+${
					data?.users?.length - PROFILE_ICON_COUNT
				}`}</span>
			</div>
		);
	};

	return (
		<tr className={getRowClassName(data?.index)}>
			<td className="row-first">
				{!status && (
					<span className="checkbox">
						{isAllChecked ? (
							findValues ? (
								<input
									type="checkbox"
									id="name-checkbox-row"
									value="all"
									checked={val}
									onChange={handleClick}
								/>
							) : (
								<input
									type="checkbox"
									id="name-checkbox-row"
									value="all"
									checked={isAllChecked}
									onChange={handleClick}
								/>
							)
						) : (
							<input
								type="checkbox"
								id="name-checkbox-row"
								value={data?.name}
								onChange={handleCheck}
								checked={valueOnAllUnchecked}
							/>
						)}

						<div className="title-video-url-wrapper">
							<img
								className="title-poster"
								src={data?.thumbnail}
								alt={data?.title}
								onError={errorThumbnail}
							/>
							<div className="wrapper-title">
								<div className="heading-title">{data?.title}</div>
								<a
									className="url-title"
									href={data?.url}
									target="_blank"
									rel="noopener noreferrer">
									<u>{data?.url}</u>
								</a>
							</div>
						</div>
					</span>
				)}
				{status && (
					<div className="title-video-url-wrapper extra-spacing">
						<img
							className="title-poster"
							src={data?.thumbnail}
							alt={data?.title}
							onError={errorThumbnail}
						/>
						<div className="wrapper-title">
							<div className="heading-title">{data?.title}</div>
							<a
								className="url-title"
								href={data?.url}
								target="_blank"
								rel="noopener noreferrer">
								<u>{data?.url}</u>
							</a>
						</div>
					</div>
				)}
			</td>
			<td className="row-reason">{data?.last_reported_reason}</td>
			<td className="row-reported-time">{getDate(data?.last_reported_at)}</td>
			<td className="row-frequency">{data?.frequency}</td>
			<td className="row-reported-by" onClick={() => openReportedPopup(data?.video_id)}>
				{renderProfile(data)}
			</td>

			{!status && (
				<td className="row-actions">
					<Tooltip
						overlay={ROW_TOOLTIP.delete}
						placement="bottom"
						overlayInnerStyle={overlayStyle}
						align={{
							offset: [-2, -8],
						}}>
						<span
							className="live-channel-action-button"
							onClick={() => handleChangeOnDelete(data?.video_id, data?.title)}
							onMouseEnter={onMouseEnterOnDeleteButton}
							onMouseLeave={onMouseLeaveOnDeleteButton}>
							<img src={icon} className="live-channel-action-button-image" alt="delete" />
						</span>
					</Tooltip>
				</td>
			)}
		</tr>
	);
};

// Row.propTypes = {
// 	data: PropTypes.object.isRequired,
// };
