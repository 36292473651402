import React from 'react';
import './styles.scss';
import loader from 'src/app/assets/images/GradientLoader.gif';

export const Loader = ({ textHidden, loadMore = false }) => {
	return (
		<div className={`loader ${loadMore ? 'extra-loader-padding' : ''}`}>
			<img className="loader-image-style" src={loader} alt="loader" />
			{!textHidden && (
				<div className="loading-style-outerDiv">
					<h5 className="loading-style"> Loading...</h5>
				</div>
			)}
		</div>
	);
};
