import React from 'react';
import AddButtonImage from 'src/app/assets/images/add-icon.png';
import './styles.scss';

const AddButton = ({ name, onClick }) => {
	return (
		<div className="add-button" onClick={onClick} role="button">
			<img src={AddButtonImage} alt="add button" />
			<h3 className="add-screen">{name}</h3>
		</div>
	);
};

export default AddButton;
