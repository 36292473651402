import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import './styles.scss';
import { DashboardWrapper } from '../Dashboard/Components/DashboardWrapper';
import { isValidSession } from 'src/app/common/utils/sessionUtils';

/**
 * this is a HOC which will check if the requested route is required authentication or not.
 * For eg: We can view dashbaord only after logged in into the site. so, here on hitting route '/dashboard' it will redirect us to '/'
 * i.e. home page first to login and then it will redirect us to that page.
 */
export const PrivateRoute = ({
	component,
	redirectTo = false,
	redirectToShows = false,
}) => {
	const ComponentWithRouterProps = withRouter(component);
	return (
		<React.Fragment>
			{/* If user is logged-in and valid session, then redirect to dashboard
			else, redirect to login screen */}
			{isValidSession() ? (
				/**
				 * This is our admin dashboard where Header and SideBar will remain same for the whole dashbaord, only the content in between will be changed acc to the tab selected.
				 * This is placed here on the top of main component i.e dashboard so that it won't get rendered agaian and again when we'll select the different tab and route for that will get changed accordingly.
				 */
				<DashboardWrapper>
					{redirectTo === true ? <Redirect to="/movies/trending" /> : null}
					{redirectToShows === true ? <Redirect to="/shows/trending" /> : null}
					<ComponentWithRouterProps />
				</DashboardWrapper>
			) : (
				<Redirect to="/" />
			)}
		</React.Fragment>
	);
};
