import { Auth } from 'aws-amplify';
import { getItemFromLocalStorage } from './localStorageUtils';

export const getJwtToken = async () => {
	try {
		return (await Auth.currentSession()).getIdToken().getJwtToken();
	} catch (error) {}
};

// get cognito user id
export const getCognitoUserPoolId = async () => {
	try {
		return (await Auth.currentAuthenticatedUser()).username;
	} catch (error) {}
};

// this function is checking if the existing session is valid or expired.
export const isValidSession = () => {
	try {
		const user = getItemFromLocalStorage(
			`CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEBCLIENT_ID}.LastAuthUser`,
		);
		return user;
	} catch (error) {
		console.log('error', error);
	}
};
