import AxiosBuilder from '../../common/services/AxiosBuilder';
import { getJwtToken } from 'src/app/common/utils/sessionUtils';

/**
 * This api is used to get all the reservations data
 */
export const getReservationsService = async (apiEndpoint, data) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				status: data && data.status,
				sortOn: data && data.sortOn ? data.sortOn : '',
				propertyId: data.propertyId,
				fromToDate: data && data.fromToDate ? data.fromToDate : '',
				limit: data.limit,
				offset: data.offset,
				timezoneRegion: data.timezoneRegion,
			}),
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const sendKeyService = async (apiEndpoint, data) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				reservationId: data.reservationId,
				propertyId: data.propertyId,
			}),
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/** send sms service */
export const sendSmsService = async (apiEndpoint, apiBody) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify(apiBody),
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const expireKeyService = async (apiEndpoint, params) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams({ reservationId: params.reservationId })
			.GET()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getData = async (apiEndpoint, params) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams({
				...params,
			})
			.GET()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getCommonMethod = async (apiEndpoint, params) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams({
				...params,
			})
			.GET()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const postCommonMethod = async (apiEndpoint, apiBody) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify(apiBody),
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getTableData = async (apiEndpoint, params) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams({
				...params,
			})
			.GET()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const putCommonMethod = async (apiEndpoint, _data) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify(_data),
		})
			.withAuth(token)
			.PUT()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export const postData = async (apiEndpoint, _data) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				..._data,
			}),
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const deleteData = async (apiEndpoint, body) => {
	const token = await getJwtToken();
	return new Promise((resolve, reject) => {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				...body,
			}),
		})
			.withAuth(token)
			.DELETE()
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => reject(error));
	});
};

export const putDigestVideos = async (apiEndpoint, data) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: data,
		})
			.withAuth(token)
			.PUT()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getExternalData = async (apiEndpoint, params) => {
	// const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withParams({
				...params,
			})
			.GET()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getUserInfo = async (apiEndpoint, params) => {
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withParams({
				...params,
			})
			.withAuth('null')
			.GET()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getYouTubeData = async (apiEndpoint, params, videoDetails) => {
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withParams({
				...params,
			})
			.withExternalYTHeader(
				videoDetails
					? process.env.REACT_APP_YT_HEADER
					: process.env.REACT_APP_YT_HEADER_HOST,
				process.env.REACT_APP_RAPID_API_KEY || '',
			)
			.GET()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
