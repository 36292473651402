import * as Actions from './actionTypes';
import { RESERVATIONS_INITIAL_STATE } from './initialState';

// returns array of unqie objects
const filteredArray = (arr, keyName = 'screen_bucket_id') => {
	return arr.filter(
		(ele, ind) =>
			ind ===
			arr.findIndex(
				(elem) => elem[keyName] === ele[keyName] && elem[keyName] === ele[keyName],
			),
	);
};

const updateList = (
	data,
	screenName,
	type,
	isPublishTab,
	bucketId,
	updatedBucketData,
	newData,
) => {
	const index = data.findIndex(
		(item) => item && item.bucket_id === bucketId && item.screen_name === screenName,
	);
	if (isPublishTab) {
		if (type === 'no operation' && updatedBucketData && index !== -1) {
			data[index] =
				newData &&
				newData.data &&
				newData.data.filter(
					(item) => item.bucket_id === bucketId && item.screen_name === screenName,
				)[0];
		} else if (type === 'no operation' && index === -1) {
			data.unshift(
				newData.data.filter(
					(item) => item.bucket_id === bucketId && item.screen_name === screenName,
				)[0],
			);
		} else if (type === 'add' && index === -1 && updatedBucketData) {
			const {
				bucket_rank,
				created_at,
				from_date,
				name,
				screen_bucket_id,
				to_date,
				updated_at,
				videoCount,
			} = updatedBucketData;
			data.push({
				bucket_id: bucketId,
				bucket_rank,
				bucket_status: 'published',
				created_at,
				from_date,
				name,
				screen_bucket_id: `${screen_bucket_id}-new`,
				screen_id: screenName === 'Home' ? 1 : 2,
				screen_name: screenName,
				to_date,
				updated_at,
				videoCount,
			});
		} else if (type === 'remove' && index >= 0) {
			data.splice(index, 1);
		}
	} else {
		if (type === 'no operation' && updatedBucketData && index !== -1) {
			data[index] =
				newData &&
				newData.data &&
				newData.data.filter(
					(item) => item.bucket_id === bucketId && item.screen_name === screenName,
				)[0];
		} else if (type === 'no operation' && index === -1) {
			const evaluatedValue = newData.data.filter(
				(item) => item.bucket_id === bucketId && item.screen_name === screenName,
			)[0];
			if (typeof evaluatedValue !== 'undefined') {
				data.unshift(
					newData &&
						newData.data &&
						newData.data.filter(
							(item) => item.bucket_id === bucketId && item.screen_name === screenName,
						)[0],
				);
			}
		} else if (type === 'add' && index !== -1) {
			data.splice(index, 1);
		} else if (type === 'remove' && updatedBucketData) {
			const {
				bucket_rank,
				created_at,
				from_date,
				name,
				screen_bucket_id,
				to_date,
				updated_at,
				videoCount,
			} = updatedBucketData;

			//I don't know why Satyam/Vinayak did this but this is not working right.
			// Let the commented code to be there in order to know it's impact in
			// future as I couldn't found any use case as of now.

			// data.push({
			// 	bucket_id: bucketId,
			// 	bucket_rank,
			// 	bucket_status: 'published',
			// 	created_at,
			// 	from_date,
			// 	name,
			// 	screen_bucket_id,
			// 	screen_id: screenName === 'Home' ? 1 : 2,
			// 	screen_name: screenName,
			// 	to_date,
			// 	updated_at,
			// 	videoCount,
			// });
		} else if (type === 'remove' && index >= 0) {
			data.splice(index, 1);
		}
	}

	return [...data.filter(Boolean)];
};

export function reservationsReducer(state = RESERVATIONS_INITIAL_STATE, action) {
	switch (action.type) {
		case Actions.SAVE_RESERVATIONS:
			return {
				...state,
				data: action.payload,
			};
		case Actions.GET_CAROUSEL_METADATA:
			const getCarousel = action.payload;
			return {
				...state,
				carousel: { ...state.carousel, metaData: getCarousel.data },
			};
		case Actions.CAROUSEL_DATA:
			const carouselData = action.payload;
			return {
				...state,
				carousel: { ...state.carousel, data: carouselData.data },
			};
		case Actions.SET_SELECTED_BUCKET:
			return {
				...state,
				selectedBucket: action.payload.status,
			};
		case Actions.GET_ALL_BUCKETS:
			const dashboardBucketArray = [...state.allBuckets, ...action.payload.data['data']];
			return {
				...state,
				allBuckets: filteredArray(dashboardBucketArray, 'bucket_id'),
				totalBucket: action.payload.data.length,
			};
		case Actions.REPLACE_ALL_BUCKETS:
			const dashboardBucketArrayReplace = [...action.payload?.data?.data];
			return {
				...state,
				allBuckets: filteredArray(dashboardBucketArrayReplace, 'bucket_id'),
				totalBucket: action.payload.data.length,
			};
		case Actions.ADD_VIDEO_TO_BUCKET:
			return {
				...state,
			};
		case Actions.GET_TABLE_DATA:
			const valueLoadMoreBucket = action.payload.params?.status;
			const filtered = [...state.tableData, ...action.payload.res?.data['data']];
			if (valueLoadMoreBucket === 'published') {
				return {
					...state,
					tableData: filtered, // We have done this in order to avoid duplicate data in an array.
					totalBucket: action.payload.res?.data.length,
					bucketTable: {
						...state.bucketTable,
						published: [
							...state.bucketTable.published,
							...action.payload.res?.data['data'],
						],
					},
					totalBucketCount: {
						...state.totalBucketCount,
						published: action.payload.res?.data.length,
					},
				};
			} else if (valueLoadMoreBucket === 'unpublished') {
				return {
					...state,
					tableData: filtered, // We have done this in order to avoid duplicate data in an array.
					totalBucket: action.payload.res?.data.length,
					bucketTable: {
						...state.bucketTable,
						unpublished: [
							...state.bucketTable.unpublished,
							...action.payload.res?.data['data'],
						],
					},
					totalBucketCount: {
						...state.totalBucketCount,
						unpublished: action.payload.res?.data.length,
					},
				};
			} else if (valueLoadMoreBucket === 'archived') {
				return {
					...state,
					tableData: filtered, // We have done this in order to avoid duplicate data in an array.
					totalBucket: action.payload.res?.data.length,
					bucketTable: {
						...state.bucketTable,
						archived: [
							...state.bucketTable.archived,
							...action.payload.res?.data['data'],
						],
					},
					totalBucketCount: {
						...state.totalBucketCount,
						archived: action.payload.res?.data.length,
					},
				};
			} else {
				return {
					...state,
					tableData: filtered, // We have done this in order to avoid duplicate data in an array.
					totalBucket: action.payload.res?.data.length,
					bucketTable: {
						...state.bucketTable,
						draft: [...state.bucketTable.draft, ...action.payload.res?.data['data']],
					},
					totalBucketCount: {
						...state.totalBucketCount,
						draft: action.payload.res?.data.length,
					},
				};
			}
		case Actions.EDIT_BUCKET_TABLE_ON_PUBLISH_OPERTAION:
			const convertToPublishArray = [action.payload?.data];
			return {
				...state,
				bucketTable: {
					...state.bucketTable,
					published: [...convertToPublishArray, ...state.bucketTable.published],
				},
			};
		case Actions.EDIT_BUCKET_TABLE_ON_UNPUBLISH_OPERTAION:
			const convertToUnpublishArray = [action.payload?.data];
			return {
				...state,
				bucketTable: {
					...state.bucketTable,
					unpublished: [...convertToUnpublishArray, ...state.bucketTable.unpublished],
				},
			};
		case Actions.GET_TABLE_DATA_ON_OPERATION:
			// We are doing so coz we are facing issue of duplicacy only getting length won't help us.
			const editStatus = action.payload.params?.status;
			const filteredUpdatedData = filteredArray([
				...state.tableData,
				...action.payload.res?.data['data'],
			]);
			let newfilteredUpdatedData = [];
			if (editStatus === 'published') {
				newfilteredUpdatedData = filteredArray([
					...state.bucketTable.published,
					...action.payload.res?.data['data'],
				]);
				return {
					...state,
					tableData: filteredUpdatedData,
					totalBucket: action.payload.res?.data['length'],
					bucketTable: { ...state.bucketTable, published: newfilteredUpdatedData },
					totalBucketCount: {
						...state.totalBucketCount,
						published: action.payload.res?.data['length'],
					},
				};
			} else if (editStatus === 'unpublished') {
				newfilteredUpdatedData = filteredArray([
					...state.bucketTable.unpublished,
					...action.payload.res?.data['data'],
				]);
				return {
					...state,
					tableData: filteredUpdatedData,
					totalBucket: action.payload.res?.data['length'],
					bucketTable: { ...state.bucketTable, unpublished: newfilteredUpdatedData },
					totalBucketCount: {
						...state.totalBucketCount,
						unpublished: action.payload.res?.data['length'],
					},
				};
			} else if (editStatus === 'archived') {
				newfilteredUpdatedData = filteredArray([
					...state.bucketTable.archived,
					...action.payload.res?.data['data'],
				]);
				return {
					...state,
					bucketTable: { ...state.bucketTable, archived: newfilteredUpdatedData },
					totalBucketCount: {
						...state.totalBucketCount,
						archived: action.payload.res?.data['length'],
					},
					tableData: filteredUpdatedData,
					totalBucket: action.payload.res?.data['length'],
				};
			} else {
				newfilteredUpdatedData = filteredArray([
					...state.bucketTable.draft,
					...action.payload.res?.data['data'],
				]);
				return {
					...state,
					tableData: filteredUpdatedData,
					totalBucket: action.payload.res?.data['length'],
					bucketTable: { ...state.bucketTable, draft: newfilteredUpdatedData },
					totalBucketCount: {
						...state.totalBucketCount,
						draft: action.payload.res?.data['length'],
					},
				};
			}

		case Actions.GET_TABLE_DATA_ON_TAB_CHANGE:
			const valueOfBucketTable = action.payload.params?.status;
			if (valueOfBucketTable === 'published') {
				return {
					...state,
					tableData: [...action.payload.res?.data['data']],
					totalBucket: action.payload.res?.data['length'],
					bucketTable: {
						...state.bucketTable,
						published: [...action.payload.res?.data['data']],
					},
					totalBucketCount: {
						...state.totalBucketCount,
						published: action.payload.res?.data['length'],
					},
				};
			} else if (valueOfBucketTable === 'unpublished') {
				return {
					...state,
					tableData: [...action.payload.res?.data['data']],
					totalBucket: action.payload.res?.data['length'],
					bucketTable: {
						...state.bucketTable,
						unpublished: [...action.payload.res?.data['data']],
					},
					totalBucketCount: {
						...state.totalBucketCount,
						unpublished: action.payload.res?.data['length'],
					},
				};
			} else if (valueOfBucketTable === 'archived') {
				return {
					...state,
					tableData: [...action.payload.res?.data['data']],
					totalBucket: action.payload.res?.data['length'],
					bucketTable: {
						...state.bucketTable,
						archived: [...action.payload.res?.data['data']],
					},
					totalBucketCount: {
						...state.totalBucketCount,
						archived: action.payload.res?.data['length'],
					},
				};
			} else {
				return {
					...state,
					tableData: [...action.payload.res?.data['data']],
					totalBucket: action.payload.res?.data['length'],
					bucketTable: {
						...state.bucketTable,
						draft: [...action.payload.res?.data['data']],
					},
					totalBucketCount: {
						...state.totalBucketCount,
						draft: action.payload.res?.data['length'],
					},
				};
			}
		case Actions.GET_TABLE_DATA_ON_SCREEN_CHANGE:
			const valueOfBucketTableOnScreenChange = action.payload.params?.status;
			if (valueOfBucketTableOnScreenChange === 'published') {
				return {
					...state,
					tableData: action.payload.res?.data.data,
					totalBucket: action.payload.res?.data.length,
					bucketTable: {
						...state.bucketTable,
						published: action.payload.res?.data.data,
					},
					totalBucketCount: {
						...state.totalBucketCount,
						published: action.payload.res?.data.length,
					},
				};
			} else if (valueOfBucketTableOnScreenChange === 'unpublished') {
				return {
					...state,
					tableData: action.payload.res?.data.data,
					totalBucket: action.payload.res?.data['length'],
					bucketTable: {
						...state.bucketTable,
						unpublished: action.payload.res?.data.data,
					},
					totalBucketCount: {
						...state.totalBucketCount,
						unpublished: action.payload.res?.data.length,
					},
				};
			} else if (valueOfBucketTableOnScreenChange === 'archived') {
				return {
					...state,
					tableData: action.payload.res?.data.data,
					totalBucket: action.payload.res?.data['length'],
					bucketTable: {
						...state.bucketTable,
						archived: action.payload.res?.data.data,
					},
					totalBucketCount: {
						...state.totalBucketCount,
						archived: action.payload.res?.data.length,
					},
				};
			} else {
				return {
					...state,
					tableData: action.payload.res?.data.data,
					totalBucket: action.payload.res?.data['length'],
					bucketTable: {
						...state.bucketTable,
						draft: action.payload.res?.data.data,
					},
					totalBucketCount: {
						...state.totalBucketCount,
						draft: action.payload.res?.data.length,
					},
				};
			}
		case Actions.SET_PRODUCTS:
			const products = action.payload;
			return {
				...state,
				data: products,
			};
		case Actions.GET_SCREENS:
			return {
				...state,
				screens: action.payload,
			};
		case Actions.SET_RESERVATIONS_COUNT:
			const updatedState = JSON.parse(JSON.stringify(state));
			const { reservationsCount } = updatedState;
			reservationsCount[action.payload.status] = action.payload.count;
			return {
				...updatedState,
				reservationsCount,
			};

		case Actions.SET_PAGE_OFFSET:
			const updatedGlobalState = JSON.parse(JSON.stringify(state));
			const { reservationsPageNumber } = updatedGlobalState;
			reservationsPageNumber[action.payload.tab] = action.payload.pageNumber;
			return {
				...updatedGlobalState,
				reservationsPageNumber,
			};

		case Actions.SET_RESERVATION_STATUS:
			return {
				...state,
				reservationStatus: action.payload.status,
			};
		case Actions.SET_PRESSED_HEADER_TAB:
			return { ...state, pressedHeaderTab: action.payload };
		case Actions.SET_WS_MESSAGE_RECEIVED_STATUS:
			return {
				...state,
				isWsMessageReceived: action.payload.status,
			};
		case Actions.SET_PROPERTY:
			return { ...state, selectedProperty: action.payload };
		case Actions.GET_SUBSECTION_DATA:
			const data = [...state.subSectionData.data, ...action.payload.data];
			const arrayUniqueByKey = [
				...new Map(data.map((item) => [item['video_id'], item])).values(),
			];
			return {
				...state,
				subSectionData: {
					...state.subSectionData,
					data: arrayUniqueByKey,
					length: action.payload.data?.length,
				},
			};
		case Actions.RESET_SUBSECTION_DATA:
			return {
				...state,
				subSectionData: {
					data: [...action.payload.data],
				},
			};
		case Actions.GET_CARD_SIZES:
			return {
				...state,
				cardSizes: action.payload,
			};
		case Actions.GET_CATEGORIES:
			return {
				...state,
				categories: action.payload,
			};
		default:
			return state;
		case Actions.GET_PUBLISHED_DATA:
			return {
				...state,
				publishedData: [...state.publishedData, ...action.payload['data']],
				totalBucket: action.payload?.length,
			};
		case Actions.GET_PUBLISHED_DATA_ON_OPERATION:
			// We are doing so coz we are facing issue of duplicacy only getting length won't help us.
			const filteredUpdatedPublishedData = filteredArray([
				...state.publishedData,
				...action.payload['data'],
			]);
			return {
				...state,
				publishedData: filteredUpdatedPublishedData,
				totalBucket: action.payload['length'],
			};

		case Actions.GET_PUBLISHED_DATA_ON_SCREEN_CHANGE:
			return {
				...state,
				publishedData: action.payload.data,
				totalBucket: action.payload.length,
			};
		case Actions.GET_PUBLISHED_DATA_ON_ORDER_CHANGE:
			return {
				...state,
				publishedData: action.payload,
			};
		case Actions.EDIT_DATA_IN_TABLE:
			let copy = [...state.tableData];
			const { status } = action.payload;
			let newCopy = [];
			if (status === 'published') {
				newCopy = [...state.bucketTable.published];
			} else if (status === 'unpublished') {
				newCopy = [...state.bucketTable.unpublished];
			} else if (status === 'draft') {
				newCopy = [...state.bucketTable.draft];
			} else if (status === 'archived') {
				newCopy = [...state.bucketTable.archived];
			}
			const { updatedBucketData, isPublishTab, newData } = action.payload;
			const { bucketId, type: homeType } = action.payload.home;
			const { type: discoverType, bucketId: discoverBucketId } = action.payload.discover;

			const tempVar = updateList(
				newCopy.filter(Boolean),
				'Home',
				homeType,
				isPublishTab,
				bucketId,
				updatedBucketData,
				newData,
			);
			newCopy = tempVar.filter(Boolean);
			newCopy = updateList(
				newCopy.filter(Boolean),
				'Discover',
				discoverType,
				isPublishTab,
				discoverBucketId,
				updatedBucketData,
				newData,
			);
			newCopy = updateList(
				newCopy.filter(Boolean),
				'Live TV',
				homeType,
				isPublishTab,
				bucketId,
				updatedBucketData,
				newData,
			);

			if (status === 'published') {
				return {
					...state,
					totalBucket: newData?.length,
					bucketTable: { ...state.bucketTable, published: newCopy },
					totalBucketCount: { ...state.totalBucketCount, published: newData?.length },
				};
			} else if (status === 'unpublished') {
				return {
					...state,
					totalBucket: newData?.length,
					bucketTable: { ...state.bucketTable, unpublished: newCopy },
					totalBucketCount: { ...state.totalBucketCount, unpublished: newData?.length },
				};
			} else if (status === 'archived') {
				return {
					...state,
					totalBucket: newData?.length,
					bucketTable: { ...state.bucketTable, archived: newCopy },
					totalBucketCount: { ...state.totalBucketCount, archived: newData?.length },
				};
			} else {
				return {
					...state,
					totalBucket: newData?.length,
					bucketTable: { ...state.bucketTable, draft: newCopy },
					totalBucketCount: { ...state.totalBucketCount, draft: newData?.length },
				};
			}
		case Actions.EDIT_DATA_IN_PUBLISHED_TABLE:
			let copyOfData = [...state.publishedData];
			const {
				updatedBucketData: updatedData,
				isPublishTab: isPublished,
				newData: publishedNewData,
				selectedScreen,
			} = action.payload;
			const { bucketId: bucketid, type: home } = action.payload.home;
			const { type: discover } = action.payload.discover;
			if (selectedScreen === 'Home') {
				copyOfData = updateList(
					copyOfData,
					'Home',
					home,
					isPublished,
					bucketid,
					updatedData,
				);
			} else if (selectedScreen === 'Discover') {
				copyOfData = updateList(
					copyOfData,
					'Discover',
					discover,
					isPublished,
					bucketid,
					updatedData,
				);
			}
			return {
				...state,
				publishedData: copyOfData,
				publishedNewDataLength: publishedNewData.data.length,
				totalBucket: publishedNewData.length,
			};
		case Actions.GET_WAITLIST_USER_DATA:
			return {
				...state,
				waitlistData: [...state.waitlistData, ...action.payload?.data],
				waitlistTotalUsers: action.payload?.length,
			};
		case Actions.GET_WAITLIST_USER_DATA_INITIAL:
			return {
				...state,
				waitlistData: action.payload?.data,
				waitlistTotalUsers: action.payload?.length,
			};
		case Actions.EDIT_DATA_ON_WAITLIST_TABLE:
			const index = state.waitlistData.findIndex(
				(item) => item.user_waitlist_id === action?.payload.userWaitlistId,
			);
			state.waitlistData.splice(index, 1);
			let copyOfWaitlistData = [...state.waitlistData];
			return {
				...state,
				waitlistData: copyOfWaitlistData,
				waitlistTotalUsers: copyOfWaitlistData.length,
			};
		case Actions.EDIT_DATA_ON_WAITLIST_TABLE_ON_SELECTION:
			action?.payload.res.map((val) => {
				const index = state.waitlistData.findIndex(
					(item) => val?.waitlistId === item?.user_waitlist_id,
				);
				state.waitlistData.splice(index, 1);
			});
			let newWaitlistData = [...state.waitlistData];
			return {
				...state,
				waitlistData: newWaitlistData,
				waitlistTotalUsers: newWaitlistData.length,
			};
		case Actions.GET_FILTERS: {
			const { genres, providers } = action.payload;
			return {
				...state,
				filters: {
					genres: genres,
					services: providers,
				},
			};
		}
		case Actions.DELETE_DATA_FROM_TABLE: {
			let copy = [...state.tableData];
			const { status } = action.payload;
			let newCopy = [];
			if (status === 'published') {
				newCopy = [...state.bucketTable.published];
			} else if (status === 'unpublished') {
				newCopy = [...state.bucketTable.unpublished];
			} else if (status === 'draft') {
				newCopy = [...state.bucketTable.draft];
			} else if (status === 'archived') {
				newCopy = [...state.bucketTable.archived];
			}
			const { updatedBucketData, isPublishTab } = action.payload;
			const { bucketId, type: homeType } = action.payload.home;
			const { type: discoverType } = action.payload.discover;
			newCopy = updateList(
				newCopy,
				'Home',
				homeType,
				isPublishTab,
				bucketId,
				updatedBucketData,
			);
			newCopy = updateList(
				newCopy,
				'Discover',
				discoverType,
				isPublishTab,
				bucketId,
				updatedBucketData,
			);
			newCopy = updateList(
				newCopy,
				'Live TV',
				homeType,
				isPublishTab,
				bucketId,
				updatedBucketData,
			);
			if (status === 'published') {
				return {
					...state,
					tableData: copy,
					newDataLength: 20,
					bucketTable: { ...state.bucketTable, published: newCopy },
				};
			} else if (status === 'unpublished') {
				return {
					...state,
					tableData: copy,
					newDataLength: 20,
					bucketTable: { ...state.bucketTable, unpublished: newCopy },
				};
			} else if (status === 'archived') {
				return {
					...state,
					tableData: copy,
					newDataLength: 20,
					bucketTable: { ...state.bucketTable, archived: newCopy },
				};
			} else {
				return {
					...state,
					tableData: copy,
					newDataLength: 20,
					bucketTable: { ...state.bucketTable, draft: newCopy },
				};
			}
		}
		case Actions.GET_BUCKET_DETAILS:
			return {
				...state,
				bucketDetails: action.payload,
			};

		case Actions.SET_BUCKET_DETAILS:
			return {
				...state,
				bucketDetails: action.payload,
			};
		case Actions.SET_DIGEST_DATA:
			return {
				...state,
				digestContentData: action.payload?.content,
			};
		case Actions.UPDATE_BUCKET_VIDEO_DATA:
			return {
				...state,
				subSectionData: {
					data: action.payload,
					length: action.payload?.length,
				},
			};
		case Actions.GET_PARENT_BUCKET_DATA:
			return {
				...state,
				parentBucket: action.payload,
			};
		case Actions.GET_LIST_FOR_YOU:
			return {
				...state,
				listForYou: [...state.listForYou, ...action.payload],
			};
		case Actions.GET_LIST_FOR_YOU_INITIAL:
			return {
				...state,
				listForYou: action.payload,
			};
		case Actions.REMOVE_DATA_LIST_FOR_YOU:
			const copyOfListForYou = [...state.listForYou];
			const indexOfListOfYou = copyOfListForYou.findIndex(
				(item) => item.bucket_id === action.payload,
			);
			copyOfListForYou.splice(indexOfListOfYou, 1);
			return {
				...state,
				listForYou: copyOfListForYou,
			};
	}
}
