import React from "react";
import "./styles.scss";
import CustomDateRangePicker from "../CustomDateRangePicker";
import { getCurrentDate } from "../../../common/utils/dateUtils";
import useOutsideAlerter from "../../../customHooks/ClickAway";

const CustomDateRangePopup = ({
  id,
  defaultValue,
  label,
  placeholder,
  handleValueChanges,
  toggleCalendar,
}) => {
  const [isVisible, setVisible] = React.useState(false);
  const [date, setDate] = React.useState(null);
  const dateWrapperRef = React.useRef(null);

  useOutsideAlerter(dateWrapperRef, () => {
    setVisible(false);
    const buttons = document.querySelector(".action-button");
    if (buttons) {
      buttons.style.opacity = 1;
      buttons.style.pointerEvents = "all";
      buttons.style.cursor = "cursor";
    }
  });

  const retrieveDate = (date) => {
    setDate(date);
    handleValueChanges(id, date);
  };

  React.useEffect(() => {
    defaultValue ? setDate(defaultValue) : setDate(null);
  }, [defaultValue]);

  return (
    <div className={"custom-date-range-wrapper"}>
      <p className={"placeholder"}>{label}</p>
      <div ref={dateWrapperRef} className="date-wrapper from-date">
        <span
          className={"date-placeholder"}
          onClick={(e) => {
            setVisible(true);
            toggleCalendar(true, e);
          }}
        >
          <div>
            <p className={date?.startDate ? "color-white" : null}>
              {date?.startDate
                ? `${getCurrentDate(date.startDate)} - ${getCurrentDate(
                    date.endDate
                  )}`
                : placeholder}
            </p>
          </div>
        </span>
        <CustomDateRangePicker
          defaultValue={date}
          isVisible={isVisible}
          handleDateChange={retrieveDate}
          handleVisibiltyChange={(flag) => setVisible(flag)}
        />
      </div>
    </div>
  );
};

export default CustomDateRangePopup;
