import {
	ROOT_URL,
	METADATA_URL,
	BUCKET_DETAIL_URL,
} from '../../../../common/constants/AppConstants';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCarouselMetadata, fetchDataFromGetApi } from '../../actionCreators';
import BucketDetails from '../BucketDetails';
import { useLocation } from 'react-router-dom';
import { BUCKET_NAME_MAPPER } from './constant';
import { Loader } from 'src/app/components/Loader';

const Section = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const [contentType, setContentType] = useState(null);
	const [values, setValues] = useState(null);
	let { section } = useParams();
	const [offset, setOffset] = useState(0);
	const carouselMetaData = useSelector((state) => state.reservations.carousel?.metaData);
	const [metaDataToBeUsed, setMetaDataToBeUsed] = useState(null);
	const [onLoad, setOnLoad] = useState('section');
	const [bucketTags, setBucketTags] = useState(null);
	const [bucketDetails, setBucketDetails] = useState(null);

	const getValueFromURL = (pathName) => {
		const firstRange = pathName.indexOf('/');
		const secondRange = pathName.indexOf('/', firstRange + 1);
		setContentType(pathName.slice(firstRange + 1, secondRange - 1));
		return (
			pathName.slice(secondRange + 1) + ' ' + pathName.slice(firstRange + 1, secondRange)
		);
	};

	const fetchTags = async (bucket_id) => {
		fetchDataFromGetApi(
			`${ROOT_URL}${BUCKET_DETAIL_URL}`,
			{
				bucket_id: bucket_id,
			},
			({ data }) => {
				setBucketDetails(data);
				if (data?.tags) {
					setBucketTags(data?.tags);
				} else {
					setBucketTags([]);
				}
			},
		);
	};

	useEffect(() => {
		if (!values) {
			const val = getValueFromURL(location.pathname);
			setValues(BUCKET_NAME_MAPPER[val]);
		}
	}, []);

	useEffect(() => {
		if (!carouselMetaData) {
			const apiUrl = `${ROOT_URL}${METADATA_URL}`;
			setOnLoad('section');
			setOffset(0);
			dispatch(getCarouselMetadata(apiUrl));
		}
	}, [dispatch, carouselMetaData, setOnLoad]);

	useEffect(() => {
		if (carouselMetaData && values) {
			const [metaData] = carouselMetaData.filter(
				(data) => values?.toLowerCase() === data.slug.toLowerCase(),
			);
			fetchTags(metaData?.bucket_id);
			setOnLoad('section');
			setOffset(0);
			setMetaDataToBeUsed(metaData);
		}
	}, [carouselMetaData, setMetaDataToBeUsed, values, setOnLoad]);

	useEffect(() => {
		// As of now we get undefined in top-rated and new so in order to avoid infinite loading we pass this object.
		if (typeof metaDataToBeUsed === 'undefined') {
			setMetaDataToBeUsed({ name: '', bucket_id: null });
		}
	}, [metaDataToBeUsed]);

	const updateResults = () => {
		dispatch(getCarouselMetadata(`${ROOT_URL}${METADATA_URL}`));
	};

	if (metaDataToBeUsed && bucketTags) {
		return (
			<BucketDetails
				metaData={metaDataToBeUsed}
				onLoad={onLoad}
				setOnLoad={setOnLoad}
				offset={offset}
				setOffset={setOffset}
				contentType={contentType}
				updateResults={updateResults}
				bucketTags={bucketTags}
				bucketDetails={bucketDetails}
			/>
		);
	} else {
		return <Loader />;
	}
};

export default Section;
