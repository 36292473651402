import React, { useState, useEffect } from 'react';
import IconTextButton from '../../components/atoms/IconTextButton';
import PopupModal from '../../components/molecules/PopupModal';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import CustomSelectBucket from '../../components/atoms/CustomSelectBucket';
import TableContainer from './Components/TableContainer';
import published, { deleteConfirmationModalStyle } from './constant';
import { popupContentStyle } from 'src/app/common/constants/PopupStyles';
import './styles.scss';
import { EditBucketModal } from '../../components/Modals/EditBucketModal';
import { Toast } from '../../components/Toast';
import { RECORD_TO_FETCH } from '../../common/constants/BucketListingConstant';
import {
	PUBLISHED_BUCKETS_URL,
	ROOT_URL,
	BUCKET_URL,
	UPDATE_STATUS_BY_SCREEN,
} from '../../common/constants/AppConstants';
import TOAST_ERROR_MESSAGE from '../../common/constants/ToastMessages';
import { POPUP } from '../../common/constants/PopupMessages';
import {
	editTableData,
	deleteBucket,
	updateBucketDetails,
	getPublishedTableData,
	getPublishedTableDataOnOperation,
} from '../Dashboard/actionCreators';
import DeleteConfirmation from '../../components/molecules/DeleteConfirmation';
import { EDIT_DATA_IN_PUBLISHED_TABLE } from '../Dashboard/actionTypes';
import ScrollToTop from 'src/app/components/atoms/ScrollToTop';
import CreateBucket from 'src/app/components/CreateBucket';

export const PublishedBucket = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const tableData = useSelector((state) => state.reservations?.publishedData);
	const screensSelector = useSelector((state) => state.reservations.screens);
	const [page, setPage] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedScreenDetails, setSelectedScreenDetails] = useState({
		screen_id: 1,
		name: 'Home',
	});
	const [shuffleButtonStatus, setShuffleButtonStatus] = useState('disabled');
	const [screenChange, setScreenChange] = useState('screenId');
	const [isCalendarVisible, setIsCalendarVisible] = useState(false);
	const [isThumbnailPickerVisible, setIsThumbnailPickerVisible] = useState(false);
	const [bucketListUpdateFlag, setBucketListUpdateFlag] = useState(false);
	const [bucketId, setBucketId] = useState(null);
	const [screenBucketId, setScreenBucketId] = useState(null);
	const [openEditBucketModal, setOpenEditBucketModal] = useState();
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		isError: false,
	});
	const [noAutoScroll, setNoAutoScroll] = useState(false);
	const [isOpenDeleteComfirmation, setIsOpenDeleteComfirmation] = useState(false);
	const [onDeleteLoading, setOnDeleteLoading] = useState(false);
	const [isUpdatedData, setUpdatedData] = useState(false);
	const [onStatusChangeLoading, setOnStatusChangeLoading] = useState(false);
	const [isOpenChangeStatus, setIsOpenChangeStatus] = useState(false);
	const [isBucketNameDisable, setBucketNameDisable] = useState(false);

	const handleChange = (value) => {
		setScreenChange('screenId');
		setPage(1);
		setSelectedScreenDetails(value);
	};

	const toggleModal = () => {
		setIsOpen(!isOpen);
	};

	const handleToastDismiss = () => {
		setShowToast({ show: false, message: '' });
	};

	const closeEditModal = (val) => {
		setOpenEditBucketModal(val);
	};

	const openEditModal = (id, bucketid, is_predefined, is_user_type) => {
		setBucketNameDisable(false);
		if (is_user_type) {
			if (is_user_type > 0) {
				setBucketNameDisable(true);
			}
		} else if (is_predefined) {
			if (is_predefined > 0) {
				setBucketNameDisable(true);
			}
		}

		setBucketId(id);
		setScreenBucketId(bucketid);
		setOpenEditBucketModal(true);
		setNoAutoScroll(true);
	};

	const openDeleteModal = (id, bucketid) => {
		setBucketId(id);
		setScreenBucketId(bucketid);
		setIsOpenDeleteComfirmation(true);
	};

	const openChangeStatusModal = (id) => {
		setBucketId(id);
		setIsOpenChangeStatus(true);
	};

	const closeDeleteConfirmationModal = () => {
		setIsOpenDeleteComfirmation(false);
		setIsOpenChangeStatus(false);
	};

	const openToast = (message, isError, show) => {
		setShowToast({ show: show, message: message, isError: isError });
	};

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#infinite-table-container');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const editTable = (form, homeType, discoverType, isPublishTab) => {
		editTableData(`${ROOT_URL}${PUBLISHED_BUCKETS_URL}`, form, ({ data }) => {
			let updatedBucketData = data?.data.filter((item) => item.bucket_id === bucketId);
			updatedBucketData = updatedBucketData.length > 0 ? updatedBucketData.pop() : null;
			dispatch({
				type: EDIT_DATA_IN_PUBLISHED_TABLE,
				payload: {
					home: {
						type: homeType,
						bucketId,
					},
					discover: {
						type: discoverType,
						bucketId,
					},
					updatedBucketData,
					isPublishTab: isPublishTab,
					newData: data,
					selectedScreen: selectedScreenDetails.name,
				},
			});
		});
		dispatch(
			getPublishedTableDataOnOperation(`${ROOT_URL}${PUBLISHED_BUCKETS_URL}`, form),
		);
	};

	const updateResults = (isDiscoverScreenPreset, isHomeScreenPreset, switchValue) => {
		const indexOfDataToBeUpdated = tableData.findIndex(
			(data) => data.bucket_id === bucketId,
		);
		const page = Math.ceil(indexOfDataToBeUpdated / RECORD_TO_FETCH);
		let form = {
			page: indexOfDataToBeUpdated === 0 ? page + 1 : page,
			perPage: RECORD_TO_FETCH,
			screen_id: selectedScreenDetails?.screen_id,
		};
		const homeType =
			isHomeScreenPreset && switchValue.home[0]?.status === 'published'
				? 'no operation'
				: 'remove';
		const discoverType =
			isDiscoverScreenPreset && switchValue.discover[0]?.status === 'published'
				? 'no operation'
				: 'remove';
		editTable(form, homeType, discoverType, true, 'onUpdate');
	};

	const closeModal = () => {
		setIsOpen(false);
		setIsCalendarVisible(false);
		setIsThumbnailPickerVisible(false);
	};

	const newBucketCreated = ({ response, message }) => {
		setBucketListUpdateFlag(!bucketListUpdateFlag);
		if (message) {
			setShowToast({ show: true, message: message, isError: false });
		} else if (response) {
			setShowToast({ show: true, message: response?.data?.message, isError: true });
		}
	};

	const onDeleteBucket = () => {
		setOnDeleteLoading(true);
		deleteBucket(
			`${ROOT_URL}${BUCKET_URL}`,
			{
				bucket_id: bucketId,
			},
			({ message, status: responseStatus }, response) => {
				setOnDeleteLoading(false);
				if (responseStatus && message) {
					setUpdatedData(true);
					closeDeleteConfirmationModal();
					const indexOfDataToBeUpdated = tableData.findIndex(
						(data) => data.bucket_id === bucketId,
					);
					const page = Math.ceil(indexOfDataToBeUpdated / RECORD_TO_FETCH);
					let form = {
						page: indexOfDataToBeUpdated === 0 ? page + 1 : page,
						perPage: RECORD_TO_FETCH,
						screen_id: selectedScreenDetails?.screen_id,
					};
					editTable(form, 'remove', 'remove', true, 'onUpdate');
					openToast(TOAST_ERROR_MESSAGE.BUCKET_SUCCESSFULLY_DELETED, false, true);
				} else if (response) {
					setUpdatedData(false);
					openToast(response.data?.message, true, true);
				}
			},
		);
	};

	const onPublishAndUnpublishBucket = () => {
		setOnStatusChangeLoading(true);
		updateBucketDetails(
			`${ROOT_URL}${UPDATE_STATUS_BY_SCREEN}`,
			{
				bucket_id: bucketId,
				status: 'unpublished',
				screen_id: selectedScreenDetails?.screen_id,
			},
			({ message, status: responseStatus }, response) => {
				setOnStatusChangeLoading(false);
				if (responseStatus && message) {
					setUpdatedData(true);
					closeDeleteConfirmationModal();
					const indexOfDataToBeUpdated = tableData.findIndex(
						(data) => data.bucket_id === bucketId,
					);
					const page = Math.ceil(indexOfDataToBeUpdated / RECORD_TO_FETCH);
					let form = {
						page: indexOfDataToBeUpdated === 0 ? page + 1 : page,
						perPage: RECORD_TO_FETCH,
						screen_id: selectedScreenDetails?.screen_id,
					};
					editTable(form, 'remove', 'remove', true, 'onUnpublish');
					openToast(TOAST_ERROR_MESSAGE.BUCKET_STATUS_CHANGED, false, true);
				} else if (response) {
					setUpdatedData(false);
					openToast(response.data?.message, true, true);
				}
			},
		);
	};

	useEffect(() => {
		if (selectedScreenDetails?.screen_id) {
			if (shuffleButtonStatus === 'disabled') {
				setShuffleButtonStatus('enabled');
			}
		}
		if (selectedScreenDetails?.screen_id === 0) {
			setShuffleButtonStatus('disabled');
		}
	}, [selectedScreenDetails, shuffleButtonStatus]);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setShowToast(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	return (
		<div className="published-bucket-right-container">
			<div className="header-parent-block">
				<div className="bucket-header-block">
					<h1>{published.heading}</h1>
					<div className="right-button-block">
						{screensSelector?.data && (
							<CustomSelectBucket
								defaultValue={selectedScreenDetails}
								from="popup"
								placeholder="Select Screen"
								handleValueChanges={(value) => handleChange(value)}
								menuItems={screensSelector?.data}
								idFieldName="screen_id"
								screenType="published"
							/>
						)}

						<IconTextButton
							buttonText="+ Create Bucket"
							handleClick={() => toggleModal()}
						/>
					</div>
				</div>
			</div>

			<div className="content-block">
				<div className="bucket-content-block">
					<TableContainer
						selectedScreenId={selectedScreenDetails?.screen_id}
						screenChange={screenChange}
						setScreenChange={setScreenChange}
						page={page}
						setPage={setPage}
						loading={loading}
						setLoading={setLoading}
						openEditModal={openEditModal}
						openDeleteModal={openDeleteModal}
						isUpdatedData={isUpdatedData}
						openChangeStatusModal={openChangeStatusModal}
					/>
				</div>
				<CreateBucket
					isOpen={isOpen}
					closeModal={closeModal}
					toggleModal={toggleModal}
					newBucketCreated={newBucketCreated}
				/>
			</div>
			<EditBucketModal
				bucketId={bucketId}
				isOpen={openEditBucketModal}
				setIsOpen={closeEditModal}
				showToast={openToast}
				isEdit
				noAutoScroll={noAutoScroll}
				setNoAutoScroll={setNoAutoScroll}
				callback={updateResults}
				isDisable={isBucketNameDisable}
			/>
			<Popup
				className="popup-content-container"
				open={isOpenDeleteComfirmation || isOpenChangeStatus}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={deleteConfirmationModalStyle}>
				{isOpenDeleteComfirmation && (
					<DeleteConfirmation
						onClose={closeDeleteConfirmationModal}
						onDelete={onDeleteBucket}
						loading={onDeleteLoading}
						message={POPUP.PUBLISHED_MESSAGE}
					/>
				)}
				{isOpenChangeStatus && (
					<DeleteConfirmation
						onClose={closeDeleteConfirmationModal}
						onDelete={onPublishAndUnpublishBucket}
						loading={onStatusChangeLoading}
						message={POPUP.UNPUBLISH_STATUS}
						buttonText={'Yes, Unpublish'}
					/>
				)}
			</Popup>
			{showToast.show ? (
				<Toast
					message={showToast.message}
					onDismiss={handleToastDismiss}
					isError={showToast.isError}
				/>
			) : null}
			{tableData?.length > 20 && !openEditBucketModal && !isOpen && (
				<ScrollToTop handleScrollToTop={handleScrollToTop} />
			)}
		</div>
	);
};

export default PublishedBucket;
