export const ALL_USERS_HEADING = 'All Users';
export const SEARCH_BOX_PLACEHOLDER = 'Search by name';
export const ONLY_INTEGER_ALLOWED = 'Only Integer values are allowed';
export const INVITE_UPPER_COUNT = 'Only upto 100 invites are valid';
export const INVITE_LOWER_COUNT = 'Enter valid invite count';
export const DELETE_CONFIRMATION_MESSAGE = 'Are you sure you want to delete this user?';
export const USER_DELETE_SUCCESS_MESSAGE = 'User deleted successfully';

export const ALL_USERS_TABLE_COLUMNS_CONFIG = [
	{
		key: 'name',
		title: 'Name',
		className: 'name',
		spanClassName: 'name-span',
	},
	{
		key: 'email',
		title: 'Contact',
		className: 'email',
		spanClassName: 'email-span',
	},
	{
		key: 'total-videos',
		title: 'Total Videos',
		className: 'total-videos',
		spanClassName: 'total-videos-span',
	},
	{
		key: 'followers',
		title: 'Followers',
		className: 'followers',
		spanClassName: 'followers-span',
	},
	{
		key: 'remaining-invites',
		title: 'Remaining Invites',
		className: 'remaining-invites',
		spanClassName: 'remaining-invites-span',
	},
	{
		key: 'action',
		title: 'Action',
		className: 'action',
		spanClassName: 'action-span',
	},
];

export const profileBackgroundColor = [
	'#E7635D',
	'#986BE3',
	'#FFC974',
	'#46C7B4',
	'#E87AAA',
	'#9BBB5C',
	'#5072E1',
];
export const PER_PAGE = 20;

export const sortOptions = [
	{ key: 'name', name: 'Name' },
	{ key: 'user_email', name: 'Email' },
	{ key: 'videos', name: 'Number of Videos' },
	{ key: 'invite_count', name: 'Number of Invites' },
	{ key: 'followers', name: 'Followers' },
];

export const mapper = {
	name: 'name',
	email: 'user_email',
	'total-videos': 'videos',
	followers: 'followers',
	'remaining-invites': 'invite_count',
};

export const EDIT_OPTION = [
	{ value: 'delete', label: 'Delete' },
	{ value: 'deactivate', label: 'De-Activate' },
	{ value: 'activate', label: 'Activate' },
];
