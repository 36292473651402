import { ButtonLoader } from 'src/app/components/ButtonLoader';
import React from 'react';
import './DeleteConfirmation.styles.scss';
import DELETE_CONFIRMATION from './constant';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';

const DeleteConfirmation = ({
	onClose,
	onDelete,
	loading,
	message,
	buttonText,
	fromLiveScreen = false,
	fromBucketListing = false,
}) => {
	return (
		<div className="delete-confirmation-container">
			<img
				src={CrossIcon}
				alt="close"
				className={`cross-popup`}
				onClick={() => {
					onClose();
				}}
			/>
			<div className="title">{message || DELETE_CONFIRMATION.HEADING}</div>
			<div className={`buttons ${fromLiveScreen ? 'live-margin' : ''}`}>
				<button onClick={onClose} className="cancel">
					{DELETE_CONFIRMATION.CANCEL}
				</button>
				{loading ? (
					<ButtonLoader />
				) : (
					<button onClick={onDelete}>{buttonText || DELETE_CONFIRMATION.DELETE}</button>
				)}
			</div>
		</div>
	);
};

export default DeleteConfirmation;
