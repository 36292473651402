import { compose, createStore, applyMiddleware } from 'redux';
import INITIAL_STATE from './initialState';
import { rootReducer } from './rootReducer';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// eslint-disable-next-line import/no-mutable-exports
let store;

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['qewdDigest', 'breadCrumb'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

if (process.env.REACT_APP_ENV === 'dev') {
	store = createStore(
		persistedReducer,
		INITIAL_STATE,
		compose(
			window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
				? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
				: (f) => f,
			applyMiddleware(thunk),
		),
	);
} else {
	store = createStore(persistedReducer, INITIAL_STATE, applyMiddleware(thunk));
}

const persistor = persistStore(store);
export { store, persistor };
