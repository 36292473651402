export const RESERVATIONS_INITIAL_STATE = {
	data: [],
	screens: [],
	carousel: {},
	tableData: [],
	bucketTable: {
		published: [],
		unpublished: [],
		draft: [],
		archived: [],
	},
	totalBucketCount: {
		published: 0,
		unpublished: 0,
		draft: 0,
		archived: 0,
	},
	totalBucket: 0,
	allBuckets: [],
	selectedBucket: null,
	subSectionData: {
		data: [],
		length: 0,
	},
	filterSectionData: {
		data: [],
		length: 0,
	},
	cardSizes: null,
	categories: [],
	filters: { genres: null, services: null },
	newDataLength: 0,
	publishedNewDataLength: 0,
	waitlistData: [],
	waitlistTotalUsers: 0,
	bucketDetails: {},
	digestContentData: [],
	parentBucket: [],
	listForYou: [],
};

export const SIDEBAR_INITIAL_STATE = {
	expanded: false,
};
