const TOAST_ERROR_MESSAGE = {
	SELECT_MOVIE_TO_DELETE: 'Please select  video(s) to delete',
	SELECT_BUCKET_FIRST: 'Please select bucket first',
	SUCCESSFULLY_ADDED_TO_BUCKET: 'Successfully added to bucket',
	VIDEO_ALREADY_EXIST: 'Video already exist in bucket',
	BUCKET_CREATED: 'Bucket created successfully',
	BUCKET_SUCCESSFULLY_DELETED: 'Bucket successfully deleted',
	BUCKET_STATUS_CHANGED: 'Bucket Status changed successfully',
	BUCKET_MARKED_AS_LIST_FOR_YOU: 'Bucket mark as List for You successfully.',
	BUCKET_SUCCESSFULLY_ARCHIVED: 'Bucket successfully archived',
};

export default TOAST_ERROR_MESSAGE;
