import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DataTableKebabMenu from '../../components/molecules/DataTableKebabMenu';
import GrabIcon from '../../assets/logos/Grab.svg';
import {
	transitionOccured,
	bucketRedirectPath,
	bucketRedirectPathForLive,
} from '../../common/utils/methods';
import './styles.scss';
import { newDateRange } from '../../common/utils/dateUtils';
import { OPTIONS } from '../../common/constants/BucketListingConstant';
import {
	getBucketDetails,
	setBucketDetails,
} from 'src/app/pages/Dashboard/actionCreators';
import * as AppConstants from 'src/app/common/constants/AppConstants';

export const Row = ({
	data,
	isAllChecked,
	shuffleMode,
	adjustPaddingBottomStatus,
	adjustPaddingBottomScreen,
	adjustPaddingBottomMoreOption,
	isLast,
	openEditBucketModal,
	openDeleteModal,
	openChangeStatusModal,
	setRedirectToBucket,
	setBucketDetailMetaData,
}) => {
	const [showScreenDropdown, setShowScreenDropdown] = useState(false);
	const [shuffleClass, setShuffleClass] = useState('');
	const dispatch = useDispatch();
	const history = useHistory();
	const classNameConditon = (data.index + 1) % 2 === 0 ? 'even' : 'odd';

	useEffect(() => {
		if (shuffleMode) {
			if (transitionOccured('rowShuffle', 'get') && data.index === 1) {
				setTimeout(() => {
					setShuffleClass('translateX');
				}, 100);
				setTimeout(() => {
					setShuffleClass('');
				}, 1000);
				transitionOccured('rowShuffle', 'set');
			}
		}
	}, [shuffleMode]);
	useEffect(() => {
		const arrowEl = document.getElementById(`select-screen-row-down-arrow-${data.index}`);
		if (!showScreenDropdown) {
			arrowEl?.classList.remove('select-box-down-arrow-rotate');
			arrowEl?.classList.add('select-box-down-arrow-unrotate');
		} else {
			arrowEl?.classList.remove('select-box-down-arrow-unrotate');
			arrowEl?.classList.add('select-box-down-arrow-rotate');
		}
	}, [showScreenDropdown]);

	const handlePaddingBottomMore = (isVisible) => {
		if (isLast) {
			if (isVisible) {
				adjustPaddingBottomMoreOption(true);
			} else {
				adjustPaddingBottomMoreOption(false);
			}
		}
	};
	const handleScreenDropdownChange = (toggle = true) => {
		if (!toggle) {
			if (showScreenDropdown) {
				adjustPaddingBottomScreen(!showScreenDropdown);
				setShowScreenDropdown(false);
			}
		} else {
			if (isLast) adjustPaddingBottomScreen(!showScreenDropdown);
			setShowScreenDropdown(!showScreenDropdown);
		}
	};
	const handleBucketRedirect = (id, title, buck_type, screen_name) => {
		console.log('data', data);
		dispatch(setBucketDetails({}));
		if (buck_type === 'video') {
			let path = `/detail?title=${title}&fromBucket=${true}&bucket_id=${id}`;
			history.push(path);
		} else {
			if (screen_name === 'Live TV') {
				let path = bucketRedirectPathForLive(id, title, 'live-bucket', buck_type);
				history.push(path);
			} else {
				let path = `/published/${title}?bucket_id=${id}`;
				history.push(path);
			}
		}
	};

	return (
		<tr className={`${classNameConditon} ${shuffleClass}`}>
			<td>
				<span className="checkbox">
					{shuffleMode ? (
						<img src={GrabIcon} className="grab-icon" alt="Grab Icon" />
					) : isAllChecked ? (
						<input
							type="checkbox"
							id="name-checkbox-row"
							value="all"
							checked={isAllChecked}
						/>
					) : (
						<input type="checkbox" id="name-checkbox-row" value="all" />
					)}

					<span
						className="bucket-name"
						onClick={() =>
							handleBucketRedirect(
								data?.bucket_id,
								data?.name,
								data?.content_type,
								data?.screen_name,
							)
						}>
						{`${data.name}${data?.is_predefined || data?.is_user_type ? '  *' : ''}`}
					</span>
					<span className="ranked-name">{data?.is_ranked_fashion ? 'Ranked' : ''}</span>
				</span>
			</td>
			<td className="row-dateRange">{newDateRange(data)}</td>
			<td className="row-videoCount">{data.videoCount}</td>
			<td>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						position: 'relative',
					}}
					onClick={() => handleScreenDropdownChange()}>
					{data.screen_name}
				</div>
			</td>
			<td className="row-icon">
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						position: 'relative',
					}}>
					<DataTableKebabMenu
						visibilityStatus={handlePaddingBottomMore}
						openEditBucketModal={openEditBucketModal}
						openConfirmationModal={openDeleteModal}
						openChangeStatusModal={openChangeStatusModal}
						optionNotVisible={{
							value: data.is_user_type || data.is_predefined,
							column: 'delete',
						}}
						options={OPTIONS}
						divId={'#infinite-table-container'}
					/>
				</div>
			</td>
		</tr>
	);
};

Row.propTypes = {
	data: PropTypes.object.isRequired,
};
