import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import PrimaryTextButton from '../atoms/PrimaryTextButton';
import ResetAllButton from '../atoms/ResetAllButton';
import * as CONSTANTS from './constant';
import DigestModalCards from 'src/app/components/DigestModalCards';
import { Loader } from '../Loader';
import { useSelector, useDispatch } from 'react-redux';
import { ADD_THUMBNAILS } from 'src/app/pages/Dashboard/actionTypes';
import { setDigestData } from 'src/app/pages/Dashboard/actionCreators';
import { putCommonMethod } from 'src/app/pages/Dashboard/Service';
import { BUCKET_URL, ROOT_URL } from 'src/app/common/constants/AppConstants';
import PublishTick from 'src/app/assets/images/tick.svg';
import { ButtonLoader } from 'src/app/components/ButtonLoader';

const DigestModal = ({
	content,
	show,
	setCheckUpdate,
	onUpdateData,
	closeDeleteConfirmationModal,
	checkUpdate,
	buttonLoader,
	fromPreviewScreen,
	descData,
	setDescData,
	setToastInfo,
}) => {
	const [isloading, setLoading] = useState(true);
	const [focusText, setFocusText] = useState(false);
	const [focusTextOnDesc, setFocusTextOnDesc] = useState(false);
	const id = useRef(-1);
	const dispatch = useDispatch();
	const [showTick, setShowTick] = useState(false);
	const [description, setDescription] = useState(descData?.description);
	const [buttonLoaderOnTick, setButtonLoaderOnTick] = useState(false);

	useEffect(() => {
		if (content) {
			setLoading(false);
		}
	}, [content]);

	const handleBlur = (e, index) => {
		setFocusText(false);
		if (content[index].description_alias !== e.target.value) {
			setCheckUpdate(true);
			content[index].description_alias = e.target.value;
			if (fromPreviewScreen) {
				dispatch({ type: ADD_THUMBNAILS, payload: content });
			} else {
				dispatch(setDigestData({ content: content }));
			}
		}
	};

	const handleFocus = (e) => {
		id.current = Number(e.target.id);
		setFocusText(true);
	};

	const getParams = (e) => {
		let screen_array = [];
		let category_array = [];

		descData?.screens.map((items) => {
			let screens = {
				screen_id: items?.screen_id,
				bucket_status: items?.status || items?.bucket_status,
			};
			screen_array.push(screens);
		});

		if (descData.categories.length) {
			descData?.categories.map((items) => {
				let category = {
					general_category_id: items?.general_category_id,
				};
				category_array.push(category);
			});
		} else {
			let category = {
				general_category_id: 0,
			};
			category_array.push(category);
		}

		return {
			bucket_id: process.env.REACT_APP_QEWD_DIGEST_BUCKET_ID,
			name: descData?.name,
			description: e,
			screens: screen_array,
			categories: category_array,
		};
	};

	const handleBlurOnDesc = async (e) => {
		setShowTick(false);
		setButtonLoaderOnTick(true);
		if (description === '') {
			setToastInfo(true, true, CONSTANTS.EMPTY_DESC);
		} else if (description !== descData?.description) {
			try {
				const params = getParams(description);
				setDescData(params);
				const res = await putCommonMethod(`${ROOT_URL}${BUCKET_URL}`, params);
				if (res.status) {
					setToastInfo(true, false, CONSTANTS.TOAST_MESSAGE);
				}
			} catch (error) {
				setToastInfo(true, true, CONSTANTS.TOAST_ERROR);
			}
		}

		setFocusTextOnDesc(false);
		setButtonLoaderOnTick(false);
	};

	const handleFocusOnDesc = (e) => {
		if (e?.relatedTarget?.className !== 'button edit-button') {
			setFocusTextOnDesc(true);
		}
	};

	const handleTick = (e) => {
		if (e.target.value !== descData?.description) {
			setShowTick(true);
			setDescription(e.target.value);
		} else {
			setShowTick(false);
		}
	};

	if (show)
		return (
			<div className="digest-modal-container">
				<p className="digest-modal-header">{CONSTANTS.EDIT_DIGEST_HEADING}</p>
				<div className="digest-modal-container-content">
					<div className="digest-modal-container-default-message">
						<div className="digest-default-message-header">
							{CONSTANTS.DEFAULT_MESSAGE__HEADING}
						</div>
						<div className="digest-default-message-body">
							<textarea
								className={`digest-card-wrapper-desc-text ${
									focusTextOnDesc ? 'focusText' : ''
								}`}
								rows="2"
								id={1}
								defaultValue={descData?.description}
								onFocus={(e) => handleFocusOnDesc(e)}
								onClick={() => setFocusTextOnDesc(true)}
								onkeypress={() => setFocusTextOnDesc(true)}
								onChange={(e) => handleTick(e)}></textarea>
							{showTick && (
								<button className="tick-button" onClick={(e) => handleBlurOnDesc(e)}>
									<img src={PublishTick} alt="DONE" />
								</button>
							)}
							{buttonLoaderOnTick && <ButtonLoader />}
						</div>
					</div>
					<div className="digest-card-container">
						{isloading === false && content ? (
							content.map((item, index) => {
								return (
									<DigestModalCards
										data={item}
										key={index}
										indexVal={index}
										handleBlur={(e) => handleBlur(e, index, item.video_id)}
										focusText={focusText}
										handleFocus={(e) => handleFocus(e)}
										id={id.current}
									/>
								);
							})
						) : (
							<Loader />
						)}
					</div>
				</div>
				<div className="digest-action-button">
					<ResetAllButton
						label="Cancel"
						handleClick={() => closeDeleteConfirmationModal()}
					/>
					<PrimaryTextButton
						disabledButton={!checkUpdate}
						label={'Update'}
						main={true}
						handleClick={onUpdateData}
						showLoader={buttonLoader}
					/>
				</div>
			</div>
		);
	else return null;
};

export default DigestModal;
