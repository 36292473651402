export const PAGE_HEADER = 'YouTube Videos';
export const SEARCHBAR_PLACEHOLDER = 'Search videos by name';
export const RECORD_TO_FETCH = 40;
export const EMPTY_STATE_MESSAGE = 'No Results Found';
export const SUCCESS_ADD = 'Video added to Bucket Successfully';

export const CAROUSAL_DATA = [
	{
		id: 1,
		heading: 'Trending Now on YouTube',
		type: 'n',
	},
	{
		id: 2,
		heading: 'Trending Music on YouTube',
		type: 'mu',
	},
	{
		id: 3,
		heading: 'Trending Movies on YouTube',
		type: 'mo',
	},
	{
		id: 4,
		heading: 'Trending in Gaming on YouTube',
		type: 'g',
	},
];
