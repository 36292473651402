export const LIVE_STREAM_HEADING = 'Live Channels';

export const LIVE_STREAM_TABLE_COLUMNS_CONFIG = [
	{
		key: 'name',
		title: 'Name',
		className: 'name',
		spanClassName: 'name-span',
	},
	{
		key: 'stream-url',
		title: 'Channel URL',
		className: 'stream-url',
		spanClassName: 'stream-url-span',
	},
	{
		key: 'icon',
		title: 'Logo',
		className: 'icon',
		spanClassName: 'icon-span',
	},
	{
		key: 'action',
		title: 'Actions',
		className: 'actions',
		spanClassName: 'actions-span',
	},
];

export const contentTooltipStyle = {
	width: 300,
	maxHeight: 150,
	background: '#2A2A31',
	borderRadius: 12,
	padding: 20,
	overflowY: 'scroll',
	overflowX: 'hidden',
};

export const ADD_URL_POPUP_CONST = {
	editMessage: 'Edit Live Channel URL',
	message: 'Add Live Channel URL',
};

export const ERROR_MESSAGE = 'Something went wrong. Please try again.';
