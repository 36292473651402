export const LOGIN_FORM = {
	loginButtonTitle: 'Login',
	placeholder: 'Enter Phone Number',
	label: 'Phone Number',
	buttonLabel: 'Login',
	invalidNumber: 'Invalid Phone Number',
};

export const HOME_CONSTANTS = {
	remainingTimeSeconds: 59,
	remainingTimeMinutes: 2,
};

export const OTP_CONSTANTS = {
	OTP_Length: 6,
	OTP_Placeholder: '......',
	OTP_TimeRemaining: 'Time remaining ',
	OTP_Button: 'Verify OTP',
	OTP_Resend: 'Resend OTP',
	OTP_Seconds_Check: 10,
};

export const TOAST_MESSAGES = {
	WRONG_OTP: 'Wrong OTP. Please verify and enter again',
	EXPIRE_OTP: 'OTP has expired. Please click on Resend OTP to get a new one.',
	ERROR_OTP: 'Something went wrong. Please try again.',
	ADMIN_DENY: "You don't have admin rights to login",
	USER_NOT_EXIST: 'User not exist',
};

export const ADMIN_EMAIL = 'admin.user@qewd.com';
export const SOCIAL_LOGIN_HEADING = 'OR LOGIN WITH';
