import React from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../SideBar';
import { useEffect } from 'react';
import { getScreens } from '../../actionCreators';
import { ROOT_URL, SCREEN_URL } from '../../../../common/constants/AppConstants';

export const DashboardWrapper = ({ children }) => {
	const dispatch = useDispatch();
	const isExpandedSelector = useSelector((state) => state.sidebar.expanded);
	const screen = useSelector((state) => state.reservations.screens);

	useEffect(() => {
		if (screen.length === 0) {
			dispatch(getScreens(`${ROOT_URL}${SCREEN_URL}`));
		}
	}, [dispatch]);

	return (
		<React.Fragment>
			<div className="global-container hide-overflow-x">
				<div
					className={`global-sidebar-container ${
						isExpandedSelector
							? 'global-sidebar-container-expanded-width'
							: 'global-sidebar-container-collapsed-width'
					}`}>
					<SideBar />
				</div>
				<div
					className={`global-content-container ${
						isExpandedSelector
							? 'global-content-expanded-container'
							: 'global-content-collapsed-container'
					}`}>
					{children}
				</div>
			</div>
		</React.Fragment>
	);
};
