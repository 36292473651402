import React, { useState, useEffect } from 'react';
import './styles.scss';
import { getRowClassName, getYear } from 'src/app/common/utils/methods';
import * as CONST from '../Constant';
import { overlayStyle } from 'src/app/common/constants/AppConstants';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import FilePickerIcon from 'src/app/assets/images/FilePickerIcon.svg';
import { transitionOccured, selectCards } from 'src/app/common/utils/methods';
import GrabIcon from 'src/app/assets/logos/Grab.svg';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import DeleteBucketActive from 'src/app/assets/images/DeleteIconActive.svg';
import EditIcon from 'src/app/assets/images/EditActionButton.svg';
import AddIcon from 'src/app/assets/logos/Add.svg';

export const Row = ({
	data,
	id,
	shuffleMode,
	innerref,
	styleset,
	snap,
	handleAddButton,
	deleteAll,
	setUncheckedOnSelectOne,
	selectedCards,
	length,
	onSelecting,
	setDeleteAll,
	handleChangeOnEdit,
	handleVideoPopup,
	handleDeleteChannelFromBucket,
	showSubCategoryData,
	addSingleChannelToBucket,
	contentType,
	handleSubCategory,
	...other
}) => {
	const [shuffleClass, setShuffleClass] = useState('');
	const [selectionValue, setSelectionValue] = useState('');
	const [icon, setIcon] = useState(DeleteBucket);

	const addDefaultSrc = (ev) => {
		ev.target.className = 'row-icon-img row-icon-img-none';
		ev.target.src = '';
	};

	const onMouseEnterOnDeleteButton = () => {
		setIcon(DeleteBucketActive);
	};

	const onMouseLeaveOnDeleteButton = () => {
		setIcon(DeleteBucket);
	};

	useEffect(() => {
		if (selectedCards?.length > 0) {
			const cards = selectCards(selectedCards, id);
			setSelectionValue(cards);
		}
	}, [selectedCards, selectionValue]);

	useEffect(() => {
		setSelectionValue(deleteAll);
	}, [deleteAll]);

	const onSelectionChange = (e) => {
		if (deleteAll && !e.target.checked) {
			setUncheckedOnSelectOne(false);
			setDeleteAll(false);
		}
		if (deleteAll && e.target.checked) {
			if (selectedCards?.length + 1 === length) {
				setUncheckedOnSelectOne(true);
			}
		}
		setSelectionValue(e.target.checked);
		onSelecting({ id: e.target.id, value: e.target.checked });
	};

	useEffect(() => {
		if (shuffleMode) {
			if (transitionOccured('rowShuffle', 'get') && data.index === 1) {
				setTimeout(() => {
					setShuffleClass('translateX');
				}, 100);
				setTimeout(() => {
					setShuffleClass('');
				}, 1000);
				transitionOccured('rowShuffle', 'set');
			}
		}
	}, [shuffleMode]);

	function getStyle(style, snapshot) {
		if (!snapshot.isDropAnimating) {
			return style;
		}
		return {
			...style,
			// cannot be 0, but make it super tiny
			transitionDuration: `0.001s`,
		};
	}

	return (
		<tr
			className={`${getRowClassName(data?.index)} ${shuffleClass}`}
			ref={innerref}
			style={shuffleMode ? getStyle(styleset, snap) : null}
			{...other}>
			<td>
				<span className="checkbox">
					{shuffleMode ? (
						<img src={GrabIcon} className="grab-icon" alt="Grab Icon" />
					) : (
						<input
							id={id}
							type="checkbox"
							className="name-checkbox-row"
							value={selectionValue}
							onChange={onSelectionChange}
							alt="selection"
							checked={selectionValue}
						/>
					)}

					<div
						className={`title-container ${
							contentType === CONST.CONTENT_TYPE.live_channel ? 'sub-cat-title' : ''
						}`}
						onClick={
							contentType === CONST.CONTENT_TYPE.live_channel
								? () => handleSubCategory(data?.content_id)
								: () => {}
						}>
						<p className="title-name">{data?.name}</p>
					</div>
				</span>
			</td>

			<td className="row-url-all" onClick={handleVideoPopup}>
				{data?.url}
			</td>

			<td className="logo-row">
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<img
						onError={addDefaultSrc}
						className="row-icon-img"
						src={`${data?.thumbnail}`}
						alt="icon"
					/>
				</div>
			</td>

			<td className="row-options">
				{!showSubCategoryData && (
					<Tooltip
						overlay={
							contentType === CONST.CONTENT_TYPE.live_stream
								? CONST.TOOLTIP_CONST.live_stream_delete
								: CONST.TOOLTIP_CONST.live_channel_delete
						}
						placement="bottom"
						overlayInnerStyle={overlayStyle}
						align={{
							offset: [-4, -2],
						}}>
						<span
							className="live-stream-action-button"
							onClick={handleDeleteChannelFromBucket}
							onMouseEnter={() => onMouseEnterOnDeleteButton()}
							onMouseLeave={() => onMouseLeaveOnDeleteButton()}>
							<img src={icon} className="live-stream-action-button-image" alt="delete" />
						</span>
					</Tooltip>
				)}
				{showSubCategoryData && contentType === CONST.CONTENT_TYPE.live_stream && (
					<Tooltip
						overlay="Add Live Channel"
						placement="bottom"
						overlayInnerStyle={overlayStyle}
						align={{
							offset: [-4, 2],
						}}>
						<span
							className="live-stream-action-button"
							onClick={() => addSingleChannelToBucket(data?.content_id)}>
							<img
								src={AddIcon}
								className="live-stream-action-button-image"
								alt="delete"
							/>
						</span>
					</Tooltip>
				)}
				{contentType === CONST.CONTENT_TYPE.live_stream && (
					<Tooltip
						overlay="Edit Live Channel"
						placement="bottom"
						overlayInnerStyle={overlayStyle}
						align={{
							offset: [8, -2],
						}}>
						<span className="live-stream-action-button" onClick={handleChangeOnEdit}>
							<img
								src={EditIcon}
								className="live-stream-action-button-image-exta-styling"
								alt="edit"
							/>
						</span>
					</Tooltip>
				)}
			</td>
		</tr>
	);
};

export default Row;
