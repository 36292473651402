import {
	GET_USER_DATA_INITIAL,
	GET_USER_DATA,
	EDIT_USER_DATA_ON_DELETE,
} from 'src/app/pages/Dashboard/actionTypes';
import ALL_USER_INITIAL_STATE from './initialState';
import { removeSingleEntryFromArray } from 'src/app/common/utils/methods';

const allUserReducer = (state = ALL_USER_INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_USER_DATA_INITIAL:
			return {
				...state,
				allUsers: action.payload || [],
			};
		case GET_USER_DATA:
			return {
				...state,
				allUsers: [...state.allUsers, ...action.payload],
			};
		case EDIT_USER_DATA_ON_DELETE:
			const arr = [...state.allUsers];
			const resultArray = removeSingleEntryFromArray(arr, action.payload);
			return {
				...state,
				allUsers: [...resultArray],
			};
		default:
			return state;
	}
};

export default allUserReducer;
