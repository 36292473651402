import React from 'react';
import { getRowClassName } from 'src/app/common/utils/methods';
import { IMAGE_PLACEHOLDER_HORIZONTAL } from 'src/app/common/constants/AppConstants';

export const Row = ({ data, val, handleClick }) => {
	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
	};

	return (
		<tr className={`${getRowClassName(data?.index)} `}>
			<td className="services-name-row">
				<div className="services-name-row-wrapper">
					<img
						src={data?.logo}
						alt="service-url"
						className="service-logo"
						onError={addDefaultSrc}
					/>
					<p className="services-name">{data?.display_name}</p>
				</div>
			</td>
			<td className="service-checkbox">
				<span className="checkbox">
					<input
						type="checkbox"
						id="services-checkbox-row"
						value="all"
						checked={val}
						defaultChecked={data?.is_playable_on_qewd}
						onChange={handleClick}
					/>
				</span>
			</td>
		</tr>
	);
};

export default Row;
