import React, { useState, useEffect, forwardRef } from 'react';
import './styles.scss';
import * as CONST from '../Constant';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import Row from './Row';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import { ReactSortable } from 'react-sortablejs';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'src/app/components/Loader';
import Table from 'src/app/components/Table';
import { putCommonMethod } from 'src/app/pages/Dashboard/Service';
import { useDispatch } from 'react-redux';
import { UPDATE_BUCKET_VIDEO_DATA } from 'src/app/pages/Dashboard/actionTypes';

export const TableContainer = ({
	tableData,
	shuffle,
	hasMore,
	fetchMoreData,
	handleAddButton,
	metaData,
	setToastError,
	setShowToastMessage,
	setShowToast,
	deleteAll,
	selectedCards,
	setDeleteAll,
	onSelecting,
	selectToDeleteAll,
	setUncheckedOnSelectOne,
	unCheckedOnSelectOne,
}) => {
	const dispatch = useDispatch();
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (tableData) {
			setTableDisplayData([...tableData]);
			setLoading(false);
		}
	}, [tableData, setLoading]);

	const dragEndHandler = async (result) => {
		if (!result.destination) return;

		const items = Array.from(tableDisplayData);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setTableDisplayData([...items]);

		const draggedItem = tableDisplayData[result.source?.index];
		let videoRank;
		if (result.destination?.index === 0) {
			videoRank =
				items[result.destination?.index + 1]['video_sequence'] > 1
					? items[result.destination?.index + 1]['video_sequence'] - 1 || 0.5
					: items[result.destination?.index + 1]['video_sequence'] / 1.01;
		} else if (result.destination?.index === tableDisplayData.length - 1) {
			videoRank = items[result.destination?.index - 1]['video_sequence'] + 1 || 2;
		} else {
			videoRank =
				(items[result.destination?.index - 1]['video_sequence'] +
					items[result.destination?.index + 1]['video_sequence']) /
					2 || 1;
		}

		try {
			const resp = await putCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.UPDATE_VIDEO_RANK}`,
				{
					bucket_id: metaData?.bucket_id,
					video_id: draggedItem?.video_id,
					video_rank: videoRank,
				},
			);
			const arr = [...items];
			arr[result.destination?.index]['video_sequence'] = videoRank;
			dispatch({ type: UPDATE_BUCKET_VIDEO_DATA, payload: [...arr] });
		} catch (e) {
			setShowToastMessage('Something Went Wrong');
			setToastError(true);
			setShowToast(true);
		}
	};

	const renderTable = () => {
		if (loading) {
			return (
				<EmptyState
					columns={CONST.BUCKET_DETAILS_TABLE_COLUMNS_CONFIG}
					renderColumns={2}
				/>
			);
		} else {
			return tableDisplayData.map((item, index) => {
				return (
					<Row
						key={item && `${item.video_id}`}
						data={{ ...item, index }}
						id={item?.video_id}
						shuffleMode={shuffle}
						handleAddButton={handleAddButton}
						deleteAll={deleteAll}
						setUncheckedOnSelectOne={setUncheckedOnSelectOne}
						selectedCards={selectedCards}
						length={tableDisplayData?.length}
						onSelecting={onSelecting}
						setDeleteAll={setDeleteAll}
					/>
				);
			});
		}
	};

	if (shuffle) {
		return (
			<DragDropContext onDragEnd={dragEndHandler}>
				<Table isRenderable={true} shuffleMode={shuffle} changeBG={true}>
					<Droppable droppableId="table">
						{(provided, snapshot) => (
							<div
								id="scrollableDivBucketList"
								className="table"
								ref={provided.innerRef}
								{...provided.droppableProps}>
								<InfiniteScroll
									dataLength={tableData?.length}
									next={fetchMoreData}
									hasMore={hasMore}
									scrollThreshold={0.2}
									loader={<Loader loadMore={true} />}
									scrollableTarget="scrollableDivBucketList">
									<table className="table-bucket-details" id="">
										<thead>
											<tr>
												{CONST.BUCKET_DETAILS_TABLE_COLUMNS_CONFIG.map((column) => {
													return (
														<Column
															key={column.key}
															config={column}
															shuffleMode={shuffle}
															isWaitlist={false}
															fromBuck={true}
															selectToDeleteAll={selectToDeleteAll}
															deleteAll={deleteAll}
															unCheckedOnSelectOne={unCheckedOnSelectOne}
														/>
													);
												})}
											</tr>
										</thead>

										<tbody>
											{tableDisplayData.map((item, index) => {
												return (
													<Draggable
														draggableId={item.video_id.toString()}
														index={index}
														key={item.video_id}>
														{(provided, snapshot) => (
															<Row
																key={item && `${item.video_id}`}
																data={{ ...item, index }}
																id={item?.video_id}
																shuffleMode={shuffle}
																innerref={provided.innerRef}
																styleset={provided.draggableProps.style}
																deleteAll={deleteAll}
																snap={snapshot}
																handleAddButton={handleAddButton}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																setUncheckedOnSelectOne={setUncheckedOnSelectOne}
																selectedCards={selectedCards}
																length={tableDisplayData?.length}
																onSelecting={onSelecting}
																setDeleteAll={setDeleteAll}
															/>
														)}
													</Draggable>
												);
											})}
										</tbody>
									</table>
								</InfiniteScroll>
							</div>
						)}
					</Droppable>
				</Table>
			</DragDropContext>
		);
	} else {
		return (
			<Table isRenderable={true} shuffleMode={shuffle} changeBG={true}>
				<div id="scrollableDivBucketList">
					<InfiniteScroll
						dataLength={tableData?.length}
						next={fetchMoreData}
						hasMore={hasMore}
						scrollThreshold={0.5}
						loader={<Loader loadMore={true} />}
						scrollableTarget="scrollableDivBucketList">
						<table className="table-bucket-details">
							<thead>
								<tr>
									{CONST.BUCKET_DETAILS_TABLE_COLUMNS_CONFIG.map((column) => {
										return (
											<Column
												key={column.key}
												config={column}
												shuffleMode={shuffle}
												isWaitlist={false}
												fromBuck={true}
												selectToDeleteAll={selectToDeleteAll}
												deleteAll={deleteAll}
												unCheckedOnSelectOne={unCheckedOnSelectOne}
											/>
										);
									})}
								</tr>
							</thead>
							<tbody>{renderTable()}</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</Table>
		);
	}
};

export default TableContainer;
