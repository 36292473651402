import React, { useState, useEffect, useRef } from 'react';
import { getCommonMethod, postCommonMethod } from 'src/app/pages/Dashboard/Service';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import { AppButton } from 'src/app/components/AppButton';
import * as AUTOPOPULATE_CONSTANTS from './constant';
import { Loader } from 'src/app/components/Loader';
import BigCard from 'src/app/components/BigCard';
import './styles.scss';
import {
	ROOT_URL,
	QEWD_DIGEST_PUBLISH_FREQUENCY,
	QEWD_DIGEST,
	overlayStyle,
	BUCKET_DETAIL_URL,
} from 'src/app/common/constants/AppConstants';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import Popup from 'reactjs-popup';
import { deleteConfirmationPopupStyle } from 'src/app/common/constants/PopupStyles';
import { useHistory, useLocation } from 'react-router-dom';
import EditImage from 'src/app/assets/images/Edit.png';
import { EditDigestModal } from 'src/app/components/Modals/EditDigestModal';
import { setDigestData, setInitialData } from 'src/app/pages/Dashboard/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { publishDigest } from 'src/app/pages/Dashboard/actionCreators';
import { FREQUENCY } from 'src/app/components/PublishModal/constants';
import { Toast } from 'src/app/components/Toast';
import PublishModal from 'src/app/components/PublishModal';
import * as CONSTANTS from 'src/app/components/Modals/EditDigestModal/constants';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

export const QewdDigestPrepopulate = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [selectedId, setSelectedId] = useState(null);
	const [selectedSection, setSelectedSection] = useState(null);
	const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
	const searchRef = useRef('');
	const unchangedData = useRef([]);
	const [openModal, setOpenModal] = useState(false);
	const [frequencyHeader, setFrequencyHeader] = useState(null);
	const [updateBigCards, setUpdateBigCard] = useState(false);
	const [openPublishModal, setOpenPublishModal] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [frequencyValue, setFreqencyValue] = useState();
	const [message, setMessage] = useState(AUTOPOPULATE_CONSTANTS.PUBLISH_MESSAGE);
	const [frequencyChecked, setFrequencyChecked] = useState(false);
	const [publishButtonLoader, setPublishButtonLoader] = useState(false);
	const [publishDirectly, setPublishDirectly] = useState(false);
	const [publishDirectlyLoader, setPublishDirectlyLoader] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [showLoaderOnScreenLoad, setShowLoaderOnScreenLoad] = useState(0);
	const [descData, setDescData] = useState(AUTOPOPULATE_CONSTANTS.DEFAULT_MESSAGE__BODY);

	const fetchData = async (val = true) => {
		if (!showLoaderOnScreenLoad) {
			setLoading(true);
		}
		const response = await getCommonMethod(`${ROOT_URL}/qewd-digest?offset=0&perPage=4`);
		setShowLoaderOnScreenLoad(1);
		let value = [];
		if (response && response.data) {
			const res = Object.entries(response.data);
			setData(res);
			res.map((item) => {
				if (item[0] !== 'update_frequency') {
					item[1].map((val) => {
						value.push(val);
					});
				} else {
					if (item[1] || item[1] === 0) {
						setFrequencyHeader(item[1]);
					}
				}
			});
		}
		dispatch(setDigestData({ content: value }));
		if (val) {
			setTimeout(() => setLoading(false), 100);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (updateBigCards) {
			fetchData(false);
			setTimeout(() => setUpdateBigCard(false), 100);
		}
	}, [updateBigCards]);

	const handleChange = (e) => {
		setSearchText(e.target.value);
	};

	const emptySearchBox = () => {
		setSearchText('');
		searchRef.current.value = '';
	};
	const handleEditModal = () => {
		setOpenModal(true);
	};

	const onSelection = (id, section) => {
		if (id) {
			setSelectedId(id);
			setSelectedSection(section);
		} else {
			setSelectedId(null);
		}
	};

	const toggleDeleteConfirmation = (value) => {
		setToggleConfirmationModal(value);
	};

	const onDelete = () => {
		const copyOfData = [...data];
		const sectionFromWhichCardToBeDeleted = copyOfData.filter(
			(item) => item[0] === selectedSection,
		);
		const index = sectionFromWhichCardToBeDeleted[0][1].findIndex(
			(item) => item.video_id === selectedId,
		);
		if (index !== -1) {
			sectionFromWhichCardToBeDeleted[0][1].splice(index, 1);
		}
		toggleDeleteConfirmation(false);
	};

	const redirectToManual = () => {
		history.push(`${location.pathname}/manual`);
	};

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setShowToast(toastDisplayVal);
		setToastError(errorVal);
		setShowToastMessage(messageVal);
	};

	const handleFrequencyClick = () => {
		setOpenPublishModal(true);
	};

	const closePublishModal = async () => {
		setOpenPublishModal(false);
	};

	const closeModal = async () => {
		setPublishDirectly(false);
	};

	const getSelectionValue = (value) => {
		setFreqencyValue(FREQUENCY[value]);
	};

	const onPublishData = async () => {
		setPublishButtonLoader(true);
		setPublishDirectlyLoader(true);
		publishDigest(
			`${ROOT_URL}${QEWD_DIGEST_PUBLISH_FREQUENCY}`,
			{
				update_frequency: frequencyValue,
			},
			(status, response) => {
				if (status) {
					setToastInfo(
						true,
						false,
						AUTOPOPULATE_CONSTANTS.SUCCESS_FRQUENCY_UPDATION_MESSAGE,
					);
					setFrequencyHeader(frequencyValue);
					setMessage(AUTOPOPULATE_CONSTANTS.PUBLISH_MESSAGE);
					let path = `/dashboard/${process.env.REACT_APP_QEWD_DIGEST_TITLE}?bucket_id=${process.env.REACT_APP_QEWD_DIGEST_BUCKET_ID}`;
					history.push(path);
				} else {
					setToastInfo(true, true, AUTOPOPULATE_CONSTANTS.ERROR_MESSAGE);
				}
			},
		);
		setTimeout(() => {
			setPublishButtonLoader(false);
			setPublishDirectlyLoader(false);
			setFrequencyChecked(false);
			setOpenPublishModal(false);
		}, 600);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '');
	};

	const handlePublishDirectly = () => {
		setPublishDirectly(true);
	};

	const handlePublishOnFrequency = () => {
		setMessage(AUTOPOPULATE_CONSTANTS.PUBLISH_ON_FRQUENCY_PRESENT);
		setOpenPublishModal(true);
	};

	const fetchQewdDesc = async () => {
		try {
			const res = await getCommonMethod(`${ROOT_URL}${BUCKET_DETAIL_URL}`, {
				bucket_id: process.env.REACT_APP_QEWD_DIGEST_BUCKET_ID,
			});
			if (res?.data?.description) {
				setDescData(res?.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchData();
		fetchQewdDesc();
	}, []);

	return (
		<div className="qwed-digest-right-wrapper">
			<div className="qwed-digest-heading-wrapper">
				<h1>{AUTOPOPULATE_CONSTANTS.HEADING}</h1>
				{frequencyHeader > 0 ? (
					<div className="qwed-digest-frequency-header" onClick={handleFrequencyClick}>
						<span className="qwed-digest-frequency-span-content">{`Set To - Auto | Friday - Sunday, Every Week`}</span>
					</div>
				) : frequencyHeader === 0 ? (
					<div className="qwed-digest-frequency-header" onClick={handleFrequencyClick}>
						<span className="qwed-digest-frequency-span-content">{`Set To - Manual | Friday - Sunday, Every Week`}</span>
					</div>
				) : null}
			</div>
			<div className="search-box-container">
				<div className="search-with-cancel">
					<DashboardSearchBox
						handleChange={handleChange}
						className="qwed-digest-search"
						ref={searchRef}
						digestButton
					/>
					{searchText !== '' && (
						<Button
							label="Cancel"
							labelClassName="cancel-button-label"
							className="cancel-button"
							onClick={emptySearchBox}
						/>
					)}
				</div>
				<div className="header-buttons">
					<Tooltip
						overlay={`Edit ${process.env.REACT_APP_QEWD_DIGEST_TITLE}`}
						placement="bottom"
						overlayInnerStyle={overlayStyle}
						align={{
							offset: [-8, -2],
						}}>
						<div>
							<Button
								imageInfo={{
									src: EditImage,
								}}
								className="edit-button"
								onClick={handleEditModal}
							/>
						</div>
					</Tooltip>

					<div className="manually-button-div">
						<AppButton
							label="Create Manually"
							className="preview-qwed-digest"
							innerClass="preview-qwed-digest-text"
							onClick={redirectToManual}
						/>
						<AppButton
							label={`Publish ${process.env.REACT_APP_QEWD_DIGEST_TITLE}`}
							className="pre-populate"
							innerClass="pre-populate-text"
							onClick={frequencyHeader ? handlePublishDirectly : handlePublishOnFrequency}
						/>
					</div>
				</div>
			</div>

			<div className="qewd-digest-auto-populate-container">
				{loading && <Loader />}
				{!loading &&
					data &&
					data.length > 0 &&
					data.map((item) => {
						if (item[0] !== 'update_frequency') {
							return (
								<QewdDigestContent
									key={item[0]}
									title={item[0]}
									cards={item[1]}
									onSelection={onSelection}
									toggleDeleteConfirmation={toggleDeleteConfirmation}
								/>
							);
						}
					})}
			</div>
			<EditDigestModal
				isOpen={openModal}
				setIsOpen={setOpenModal}
				setUpdateBigCard={setUpdateBigCard}
				isFrequency={frequencyHeader}
				setOpenPublishModal={setOpenPublishModal}
				setMessage={setMessage}
				descData={descData}
				setDescData={setDescData}
			/>
			<Popup
				className="popup-content-container-digest-publish"
				open={openPublishModal}
				closeOnDocumentClick
				onClose={closePublishModal}
				contentStyle={CONSTANTS.popupContentStylePublish}>
				<PublishModal
					onClose={closePublishModal}
					onDelete={onPublishData}
					loading={true}
					getSelectionValue={getSelectionValue}
					frequencyBoxes={true}
					message={message}
					setFrequencyChecked={setFrequencyChecked}
					disabledButton={!frequencyChecked}
					publishButtonLoader={publishButtonLoader}
					currentFrequency={frequencyHeader}
				/>
			</Popup>
			<Popup
				open={toggleConfirmationModal}
				closeOnDocumentClick
				onClose={() => toggleDeleteConfirmation(false)}
				contentStyle={deleteConfirmationPopupStyle}>
				<DeleteConfirmation
					message={AUTOPOPULATE_CONSTANTS.DELETE_MESSAGE}
					onClose={() => toggleDeleteConfirmation(false)}
					onDelete={onDelete}
				/>
			</Popup>
			<Popup
				className="frequency-popup"
				open={publishDirectly}
				closeOnDocumentClick
				onClose={closeModal}
				contentStyle={deleteConfirmationPopupStyle}>
				<PublishModal
					onClose={closeModal}
					onDelete={onPublishData}
					getSelectionValue={getSelectionValue}
					frequencyBoxes={false}
					message={AUTOPOPULATE_CONSTANTS.PUBLISH_DIRECTLY_MESSAGE}
					publishButtonLoader={publishDirectlyLoader}
				/>
			</Popup>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

const QewdDigestContent = ({ title, cards, onSelection, toggleDeleteConfirmation }) => {
	return (
		<div className="auto-populate-content-container">
			<div className="title">{title}</div>
			<div className="cards">
				{cards &&
					cards.length > 0 &&
					cards.map((item, index) => {
						return (
							<BigCard
								key={item.video_id}
								cardDetails={item}
								index={index}
								onSelection={onSelection}
								id={item.video_id}
								toggleDeleteConfirmation={toggleDeleteConfirmation}
								title={title}
							/>
						);
					})}
				{cards && cards.length === 0 && (
					<div className="video-error-message">No video found for this category.</div>
				)}
			</div>
		</div>
	);
};
