import React from 'react';
import Cancel from '../../assets/images/Cancel.svg';
import './styles.scss';
import PropTypes from 'prop-types';

export const Toast = ({ message, onDismiss, isError }) => {
  const handleClose = () => {
    onDismiss();
  };
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div className="toast-wrapper">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <span
            className={
              isError ? 'notification-text error-message' : 'notification-text success-message'
            }
          >
            {message || 'Success!'}
          </span>
        </div>
        <div className="cancel-icon-wrapper" onClick={handleClose}>
          <img src={Cancel} />
        </div>
      </div>
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  onDismiss: PropTypes.func,
  isError: PropTypes.bool,
};
