import React from 'react';
import './styles.scss';
import * as CONSTANTS from '../DigestModal/constant';
import { IMAGE_PLACEHOLDER_HORIZONTAL } from 'src/app/common/constants/AppConstants';

const DigestModalCards = ({ data, indexVal, handleBlur, handleFocus, focusText, id }) => {
	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
	};

	const getValue = (index) => {
		let returnValue = index + 1;
		if (returnValue < 10) {
			return '0' + returnValue;
		} else {
			return returnValue;
		}
	};

	return (
		<div className="digest-card-wrapper">
			<div className="digest-card-wrapper-video-thumb">{CONSTANTS.VIDEO_THUMB}</div>
			<div className="digest-card-wrapper-image-div">
				<img
					onError={addDefaultSrc}
					className="digest-card-wrapper-image"
					src={data?.horizontal_thumbnail || data?.thumbnail}
					alt="movieThumbnail"
				/>
			</div>
			<div className="digest-card-wrapper-title-div">
				<div className="digest-card-wrapper-title-image">{getValue(indexVal)}</div>
				<div className="digest-card-wrapper-title">{data?.title}</div> {/** */}
			</div>
			<div className="digest-card-wrapper-video-thumb" style={{ marginTop: 30 }}>
				{CONSTANTS.VIDEO_DESC}
			</div>
			<div className="digest-card-wrapper-desc">
				<textarea
					className={`digest-card-wrapper-desc-text ${
						focusText && id === (data?.video_id || data?.id) ? 'focusText' : null
					}`}
					rows="2"
					id={data?.video_id || data?.id}
					defaultValue={data?.description_alias}
					onFocus={handleFocus}
					onBlur={handleBlur}></textarea>
			</div>
		</div>
	);
};

export default DigestModalCards;
