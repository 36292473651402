import React, { useState, useEffect } from 'react';
import './styles.scss';
import * as CONSTANTS from './constants';
import LeftPanel from 'src/app/pages/SettingsScreen/Components/LeftPanel';
import RightPanel from 'src/app/pages/SettingsScreen/Components/RightPanel';
import IconTextButton from 'src/app/components/atoms/IconTextButton';
import { putCommonMethod } from 'src/app/pages/Dashboard/Service';
import * as GLOBAL_CONSTANT from 'src/app/common/constants/AppConstants';
import { Toast } from 'src/app/components/Toast';
import Popup from 'reactjs-popup';
import { settingConfirmationStyle } from 'src/app/common/constants/PopupStyles';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import TagContainer from './Components/TagContainer';

export const SettingsScreen = () => {
	const [activeTabId, setActiveTabId] = useState(0);
	const [disableSave, setDisableSave] = useState(false);
	const [selectedService, setSelectedService] = useState([]);
	const [newServices, setNewServices] = useState([]);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [openPopup, setOpenPopup] = useState(false);
	const [providerName, setProviderName] = useState([]);
	const [tagsName, setTagsName] = useState([]);
	const [buttonLoader, setButtonLoader] = useState(false);
	const [apiBody, setApiBody] = useState([]);
	const [initialState, setInitialState] = useState([]);
	const [selectedCards, setSelectedCards] = useState([]);
	const [deleteAll, setDeleteAll] = useState(false);
	const [unCheckedOnSelectOne, setUncheckedOnSelectOne] = useState(deleteAll);
	const [allUncheck, setAllUncheck] = useState(true);
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const [offset, setOffset] = useState(0);
	const [newArr, setnewArr] = useState([]);
	const [tagsCountTotal, setTagsCountTotal] = useState(0);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	useEffect(() => {
		if (deleteAll && unCheckedOnSelectOne) {
			let arr = [];
			tableDisplayData.map((item, index) => {
				arr.push({
					tag_id: item?.tag_id,
					is_curated: true,
				});
			});
			setSelectedCards([...arr]);
		} else {
			if (offset > 0) {
				setSelectedCards([...selectedCards, ...newArr]);
			} else {
				setSelectedCards([...newArr]);
			}
		}
	}, [tableDisplayData]);

	const checkChange = () => {
		return (
			JSON.stringify(selectedCards.sort((a, b) => (a.tag_id > b.tag_id ? 1 : -1))) ===
			JSON.stringify(initialState.sort((a, b) => (a.tag_id > b.tag_id ? 1 : -1)))
		);
	};

	useEffect(() => {
		if (checkChange()) {
			setDisableSave(false);
		} else {
			if (JSON.stringify(selectedCards.sort()) !== JSON.stringify(initialState.sort())) {
				setDisableSave(true);
			}
		}
	}, [selectedCards, initialState]);

	const onSelectingCards = (card) => {
		const copyOfSelectedCards = [...selectedCards];
		if (card) {
			const filteredCards = copyOfSelectedCards.filter((cardItem) => {
				return cardItem.tag_id === card.tag_id;
			});
			if (filteredCards.length === 0) {
				setSelectedCards([...copyOfSelectedCards, card]);
			} else {
				const index = copyOfSelectedCards.indexOf(filteredCards[0]);
				if (index >= 0) {
					copyOfSelectedCards.splice(index, 1);
					setSelectedCards(copyOfSelectedCards);
				}
			}
		}
	};

	const selectToDeleteAll = (e) => {
		if (e.target.checked) {
			setDeleteAll(true);
			setUncheckedOnSelectOne(true);
			let arr = [];
			tableDisplayData.map((item, index) => {
				arr.push({
					tag_id: item?.tag_id,
					is_curated: true,
				});
			});
			setSelectedCards([...arr]);
		} else {
			setAllUncheck(false);
			setDeleteAll(false);
			setUncheckedOnSelectOne(false);
			setSelectedCards([]);
		}
	};

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	const toggleDeleteConfirmation = (val) => {
		setOpenPopup(val);
	};

	const handleDelete = async () => {
		try {
			setButtonLoader(true);
			const response = await putCommonMethod(
				`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.PROVIDER_MARK}`,
				{
					provider_ids: selectedService,
				},
			);

			selectedService?.length
				? setToastInfo(true, false, CONSTANTS.SERVICE_UPDATED)
				: setToastInfo(true, false, CONSTANTS.SERVICE_UPDATED_UNMARK);
			setNewServices([...selectedService]);
		} catch (e) {
			setToastInfo(true, true, GLOBAL_CONSTANT.ERROR_MESSAGE);
		}
		setDisableSave(false);
		setButtonLoader(false);
		setProviderName([]);
		setSelectedCards([]);
		setDeleteAll(false);
		setUncheckedOnSelectOne(false);
		toggleDeleteConfirmation(false);
	};

	const SaveTags = async () => {
		try {
			setButtonLoader(true);
			let arr = [...selectedCards];
			if (!deleteAll) {
				const results = initialState.filter(
					({ tag_id: id1 }) => !selectedCards.some(({ tag_id: id2 }) => id2 === id1),
				);
				if (results && results?.length) {
					results?.map((item) => {
						arr.push({
							tag_id: item?.tag_id,
							is_curated: !item.is_curated,
						});
					});
				}
			}
			const response = await putCommonMethod(
				`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.UPDATE_TAGS}`,
				arr,
			);
			setToastInfo(true, false, CONSTANTS.TAG_SUCCESS_MESSAGE);
		} catch (e) {
			setToastInfo(true, true, GLOBAL_CONSTANT.ERROR_MESSAGE);
		} finally {
			setSelectedCards([]);
			setInitialState([]);
			setDisableSave(false);
			setButtonLoader(false);
			setProviderName([]);
			setDeleteAll(false);
			setAllUncheck(true);
			setUncheckedOnSelectOne(false);
			toggleDeleteConfirmation(false);
			offset > 0 ? setOffset(0) : setOffset((x) => x - 1);
			setTableDisplayData([]);
		}
	};

	const handleTabChange = (tabId) => {
		setSelectedCards([]);
		setInitialState([]);
		setDisableSave(false);
		setButtonLoader(false);
		setProviderName([]);
		setDeleteAll(false);
		setAllUncheck(true);
		setUncheckedOnSelectOne(false);
		toggleDeleteConfirmation(false);
		offset > 0 ? setOffset(0) : setOffset((x) => x - 1);
		setTableDisplayData([]);
		setActiveTabId(tabId);
	};

	const handleSave = async () => {
		toggleDeleteConfirmation(true);
	};

	const getPopupMessage = () => {
		if (activeTabId === 0) {
			if (selectedService?.length) {
				return `Are you sure you want to mark ${providerName.join(
					', ',
				)} as a free streaming ${
					selectedService?.length === 1 ? 'provider' : 'providers'
				}?`;
			} else {
				return CONSTANTS.UNMARK;
			}
		} else {
			return `Are you sure you want to add ${selectedCards?.length} ${
				selectedCards?.length > 1 ? 'tags' : 'tag'
			} to be associated with QEWD archived lists?`;
		}
	};

	return (
		<div className="settings-screen-container">
			<div className="settings-header-parent-block">
				<div className="settings-header-block">
					<h1>{CONSTANTS.HEADING}</h1>
				</div>
				<div className="button-wrapper">
					<IconTextButton
						buttonText={activeTabId === 0 ? 'Save' : 'Save Tags'}
						handleClick={handleSave}
						disabled={!disableSave}
						settingsScreen={true}
					/>
				</div>
			</div>
			<div className="content-container">
				<LeftPanel
					activeTabId={activeTabId}
					handleTabChange={handleTabChange}
					tagsCountTotal={activeTabId === 1 ? tagsCountTotal : null}
				/>
				{activeTabId === 0 && (
					<RightPanel
						setDisableSave={setDisableSave}
						selectedService={selectedService}
						setSelectedService={setSelectedService}
						setNewServices={setNewServices}
						newServices={newServices}
						setProviderName={setProviderName}
					/>
				)}
				{activeTabId === 1 && (
					<TagContainer
						setInitialState={setInitialState}
						setToastInfo={setToastInfo}
						selectedCards={selectedCards}
						setUncheckedOnSelectOne={setUncheckedOnSelectOne}
						deleteAll={deleteAll}
						unCheckedOnSelectOne={unCheckedOnSelectOne}
						setSelectedCards={setSelectedCards}
						onSelectingCards={onSelectingCards}
						selectToDeleteAll={selectToDeleteAll}
						tableDisplayData={tableDisplayData}
						setTableDisplayData={setTableDisplayData}
						allUncheck={allUncheck}
						setAllUncheck={setAllUncheck}
						offset={offset}
						setOffset={setOffset}
						setDeleteAll={setDeleteAll}
						initialState={initialState}
						newArr={newArr}
						setnewArr={setnewArr}
						setTagsCountTotal={setTagsCountTotal}
					/>
				)}
			</div>
			<Popup
				open={openPopup}
				closeOnDocumentClick
				onClose={() => toggleDeleteConfirmation(false)}
				contentStyle={settingConfirmationStyle}>
				<DeleteConfirmation
					message={activeTabId === 0 ? getPopupMessage() : CONSTANTS.TAG_CONFIRMATION}
					onClose={() => toggleDeleteConfirmation(false)}
					onDelete={activeTabId === 0 ? handleDelete : SaveTags}
					buttonText={'Yes'}
					loading={buttonLoader}
				/>
			</Popup>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

export default SettingsScreen;
