import React, { useEffect } from 'react';
import Popup from 'reactjs-popup';
import { previewPopupStyle } from '../../common/constants/PopupStyles';
import PreviewImage from 'src/app/assets/images/preview3.jpg';
import { useSelector } from 'react-redux';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';
import YourQewdDigest from 'src/app/assets/images/your-qewd-digest.png';
import Back from 'src/app/assets/images/long-arrow - Left.svg';
import Share from 'src/app/assets/images/Share.svg';
import BigCard from '../BigCard';
import 'animate.css';
import './styles.scss';
import moment from 'moment';
import { PREVIEW_CONST } from './constant';

export const PreviewPopup = ({ isOpen, closeModal, descData }) => {
	const thumbnails = useSelector(({ qewdDigest }) => qewdDigest.thumbnails);

	const onScroll = (e) => {
		const container = document.querySelector('.preview-popup-content-container');
		container.style.background = `rgba(0,0,0, ${e.target.scrollTop * 0.002})`;
	};

	useEffect(() => {
		setTimeout(() => {
			const element = document.querySelector('.preview-popup-content-container');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	}, [isOpen]);

	return (
		<Popup
			className="preview-popup"
			open={isOpen}
			closeOnDocumentClick
			onClose={closeModal}
			contentStyle={previewPopupStyle}>
			<div className="preview-content" onScroll={onScroll}>
				<div className="heading">{process.env.REACT_APP_QEWD_DIGEST_TITLE} - Preview</div>
				<div onClick={closeModal}>
					<img src={CrossIcon} alt="close" className="close" />
				</div>
				<div className="fixed-preview-image">
					<img src={PreviewImage} alt="preview" />
				</div>
				<div className="content">
					<PreviewScreenContent thumbnails={thumbnails} descData={descData} />
				</div>
			</div>
		</Popup>
	);
};

const PreviewScreenContent = ({ thumbnails, descData }) => {
	return (
		<div className="preview-popup-content-container">
			<div className="icon-header">
				<img src={Back} alt="share" />
				<img src={Share} alt="share" />
			</div>
			<div className="sub-header">
				<div className="sub-heading">
					<img src={YourQewdDigest} alt={`${process.env.REACT_APP_QEWD_DIGEST_TITLE}`} />
				</div>
				<div className="sub-heading-description-container">
					<div className="tag">{moment().format('DD MMM')}</div>
					<p className="sub-heading-description">{descData?.description}</p>
				</div>
			</div>
			<div className="cards">
				{thumbnails &&
					thumbnails.length > 0 &&
					thumbnails.map((item, index) => {
						return (
							<BigCard
								key={item.id}
								cardDetails={{
									...item,
									horizontal_thumbnail: item.thumbnail,
								}}
								index={index}
								onSelection={() => {}}
								id={item.id}
								toggleDeleteConfirmation={() => {}}
							/>
						);
					})}
			</div>
		</div>
	);
};
