import React, { useState, useEffect } from "react";
import "./styles.scss";
import { IconList } from "./constants";

const ThumbnailPicker = ({ handleIconSelection, defaultSelected }) => {
  const [selectedId, setSelectedId] = useState(defaultSelected?.id);
  const handleIconSelect = (icon) => {
    // checks if clicked on same item
    if (selectedId !== icon.id) {
      setSelectedId(icon.id);
      handleIconSelection(icon);
    } else {
      setSelectedId(null);
      handleIconSelection({}, false);
    }
  };
  useEffect(() => {
    setSelectedId(defaultSelected?.id);
  }, [defaultSelected]);

  return (
    <ul className={`picker-list`}>
      {IconList.map((icon) => {
        return (
          <li onClick={() => handleIconSelect(icon)} key={icon.id}>
            <span
              className={`${
                selectedId === icon.id ? "active-span" : "inactive-span"
              }`}
            >
              <img src={icon.img} alt="thumbnail" />
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default ThumbnailPicker;
