export const popupContentStyleDeleteion = {
	width: 327,
	height: 176,
	background: '#2A2A31',
	marginTop: 300,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
};

export const popupContentStylePublish = {
	width: 440,
	height: 340,
	background: '#2A2A31',
	marginTop: 300,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
};

export const DESC_MESSAGE = 'Description Updated';
export const ERROR_MESSAGE = 'SOMETHING WENT WRONG, PLEASE TRY AGAIN.';
