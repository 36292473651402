import React from 'react';
import './styles.scss';

const Grid = ({ children, fromCategoryScreen = false, fromSearch = false, innerRef }) => {
	return (
		<div
			ref={fromCategoryScreen ? innerRef : null}
			className={`${
				fromCategoryScreen
					? 'content-grid-category'
					: fromSearch
					? 'content-grid-search'
					: 'content-grid'
			}`}>
			{children}
		</div>
	);
};

export default Grid;
