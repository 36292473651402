import {
	GET_SUB_CATEGORY_DATA_INITIAL,
	GET_SUB_CATEGORY_DATA,
} from 'src/app/pages/Dashboard/actionTypes';
import LIVE_BUCKET_INITIAL_STATE from './initialState';

const liveBuckReducer = (state = LIVE_BUCKET_INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_SUB_CATEGORY_DATA_INITIAL:
			return {
				...state,
				sub_category: action.payload || [],
			};
		case GET_SUB_CATEGORY_DATA:
			return {
				...state,
				sub_category: [...state.sub_category, ...action.payload],
			};
		default:
			return state;
	}
};

export default liveBuckReducer;
