export const TABS = [
	{ id: 'all', label: 'All' },
	{ id: 'movie', label: 'Movies' },
	{ id: 'show', label: 'Shows' },
	{ id: 'short_form', label: 'Short Forms' },
];

export const DROP_DOWN_ITEMS = [
	{ key: 'recently-added', name: 'Recently Added' },
	{ key: 'title-a-z', name: 'Title A-Z' },
	{ key: 'title-z-a', name: 'Title Z-A' },
];

export const HEADING = `${process.env.REACT_APP_QEWD_DIGEST_TITLE}`;
export const NO_RESULT_FOUND_MESSAGE = 'No Results Found';
