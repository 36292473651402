const POPUP_CONSTANT = {
	EDIT_HEADING: 'Edit Bucket',
	CREATE_HEADING: 'Create Bucket',
};

export const HOME_STATUS = [
	{
		id: 'home-draft',
		value: 'Draft',
		name: 'Home',
		screen_id: 1,
	},
	{
		id: 'home-published',
		value: 'Published',
		name: 'Home',
		screen_id: 1,
	},
	{
		id: 'home-unpublished',
		value: 'Unpublished',
		name: 'Home',
		screen_id: 1,
	},
	{
		id: 'home-archived',
		value: 'Archived',
		name: 'Home',
		screen_id: 1,
	},
];

export const DISCOVER_STATUS = [
	{
		id: 'discover-draft',
		value: 'Draft',
		name: 'Discover',
		screen_id: 2,
	},
	{
		id: 'discover-published',
		value: 'Published',
		name: 'Discover',
		screen_id: 2,
	},
	{
		id: 'discover-unpublished',
		value: 'Unpublished',
		name: 'Discover',
		screen_id: 2,
	},
	{
		id: 'discover-archived',
		value: 'Archived',
		name: 'Discover',
		screen_id: 2,
	},
];

export const LABELS = {
	SCREEN_TO: 'Screen To',
	HOME: 'Home',
	DISCOVER: 'Discover',
};

export const PUBLISH_DESC = 'Publish description';

export const BUCKET_CONTENT_TYPE = [
	'live_stream_epg',

	'live_stream',

	'live_channel',

	'live_category',

	'movie_show_video',

	'movie',

	'show',

	'video',
];

export const SCREEN_CONTENT_MAPPER = {
	movie_show_video: {
		general_category_id: 0,
		name: 'All(Movies & Series)',
		slug: 'movie_show_video',
	},
	movie: { general_category_id: 1, name: 'Movies', slug: 'movie' },
	show: { general_category_id: 2, name: 'TV Series', slug: 'show' },
	video: { general_category_id: 3, name: 'Short Form', slug: 'video' },
	live_channel: {
		general_category_id: 4,
		name: 'Live Subcategory',
		slug: 'live_channel',
	},
	live_stream: { general_category_id: 5, name: 'Live Channel', slug: 'live_stream' },
};

export const RANKED_BUCK = 'Show bucket content in ranked design/order';

export default POPUP_CONSTANT;
