import React from 'react';
import { SkeletonLoader } from 'src/app/components/SkeletonLoader';
import {
	ROW_LOADING_ANIMATION_WIDTH,
	ROW_LOADING_ANIMATION_HEIGHT,
} from '../../../../../common/constants/BucketListingConstant';
import './styles.scss';

export const EmptyState = ({
	index,
	columns,
	renderColumns = 2,
	fromReportedScreen = false,
}) => {
	return (
		<tr
			id="empty-state-loading"
			className={`${(index + 1) % 2 === 0 ? 'even' : 'odd'} ${
				fromReportedScreen ? 'reported-loader' : ''
			}`}>
			{columns.map(({ key }, index) => {
				return index <= columns.length && <EmptyColumn key={key} len={columns.length} />;
			})}
		</tr>
	);
};

const EmptyColumn = ({ len }) => {
	return (
		<td
			className="empty-state-loader-td"
			style={{ paddingTop: 32, width: `${100 / len}%`, paddingLeft: 10 }}>
			<SkeletonLoader
				width={ROW_LOADING_ANIMATION_WIDTH}
				height={ROW_LOADING_ANIMATION_HEIGHT}
			/>
		</td>
	);
};
