import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from './constants';
import { AppButton } from 'src/app/components/AppButton';
import AddImage from 'src/app/assets/images/Add.svg';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import TableContainer from './component/TableContainer';
import './styles.scss';
import { Toast } from 'src/app/components/Toast';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import { deleteData, postCommonMethod } from 'src/app/pages/Dashboard/Service';
import { EDIT_DATA_ON_LIVE_CHANNEL_TABLE } from 'src/app/pages/Dashboard/actionTypes';
import { editTableData } from 'src/app/pages/Dashboard/actionCreators';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { RECORD_TO_FETCH } from 'src/app/common/constants/BucketListingConstant';
import { useHistory } from 'react-router';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import ScrollToTop from 'src/app/components/atoms/ScrollToTop';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import BreadCrumbs from 'src/app/components/BreadCrumbs';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';

export const LiveChannels = ({ onPopup = false, channelId, onClosePopup, name }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const tableData = useSelector((state) => state.liveChannel?.liveChannels);
	const [isChecked, setChecked] = useState(false);
	const [offset, setOffset] = useState(0);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [countMultipleCheck, setCountMultipleCheck] = useState(0);
	const [countOnAllUnselected, setCountOnAllUnselected] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [loading, setLoading] = useState(false);
	const [liveChannelId, setLiveChannelId] = useState(null);
	const searchRef = useRef('');
	const [apiBody, setApiBody] = useState([]);
	const [addChannelCategoryPopup, setAddChannelCategoryPopup] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [addButtonLoader, setAddButtonLoader] = useState(false);
	const [createPopup, setCreatePopup] = useState(false);
	const [categoryOffset, setCategoryOffset] = useState(0);
	const [isDisable, setDisable] = useState(false);
	const [bucketName, setBucketName] = useState('');
	const [selectCategoryToAddChannel, setSelectCategoryToAddChannel] = useState(null);
	const [prevBucketName, setPrevBucketName] = useState('');
	const [prevSelectCategoryToAddChannel, setPrevSelectCategoryToAddChannel] =
		useState(null);
	const [iconKey, setIconKey] = useState(null);
	const [counterToShowDelete, setCounterToShowDelete] = useState(0);
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [defaultDeleteMessage, setdefaultDeleteMessage] = useState(true);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [channelName, setChannelName] = useState('');
	const [deleteChannelId, setDeleteChannelId] = useState(null);
	const selectedCategory = useRef(null);
	const [isEditPopup, setIsEditPopup] = useState(false);
	const [category, setCategory] = useState([]);
	const debouncedValue = useDebounce(searchText, 500);
	const [totalCount, setTotalCount] = useState(-1);

	useEffect(() => {
		if (countMultipleCheck < 1) {
			setChecked(false);
			setIsAllChecked(false);
		} else {
			setChecked(true);
			setIsAllChecked(true);
		}
	}, [countMultipleCheck, setCountMultipleCheck]);

	const handleAddChannelCategory = (val) => {
		setDisable(false);
		setAddButtonLoader(false);
		setAddChannelCategoryPopup(val);
		setTimeout(() => setCategoryOffset(0), 400);
	};

	useEffect(() => {
		if (apiBody.length < 1) {
			setChecked(false);
		}
	}, [apiBody]);

	useEffect(() => {
		counterToShowDelete > 0 ? setShowDeleteButton(true) : setShowDeleteButton(false);
	}, [counterToShowDelete]);

	const handleViewChannelCategory = () => {
		history.push(`/live/category`);
	};

	const handleCreate = (val) => {
		setDisable(false);
		setAddButtonLoader(false);
		setIconKey(null);
		setBucketName('');
		setCategory([]);
		setSelectCategoryToAddChannel(null);
		if (val) {
			setTimeout(() => {
				const element = document.querySelector('.select-popup-error-wrapper');
				if (element) {
					element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
				}
			}, 500);
		}
		setCreatePopup(val);
		setIsEditPopup(false);
	};

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#scrollableDivLiveChannel');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const handleAddCategoryToBucket = async () => {
		try {
			const contentIds = [];
			apiBody.map((item) => {
				contentIds.push(item.live_channel_id);
			});
			const resp = await postCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_LIVE_CHANNEL_IN_BUCKET}`,
				{
					content_ids: contentIds,
					bucket_id: channelId,
					content_type: 'live_channel',
				},
			);
			if (resp) {
				setCountMultipleCheck(0);
				setCountOnAllUnselected([]);
				setIsAllChecked(false);
				setCounterToShowDelete(0);
				setChecked(false);
				setApiBody([]);
				setToastInfo(true, false, resp.data.message);
			}
		} catch (e) {
			setToastInfo(
				true,
				true,
				e.response.data.message === CONSTANTS.LIVE_MSSG.live_stream_mssg
					? CONSTANTS.LIVE_MSSG.live_channel_mssg
					: e.response.data.message,
			);
		}
	};

	const handleDelete = () => {
		setdefaultDeleteMessage(false);
		setShowDeletePopup(true);
	};

	const closeDeleteConfirmationModal = () => {
		setShowDeletePopup(false);
		setdefaultDeleteMessage(true);
	};

	const onDeleteChannel = async () => {
		setAddButtonLoader(true);
		let apiData = [];
		if (deleteChannelId) {
			apiData.push(deleteChannelId);
		} else {
			apiBody.map((item) => {
				apiData.push(item.live_channel_id);
			});
		}
		await deleteChannels(apiData);
	};

	const removeDeletedItemFromRedux = (form, apiData) => {
		editTableData(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CHANNEL_URL}`,
			form,
			({ data }) => {
				dispatch({
					type: EDIT_DATA_ON_LIVE_CHANNEL_TABLE,
					payload: {
						apiData,
					},
				});
			},
		);
	};

	const deleteChannels = async (apiData) => {
		try {
			const res = await deleteData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.DELETE_LIVE_CHANNEL}`,
				{
					live_channel_ids: apiData,
				},
			);
			if (res.status) {
				let form = {
					offset: tableData?.length,
					perPage: RECORD_TO_FETCH,
					orderBy: GLOBAL_CONST.ORDER_BY.createdAt,
					orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
				};
				removeDeletedItemFromRedux(form, apiData);
				setCountMultipleCheck(0);
				setCountOnAllUnselected([]);
				setIsAllChecked(false);
				setCounterToShowDelete(0);
				setToastInfo(true, false, res.message);
				if (apiBody.length === tableData?.length && !deleteChannelId) {
					setOffset(-1);
				}
				setApiBody([]);
			}
		} catch (error) {
			setToastInfo(true, true, error.response.message);
		}
		setAddButtonLoader(false);
		setShowDeletePopup(false);
		setChannelName('');
		setdefaultDeleteMessage(true);
		setDeleteChannelId(null);
	};

	const handleChange = (e) => {
		setOffset(0);
		setSearchText(e.target.value);
	};
	const emptySearchBox = () => {
		setOffset(0);
		setSearchText('');
		searchRef.current.value = '';
	};

	const handleLiveChannelId = (id) => {
		setLiveChannelId(id);
	};

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	return (
		<div className="live-channel-right-container">
			<div className="live-channel-header-parent-block">
				<div className="live-header-block-channel">
					<div className="live-header-block-wrapper">
						<h1>{CONSTANTS.LIVE_CHANNEL_HEADING}</h1>
						{totalCount > -1 ? (
							<div className="count-header-wrapper">&nbsp;( {totalCount} )</div>
						) : null}
					</div>
					{onPopup && (
						<img
							src={CrossIcon}
							alt="close"
							className="cross-icon"
							onClick={() => {
								onClosePopup();
							}}
						/>
					)}
				</div>
				{!onPopup && <BreadCrumbs value="All Sub-Categories" />}
				<div className="live-search-box-container">
					<div className="live-search-with-cancel">
						<DashboardSearchBox
							handleChange={handleChange}
							className={
								onPopup ? 'live-stream-in-channel-search' : 'live-channel-search'
							}
							ref={searchRef}
							placeholder={CONSTANTS.SEARCH_BOX_PLACEHOLDER}
						/>
						{searchText !== '' && (
							<Button
								label="Cancel"
								labelClassName="cancel-button-label"
								className="cancel-button"
								onClick={emptySearchBox}
							/>
						)}
					</div>
					<div className="live-header-buttons">
						{showDeleteButton && (
							<Tooltip
								overlay={
									onPopup ? CONSTANTS.ROW_TOOLTIP.add : CONSTANTS.ROW_TOOLTIP.delete
								}
								placement="bottom"
								overlayInnerStyle={GLOBAL_CONST.overlayStyle}
								align={{
									offset: [-8, -2],
								}}>
								<div>
									<Button
										imageInfo={{
											src: onPopup ? AddImage : DeleteBucket,
										}}
										className="live-delete-button"
										onClick={onPopup ? handleAddCategoryToBucket : handleDelete}
									/>
								</div>
							</Tooltip>
						)}

						{!onPopup && (
							<Tooltip
								overlay={CONSTANTS.ROW_TOOLTIP.create}
								placement="bottom"
								overlayInnerStyle={GLOBAL_CONST.overlayStyle}
								align={{
									offset: [-8, -2],
								}}>
								<div>
									<Button
										imageInfo={{
											src: AddImage,
										}}
										className="live-add-button"
										onClick={() => handleCreate(true)}
									/>
								</div>
							</Tooltip>
						)}
						{!onPopup && (
							<AppButton
								label="Add to Category"
								className="live-app-button"
								innerClass="live-app-button-text"
								isDisabled={!isChecked}
								onClick={() => handleAddChannelCategory(true)}
								autoCaptialize={false}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="content-block">
				<TableContainer
					offset={offset}
					setOffset={setOffset}
					loading={loading}
					setLoading={setLoading}
					setChecked={setChecked}
					isAllChecked={isAllChecked}
					liveChannelId={liveChannelId}
					setIsAllChecked={setIsAllChecked}
					countMultipleCheck={countMultipleCheck}
					setCountMultipleCheck={setCountMultipleCheck}
					countOnAllUnselected={countOnAllUnselected}
					setCountOnAllUnselected={setCountOnAllUnselected}
					handleLiveChannelId={handleLiveChannelId}
					apiBody={apiBody}
					setApiBody={setApiBody}
					openTooltip={openTooltip}
					setOpenTooltip={setOpenTooltip}
					addChannelCategoryPopup={addChannelCategoryPopup}
					handleAddChannelCategory={handleAddChannelCategory}
					setToastInfo={setToastInfo}
					addButtonLoader={addButtonLoader}
					setAddButtonLoader={setAddButtonLoader}
					createPopup={createPopup}
					handleCreate={handleCreate}
					categoryOffset={categoryOffset}
					setCategoryOffset={setCategoryOffset}
					isDisable={isDisable}
					setDisable={setDisable}
					bucketName={bucketName}
					setBucketName={setBucketName}
					selectCategoryToAddChannel={selectCategoryToAddChannel}
					setSelectCategoryToAddChannel={setSelectCategoryToAddChannel}
					iconKey={iconKey}
					setIconKey={setIconKey}
					setCounterToShowDelete={setCounterToShowDelete}
					defaultDeleteMessage={defaultDeleteMessage}
					counterToShowDelete={counterToShowDelete}
					showDeletePopup={showDeletePopup}
					setShowDeletePopup={setShowDeletePopup}
					channelName={channelName}
					setChannelName={setChannelName}
					setDeleteChannelId={setDeleteChannelId}
					closeDeleteConfirmationModal={closeDeleteConfirmationModal}
					onDeleteChannel={onDeleteChannel}
					selectedCategory={selectedCategory}
					isEditPopup={isEditPopup}
					setIsEditPopup={setIsEditPopup}
					category={category}
					setCategory={setCategory}
					prevBucketName={prevBucketName}
					prevSelectCategoryToAddChannel={prevSelectCategoryToAddChannel}
					setPrevBucketName={setPrevBucketName}
					setPrevSelectCategoryToAddChannel={setPrevSelectCategoryToAddChannel}
					debouncedValue={debouncedValue}
					setTotalCount={setTotalCount}
					onPopup={onPopup}
				/>
			</div>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
			{tableData?.length > 20 && <ScrollToTop handleScrollToTop={handleScrollToTop} />}
		</div>
	);
};

export default LiveChannels;
