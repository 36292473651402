import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import { SIDEBAR_TABS } from '../../constants';
import avatar from 'src/app/assets/images/Avatar.svg';
import NavigationLink from '../../../../components/atoms/NavigationLink';
import { toggleSidebarExpansion } from '../../actionCreators';
import { ActiveContext } from '../../../../contexts/ActiveRouteProvider';
import { logoutModal } from 'src/app/common/constants/AppConstants';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import {
	getItemFromLocalStorage,
	removeItemFromLocalStorage,
} from 'src/app/common/utils/localStorageUtils';
import { ROOT_URL, GET_USER_BY_PHONE } from 'src/app/common/constants/AppConstants';
import { getUserInfo } from 'src/app/pages/Dashboard/Service';

const SideBar = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const isExpandedSelector = useSelector((state) => state.sidebar.expanded);

	const [isExpanded, setIsExpanded] = React.useState(isExpandedSelector || false);
	const [buttonLoader, setButtonLoader] = useState(false);
	const [isAvatarPresent, setAvatarPresent] = useState(false);
	const [avatarIcon, setAvatarIcon] = useState(null);

	useEffect(() => {
		if (!avatarIcon) {
			const avatar = getItemFromLocalStorage('avatar');
			if (avatar && avatar !== 'NULL') {
				setAvatarPresent(true);
				setAvatarIcon(avatar);
			} else {
				const admin_phone = getItemFromLocalStorage('admin_phone');
				if (admin_phone) {
					fetchAvatarIcon(admin_phone);
				} else {
					setAvatarPresent(false);
					setAvatarIcon('A');
				}
			}
		}
	}, []);

	const fetchAvatarIcon = async (admin_phone) => {
		try {
			const res = await getUserInfo(`${ROOT_URL}${GET_USER_BY_PHONE}`, {
				phone_number: admin_phone,
			});
			if (res?.data.avatar && res?.data.avatar !== 'NULL') {
				setAvatarPresent(true);
				setAvatarIcon(res?.data?.avatar);
			} else {
				setAvatarPresent(false);
				setAvatarIcon(
					(res?.data?.first_name?.charAt(0)?.toUpperCase() || '') +
						(res?.data?.last_name?.charAt(0)?.toUpperCase() || ''),
				);
			}
		} catch (e) {
			setAvatarIcon('A');
			console.log(e);
		}
	};

	const addDefaultSource = (ev) => {
		setAvatarPresent(false);
		setAvatarIcon('A');
	};

	const toggleExpansion = () => {
		dispatch(toggleSidebarExpansion(!isExpanded));
		setIsExpanded(!isExpanded);
	};

	const toggleExpansionOnIconClick = (value = true) => {
		dispatch(toggleSidebarExpansion(value));
		setIsExpanded(value);
	};

	const { activeRoute, setActive } = React.useContext(ActiveContext);

	const activeTab = activeRoute.split('/')[0];
	const isActiveRoute = (link) => {
		return activeRoute.split('/')[0] === link;
	};
	const hasSublinks = (tab) => {
		if (tab?.subLinks && Array.isArray(tab.subLinks) && tab.subLinks.length > 0)
			return true;
		else return false;
	};

	const getMenuListClasses = (tab, index = 0) => {
		let menuListClasses = 'menuListNotExpanded';
		if (isExpanded) {
			menuListClasses = 'menuListExpanded';
			if (isActiveRoute(tab.link)) {
				if (hasSublinks(tab)) menuListClasses += ' height-fit-content';
				else menuListClasses += ' height-50';
			}
			if (index === 0) menuListClasses += ' manage-left';
		}
		return menuListClasses;
	};

	const handleLogout = async () => {
		setButtonLoader(true);
		await Auth.signOut();
		setButtonLoader(false);
		removeItemFromLocalStorage('admin_phone');
		history.push('/');
	};

	const showLogoutAlert = () => {
		confirmAlert({
			overlayClassName: 'overlay-custom-class-name',
			closeOnEscape: true,
			closeOnClickOutside: true,
			customUI: ({ onClose }) => {
				return (
					<div className="alert-confirmation">
						<DeleteConfirmation
							onClose={onClose}
							onDelete={() => {
								handleLogout();
								onClose();
							}}
							loading={buttonLoader}
							message={logoutModal.message}
							buttonText={logoutModal.yes}
						/>
					</div>
				);
			},
		});
	};

	const renderNavItems = (tab) => {
		return (
			<ul className="sub-link-list">
				{tab.subLinks?.map((item) => {
					return (
						<li key={item?.label} className="sub-link-list-item">
							<NavLink
								to={`/${tab.link}/${item.link}`}
								activeClassName={'active-sublink'}
								onClick={() => {
									setActive(`${tab.link}/${item.link}`);
								}}>
								<p className="sub-link-text">{item.label}</p>
							</NavLink>
						</li>
					);
				})}
			</ul>
		);
	};

	return (
		<div className="sidebar-container">
			<span className="sidebar-expansion-span" onClick={toggleExpansion}>
				<div
					className={`icon-1 ${isExpanded ? 'firstDivHamburger' : ''}`}
					id="firstDivHamburger"></div>
				<div
					className={`icon-2 ${isExpanded ? 'thirdDivHamburger' : ''}`}
					id="secondDivHamburger"></div>
				<div
					className={`icon-3 ${isExpanded ? 'secondDivHamburger' : ''}`}
					id="thirdDivHamburger"></div>
			</span>

			<div className={`menuList nav-1 ${getMenuListClasses(SIDEBAR_TABS[0], 0)}`}>
				<NavigationLink
					tabData={SIDEBAR_TABS[0]}
					index={0}
					isExpanded={isExpanded}
					expansion={toggleExpansionOnIconClick}
				/>
			</div>

			<ul className={`sidebar-menu  is-scrollable`}>
				{SIDEBAR_TABS?.map((tab, index) => {
					if (index !== 0) {
						return (
							<li
								key={tab.id}
								className={`menuList nav-${tab.id} ${getMenuListClasses(tab, index)}`}>
								<NavigationLink
									tabData={tab}
									index={index}
									isExpanded={isExpanded}
									expansion={toggleExpansionOnIconClick}
								/>
								{isExpanded &&
									isActiveRoute(tab.link) &&
									hasSublinks(tab) &&
									renderNavItems(tab)}
							</li>
						);
					}
				})}
			</ul>
			<div className="profile-avatar" onClick={showLogoutAlert}>
				{isAvatarPresent ? (
					<img src={avatarIcon} alt="profile" onError={addDefaultSource} />
				) : (
					<div className={`avatar-initials ${avatarIcon ? '' : 'empty-avatar'}`}>
						<span>{avatarIcon}</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default SideBar;
