import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { getRowClassName } from 'src/app/common/utils/methods';
import EditImage from 'src/app/assets/images/EditActionButton.svg';
import Tick from 'src/app/assets/images/PublishTick.svg';
import CameraEditIcon from 'src/app/assets/images/CameraEditIcon.svg';
import { getProfileInitials, getProfileBackground } from 'src/app/common/utils/methods';

export const Row = ({ data, updatePopularity, handleEditPopup, setToastInfo }) => {
	const [isAvatar, setIsAvatar] = useState(false);
	const [showTextField, setShowTextField] = useState(false);
	const inputRef = useRef(null);
	const [showLoader, setShowLoader] = useState(false);
	const [topContent, setTopContent] = useState([]);
	const [icon, setIcon] = useState(false);
	const [value, setValue] = useState(data?.popularity.toFixed(2) || 0);
	const [popularity, setPopularity] = useState(data?.popularity.toFixed(2) || 0);

	useEffect(() => {
		if (data?.thumbnail) {
			setIsAvatar(true);
		}
		const arr = [];
		const top_content_data = data.top_content ? JSON.parse(data?.top_content) : [];
		top_content_data.map((item) => {
			arr.push(item?.title);
		});
		setTopContent(arr);
	}, [data]);

	const addDefaultSrc = (ev) => {
		setIsAvatar(false);
		ev.target.className = 'cast-icon-error';
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			editInviteCount();
		}
	};

	const onEditIconPress = () => {
		setShowTextField(true);
	};

	const editInviteCount = async () => {
		if (inputRef.current.value > 0 && Number(inputRef.current.value) !== popularity) {
			const newPop = parseFloat(inputRef.current.value).toFixed(2);
			if (popularity !== newPop) {
				setShowLoader(true);
				const updatePop = await updatePopularity(
					data?.cast_id,
					newPop,
					data?.thumbnail,
					data?.name,
					true,
				);
				if (updatePop) {
					setPopularity(newPop);
				}
			}
			setShowTextField(false);
			setShowLoader(false);
		} else if (inputRef.current.value < 1) {
			setToastInfo(true, true, 'Popularity must be greater than 0');
		}
	};

	return (
		<tr
			className={`${getRowClassName(data?.index)} `}
			onMouseEnter={() => setIcon(true)}
			onMouseLeave={() => setIcon(false)}>
			<td className="cast-name-row">
				<div className="cast-name-wrapper">
					{isAvatar ? (
						<>
							<img
								className={`image-profile ${data?.thumbnail ? '' : 'cast-icon-error'}`}
								alt="cast-profile"
								src={data?.thumbnail}
								onError={addDefaultSrc}
								onClick={() => {
									handleEditPopup(data?.cast_id, data?.name, popularity, data?.thumbnail);
								}}
							/>
							{icon && (
								<div className="edit-cast-wrapper">
									<img className="edit-cast-profile" src={CameraEditIcon} />
								</div>
							)}
						</>
					) : (
						<>
							<div
								className={`image-profile cast-icon-error`}
								style={{ background: `${getProfileBackground(data?.name)}` }}
								onClick={() => {
									handleEditPopup(data?.cast_id, data?.name, popularity, data?.thumbnail);
								}}>
								<span className="cast-name-error-span">
									{getProfileInitials(data?.name)}
								</span>
							</div>
							{icon && (
								<div className="edit-cast-wrapper">
									<img className="edit-cast-profile" src={CameraEditIcon} />
								</div>
							)}
						</>
					)}
					<span className="cast-name">{data?.name}</span>
				</div>
			</td>
			<td className="cast-movies-shows-row">
				<div className="cast-movies-shows">
					{topContent.length > 4
						? topContent?.slice(0, 4).join(', ')
						: topContent?.join(', ')}
				</div>
			</td>
			<td className="popularity-row">
				{!showTextField && (
					<div className="popularity-container">
						<div className="popularity-wrapper">
							<p className="cast-popularity-score">{popularity}</p>
						</div>
						{icon && (
							<span className="edit-icon" onClick={onEditIconPress}>
								<img src={EditImage} className="cast-popularity-edit-icon" alt="edit" />
							</span>
						)}
					</div>
				)}
				{showTextField && (
					<div style={{ marginLeft: 30 }}>
						<input
							ref={inputRef}
							defaultValue={!showLoader ? popularity : ''}
							className={`cast-popularity-count-input ${
								showLoader ? 'loader-input' : ''
							}`}
							type="number"
							id="popularity_score"
							name="popularity_score"
							autoFocus
							disabled={false}
							onKeyPress={(e) => handleKeyPress(e)}
						/>
						{!showLoader && (
							<span className="edit-icon" onClick={editInviteCount}>
								<img
									src={Tick}
									className="cast-popularity-edit-icon"
									alt="Update Popularity"
								/>
							</span>
						)}
					</div>
				)}
			</td>
		</tr>
	);
};

export default Row;
