import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import IndividualTabContent from '../../atoms/IndividualTabContent';

const Tab = ({
	active,
	tabs,
	handleTabChange,
	children,
	className,
	bucketCount,
	fromDigest = false,
}) => {
	const [activeTab, setActiveTab] = useState(active);

	const handleChange = (id) => {
		setActiveTab(id);
		handleTabChange(id);
	};

	return (
		<>
			<div className={className}>
				{tabs?.map((tab) => {
					return (
						<div
							key={tab?.id || tab?.key}
							className={
								tab?.id === activeTab || tab?.key === activeTab ? 'active' : 'not-active'
							}
							onClick={() => handleChange(tab?.id || tab?.key)}>
							<p>{`${tab.label} ${
								!fromDigest
									? bucketCount[tab?.id || tab?.key] > -1
										? `(${bucketCount[tab?.id || tab?.key]})`
										: ''
									: ''
							}`}</p>
						</div>
					);
				})}
				<span className="borderLine"></span>
			</div>

			{children}
		</>
	);
};

export default Tab;
