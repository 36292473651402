export const RECORD_TO_FETCH = 20;

export const CREATE_POPUP = {
	HEADING: 'Add Channel Category',
	INPUT_LABEL: 'CATEGORY NAME',
	INPUT_PLACEHOLDER: 'Enter Category Name',
	THUMBNAIL_HEADING: 'ATTACH THUMBNAIL',
	THUMBNAIL_TEXT: 'Upload Image',
	EDIT_HEADING: 'Edit Channel Category',
	UPDATE: 'Update',
	ADD: 'Add',
};
