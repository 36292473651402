import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { Button } from 'src/app/components/Button';
import { AppButton } from 'src/app/components/AppButton';
import AddImage from 'src/app/assets/images/Add.svg';
import GridContainer from './GridContainer';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import DeleteBucketActive from 'src/app/assets/images/DeleteIconActive.svg';
import { Toast } from 'src/app/components/Toast';
import Popup from 'reactjs-popup';
import { deleteConfirmationPopupStyle } from 'src/app/common/constants/PopupStyles';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { deleteData } from 'src/app/pages/Dashboard/Service';
import { editTableData } from 'src/app/pages/Dashboard/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_CATEGORY_DATA } from 'src/app/pages/Dashboard/actionTypes';
import CloseIcon from 'src/app/assets/images/TransparentClose.svg';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import ScrollToTop from 'src/app/components/atoms/ScrollToTop';
import BreadCrumbs from 'src/app/components/BreadCrumbs';
import { Bucket } from 'src/app/pages/Dashboard/Components/Bucket';

export const LiveCategory = () => {
	const history = useHistory();
	const [showCreateCategoryPopup, setShowCreateCategoryPopup] = useState(false);
	const [iconKey, setIconKey] = useState(null);
	const [categoryName, setCategoryName] = useState('');
	const [prevCategoryName, setPrevCategoryName] = useState('');
	const [buttonLoader, setButtonLoader] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [enableSelection, setEnableSelection] = useState(false);
	const [selectedCards, setSelectedCards] = useState([]);
	const [isOpenDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
	const [isEditPopup, setIsEditPopup] = useState(false);
	const dispatch = useDispatch();
	const categoryData = useSelector((state) => state.liveChannel?.liveCategories);

	const handleCreate = (val) => {
		setShowCreateCategoryPopup(val);
		setButtonLoader(false);
		setIconKey(null);
		setCategoryName('');
		setIsEditPopup(false);
	};

	const selectToDelete = () => {
		if (!enableSelection) {
			setEnableSelection(true);
		} else if (selectedCards.length === 0) {
			setToastInfo(true, true, 'PLEASE SELECT CATEGORY FIRST');
		} else if (selectedCards.length > 0) {
			setOpenDeleteConfirmation(true);
		}
	};

	const onDeleteCategory = async () => {
		setButtonLoader(true);
		let apiData = [];
		selectedCards.map((item) => {
			apiData.push(item?.id);
		});
		await deleteCategory(apiData);
	};

	const removeDeletedItemFromRedux = async (form, apiData) => {
		editTableData(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CATEGORY}`,
			form,
			({ data }) => {
				dispatch({
					type: EDIT_CATEGORY_DATA,
					payload: {
						apiData,
					},
				});
			},
		);
	};

	const deleteCategory = async (apiData) => {
		try {
			const res = await deleteData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.DELETE_CATEGORY}`,
				{
					live_category_ids: apiData,
				},
			);
			if (res.status) {
				let form = {
					offset: categoryData?.length,
					perPage: 20,
					orderBy: GLOBAL_CONST.ORDER_BY.createdAt,
					orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
				};
				await removeDeletedItemFromRedux(form, apiData);
				setToastInfo(true, false, res.message);
			}
		} catch (error) {
			setToastInfo(true, true, error.message);
		}
		onCloseDeletePopup();
		setEnableSelection(false);
		setSelectedCards([]);
	};

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#scrollableDivLiveCategory');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const onCloseDeletePopup = () => {
		setButtonLoader(false);
		setOpenDeleteConfirmation(false);
	};

	const onSelectingCards = (card) => {
		const copyOfSelectedCards = [...selectedCards];
		if (card) {
			const filteredCards = copyOfSelectedCards.filter((cardItem) => {
				return cardItem.id === card.id;
			});
			if (filteredCards.length === 0) {
				setSelectedCards([...copyOfSelectedCards, card]);
			} else {
				const index = copyOfSelectedCards.indexOf(filteredCards[0]);
				if (index >= 0) {
					copyOfSelectedCards.splice(index, 1);
					setSelectedCards(copyOfSelectedCards);
				}
			}
		}
	};

	const navigateToAllChannels = () => {
		history.push(`/live/channels`);
	};

	const unSelectAllSelectedItems = () => {
		if (selectedCards.length > 0) {
			selectedCards.forEach((card) => {
				const cardElement = document.getElementById(card.id);
				const cardContainer = document.getElementById(
					`category-grid-container-${Number(card.id)}`,
				);
				if (cardElement) {
					cardElement.checked = false;
				}
				if (cardContainer) {
					cardContainer.style.border = 'none';
				}
			});
		}
		setSelectedCards([]);
		setEnableSelection(false);
	};

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	return (
		<div className="live-category-right-container">
			<div className="live-category-header-block">
				<div className="live-category-header">
					<h1>Live Channel Categories</h1>
				</div>
				<BreadCrumbs value="Live Category" />
				<div className="live-category-header-buttons">
					<div className={`button-wrapper ${enableSelection ? 'animate-button' : ''}`}>
						<AppButton
							label="View All Channels"
							className="live-app-button"
							innerClass="live-app-button-text"
							onClick={() => navigateToAllChannels()}
							autoCaptialize={false}
						/>
						<Tooltip
							overlay="Add Live Channel Category"
							placement="bottom"
							overlayInnerStyle={GLOBAL_CONST.overlayStyle}
							align={{
								offset: [-6, -2],
							}}>
							<div>
								<Button
									imageInfo={{
										src: AddImage,
									}}
									className="live-category-add-button"
									onClick={() => handleCreate(true)}
								/>
							</div>
						</Tooltip>

						<Button
							imageInfo={{
								src: DeleteBucket,
								alt: DeleteBucketActive,
							}}
							className={`live-category-delete-button ${
								enableSelection
									? selectedCards.length > 0
										? ''
										: 'disable-delete-button'
									: ''
							}`}
							onClick={selectToDelete}
							label={`${enableSelection ? 'Delete Category' : 'Select to Delete'}`}
							labelClassName={`${
								enableSelection
									? 'live-category-delete-button-label-active'
									: 'live-category-delete-button-label'
							}`}
							deleteButtonAppearance={enableSelection}
						/>
					</div>
					{enableSelection && (
						<img
							src={CloseIcon}
							alt="close"
							onClick={unSelectAllSelectedItems}
							className="unselect-all-cards-icon"
						/>
					)}
				</div>
			</div>
			<div className="content-block">
				<GridContainer
					showAddCategoryPopup={showCreateCategoryPopup}
					handleCreate={handleCreate}
					iconKey={iconKey}
					setIconKey={setIconKey}
					categoryName={categoryName}
					setCategoryName={setCategoryName}
					buttonLoader={buttonLoader}
					setButtonLoader={setButtonLoader}
					setToastInfo={setToastInfo}
					enableSelection={enableSelection}
					onSelecting={onSelectingCards}
					isEditPopup={isEditPopup}
					setIsEditPopup={setIsEditPopup}
					prevCategoryName={prevCategoryName}
					setPrevCategoryName={setPrevCategoryName}
				/>
			</div>
			<div className="liveTableWrap">
				<Bucket livescreen={true} />
			</div>
			<Popup
				className="popup-content-container"
				open={isOpenDeleteConfirmation}
				closeOnDocumentClick
				onClose={onCloseDeletePopup}
				contentStyle={deleteConfirmationPopupStyle}>
				<DeleteConfirmation
					onClose={onCloseDeletePopup}
					onDelete={onDeleteCategory}
					message={`Are you sure you want to delete these ${selectedCards.length} Categories?`}
					loading={buttonLoader}
				/>
			</Popup>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
			{categoryData?.length > 20 && <ScrollToTop handleScrollToTop={handleScrollToTop} />}
		</div>
	);
};

export default LiveCategory;
