export const HEADING = 'Cast and Crew';
export const SEARCH_BOX_PLACEHOLDER = 'Search by name';
export const PER_PAGE = 40;

export const CAST_TABLE_COLUMNS_CONFIG = [
	{
		key: 'name',
		title: 'Name',
		className: 'name',
		spanClassName: 'name-span',
	},
	{
		key: 'movies-shows',
		title: 'Movie & Shows',
		className: 'movies-shows',
		spanClassName: 'movies-shows-span',
	},
	{
		key: 'popularity',
		title: 'Popularity',
		className: 'popularity',
		spanClassName: 'popularity-span',
	},
];

export const mapper = {
	name: 'name',
	popularity: 'popularity',
};
