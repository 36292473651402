import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import Arrow from 'src/app/assets/images/ArrowCarousal.svg';

export const EpisodeTab = ({ tabs, active, handleTabChange }) => {
	const ref = useRef(null);
	const [activeTab, setActiveTab] = useState(active);
	const [arrowSeason, setArrowSeason] = useState({
		left: false,
		right: true,
	});

	useEffect(() => {
		handleSeasonArrow();
	}, []);

	const handleSeasonArrow = () => {
		document.querySelector('#season')?.addEventListener('scroll', () => {
			const ele = document.querySelector('#season');

			if (
				ele.scrollLeft !== 0 &&
				ele.scrollWidth - Math.floor(ele.scrollLeft) === ele.offsetWidth
			) {
				setArrowSeason({
					left: true,
					right: false,
				});
			} else if (ele.scrollLeft === 0) {
				setArrowSeason({
					left: false,
					right: true,
				});
			} else {
				setArrowSeason({
					left: true,
					right: true,
				});
			}
		});
	};

	const handleChange = (index, id) => {
		setActiveTab(id);
		handleTabChange(index, id);
	};

	const scroll = (scrollOffset) => {
		ref.current.scrollLeft += scrollOffset;
	};

	return (
		<div className="wrapper-episode">
			{tabs.length > 3 && (
				<img
					src={Arrow}
					alt={'arrow'}
					className={`left-arrow ${arrowSeason.left ? '' : 'deamed'}`}
					onClick={() => scroll(-140)}
				/>
			)}
			<div
				className={`episode-tab-container ${tabs.length > 3 ? '' : 'more-margin'}`}
				id="season"
				ref={ref}>
				{tabs?.map((tab, index) => {
					return (
						<div
							key={index}
							className={`block ${tab === activeTab ? 'active' : 'not-active'}`}
							onClick={() => handleChange(index, tab)}>
							<p>{`Season ${tab}`}</p>
						</div>
					);
				})}
			</div>
			{tabs.length > 3 && (
				<img
					src={Arrow}
					alt={'arrow'}
					className={`right-arrow ${arrowSeason.right ? '' : 'deamed'}`}
					onClick={() => scroll(140)}
				/>
			)}
		</div>
	);
};

export default EpisodeTab;
