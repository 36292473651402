import InactiveUnion from 'src/app/assets/logos/InactiveUnion.svg';
import ActiveUnion from 'src/app/assets/logos/ActiveUnion.svg';
import QewdNewLogo from 'src/app/assets/logos/QewdNewLogo.svg';
import InactiveBucket from 'src/app/assets/logos/InactiveBucket.svg';
import ActiveBucket from 'src/app/assets/logos/ActiveBucket.svg';
import InactiveCamera from 'src/app/assets/logos/InactiveCamera.svg';
import ActiveCamera from 'src/app/assets/logos/ActiveCamera.svg';
import ActiveReorder from '../../assets/logos/Reorder.svg';
import InactiveReorder from '../../assets/logos/InactiveReorder.svg';
import ActiveContentManagement from '../../assets/logos/contentManagement.svg';
import InactiveContentManagement from '../../assets/logos/inactiveContentManagement.svg';
import InactiveDigest from '../../assets/logos/InactiveDigest.svg';
import ActiveDigest from '../../assets/logos/ActiveDigest.svg';
import InactiveWaitlist from '../../assets/logos/InactiveWaitlist.svg';
import ActiveWaitlist from '../../assets/logos/ActiveWaitlist.svg';
import InactiveLiveChannel from 'src/app/assets/logos/InactiveLiveChannel.svg';
import ActiveLiveChannel from 'src/app/assets/logos/ActiveLiveChannel.svg';
import ActiveUserIcon from 'src/app/assets/logos/UserIconActive.svg';
import InactiveUserIcon from 'src/app/assets/logos/UserIconInactive.svg';
import ActiveReportVideo from 'src/app/assets/logos/ActiveReportVideo.svg';
import InactiveReportVideo from 'src/app/assets/logos/InactiveReportVideo.svg';
import CastInactive from 'src/app/assets/logos/CastInactive.svg';
import CastActive from 'src/app/assets/logos/CastActive.svg';
import SettingActive from 'src/app/assets/logos/SettingActive.svg';
import SettingInactive from 'src/app/assets/logos/SettingInactive.svg';
import ListForYouActive from 'src/app/assets/logos/ListForYouActive.svg';
import ListForYouInactive from 'src/app/assets/logos/ListForYouInactive.svg';
import YTInactive from 'src/app/assets/logos/YTInactive.svg';
import YTActive from 'src/app/assets/logos/YTActive.svg';

export const SIDEBAR_TABS = [
	{ inactiveLogo: QewdNewLogo, activeLogo: QewdNewLogo, link: 'dashboard', id: 1 },
	{
		label: 'Movies',
		inactiveLogo: InactiveCamera,
		activeLogo: ActiveCamera,
		link: 'movies',
		id: 2,
		subLinks: [
			{ label: 'Trending', link: 'trending' },
			{ label: 'Popular', link: 'popular' },
			{ label: 'New', link: 'new' },
			{ label: 'Top Rated', link: 'top-rated' },
			{ label: 'Free', link: 'free' },
		],
	},
	{
		label: 'Shows',
		inactiveLogo: InactiveUnion,
		activeLogo: ActiveUnion,
		link: 'shows',
		id: 3,
		subLinks: [
			{ label: 'Trending', link: 'trending' },
			{ label: 'Popular', link: 'popular' },
			{ label: 'New', link: 'new' },
			{ label: 'Top Rated', link: 'top-rated' },
			{ label: 'Free', link: 'free' },
		],
	},
	{
		label: 'Bucket',
		inactiveLogo: InactiveBucket,
		activeLogo: ActiveBucket,
		link: 'bucket',
		id: 4,
	},
	{
		label: 'Published',
		inactiveLogo: InactiveReorder,
		activeLogo: ActiveReorder,
		link: 'published',
		id: 5,
	},
	{
		label: 'List',
		inactiveLogo: ListForYouInactive,
		activeLogo: ListForYouActive,
		link: 'list-for-you',
		id: 6,
	},
	{
		label: 'Watchlist',
		inactiveLogo: InactiveDigest,
		activeLogo: ActiveDigest,
		link: 'watchlist',
		id: 7,
	},
	{
		label: 'Content',
		inactiveLogo: InactiveContentManagement,
		activeLogo: ActiveContentManagement,
		link: 'contentManagement',
		id: 8,
	},
	// {
	// 	label: 'Waitlist',
	// 	inactiveLogo: InactiveWaitlist,
	// 	activeLogo: ActiveWaitlist,
	// 	link: 'waitlist',
	// 	id: 9,
	// },
	{
		label: 'Live',
		inactiveLogo: InactiveLiveChannel,
		activeLogo: ActiveLiveChannel,
		link: 'live',
		id: 10,
	},
	{
		label: 'All Users',
		inactiveLogo: InactiveUserIcon,
		activeLogo: ActiveUserIcon,
		link: 'all-users',
		id: 11,
	},
	{
		label: 'Reported',
		inactiveLogo: InactiveReportVideo,
		activeLogo: ActiveReportVideo,
		link: 'reported-videos',
		id: 12,
	},
	{
		label: 'Cast',
		inactiveLogo: CastInactive,
		activeLogo: CastActive,
		link: 'cast-crew',
		id: 13,
	},
	{
		label: 'YouTube',
		inactiveLogo: YTInactive,
		activeLogo: YTActive,
		link: 'youtube-videos',
		id: 14,
	},
	{
		label: 'Settings',
		inactiveLogo: SettingInactive,
		activeLogo: SettingActive,
		link: 'settings',
		id: 15,
	},
];

export const logoutModal = {
	title: 'Logout',
	message: 'Are you sure you want to logout?',
	no: 'No',
	yes: 'Logout',
};

export const POPUP_SCREEN_OPTIONS = [
	{ id: '1', name: 'Home' },
	{ id: '2', name: 'Discover' },
];

export const CAROUSEL_MORE_OPTIONS = [
	{ id: 'edit', name: 'Edit' },
	{ id: 'unpublish', name: 'Unpublish' },
	{ id: 'delete', name: 'Delete' },
];

export const CALENDAR_TYPES = {
	from: 'FROM',
	to: 'TO',
};
