import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

export const AppButton = ({
	onClick,
	label,
	isDisabled,
	isLoading,
	className,
	innerClass,
	autoCaptialize = true,
}) => {
	return (
		<div className={`btn-wrap ${className}`}>
			<button
				className={`black-btn btn ${isDisabled ? 'disabled' : ''} ${
					isLoading ? 'loading' : ''
				} ${innerClass ? innerClass : ''}`}
				onClick={onClick}>
				<span className={`${autoCaptialize ? '' : 'span-nonCapitalize'}`}>{label}</span>
				{isLoading && <div className="ld ld-ring ld-spin"></div>}
			</button>
		</div>
	);
};

AppButton.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	isDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
};
