import React, { useState, forwardRef } from 'react';
import { Column } from './Column';
import { Row } from './Row';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import { ReactSortable } from 'react-sortablejs';
import {
	updateBucketRankById,
	updatePublishBucketData,
} from '../../pages/Dashboard/actionCreators';
import { useDispatch } from 'react-redux';
import './styles.scss';
import { ROOT_URL, UPDATE_BUCKET_URL } from '../../common/constants/AppConstants';
import { getLoader } from '../../common/utils/methods';

const CustomComponent = forwardRef((props, ref) => {
	return <tbody ref={ref}>{props.children}</tbody>;
});

const Table = ({
	columnsConfig,
	shuffleMode,
	totalLength,
	loading,
	tableDisplayData,
	setTableDisplayData,
	goToNextPage,
	openEditModal,
	openDeleteModal,
	newDataLength,
	isUpdatedData,
	tableData,
	openChangeStatusModal,
	isRenderable = false,
	children,
	className,
	changeBG = false,
}) => {
	const dispatch = useDispatch();
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [increasePaddingBottomStatus, setIncreasePaddingBottomStatus] = useState(false);
	const [increasePaddingBottomScreen, setIncreasePaddingBottomScreen] = useState(false);
	const [increasePaddingBottomMoreOption, setIncreasePaddingBottomMoreOption] =
		useState(false);

	const fetchMoreData = () => {
		if (
			tableDisplayData.length > 0 &&
			totalLength > 0 &&
			tableDisplayData.length < totalLength
		) {
			goToNextPage();
		}
	};

	const handleAllCheck = () => {
		setIsAllChecked(!isAllChecked);
	};

	const dragEndHandler = (e) => {
		const draggedItem = tableDisplayData[e.newIndex];
		let bucketRank;
		let newtableData = [];
		if (e.newIndex === 0) {
			bucketRank =
				tableDisplayData[e.newIndex + 1]['bucket_rank'] > 1
					? tableDisplayData[e.newIndex + 1]['bucket_rank'] - 1
					: tableDisplayData[e.newIndex + 1]['bucket_rank'] / 1.01;
		} else if (e.newIndex === tableDisplayData.length - 1) {
			bucketRank = tableDisplayData[e.newIndex - 1]['bucket_rank'] + 1;
		} else {
			bucketRank =
				(tableDisplayData[e.newIndex - 1]['bucket_rank'] +
					tableDisplayData[e.newIndex + 1]['bucket_rank']) /
				2;
		}
		dispatch(
			updateBucketRankById(
				`${ROOT_URL}${UPDATE_BUCKET_URL}`,
				{
					bucket_id: draggedItem?.bucket_id,
					screen_id: draggedItem?.screen_id,
					bucket_rank: bucketRank,
				},
				() => {
					tableDisplayData.forEach((item) => {
						if (item.bucket_id === draggedItem.bucket_id) item.bucket_rank = bucketRank;
						newtableData.push(item);
					});
					setTableDisplayData(newtableData);
					updateTable(newtableData);
				},
			),
		);
	};

	const updateTable = (data) => {
		dispatch(updatePublishBucketData(data));
	};

	const renderTable = () => {
		if (loading) {
			return (
				<tbody>
					<EmptyState columns={columnsConfig} />
				</tbody>
			);
		} else {
			if (shuffleMode) {
				return (
					<ReactSortable
						tag={CustomComponent}
						list={tableDisplayData}
						setList={setTableDisplayData}
						onEnd={dragEndHandler}>
						{tableDisplayData.map((item, index) => {
							return (
								<Row
									key={item && `${item.screen_bucket_id}-${item.screen_name}`}
									data={{ ...item, index }}
									isAllChecked={isAllChecked}
									shuffleMode={shuffleMode ?? false}
									openEditBucketModal={() => {
										openEditModal(
											item.bucket_id,
											item.screen_bucket_id,
											item.is_predefined,
											item.is_user_type,
										);
									}}
									adjustPaddingBottomStatus={(state) => {
										setIncreasePaddingBottomStatus(state);
									}}
									adjustPaddingBottomMoreOption={(state) =>
										setIncreasePaddingBottomMoreOption(state)
									}
									adjustPaddingBottomScreen={(state) => {
										setIncreasePaddingBottomScreen(state);
									}}
									openDeleteModal={() => {
										openDeleteModal(item.bucket_id, item.screen_bucket_id);
									}}
									openChangeStatusModal={() => {
										openChangeStatusModal(item.bucket_id, item.screen_id);
									}}
								/>
							);
						})}
					</ReactSortable>
				);
			} else {
				return tableDisplayData.map((item, index) => {
					return (
						<Row
							key={item && `${item.screen_bucket_id}-${item.screen_name}`}
							data={{ ...item, index }}
							isLast={index + 1 === tableDisplayData.length ? true : false}
							isAllChecked={isAllChecked}
							shuffleMode={shuffleMode ?? false}
							adjustPaddingBottomStatus={(state) => {
								setIncreasePaddingBottomStatus(state);
							}}
							adjustPaddingBottomMoreOption={(state) =>
								setIncreasePaddingBottomMoreOption(state)
							}
							adjustPaddingBottomScreen={(state) => {
								setIncreasePaddingBottomScreen(state);
							}}
							openEditBucketModal={() => {
								openEditModal(item.bucket_id, item.screen_bucket_id);
							}}
							openDeleteModal={() => {
								openDeleteModal(item.bucket_id, item.screen_bucket_id);
							}}
							openChangeStatusModal={() => {
								openChangeStatusModal(item.bucket_id);
							}}
						/>
					);
				});
			}
		}
	};

	return (
		<div className={`${changeBG ? 'changeBG' : ''}`}>
			{isRenderable ? (
				children
			) : (
				<div
					id="infinite-table-container"
					className={
						increasePaddingBottomStatus ||
						increasePaddingBottomScreen ||
						increasePaddingBottomMoreOption
							? 'padding-bottom-increase'
							: ''
					}>
					{tableDisplayData.length > 0 && (
						<InfiniteScroll
							dataLength={tableDisplayData.length}
							next={fetchMoreData}
							hasMore={tableData.length < totalLength}
							loader={getLoader(
								loading,
								isUpdatedData,
								newDataLength,
								totalLength,
								tableData,
							)}
							scrollableTarget="infinite-table-container">
							<table className={`table ${className}`}>
								<thead>
									<tr>
										{columnsConfig.map((column) => {
											return (
												<Column
													key={column.key}
													config={column}
													handleAllCheck={handleAllCheck}
													shuffleMode={shuffleMode ?? false}
												/>
											);
										})}
										<th className="info-width"></th>
									</tr>
								</thead>
								{renderTable()}
							</table>
						</InfiniteScroll>
					)}
				</div>
			)}
		</div>
	);
};

export default Table;
