import React, { useEffect, useState } from 'react';
import './Button.styles.scss';

export const Button = ({
	onClick,
	label,
	labelClassName,
	imageInfo,
	className,
	deleteButtonAppearance = false,
	disabled = false,
}) => {
	const [icon, setIcon] = useState(imageInfo?.src);
	useEffect(() => {
		if (deleteButtonAppearance) {
			setIcon(imageInfo?.alt);
		} else {
			setIcon(imageInfo?.src);
		}
	}, [deleteButtonAppearance]);
	return (
		<div className={`image-button-container ${disabled ? 'disable-button' : ''}`}>
			<button className={`button ${className}`} onClick={onClick}>
				{imageInfo && <img src={icon} alt={imageInfo.label} />}
				{label && <div className={`label ${labelClassName}`}>{label}</div>}
			</button>
		</div>
	);
};

export default Button;
