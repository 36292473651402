import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import FilterContainerLive from './FilterContainerLive';
import ContentContainer from './ContentContainer';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import IconTextButton from 'src/app/components/atoms/IconTextButton';
import { overlayStyle } from 'src/app/common/constants/AppConstants';
import { ButtonLoader } from 'src/app/components/ButtonLoader';
import { Button } from 'src/app/components/Button';
import { fetchDataFromGetApi } from 'src/app/pages/Dashboard/actionCreators';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import {
	putCommonMethod,
	deleteData,
	getCommonMethod,
	postCommonMethod,
} from 'src/app/pages/Dashboard/Service';
import EditButton from 'src/app/pages/Dashboard/Components/BucketDetails/Components/EditButton';
import { EditBucketModal } from 'src/app/components/Modals/EditBucketModal';
import { isEmptyString, isValidURL, isValidM3U8URL } from 'src/app/common/utils/methods';
import { AddUrlPopup } from 'src/app/components/AddUrlPopup';
import { GET_LIVE_STREAM_DATA_INITIAL } from 'src/app/pages/Dashboard/actionTypes';
import AddImage from 'src/app/assets/images/add-stream.png';
import { Toast } from 'src/app/components/Toast';
import { AddStreamsToChannelModal } from 'src/app/components/Modals/AddStreamsToChannelModal';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import { deleteConfirmationPopupStyle } from 'src/app/common/constants/PopupStyles';
import { GET_SUB_CATEGORY_DATA_INITIAL } from 'src/app/pages/Dashboard/actionTypes';
import * as CONSTANTS from './Constant.js';
import './styles.scss';

const LiveBucketDetail = () => {
	const useQuery = () => {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	};
	let query = useQuery();
	const bucketId = query.get('bucket_id');
	const contentType = query.get('content_type');
	const isRendering = useRef(true);
	const dispatch = useDispatch();
	const subCategoryData = useSelector((state) => state.liveBuckReducer?.sub_category);
	const [offset, setOffset] = useState(0);
	const [tableData, setTableData] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [editBucket, setEditBucket] = useState(false);
	const [bucketDetails, setBucketDetails] = useState(null);
	const [metaData, setMetaData] = useState(null);
	const [bucketTags, setBucketTags] = useState(null);
	const [selectedCards, setSelectedCards] = useState([]);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [openAddUrlPopup, setOpenAddUrlPopup] = useState(false);
	const [newChannelName, setNewChannelName] = useState('');
	const [channelUrl, setChannellUrl] = useState('');
	const [urlNotValid, setUrlNotValid] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [addButtonLoader, setAddButtonLoader] = useState(false);
	const [iconKey, setIconKey] = useState(null);
	const [thumbnail, setThumbnail] = useState(null);
	const [prevThumbnail, setPrevThumbnail] = useState(null);
	const [liveChannelId, setLiveChannelId] = useState(null);
	const [prevName, setPrevName] = useState();
	const [prevUrl, setPrevUrl] = useState();
	const [createChannelApiBody, setCreateChannelApiBody] = useState(null);
	const [openAddNewChannel, setOpenAddNewChannel] = useState(false);
	const [loading, setLoading] = useState(true);
	const [showDeletConfirmationPopup, setShowDeletConfirmationPopup] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [deleteAll, setDeleteAll] = useState(false);
	const [unCheckedOnSelectOne, setUncheckedOnSelectOne] = useState(deleteAll);
	const [shuffle, setShuffle] = useState(false);
	const [subcategoryId, setSubcategoryId] = useState(null);
	const [isFilterVisible, setIsFilterVisible] = useState(false);
	const [showSubCategoryData, setShowSubCategoryData] = useState(false);

	useEffect(() => {
		fetchData();
	}, [bucketId]);

	useEffect(() => {
		if (subCategoryData?.length === 0) {
			fetchSubCategoryData();
		}
	}, []);

	useEffect(() => {
		if (subcategoryId) {
			setIsFilterVisible(true);
		} else {
			setIsFilterVisible(false);
		}
	}, [subcategoryId]);

	useEffect(() => {
		if (metaData) {
			isRendering.current = false;
		}
	}, [metaData]);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setShowToast(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	useEffect(() => {
		if (
			isEmptyString(newChannelName) &&
			isEmptyString(channelUrl) &&
			isEmptyString(iconKey) &&
			isEmptyString(prevThumbnail) &&
			isValidURL(channelUrl) &&
			(prevName !== newChannelName || prevUrl !== channelUrl || prevThumbnail !== iconKey)
		) {
			setIsDisabled(true);
			if (prevThumbnail === iconKey) {
				setCreateChannelApiBody({
					name: newChannelName,
					url: channelUrl,
					live_stream_id: liveChannelId,
					logo: iconKey,
				});
			} else {
				setCreateChannelApiBody({
					name: newChannelName,
					url: channelUrl,
					live_stream_id: liveChannelId,
					logo: `${GLOBAL_CONST.CATEGORY_THUMNAIL_URL}${iconKey}`,
				});
			}
		} else {
			setIsDisabled(false);
		}
	}, [channelUrl, newChannelName, prevName, prevUrl, prevThumbnail, iconKey, thumbnail]);

	const updateResults = () => {
		fetchData();
	};

	const closeModal = () => {
		setOpenAddUrlPopup(false);
	};

	const closeModalAddChannel = () => {
		setLoading(true);
		setOpenAddNewChannel(false);
		offset > 0 ? setOffset(0) : setOffset((off) => off - 1);
	};

	const onChangeName = (e) => {
		setNewChannelName(() => {
			return e.target.value;
		});
	};

	const onChangeUrl = (e) => {
		setChannellUrl(() => {
			return e.target.value.trim();
		});
	};

	const clearPopupInput = () => {
		setNewChannelName('');
		setChannellUrl('');
		setUrlNotValid(false);
	};

	const onCancelAddPopup = () => {
		setOpenAddUrlPopup(false);
		clearPopupInput();
	};

	const handleShuffle = () => {
		setShuffle(!shuffle);
	};

	const handleChangeOnEdit = (data) => {
		setOpenAddUrlPopup(true);
		if (data) {
			setLiveChannelId(data?.content_id);
			setChannellUrl(data?.url);
			setNewChannelName(data?.name);
			setThumbnail(data?.thumbnail);
			setPrevThumbnail(data?.thumbnail?.length > 0 ? data?.thumbnail : null);
			setIconKey(data?.thumbnail);
			setPrevUrl(data?.url);
			setPrevName(data?.name);
		}
	};

	const handleSubCategory = (val) => {
		setSubcategoryId(val);
		applyFilters();
	};

	const applyFilters = () => {
		setLoading(true);
		setShowSubCategoryData(true);
		offset > 0 ? setOffset(0) : setOffset((off) => off - 1);
	};

	const clearFilter = () => {
		setLoading(true);
		setSubcategoryId(null);
		setShowSubCategoryData(false);
		offset > 0 ? setOffset(0) : setOffset((off) => off - 1);
		const elem = document?.querySelector(`#subcategory-${subcategoryId}`);
		if (elem) {
			elem?.setAttribute('aria-checked', false);
		}
	};

	const onChangeFilter = (e) => {
		if (e?.target?.id === subcategoryId) {
			e.target.setAttribute('aria-checked', false);
			clearFilter();
		} else {
			const elem = document?.querySelector(`#subcategory-${subcategoryId}`);
			if (elem) {
				elem?.setAttribute('aria-checked', false);
			}
			e.target.setAttribute('aria-checked', true);
			const val = e?.target?.id.replace('subcategory-', '');
			setSubcategoryId(val);
		}
	};

	const fetchSubCategoryData = async () => {
		try {
			const res = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_LIVE_CHANNEL_URL}`,
				{
					offset: 0,
					perPage: 2000,
					orderBy: GLOBAL_CONST.ORDER_BY.createdAt,
					orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
				},
			);
			if (res) {
				dispatch({ type: GET_SUB_CATEGORY_DATA_INITIAL, payload: res.data });
			}
		} catch (e) {}
	};

	const fetchData = async () => {
		fetchDataFromGetApi(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.BUCKET_DETAIL_URL}`,
			{
				bucket_id: bucketId,
			},
			({ data }) => {
				if (data) {
					setBucketDetails(data);
					setMetaData(data);
					if (data?.tags) {
						setBucketTags(data?.tags);
					} else {
						setBucketTags([]);
					}
				}
			},
		);
	};

	const handleDeleteChannelFromBucket = (id) => {
		setDeleteId(id);
		setShowDeletConfirmationPopup(true);
	};

	const closeDeleteConfirmation = () => {
		setShowDeletConfirmationPopup(false);
		setDeleteId(null);
	};

	const deleteMultipleChannelFromBucket = async () => {
		try {
			setButtonLoading(true);
			const videoIds = selectedCards.map((card) => card.id);
			const resp = await deleteData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_LIVE_CHANNEL_IN_BUCKET}`,
				{
					content_ids: videoIds,
					bucket_id: bucketId,
				},
			);
			if (resp) {
				handleToast(resp?.message, false, true);
				setSelectedCards([]);
				setDeleteAll(false);
				setUncheckedOnSelectOne(false);
				offset > 0 ? setOffset(0) : setOffset((off) => off - 1);
			}
		} catch (error) {
			handleToast(error.response.data.message, true, true);
		} finally {
			setButtonLoading(false);
			setShowDeletConfirmationPopup(false);
		}
	};

	const deleteChannel = async () => {
		try {
			setButtonLoading(true);
			const resp = await deleteData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_LIVE_CHANNEL_IN_BUCKET}`,
				{
					content_ids: [deleteId],
					bucket_id: bucketId,
				},
			);
			if (resp) {
				if (deleteId) {
					const arr = [...tableData];
					const index = arr?.findIndex((item) => item?.content_id === deleteId);
					arr?.splice(index, 1);
					setTableData([...arr]);
				}
				handleToast(resp?.message, false, true);
			}
		} catch (error) {
			handleToast(error.response.data.message, true, true);
		} finally {
			setButtonLoading(false);
			setDeleteId(null);
			setShowDeletConfirmationPopup(false);
		}
	};

	const toggleModal = () => {
		setIsOpen(!isOpen);
		setEditBucket(false);
	};

	const handleEdit = () => {
		setEditBucket(true);
		setIsOpen(true);
	};

	const handleToastDismiss = () => {
		setShowToast(false);
	};

	const handleToast = (message, isError, show) => {
		setToastError(isError);
		setShowToastMessage(message);
		setShowToast(show);
	};

	const newBucketCreated = (message, isError, show) => {
		handleToast(message, isError, show);
	};

	const onAddUrl = async () => {
		setAddButtonLoader(true);
		try {
			let params = {
				live_stream_name: newChannelName,
				live_stream_url: channelUrl,
				logo: `${GLOBAL_CONST.CATEGORY_THUMNAIL_URL}${iconKey}`,
			};
			params = { ...createChannelApiBody };
			const response = await putCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.EDIT_STREAM_URL}`,
				params,
			);
			if (response) {
				dispatch({ type: GET_LIVE_STREAM_DATA_INITIAL, payload: { data: [] } });
				clearPopupInput();
				offset > 0 ? setOffset(0) : setOffset((off) => off - 1);
				handleToast(response.message, false, true);
				setAddButtonLoader(false);
				setOpenAddUrlPopup(false);
				setPrevName(newChannelName);
				setPrevUrl(channelUrl);
				setPrevThumbnail(iconKey);
				setIconKey(null);
			}
		} catch (e) {
			setAddButtonLoader(false);
			handleToast(e.response.data.message, true, true);
		}
	};

	const handleDelete = () => {
		setShowDeletConfirmationPopup(true);
	};

	const addChannelsToBucket = async () => {
		try {
			const contentIds = [];
			selectedCards.map((item) => {
				contentIds.push(item.id);
			});
			const resp = await postCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_LIVE_CHANNEL_IN_BUCKET}`,
				{
					content_ids: contentIds,
					bucket_id: bucketId,
					content_type: contentType,
				},
			);
			if (resp) {
				handleToast(resp?.data?.message, false, true);
				setSelectedCards([]);
				setDeleteAll(false);
				setUncheckedOnSelectOne(false);
				clearFilter();
			}
		} catch (error) {
			handleToast(
				error.response.data.message === CONSTANTS.LIVE_MSSG.live_stream_mssg
					? CONSTANTS.LIVE_MSSG.live_channel_mssg
					: error.response.data.message,
				true,
				true,
			);
		}
	};

	const renderHeader = () => {
		return (
			<div className="header-parent-block">
				<div className="header-block">
					<div>
						<h1 className="heading">
							{isRendering.current ? <ButtonLoader /> : metaData?.name}
						</h1>
					</div>
					<div className="dashboard-button-block">
						<IconTextButton
							buttonText="+ Create Bucket"
							handleClick={() => toggleModal()}
						/>
					</div>
				</div>
				<div className="search-box-block">
					<div />
					<div className="aside-buttons">
						{showSubCategoryData &&
							contentType === CONSTANTS.CONTENT_TYPE.live_stream && (
								<Button
									onClick={addChannelsToBucket}
									label={
										contentType === CONSTANTS.CONTENT_TYPE.live_stream
											? CONSTANTS.TOOLTIP_CONST.live_stream_add
											: CONSTANTS.TOOLTIP_CONST.live_channel_add
									}
									className={`button-black extra-spacing-right`}
									labelClassName={'label-grey'}
									disabled={selectedCards?.length === 0}
								/>
							)}
						<Tooltip
							overlay={
								contentType === CONSTANTS.CONTENT_TYPE.live_stream
									? CONSTANTS.TOOLTIP_CONST.live_stream_add
									: CONSTANTS.TOOLTIP_CONST.live_channel_add
							}
							placement="bottom"
							overlayInnerStyle={GLOBAL_CONST.overlayStyle}
							align={{
								offset: [-8, -2],
							}}>
							<div>
								<Button
									imageInfo={{
										src: AddImage,
									}}
									className="live-add-button"
									onClick={() => setOpenAddNewChannel(true)}
									disabled={selectedCards?.length > 0 || showSubCategoryData}
								/>
							</div>
						</Tooltip>
						<Tooltip
							overlay="Edit Bucket"
							placement="bottom"
							overlayInnerStyle={overlayStyle}
							align={{
								offset: [0, -2],
							}}>
							<div>
								<EditButton handleClick={handleEdit} />
							</div>
						</Tooltip>
						<Tooltip
							overlay={
								contentType === CONSTANTS.CONTENT_TYPE.live_stream
									? CONSTANTS.TOOLTIP_CONST.live_stream_delete
									: CONSTANTS.TOOLTIP_CONST.live_channel_delete
							}
							placement="bottom"
							overlayInnerStyle={GLOBAL_CONST.overlayStyle}
							align={{
								offset: [0, 2],
							}}>
							<div>
								<Button
									imageInfo={{
										src: DeleteBucket,
									}}
									className="live-delete-button"
									onClick={handleDelete}
									disabled={!selectedCards?.length > 0 || showSubCategoryData}
								/>
							</div>
						</Tooltip>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="live-bucket-detail">
			{renderHeader()}
			<div className="content-container">
				{contentType === CONSTANTS.CONTENT_TYPE.live_stream && (
					<FilterContainerLive
						onChangeFilter={onChangeFilter}
						isFilterVisible={isFilterVisible}
						clearAllFilters={clearFilter}
						applyFilters={applyFilters}
						disabled={shuffle}
					/>
				)}
				<ContentContainer
					bucketId={bucketId}
					tableData={tableData}
					setTableData={setTableData}
					handleToast={handleToast}
					handleChangeOnEdit={handleChangeOnEdit}
					offset={offset}
					setOffset={setOffset}
					loading={loading}
					setLoading={setLoading}
					selectedCards={selectedCards}
					setSelectedCards={setSelectedCards}
					handleDeleteChannelFromBucket={handleDeleteChannelFromBucket}
					setOpenAddNewChannel={setOpenAddNewChannel}
					deleteAll={deleteAll}
					setDeleteAll={setDeleteAll}
					unCheckedOnSelectOne={unCheckedOnSelectOne}
					setUncheckedOnSelectOne={setUncheckedOnSelectOne}
					shuffle={shuffle}
					handleShuffle={handleShuffle}
					showSubCategoryData={showSubCategoryData}
					subcategoryId={subcategoryId}
					contentType={contentType}
					handleSubCategory={handleSubCategory}
					handleBack={clearFilter}
				/>
			</div>
			<EditBucketModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				showToast={newBucketCreated}
				isEdit={editBucket}
				bucketId={metaData?.bucket_id}
				callback={updateResults}
				livescreen={true}
			/>
			<AddUrlPopup
				isOpen={openAddUrlPopup}
				closeModal={closeModal}
				message={'Edit Live Channel URL'}
				channelName={newChannelName}
				channelUrl={channelUrl}
				onChangeName={onChangeName}
				onChangeUrl={onChangeUrl}
				isDisable={isDisabled}
				onAdd={onAddUrl}
				onCancel={onCancelAddPopup}
				loading={addButtonLoader}
				urlNotValid={urlNotValid}
				isEdit={true}
				buttonText={'Update'}
				iconKey={iconKey}
				setIconKey={setIconKey}
				thumbnail={thumbnail}
			/>
			<AddStreamsToChannelModal
				isOpen={openAddNewChannel}
				closeModal={closeModalAddChannel}
				channelId={bucketId}
				name={metaData?.name}
				addToBucket={true}
				contentType={contentType}
			/>

			<Popup
				className="popup-content-container-digest"
				open={showDeletConfirmationPopup}
				closeOnDocumentClick
				onClose={closeDeleteConfirmation}
				contentStyle={deleteConfirmationPopupStyle}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmation}
					onDelete={deleteId ? deleteChannel : deleteMultipleChannelFromBucket}
					loading={buttonLoading}
					message={`Do you want to delete ${
						selectedCards?.length > 0 ? selectedCards?.length : 'this'
					} ${CONSTANTS.CONTENT_TYPE_DISPLAY[contentType]} from bucket.`}
					buttonText={'Delete'}
				/>
			</Popup>

			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

export default LiveBucketDetail;
