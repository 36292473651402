export const HEADING = 'Settings';

export const LEFT_PANEL_CONSTANTS = [
	{
		id: 0,
		label: 'Free Streaming Services',
	},
	{
		id: 1,
		label: 'Manage Tags',
	},
	{
		id: 2,
		label: 'Manage Account',
	},
];

export const WEBVIEW_TABLE_COLUMNS_CONFIG = [
	{
		key: 'services',
		title: 'Services',
		className: 'services',
		spanClassName: 'services-span',
	},
	{
		key: 'play-on-qewd',
		title: 'Play on QEWD',
		className: 'play-on-qewd',
		spanClassName: 'play-on-qewd-span',
	},
];

export const TAGS_TABLE_COLUMNS_CONFIG = [
	{
		key: 'tags',
		title: 'Tags',
		className: 'name',
		spanClassName: 'tags-span',
	},
	{
		key: 'bucket-count',
		title: 'Bucket Count',
		className: 'bucket-count',
		spanClassName: 'bucket-count-span',
	},
	{
		key: 'bucket-name',
		title: 'Bucket Name',
		className: 'bucket-name',
		spanClassName: 'bucket-name-span',
	},
	{
		key: 'actions-tag',
		title: 'Actions',
		className: 'actions-tag',
		spanClassName: 'actions-tag-span',
	},
];

export const EDIT_OPTION = [{ value: 'edit', label: 'Edit' }];

export const SERVICE_UPDATED = 'You have mark provider service successfully';
export const SERVICE_UPDATED_UNMARK = 'You have unmark all provider service successfully';
export const UNMARK = 'Are you sure you want to unmark all free streaming providers?';
export const FREE_PROVIDERS = 'free';
export const TAG_CONFIRMATION = 'Are you sure you want to update selected tag(s)?';
export const TAG_SUCCESS_MESSAGE = 'Tag(s) Updated Successfully';
