import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { deleteConfirmationPopupStyle } from 'src/app/common/constants/PopupStyles';
import PublishModal from '../PublishModal';
import { postCommonMethod } from 'src/app/pages/Dashboard/Service';
import { publishDigest } from 'src/app/pages/Dashboard/actionCreators';
import {
	QEWD_DIGEST,
	ROOT_URL,
	QEWD_DIGEST_PUBLISH_FREQUENCY,
} from 'src/app/common/constants/AppConstants';
import { useSelector, useDispatch } from 'react-redux';
import { ADD_THUMBNAILS } from 'src/app/pages/Dashboard/actionTypes';
import { useHistory } from 'react-router';

export const FrequencyPopup = ({ isOpen, closeModal, message, setToast }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [frequencyValue, setFreqencyValue] = useState();
	const [loading, setLoading] = useState(false);
	const selectedThumbnails = useSelector((state) => state.qewdDigest.thumbnails);
	const onPublish = async () => {
		const thumbnailIds = selectedThumbnails.map((item) => {
			return {
				video_id: item.id,
			};
		});
		setLoading(true);
		try {
			const response = await postCommonMethod(`${ROOT_URL}${QEWD_DIGEST}`, thumbnailIds);
			if (response && response.status) {
				dispatch({ type: ADD_THUMBNAILS, payload: [] });
				setToast({ message: response.data.message, show: true, isError: false });
				let path = `/dashboard/${process.env.REACT_APP_QEWD_DIGEST_TITLE}?bucket_id=${process.env.REACT_APP_QEWD_DIGEST_BUCKET_ID}`;
				history.push(path);
			}
		} catch (error) {
			setToast({ message: error.response.data.message, show: true, isError: true });
		}
		setLoading(false);
		closeModal();
	};

	const getSelectionValue = (value) => {
		setFreqencyValue(value);
	};

	return (
		<Popup
			className="frequency-popup"
			open={isOpen}
			closeOnDocumentClick
			onClose={closeModal}
			contentStyle={deleteConfirmationPopupStyle}>
			<PublishModal
				onClose={closeModal}
				onDelete={onPublish}
				getSelectionValue={getSelectionValue}
				frequencyBoxes={false}
				message={message}
				publishButtonLoader={loading}
			/>
		</Popup>
	);
};
