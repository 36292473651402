import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import InfiniteScroll from 'react-infinite-scroll-component';
import { infinteScrollEndMessage } from 'src/app/pages/ComponentConstant';
import { Loader } from 'src/app/components/Loader';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import Table from 'src/app/components/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as CONST from '../constants';
import Row from './Row';
import { getCommonMethod, putCommonMethod } from 'src/app/pages/Dashboard/Service';
import * as GLOBAL_CONSTANT from 'src/app/common/constants/AppConstants';
import {
	GET_CAST_DATA_INITIAL,
	GET_CAST_DATA,
	EDIT_PROFILE_ICON,
} from 'src/app/pages/Dashboard/actionTypes';
import Popup from 'reactjs-popup';
import { castEditPopup } from 'src/app/common/constants/PopupStyles';
import EditCastProfile from 'src/app/components/EditCastProfile';
import { modifiedDebounceValue, debounceCheck } from 'src/app/common/utils/methods';

export const CastTableContainer = ({
	offset,
	setOffset,
	loading,
	setLoading,
	shuffleMode = false,
	setTotalCount,
	setToastInfo,
	debouncedValue,
}) => {
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.castReducer?.cast);
	const [hasMore, setHasMore] = useState(false);
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const highlighted = useRef(null);
	const [dropDownValue, setDropDownValue] = useState({
		name: false,
		popularity: false,
	});
	const [filter, setFilter] = useState({
		key: 'popularity',
		value: false,
	});
	const [editPopup, setEditPopup] = useState(false);
	const [editPopupData, setEditPopupData] = useState(null);
	const [buttonLoader, setButtonLoader] = useState(false);

	useEffect(() => {
		if (tableData) {
			setLoading(false);
			setTableDisplayData([...tableData]);
		}
	}, [tableData, setLoading]);

	useEffect(() => {
		fetchCastCount();
	}, []);

	useEffect(() => {
		fetchCastData();
	}, [dispatch, offset, debouncedValue, filter]);

	const toggleEditPopup = () => {
		setEditPopup(false);
	};

	const handleEditPopup = (cast_id, name, popularity, thumbnail) => {
		setEditPopup(true);
		setEditPopupData({
			cast_id: cast_id,
			name: name,
			popularity: popularity,
			thumbnail: thumbnail,
		});
	};

	const updatePopularity = async (
		cast_id,
		popularity_count,
		cast_thumbnail,
		cast_name,
		popularityEdit = false,
	) => {
		setButtonLoader(true);
		try {
			const response = await putCommonMethod(
				`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.CAST_UPDATE}`,
				{
					cast_id: cast_id,
					popularity: popularity_count,
					name: cast_name,
					thumbnail: cast_thumbnail,
				},
			);
			if (!popularityEdit && response.status) {
				const index = tableDisplayData.findIndex((item) => item.cast_id === cast_id);
				const arr = [...tableDisplayData];
				let item = {
					...arr[index],
					thumbnail: `${process.env.REACT_APP_CAST_BUCKET}${cast_thumbnail}`,
				};
				arr[index] = item;
				setTableDisplayData(arr);
			}
			setToastInfo(true, false, response?.message);
			setButtonLoader(false);
			toggleEditPopup();

			return true;
		} catch (e) {
			setToastInfo(true, true, GLOBAL_CONSTANT.ERROR_MESSAGE);
			setButtonLoader(false);
			toggleEditPopup();
			return false;
		}
	};

	const handleHasMore = (response) => {
		if (response?.data?.length > 0 && response?.data?.length === CONST.PER_PAGE) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const fetchCastData = async () => {
		try {
			const params = {
				offset: offset || 0,
				perPage: CONST.PER_PAGE,
				orderType: filter.value
					? GLOBAL_CONSTANT.ORDER_TYPE.ASC
					: GLOBAL_CONSTANT.ORDER_TYPE.DESC,
				orderBy: CONST.mapper[filter.key],
			};
			if (debounceCheck(debouncedValue)) {
				const modifiedDebouncedValue = modifiedDebounceValue(debouncedValue);
				params.searchTerm = modifiedDebouncedValue;
			}

			if (offset > 0) {
				const resp = await getCommonMethod(
					`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.CAST_LIST}`,
					params,
				);
				dispatch({ type: GET_CAST_DATA, payload: resp.data });
				handleHasMore(resp);
			} else {
				setLoading(true);
				const resp = await getCommonMethod(
					`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.CAST_LIST}`,
					params,
				);
				dispatch({ type: GET_CAST_DATA_INITIAL, payload: resp.data });
				handleHasMore(resp);
			}
		} catch (e) {}
	};

	const fetchCastCount = async () => {
		try {
			const response = await getCommonMethod(
				`${GLOBAL_CONSTANT.ROOT_URL}${GLOBAL_CONSTANT.CAST_COUNT}`,
			);
			setTotalCount(response?.data?.total_cast_records);
		} catch (e) {}
	};

	const fetchMoreData = () => {
		if (hasMore) {
			setOffset(tableDisplayData?.length);
		}
	};

	const handleDrop = async (k) => {
		setLoading(true);
		setFilter({
			key: k,
			value: !dropDownValue[k],
		});
		setOffset(0);
	};

	const handleAllCheck = () => {};

	const renderTable = () => {
		if (loading) {
			return <EmptyState columns={CONST.CAST_TABLE_COLUMNS_CONFIG} renderColumns={2} />;
		} else {
			return tableDisplayData.map((item, index) => {
				return (
					<Row
						key={item && `${item?.cast_id}`}
						data={{ ...item, index }}
						updatePopularity={updatePopularity}
						setToastInfo={setToastInfo}
						handleEditPopup={handleEditPopup}
					/>
				);
			});
		}
	};

	return (
		<div>
			<Table isRenderable={true}>
				<div id="scrollableDivCast">
					<InfiniteScroll
						dataLength={tableData?.length}
						next={fetchMoreData}
						hasMore={hasMore}
						loader={<Loader loadMore={true} />}
						scrollThreshold={0.3}
						scrollableTarget="scrollableDivCast"
						endMessage={loading ? null : infinteScrollEndMessage()}>
						<table className="table-cast">
							<thead>
								<tr>
									{CONST.CAST_TABLE_COLUMNS_CONFIG.map((column) => {
										return (
											<Column
												key={column.key}
												config={column}
												handleAllCheck={(event) => handleAllCheck(event)}
												shuffleMode={shuffleMode ?? false}
												isWaitlist={true}
												status={true}
												fromAllUser={true}
												fromCast={true}
												dropDownValue={dropDownValue}
												setDropDownValue={setDropDownValue}
												handledrop={handleDrop}
												highlighted={highlighted}
											/>
										);
									})}
								</tr>
							</thead>
							<tbody>{renderTable()}</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</Table>
			<Popup
				className="popup-content-container-digest"
				open={editPopup}
				closeOnDocumentClick
				onClose={toggleEditPopup}
				contentStyle={castEditPopup}>
				<EditCastProfile
					data={editPopupData}
					closePopup={toggleEditPopup}
					updatePopularity={updatePopularity}
					buttonLoader={buttonLoader}
					setButtonLoader={setButtonLoader}
					setToastInfo={setToastInfo}
				/>
			</Popup>
		</div>
	);
};

export default CastTableContainer;
