import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AppButton } from 'src/app/components/AppButton';
import AddImage from 'src/app/assets/images/add-stream.png';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import TableContainer from 'src/app/pages/LiveStreamsInChannel/component/TableContainer';
import './styles.scss';
import { useLocation, useHistory } from 'react-router-dom';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import {
	deleteData,
	getCommonMethod,
	postCommonMethod,
	putCommonMethod,
	getTableData,
} from 'src/app/pages/Dashboard/Service';
import { editTableData } from 'src/app/pages/Dashboard/actionCreators';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { RECORD_TO_FETCH } from 'src/app/common/constants/BucketListingConstant';
import { Toast } from 'src/app/components/Toast';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import ScrollToTop from 'src/app/components/atoms/ScrollToTop';
import { AddStreamsToChannelModal } from 'src/app/components/Modals/AddStreamsToChannelModal';
import { AddUrlPopup } from 'src/app/components/AddUrlPopup';
import { isEmptyString, isValidURL, isValidM3U8URL } from 'src/app/common/utils/methods';
import { ADD_URL_POPUP_CONST, ERROR_MESSAGE } from './constants';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import BreadCrumbs from 'src/app/components/BreadCrumbs';

export const LiveStreamsInChannel = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const path = useLocation();
	const params = path && path.pathname && path.pathname.split('/');
	const channelId = params && params[params.length - 1];
	const [isChecked, setChecked] = useState(false);
	const [offset, setOffset] = useState(0);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [countMultipleCheck, setCountMultipleCheck] = useState(0);
	const [countOnAllUnselected, setCountOnAllUnselected] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [loading, setLoading] = useState(false);
	const [liveChannelId, setLiveChannelId] = useState(null);
	const searchRef = useRef('');
	const [apiBody, setApiBody] = useState([]);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [counterToShowDelete, setCounterToShowDelete] = useState(0);
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [defaultDeleteMessage, setdefaultDeleteMessage] = useState(true);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [channelName, setChannelName] = useState('');
	const [deleteChannelId, setDeleteChannelId] = useState(null);
	const [addButtonLoader, setAddButtonLoader] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [allLiveChannels, setAllLiveChannels] = useState([]);
	const [channel, setChannel] = useState();
	const [hasMoreStreams, setHasMoreStreams] = useState(false);
	const [openAddUrlPopup, setOpenAddUrlPopup] = useState(false);
	const [newChannelName, setNewChannelName] = useState('');
	const [channelUrl, setChannellUrl] = useState('');
	const [isEditPopup, setIsEditPopup] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [urlNotValid, setUrlNotValid] = useState(false);
	const [prevName, setPrevName] = useState();
	const [prevUrl, setPrevUrl] = useState();
	const [iconKey, setIconKey] = useState(null);
	const [thumbnail, setThumbnail] = useState(null);
	const [prevThumbnail, setPrevThumbnail] = useState(null);
	const [createChannelApiBody, setCreateChannelApiBody] = useState(null);
	const debouncedValue = useDebounce(searchText, 500);
	const [totalCount, setTotalCount] = useState(-1);

	useEffect(() => {
		if (countMultipleCheck < 1) {
			setChecked(false);
			setIsAllChecked(false);
		} else {
			setChecked(true);
			setIsAllChecked(true);
		}
	}, [countMultipleCheck, setCountMultipleCheck]);

	useEffect(() => {
		if (
			isEditPopup &&
			isEmptyString(newChannelName) &&
			isEmptyString(channelUrl) &&
			isEmptyString(iconKey) &&
			isEmptyString(prevThumbnail) &&
			isValidURL(channelUrl) &&
			(prevName !== newChannelName || prevUrl !== channelUrl || prevThumbnail !== iconKey)
		) {
			setIsDisabled(true);

			if (prevThumbnail === iconKey) {
				setCreateChannelApiBody({
					name: newChannelName,
					url: channelUrl,
					live_stream_id: liveChannelId,
					logo: iconKey,
				});
			} else {
				setCreateChannelApiBody({
					name: newChannelName,
					url: channelUrl,
					live_stream_id: liveChannelId,
					logo: `${GLOBAL_CONST.CATEGORY_THUMNAIL_URL}${iconKey}`,
				});
			}
		} else {
			setIsDisabled(false);
		}
	}, [
		channelUrl,
		isEditPopup,
		newChannelName,
		prevName,
		prevUrl,
		prevThumbnail,
		iconKey,
		thumbnail,
	]);

	useEffect(() => {
		if (apiBody.length < 1) {
			setChecked(false);
		}
	}, [apiBody]);

	const handleHasMore = async (response) => {
		if (
			response &&
			response.data &&
			response.data.length > 0 &&
			response.data.length === 20
		) {
			setHasMoreStreams(true);
		} else {
			setHasMoreStreams(false);
		}
		setLoading(false);
	};

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#scrollableDivLiveStreamInChannel');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const fetchLiveStreamsInChannel = async () => {
		const params = {
			offset: offset,
			perPage: RECORD_TO_FETCH,
			live_channel_id: channelId,
			orderBy: GLOBAL_CONST.ORDER_BY.sequence,
			orderType: GLOBAL_CONST.ORDER_TYPE.DESC,
		};
		if (isEmptyString(debouncedValue) && debouncedValue.length > 1) {
			const modifiedDebounceValue = debouncedValue.replace(/\s+/g, ' ').trim();
			params.searchTerm = modifiedDebounceValue;
		}
		const response = await getCommonMethod(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.LIVE_STREAM_BY_CHANNEL}`,
			params,
		);

		if (offset > 0) {
			setAllLiveChannels([...allLiveChannels, ...response.data]);
			handleHasMore(response);
		} else {
			setLoading(true);
			setTotalCount(response?.total_record_count);
			setAllLiveChannels(response.data);
			handleHasMore(response);
		}
	};

	useEffect(() => {
		fetchLiveStreamsInChannel();
	}, [offset, channelId, debouncedValue]);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	useEffect(() => {
		counterToShowDelete > 0 ? setShowDeleteButton(true) : setShowDeleteButton(false);
	}, [counterToShowDelete]);

	const handleDelete = () => {
		setdefaultDeleteMessage(false);
		setShowDeletePopup(true);
	};
	const closeDeleteConfirmationModal = () => {
		setShowDeletePopup(false);
		setdefaultDeleteMessage(true);
	};

	const onDeleteChannel = async () => {
		setAddButtonLoader(true);
		let apiData = [];
		if (deleteChannelId) {
			apiData.push(deleteChannelId);
		} else {
			apiBody.map((item) => {
				apiData.push(item.live_stream_id);
			});
		}
		await deleteStreamsFromChannels(apiData);
	};

	const removeApprovedItem = (form, apiData) => {
		editTableData(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.LIVE_STREAM_BY_CHANNEL}`,
			form,
			({ data }) => {
				const copyOfLiveStreams = [...allLiveChannels];
				apiData.map((val) => {
					const index = copyOfLiveStreams.findIndex(
						(item) => val === item?.live_stream_id,
					);
					copyOfLiveStreams.splice(index, 1);
				});
				setAllLiveChannels(copyOfLiveStreams);
			},
		);
	};

	const deleteStreamsFromChannels = async (apiData) => {
		try {
			const res = await deleteData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.DELETE_LIVE_STREAM_FROM_CHANNEL}`,
				{
					live_stream_ids: apiData,
					live_channel_id: channelId,
				},
			);
			if (res.status) {
				let form = {
					offset: allLiveChannels.length,
					perPage: RECORD_TO_FETCH,
					live_channel_id: channelId,
				};
				removeApprovedItem(form, apiData);
				setCountMultipleCheck(0);
				setCountOnAllUnselected([]);
				setIsAllChecked(false);
				setCounterToShowDelete(0);
				setToastInfo(true, false, res.message);
				if (apiBody.length === allLiveChannels.length && !deleteChannelId) {
					setOffset(-1);
				}
				setApiBody([]);
			}
		} catch (error) {
			setToastInfo(true, true, error.response.data.message);
		}
		setAddButtonLoader(false);
		setShowDeletePopup(false);
		setChannelName('');
		setdefaultDeleteMessage(true);
		setDeleteChannelId(null);
	};

	const handleViewChannel = () => {
		history.push(`/live/channels`);
	};

	const handleCreate = () => {
		setOpenAddUrlPopup(true);
		setIsEditPopup(false);
	};
	const handleChange = (e) => {
		setLoading(true);
		setOffset(0);
		setSearchText(e.target.value);
	};
	const emptySearchBox = () => {
		setLoading(true);
		setOffset(0);
		setSearchText('');
		searchRef.current.value = '';
	};

	const handleLiveChannelId = (id) => {
		setLiveChannelId(id);
	};

	const clearPopupInput = () => {
		setNewChannelName('');
		setChannellUrl('');
		setUrlNotValid(false);
	};

	const onCancelAddPopup = () => {
		setIsEditPopup(false);
		clearPopupInput();
	};

	const onChangeName = (e) => {
		setNewChannelName(() => {
			return e.target.value;
		});
	};

	const onChangeUrl = (e) => {
		setChannellUrl(() => {
			return e.target.value.trim();
		});
	};

	const onAddUrl = async () => {
		setAddButtonLoader(true);

		try {
			const medthodToBeCalled = isEditPopup ? putCommonMethod : postCommonMethod;
			const url = isEditPopup
				? `${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.EDIT_STREAM_URL}`
				: `${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.ADD_STREAM_URL}`;

			let params = {
				live_stream_name: newChannelName,
				live_stream_url: channelUrl,
				logo: `${GLOBAL_CONST.CATEGORY_THUMNAIL_URL}${iconKey}`,
			};
			if (isEditPopup) {
				params = { ...createChannelApiBody };
			}
			const response = await medthodToBeCalled(url, params);
			if (!isEditPopup && response && response.data && response.data.message) {
				setOffset(0);
				// fetchNewData();
				fetchLiveStreamsInChannel();
				clearPopupInput();
				setToastInfo(true, false, response.data.message);
				setAddButtonLoader(false);
				setOpenAddUrlPopup(false);
			} else if (isEditPopup && response && response.status) {
				setOffset(0);
				// fetchNewData();
				fetchLiveStreamsInChannel();
				clearPopupInput();
				setToastInfo(true, false, response.message);
				setAddButtonLoader(false);
				setOpenAddUrlPopup(false);
			}
			setPrevName(newChannelName);
			setPrevUrl(channelUrl);
			setPrevThumbnail(iconKey);
			setIconKey(null);
		} catch (error) {
			setAddButtonLoader(false);
			setToastInfo(true, true, ERROR_MESSAGE);
		}
		setIsEditPopup(false);
	};

	const closeModal = () => {
		setOpenAddUrlPopup(false);
		fetchLiveStreamsInChannel();
	};

	const closeEditModal = () => {
		setIsEditPopup(false);
	};

	const handleChangeOnEdit = (data) => {
		if (data) {
			setLiveChannelId(data.live_stream_id);
			setChannellUrl(data.url);
			setNewChannelName(data.display_name);
			setPrevUrl(data.url);
			setPrevName(data.display_name);
			setThumbnail(data.logo);
			setPrevThumbnail(data.logo);
			setIconKey(data.logo);
			setIsEditPopup(true);
		}
	};

	return (
		<div className="live-channel-right-container">
			<div className="live-channel-header-parent-block">
				<div className="live-header-block-channel">
					<h1>{location.state?.name}</h1>
					{totalCount > -1 ? (
						<div className="count-header-wrapper">&nbsp;( {totalCount} )</div>
					) : null}
				</div>
				<BreadCrumbs value={`Channel-${location.state?.name}`} />
				<div className="live-search-box-container">
					<div className="live-search-with-cancel">
						<DashboardSearchBox
							handleChange={handleChange}
							className="live-channel-search"
							ref={searchRef}
							digestButton
							placeholder="Search by name or keywords"
							handleEnterKeyPress={() => {}}
						/>
						{searchText !== '' && (
							<Button
								label="Cancel"
								labelClassName="cancel-button-label"
								className="cancel-button"
								onClick={emptySearchBox}
							/>
						)}
					</div>
					<div className="live-header-buttons">
						{showDeleteButton && (
							<Tooltip
								overlay="Delete Live Channel"
								placement="bottom"
								overlayInnerStyle={GLOBAL_CONST.overlayStyle}
								align={{
									offset: [-8, -2],
								}}>
								<div>
									<Button
										imageInfo={{
											src: DeleteBucket,
										}}
										className="live-delete-button"
										onClick={handleDelete}
									/>
								</div>
							</Tooltip>
						)}

						<Tooltip
							overlay="Add Live Channel"
							placement="bottom"
							overlayInnerStyle={GLOBAL_CONST.overlayStyle}
							align={{
								offset: [-8, -2],
							}}>
							<div>
								<Button
									imageInfo={{
										src: AddImage,
									}}
									className="live-add-button"
									onClick={handleCreate}
								/>
							</div>
						</Tooltip>
						<AppButton
							label="View All Channels"
							className="live-app-button"
							innerClass="live-app-button-text"
							onClick={handleViewChannel}
						/>
					</div>
				</div>
			</div>
			<div className="content-block">
				<TableContainer
					offset={offset}
					setOffset={setOffset}
					loading={loading}
					setLoading={setLoading}
					setChecked={setChecked}
					isAllChecked={isAllChecked}
					liveChannelId={liveChannelId}
					setIsAllChecked={setIsAllChecked}
					countMultipleCheck={countMultipleCheck}
					setCountMultipleCheck={setCountMultipleCheck}
					countOnAllUnselected={countOnAllUnselected}
					setCountOnAllUnselected={setCountOnAllUnselected}
					handleLiveChannelId={handleLiveChannelId}
					apiBody={apiBody}
					setApiBody={setApiBody}
					openTooltip={openTooltip}
					setOpenTooltip={setOpenTooltip}
					setCounterToShowDelete={setCounterToShowDelete}
					defaultDeleteMessage={defaultDeleteMessage}
					counterToShowDelete={counterToShowDelete}
					showDeletePopup={showDeletePopup}
					setShowDeletePopup={setShowDeletePopup}
					channelName={channelName}
					setChannelName={setChannelName}
					setDeleteChannelId={setDeleteChannelId}
					closeDeleteConfirmationModal={closeDeleteConfirmationModal}
					onDeleteChannel={onDeleteChannel}
					addButtonLoader={addButtonLoader}
					setAddButtonLoader={setAddButtonLoader}
					channel={channel}
					handleChangeOnEdit={handleChangeOnEdit}
					setLiveChannelId={setLiveChannelId}
					tableData={allLiveChannels}
					setAllLiveChannels={setAllLiveChannels}
					hasMoreStreams={hasMoreStreams}
					setToastInfo={setToastInfo}
				/>
			</div>

			<AddStreamsToChannelModal
				isOpen={openAddUrlPopup}
				closeModal={closeModal}
				channelId={channelId}
				name={location.state?.name}
			/>

			<AddUrlPopup
				isOpen={isEditPopup}
				closeModal={closeEditModal}
				message={
					isEditPopup ? ADD_URL_POPUP_CONST.editMessage : ADD_URL_POPUP_CONST.message
				}
				channelName={newChannelName}
				channelUrl={channelUrl}
				onChangeName={onChangeName}
				onChangeUrl={onChangeUrl}
				isDisable={
					isEditPopup
						? isDisabled
						: isEmptyString(newChannelName) &&
						  isValidURL(channelUrl) &&
						  isEmptyString(iconKey) &&
						  iconKey
				}
				onAdd={onAddUrl}
				onCancel={onCancelAddPopup}
				loading={addButtonLoader}
				urlNotValid={urlNotValid}
				isEdit={isEditPopup}
				buttonText={isEditPopup ? 'Update' : 'Add'}
				iconKey={iconKey}
				setIconKey={setIconKey}
				thumbnail={thumbnail}
			/>

			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
			{allLiveChannels?.length > 20 && (
				<ScrollToTop handleScrollToTop={handleScrollToTop} />
			)}
		</div>
	);
};

export default LiveStreamsInChannel;
