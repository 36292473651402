import {
	GET_LIVE_CHANNEL_DATA_INITIAL,
	GET_LIVE_CHANNEL_DATA,
	GET_LIVE_STREAM_DATA,
	GET_LIVE_STREAM_DATA_INITIAL,
	GET_LIVE_CATEGORY_DATA,
	GET_LIVE_CATEGORY_INITIAL,
	EDIT_DATA_ON_LIVE_CHANNEL_TABLE,
	EDIT_DATA_ON_LIVE_STREAM_TABLE,
	EDIT_CATEGORY_DATA,
	LIVE_STREAM_SCROLL,
} from 'src/app/pages/Dashboard/actionTypes';
import LIVE_CHANNEL_INITIAL_STATE from './initialState';

const liveChannelReducer = (state = LIVE_CHANNEL_INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_LIVE_CHANNEL_DATA_INITIAL:
			return {
				...state,
				liveChannels: action.payload || [],
			};
		case GET_LIVE_CHANNEL_DATA:
			return {
				...state,
				liveChannels: [...state.liveChannels, ...action.payload],
			};
		case GET_LIVE_STREAM_DATA:
			return {
				...state,
				liveStreams: [...state.liveStreams, ...action.payload?.data],
				totalStreams: action.payload?.total_record_count,
			};
		case GET_LIVE_STREAM_DATA_INITIAL:
			return {
				...state,
				liveStreams: action.payload?.data,
				totalStreams: action.payload?.total_record_count,
			};
		case GET_LIVE_CATEGORY_INITIAL:
			return {
				...state,
				liveCategories: action.payload,
			};
		case GET_LIVE_CATEGORY_DATA:
			return {
				...state,
				liveCategories: [...state.liveCategories, ...action.payload],
			};
		case EDIT_DATA_ON_LIVE_CHANNEL_TABLE:
			const copyOfLiveChannels = [...state.liveChannels];
			action?.payload.apiData.map((val) => {
				const index = copyOfLiveChannels.findIndex(
					(item) => val === item?.live_channel_id,
				);
				copyOfLiveChannels.splice(index, 1);
			});
			return {
				...state,
				liveChannels: copyOfLiveChannels,
			};
		case EDIT_DATA_ON_LIVE_STREAM_TABLE:
			const copyOfLiveStreams = [...state.liveStreams];
			action?.payload.apiData.map((val) => {
				const index = copyOfLiveStreams.findIndex((item) => val === item?.live_stream_id);
				copyOfLiveStreams.splice(index, 1);
			});
			return {
				...state,
				liveStreams: copyOfLiveStreams,
			};
		case EDIT_CATEGORY_DATA:
			action?.payload.apiData.map((val) => {
				const index = state.liveCategories.findIndex(
					(item) => Number(val) === item?.live_category_id,
				);
				state.liveCategories.splice(index, 1);
			});
			let newLiveCategory = [...state.liveCategories];
			return {
				...state,
				liveCategories: newLiveCategory,
			};
		case LIVE_STREAM_SCROLL:
			return {
				...state,
				liveStreamScrollOffset: action.payload,
			};
		default:
			return state;
	}
};

export default liveChannelReducer;
