import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import DeleteBucketActive from 'src/app/assets/images/DeleteIconActive.svg';
import { ICON_URL } from 'src/app/common/constants/AppConstants';
import ClickAwayListener from 'react-click-away-listener';
import EditImage from 'src/app/assets/images/EditActionButton.svg';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { overlayStyle } from 'src/app/common/constants/AppConstants';
import { useLocation } from 'react-router-dom';
import { getRowClassName } from 'src/app/common/utils/methods';

export const Row = ({
	data,
	isAllChecked,
	handleCheck,
	handleLiveChannelId,
	val,
	handleClick,
	id,
	countOnAllUnselected,
	handleMoreClick,
	openTooltip,
	closeToolTip,
	liveChannelId,
	handleChangeOnDelete,
	handleChangeOnEdit,
	redirectToChannel,
}) => {
	const [icon, setIcon] = useState(DeleteBucket);
	const path = useLocation();
	const onMouseEnterOnDeleteButton = () => {
		setIcon(DeleteBucketActive);
	};
	const onMouseLeaveOnDeleteButton = () => {
		setIcon(DeleteBucket);
	};
	const findValues = id.includes(data?.live_channel_id);
	const valueOnAllUnchecked = countOnAllUnselected.includes(data?.live_channel_id);
	const addDefaultSrc = (ev) => {
		ev.target.className = 'row-icon-img row-icon-img-none';
		ev.target.src = '';
	};

	// const renderTooltipData = () => {
	// 	return (
	// 		<div className="tooltip-wrapper">
	// 			{data?.categories.map((item) => {
	// 				return (
	// 					<div className="category-capsule-outer-wrapper">
	// 						<div className="category-capsule" key={item.live_category_id}>
	// 							<span className="category-capsule-inner-text">{item?.name}</span>
	// 						</div>
	// 					</div>
	// 				);
	// 			})}
	// 		</div>
	// 	);
	// };

	const renderCheckbox = () => {
		return (
			<span className="checkbox">
				{isAllChecked ? (
					findValues ? (
						<input
							type="checkbox"
							id="name-checkbox-row"
							value="all"
							checked={val}
							onChange={handleClick}
						/>
					) : (
						<input
							type="checkbox"
							id="name-checkbox-row"
							value="all"
							checked={isAllChecked}
							onChange={handleClick}
						/>
					)
				) : (
					<input
						type="checkbox"
						id="name-checkbox-row"
						value={data?.name}
						onChange={handleCheck}
						checked={valueOnAllUnchecked}
					/>
				)}

				<span
					className="bucket-name"
					onClick={() => redirectToChannel(data?.live_channel_id, data?.name)}>
					<u>{data?.name}</u>
				</span>
			</span>
		);
	};

	return (
		<tr className={getRowClassName(data?.index)}>
			<td>{renderCheckbox()}</td>
			<td className="row-category">
				<div className="row-category-div">
					<div className="category-capsule" key={data?.live_channel_id}>
						<span className="category-capsule-inner-text">{path.state?.name}</span>
					</div>
					{/* {data?.categories.length < 3 ? (
						data?.categories.map((item) => {
							return (
								<div className="category-capsule" key={item.live_category_id}>
									<span className="category-capsule-inner-text">{item?.name}</span>
								</div>
							);
						})
					) : (
						<div className="row-category-div">
							<div className="category-capsule">
								<span className="category-capsule-inner-text">
									{data?.categories[0].name}
								</span>
							</div>
							<div className="category-capsule">
								<span className="category-capsule-inner-text">
									{data?.categories[1].name}
								</span>
							</div>
							{data?.live_channel_id === liveChannelId && openTooltip ? (
								<ClickAwayListener onClickAway={() => closeToolTip()}>
									<div>
										<div
											className="category-more"
											onClick={(e) => handleMoreClick(e, data?.live_channel_id)}>
											{`+${data?.categories.length - 2} More`}
											<div className={`${openTooltip ? 't-one' : null}`}>
												<div className={`tooltip ${openTooltip ? 'tool' : null}`}>
													{renderTooltipData()}
												</div>
											</div>
										</div>
									</div>
								</ClickAwayListener>
							) : (
								<div
									className="category-more"
									onClick={(e) => handleMoreClick(e, data?.live_channel_id)}
									data-tip
									data-for="registerTip">
									{`+${data?.categories.length - 2} More`}
								</div>
							)}
						</div>
					)} */}
				</div>
			</td>
			<td className="row-icon">
				<img
					onError={addDefaultSrc}
					className="row-icon-img"
					alt="thumbnail"
					src={`${data?.thumbnail}`}></img>
			</td>
			<td className="row-actions">
				<Tooltip
					overlay="Delete Live Sub-Category"
					placement="bottom"
					overlayInnerStyle={overlayStyle}
					align={{
						offset: [-2, -8],
					}}>
					<span
						className="live-channel-action-button"
						onClick={() => handleChangeOnDelete(data?.live_channel_id, data?.name)}
						onMouseEnter={onMouseEnterOnDeleteButton}
						onMouseLeave={onMouseLeaveOnDeleteButton}>
						<img src={icon} className="live-channel-action-button-image" alt="delete" />
					</span>
				</Tooltip>
				<Tooltip
					overlay="Edit Live Sub-Category"
					placement="bottom"
					overlayInnerStyle={overlayStyle}
					align={{
						offset: [-2, -8],
					}}>
					<span className="live-channel-action-button" onClick={handleChangeOnEdit}>
						<img
							src={EditImage}
							className="live-channel-action-button-image-exta-styling"
							alt="edit"
						/>
					</span>
				</Tooltip>
			</td>
		</tr>
	);
};

Row.propTypes = {
	data: PropTypes.object.isRequired,
};
