export const CREATE_LIVE_CHANNEL_POPUP = {
	HEADING: 'Add Sub-Category',
	CANCEL: 'Cancel',
	ADD: 'Add',
};

export const CREATE_LIVE_CHANNEL_POPUP_CUSTOM_POPUP = {
	LABEL: 'CATEGORY NAME',
	PLACEHOLDER: 'Select Category',
	ERROR_VERBOSE: 'Category is required',
	UPLOAD_DEFAULT_ERROR_MESSAGE: 'Error occurs while uploading file, Please try again.',
	INPUT_LABEL: 'SUB-CATEGORY NAME',
	INPUT_PLACEHOLDER: 'Enter Sub-Category Name',
};

export const PROGRESS_BAR_PERCENTAGE = 100;

export const PROGRESS_BAR = {
	strokeWidth: '0.5',
	strokeColor: '#F82C5E',
	trailColor: '#ffffff52',
	style: { width: '90%' },
};

export const PROGRESS_BAR_CATEGORY = {
	strokeWidth: '1.25',
	strokeColor: '#F82C5E',
	trailColor: '#ffffff52',
	style: { width: '44.5%' },
};
