import React, { useState } from 'react';
import '../styles.scss';
import * as CONST from '../constants';

export const LeftPanel = ({ activeTabId, handleTabChange, tagsCountTotal }) => {
	const contentPills = (text, tabId) => {
		return (
			<div
				className={`content-pill-wrapper ${activeTabId === tabId ? 'active-tab' : ''}`}
				key={tabId}
				id={tabId}
				onClick={() => handleTabChange(tabId)}>
				{tabId === 1 ? (
					<p>{`${text} ${tagsCountTotal ? `(${tagsCountTotal})` : ''}`}</p>
				) : (
					<p>{text}</p>
				)}
			</div>
		);
	};
	return (
		<div className="left-panel-container">
			{CONST.LEFT_PANEL_CONSTANTS?.map((item) => {
				return contentPills(item?.label, item?.id);
			})}
		</div>
	);
};

export default LeftPanel;
