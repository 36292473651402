export const LIVE_STREAM_HEADING = 'Live Channels';
export const ADD_LIVE_STREAM = 'Add Live Channels In ';

export const LIVE_MSSG = {
	live_stream_mssg: 'live_stream is already exist in bucket!',
	live_channel_mssg: 'live channel(s) is/are already exist in bucket',
};

export const LIVE_STREAM_TABLE_COLUMNS_CONFIG = [
	{
		key: 'name',
		title: 'Name',
		className: 'name',
		popUpClassName: 'name',
		spanClassName: 'name-span',
		deleteClassName: '',
	},
	{
		key: 'stream-url',
		title: 'Channel URL',
		className: 'stream-url',
		popUpClassName: 'stream-url',
		spanClassName: 'stream-url-span',
		deleteClassName: 'stream-url-delete',
	},
	{
		key: 'subCategory',
		title: 'Sub-Category',
		className: 'sub-category',
		popUpClassName: 'popup-sub-category',
		spanClassName: 'sub-category-span',
		deleteClassName: '',
	},
	{
		key: 'icon',
		title: 'Logo',
		className: 'icon',
		popUpClassName: 'popup-icon',
		spanClassName: 'icon-span',
		deleteClassName: 'stream-icon-deleted',
	},
	{
		key: 'action',
		title: 'Actions',
		className: 'actions',
		popUpClassName: 'actions',
		spanClassName: 'actions-span',
		deleteClassName: '',
	},
];

export const contentTooltipStyle = {
	width: 300,
	maxHeight: 150,
	background: '#2A2A31',
	borderRadius: 12,
	padding: 20,
	overflowY: 'scroll',
	overflowX: 'hidden',
};

export const TABS = [
	{ key: 'all', label: 'All' },
	{ key: 'unmapped', label: 'Unmapped' },
	{ key: 'deleted', label: 'Removed' },
];
