import React, { useEffect } from 'react';
import './styles.scss';
import { validateEmail, validatePhoneNumber } from '../../../common/utils/methods';
import { LOGIN_FORM, SOCIAL_LOGIN_HEADING } from '../constants';
import { ButtonLoader } from 'src/app/components/ButtonLoader';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import APPLELOGO from 'src/app/assets/logos/apple.svg';
import GOOGLELOGO from 'src/app/assets/logos/Google.svg';
import FBLOGO from 'src/app/assets/logos/FB-Meta.svg';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

export const LoginForm = ({
	onLoginClick,
	value,
	setValue,
	showError,
	setShowError,
	isButtonActive,
	setButtonActive,
	isLoggedIn,
	isButtonLoader,
	socialSignin,
}) => {
	useEffect(() => {
		if (value && isValidPhoneNumber(value)) {
			setButtonActive(true);
		} else {
			setShowError(true);
			setButtonActive(false);
		}
	}, [value, setValue]);

	return (
		<div
			className={`loginFormWrapper ${
				isLoggedIn === false ? '' : 'loginFormWrapperTransform'
			}`}>
			<div className="formWrapper">
				<form className="login-form">
					<div className="login-field-wrapper">
						<label className="fieldLabel">{LOGIN_FORM.label}</label>
						<PhoneInput
							international
							withCountryCallingCode={false}
							placeholder={LOGIN_FORM.placeholder}
							value={value}
							onChange={setValue}
							defaultCountry="US"
							maxLength={17}
						/>
						{value && !isValidPhoneNumber(value) && value?.length > 11 && (
							<div className="error-verbose">{LOGIN_FORM.invalidNumber}</div>
						)}
					</div>
					<div className="login-button-wrapper">
						<div className="login-button-container">
							<button
								className={`login-button ${isButtonActive ? '' : 'disabled'}`}
								onClick={onLoginClick}>
								{isButtonLoader ? (
									<ButtonLoader />
								) : (
									<span className="login-button-screen">{LOGIN_FORM.buttonLabel}</span>
								)}
							</button>
						</div>
					</div>
				</form>
				<div className="social-login-container">
					<div className="top-head">
						<div className="seprator-left"></div>
						<div className="social-login-heading">{SOCIAL_LOGIN_HEADING}</div>
						<div className="seprator-right"></div>
					</div>
					<div className="body-container">
						<div
							className="social-button-outer-wrapper"
							onClick={() => socialSignin(CognitoHostedUIIdentityProvider.Apple)}>
							<img src={APPLELOGO} />
						</div>
						<div
							className="social-button-outer-wrapper"
							onClick={() => socialSignin(CognitoHostedUIIdentityProvider.Facebook)}>
							<img src={FBLOGO} />
						</div>
						<div
							className="social-button-outer-wrapper"
							onClick={() => socialSignin(CognitoHostedUIIdentityProvider.Google)}>
							<img src={GOOGLELOGO} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
