import React from "react";
import { useState } from "react";
import { removeAllSpecialCharacters } from "../../common/utils/methods";
import "./FilterCheckBox.styles.scss";

const FilterCheckBox = ({
  name,
  label,
  id,
  type,
  onChangeFilter,
  index,
  length,
  showAllOptions,
}) => {
  if (type === "pills") {
    return (
      <div className="filter-pill">
        {label && (
          <div
            aria-checked={false}
            id={`${type}-${id}`}
            role="checkbox"
            className="pill"
            htmlFor={`${type}-${id}`}
            onClick={onChangeFilter}
          >
            {label}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="filter-checkbox">
      {!showAllOptions && (
        <>
          <input
            type={type}
            id={`${type}-${id}`}
            className="checkbox"
            name={removeAllSpecialCharacters(name)}
            onChange={onChangeFilter}
          />
          {label && <label htmlFor={`${type}-${id}`}>{label}</label>}
        </>
      )}
    </div>
  );
};

export default FilterCheckBox;
