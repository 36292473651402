export const LIVE_CHANNEL_HEADING = 'Live Channels';
export const SEARCH_BOX_PLACEHOLDER = 'Search by name or keywords';
export const LIVE_CHANNEL_TABLE_COLUMNS_CONFIG = [
	{
		key: 'name',
		title: 'Name',
		className: 'name',
		spanClassName: 'name-span',
	},
	{
		key: 'category',
		title: 'Category',
		className: 'category',
		spanClassName: 'category-span',
	},
	{
		key: 'icon',
		title: 'Logo',
		className: 'icon',
		spanClassName: 'icon-span',
	},
	{
		key: 'action',
		title: 'Actions',
		className: 'actions',
		spanClassName: 'actions-span',
	},
];

export const contentTooltipStyle = {
	width: 300,
	maxHeight: 150,
	background: '#2A2A31',
	borderRadius: 12,
	padding: 20,
	overflowY: 'scroll',
	overflowX: 'hidden',
};

export const TOAST_SUCCESS_MESSAGE = 'Successfully added category to sub-category';
export const TOAST_MESSAGE_CHANNEL_EXIST = 'Sub-category already exists in this category';
export const CONDITION_CHECK_FOR_SINGLE =
	'Your live sub-category has already been added to your live category!';
export const CONDITION_CHECK_FOR_MULTIPLE =
	'Your live sub-category have already been added to your live category!';
export const DELETE_MESSAGE = 'Are you sure you want to delete this live sub-category?';
