import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/app/components/Button';
import PopupModal from '../../../../components/molecules/PopupModal';
import {
	addVideoToBucket,
	getCarouselMetadata,
	getCarouselData,
	setPageOffset,
} from '../../actionCreators';
import CarouselData from '../../../../components/molecules/Carousel';
import IconTextButton from '../../../../components/atoms/IconTextButton';
import DashboardSearchBox from '../../../../components/atoms/DashboardSearchBox';
import CustomSelectBox from '../../../../components/atoms/CustomSelectBox';
import './styles.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../../components/Loader';
import { infinteScrollEndMessage } from '../../../ComponentConstant';
import { Toast } from '../../../../components/Toast/index';
import Popup from 'reactjs-popup';
import { TILES_TYPE } from '../../../../common/constants/CarouselScreenConstant';
import TOAST_ERROR_MESSAGE from '../../../../common/constants/ToastMessages';
import { isEmptyString, modifiedDebounceValue } from 'src/app/common/utils/methods';
import {
	ROOT_URL,
	METADATA_URL,
	GET_DASHBOARD_URL,
	ADD_VIDEOS_URL,
	XBYTE_AUTOCOMPLETE_URL,
	SEARCH_ADD_VIDEO,
	SEARCH_SUGGESTION_URL,
	OPEN_SEARCH,
} from '../../../../common/constants/AppConstants';
import {
	popupContentStyle,
	videoDetailsPopupStyle,
} from 'src/app/common/constants/PopupStyles';
import { EditBucketModal } from 'src/app/components/Modals/EditBucketModal';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import {
	getExternalData,
	postCommonMethod,
	getCommonMethod,
} from 'src/app/pages/Dashboard/Service';
import Grid from 'src/app/components/Grid';
import Card from 'src/app/pages/Dashboard/Components/BucketDetails/Components/Card';
import * as DUMMY_CONSTANTS from './constants';
import EmptyImage from 'src/app/assets/images/empty-state.png';
import VerticalTile from 'src/app/components/atoms/VericalTile';
import VideoDetailPopup from 'src/app/components/VideoDetailPopup';
import CreateBucket from 'src/app/components/CreateBucket';

export const Dashboard = () => {
	const dispatch = useDispatch();
	const carouselMetaData = useSelector((state) => state.reservations.carousel?.metaData);
	const carouselData = useSelector((state) => state.reservations.carousel?.data);
	const selectedBucket = useSelector((state) => state.reservations.selectedBucket);
	const searchRef = useRef('');
	const [searchText, setSearchText] = useState('');
	const [enterPressed, setEnterPressed] = useState(false);
	const [val, setVal] = useState([]);
	const [count, setCount] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const height = window.screen.height;
	const [isOpen, setIsOpen] = useState(false);
	const [videoId, setVideoId] = useState(null);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [bucketListUpdateFlag, setBucketListUpdateFlag] = useState(false);
	const [isCalendarVisible, setIsCalendarVisible] = useState(false);
	const [isThumbnailPickerVisible, setIsThumbnailPickerVisible] = useState(false);

	// For Edit Bucket Modal
	const [bucketId, setBucketId] = useState(null);
	const [openEditBucketModal, setOpenEditBucketModal] = useState();
	const [noAutoScroll, setNoAutoScroll] = useState(false);
	const debouncedValue = useDebounce(searchText, 500);
	const [searchData, setSearchData] = useState([]);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [searchVideo, setSearchVideo] = useState(null);
	const [showVideoDetailPopup, setShowVideoDetailPopup] = useState(false);
	const [popupVideoId, setPopupVideoId] = useState(null);
	const [contentType, setContentType] = useState(null);
	const [firstLoader, setFirstLoader] = useState(false);
	const loaderCount = useRef(0);

	useEffect(() => {
		if (height > 1400) {
			fetchMoreData();
		}
	}, []);

	useEffect(() => {
		if (!carouselMetaData) {
			dispatch(getCarouselMetadata(`${ROOT_URL}${METADATA_URL}`));
		}
	}, [dispatch]);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setShowToast(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	useEffect(() => {
		if (carouselMetaData) {
			if (carouselData) {
				let reVal = [];
				Object.keys(carouselData.data).map(function (keyName, keyIndex) {
					reVal.push(carouselMetaData[keyIndex]?.bucket_id);
				});
				setVal(reVal);
				setCount(Object.keys(carouselData?.data)?.length - 1);
			} else {
				setVal(
					[
						carouselMetaData[0]?.bucket_id,
						carouselMetaData[1]?.bucket_id,
						carouselMetaData[2]?.bucket_id,
					].filter(Boolean),
				);
				setCount(2);
			}
		}
	}, [carouselMetaData]);

	useEffect(() => {
		if (carouselData) {
			if (val.length > Object.keys(carouselData?.data)?.length) {
				dispatch(
					getCarouselData(`${ROOT_URL}${GET_DASHBOARD_URL}?page=1&perPage=20`, {
						bucket_ids: val,
					}),
				);
			}
		} else {
			if (val.length > 0) {
				dispatch(
					getCarouselData(`${ROOT_URL}${GET_DASHBOARD_URL}?page=1&perPage=20`, {
						bucket_ids: val,
					}),
				);
			}
		}
	}, [val]);

	useEffect(() => {
		setLoading(true);
		if (debouncedValue) {
			//&& debouncedValue?.length > 2
			loaderCount.current = loaderCount.current + 1;
			if (page === 1) {
				fetchSearchData();
			} else {
				setPage(1);
			}
			setHasMore(true);
		} else {
			// emptySearchBox();
			setSearchData([]);
			loaderCount.current = 0;
			setLoading(false);
			if (val.length > 0 && val.length === carouselMetaData.length) {
				setHasMore(false);
			} else {
				setHasMore(true);
			}
		}
		// setSearchData([]);
	}, [debouncedValue]);

	useEffect(() => {
		if (debouncedValue) {
			// && debouncedValue?.length > 2
			fetchSearchData();
		}
	}, [page]);

	const handleHasMore = (response) => {
		if (
			response &&
			response.data &&
			response.data.length > 0 &&
			response.data.length === DUMMY_CONSTANTS.FETCH_TILES_COUNT
		) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
		setLoading(false);
	};

	const fetchSearchData = async () => {
		if (loaderCount.current === 1) {
			setFirstLoader(true);
		}
		const params = {
			text: '',
			page: page > 1 ? page : 1,
			perPage: DUMMY_CONSTANTS.FETCH_TILES_COUNT,
			search_type: 'movie_show',
		};
		if (isEmptyString(debouncedValue) && debouncedValue?.length) {
			params.text = modifiedDebounceValue(debouncedValue);
		}
		try {
			// const res = await getExternalData(`${XBYTE_AUTOCOMPLETE_URL}`, params);
			const res = await getCommonMethod(`${ROOT_URL}${OPEN_SEARCH}`, params);
			if (res.status) {
				if (page > 1) {
					setSearchData([...searchData, ...res?.data]);
					handleHasMore(res);
				} else {
					setLoading(true);
					setSearchData(res?.data);
					setPage(2);
					handleHasMore(res);
				}
				setFirstLoader(false);
			}
		} catch (error) {
			setShowToastMessage(DUMMY_CONSTANTS.ERROR_VERBOSE);
			setToastError(true);
			setShowToast(true);
			setTimeout(() => {
				emptySearchBox();
			}, 500);
			// setLoading(false);
		}
	};

	const handleChange = (e) => {
		setTimeout(() => {
			const element = document.querySelector('.content-block');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 20);
		setSearchText(e.target.value);
	};

	const emptySearchBox = () => {
		setLoading(true);
		setSearchData([]);
		setSearchText('');
		loaderCount.current = 0;
		searchRef.current.value = '';
		setTimeout(() => {
			const element = document.querySelector('.content-block');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 20);
		setTimeout(() => {
			setLoading(false);
		}, 500);
	};

	useEffect(() => {
		if (selectedBucket !== null && videoId !== null) {
			dispatch(
				addVideoToBucket(
					`${ROOT_URL}${ADD_VIDEOS_URL}`,
					{
						video_ids: [videoId],
						bucket_id: selectedBucket?.bucket_id,
					},
					({ data, response }) => {
						if (data) {
							setToastError(false);
							setShowToastMessage(TOAST_ERROR_MESSAGE.SUCCESSFULLY_ADDED_TO_BUCKET);
							setShowToast(true);
						} else if (response) {
							const errorMessage = response?.data?.message;
							setShowToastMessage(errorMessage);
							setToastError(true);
							setShowToast(true);
						}
					},
				),
			);
		}
	}, [videoId, selectedBucket, dispatch]);

	const addSearchVideoToBucket = async () => {
		const params = {
			bucket_id: selectedBucket?.bucket_id,
			videos: [
				{
					video_id: searchVideo.video_id,
					title: searchVideo.title,
					thumbnail: searchVideo.thumbnail,
					horizontal_thumbnail: searchVideo.horizontal_thumbnail,
					content_type: searchVideo.content_type,
				},
			],
		};
		const res = await postCommonMethod(`${ROOT_URL}${SEARCH_ADD_VIDEO}`, params);
		if (res?.data?.status) {
			setToastError(false);
			setShowToastMessage(DUMMY_CONSTANTS.SEARCH_TOAST_CONSTANTS[res?.data?.message]);
			setShowToast(true);
		} else {
			setToastError(true);
			setShowToastMessage(DUMMY_CONSTANTS.ERROR_VERBOSE);
			setShowToast(true);
		}
	};

	useEffect(() => {
		if (selectedBucket !== null && searchVideo !== null) {
			addSearchVideoToBucket();
		}
	}, [searchVideo, selectedBucket]);

	const closeEditModal = (val) => {
		setOpenEditBucketModal(val);
	};

	const openEditModal = (id) => {
		setBucketId(id);
		setOpenEditBucketModal(true);
		setNoAutoScroll(true);
	};
	const openToast = (message, isError, show) => {
		setShowToast({ show: show, message: message, isError: isError });
	};

	const toggleModal = () => {
		setIsOpen(!isOpen);
	};

	const closeModal = () => {
		setIsOpen(false);
		setIsCalendarVisible(false);
		setIsThumbnailPickerVisible(false);
	};

	const handleAddButton = (value) => {
		if (!selectedBucket) {
			setToastError(true);
			setShowToastMessage(TOAST_ERROR_MESSAGE.SELECT_BUCKET_FIRST);
			setShowToast(true);
			return;
		}
		setVideoId((prev) => {
			if (prev === value) {
				setToastError(true);
				setShowToastMessage(TOAST_ERROR_MESSAGE.VIDEO_ALREADY_EXIST);
				setShowToast(true);
			}
			return value;
		});
	};
	const handleAddButtonForSearch = (value) => {
		setShowToast(false);
		setShowToastMessage('');
		setToastError(false);
		if (!selectedBucket) {
			setToastError(true);
			setShowToastMessage(TOAST_ERROR_MESSAGE.SELECT_BUCKET_FIRST);
			setShowToast(true);
			return;
		}
		setSearchVideo((prev) => {
			if (prev === value) {
				setToastError(true);
				setShowToastMessage(TOAST_ERROR_MESSAGE.VIDEO_ALREADY_EXIST);
				setShowToast(true);
			}
			return value;
		});
	};
	const handleToastDismiss = () => {
		setShowToast(false);
	};

	const handleClick = (id, type) => {
		setShowVideoDetailPopup(true);
		setPopupVideoId(id);
		setContentType(type);
	};

	const closeVideoDetailsPopup = () => {
		setShowVideoDetailPopup(false);
		setPopupVideoId(null);
		setContentType(null);
	};

	const resetValueOnFetching = () => {
		if (carouselMetaData && val.length <= carouselMetaData.length - 3) {
			setVal([
				...val,
				carouselMetaData[count + 1]?.bucket_id,
				carouselMetaData[count + 2]?.bucket_id,
				carouselMetaData[count + 3]?.bucket_id,
			]);
			setCount((value) => value + 3);
		} else {
			if (carouselMetaData) {
				setVal([...val, carouselMetaData[count + 1]?.bucket_id]);
				setCount((value) => value + 1);
			}
		}
	};
	const fetchMoreData = () => {
		if (!debouncedValue) {
			//|| debouncedValue?.length < 3
			if (val.length > 0 && val.length === carouselMetaData.length) {
				setHasMore(false);
				return;
			}
			resetValueOnFetching();
		} else {
			if (hasMore) {
				setPage((val) => val + 1);
			}
		}
	};
	const newBucketCreated = ({ response, message }) => {
		setBucketListUpdateFlag(!bucketListUpdateFlag);
		if (message) {
			setToastError(false);
			setShowToastMessage(message);
			setShowToast(true);
		} else if (response) {
			setToastError(true);
			setShowToastMessage(response.data?.message);
			setShowToast(true);
		}
	};

	const onSelectingCards = () => {};
	const emptyState = debouncedValue?.length > 2 && searchData.length === 0 && !loading;

	return (
		<div className="right-container">
			<div className="header-parent-block">
				<div className="header-block">
					<div>
						<h1 className="heading">Dashboard</h1>
					</div>
					<div className="dashboard-button-block">
						<IconTextButton
							buttonText="+ Create Bucket"
							handleClick={() => toggleModal()}
						/>
					</div>
				</div>
				<div className="search-box-block">
					<div className="live-search-with-cancel">
						<DashboardSearchBox
							value={searchText}
							handleChange={handleChange}
							className="live-channel-search"
							ref={searchRef}
							placeholder={DUMMY_CONSTANTS.SEARCHBAR_PLACEHOLDER}
						/>
						{searchText !== '' && (
							<Button
								label="Cancel"
								labelClassName="cancel-button-label"
								className="cancel-button"
								onClick={emptySearchBox}
							/>
						)}
					</div>

					<CustomSelectBox
						fromScreen="dashboard"
						handleToastDismiss={handleToastDismiss}
						updateFlag={bucketListUpdateFlag}
						idFieldName={'bucket_id'}
						enableDropdown
						searchEnable={true}
					/>
				</div>
			</div>
			<div id="content-block" className="content-block">
				{carouselData &&
				carouselData.data &&
				Object.keys(carouselData.data).length > 0 ? (
					<InfiniteScroll
						dataLength={
							debouncedValue //&& debouncedValue?.length > 2
								? searchData?.length
								: Object.keys(carouselData.data).length
						}
						next={!loading && fetchMoreData}
						hasMore={hasMore}
						loader={!loading && <Loader loadMore={true} />}
						endMessage={
							debouncedValue //&& debouncedValue?.length > 2
								? null
								: infinteScrollEndMessage()
						}
						scrollThreshold={debouncedValue ? 0.2 : 0.8}
						scrollableTarget="content-block">
						{!debouncedValue && //|| debouncedValue?.length < 3
							Object.keys(carouselData.data).map(function (keyName, keyIndex) {
								return (
									<CarouselData
										key={keyIndex}
										compId={keyIndex}
										bucketDetail={{
											title: keyName,
											bucketId: carouselData?.data[keyName][0].bucket_id,
										}}
										tileType={
											keyIndex === 0 ? TILES_TYPE.vertical : TILES_TYPE.horizontal
										}
										carouselData={carouselData}
										handleVideo={handleAddButton}
										openEditBucketModal={() => {
											openEditModal(val[keyIndex]);
										}}
										loader={loading}
										handleClick={handleClick}
									/>
								);
							})}
						{debouncedValue && ( // !loading &&
							<Grid fromSearch>
								{searchData?.length > 0 &&
									searchData.map((item) => {
										return (
											<VerticalTile
												key={item.video_id}
												tileDetails={item}
												handleVideo={handleAddButtonForSearch}
												fromSearch={true}
												handleClick={() =>
													handleClick(item?.video_id, item?.content_type)
												}
											/>
										);
									})}
							</Grid>
						)}
						{firstLoader && ( //loading
							<Grid fromSearch>
								{[...Array(DUMMY_CONSTANTS.FETCH_TILES_COUNT)].map((i) => {
									return <Card loader={true} fromSearch />;
								})}
							</Grid>
						)}
					</InfiniteScroll>
				) : (
					<Loader />
				)}
				{emptyState && (
					<div className="empty-image">
						<img src={EmptyImage} alt="no results found" />
						<h3 className="no-filter-found">{DUMMY_CONSTANTS.EMPTY_STATE_MESSAGE}</h3>
					</div>
				)}

				<CreateBucket
					isOpen={isOpen}
					closeModal={closeModal}
					toggleModal={toggleModal}
					newBucketCreated={newBucketCreated}
				/>
				<EditBucketModal
					bucketId={bucketId}
					isOpen={openEditBucketModal}
					setIsOpen={closeEditModal}
					showToast={openToast}
					isEdit
					noAutoScroll={noAutoScroll}
					setNoAutoScroll={setNoAutoScroll}
					isDisable={true}
				/>
				<Popup
					className="popup-video-details"
					open={showVideoDetailPopup}
					closeOnDocumentClick
					onClose={closeVideoDetailsPopup}
					contentStyle={videoDetailsPopupStyle}>
					<VideoDetailPopup
						videoId={popupVideoId}
						closePopup={closeVideoDetailsPopup}
						contentType={contentType}
					/>
				</Popup>
				{showToast ? (
					<Toast
						message={showToastMessage}
						onDismiss={handleToastDismiss}
						isError={toastError}
					/>
				) : null}
			</div>
		</div>
	);
};
