import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import EpisodeTab from 'src/app/components/molecules/EpisodesTab';
import { GET_EPISODE_DETAILS, ROOT_URL } from 'src/app/common/constants/AppConstants';
import { getCommonMethod } from 'src/app/pages/Dashboard/Service';
import { Loader } from 'src/app/components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IMAGE_PLACEHOLDER_HORIZONTAL } from 'src/app/common/constants/AppConstants';
import { RECORD_TO_FETCH, EPISODE_ROUND_COUNT } from './constant';

export const Episodes = ({ data, videoId, handleEpisodeDetails }) => {
	const [loading, setLoading] = useState(true);
	const [episodeData, setEpisodeData] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const activeTab = useRef(data?.seasons[0]);
	const [page, setPage] = useState(1);

	const [expandEpisode, setExpandEpisode] = useState(null);

	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
	};

	const handleTabChange = (index, id) => {
		setLoading(true);
		activeTab.current = id;
		if (page > 1) {
			setPage(1);
		} else {
			fetchEpisodeData();
		}
	};

	useEffect(() => {
		fetchEpisodeData();
	}, [page]);

	const getEpisodeNumber = (val) => {
		return val > EPISODE_ROUND_COUNT ? val : '0' + val;
	};

	const toggleExpansion = (val) => {
		setExpandEpisode(val);
	};

	const fetchEpisodeData = async () => {
		try {
			const params = {
				season_no: activeTab.current,
				show_reelgood_id: data?.show_reelgood_id,
				page: page,
				perPage: RECORD_TO_FETCH,
			};
			const res = await getCommonMethod(`${ROOT_URL}${GET_EPISODE_DETAILS}`, params);
			setLoading(false);
			handleHasMore(res);
			if (page > 1) {
				setEpisodeData([...episodeData, ...res?.data]);
			} else {
				setEpisodeData(res?.data);
			}
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	};

	const handleHasMore = (response) => {
		if (response?.data?.length > 0 && response?.data?.length === RECORD_TO_FETCH) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const fetchMoreData = () => {
		setPage((val) => val + 1);
	};

	const renderEpisodes = (item) => {
		return (
			<div className="episode-details-wrapper" key={item?.video_id}>
				<img
					onError={addDefaultSrc}
					src={item?.thumbnail || IMAGE_PLACEHOLDER_HORIZONTAL}
					alt="Episode Thumbnail"
					className="episode-image"
					onClick={() => handleEpisodeDetails(item?.video_id)}
				/>
				<div className="episode-info">
					<div className="episode-number">{`E${getEpisodeNumber(item?.episode_no)}`}</div>
					<div className="episode-name">{`${item?.title}`}</div>
				</div>
			</div>
		);
	};

	return (
		<div className="episodes-container">
			<EpisodeTab
				tabs={data?.seasons}
				active={activeTab.current}
				handleTabChange={handleTabChange}
			/>
			{loading ? (
				<div className="episodes-detail-loader-container">
					<Loader />
				</div>
			) : (
				episodeData?.length && (
					<div
						className={` ${
							expandEpisode ? 'episode-details-expand ' : 'episode-details-container'
						}`}
						id="episode-details-container">
						<InfiniteScroll
							dataLength={episodeData?.length}
							next={fetchMoreData}
							hasMore={hasMore}
							loader={<Loader />}
							scrollableTarget="episode-details-container">
							{episodeData.map((item) => {
								return renderEpisodes(item);
							})}
						</InfiniteScroll>
					</div>
				)
			)}
			{episodeData.length > 3 &&
				(expandEpisode ? (
					<span
						className="span-more-less"
						onClick={() => {
							toggleExpansion(false);
						}}>
						Less
					</span>
				) : (
					<span
						className="span-more-less"
						onClick={() => {
							toggleExpansion(true);
						}}>
						More
					</span>
				))}
		</div>
	);
};

export default Episodes;
