export const BROWSE_HEADING = 'Browse a file from your computer';
export const BROWSE_FILE = 'Browse File';
export const OR = 'OR';
export const COPY_URL_HEADING = 'Copy image URL from any website';
export const COPY_URL_PLACEHOLDER = 'Paste URL here and press enter';
export const CANCEL = 'Cancel';
export const UPLOAD = 'Upload & Save';
export const SIZE = 2000001;
export const CORS_ERROR =
	'This image is not accessible outside its website. Please choose another image.';
export const NON_IMAGE_ERROR = 'Invalid image URL';
export const SIZE_ERROR = "Upload file size can't exceed 2 MB.";
export const PUBLIC_BUCKET = 'qewd-public-bucket';
export const CAST_BUCKET = 'qewd-cast-images';
