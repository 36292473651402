export const BUCKET = {
	HEADING: 'Buckets',
};

export const popupContentStyle = {
	width: 327,
	height: 176,
	background: '#2A2A31',
	marginTop: 300,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
};
export const publishedPopupStyles = {
	width: 327,
	height: 210,
	background: '#2A2A31',
	marginTop: 300,
	boxShadow: '0px 18px 26px 0px #00000059',
	borderRadius: 12,
};

export const TABS = [
	{ id: 'published', label: 'Published' },
	{ id: 'unpublished', label: 'Unpublished' },
	{ id: 'draft', label: 'Draft' },
	{ id: 'archived', label: 'Archived' },
];

export const TABS_LIVE = [
	{ id: 'published', label: 'Published' },
	{ id: 'unpublished', label: 'Unpublished' },
	{ id: 'draft', label: 'Draft' },
];

export const ARCHIVE_CONST = {
	archiveLabel: 'Mark as Archive',
};

export const STATUS = {
	published: 'published',
	unpublished: 'unpublished',
	draft: 'draft',
	archived: 'archived',
};

export const LIST_FOR_YOU_SLUG = 'lists-for-you';
export const LIST_FOR_YOU_HEADING = 'Mark as List for You';
