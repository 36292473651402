import React from 'react';
import './styles.scss';
import { ButtonLoader } from '../../ButtonLoader/index';

const IconTextButton = ({
	buttonText,
	handleClick,
	disabled,
	showLoader = false,
	fromWaitlist = false,
	settingsScreen = false,
}) => {
	return (
		<button
			className={`button-container ${disabled ? 'disabled' : ''} ${
				fromWaitlist ? 'extra-styling' : ''
			} ${settingsScreen ? 'setting-styling' : ''}`}
			onClick={handleClick}>
			{showLoader ? <ButtonLoader /> : <p className="button-text">{buttonText}</p>}
		</button>
	);
};

export default IconTextButton;
