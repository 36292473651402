import React from 'react';
import './styles.scss';
import loader from 'src/app/assets/images/WhiteLoader.gif';

export const ButtonLoader = ({ fromVerifyPopup = false }) => {
	return (
		<div className={`${fromVerifyPopup ? '' : 'loaderButton'}`}>
			<img className="button-loader-img" src={loader} alt="loader" />
		</div>
	);
};
