export const LIST_FOR_YOU_TABLE_COLUMNS_CONFIG = [
	{
		key: 'name',
		title: 'Name',
		className: 'name',
		spanClassName: 'name-span',
	},
	{
		key: 'dateRange_key',
		title: 'Date Range',
		className: 'dateRange',
		spanClassName: 'date-span',
	},
	{
		key: 'videoCount_key',
		title: 'No. of videos',
		className: 'videoCount',
		spanClassName: 'video-span',
	},
	{
		key: 'action',
		title: 'Action',
		className: 'action',
		spanClassName: 'action-span',
	},
];
export const LIST_FOR_YOU_HEADING = 'List For You';
export const EDIT_OPTION = [{ value: 'remove', label: 'Remove' }];
export const LIST_FOR_YOU_SLUG = 'lists-for-you';
export const RECORD_TO_FETCH = 20;
export const SEARCH_BOX_PLACEHOLDER = 'Search by name';
