import React, { useEffect, useState, useRef } from 'react';
import { profileBackgroundColor } from 'src/app/pages/AllUsers/constants';
import EditImage from 'src/app/assets/images/EditActionButton.svg';
import Tick from 'src/app/assets/images/PublishTick.svg';
import * as CONST from 'src/app/pages/AllUsers/constants';
import { getRowClassName } from 'src/app/common/utils/methods';
import blueTick from 'src/app/assets/images/blueTick.svg';
import { ButtonLoader } from 'src/app/components/ButtonLoader';
import CarouselMoreOptionBox from 'src/app/components/atoms/CarouselMoreOptionBox';
import Kebab from 'src/app/assets/logos/Kebab.svg';
import ClickAwayListener from 'react-click-away-listener';
import Vertmore from 'src/app/assets/logos/VertMore.svg';

export const Row = ({
	data,
	updateInviteCount,
	setToastInfo,
	onHandleClick,
	userData,
	deactivateUser,
	openDeleteConfirmationModal,
}) => {
	const [isAvatar, setIsAvatar] = useState(false);
	const [showTextField, setShowTextField] = useState(false);
	const [inviteCount, setInviteCount] = useState(data?.invite_count || 0);
	const [showLoader, setShowLoader] = useState(false);
	const inputRef = useRef(null);
	const [showOptionDropdown, setShowOptionDropdown] = useState(false);

	const sectionOneEl = document.querySelector('#scrollableDivAllUsers');
	sectionOneEl?.addEventListener('scroll', (ev) => {
		if (showOptionDropdown) {
			setShowOptionDropdown(false);
		}
	});

	const handleSelect = (option) => {
		if (option?.value === 'delete') {
			openDeleteConfirmationModal(data?.user_id);
		} else if (option?.value === 'deactivate') {
			deactivateUser(data?.user_id, true, data?.first_name, data?.last_name);
		} else if (option?.value === 'activate') {
			deactivateUser(data?.user_id, false, data?.first_name, data?.last_name);
		}
		setShowOptionDropdown(false);
	};

	useEffect(() => {
		if (data?.avatar) {
			setIsAvatar(true);
		}
	}, [data]);

	const addDefaultSrc = (ev) => {
		setIsAvatar(false);
		ev.target.className = 'user-icon-error';
	};

	const onEditIconPress = () => {
		setShowTextField(true);
	};

	const editInviteCount = async () => {
		setShowLoader(true);
		if (
			inputRef.current.value > -1 &&
			inputRef.current.value < 101 &&
			Number(inputRef.current.value) !== inviteCount &&
			Number.isInteger(Number(inputRef.current.value))
		) {
			setInviteCount(inputRef.current.value);
			await updateInviteCount(
				inputRef.current.value,
				data?.user_id,
				data?.user_type,
				data?.first_name,
			);
		} else if (!Number.isInteger(Number(inputRef.current.value))) {
			setToastInfo(true, true, CONST.ONLY_INTEGER_ALLOWED);
		} else if (inputRef.current.value > 100) {
			setToastInfo(true, true, CONST.INVITE_UPPER_COUNT);
		} else if (inputRef.current.value < 0) {
			setToastInfo(true, true, CONST.INVITE_LOWER_COUNT);
		}
		setShowTextField(false);
		setShowLoader(false);
	};

	const getProfileBackground = (name) => {
		const nameIndex = (name && name.length > 0 ? name[0].charCodeAt(0) : 1) % 7;
		return profileBackgroundColor[nameIndex];
	};

	const closeCountTextbox = () => {
		setShowTextField(false);
		setShowLoader(false);
	};

	const getProfileInitials = () => {
		return (
			`${data?.first_name?.charAt(0)?.toUpperCase() || ''}${
				data?.last_name?.charAt(0)?.toUpperCase() || ''
			}` || ''
		);
	};

	return (
		<tr className={`${getRowClassName(data?.index)} `}>
			<td
				className={`user-name-row ${
					userData[data?.index]?.is_deactivate === 1 ? 'deamed' : ''
				}`}>
				<div className="user-name-wrapper" onClick={() => onHandleClick(data)}>
					{isAvatar ? (
						<img
							className={`image-profile ${data?.avatar ? '' : 'user-icon-error'}`}
							alt="user-profile"
							src={data?.avatar}
							onError={addDefaultSrc}
						/>
					) : (
						<div
							className={`image-profile user-icon-error`}
							style={{ background: `${getProfileBackground(data?.first_name)}` }}>
							<span className="user-name-error-span">{getProfileInitials()}</span>
						</div>
					)}
					{userData[data?.index]?.is_verified ? (
						<img className="verified-tick" src={blueTick} alt="Verified User" />
					) : null}
					<div
						className={`${
							userData[data?.index]?.account_type !== 'Business' ? 'extra-margin-top' : ''
						}`}>
						<span className="user-name">{`${data?.first_name || ''} ${
							data?.last_name || ''
						}`}</span>
						{userData[data?.index]?.account_type === 'Business' && (
							<div className="ba-tag">Business Account</div>
						)}
					</div>
				</div>
			</td>
			<td
				className={`user-email-row ${
					userData[data?.index]?.is_deactivate === 1 ? 'deamed' : ''
				}`}>
				<div className="row-num">{data?.phone_number}</div>
				<div className="row-mail">{data?.user_email}</div>
			</td>
			<td className="user-total-videos-row">
				<p className="user-invite-count">{data?.videos}</p>
			</td>
			<td className="user-followers-row">
				<p className="user-invite-count">{data?.followers}</p>
			</td>
			<td
				className={`user-invite-row ${
					userData[data?.index]?.is_deactivate === 1 ? 'deamed' : ''
				}`}>
				{!showTextField && (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<p className="user-invite-count">{inviteCount}</p>
						<span
							className="edit-icon"
							onClick={
								userData[data?.index]?.is_deactivate === 1 ? null : onEditIconPress
							}>
							<img src={EditImage} className="user-invite-edit-icon" alt="edit" />
						</span>
					</div>
				)}
				{showTextField && (
					<div style={{ marginLeft: 30 }}>
						<input
							ref={inputRef}
							defaultValue={!showLoader ? inviteCount : ''}
							className={`user-invite-count-input ${showLoader ? 'loader-input' : ''}`}
							type="number"
							id="invite_count"
							name="invite_count"
							min={0}
							max={100}
							autoFocus
							disabled={userData[data?.index]?.is_deactivate === 1 ? true : false}
						/>
						{!showLoader && (
							<span
								className="edit-icon"
								onClick={
									userData[data?.index]?.is_deactivate === 1 ? null : editInviteCount
								}>
								<img src={Tick} className="user-invite-edit-icon" alt="Update Invite" />
							</span>
						)}
					</div>
				)}
			</td>
			<td className="user-action">
				<ClickAwayListener onClickAway={() => setShowOptionDropdown(false)}>
					<div>
						<div className={`options ${showOptionDropdown ? 'padding-more' : ''}`}>
							{!showOptionDropdown ? (
								<img
									src={Vertmore}
									alt="dots"
									className="dots"
									onClick={() => setShowOptionDropdown(!showOptionDropdown)}
								/>
							) : (
								<img
									src={Kebab}
									className="selected-dots"
									alt="dots"
									onClick={() => setShowOptionDropdown(!showOptionDropdown)}
								/>
							)}
						</div>
						{showOptionDropdown && (
							<div className="kebab-dropdown">
								<CarouselMoreOptionBox
									from="carousel"
									options={CONST.EDIT_OPTION}
									optionNotVisible={
										userData[data?.index]?.is_deactivate === 1
											? {
													value: true,
													column: 'deactivate',
											  }
											: {
													value: true,
													column: 'activate',
											  }
									}
									handleSelect={handleSelect}
								/>
							</div>
						)}
					</div>
				</ClickAwayListener>
			</td>
		</tr>
	);
};

export default Row;
