export const sortByFilterValues = {
  title: "Sort By",
  id: 1,
  option_type: "dropDown",
  options: [
    {
      name: "IMDB Rating - Ascending",
      option_id: "imdb_rating-asc",
    },
    {
      name: "IMDB Rating - Descending",
      option_id: "imdb_rating-desc",
    },
    {
      name: "Title (A-Z)",
      option_id: "title-asc",
    },
    {
      name: "Title (Z-A)",
      option_id: "title-desc",
    },
    {
      name: "Release Date - Ascending",
      option_id: "released_date-asc",
    },
    {
      name: "Release Date - Descending",
      option_id: "released_date-desc",
    },
  ],
};

export const directorFilterValues = {
  title: "Directors",
  id: 4,
  option_type: "dropdown",
  options: [
    {
      name: "Quentin Tarantino",
      option_id: 1,
    },
    {
      name: "Martin Scorsese",
      option_id: 2,
    },
    {
      name: "Steven Spielberg",
      option_id: 3,
    },
    {
      name: "Christopher Nolan",
      option_id: 4,
    },
    {
      name: "David Fincher",
      option_id: 5,
    },
  ],
};

export const movieCastFilterValues = {
  title: "Movie Cast",
  id: 5,
  option_type: "dropdown",
  options: [
    {
      name: "Leonardo DiCaprio",
      option_id: 1,
    },
    {
      name: "Robert De Niro",
      option_id: 2,
    },
    {
      name: "Denzel Washington",
      option_id: 3,
    },
    {
      name: "Tom Hanks",
      option_id: 4,
    },
    {
      name: "Joaquin Phoenix",
      option_id: 5,
    },
  ],
};
