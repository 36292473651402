export const RECORD_TO_FETCH = 20;
export const SUCCESS_ADD = 'Video added to Bucket Successfully';
export const EMPTY_STATE_MESSAGE = {
	NO_FILTER_RESULT: 'No filter result found!',
	PLEASE_CHANGE_FILTER: 'Please add videos',
	CRITERIA_TO_GET_RESULTS: 'to your bucket',
};

export const TITLE_MAPPER = {
	'Trending Now on YouTube': 'n',
	'Trending Music on YouTube': 'mu',
	'Trending Movies on YouTube': 'mo',
	'Trending in Gaming on YouTube': 'g',
};

export const SHUFFLE_CONST = {
	reorder_message: 'Want to re-order listing?',
	click_shuffle: 'Click to Shuffle',
	reorder_done: 'Done with reordering?',
	click_back: 'Back to Normal View',
};

export const VIEW_TYPE = {
	grid: 'grid',
	list: 'list',
};

export const TOAST_ERROR_MESSAGE = {
	SELECT_MOVIE_TO_DELETE: 'Please select video(s) to delete',
	SELECT_BUCKET_FIRST: 'Please select bucket first',
	SUCCESSFULLY_ADDED_TO_BUCKET: 'Successfully added to bucket',
	VIDEO_ALREADY_EXIST: 'Video already exist in bucket',
};

export const VIDEO_DETAILS_TABLE_COLUMNS_CONFIG = [
	{
		key: 'title',
		title: 'Titles',
		className: 'name',
		spanClassName: 'title-span',
	},
	{
		key: 'channel',
		title: 'Description',
		className: 'channel',
		spanClassName: 'channel-span',
	},
	{
		key: 'add',
		title: '',
		className: 'add',
		spanClassName: 'add-span',
	},
];

export const VIDEO_DETAILS_TABLE_COLUMNS_CONFIG_CHANNEL_DATA = [
	{
		key: 'title',
		title: 'Titles',
		className: 'name',
		spanClassName: 'title-span',
	},
	{
		key: 'channel',
		title: 'Channel',
		className: 'channel',
		spanClassName: 'channel-span',
	},
	{
		key: 'publishTime',
		title: 'Published',
		className: 'publishTime',
		spanClassName: 'publishTime-span',
	},
	{
		key: 'add',
		title: '',
		className: 'add',
		spanClassName: 'add-span',
	},
];
