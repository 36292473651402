export const SAVE_RESERVATIONS = 'SAVE_RESERVATIONS';
export const SET_RESERVATIONS_COUNT = 'SET_RESERVATIONS_COUNT';
export const SET_RESERVATION_STATUS = 'SET_RESERVATION_STATUS';
export const SET_PRESSED_HEADER_TAB = 'SET_PRESSED_HEADER_TAB';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_WS_MESSAGE_RECEIVED_STATUS = 'SET_WS_MESSAGE_RECEIVED_STATUS';
export const SET_PAGE_OFFSET = 'SET_PAGE_OFFSET';
export const SET_PROPERTY = 'SET_PROPERTY';
export const GET_CAROUSEL_METADATA = 'GET_CAROUSEL_METADATA';
export const CAROUSEL_DATA = 'CAROUSEL_DATA';
export const GET_TABLE_DATA = 'GET_TABLE_DATA';
export const GET_TABLE_DATA_ON_OPERATION = 'GET_TABLE_DATA_ON_OPERATION';
export const GET_TABLE_DATA_ON_TAB_CHANGE = 'GET_TABLE_DATA_ON_TAB_CHANGE';
export const GET_TABLE_DATA_ON_SCREEN_CHANGE = 'GET_TABLE_DATA_ON_SCREEN_CHANGE';
export const GET_SCREENS = 'GET_SCREENS';
export const GET_ALL_BUCKETS = 'GET_ALL_BUCKETS';
export const REPLACE_ALL_BUCKETS = 'REPLACE_ALL_BUCKETS';
export const ADD_VIDEO_TO_BUCKET = 'ADD_VIDEO_TO_BUCKET';
export const SET_SELECTED_BUCKET = 'SET_SELECTED_BUCKET';
export const SET_EXPANDED_SIDEBAR = 'SET_EXPANDED_SIDEBAR';
export const GET_SUBSECTION_DATA = 'GET_SUBSECTION_DATA';
export const RESET_SUBSECTION_DATA = 'RESET_SUBSECTION_DATA';
export const GET_CARD_SIZES = 'GET_CARD_SIZES';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_PUBLISHED_DATA = 'GET_PUBLISHED_DATA';
export const GET_PUBLISHED_DATA_ON_OPERATION = 'GET_PUBLISHED_DATA_ON_OPERATION';
export const GET_PUBLISHED_DATA_ON_SCREEN_CHANGE = 'GET_PUBLISHED_DATA_ON_SCREEN_CHANGE';
export const EDIT_DATA_IN_TABLE = 'EDIT_DATA_IN_TABLE';
export const EDIT_DATA_IN_PUBLISHED_TABLE = 'EDIT_DATA_IN_PUBLISHED_TABLE';
export const GET_FILTERS = 'GET_FILTERS';
export const DELETE_DATA_FROM_TABLE = 'DELETE_DATA_FROM_TABLE';
export const ON_PUBLISH_UNPUBLISH_DATA = 'ON_PUBLISH_UNPUBLISH_DATA';
export const GET_PUBLISHED_DATA_ON_ORDER_CHANGE = 'GET_PUBLISHED_DATA_ON_ORDER_CHANGE';
export const SET_CONTENT_MANAGEMENT_DATA = 'SET_CONTENT_MANAGEMENT_DATA';
export const GET_WAITLIST_USER_DATA = 'GET_WAITLIST_USER_DATA';
export const GET_WAITLIST_USER_DATA_INITIAL = 'GET_WAITLIST_USER_DATA_INITIAL';
export const EDIT_DATA_ON_WAITLIST_TABLE = 'EDIT_DATA_ON_WAITLIST_TABLE';
export const EDIT_DATA_ON_WAITLIST_TABLE_ON_SELECTION =
	'EDIT_DATA_ON_WAITLIST_TABLE_ON_SELECTION_SELECTION';
export const GET_BUCKET_DETAILS = 'GET_BUCKET_DETAILS';
export const SET_BUCKET_DETAILS = 'SET_BUCKET_DETAILS';
export const IS_CONTENT_PUBLISHED = 'IS_CONTENT_PUBLISHED';
export const SET_DIGEST_DATA = 'SET_DIGEST_DATA';
export const ADD_THUMBNAILS = 'ADD_THUMBNAILS';
export const GET_LIVE_CHANNEL_DATA = 'GET_LIVE_CHANNEL_DATA';
export const GET_LIVE_CHANNEL_DATA_INITIAL = 'GET_LIVE_CHANNEL_DATA_INITIAL';
export const GET_LIVE_STREAM_DATA = 'GET_LIVE_STREAM_DATA';
export const GET_LIVE_CATEGORY_INITIAL = 'GET_LIVE_CATEGORY_INITIAL';
export const GET_LIVE_CATEGORY_DATA = 'GET_LIVE_CATEGORY_DATA';
export const EDIT_DATA_ON_LIVE_CHANNEL_TABLE = 'EDIT_DATA_ON_LIVE_CHANNEL_TABLE';
export const EDIT_DATA_ON_LIVE_STREAM_TABLE = 'EDIT_DATA_ON_LIVE_STREAM_TABLE';
export const GET_LIVE_STREAM_DATA_INITIAL = 'GET_LIVE_STREAM_DATA_INITIAL';
export const EDIT_CATEGORY_DATA = 'EDIT_CATEGORY_DATA';
export const EDIT_BUCKET_TABLE_ON_PUBLISH_OPERTAION =
	'EDIT_BUCKET_TABLE_ON_PUBLISH_OPERTAION';
export const EDIT_BUCKET_TABLE_ON_UNPUBLISH_OPERTAION =
	'EDIT_BUCKET_TABLE_ON_UNPUBLISH_OPERTAION';
export const LIVE_STREAM_SCROLL = 'LIVE_STREAM_SCROLL';
export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';
export const REMOVE_BREADCRUMB = 'REMOVE_BREADCRUMB';
export const GET_USER_DATA_INITIAL = 'GET_USER_DATA_INITIAL';
export const GET_USER_DATA = 'GET_USER_DATA';
export const EDIT_USER_DATA_ON_DELETE = 'EDIT_USER_DATA_ON_DELETE';
export const GET_REPORTED_VIDEO_DATA_INITIAL = 'GET_REPORTED_VIDEO_DATA_INITIAL';
export const GET_REPORTED_VIDEO_DATA = 'GET_REPORTED_VIDEO_DATA';
export const EDIT_DATA_ON_REPORTED_TABLE = 'EDIT_DATA_ON_REPORTED_TABLE';
export const GET_CAST_DATA_INITIAL = 'GET_CAST_DATA_INITIAL';
export const GET_CAST_DATA = 'GET_CAST_DATA';
export const GET_TAGS_DATA_INITIAL = 'GET_TAGS_DATA_INITIAL';
export const GET_TAGS_DATA = 'GET_TAGS_DATA';
export const UPDATE_BUCKET_VIDEO_DATA = 'UPDATE_BUCKET_VIDEO_DATA';
export const GET_PARENT_BUCKET_DATA = 'GET_PARENT_BUCKET_DATA';
export const GET_LIST_FOR_YOU_INITIAL = 'GET_LIST_FOR_YOU_INITIAL';
export const GET_LIST_FOR_YOU = 'GET_LIST_FOR_YOU';
export const REMOVE_DATA_LIST_FOR_YOU = 'REMOVE_DATA_LIST_FOR_YOU';
export const GET_SUB_CATEGORY_DATA_INITIAL = 'GET_SUB_CATEGORY_DATA_INITIAL';
export const GET_SUB_CATEGORY_DATA = 'GET_SUB_CATEGORY_DATA';
