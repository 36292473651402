import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import * as CONSTANTS from './constants';
import DashboardSearchBox from 'src/app/components/atoms/DashboardSearchBox';
import { Button } from 'src/app/components/Button';
import CastTableContainer from './component/CastTableContainer';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';
import { Toast } from 'src/app/components/Toast';

export const CastCrew = () => {
	const searchRef = useRef('');
	const [totalCount, setTotalCount] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const debouncedValue = useDebounce(searchText, 500);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	const handleChange = (e) => {
		setOffset(0);
		setSearchText(e.target.value);
	};
	const emptySearchBox = () => {
		setLoading(true);
		setSearchText('');
		searchRef.current.value = '';
		setTimeout(() => {
			setOffset(0);
		}, 500);
	};

	return (
		<div className="cast-right-container">
			<div className="cast-header-parent-block">
				<div className="cast-header-block">
					<h1>{CONSTANTS.HEADING}</h1>
					{totalCount > 0 && <div className="total-count">{totalCount}</div>}
				</div>
			</div>

			<div className="cast-search-with-cancel">
				<DashboardSearchBox
					handleChange={handleChange}
					className="cast-search"
					ref={searchRef}
					placeholder={CONSTANTS.SEARCH_BOX_PLACEHOLDER}
				/>
				{searchText !== '' && (
					<Button
						label="Cancel"
						labelClassName="cancel-button-label"
						className="cancel-button"
						onClick={emptySearchBox}
					/>
				)}
			</div>

			<div className="content-block">
				<CastTableContainer
					offset={offset}
					setOffset={setOffset}
					loading={loading}
					setLoading={setLoading}
					setTotalCount={setTotalCount}
					setToastInfo={setToastInfo}
					debouncedValue={debouncedValue}
				/>
			</div>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

export default CastCrew;
