import { ADD_BREADCRUMB, REMOVE_BREADCRUMB } from 'src/app/pages/Dashboard/actionTypes';
import BREAD_CRUMB_INITIAL_STATTE from './initialState';

const breadCrumbReducer = (state = BREAD_CRUMB_INITIAL_STATTE, action) => {
	switch (action.type) {
		case ADD_BREADCRUMB:
			const routes = [...state.breadCrumbRoute];
			const findIndexOfRoute = routes.findIndex(
				(item) => item.breadcrumb === action?.payload?.value,
			);
			const { location, value } = action?.payload;
			if (findIndexOfRoute === -1) {
				const data = {
					path: location?.pathname,
					breadcrumb: value,
					name: location?.state?.name,
				};
				return {
					...state,
					breadCrumbRoute: [...state.breadCrumbRoute, data],
				};
			} else {
				return {
					...state,
					breadCrumbRoute: state.breadCrumbRoute.slice(0, findIndexOfRoute + 1),
				};
			}
		case REMOVE_BREADCRUMB:
			return {
				...state,
				breadCrumbRoute: [],
			};

		default:
			return state;
	}
};

export default breadCrumbReducer;
