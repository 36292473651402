export const TOAST_ERROR_MESSAGE = {
	SELECT_MOVIE_TO_DELETE: 'Please select video(s) to delete',
	SELECT_BUCKET_FIRST: 'Please select bucket first',
	SUCCESSFULLY_ADDED_TO_BUCKET: 'Successfully added to bucket',
	VIDEO_ALREADY_EXIST: 'Video already exist in bucket',
};

export const EMPTY_STATE_MESSAGE = {
	NO_FILTER_RESULT: 'No filter result found!',
	PLEASE_CHANGE_FILTER: 'Please change your filter',
	CRITERIA_TO_GET_RESULTS: 'criteria to get results',
};

export const VIEW_TYPE = {
	grid: 'grid',
	list: 'list',
};

export const BUCKET_DETAILS_TABLE_COLUMNS_CONFIG = [
	{
		key: 'title',
		title: 'Titles',
		className: 'name',
		spanClassName: 'title-span',
	},
	{
		key: 'year',
		title: 'Year',
		className: 'year',
		spanClassName: 'year-span',
	},
	{
		key: 'ratings',
		title: 'Rating',
		className: 'ratings',
		spanClassName: 'ratings-span',
	},
	{
		key: 'imdb',
		title: 'IMDB',
		className: 'imdb',
		spanClassName: 'imdb-span',
	},
	{
		key: 'add',
		title: '',
		className: 'add',
		spanClassName: 'add-span',
	},
];

export const SHUFFLE_CONST = {
	reorder_message: 'Want to re-order listing?',
	click_shuffle: 'Click to Shuffle',
	reorder_done: 'Done with reordering?',
	click_back: 'Back to Normal View',
};
