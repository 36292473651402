import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAllBucket,
	setSelectedBucket,
	replaceAllBucket,
} from '../../../pages/Dashboard/actionCreators';
import './styles.scss';
import CustomOptionsBox from '../CustomOptionsBox';
import DownArrow from '../../../assets/logos/DownArrow.svg';
import ClickAwayListener from 'react-click-away-listener';
import {
	PER_PAGE_ELEMENT,
	PAGE,
	PLACEHOLDER,
	SCREEN_OPTIONS,
} from '../../../common/constants/CarouselScreenConstant';
import * as types from 'src/app/pages/Dashboard/actionTypes';
import {
	BUCKET_URL,
	ROOT_URL,
	CONTENT_TYPE,
} from '../../../common/constants/AppConstants';
import { useDebounce } from 'src/app/common/utils/hooks/debounce';

const CustomSelectBox = ({
	fromScreen,
	updateFlag,
	idFieldName,
	enableDropdown,
	ytCategory = false,
	searchEnable = false,
}) => {
	const dispatch = useDispatch();
	const allBuckets = useSelector((state) => state.reservations?.allBuckets);
	const totalBucket = useSelector((state) => state.reservations?.totalBucket);
	const selectedBucket = useSelector((state) => state.reservations.selectedBucket);
	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState('');
	const debouncedValue = useDebounce(searchText, 500);
	const searchRef = useRef('');
	const [showOptionDropdown, setShowOptionDropdown] = useState(false);

	const handleChange = (e) => {
		setTimeout(() => {
			const element = document.querySelector('.infinite-scroll-option-box');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 20);
		setSearchText(e.target.value);
	};

	const handleRequestChange = () => {
		if (allBuckets.length < totalBucket) {
			setPage((value) => value + PAGE);
		}
	};
	const handleDropdownChange = (state) => {
		if (enableDropdown) {
			setShowOptionDropdown((prev) => {
				const dropDownArrow = document.getElementById('select-box-dashboard-down-arrow');
				if (prev) {
					dropDownArrow?.classList.remove('select-box-down-arrow-rotate');
					dropDownArrow?.classList.add('select-box-down-arrow-unrotate');
					return !prev;
				} else {
					if (state) {
						dropDownArrow?.classList.remove('select-box-down-arrow-unrotate');
						dropDownArrow?.classList.add('select-box-down-arrow-rotate');
						return !prev;
					} else return false;
				}
			});
		}
	};

	useEffect(() => {
		dispatch(setSelectedBucket(null));
		setPage(1);
		if (ytCategory) {
			dispatch(
				replaceAllBucket(`${ROOT_URL}${BUCKET_URL}`, {
					page: 1,
					perPage: PER_PAGE_ELEMENT,
					content_type: 'video',
					...(debouncedValue &&
						debouncedValue?.length > 0 && {
							search_term: debouncedValue,
						}),
				}),
			);
		} else {
			dispatch(
				replaceAllBucket(`${ROOT_URL}${BUCKET_URL}`, {
					page: 1,
					perPage: PER_PAGE_ELEMENT,
					content_type: 'movie_show_video, movie, show',
					...(debouncedValue &&
						debouncedValue?.length > 0 && {
							search_term: debouncedValue,
						}),
				}),
			);
		}
	}, [debouncedValue]);

	useEffect(() => {
		dispatch(setSelectedBucket(null));
		setPage(1);
		if (allBuckets.length > 0) {
			if (ytCategory) {
				dispatch(
					replaceAllBucket(`${ROOT_URL}${BUCKET_URL}`, {
						page: 1,
						perPage: PER_PAGE_ELEMENT,
						content_type: 'video',
					}),
				);
			} else {
				dispatch(
					replaceAllBucket(`${ROOT_URL}${BUCKET_URL}`, {
						page: 1,
						perPage: PER_PAGE_ELEMENT,
						content_type: `${CONTENT_TYPE?.MOVIE_SHOWS_VIDEOS},${CONTENT_TYPE?.MOVIE}, ${CONTENT_TYPE?.SHOW} `,
					}),
				);
			}
		}
	}, [ytCategory]);

	useEffect(() => {
		if (page > 1 || allBuckets.length === 0) {
			if (ytCategory) {
				dispatch(
					getAllBucket(`${ROOT_URL}${BUCKET_URL}`, {
						page: page > 1 ? page : 1,
						perPage: PER_PAGE_ELEMENT,
						content_type: 'video',
						...(debouncedValue &&
							debouncedValue?.length > 0 && {
								search_term: debouncedValue,
							}),
					}),
				);
			} else {
				dispatch(
					getAllBucket(`${ROOT_URL}${BUCKET_URL}`, {
						page: page > 1 ? page : 1,
						perPage: PER_PAGE_ELEMENT,
						content_type: 'movie_show_video, movie, show',
						...(debouncedValue &&
							debouncedValue?.length > 0 && {
								search_term: debouncedValue,
							}),
					}),
				);
			}
		}
	}, [page, updateFlag, dispatch]);

	const handleSelect = (selectedOption) => {
		dispatch(setSelectedBucket(selectedOption));
	};
	return (
		<>
			<ClickAwayListener onClickAway={() => handleDropdownChange(false)}>
				<div className="select-span-container">
					<span
						className={`select-span ${!enableDropdown ? 'disabled' : ''}`}
						onClick={handleDropdownChange}>
						<div>
							<p>{selectedBucket ? selectedBucket?.name : PLACEHOLDER}</p>
						</div>
						<div>
							<img
								src={DownArrow}
								id="select-box-dashboard-down-arrow"
								alt="Down Arrow"
							/>
						</div>
					</span>
					{showOptionDropdown && (
						<CustomOptionsBox
							fromScreen={fromScreen}
							options={fromScreen === SCREEN_OPTIONS.dashboard ? allBuckets : null}
							closeDropdown={(flag) => setShowOptionDropdown(flag)}
							handleSelect={handleSelect}
							selectType="single"
							idFieldName={
								fromScreen === SCREEN_OPTIONS.dashboard ? idFieldName : 'bucket_id'
							}
							handleRequestChange={handleRequestChange}
							selectedOption={selectedBucket}
							totalBucket={totalBucket}
							bucketScreen={true}
							searchEnable={searchEnable}
							searchText={searchText}
							searchRef={searchRef}
							handleChange={handleChange}
							debouncedValue={debouncedValue}
						/>
					)}
				</div>
			</ClickAwayListener>
		</>
	);
};

export default CustomSelectBox;
