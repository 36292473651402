export const REPORTED_VIDEOS_HEADING = 'Reported Videos';

export const REPORTED_TABLE_COLUMNS_CONFIG = [
	{
		key: 'title',
		title: 'Title & Video URL',
		className: 'name',
		spanClassName: 'title-span',
	},
	{
		key: 'reason',
		title: 'Last Reported Reason',
		className: 'reason',
		spanClassName: 'reason-span',
	},
	{
		key: 'last-reported',
		title: 'Last Reported Time',
		className: 'last-reported',
		spanClassName: 'last-reported-span',
	},
	{
		key: 'frequency',
		title: 'Frequency',
		className: 'frequency',
		spanClassName: 'frequency-span',
	},
	{
		key: 'reported-by',
		title: 'Reported By',
		className: 'reported-by',
		spanClassName: 'reported-by-span',
	},
	{
		key: 'action',
		title: 'Action',
		className: 'action',
		spanClassName: 'action-span',
	},
];

export const ROW_TOOLTIP = {
	delete: 'Delete Video',
};

export const TABS = [
	{ key: 'reported', label: 'Reported' },
	{ key: 'removed', label: 'Removed' },
];

export const DELETE_MESSAGE =
	'Are you sure you want to delete this video from the platform?';

export const PROFILE_ICON_COUNT = 3;
