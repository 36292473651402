import React, { useState, useEffect, createContext } from "react";

export const ActiveContext = createContext({
  activeRoute: "/dashboard",
  setActive: () => {},
});

const ActiveRouteProvider = ({ children }) => {
  const [activeRoute, setActiveRoute] = useState("/dashboard");
  const setActive = (route) => {
    setActiveRoute(route);
  };

  return (
    <ActiveContext.Provider value={{ activeRoute, setActive }}>
      {children}
    </ActiveContext.Provider>
  );
};

export default ActiveRouteProvider;
