import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as CONST from '../constants';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { RECORD_TO_FETCH } from 'src/app/common/constants/BucketListingConstant';
import { getCommonMethod, putCommonMethod } from 'src/app/pages/Dashboard/Service';
import { Loader } from 'src/app/components/Loader';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import InfiniteScroll from 'react-infinite-scroll-component';
import { infinteScrollEndMessage } from 'src/app/pages/ComponentConstant';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import Table from 'src/app/components/Table';
import TagRow from './TagRow';
import Popup from 'reactjs-popup';
import { castEditPopup, bucketDescTagsPopup } from 'src/app/common/constants/PopupStyles';
import EditCastProfile from 'src/app/components/EditCastProfile';
import {
	GET_TAGS_DATA,
	GET_TAGS_DATA_INITIAL,
} from 'src/app/pages/Dashboard/actionTypes';
import TagBucketDesc from 'src/app/components/TagsBucketDesc';

const TagContainer = ({
	setInitialState,
	setToastInfo,
	selectedCards,
	setUncheckedOnSelectOne,
	deleteAll,
	unCheckedOnSelectOne,
	onSelectingCards,
	selectToDeleteAll,
	tableDisplayData,
	setTableDisplayData,
	setSelectedCards,
	allUncheck,
	setAllUncheck,
	setDeleteAll,
	offset,
	setOffset,
	initialState,
	setTagsCountTotal,
	setnewArr,
}) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.settingReducer?.tags);
	const [hasMore, setHasMore] = useState(false);
	const [editPopup, setEditPopup] = useState(false);
	const [editPopupData, setEditPopupData] = useState([]);
	const [buttonLoader, setButtonLoader] = useState(false);
	const [bucketDescPopup, setBucketDescPopup] = useState(false);
	const [tagsCount, setTagsCount] = useState(0);
	const [bucketIds, setBucketIds] = useState([]);

	useEffect(() => {
		fetchTagCount();
	}, []);

	useEffect(() => {
		if (tableData) {
			setLoading(false);
			setTableDisplayData([...tableData]);
		}
	}, [tableData, setLoading]);

	useEffect(() => {
		fetchTagList();
	}, [offset]);

	const fetchTagCount = async () => {
		try {
			const res = await getCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.TAG_COUNT}`,
			);
			setTagsCountTotal(res?.data?.total);
		} catch (e) {}
	};

	const fetchTagList = async () => {
		try {
			if (offset > 0) {
				const res = await getCommonMethod(
					`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_TAGS}`,
					{
						offset: offset,
						limit: RECORD_TO_FETCH,
					},
				);
				const arr = [];
				res?.data.map((item) => {
					if (item?.is_curated && allUncheck) {
						arr.push({
							tag_id: item?.tag_id,
							is_curated: true,
						});
					}
				});
				setTagsCount((count) => count + res.data?.length);
				setnewArr([...arr]);
				dispatch({ type: GET_TAGS_DATA, payload: res.data });
				setInitialState([...initialState, ...arr]);
				handleHasMore(res);
			} else {
				setLoading(true);
				const res = await getCommonMethod(
					`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_TAGS}`,
					{
						offset: 0,
						limit: RECORD_TO_FETCH,
					},
				);
				const arr = [];
				res?.data.map((item) => {
					if (item?.is_curated) {
						arr.push({
							tag_id: item?.tag_id,
							is_curated: true,
						});
					}
				});
				setTagsCount(res.data?.length);
				setnewArr([...arr]);
				setInitialState([...arr]);
				dispatch({ type: GET_TAGS_DATA_INITIAL, payload: res.data });
				handleHasMore(res);
			}
		} catch (e) {}
	};

	const handleHasMore = (response) => {
		if (response?.data?.length > 0 && response?.data?.length === RECORD_TO_FETCH) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const fetchMoreData = () => {
		if (hasMore) {
			setOffset(tableDisplayData?.length);
		}
	};

	const openEditModal = (data) => {
		setEditPopup(true);
		setEditPopupData(data);
	};

	const closeEditModal = () => {
		setEditPopup(false);
	};

	const openBucketDescPopup = (data) => {
		setEditPopupData(data);
		setBucketIds(data?.bucket_ids);
		setBucketDescPopup(true);
	};

	const closeBucketDescPopup = () => {
		setBucketDescPopup(false);
	};

	const updatePopularity = async (id, thumbnail) => {
		setButtonLoader(true);
		try {
			const res = await putCommonMethod(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.TAG_THUMBNAIL}`,
				{
					tag_id: id,
					thumbnail: `${process.env.REACT_APP_TAGS_BUCKET}${thumbnail}`,
				},
			);
			if (res.status) {
				const index = tableDisplayData.findIndex((item) => item.tag_id === id);
				const arr = [...tableDisplayData];
				let item = {
					...arr[index],
					thumbnail: `${process.env.REACT_APP_TAGS_BUCKET}${thumbnail}`,
				};
				arr[index] = item;
				setTableDisplayData(arr);
			}
		} catch (e) {}
		setButtonLoader(false);
		closeEditModal();
	};

	const renderTable = () => {
		if (loading) {
			return <EmptyState columns={CONST.TAGS_TABLE_COLUMNS_CONFIG} renderColumns={2} />;
		} else {
			return tableDisplayData.map((item, index) => {
				return (
					<TagRow
						key={item && `${item.tag_id}`}
						data={{ ...item, index }}
						id={item.tag_id}
						toggleEditModal={openEditModal}
						toggleBucketDescPopup={openBucketDescPopup}
						deleteAll={deleteAll}
						setDeleteAll={setDeleteAll}
						setUncheckedOnSelectOne={setUncheckedOnSelectOne}
						selectedCards={selectedCards}
						length={tableDisplayData?.length}
						onSelecting={onSelectingCards}
						is_curated={item?.is_curated ? true : false}
					/>
				);
			});
		}
	};

	return (
		<div className="right-panel-container">
			<div className="right-panel-table-wrapper">
				<Table isRenderable={true}>
					<div id="scrollableDivTags">
						<InfiniteScroll
							dataLength={tableDisplayData.length}
							next={fetchMoreData}
							hasMore={hasMore}
							loader={<Loader loadMore={true} />}
							scrollableTarget="scrollableDivTags"
							endMessage={loading ? null : infinteScrollEndMessage()}>
							<table className="table-tags">
								<thead>
									<tr>
										{CONST.TAGS_TABLE_COLUMNS_CONFIG.map((column) => {
											return (
												<Column
													key={column.key}
													config={column}
													isWaitlist={true}
													tagsCount={tagsCount}
													fromBuck={true}
													selectToDeleteAll={selectToDeleteAll}
													deleteAll={deleteAll}
													unCheckedOnSelectOne={unCheckedOnSelectOne}
												/>
											);
										})}
									</tr>
								</thead>
								<tbody>{renderTable()}</tbody>
							</table>
						</InfiniteScroll>
					</div>
				</Table>
			</div>
			<Popup
				className="popup-content-container-digest"
				open={editPopup}
				closeOnDocumentClick
				onClose={closeEditModal}
				contentStyle={castEditPopup}>
				<EditCastProfile
					data={editPopupData}
					closePopup={closeEditModal}
					updatePopularity={updatePopularity}
					buttonLoader={buttonLoader}
					setButtonLoader={setButtonLoader}
					setToastInfo={setToastInfo}
					fromTags={true}
				/>
			</Popup>
			<Popup
				className="popup-content-container-digest"
				open={bucketDescPopup}
				closeOnDocumentClick
				onClose={closeBucketDescPopup}
				contentStyle={bucketDescTagsPopup}>
				<TagBucketDesc
					tagData={editPopupData}
					toggleBucketDescPopup={closeBucketDescPopup}
					bucketIds={bucketIds}
				/>
			</Popup>
		</div>
	);
};

export default TagContainer;
