import React, { useState, useEffect, useRef } from 'react';
import LoginScreenImage from 'src/app/assets/images/LoginRightImage.png';
import QewdLogoLogin from 'src/app/assets/images/QewdLogoLogin.svg';
import { LoginForm } from './LoginForm/index';
import { OTPScreen } from './OTPScreen/index';
import './styles.scss';
import { Redirect } from 'react-router-dom';
import { HOME_CONSTANTS, TOAST_MESSAGES, ADMIN_EMAIL } from './constants';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { Toast } from 'src/app/components/Toast';
import { ADD_THUMBNAILS, REMOVE_BREADCRUMB } from 'src/app/pages/Dashboard/actionTypes';
import { useDispatch } from 'react-redux';
import {
	ROOT_URL,
	GET_USER_BY_USERID,
	GET_USER_BY_PHONE,
	ERROR_MESSAGE,
} from 'src/app/common/constants/AppConstants';
import { getCommonMethod, getUserInfo } from '../Dashboard/Service';
import {
	setItemIntoLocalStoarge,
	removeItemFromLocalStorage,
} from 'src/app/common/utils/localStorageUtils';
import { removePlusFromMobile } from 'src/app/common/utils/methods';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';

export const HomeLogin = () => {
	const history = useHistory();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [value, setValue] = useState('');
	const [showError, setShowError] = useState(false);
	const [isButtonActive, setButtonActive] = useState(false);
	const [verifyOtp, setVerify] = useState(false);
	const [seconds, setSeconds] = useState(HOME_CONSTANTS.remainingTimeSeconds);
	const [minute, setMinute] = useState(HOME_CONSTANTS.remainingTimeMinutes);
	const [disableInput, setDisableInput] = useState(false);
	const [disableButton, setDisableButton] = useState(true);
	const [otp, setOtp] = useState(null);
	const [session, setSession] = useState(null);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		isError: false,
	});
	const [isOtpExpire, setOtpExpire] = useState(true);
	const [disableResend, setDisableResend] = useState(true);
	const [isButtonLoader, setButtonLoader] = useState(false);
	const [verifyButtonLoader, setVerifyButtonLoader] = useState(false);
	const userRef = useRef(false);
	const dispatch = useDispatch();
	const notSocialLogin = useRef(false);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setShowToast(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	useEffect(() => {
		let cancel = false;
		if (cancel) return;
		dispatch({ type: ADD_THUMBNAILS, payload: [] });
		dispatch({ type: REMOVE_BREADCRUMB, payload: {} });

		Hub.listen('auth', listner);
		return () => {
			cancel = true;
		};
	}, []);
	const listner = async (response) => {
		const {
			payload: { event, data },
		} = response;

		try {
			switch (event) {
				case 'signIn':
					checkForAdmin(data);
					break;
				case 'signOut':
					removeItemFromLocalStorage('admin_phone');
					removeItemFromLocalStorage('avatar');
					break;
				case 'signIn_failure':
					break;
				case 'signUp_failure':
					break;
				case 'codeFlow':
					break;
				case 'customState_failure':
					break;
				default:
					break;
			}
		} catch (error) {
			setShowToast({
				message: ERROR_MESSAGE,
				isError: true,
				show: true,
			});
		}
	};

	const checkForAdmin = async (data) => {
		try {
			if (notSocialLogin.current) {
				return;
			}
			const res = await getUserInfo(`${ROOT_URL}${GET_USER_BY_USERID}`, {
				user_id: data?.username,
			});
			if (res?.data?.user_type === 'admin') {
				setItemIntoLocalStoarge('avatar', res?.data?.avatar);
				let path = `/dashboard`;
				Hub.remove('auth', listner);
				history.push(path);
			} else {
				setShowToast({
					message: TOAST_MESSAGES.ADMIN_DENY,
					isError: true,
					show: true,
				});
			}
		} catch (e) {
			if (!notSocialLogin.current) {
				setShowToast({
					message: TOAST_MESSAGES.USER_NOT_EXIST,
					isError: true,
					show: true,
				});
				await Auth.signOut();
			}
		}
	};

	const handleLoginClick = async (event) => {
		event.preventDefault(true);
		notSocialLogin.current = true;
		setButtonLoader(true);
		try {
			const res = await getUserInfo(`${ROOT_URL}${GET_USER_BY_PHONE}`, {
				phone_number: removePlusFromMobile(value),
			});
			if (res?.data?.user_type === 'admin') {
				setItemIntoLocalStoarge('admin_phone', removePlusFromMobile(value));
				setItemIntoLocalStoarge('avatar', res?.data?.avatar);
				if (isOtpExpire) {
					await signInUser(value);
				}
				if (!userRef.current) {
					setDisableInput(false);
					setIsLoggedIn(true);
				}
			} else {
				setButtonLoader(false);
				setShowToast({
					message: TOAST_MESSAGES.ADMIN_DENY,
					isError: true,
					show: true,
				});
			}
		} catch (e) {
			setButtonLoader(false);
			setShowToast({
				message: TOAST_MESSAGES.USER_NOT_EXIST,
				isError: true,
				show: true,
			});
		}
	};

	const onVerifyClick = async () => {
		await verifyOtpOnClick();
	};

	const verifyOtpOnClick = () => {
		Auth.sendCustomChallengeAnswer(session, otp)
			.then((user) => {
				if (user && user.signInUserSession) {
					setVerify(true);
					setSession(null);
					setVerifyButtonLoader(false);
				} else {
					setShowToast({
						message: TOAST_MESSAGES.WRONG_OTP,
						isError: true,
						show: true,
					});
					setOtp(null);
					setVerifyButtonLoader(false);
					setDisableButton(true);
				}
			})
			.catch((err) => {
				setShowToast({
					message: TOAST_MESSAGES.ERROR_OTP,
					isError: true,
					show: true,
				});
				setIsLoggedIn(false);
				setOtpExpire(true);
				setSeconds(HOME_CONSTANTS.remainingTimeSeconds);
				setMinute(HOME_CONSTANTS.remainingTimeMinutes);
				setDisableInput(true);
				setDisableButton(true);
				setOtp(null);
				setVerifyButtonLoader(false);
			});
	};

	const signInUser = async (username) => {
		try {
			const result = await Auth.signIn({
				username: username,
			});
			if (result) {
				setSession(result);
				setOtpExpire(false);
				userRef.current = false;
			}
		} catch (error) {
			if (error.code === 'UserNotFoundException') {
				userRef.current = true;
				setShowToast({
					message: TOAST_MESSAGES.USER_NOT_EXIST,
					isError: true,
					show: true,
				});
			} else {
				userRef.current = true;
				setShowToast({
					message: TOAST_MESSAGES.ERROR_OTP,
					isError: true,
					show: true,
				});
			}
		} finally {
			setButtonLoader(false);
		}
	};

	const handleEditClick = (e) => {
		e.preventDefault();
		setDisableInput(true);
		setDisableButton(true);
		setOtp(null);
		setIsLoggedIn(false);
		if (isOtpExpire === true) {
			setSeconds(HOME_CONSTANTS.remainingTimeSeconds);
			setMinute(HOME_CONSTANTS.remainingTimeMinutes);
		}
	};

	const handleToastDismiss = () => {
		setShowToast({ show: false, message: '' });
	};

	const handleResend = async (e) => {
		e.preventDefault();
		await signInUser(value);
		setSeconds(HOME_CONSTANTS.remainingTimeSeconds);
		setMinute(HOME_CONSTANTS.remainingTimeMinutes);
		setDisableResend(true);
		setDisableInput(false);
		setOtp(null);
	};

	const socialSignin = (provider) => {
		Auth.federatedSignIn({ provider: provider });
	};

	return (
		<>
			<div className="loginScreenWrapper">
				<div className="leftLoginBlock">
					<div className="logoHeader">
						<img src={QewdLogoLogin} />
					</div>
					{verifyOtp ? (
						<Redirect to="/dashboard" />
					) : (
						<div className="parentContainer">
							<LoginForm
								value={value}
								setValue={setValue}
								showError={showError}
								setShowError={setShowError}
								isButtonActive={isButtonActive}
								setButtonActive={setButtonActive}
								onLoginClick={(event) => handleLoginClick(event)}
								isLoggedIn={isLoggedIn}
								isButtonLoader={isButtonLoader}
								socialSignin={socialSignin}
							/>
							<OTPScreen
								value={value}
								setVerify={setVerify}
								isLoggedIn={isLoggedIn}
								handleEditClick={(event) => handleEditClick(event)}
								seconds={seconds}
								setSeconds={setSeconds}
								minute={minute}
								setMinute={setMinute}
								disableInput={disableInput}
								setDisableInput={setDisableInput}
								disableButton={disableButton}
								setDisableButton={setDisableButton}
								otp={otp}
								setOtp={setOtp}
								onVerifyClick={onVerifyClick}
								setOtpExpire={setOtpExpire}
								disableResend={disableResend}
								setDisableResend={setDisableResend}
								setShowToast={setShowToast}
								handleResend={(event) => handleResend(event)}
								isButtonLoader={isButtonLoader}
								verifyButtonLoader={verifyButtonLoader}
								setVerifyButtonLoader={setVerifyButtonLoader}
							/>
						</div>
					)}
				</div>
				<div className="rightImageBlock">
					<img src={LoginScreenImage} className="login-screen-image"></img>
				</div>
			</div>
			{showToast.show ? (
				<Toast
					message={showToast.message}
					onDismiss={handleToastDismiss}
					isError={showToast.isError}
				/>
			) : null}
		</>
	);
};
