import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import Popup from 'reactjs-popup';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	youtubeVideoDetailsPopupStyle,
	tagsPopupStyle,
	popupContentStyleBucketDetails,
} from 'src/app/common/constants/PopupStyles';
import IconTextButton from 'src/app/components/atoms/IconTextButton';
import CustomSelectBox from 'src/app/components/atoms/CustomSelectBox';
import EditButton from 'src/app/pages/Dashboard/Components/BucketDetails/Components/EditButton';
import { Button } from 'src/app/components/Button';
import { Toast } from 'src/app/components/Toast/index';
import { Loader } from 'src/app/components/Loader';
import {
	overlayStyle,
	ROOT_URL,
	DELETE_VIDEOS_URL,
	ADD_SHORTFORM,
	ERROR_MESSAGE,
} from 'src/app/common/constants/AppConstants';
import {
	addVideoToBucket,
	deleteVideoFromBucket,
} from 'src/app/pages/Dashboard/actionCreators';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import CloseIcon from 'src/app/assets/images/TransparentClose.svg';
import ListActive from 'src/app/assets/logos/ListActive.svg';
import ListInactive from 'src/app/assets/logos/ListInactive.svg';
import GridActive from 'src/app/assets/logos/GridActive.svg';
import GridInactive from 'src/app/assets/logos/GridInactive.svg';
import HorizontalTile from 'src/app/components/atoms/HorizontalTile';
import ShortformVideoPopup from 'src/app/components/atoms/ShortformVideoPopup';
import { EditBucketModal } from 'src/app/components/Modals/EditBucketModal';
import TagsPopup from 'src/app/components/atoms/TagsPopup';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import Card from 'src/app/pages/Dashboard/Components/BucketDetails/Components/Card';
import EmptyImage from 'src/app/assets/images/empty-state.png';
import { putCommonMethod } from 'src/app/pages/Dashboard/Service';
import TableContainer from './Table';
import * as CONST from '../constant';
import './styles.scss';

const VideoDetails = ({
	videoDetail,
	title,
	bucketTags,
	fromBucket,
	loading,
	setLoading,
	fetchMoreData,
	hasMore,
	setHasMore,
	bucketId,
	updateResults,
	metaData,
	setVideoDetail,
	setOffset,
	offset,
}) => {
	const selectedBucket = useSelector((state) => state.reservations?.selectedBucket);
	const [viewDetail, setViewDetail] = useState(false);
	const [videoDetailId, setVideoDetailId] = useState(null);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [enableSelection, setEnableSelection] = useState(false);
	const [bucketListUpdateFlag, setBucketListUpdateFlag] = useState(false);
	const [viewType, setViewType] = useState(CONST.VIEW_TYPE.grid);
	const [deleteAll, setDeleteAll] = useState(false);
	const [unCheckedOnSelectOne, setUncheckedOnSelectOne] = useState(deleteAll);
	const [selectedCards, setSelectedCards] = useState([]);
	const [shuffle, setShuffle] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [editBucket, setEditBucket] = useState(false);
	const [viewTagsPopup, setViewTagsPopup] = useState(false);
	const [isOpenDeleteComfirmation, setIsOpenDeleteComfirmation] = useState(false);
	const [onDeleteLoading, setOnDeleteLoading] = useState(false);
	const [videoId, setVideoId] = useState(null);

	useEffect(() => {
		if (selectedCards.length > 0) {
			setEnableSelection(true);
		} else {
			setEnableSelection(false);
		}

		if (selectedCards.length === videoDetail.length && selectedCards.length) {
			setDeleteAll(true);
			setUncheckedOnSelectOne(true);
		}
	}, [selectedCards]);

	useEffect(() => {
		fetchAllDeleteData(videoDetail, false);
	}, [deleteAll]);

	useEffect(() => {
		fetchAllDeleteData(videoDetail, true);
	}, [videoDetail]);

	useEffect(() => {
		if (videoId) {
			addVideoToBucket();
		}
	}, [videoId]);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setShowToast(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const addVideoToBucket = async () => {
		if (selectedBucket?.bucket_id) {
			try {
				const res = await putCommonMethod(`${ROOT_URL}${ADD_SHORTFORM}`, {
					video_urls: [`https://youtu.be/${videoId}`],
					bucket_id: selectedBucket?.bucket_id,
				});
				if (res) {
					handleToast(true, CONST.SUCCESS_ADD, false);
				}
			} catch (error) {
				handleToast(true, error?.response?.data?.message || ERROR_MESSAGE, true);
			} finally {
				setVideoId(null);
			}
		} else {
			handleToast(true, 'Please select bucket to add video(s)', true);
		}
	};

	const handleToast = (showToast, toastMssg, toastError) => {
		setShowToast(showToast);
		setShowToastMessage(toastMssg);
		setToastError(toastError);
	};

	const changeViewType = (viewType) => {
		setViewType(viewType);
	};

	const selectToDelete = () => {
		if (!enableSelection) {
			setEnableSelection(true);
		} else if (selectedCards.length === 0) {
			setShowToastMessage(CONST.TOAST_ERROR_MESSAGE.SELECT_MOVIE_TO_DELETE);
			setToastError(true);
			setShowToast(true);
		} else if (selectedCards.length > 0) {
			setIsOpenDeleteComfirmation(true);
		}
	};

	const selectToDeleteAll = (e) => {
		if (e.target.checked) {
			setDeleteAll(true);
			setUncheckedOnSelectOne(true);
		} else {
			setDeleteAll(false);
			setUncheckedOnSelectOne(false);
			setSelectedCards([]);
		}
	};

	const handleShuffle = () => {
		setShuffle(!shuffle);
	};

	const toggleModal = () => {
		setIsOpen(!isOpen);
		setEditBucket(false);
	};

	const handleEdit = () => {
		setEditBucket(true);
		setIsOpen(true);
	};

	const handleAddButton = (value) => {
		if (!selectedBucket) {
			setToastError(true);
			setShowToastMessage(CONST.TOAST_ERROR_MESSAGE.SELECT_BUCKET_FIRST);
			setShowToast(true);
			return;
		}
		setVideoId((prev) => {
			if (prev === value) {
				setToastError(true);
				setShowToastMessage(CONST.TOAST_ERROR_MESSAGE.VIDEO_ALREADY_EXIST);
				setShowToast(true);
			}
			return value;
		});
	};

	const handleClick = (url) => {
		if (url) {
			const id = url?.replace('https://www.youtube.com/watch?v=', '');
			setViewDetail(true);
			setVideoDetailId(id);
		}
	};

	const onDeleteVideo = () => {
		setOnDeleteLoading(true);
		const videoIds = selectedCards.map((card) => card.id);
		deleteVideoFromBucket(
			`${ROOT_URL}${DELETE_VIDEOS_URL}`,
			{
				bucket_id: bucketId,
				video_ids: videoIds,
			},
			(response) => {
				if (response.status && response.message) {
					closeDeleteConfirmationModal();
					setOnDeleteLoading(false);
					if (viewType === CONST.VIEW_TYPE.grid) {
						videoIds.forEach((video) => {
							const cardElement = document.getElementById(video);
							const element = document.getElementById(`card-wrapper-${video}`);
							element.style.visibility = 'hidden';
							element.style.transform = 'translateY(-50px)';
							element.style.opacity = 0;
							element.style.border = 'none';
							element.style.transition =
								'visibility 0.5s linear, opacity 0.5s linear, transform 0.5s linear, border 0.5s linear';
							if (cardElement) {
								cardElement.checked = false;
							}
						});
					}
					setSelectedCards([]);
					setDeleteAll(false);
					setUncheckedOnSelectOne(false);
					offset > 0 ? setOffset(0) : setOffset((val) => val - 1);

					setTimeout(() => {
						setToastError(false);
						setShowToastMessage(response.message);
						setShowToast(true);
					}, 500);
				}
			},
		);
	};

	const unSelectAllSelectedItems = () => {
		if (selectedCards.length > 0) {
			selectedCards.forEach((card) => {
				const cardElement = document.getElementById(card.id);
				const cardContainer = document.getElementById(`card-container-${card.id}`);
				if (cardElement) {
					cardElement.checked = false;
				}
				if (cardContainer) {
					cardContainer.style.border = 'none';
				}
			});
		}
		setSelectedCards([]);
		setEnableSelection(false);
	};

	const newBucketCreated = (message, isError, show) => {
		setBucketListUpdateFlag(!bucketListUpdateFlag);
		setToastError(isError);
		setShowToastMessage(message);
		setShowToast(show);
	};

	const openTagViewPopup = () => {
		setViewTagsPopup(!viewTagsPopup);
	};

	const closeTagViewPopup = () => {
		setViewTagsPopup(false);
	};

	const handleAddMoreTags = () => {
		setViewTagsPopup(false);
		handleEdit();
	};

	const onSelectingCards = (card) => {
		const copyOfSelectedCards = [...selectedCards];
		if (card) {
			const filteredCards = copyOfSelectedCards.filter((cardItem) => {
				return cardItem.id === card.id;
			});
			if (filteredCards.length === 0) {
				setSelectedCards([...copyOfSelectedCards, card]);
			} else {
				const index = copyOfSelectedCards.indexOf(filteredCards[0]);
				if (index >= 0) {
					copyOfSelectedCards.splice(index, 1);
					setSelectedCards(copyOfSelectedCards);
				}
			}
		}
	};

	const fetchAllDeleteData = (data, fetchingMoreVideo) => {
		if (deleteAll) {
			let val = [];
			if (fetchingMoreVideo) {
				val = [...selectedCards];
			}
			data.map((movieItem) => {
				val.push({
					id: movieItem.video_id?.toString() || movieItem.videoId?.toString(),
					value: enableSelection,
				});
			});
			setSelectedCards([...val]);
		}
	};

	const closeDeleteConfirmationModal = () => {
		setIsOpenDeleteComfirmation(false);
	};

	const openDetailPopup = (id) => {
		setViewDetail(true);
		setVideoDetailId(id);
	};

	const closeDetailPopup = () => {
		setViewDetail(false);
		setVideoDetailId(null);
	};

	const openListDetail = (id, url) => {
		if (fromBucket) {
			handleClick(url);
		} else {
			openDetailPopup(id);
		}
	};

	const renderEmptyState = () => {
		return (
			<div>
				{loading && (
					<div className="card-grid">
						{[...Array(20)].map((i, index) => {
							return <Card key={index} loader={true} />;
						})}
					</div>
				)}
				{!loading && (
					<div className="empty-state-container empty-image">
						<img src={EmptyImage} alt="no results found" />
						<h3 className="no-filter-found">
							{CONST.EMPTY_STATE_MESSAGE.NO_FILTER_RESULT}
						</h3>
						<h5 className="please-change-filter">
							{CONST.EMPTY_STATE_MESSAGE.PLEASE_CHANGE_FILTER}
						</h5>
						<h5 className="please-change-filter">
							{CONST.EMPTY_STATE_MESSAGE.CRITERIA_TO_GET_RESULTS}
						</h5>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="video-detail-screen-container">
			<div className="header-parent-block">
				<div className="header-block">
					<div>
						<h1 className="heading">{title}</h1>
						{bucketTags && bucketTags?.length > 0 && (
							<p className="view-tags">
								<span onClick={openTagViewPopup}>View Tags</span>
							</p>
						)}
					</div>
					<div className="dashboard-button-block">
						<IconTextButton
							buttonText="+ Create Bucket"
							handleClick={() => toggleModal()}
							disabled={enableSelection}
						/>
					</div>
				</div>
				<div className="search-box-block">
					<div />
					<div className="aside-buttons">
						<CustomSelectBox
							fromScreen="dashboard"
							handleToastDismiss={() => handleToast(false, '', false)}
							updateFlag={bucketListUpdateFlag}
							idFieldName="bucket_id"
							enableDropdown={!enableSelection}
							ytCategory={true}
							searchEnable={true}
						/>
						{fromBucket && (
							<Tooltip
								overlay="Edit Bucket"
								placement="bottom"
								overlayInnerStyle={overlayStyle}
								align={{
									offset: [0, -2],
								}}>
								<div>
									<EditButton disabled={enableSelection} handleClick={handleEdit} />
								</div>
							</Tooltip>
						)}
						{fromBucket && (
							<Button
								onClick={selectToDelete}
								imageInfo={{
									src: DeleteBucket,
									label: 'select to edit',
								}}
								label={enableSelection ? 'Delete Videos' : 'Select To Delete'}
								className={`${enableSelection ? 'button-pink' : 'button-black'}`}
								labelClassName={`${enableSelection ? 'label-white' : 'label-grey'}`}
								disabled={false}
							/>
						)}
						{enableSelection && fromBucket && (
							<img
								src={CloseIcon}
								alt="close"
								onClick={unSelectAllSelectedItems}
								className="unselect-all-cards-icon"
							/>
						)}
						<Tooltip
							overlay="List View"
							placement="bottom"
							overlayInnerStyle={overlayStyle}
							align={{
								offset: [0, -2],
							}}>
							<img
								src={viewType === CONST.VIEW_TYPE.list ? ListActive : ListInactive}
								alt="List View"
								className="change-layout-icon"
								onClick={() => changeViewType(CONST.VIEW_TYPE.list)}
							/>
						</Tooltip>
						<Tooltip
							overlay="Grid View"
							placement="bottom"
							overlayInnerStyle={overlayStyle}
							align={{
								offset: [0, -2],
							}}>
							<img
								src={viewType === CONST.VIEW_TYPE.grid ? GridActive : GridInactive}
								alt="Grid View"
								className="change-layout-icon no-margin"
								onClick={() => changeViewType(CONST.VIEW_TYPE.grid)}
							/>
						</Tooltip>
					</div>
				</div>
			</div>
			{viewType === CONST.VIEW_TYPE.grid && !fromBucket && (
				<div className="yt-video-container">
					{videoDetail && videoDetail?.length > 0 && (
						<div className="gridContainer">
							{videoDetail?.map((item, index) => {
								return (
									<HorizontalTile
										key={index}
										handleVideo={handleAddButton}
										handleClick={() => openDetailPopup(item?.videoId || item?.video_id)}
										fromYt={true}
										tileDetails={{
											video_id: item?.videoId || item?.video_id,
											horizontal_thumbnail: item?.thumbnail,
											title: item?.title,
											description_alias: '',
										}}
										fromSearch={true}
									/>
								);
							})}
						</div>
					)}
				</div>
			)}
			{viewType === CONST.VIEW_TYPE.list && (
				<>
					{fromBucket && (
						<div
							className={`${
								videoDetail?.length ? 'shuffle-mode' : 'shuffle-mode-none'
							} `}>
							<p className="ordinary">
								{shuffle
									? CONST.SHUFFLE_CONST.reorder_done
									: CONST.SHUFFLE_CONST.reorder_message}
							</p>
							<p className="highlighted" onClick={handleShuffle}>
								&nbsp;
								{shuffle
									? CONST.SHUFFLE_CONST.click_back
									: CONST.SHUFFLE_CONST.click_shuffle}
							</p>
						</div>
					)}
					<div className="yt-video-container">
						{videoDetail && videoDetail?.length > 0 && (
							<TableContainer
								shuffle={shuffle}
								tableData={videoDetail}
								fetchMoreData={fetchMoreData}
								hasMore={hasMore}
								setUncheckedOnSelectOne={setUncheckedOnSelectOne}
								unCheckedOnSelectOne={unCheckedOnSelectOne}
								selectedCards={selectedCards}
								setDeleteAll={setDeleteAll}
								onSelecting={onSelectingCards}
								selectToDeleteAll={selectToDeleteAll}
								fromBucket={fromBucket}
								deleteAll={deleteAll}
								bucketId={bucketId}
								handleToast={handleToast}
								setVideoDetail={setVideoDetail}
								openDetail={openListDetail}
								handleAddButton={handleAddButton}
							/>
						)}
					</div>
				</>
			)}
			{viewType === CONST.VIEW_TYPE.grid && fromBucket && (
				<div className="yt-video-container" id={'ytVideoContainer'}>
					{enableSelection && videoDetail?.length && (
						<div className="selection-button extra-margin">
							<input
								id={'check'}
								type="checkbox"
								onChange={selectToDeleteAll}
								alt="selection"
								checked={deleteAll && unCheckedOnSelectOne}
							/>
							<span
								className={`${
									deleteAll && unCheckedOnSelectOne
										? 'span-delete-selected'
										: 'span-delete-unselected'
								}`}>
								Select All
							</span>
						</div>
					)}
					<InfiniteScroll
						dataLength={videoDetail?.length}
						next={fetchMoreData}
						hasMore={hasMore}
						loader={<Loader loadMore={true} />}
						scrollThreshold={0.4}
						scrollableTarget="ytVideoContainer">
						<div className="gridContainer">
							{videoDetail?.map((item, index) => {
								return (
									<Card
										key={item.video_id || item?.videoId}
										id={item.video_id || item?.videoId}
										tileDetails={item}
										handleAddButton={handleAddButton}
										enableSelection={enableSelection}
										onSelecting={onSelectingCards}
										fromRedirectionScreen={false}
										deleteAll={deleteAll}
										setUncheckedOnSelectOne={setUncheckedOnSelectOne}
										selectedCards={selectedCards}
										carouselData={videoDetail}
										handleClick={() => handleClick(item?.url)}
										setDeleteAll={setDeleteAll}
										fromVideoDetail={true}
										fromBucket={fromBucket}
									/>
								);
							})}
						</div>
					</InfiniteScroll>
				</div>
			)}
			{videoDetail?.length === 0 && !loading && renderEmptyState()}

			<Popup
				className="yt-video-popup"
				open={viewDetail}
				closeOnDocumentClick
				onClose={closeDetailPopup}
				contentStyle={youtubeVideoDetailsPopupStyle}>
				<ShortformVideoPopup id={videoDetailId} onClose={closeDetailPopup} />
			</Popup>
			{loading && (
				<div className="loadWrap">
					<Loader />
				</div>
			)}
			<EditBucketModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				showToast={newBucketCreated}
				isEdit={editBucket}
				bucketId={bucketId}
				callback={updateResults}
				fromYt={true}
			/>
			<Popup
				className="popup-content-container"
				open={viewTagsPopup}
				closeOnDocumentClick
				onClose={closeTagViewPopup}
				contentStyle={tagsPopupStyle}>
				<TagsPopup
					data={bucketTags}
					closePopup={closeTagViewPopup}
					bucketName={metaData?.name}
					handleAddMoreTags={handleAddMoreTags}
				/>
			</Popup>
			<Popup
				className="popup-content-container"
				open={isOpenDeleteComfirmation}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={popupContentStyleBucketDetails}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmationModal}
					onDelete={onDeleteVideo}
					loading={onDeleteLoading}
					fromBucketListing={true}
					message={`Are you sure you want to delete ${
						selectedCards.length > 1 ? 'all' : ''
					} selected  ${selectedCards.length > 1 ? 'videos' : 'video'}? `}
				/>
			</Popup>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={() => handleToast(false, '', false)}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

export default VideoDetails;
