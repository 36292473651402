import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RECORD_TO_FETCH } from 'src/app/common/constants/BucketListingConstant';
import * as CONSTANT from '../constants';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import { putCommonMethod } from 'src/app/pages/Dashboard/Service';
import {
	GET_LIVE_STREAM_DATA,
	GET_LIVE_STREAM_DATA_INITIAL,
} from 'src/app/pages/Dashboard/actionTypes';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import InfiniteScroll from 'react-infinite-scroll-component';
import { infinteScrollEndMessage } from 'src/app/pages/ComponentConstant';
import { Loader } from 'src/app/components/Loader';
import { Row } from './Row';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import Table from 'src/app/components/Table';
import Popup from 'reactjs-popup';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import { deletePopupStyle, videoPopupStyle } from 'src/app/common/constants/PopupStyles';
import './styles.scss';
import { ReactSortable } from 'react-sortablejs';
import VideoPlayer from 'src/app/components/VideoPlayer/VideoPlayerIndex';

const CustomComponent = forwardRef((props, ref) => {
	return <tbody ref={ref}>{props.children}</tbody>;
});

const TableContainer = ({
	offset,
	setOffset,
	loading,
	setLoading,
	setChecked,
	handleLiveChannelId,
	liveChannelId,
	apiBody,
	setApiBody,
	isAllChecked,
	setIsAllChecked,
	countMultipleCheck,
	setCountMultipleCheck,
	countOnAllUnselected,
	setCountOnAllUnselected,
	shuffleMode = false,
	openTooltip,
	setCounterToShowDelete,
	defaultDeleteMessage,
	counterToShowDelete,
	showDeletePopup,
	setShowDeletePopup,
	channelName,
	setChannelName,
	setDeleteChannelId,
	closeDeleteConfirmationModal,
	onDeleteChannel,
	addButtonLoader,
	handleChangeOnEdit,
	channel,
	tableData,
	hasMoreStreams,
	setAllLiveChannels,
	setToastInfo,
}) => {
	const mount = useRef();
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [count, setCount] = useState(0);
	const [val, setVal] = useState(false);
	const [id, setId] = useState([]);
	const [delVal, setDelVal] = useState(0);
	const [videoPopup, setVideoPopup] = useState(false);
	const [videoUrl, setVideoUrl] = useState(null);

	useEffect(() => {
		if (countMultipleCheck === 0) {
			setId([]);
		}
		if (isAllChecked) {
			let allCheckedValues = [];
			setCountMultipleCheck(tableData.length - delVal);
			setCounterToShowDelete(tableData.length - delVal);
			tableData.map((item) => {
				if (!id.includes(item.live_stream_id)) {
					let bodyObj = {
						live_stream_id: item.live_stream_id,
						live_category_id: null,
					};
					allCheckedValues.push(bodyObj);
				}
			});
			setApiBody([...allCheckedValues]);
		}
	}, [tableData]);

	const handleChangeOnDelete = (channelId, channelName) => {
		setShowDeletePopup(true);
		setChannelName(channelName);
		setDeleteChannelId(channelId);
		handleLiveChannelId(null);
	};

	const handleClick = (e, channel_id) => {
		if (e.target.checked === false) {
			const index = apiBody.findIndex((item) => item.live_stream_id === channel_id);
			apiBody.splice(index, 1);
			setApiBody([...apiBody]);
			setId([...id, channel_id]);
			setVal(false);
			setCountMultipleCheck((value) => value - 1);
			setCounterToShowDelete((value) => value - 1);
			setDelVal((v) => v + 1);
		}
		if (e.target.checked === true) {
			const index = id.findIndex((item) => item === channel_id);
			id.splice(index, 1);
			let values = {
				live_stream_id: channel_id,
				live_channel_id: channel,
			};
			setVal(false);
			setApiBody([...apiBody, values]);
			setCountMultipleCheck((value) => value + 1);
			setCounterToShowDelete((value) => value + 1);
			setDelVal((v) => v - 1);
		}
	};

	const handleVideoPopup = (url) => {
		setVideoPopup(true);
		setVideoUrl(url);
	};

	const closeVideoPopup = () => {
		setVideoPopup(false);
		setVideoUrl(null);
	};

	const handleAllCheck = (e) => {
		setId([]);
		setDelVal(0);
		if (e.target.checked === true) {
			setChecked(true);
			setIsAllChecked(true);
			let allCheckedValues = [];
			setCountMultipleCheck(tableData.length);
			setCounterToShowDelete(tableData.length);
			tableData.map((item) => {
				let bodyObj = {
					live_stream_id: item.live_stream_id,
				};
				allCheckedValues.push(bodyObj);
			});
			setApiBody([...allCheckedValues]);
		} else {
			setChecked(false);
			setCountMultipleCheck(0);
			setCounterToShowDelete(0);
			setIsAllChecked(false);
			setCount(0);
			setApiBody([]);
		}
		setCountOnAllUnselected([]);
	};

	const handleCheck = (e, id) => {
		if (e.target.checked === true) {
			countOnAllUnselected.push(id);
			setCountOnAllUnselected(countOnAllUnselected);
			setCounterToShowDelete((val) => val + 1);
			setCount((prev) => prev + 1);
			let val = {
				live_stream_id: id,
			};
			setApiBody([...apiBody, val]);
		} else {
			let indexOnAllUnselected = countOnAllUnselected.findIndex((item) => item === id);
			countOnAllUnselected.splice(indexOnAllUnselected, 1);
			setCountOnAllUnselected(countOnAllUnselected);
			setCounterToShowDelete((val) => val - 1);
			setCount((prev) => prev - 1);
			const index = apiBody.findIndex((item) => item.live_stream_id === id);
			apiBody.splice(index, 1);
			setApiBody([...apiBody]);
		}
	};

	useEffect(() => {
		if (count > 0) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	}, [count]);

	const fetchMoreData = () => {
		if (hasMoreStreams) {
			goToNextPage();
		}
	};

	useEffect(() => {
		if (tableData) {
			setLoading(false);
			setTableDisplayData([...tableData]);
			handleHasMore(tableData);
		}
	}, [tableData, setLoading]);

	const handleHasMore = (response) => {
		if (response && response.length > 0 && response.length === 20) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const goToNextPage = () => {
		setOffset(tableDisplayData?.length);
	};

	useEffect(() => {
		mount.current = 'mount';
		setLoading(true);

		return () => (mount.current = 'unmount');
	}, [setLoading]);

	const reorderLiveChannels = async (params, e, newSequence) => {
		const res = await putCommonMethod(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.REORDER_LIVE_CHANNELS_IN_SUBCATEGORY}`,
			params,
		);
		if (res.status) {
			tableDisplayData[e.newIndex].sequence = newSequence;
			setAllLiveChannels(tableDisplayData);
		}
	};

	const dragEndHandler = async (e) => {
		try {
			let newSequence;
			if (e.newIndex === 0) {
				newSequence = tableDisplayData[e.newIndex + 1].sequence + 1;
			} else if (e.newIndex === tableDisplayData.length - 1) {
				newSequence = tableDisplayData[e.newIndex - 1].sequence - 1;
			} else {
				newSequence =
					(tableDisplayData[e.newIndex - 1].sequence +
						tableDisplayData[e.newIndex + 1].sequence) /
					2;
			}
			const params = {
				live_channel_stream_id: tableDisplayData[e.newIndex].live_channel_stream_id,
				sequence: newSequence,
			};

			reorderLiveChannels(params, e, newSequence);
		} catch (e) {
			setToastInfo(true, true, GLOBAL_CONST.ERROR_MESSAGE);
		}
	};

	const renderTable = () => {
		if (loading) {
			return (
				<tbody>
					<EmptyState
						columns={CONSTANT.LIVE_STREAM_TABLE_COLUMNS_CONFIG}
						renderColumns={2}
					/>
				</tbody>
			);
		} else {
			return (
				<ReactSortable
					tag={CustomComponent}
					list={tableDisplayData}
					setList={setTableDisplayData}
					onEnd={dragEndHandler}>
					{tableDisplayData.map((item, index) => {
						return (
							<Row
								key={item && `${item.live_stream_id}`}
								data={{ ...item, index }}
								isAllChecked={isAllChecked}
								handleCheck={(event) =>
									handleCheck(event, item?.live_stream_id, item?.display_name)
								}
								handleLiveChannelId={() => {
									handleLiveChannelId(item.live_stream_id);
								}}
								countOnAllUnselected={countOnAllUnselected}
								val={val}
								handleClick={(event) => handleClick(event, item.live_stream_id)}
								id={id}
								handleChangeOnDelete={handleChangeOnDelete}
								handleChangeOnEdit={() => handleChangeOnEdit(item)}
								handleVideoPopup={() => handleVideoPopup(item?.url)}
							/>
						);
					})}
				</ReactSortable>
			);
		}
	};

	return (
		<div>
			<Table isRenderable={true}>
				<div
					id="scrollableDivLiveStreamInChannel"
					className={`${openTooltip ? 'disableScroll' : ''}`}>
					<InfiniteScroll
						dataLength={tableData.length}
						next={fetchMoreData}
						hasMore={hasMoreStreams}
						loader={<Loader loadMore={true} />}
						scrollableTarget="scrollableDivLiveStreamInChannel"
						endMessage={loading ? null : infinteScrollEndMessage()}>
						<table className="table-live-stream">
							{/* <tbody> */}
							<thead>
								<tr>
									{CONSTANT.LIVE_STREAM_TABLE_COLUMNS_CONFIG.map((column) => {
										return (
											<Column
												key={column.key}
												config={column}
												handleAllCheck={(event) => handleAllCheck(event)}
												shuffleMode={shuffleMode ?? false}
												isAllChecked={isAllChecked}
												countMultipleCheck={countMultipleCheck}
												isWaitlist={true}
											/>
										);
									})}
								</tr>
							</thead>
							{renderTable()}
							{/* </tbody> */}
						</table>
					</InfiniteScroll>
				</div>
			</Table>
			<Popup
				className="popup-content-container-digest"
				open={showDeletePopup}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={deletePopupStyle}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmationModal}
					onDelete={onDeleteChannel}
					loading={addButtonLoader}
					message={`${
						defaultDeleteMessage
							? `Are you sure you want to delete this ${channelName} live channel?`
							: `Are you sure you want to delete these ${counterToShowDelete} live channels?`
					}`}
					fromLiveScreen={true}
				/>
			</Popup>
			<Popup
				className="popup-content-container-digest"
				open={videoPopup}
				closeOnDocumentClick
				onClose={closeVideoPopup}
				contentStyle={videoPopupStyle}>
				<VideoPlayer url={videoUrl} closePopup={closeVideoPopup} />
			</Popup>
		</div>
	);
};

export default TableContainer;
