import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BucketDetails from '../BucketDetails';
import { ROOT_URL, BUCKET_DETAIL_URL } from 'src/app/common/constants/AppConstants';
import { fetchDataFromGetApi } from 'src/app/pages/Dashboard/actionCreators';
import { Loader } from 'src/app/components/Loader';
import { RESET_SUBSECTION_DATA } from 'src/app/pages/Dashboard/actionTypes';

const BucketRedirectSection = () => {
	const dispatch = useDispatch();
	const useQuery = () => {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	};
	const [metaData, setMetaData] = useState(null);
	const [bucketTags, setBucketTags] = useState(null);
	const [offset, setOffset] = useState(0);
	const [onLoad, setOnLoad] = useState('section');
	let query = useQuery();
	const val = query.get('bucket_id');
	const [bucketDetails, setBucketDetails] = useState(null);

	useEffect(() => {
		dispatch({
			type: RESET_SUBSECTION_DATA,
			payload: {
				data: [],
			},
		});
	}, []);

	const fetchData = () => {
		fetchDataFromGetApi(
			`${ROOT_URL}${BUCKET_DETAIL_URL}`,
			{
				bucket_id: val,
			},
			({ data }) => {
				if (data) {
					setBucketDetails(data);
					setMetaData(data);
					if (data?.tags) {
						setBucketTags(data?.tags);
					} else {
						setBucketTags([]);
					}
				}
			},
		);
	};

	useEffect(() => {
		fetchData();
	}, [val]);

	const updateResults = () => {
		fetchData();
	};

	if (metaData && bucketTags) {
		return (
			<BucketDetails
				metaData={metaData}
				onLoad={onLoad}
				setOnLoad={setOnLoad}
				offset={offset}
				setOffset={setOffset}
				updateResults={updateResults}
				bucketTags={bucketTags}
				fromRedirectionScreen={true}
				bucketDetails={bucketDetails}
			/>
		);
	} else {
		return <Loader />;
	}
};

export default BucketRedirectSection;
