import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import Table from 'src/app/components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'src/app/pages/Dashboard/Components/Bucket/Table/Column';
import InfiniteScroll from 'react-infinite-scroll-component';
import { infinteScrollEndMessage } from 'src/app/pages/ComponentConstant';
import { Loader } from 'src/app/components/Loader';
import * as CONST from 'src/app/pages/ReportedVideos/constant';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import { Button } from 'src/app/components/Button';
import { Row } from './Row';
import { EmptyState } from 'src/app/pages/Dashboard/Components/Bucket/Table/EmptyState';
import Popup from 'reactjs-popup';
import {
	reportVideoPopupStyle,
	deletePopupStyle,
} from 'src/app/common/constants/PopupStyles';
import ReportPopup from 'src/app/components/ReportPopup';
import * as GLOBAL_CONST from 'src/app/common/constants/AppConstants';
import {
	GET_REPORTED_VIDEO_DATA_INITIAL,
	GET_REPORTED_VIDEO_DATA,
	EDIT_DATA_ON_REPORTED_TABLE,
} from 'src/app/pages/Dashboard/actionTypes';
import { getTableData, deleteData } from 'src/app/pages/Dashboard/Service';
import { Toast } from 'src/app/components/Toast';
import DeleteConfirmation from 'src/app/components/molecules/DeleteConfirmation';
import { editTableData } from 'src/app/pages/Dashboard/actionCreators';
import Tab from 'src/app/components/molecules/Tab';
import IndividualTabContent from 'src/app/components/atoms/IndividualTabContent';

export const TableContainer = ({ shuffleMode = false, loading, setLoading }) => {
	const dispatch = useDispatch();
	const tableData = useSelector((state) => state.reportedVideo?.reportedVideos);
	const [hasMore, setHasMore] = useState(false);
	const [offset, setOffset] = useState(0);
	const [tableDisplayData, setTableDisplayData] = useState([]);
	const [isChecked, setChecked] = useState(false);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [countMultipleCheck, setCountMultipleCheck] = useState(0);
	const [count, setCount] = useState(0);
	const [val, setVal] = useState(false);
	const [id, setId] = useState([]);
	const [delVal, setDelVal] = useState(0);
	const [countOnAllUnselected, setCountOnAllUnselected] = useState([]);
	const [openPopup, setOpenPopup] = useState(false);
	const [apiBody, setApiBody] = useState([]);
	const [counterToShowDelete, setCounterToShowDelete] = useState(0);
	const [reportedUserData, setReportedUserData] = useState([]);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [defaultDeleteMessage, setdefaultDeleteMessage] = useState(true);
	const [reportVideoName, setReportVideoName] = useState(null);
	const [reportVideoId, setReportVideoId] = useState(null);
	const [buttonLoader, setButtonLoader] = useState(false);
	const [status, setStatus] = useState(false);
	const activeTab = useRef('reported');

	useEffect(() => {
		if (countMultipleCheck < 1) {
			setChecked(false);
			setIsAllChecked(false);
		} else {
			setChecked(true);
			setIsAllChecked(true);
		}
	}, [countMultipleCheck, setCountMultipleCheck]);

	useEffect(() => {
		if (apiBody.length < 1) {
			setChecked(false);
		}
	}, [apiBody]);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setToastInfo(false, false, '');
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	const handleChangeTab = (tab) => {
		activeTab.current = tab;
		if (tab === CONST.TABS[0].key) {
			setStatus(false);
		} else {
			setStatus(true);
		}
		offset > 0 ? setOffset(0) : setOffset((v) => v - 1);
	};

	const setToastInfo = (toastDisplayVal, errorVal, messageVal) => {
		setToastError(errorVal);
		setShowToastMessage(messageVal);
		setShowToast(toastDisplayVal);
	};

	const handleToastDismiss = () => {
		setToastInfo(false, false, '', true);
	};

	useEffect(() => {
		if (tableData) {
			setLoading(false);
			setTableDisplayData([...tableData]);
		}
	}, [tableData, setLoading]);

	useEffect(() => {
		if (count > 0) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	}, [count]);

	useEffect(() => {
		if (countMultipleCheck === 0) {
			setId([]);
		}
		if (isAllChecked) {
			let allCheckedValues = [];
			setCountMultipleCheck(tableData.length - delVal);
			setCounterToShowDelete(tableData.length - delVal);
			tableData.map((item) => {
				if (!id.includes(item.video_id)) {
					allCheckedValues.push(item.video_id);
				}
			});
			setApiBody([...allCheckedValues]);
		}
	}, [tableData]);

	const handleClick = (e, channel_id) => {
		if (e.target.checked === false) {
			const index = apiBody.findIndex((item) => item === channel_id);
			apiBody.splice(index, 1);
			setApiBody([...apiBody]);
			setId([...id, channel_id]);
			setVal(false);
			setCountMultipleCheck((value) => value - 1);
			setCounterToShowDelete((value) => value - 1);
			setDelVal((v) => v + 1);
		}
		if (e.target.checked === true) {
			const index = id.findIndex((item) => item === channel_id);
			id.splice(index, 1);
			setVal(false);
			setApiBody([...apiBody, channel_id]);
			setCountMultipleCheck((value) => value + 1);
			setCounterToShowDelete((value) => value + 1);
			setDelVal((v) => v - 1);
		}
	};

	const handleAllCheck = (e) => {
		setId([]);
		setDelVal(0);
		if (e.target.checked === true) {
			setChecked(true);
			setIsAllChecked(true);
			let allCheckedValues = [];
			setCountMultipleCheck(tableData.length);
			setCounterToShowDelete(tableData.length);
			tableData.map((item) => {
				allCheckedValues.push(item.video_id);
			});
			setApiBody([...allCheckedValues]);
		} else {
			setChecked(false);
			setCountMultipleCheck(0);
			setCounterToShowDelete(0);
			setIsAllChecked(false);
			setCount(0);
			setApiBody([]);
		}
		setCountOnAllUnselected([]);
	};

	const handleCheck = (e, id) => {
		if (e.target.checked === true) {
			countOnAllUnselected.push(id);
			setCountOnAllUnselected(countOnAllUnselected);
			setCounterToShowDelete((val) => val + 1);
			setCount((prev) => prev + 1);
			setApiBody([...apiBody, id]);
		} else {
			let indexOnAllUnselected = countOnAllUnselected.findIndex((item) => item === id);
			countOnAllUnselected.splice(indexOnAllUnselected, 1);
			setCountOnAllUnselected(countOnAllUnselected);
			setCounterToShowDelete((val) => val - 1);
			setCount((prev) => prev - 1);
			const index = apiBody.findIndex((item) => item === id);
			apiBody.splice(index, 1);
			setApiBody([...apiBody]);
		}
	};

	useEffect(() => {
		fetchData();
	}, [dispatch, offset]);

	const handleHasMore = (response) => {
		if (response?.data?.length > 0 && response?.data?.length === 20) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const fetchMoreData = () => {
		if (hasMore) {
			setOffset(tableDisplayData?.length);
		}
	};

	const closeReportPopup = () => {
		setReportedUserData([]);
		setOpenPopup(false);
	};
	const openReportedPopup = (videoId) => {
		const index = tableDisplayData.findIndex((item) => item.video_id === videoId);
		setReportedUserData(tableDisplayData[index].users);
		setOpenPopup(true);
	};

	const fetchData = async () => {
		const params = {
			offset: offset > 0 ? offset : 0,
			perPage: 20,
			orderBy: status ? GLOBAL_CONST.ORDER_BY.deletedAt : GLOBAL_CONST.ORDER_BY.frequency,
			order: GLOBAL_CONST.ORDER_TYPE.DESC,
			orderOn: status ? 'v' : 'vr',
			is_deleted: status,
		};

		if (offset > 0) {
			const response = await getTableData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_REPORTED_VIDEOS}`,
				params,
			);
			handleHasMore(response);
			dispatch({ type: GET_REPORTED_VIDEO_DATA, payload: response.data });
		} else {
			setLoading(true);
			const response = await getTableData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_REPORTED_VIDEOS}`,
				params,
			);
			handleHasMore(response);

			dispatch({ type: GET_REPORTED_VIDEO_DATA_INITIAL, payload: response.data });
		}
	};

	const handleChangeOnDelete = (id, title) => {
		setShowDeletePopup(true);
		setReportVideoName(title);
		setReportVideoId(id);
	};

	const handleDelete = () => {
		setdefaultDeleteMessage(false);
		setShowDeletePopup(true);
	};

	const closeDeleteConfirmationModal = () => {
		setShowDeletePopup(false);
		setdefaultDeleteMessage(true);
	};

	const onDeleteChannel = async () => {
		setButtonLoader(true);
		let apiData = [];
		if (reportVideoId) {
			apiData.push(reportVideoId);
		} else {
			apiData = apiBody;
		}
		await deleteChannels(apiData);
	};

	const removeDeletedItemFromRedux = (form, apiData) => {
		editTableData(
			`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_REPORTED_VIDEOS}`,
			form,
			({ data }) => {
				dispatch({
					type: EDIT_DATA_ON_REPORTED_TABLE,
					payload: {
						apiData,
					},
				});
			},
		);
	};

	const deleteChannels = async (apiData) => {
		try {
			const res = await deleteData(
				`${GLOBAL_CONST.ROOT_URL}${GLOBAL_CONST.GET_REPORTED_VIDEOS}`,
				{
					video_ids: apiData,
				},
			);
			if (res.status) {
				let form = {
					offset: tableData?.length,
					perPage: 20,
					orderBy: GLOBAL_CONST.ORDER_BY.frequency,
					order: GLOBAL_CONST.ORDER_TYPE.DESC,
					orderOn: 'vr',
					is_deleted: status,
				};
				removeDeletedItemFromRedux(form, apiData);
				setCountMultipleCheck(0);
				setCountOnAllUnselected([]);
				setIsAllChecked(false);
				setCounterToShowDelete(0);
				setToastInfo(true, false, res.message);
				if (apiBody.length === tableData?.length && !reportVideoId) {
					setOffset(-1);
				}
				setApiBody([]);
			}
		} catch (error) {
			setToastInfo(true, true, GLOBAL_CONST.ERROR_MESSAGE);
		}
		setButtonLoader(false);
		setShowDeletePopup(false);
		setReportVideoName('');
		setdefaultDeleteMessage(true);
	};

	const renderTable = () => {
		if (loading) {
			return (
				<EmptyState
					columns={CONST.REPORTED_TABLE_COLUMNS_CONFIG}
					renderColumns={2}
					fromReportedScreen={true}
				/>
			);
		} else {
			return tableDisplayData.map((item, index) => {
				return (
					<Row
						key={item && `${item?.video_id}`}
						data={{ ...item, index }}
						isAllChecked={isAllChecked}
						handleCheck={(event) => handleCheck(event, item?.video_id, item?.title)}
						val={val}
						handleClick={(event) => handleClick(event, item?.video_id)}
						id={id}
						countOnAllUnselected={countOnAllUnselected}
						openReportedPopup={openReportedPopup}
						handleChangeOnDelete={handleChangeOnDelete}
						status={status}
					/>
				);
			});
		}
	};

	return (
		<div>
			<div className={`button-wrapper`}>
				{!status && (
					<Button
						onClick={handleDelete}
						imageInfo={{
							src: DeleteBucket,
							label: 'Delete',
						}}
						label={isChecked ? 'Delete' : 'Delete'}
						className={`${
							isChecked
								? 'button-pink reported-delete-button'
								: 'button-black reported-delete-button'
						}`}
						labelClassName={`${isChecked ? 'label-white' : 'label-grey'}`}
						disabled={!isChecked}
					/>
				)}
				<div>
					<Tab
						tabs={CONST.TABS}
						active={activeTab.current}
						handleTabChange={handleChangeTab}
						className="waitlist-tabs"
						fromDigest={true}
					/>
				</div>
			</div>
			<Table isRenderable={true}>
				<div id="scrollableDivReported">
					<InfiniteScroll
						dataLength={tableData.length}
						next={fetchMoreData}
						hasMore={hasMore}
						loader={<Loader loadMore={true} />}
						scrollableTarget="scrollableDivReported"
						endMessage={loading ? null : infinteScrollEndMessage()}>
						<table className="table-reported-videos">
							<thead>
								<tr>
									{CONST.REPORTED_TABLE_COLUMNS_CONFIG.map((column, index) => {
										if (
											status &&
											index === CONST.REPORTED_TABLE_COLUMNS_CONFIG.length - 1
										) {
											return null;
										}
										return (
											<Column
												key={column.key}
												config={column}
												handleAllCheck={(event) => handleAllCheck(event)}
												shuffleMode={shuffleMode ?? false}
												isWaitlist={true}
												isAllChecked={isAllChecked}
												countMultipleCheck={countMultipleCheck}
												status={status}
												marginClassName="extra-margin-report"
											/>
										);
									})}
								</tr>
							</thead>
							<tbody>{renderTable()}</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</Table>
			<Popup
				className="popup-content-container-digest"
				open={openPopup}
				closeOnDocumentClick
				onClose={closeReportPopup}
				contentStyle={reportVideoPopupStyle}>
				<ReportPopup
					closeReportPopup={closeReportPopup}
					dataOfReporters={reportedUserData}
				/>
			</Popup>
			<Popup
				className="popup-content-container-digest"
				open={showDeletePopup}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={deletePopupStyle}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmationModal}
					onDelete={onDeleteChannel}
					loading={buttonLoader}
					message={
						defaultDeleteMessage
							? `Are you sure you want to delete '${reportVideoName}'  video from the platform?`
							: counterToShowDelete === 1
							? CONST.DELETE_MESSAGE
							: `Are you sure you want to delete these ${counterToShowDelete} videos from the platform?`
					}
					fromLiveScreen={true}
				/>
			</Popup>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
		</div>
	);
};

export default TableContainer;
