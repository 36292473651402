import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import DeleteBucket from 'src/app/assets/images/delete.svg';
import DeleteBucketActive from 'src/app/assets/images/DeleteIconActive.svg';
import EditIcon from 'src/app/assets/images/EditActionButton.svg';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { overlayStyle } from 'src/app/common/constants/AppConstants';
import { getRowClassName } from 'src/app/common/utils/methods';

export const Row = ({
	data,
	isAllChecked,
	handleCheck,
	handleLiveChannelId,
	val,
	handleClick,
	id,
	handleChangeOnEdit,
	handleChangeOnDelete,
	countOnAllUnselected,
	handleVideoPopup,
}) => {
	const [icon, setIcon] = useState(DeleteBucket);
	const findValues = id.includes(data?.live_stream_id);
	const valueOnAllUnchecked = countOnAllUnselected.includes(data?.live_stream_id);
	const addDefaultSrc = (ev) => {
		ev.target.className = 'row-icon-img row-icon-img-none';
		ev.target.src = '';
	};

	const onMouseEnterOnDeleteButton = () => {
		setIcon(DeleteBucketActive);
	};

	const onMouseLeaveOnDeleteButton = () => {
		setIcon(DeleteBucket);
	};

	return (
		<tr className={getRowClassName(data?.index)}>
			<td>
				<span className="checkbox">
					{isAllChecked ? (
						findValues ? (
							<input
								type="checkbox"
								id="name-checkbox-row"
								value="all"
								checked={val}
								onChange={handleClick}
							/>
						) : (
							<input
								type="checkbox"
								id="name-checkbox-row"
								value="all"
								checked={isAllChecked}
								onChange={handleClick}
							/>
						)
					) : (
						<input
							type="checkbox"
							id="name-checkbox-row"
							value={data?.display_name}
							onChange={handleCheck}
							checked={valueOnAllUnchecked}
						/>
					)}

					<span className="bucket-name">{data?.display_name}</span>
				</span>
			</td>
			<td className="row-url" onClick={handleVideoPopup}>
				{data?.url}
			</td>
			<td className="row-icon">
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<img
						onError={addDefaultSrc}
						className="row-icon-img"
						src={`${data?.logo}`}
						alt="icon"
					/>
				</div>
			</td>
			<td className="row-actions">
				<Tooltip
					overlay="Delete Live Channel"
					placement="bottom"
					overlayInnerStyle={overlayStyle}
					align={{
						offset: [-4, -2],
					}}>
					<span
						className="live-stream-action-button"
						onClick={() => handleChangeOnDelete(data?.live_stream_id, data?.display_name)}
						onMouseEnter={() => onMouseEnterOnDeleteButton()}
						onMouseLeave={() => onMouseLeaveOnDeleteButton()}>
						<img src={icon} className="live-stream-action-button-image" alt="delete" />
					</span>
				</Tooltip>
				<Tooltip
					overlay="Edit Live Channel"
					placement="bottom"
					overlayInnerStyle={overlayStyle}
					align={{
						offset: [8, -2],
					}}>
					<span className="live-stream-action-button" onClick={handleChangeOnEdit}>
						<img
							src={EditIcon}
							className="live-stream-action-button-image-exta-styling"
							alt="edit"
						/>
					</span>
				</Tooltip>
			</td>
		</tr>
	);
};

Row.propTypes = {
	data: PropTypes.object.isRequired,
};
