export const LOCAL_STORAGE_KEYS = {
	accessToken: 'accessToken',
};

export const DATE_FORMATS = {
	dayTimeFormat: 'ddd, MMM DD, YYYY',
	dayMonthYear: 'DD/MM/YYYY',
	monthDayYear: 'MM/DD/YYYY',
	datePickerDefaultFormat: 'ddd MMM DD YYYY hh:mm:ss',
};

export const logoutModal = {
	title: 'Logout',
	message: 'Are you sure you want to logout?',
	no: 'No',
	yes: 'Logout',
};

export const ROOT_URL = process.env.REACT_APP_API_BASE_URL;
export const UPDATE_BUCKET_URL = '/bucket/updateRankById';
export const GET_FILTER_URL = '/bucket/getFilter';
export const GET_VIDEOS_URL = '/bucket/getVideosNew';
export const ADD_VIDEOS_URL = '/bucket/addVideos';
export const SCREEN_URL = '/screen';
export const METADATA_URL = '/bucket/metadata';
export const GET_DASHBOARD_URL = '/bucket/getDashboardCarousel';
export const DELETE_VIDEOS_URL = '/bucket/deleteVideos';
export const PUBLISHED_BUCKETS_URL = '/screen/publishedBuckets';
export const BUCKET_URL = '/bucket';
export const BUCKET_DETAIL_URL = '/bucket/details';
export const GET_CARD_SIZE = '/bucket/getCardSize';
export const GET_CATEGORY = '/bucket/getCategory';
export const GET_FILTERS_URL = '/video/filters';
export const GET_FILTERED_VIDEOS = '/video/getFilteredVideos?';
export const UPDATE_STATUS_BY_ID = '/bucket/updateStatusById';
export const UPDATE_STATUS_BY_SCREEN = '/bucket/updateStatusOfScreen';
export const CONTENT = '/content';
export const WAITLIST_URL = '/user/getWaitlistUsers';
export const INVITE_URL = '/user/acceptInviteLink';
export const UPDATE_QEWD_VIDEOS = '/qewd-digest/updateQewdVideos';
export const QEWD_DIGEST_PUBLISH_FREQUENCY = '/qewd-digest';
export const QEWD_DIGEST = '/qewd-digest';
export const QEWD_VIDEOS = '/qewd-digest/getQewdVideos';
export const GET_LIVE_CHANNEL_URL = '/live/getListOfLiveChannels';
export const GET_LIVE_STREAM = '/live/getListOfLiveStreams';
export const GET_LIVE_CATEGORY = '/live/getListOfLiveCategories';
export const ADD_CHANNEL_TO_CATEGORY = '/live/addChannelToCategory';
export const CREATE_LIVE_CHANNELS = '/live/createLiveChannel';
export const DELETE_LIVE_CHANNEL = '/live/deleteChannel';
export const DELETE_LIVE_STREAMS = '/live/deleteStream';
export const CREATE_LIVE_CATEGORY = '/live/createLiveCatgeory';
export const EDIT_LIVE_CATEGORY = '/live/editLiveCategory';
export const LIVE_STREAM_BY_CHANNEL = '/live/getLiveStreamsByChannelId';
export const DELETE_LIVE_STREAM_FROM_CHANNEL = '/live/deleteLiveStreamFromLiveChannel';
export const DELETE_CATEGORY = '/live/deleteCategories';
export const ADD_STREAM_TO_CHANNEL = '/live/addLiveStreamToLiveChannel';
export const ADD_STREAM_URL = '/live/addLiveStreamUrl';
export const EDIT_STREAM_URL = '/live/editLiveStreamUrl';
export const EDIT_LIVE_CHANNELS = '/live/editLiveChannel';
export const GET_LIVE_CHANNEL_BY_CATEGORY = '/live/getListOfLiveChannelsForACategory';
export const DELETE_LIVE_CHANNEL_FROM_CATEGORY = '/live/deleteChannelFromCategory';
export const GET_USER_BY_EMAIL = '/user/getByEmail';
export const GET_USER_BY_PHONE = '/user/getByPhoneNumber';
export const GET_USER_BY_USERID = '/user/getByUserId';
export const IMAGE_PLACEHOLDER_HORIZONTAL =
	'https://qewd-infra-test-s3bucket-1js7zf7qud70u.s3.us-west-2.amazonaws.com/Placeholder-Horizontal.svg';
export const IMAGE_PLACEHOLDER_VERTICAL =
	'https://qewd-infra-test-s3bucket-1js7zf7qud70u.s3.us-west-2.amazonaws.com/Placeholder-Vertical.svg';
export const ICON_URL =
	'https://qewd-test-live-category.s3.us-west-2.amazonaws.com/live-channel-icons/';
export const CATEGORY_THUMNAIL_URL =
	'https://qewd-test-live-category.s3.us-west-2.amazonaws.com/';
export const XBYTE_AUTOCOMPLETE_URL = 'https://reelgood.xbyte.io/autocomplete';
export const SEARCH_ADD_VIDEO = '/bucket/searchAndAddBucketVideos';
export const SEARCH_SUGGESTION_URL = '/search/suggestions';
export const REORDER_LIVE_CHANNELS_IN_CATEGORY = '/live/reorderSubcategoryOfCategory';
export const REORDER_LIVE_CHANNELS_IN_SUBCATEGORY = '/live/reorderChannelOfSubCategory';
export const SEARCH_REPORT = '/search/searchReport';
export const GET_USERS = '/user';
export const GET_UNMAPPED_LIVE_CHANNEL =
	'/live/getListOfLiveStreamsNotPartOfAnySubCategory';
export const GET_DELETED_LIVE_CHANNEL = '/live/getDeletedLiveStreams';
export const GET_REPORTED_VIDEOS = '/video/reportVideo';
export const GET_DETAILS_BY_ID = '/video/getByIdAndType';
export const GET_EPISODE_DETAILS = '/video/getSeasonEpisodes';
export const GET_USER_TAGS = '/user/getTags';
export const DEACTIVATE_USER = '/user/deactivateAccount';
export const OPEN_SEARCH = '/es/searchGet';
export const GET_CAST = '/video/cast';
export const CAST_LIST = '/cast';
export const CAST_UPDATE = '/cast/details';
export const CAST_COUNT = '/cast/count';
export const CAST_AVATAR = '/cast/avatar';
export const PROVIDER_FREE = '/provider/list';
export const PROVIDER_MARK = '/provider/markAsPlayableOnQewd';
export const GET_TAGS = '/tag/getCuratedSelectedTags';
export const UPDATE_VIDEO_RANK = '/bucket/updateBucketVideoRank';
export const TAG_COUNT = '/tag/getCuratedTagsCount';
export const BUCKET_METADATA = '/bucket/getBucketsMetadata';
export const TAG_THUMBNAIL = '/tag';
export const UPDATE_TAGS = '/tag/markTagsAsCurated';
export const DELETE_USER = '/user/deleteUsersByAdmin';
export const MARK_LIST_FOR_YOU = '/bucket/markAsChildrenBucketOfParent';
export const PARENT_BUCKET_URL = '/bucket/parentBuckets';
export const GET_LIST_OF_YOU = '/bucket/childrenBucketOfParent';
export const REMOVE_LIST_FOR_YOU = '/bucket/removeChildrenBuckeOfParent';
export const REORDER_LIST_FOR_YOU = '/bucket/reorderChildBucket';
export const ARCHIVE_MULTIPLE = '/bucket/updateStatusOfBuckets';
export const ADD_SHORTFORM = '/bucket/addShortFormsToBucket';
export const GET_LIVE_BUCKET = '/bucket/content/getByAdmin';
export const ADD_LIVE_CHANNEL_IN_BUCKET = '/bucket/content';
export const LIVE_BUCKET_REORDER = '/bucket/content/reorder';

export const ORDER_BY = {
	name: 'name',
	createdAt: 'created_at',
	sequence: 'sequence',
	frequency: 'frequency',
	deletedAt: 'deleted_at',
};

export const ORDER_TYPE = {
	ASC: 'asc',
	DESC: 'desc',
};

export const overlayStyle = {
	background: '#2A2A31',
	borderRadius: 8,
	fontFamily: 'TT-Commons-Demi',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: 16,
	color: '#FFFFFF',
	paddingRight: 15,
	paddingLeft: 15,
	filter: ' drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.35))',
	paddingTop: 12,
};

export const ERROR_MESSAGE = 'Something Went Wrong, Please Try Again Later';
export const REPORT_POPUP_HEADING = 'Reported Reasons';
export const COLORS = {
	background: '#2A2A31',
};

export const CONTENT_TYPE = {
	MOVIE_SHOWS_VIDEOS: 'movie_show_video',
	MOVIE: 'movie',
	SHOW: 'show',
};
