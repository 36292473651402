import React, { useRef, useState } from 'react';
import DeleteButton from 'src/app/assets/images/delete.png';
import { Button } from 'src/app/components/Button';
import { IMAGE_PLACEHOLDER_HORIZONTAL } from 'src/app/common/constants/AppConstants';
import './styles.scss';
import { getImage } from 'src/app/common/utils/methods';

const BigCard = ({
	cardDetails,
	onSelection,
	id,
	index,
	toggleDeleteConfirmation,
	title,
	noTap,
}) => {
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [show, setShow] = useState(false);
	const imageContainerRef = useRef();
	const description = cardDetails?.description_alias?.split(' ');
	const onSelectionChange = (e) => {
		if (e && e.target && noTap) {
			setShowDeleteButton(true);
			onSelection(id, title);
		}
	};

	const onMouseLeave = () => {
		setShowDeleteButton(false);
	};

	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
		ev.target.className = 'cardImgHor placeholderImage';
	};

	return (
		<div
			className="big-card-container"
			id={`big-card-wrapper-${id}`}
			onMouseLeave={onMouseLeave}
			onMouseDown={onSelectionChange}
			onMouseOver={onSelectionChange}
			onMouseEnter={onSelectionChange}>
			<div className="header">
				<h3 className="count">{index < 9 ? `0${index + 1}` : index + 1} </h3>
				{showDeleteButton && (
					<img
						src={DeleteButton}
						alt="delete"
						onClick={() => toggleDeleteConfirmation(true)}
					/>
				)}
			</div>
			<div
				ref={imageContainerRef}
				id={`big-card-container-${id}`}
				className="big-card-container-image">
				<img
					className="big-card-img"
					src={getImage(cardDetails.horizontal_thumbnail)}
					alt="card"
					onError={addDefaultSrc}
				/>
				{/* <div className="overlay" /> */}
			</div>
			<div className="card-details">
				<div className="big-card-label">
					<p>{cardDetails?.title}</p>
				</div>
				<div className="big-card-sub-title">
					{description?.length > 0 && (
						<p
							aria-expanded="false"
							id={`collapse-description-${id}`}
							className={show ? 'show-description' : 'collapse-description'}>
							{description.join(' ')}
						</p>
					)}
					{description?.length > 50 && (
						<Button
							onClick={() => {
								setShow(!show);
							}}
							label={show ? 'Less' : 'More'}
							className="more"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default BigCard;
