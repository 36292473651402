import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { getCommonMethod } from 'src/app/pages/Dashboard/Service';
import {
	ROOT_URL,
	GET_DETAILS_BY_ID,
	GET_CAST,
} from 'src/app/common/constants/AppConstants';
import { Loader } from 'src/app/components/Loader';
import Episodes from 'src/app/components/Episodes';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';
import BackIcon from 'src/app/assets/images/BackIcon.svg';
import { IMAGE_PLACEHOLDER_HORIZONTAL } from 'src/app/common/constants/AppConstants';
import * as CONST from './constant';
import ShowMoreText from 'react-show-more-text';
import CastProfile from 'src/app/components/atoms/CastProfile';
import Arrow from 'src/app/assets/images/ArrowCarousal.svg';
import GreenDot from 'src/app/assets/logos/GreenDot.svg';

export const VideoDetailPopup = ({ videoId, closePopup, contentType }) => {
	const [data, setData] = useState(null);
	const [show, setShow] = useState(false);
	const [description, setDescription] = useState(null);
	const [episodeDetail, setEpisodeDetail] = useState(false);
	const [episodeVideoId, setEpisodeVideoId] = useState(null);
	const [episodeDescription, setEpisodeDescription] = useState(null);
	const [episodeData, setEpisodeData] = useState(null);
	const [genre, setGenre] = useState([]);
	const ref = useRef(null);
	const dirRef = useRef(null);
	const [genreShow, setGenreShow] = useState(false);
	const [actor, setActor] = useState([]);
	const [director, setDirector] = useState([]);

	const [arrowActor, setArrowActor] = useState({
		left: false,
		right: true,
	});

	const [arrowDirector, setArrowDirector] = useState({
		left: false,
		right: true,
	});

	useEffect(() => {
		fetchVideoData(false);
		fetchCastData();
	}, []);

	useEffect(() => {
		if (data) {
			const genre_data = [];
			data?.genres?.map((item) => {
				genre_data.push(item?.name);
			});
			setGenre(genre_data);
			handleActorArrow();
			handleDirectorArrow();
		}
	}, [data]);

	const handleActorArrow = () => {
		document.querySelector('#actor')?.addEventListener('scroll', () => {
			const ele = document.querySelector('#actor');

			if (
				ele.scrollLeft !== 0 &&
				ele.scrollWidth - Math.floor(ele.scrollLeft) === ele.offsetWidth
			) {
				setArrowActor({
					left: true,
					right: false,
				});
			} else if (ele.scrollLeft === 0) {
				setArrowActor({
					left: false,
					right: true,
				});
			} else {
				setArrowActor({
					left: true,
					right: true,
				});
			}
		});
	};

	const handleDirectorArrow = () => {
		document.querySelector('#director')?.addEventListener('scroll', () => {
			const elem = document.querySelector('#director');

			if (
				elem.scrollLeft !== 0 &&
				elem.scrollWidth - Math.floor(elem.scrollLeft) === elem.offsetWidth
			) {
				setArrowDirector({
					left: true,
					right: false,
				});
			} else if (elem.scrollLeft === 0) {
				setArrowDirector({
					left: false,
					right: true,
				});
			} else {
				setArrowDirector({
					left: true,
					right: true,
				});
			}
		});
	};

	useEffect(() => {
		if (episodeVideoId) {
			fetchVideoData(true);
		}
	}, [episodeVideoId]);

	const addDefaultSrc = (ev) => {
		ev.target.src = IMAGE_PLACEHOLDER_HORIZONTAL;
	};

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#video-detail-container');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const getDate = (val) => {
		const date = new Date(val);
		return date.getFullYear();
	};

	const fetchVideoData = async (episodes = false) => {
		try {
			const response = await getCommonMethod(`${ROOT_URL}${GET_DETAILS_BY_ID}`, {
				video_id: episodes ? episodeVideoId : videoId,
				content_type: episodes ? 'episode' : contentType,
			});
			if (episodes) {
				setEpisodeDescription(response.data?.description);
				setEpisodeData(response?.data);
			} else {
				setDescription(response.data?.description);
				setData(response?.data);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const fetchCastData = async () => {
		try {
			const response = await getCommonMethod(`${ROOT_URL}${GET_CAST}`, {
				video_id: videoId,
			});
			setActor(response.data.actor);
			setDirector(response.data.director);
		} catch (e) {}
	};

	const handleEpisodeDetails = (id) => {
		setEpisodeDetail(true);
		setEpisodeVideoId(id);
		setTimeout(() => {
			handleScrollToTop();
			document.getElementById('video-detail-wrapper').style.display = 'none';
		}, 600);
	};

	const handleBack = () => {
		setEpisodeDetail(false);
		setTimeout(() => {
			setEpisodeData(null);
			setEpisodeDescription(null);
			setEpisodeVideoId(null);
			document.getElementById('video-detail-wrapper').style.display = 'block';
			handleScrollToTop();
		}, 600);
	};

	const showDescription = () => {
		return (
			<div className="big-card-sub-title">
				<ShowMoreText
					lines={4}
					more="More"
					less="Less"
					className="content-style"
					anchorClass="more"
					onClick={() => {
						setShow(!show);
					}}
					expanded={false}
					width={420}
					truncatedEndingComponent={'... '}>
					{episodeDetail ? episodeDescription : description}
				</ShowMoreText>
			</div>
		);
	};

	const episodeShowDetail = () => {
		return (
			<div className="episode-info">
				<div className="show-wrap">
					<div className="detail-head">Show</div>
					<div className="detail-content">{episodeData?.show_title}</div>
				</div>
				<div className="episode-season">
					<div className="season-info">
						<div className="detail-head">Season</div>
						<div className="season-num">{episodeData?.season_no}</div>
					</div>
					<div className="episode-count">
						<div className="detail-head">Episode</div>
						<div className="season-num">{episodeData?.episode_no}</div>
					</div>
				</div>
			</div>
		);
	};

	const showDetailInfo = () => {
		return (
			<div className="video-info-wrapper">
				<div className="ratings">
					{data?.imdb_rating && (
						<div className="imdb-ratings">
							<img className="green-dot" alt="Green-dot" src={GreenDot} />
							<span className="detail-heading">{`IMDB ${data?.imdb_rating}`}</span>
						</div>
					)}
					{data?.metadata_json?.pg_rating && (
						<div className="imdb-ratings">
							<img className="green-dot" alt="Green-dot" src={GreenDot} />
							<span className="detail-heading">{`PG ${data?.metadata_json?.pg_rating}
						`}</span>
						</div>
					)}
				</div>
				<div className="date-season-rating">
					<div className="content">
						{episodeDetail ? episodeData?.released_date : data?.released_date}
					</div>
					{contentType === 'show' && <div className="dot"></div>}
					{contentType === 'show' && (
						<div className="content">{`${data?.seasons_count} ${
							data?.seasons_count > CONST.SINGLE_COUNT ? 'Seasons' : 'Season'
						}`}</div>
					)}
					{contentType === 'show' && <div className="dot"></div>}
					{contentType === 'show' && (
						<div className="content">{`${data?.episode_count} ${
							data?.episode_count > CONST.SINGLE_COUNT ? 'Episodes' : 'Episode'
						}`}</div>
					)}
				</div>
				<div className="genre">
					<div className="content">{`Genre: ${genre.join(', ')}`}</div>
				</div>
			</div>
		);
	};

	const episodeWatchOn = () => {
		return (
			<div className="watch-on-image-wrapper">
				{episodeData?.providers.map((item, index) => {
					return (
						<a href={item?.web_link} target="_blank" key={index}>
							<div className="provider-image-div">
								<img className="provider-image" src={item?.logo} />
								<div className="provider-availibility">
									{CONST.PROVIDER_AVAILIBILTY_MAPPER[item?.availability_type]}
								</div>
							</div>
						</a>
					);
				})}
			</div>
		);
	};

	const renderWatchOn = () => {
		return (
			<div className="watch-on-image-wrapper">
				{data?.providers.map((item, index) => {
					return (
						<a href={item?.web_link} target="_blank" key={index}>
							<div className="provider-image-div">
								<img className="provider-image" src={item?.logo} />
								<div className="provider-availibility">
									{CONST.PROVIDER_AVAILIBILTY_MAPPER[item?.availability_type]}
								</div>
							</div>
						</a>
					);
				})}
			</div>
		);
	};

	const watchOn = () => {
		return (
			<div className="video-detail-watch">
				<div className="watch-on">Watch On</div>
				{episodeDetail ? episodeWatchOn() : renderWatchOn()}
			</div>
		);
	};

	const scroll = (scrollOffset) => {
		ref.current.scrollLeft += scrollOffset;
	};

	const scrollDirector = (scrollOffset) => {
		dirRef.current.scrollLeft += scrollOffset;
	};

	const getActors = () => {
		return (
			<div className="actors">
				<div className="sub-heading">{actor.length > 1 ? CONST.ACTORS : CONST.ACTOR}</div>
				<div className="actor-container">
					{actor.length > 4 && (
						<img
							src={Arrow}
							alt={'arrow'}
							className={`left-arrow ${arrowActor.left ? '' : 'deamed'}`}
							onClick={() => scroll(-224)}
						/>
					)}
					<div
						className={`cast-container ${actor.length <= 4 ? 'more-margin' : ''}`}
						id="actor"
						ref={ref}>
						{actor?.map((data, index) => {
							return (
								<div key={index}>
									<CastProfile data={data} />
								</div>
							);
						})}
					</div>
					{actor.length > 4 && (
						<img
							src={Arrow}
							alt={'arrow'}
							className={`right-arrow ${arrowActor.right ? '' : 'deamed'}`}
							onClick={() => scroll(224)}
						/>
					)}
				</div>
			</div>
		);
	};

	const getDirector = () => {
		return (
			<div className="director">
				<div className="sub-heading">
					{director.length > 1 ? CONST.DIRECTORS : CONST.DIRECTOR}
				</div>
				<div className="director-container">
					{director.length > 4 && (
						<img
							src={Arrow}
							alt={'arrow'}
							className={`left-arrow ${arrowDirector.left ? '' : 'deamed'}`}
							onClick={() => scrollDirector(-224)}
						/>
					)}
					<div
						className={`cast-container ${director.length <= 4 ? 'more-margin' : ''}`}
						id="director"
						ref={dirRef}>
						{director?.map((data, index) => {
							return (
								<div key={index}>
									<CastProfile data={data} />
								</div>
							);
						})}
					</div>
					{director.length > 4 && (
						<img
							src={Arrow}
							alt={'arrow'}
							className={`right-arrow ${arrowDirector.right ? '' : 'deamed'}`}
							onClick={() => scrollDirector(224)}
						/>
					)}
				</div>
			</div>
		);
	};

	const showCast = () => {
		return (
			<div className="cast-wrapper">
				{(actor.length > 0 || director.length > 0) && (
					<div className="cast-heading">{'Cast & Crew'}</div>
				)}
				{actor.length > 0 && getActors()}
				{director.length > 0 && getDirector()}
			</div>
		);
	};

	return (
		<div className="video-detail-container" id="video-detail-container">
			{data ? (
				<div
					className={`video-detail-wrapper ${episodeDetail ? 'slide' : ''}`}
					id="video-detail-wrapper">
					<img
						onError={addDefaultSrc}
						src={data?.horizontal_thumbnail || IMAGE_PLACEHOLDER_HORIZONTAL}
						alt="video-title"
						className="data-image"
					/>
					<img
						src={CrossIcon}
						alt="close"
						className="cross-icon-popup"
						onClick={() => {
							closePopup();
						}}
					/>
					<h2 className="video-title">{data?.title}</h2>
					<div className="video-details">
						{showDetailInfo()}
						{showDescription()}
						{watchOn()}
					</div>

					{contentType === 'show' && <div className="seprator"></div>}
					{contentType === 'show' && (
						<Episodes
							data={data}
							videoId={videoId}
							handleEpisodeDetails={handleEpisodeDetails}
						/>
					)}

					<div className="cast-and-crew">{showCast()}</div>
				</div>
			) : (
				<div className="video-detail-loader-container">
					<Loader />
				</div>
			)}

			{episodeData && (
				<div className={`episode-wrapper ${episodeDetail ? '' : 'slide-two'}`}>
					<img
						onError={addDefaultSrc}
						src={episodeData?.horizontal_thumbnail || IMAGE_PLACEHOLDER_HORIZONTAL}
						alt="video-title"
						className="data-image"
					/>
					<img
						src={BackIcon}
						alt="close"
						className="back-icon-popup"
						onClick={handleBack}
					/>
					<img
						src={CrossIcon}
						alt="close"
						className="cross-popup"
						onClick={() => {
							closePopup();
						}}
					/>
					<h2 className="video-title">{episodeData?.title}</h2>
					<div className="video-details">
						{showDescription()}
						{episodeShowDetail()}
						{watchOn()}
					</div>
				</div>
			)}
		</div>
	);
};

export default VideoDetailPopup;
