import React from 'react';
import CrossIcon from 'src/app/assets/images/CrossIcon.svg';
import './styles.scss';
import { REPORT_POPUP_HEADING } from 'src/app/common/constants/AppConstants';
import ReportContent from './ReportContent';

export const ReportPopup = ({ closeReportPopup, dataOfReporters }) => {
	return (
		<div className="report-popup-wrapper">
			<span className="cross-icon" onClick={() => closeReportPopup()}>
				<img src={CrossIcon} alt="close" />
			</span>
			<div className="report-popup-heading">{REPORT_POPUP_HEADING}</div>
			<div className="seprator"></div>
			<div className="report-content-container">
				{dataOfReporters.map((item, index) => {
					return <ReportContent item={item} key={index} />;
				})}
			</div>
		</div>
	);
};

export default ReportPopup;
