import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	addVideoToBucket,
	getSubSectionData,
	deleteVideoFromBucket,
	resetSubSectionData,
	getFilters,
} from '../../actionCreators';
import IconTextButton from '../../../../components/atoms/IconTextButton';
import CustomSelectBox from '../../../../components/atoms/CustomSelectBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../../components/Loader';
import { Toast } from '../../../../components/Toast/index';
import Popup from 'reactjs-popup';
import { FilterContainer } from '../../../../components/Filter';
import DeleteBucket from '../../../../assets/images/delete.svg';
import { FilterTags } from '../../../../components/Filter/FilterTags';
import Card from './Components/Card';
import { useRef } from 'react';
import EditButton from './Components/EditButton';
import DeleteConfirmation from '../../../../components/molecules/DeleteConfirmation';
import { Button } from '../../../../components/Button';
import {
	isEmptyString,
	transformValuesForParams,
} from '../../../../common/utils/methods';
import CloseIcon from '../../../../assets/images/TransparentClose.svg';
import * as CONST from './Components/Constant';
import './styles.scss';
import {
	GET_SUBSECTION_DATA,
	RESET_SUBSECTION_DATA,
	GET_FILTERS,
} from '../../actionTypes';
import {
	ROOT_URL,
	GET_VIDEOS_URL,
	ADD_VIDEOS_URL,
	DELETE_VIDEOS_URL,
	GET_FILTERS_URL,
	SEARCH_REPORT,
} from '../../../../common/constants/AppConstants';
import EmptyImage from '../../../../assets/images/empty-state.png';
import { EditBucketModal } from 'src/app/components/Modals/EditBucketModal';
import { ButtonLoader } from 'src/app//components/ButtonLoader';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { overlayStyle } from 'src/app/common/constants/AppConstants';
import ScrollToTop from 'src/app/components/atoms/ScrollToTop';
import {
	tagsPopupStyle,
	videoDetailsPopupStyle,
} from 'src/app/common/constants/PopupStyles';
import TagsPopup from 'src/app/components/atoms/TagsPopup';
import BackIcon from 'src/app/assets/images/BackIcon.svg';
import { getCommonMethod } from 'src/app/pages/Dashboard/Service';
import VideoDetailPopup from 'src/app/components/VideoDetailPopup';
import { popupContentStyleBucketDetails } from 'src/app/common/constants/PopupStyles';
import ListActive from 'src/app/assets/logos/ListActive.svg';
import ListInactive from 'src/app/assets/logos/ListInactive.svg';
import GridActive from 'src/app/assets/logos/GridActive.svg';
import GridInactive from 'src/app/assets/logos/GridInactive.svg';
import TableContainer from './Components/Table';
import Table from 'src/app/components/Table';

const BucketDetails = ({
	metaData,
	onLoad,
	setOnLoad,
	offset,
	setOffset,
	contentType,
	updateResults,
	bucketTags,
	fromRedirectionScreen = false,
	bucketDetails,
}) => {
	const dispatch = useDispatch();
	const searchRef = useRef();
	const movieCastRef = useRef();
	const [filters, setFilters] = useState([]);
	const [movieCaseFilters, setMovieCaseFilters] = useState([]);
	const [directors, setDirectors] = useState([]);
	const [movieCast, setMovieCast] = useState([]);
	const [copyOfFiltersForTags, setCopyOfFiltersForTags] = useState([]);
	const [copyOfMovieCastFiltersForTags, setCopyOfMovieCastFiltersForTags] = useState([]);
	const [enableSelection, setEnableSelection] = useState(false);
	const [isFilterVisible, setIsFilterVisible] = useState(false);
	const [openServicesSearch, setOpenServicesSearch] = useState(false);
	const [selectedCards, setSelectedCards] = useState([]);
	const carouselData = useSelector((state) => {
		return {
			data: state.reservations?.subSectionData?.data,
			length: state.reservations?.subSectionData?.length,
		};
	});
	const selectedBucket = useSelector((state) => state.reservations?.selectedBucket);
	const [filtersApplied, setFiltersApply] = useState(false);
	const [onDeleteLoading, setOnDeleteLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenDeleteComfirmation, setIsOpenDeleteComfirmation] = useState(false);
	const [videoId, setVideoId] = useState(null);
	const [showToast, setShowToast] = useState(false);
	const [showToastMessage, setShowToastMessage] = useState('');
	const [toastError, setToastError] = useState(false);
	const [bucketListUpdateFlag, setBucketListUpdateFlag] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [sortBy, setSortBy] = useState(null);
	const [orderBy, setOrderBy] = useState({ sortBy: 'video_sequence', orderBy: 'asc' });
	const [sortByFilter, setSortByFilter] = useState([]);
	const isRendering = useRef(true);
	const scrollRef = useRef(null);
	const filterData = useSelector((state) => state.reservations?.filters);
	const [editBucket, setEditBucket] = useState(false);
	const [viewTagsPopup, setViewTagsPopup] = useState(false);
	const [deleteAll, setDeleteAll] = useState(false);
	const [videoProgress, setVideoProgress] = useState(false);
	const [searchReportData, setSearchReportData] = useState([]);
	const [searchReportOffset, setSearchReportOffset] = useState(0);
	const [hasMoreSearchReportData, setHasMoreSearchReportData] = useState(false);
	const [unCheckedOnSelectOne, setUncheckedOnSelectOne] = useState(deleteAll);
	const [showVideoDetailPopup, setShowVideoDetailPopup] = useState(false);
	const [popupVideoId, setPopupVideoId] = useState(null);
	const [contentTypeVideo, setContentTypeVideo] = useState(null);
	const [viewType, setViewType] = useState(CONST.VIEW_TYPE.grid);
	const [shuffle, setShuffle] = useState(false);

	useEffect(() => {
		if (showToast) {
			const interval = setInterval(() => {
				setShowToast(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [showToast]);

	useEffect(() => {
		if (selectedCards.length > 0) {
			setEnableSelection(true);
		} else {
			setEnableSelection(false);
		}

		if (selectedCards.length === carouselData.data.length && selectedCards.length) {
			setDeleteAll(true);
			setUncheckedOnSelectOne(true);
		}
	}, [selectedCards]);

	const handleShuffle = () => {
		setShuffle(!shuffle);
	};

	const fetchAllDeleteData = (data, fetchingMoreVideo) => {
		if (deleteAll) {
			let val = [];
			if (fetchingMoreVideo) {
				val = [...selectedCards];
			}
			data.map((movieItem) => {
				val.push({ id: movieItem.video_id.toString(), value: enableSelection });
			});
			setSelectedCards([...val]);
		}
	};

	const handleHasMoreReport = (response) => {
		if (response?.data?.length === 20) {
			setHasMoreSearchReportData(true);
		} else {
			setHasMoreSearchReportData(false);
		}
	};

	useEffect(() => {
		fetchAllDeleteData(carouselData.data, false);
	}, [deleteAll]);

	useEffect(() => {
		if (metaData) {
			isRendering.current = false;
		}
	}, [metaData]);

	/**
	 * Network request to fetch filters data
	 * and setting the api response in redux state
	 */
	useEffect(() => {
		let cancel = false;
		if (cancel) return;
		if (!filterData.genres || !filterData.services) {
			getFilters(`${ROOT_URL}${GET_FILTERS_URL}`, null, (response) => {
				const { data } = response;
				dispatch({ type: GET_FILTERS, payload: data });
				setTimeout(() => setIsLoading(false), 2000);
			});
		}
		return () => {
			cancel = true;
		};
	}, []);

	const handleHasMore = (response) => {
		if (
			response &&
			response.data &&
			response.data.length > 0 &&
			response.data.length === 20
		) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	useEffect(() => {
		let cancel = false;
		if (cancel) return;
		if (metaData && metaData.bucket_id) {
			if (onLoad === 'page' && offset > 0 && !filtersApplied) {
				getSubSectionData(
					`${ROOT_URL}${GET_VIDEOS_URL}`,
					{
						bucket_id: metaData.bucket_id,
						offset: offset,
						perPage: 20,
						content_type: contentType,
						orderBy: orderBy.sortBy,
						orderType: orderBy.orderBy,
						orderOn: 'bv',
						// orderOn: filters.length > 0 ? 'v' : 'bv',
					},
					(response) => {
						handleHasMore(response);
						fetchAllDeleteData(response?.data, true);
						dispatch({ type: GET_SUBSECTION_DATA, payload: response });
					},
				);
			} else if (onLoad === 'page' && offset > 0 && filtersApplied) {
				updateScreenDataBasedOnFilters(filters, movieCaseFilters);
			} else if (onLoad === 'section' && offset <= 0) {
				setIsLoading(true);
				resetSubSectionData(
					`${ROOT_URL}${GET_VIDEOS_URL}`,
					{
						bucket_id: metaData.bucket_id,
						offset: 0,
						perPage: 20,
						content_type: contentType,
						orderBy: orderBy.sortBy,
						orderType: orderBy.orderBy,
						orderOn: 'bv',
						// orderOn: filters.length > 0 ? 'v' : 'bv',
					},
					(response) => {
						setIsLoading(false);
						handleHasMore(response);
						dispatch({ type: RESET_SUBSECTION_DATA, payload: response });
					},
				);
			}
		} else {
			dispatch({
				type: RESET_SUBSECTION_DATA,
				payload: { data: [] },
			});
		}
		return () => {
			cancel = true;
		};
	}, [metaData, dispatch, onLoad, offset, contentType]);

	useEffect(() => {
		let cancel = false;
		if (cancel) return;
		if (selectedBucket && videoId) {
			dispatch(
				addVideoToBucket(
					`${ROOT_URL}${ADD_VIDEOS_URL}`,
					{
						video_ids: [videoId],
						bucket_id: selectedBucket?.bucket_id,
					},
					({ data, response }) => {
						if (data) {
							setToastError(false);
							setShowToastMessage(CONST.TOAST_ERROR_MESSAGE.SUCCESSFULLY_ADDED_TO_BUCKET);
							setShowToast(true);
						} else if (response) {
							const errorMessage = response?.data?.message;
							setShowToastMessage(errorMessage);
							setToastError(true);
							setShowToast(true);
						}
					},
				),
			);
		}
		return () => {
			cancel = true;
		};
	}, [videoId, selectedBucket, dispatch]);

	const openTagViewPopup = () => {
		setViewTagsPopup(!viewTagsPopup);
	};

	const closeTagViewPopup = () => {
		setViewTagsPopup(false);
	};

	const handleAddMoreTags = () => {
		setViewTagsPopup(false);
		handleEdit();
	};

	const toggleModal = () => {
		setIsOpen(!isOpen);
		setEditBucket(false);
	};
	const closeDeleteConfirmationModal = () => {
		setIsOpenDeleteComfirmation(false);
	};
	const handleAddButton = (value) => {
		if (!selectedBucket) {
			setToastError(true);
			setShowToastMessage(CONST.TOAST_ERROR_MESSAGE.SELECT_BUCKET_FIRST);
			setShowToast(true);
			return;
		}
		setVideoId((prev) => {
			if (prev === value) {
				setToastError(true);
				setShowToastMessage(CONST.TOAST_ERROR_MESSAGE.VIDEO_ALREADY_EXIST);
				setShowToast(true);
			}
			return value;
		});
	};
	const handleToastDismiss = () => {
		setShowToast(false);
	};
	const goToNextPage = () => {
		setOffset(carouselData.data.length);
	};
	const fetchMoreData = () => {
		setOnLoad('page');
		goToNextPage();
	};
	const newBucketCreated = (message, isError, show) => {
		setBucketListUpdateFlag(!bucketListUpdateFlag);
		setToastError(isError);
		setShowToastMessage(message);
		setShowToast(show);
	};

	const onChangeFilter = (e) => {
		const [type] = e.target.id.split('-');
		const copyOfFilters = [...filters];
		const filteredResults = copyOfFilters.filter(({ id }) => {
			return id === e.target.id;
		});
		if (filteredResults.length > 0 && !e.target.checked) {
			const index = copyOfFilters.indexOf(filteredResults[0]);
			e.target.setAttribute('aria-checked', false);
			copyOfFilters.splice(index, 1);
			setFilters(() => {
				return copyOfFilters;
			});
		} else if (filteredResults.length === 0) {
			const newFilter = {
				id: e.target.id,
				value: e.target.name || e.target.textContent,
				type: type === 'pills' ? 'genres' : 'providers',
			};
			e.target.setAttribute('aria-checked', true);
			copyOfFilters.push(newFilter);
			setFilters(() => {
				return copyOfFilters;
			});
		}
	};

	const onChangeDirectors = (e) => {
		setDirectors(e.target.value);
		setFilters([...filters]);
	};

	const onChangeMovieCast = (e) => {
		setMovieCast(e.target.value);
		setMovieCaseFilters([...movieCaseFilters]);
	};

	const onChangeSearchFilter = () => {
		const copyOfFilters = [...filters];
		if (isEmptyString(directors)) {
			new Set(directors.split(',')).forEach((value) => {
				value = value.trim();
				if (isEmptyString(value)) {
					const newFilter = {
						id: `${value}`,
						value,
						type: 'directors',
					};
					const filteredResults = copyOfFilters.filter(({ value: filterValue }) => {
						return value === filterValue;
					});
					if (filteredResults.length === 0) {
						copyOfFilters.push(newFilter);
					}
				}
			});
		}

		return new Promise((resolve) => {
			resolve(copyOfFilters);
		});
	};

	const onChangeSearchMovieCast = () => {
		const copyOfMovieCast = [...movieCaseFilters];
		if (isEmptyString(movieCast)) {
			new Set(movieCast.split(',')).forEach((value) => {
				value = value.trim();
				const newFilter = {
					id: `${value}`,
					value,
					type: 'movieCast',
				};
				const filteredResults = copyOfMovieCast.filter(({ value: filterValue }) => {
					return value === filterValue;
				});
				if (filteredResults.length === 0) {
					copyOfMovieCast.push(newFilter);
				}
			});
		}
		return new Promise((resolve) => {
			resolve(copyOfMovieCast);
		});
	};

	const removeFilter = (id, type) => {
		const copyOfFilters = [...filters];
		const filteredResult = copyOfFilters
			.filter(({ type: dataType }) => dataType === type)
			.find(({ id: filterId }) => {
				if (filterId.toString().trim() === id.toString().trim()) {
					return filterId;
				}
				return false;
			});
		let index;
		if (filteredResult) {
			index = copyOfFilters.indexOf(filteredResult);
			const checkbox = document.getElementById(id);
			if (checkbox) {
				checkbox.checked = false;
			}
			const pill = document.getElementById(id);
			if (pill) {
				pill.setAttribute('aria-checked', false);
			}
			if (searchRef.current) {
				const allDirectors = new Set();
				searchRef.current.value
					.split(',')
					.map((director) => allDirectors.add(director.trim()));
				const arrayOfUniqueDirectors = Array.from(allDirectors);
				const position = arrayOfUniqueDirectors.indexOf(id);
				if (position >= 0) {
					arrayOfUniqueDirectors.splice(position, 1);
					searchRef.current.value = arrayOfUniqueDirectors.toString();
				}
			}
			setOffset(0);
			copyOfFilters.splice(index, 1);
			setCopyOfFiltersForTags(copyOfFilters);
			setFilters(copyOfFilters);
			if ([...copyOfFilters, ...movieCaseFilters, ...sortByFilter].length > 0) {
				updateScreenDataBasedOnFilters(copyOfFilters, movieCaseFilters, null, true);
			} else {
				setIsLoading(true);
				resetSubSectionData(
					`${ROOT_URL}${GET_VIDEOS_URL}`,
					{
						bucket_id: metaData.bucket_id,
						offset: 0,
						perPage: 20,
						content_type: contentType,
						orderBy: orderBy.sortBy,
						orderType: orderBy.orderBy,
						orderOn: 'bv',
						// orderOn: filters.length > 0 ? 'v' : 'bv',
					},
					(response) => {
						setIsLoading(false);
						handleHasMore(response);
						dispatch({ type: RESET_SUBSECTION_DATA, payload: response });
					},
				);
			}
		}
	};

	const removeFilterFromMovieCast = (id, type) => {
		const copyOfFilters = [...movieCaseFilters];
		const filteredResults = copyOfFilters.filter(({ id: filterId, type: dataType }) => {
			return filterId === id && dataType === type;
		});
		let index;
		if (filteredResults.length > 0) {
			index = copyOfFilters.indexOf(filteredResults[0]);
			if (movieCastRef.current) {
				const allMovieCast = new Set();
				movieCastRef.current.value
					.split(',')
					.map((movieCast) => allMovieCast.add(movieCast.trim()));
				const arrayOfUniqueMovieCase = Array.from(allMovieCast);
				const movieCasePosition = arrayOfUniqueMovieCase.indexOf(id);
				if (movieCasePosition >= 0) {
					arrayOfUniqueMovieCase.splice(movieCasePosition, 1);
					movieCastRef.current.value = arrayOfUniqueMovieCase.toString();
				}
			}
			setOffset(0);
			copyOfFilters.splice(index, 1);
			setCopyOfMovieCastFiltersForTags(copyOfFilters);
			setMovieCaseFilters(copyOfFilters);
			if ([...copyOfFilters, ...filters, ...sortByFilter].length > 0) {
				updateScreenDataBasedOnFilters(filters, copyOfFilters, null, true);
			} else {
				setIsLoading(true);
				resetSubSectionData(
					`${ROOT_URL}${GET_VIDEOS_URL}`,
					{
						bucket_id: metaData.bucket_id,
						offset: 0,
						perPage: 20,
						content_type: contentType,
						orderBy: orderBy.sortBy,
						orderType: orderBy.orderBy,
						orderOn: 'bv',
						// orderOn: filters.length > 0 ? 'v' : 'bv',
					},
					(response) => {
						setIsLoading(false);
						handleHasMore(response);
						dispatch({ type: RESET_SUBSECTION_DATA, payload: response });
					},
				);
			}
		}
	};

	const removeSortByFilter = () => {
		setOffset(0);
		setSortByFilter([]);
		setSortBy(null);
		setOrderBy({ sortBy: 'video_sequence', orderBy: 'asc' });
		const order = { sortBy: 'video_sequence', orderBy: 'asc' };
		updateScreenDataBasedOnFilters(filters, movieCaseFilters, order, true);
	};

	const selectToDelete = () => {
		if (!enableSelection) {
			setEnableSelection(true);
		} else if (selectedCards.length === 0) {
			setShowToastMessage(CONST.TOAST_ERROR_MESSAGE.SELECT_MOVIE_TO_DELETE);
			setToastError(true);
			setShowToast(true);
		} else if (selectedCards.length > 0) {
			setIsOpenDeleteComfirmation(true);
		}
	};

	const selectToDeleteAll = (e) => {
		if (e.target.checked) {
			setDeleteAll(true);
			setUncheckedOnSelectOne(true);
		} else {
			setDeleteAll(false);
			setUncheckedOnSelectOne(false);
			setSelectedCards([]);
		}
	};

	/** network request
	 * fetch new data according to the user selected filters
	 */
	const updateScreenDataBasedOnFilters = (
		movieFilters,
		movieCastFilters,
		order,
		sortByOffset,
	) => {
		const { genres, providers, directors, casts } = transformValuesForParams([
			...movieFilters,
			...movieCastFilters,
		]);
		let apiRequestParams = {
			perPage: 20,
		};
		if (genres) {
			apiRequestParams = { ...apiRequestParams, genres };
		}
		if (providers) {
			apiRequestParams = { ...apiRequestParams, providers };
		}
		if (directors) {
			apiRequestParams = { ...apiRequestParams, directors };
		}
		if (casts) {
			apiRequestParams = { ...apiRequestParams, casts };
		}
		if (offset === 0 || sortByOffset) {
			setIsLoading(true);
			resetSubSectionData(
				`${ROOT_URL}${GET_VIDEOS_URL}`,
				{
					bucket_id: metaData && metaData.bucket_id,
					content_type: contentType,
					orderBy: order ? order.sortBy : orderBy.sortBy,
					orderType: order ? order.orderBy : orderBy.orderBy,
					orderOn: 'bv',
					// orderOn: filters.length > 0 ? 'v' : 'bv',
					offset: 0,
					...apiRequestParams,
				},
				(updatedData) => {
					dispatch({
						type: RESET_SUBSECTION_DATA,
						payload: updatedData,
					});
					handleHasMore(updatedData);
					setIsLoading(false);
				},
			);
		} else {
			getSubSectionData(
				`${ROOT_URL}${GET_VIDEOS_URL}`,
				{
					bucket_id: metaData && metaData.bucket_id,
					offset: offset,
					content_type: contentType,
					orderBy: order ? order.sortBy : orderBy.sortBy,
					orderType: order ? order.orderBy : orderBy.orderBy,
					orderOn: 'bv',
					// orderOn: filters.length > 0 ? 'v' : 'bv',
					...apiRequestParams,
				},
				(response) => {
					fetchAllDeleteData(response?.data, true);
					dispatch({ type: GET_SUBSECTION_DATA, payload: response });
					handleHasMore(response);
					setIsLoading(false);
					isRendering.current = false;
				},
			);
		}
	};

	const applyFilters = () => {
		if (scrollRef && scrollRef.current) {
			scrollRef.current.scrollTo({ top: 0, behaviour: 'smooth' });
		}
		onChangeSearchFilter()
			.then((movieFilters) => {
				return new Promise((resolve) => {
					resolve(movieFilters);
				});
			})
			.then((movieFilters) => {
				onChangeSearchMovieCast().then((movieCastFilters) => {
					setOffset(0);
					setFilters([...movieFilters]);
					setMovieCaseFilters([...movieCastFilters]);
					if (sortBy !== null) {
						setSortByFilter([{ id: 'dropdown', value: sortBy.name, type: 'dropdown' }]);
					}
					setCopyOfFiltersForTags([...movieFilters]);
					setCopyOfMovieCastFiltersForTags([...movieCastFilters]);
					if ([...movieCaseFilters, ...movieFilters, ...sortByFilter].length > 0) {
						setFiltersApply(true);
					} else {
						setFiltersApply(false);
					}
					updateScreenDataBasedOnFilters(movieFilters, movieCastFilters, null, true);
				});
			});
		setDirectors('');
		setMovieCast('');
	};

	const clearAllFilters = () => {
		setFilters([]);
		setMovieCaseFilters([]);
		setCopyOfFiltersForTags([]);
		setOffset(0);
		setOrderBy({ sortBy: 'video_sequence', orderBy: 'asc' });
		setCopyOfMovieCastFiltersForTags([]);
		setIsLoading(true);
		setSortBy(null);
		setSortByFilter([]);
		resetSubSectionData(
			`${ROOT_URL}${GET_VIDEOS_URL}`,
			{
				bucket_id: metaData.bucket_id,
				offset: 0,
				perPage: 20,
				content_type: contentType,
				orderBy: 'video_sequence',
				orderType: 'asc',
				orderOn: 'bv',
				// orderOn: filters.length > 0 ? 'v' : 'bv',
			},
			(updatedData) => {
				dispatch({
					type: RESET_SUBSECTION_DATA,
					payload: updatedData,
				});
				setIsLoading(false);
				handleHasMore(updatedData);
			},
		);
		const checkboxes = document.querySelectorAll('.checkbox');
		checkboxes.forEach((checkbox) => {
			checkbox.checked = false;
		});
		const pills = document.querySelectorAll('.pill');
		pills.forEach((checkbox) => {
			checkbox.setAttribute('aria-checked', false);
		});
		if (searchRef.current) {
			searchRef.current.value = '';
		}
		if (movieCastRef.current) {
			movieCastRef.current.value = '';
		}
		setIsFilterVisible(false);
		setFiltersApply(false);
	};

	const toggleSearchInput = (val) => {
		setOpenServicesSearch(val);
	};

	const unSelectAllSelectedItems = () => {
		if (selectedCards.length > 0) {
			selectedCards.forEach((card) => {
				const cardElement = document.getElementById(card.id);
				const cardContainer = document.getElementById(`card-container-${card.id}`);
				if (cardElement) {
					cardElement.checked = false;
				}
				if (cardContainer) {
					cardContainer.style.border = 'none';
				}
			});
		}
		setSelectedCards([]);
		setEnableSelection(false);
		setDeleteAll(false);
		setUncheckedOnSelectOne(false);
	};

	const onSelectingCards = (card) => {
		const copyOfSelectedCards = [...selectedCards];
		if (card) {
			const filteredCards = copyOfSelectedCards.filter((cardItem) => {
				return cardItem.id === card.id;
			});
			if (filteredCards.length === 0) {
				setSelectedCards([...copyOfSelectedCards, card]);
			} else {
				const index = copyOfSelectedCards.indexOf(filteredCards[0]);
				if (index >= 0) {
					copyOfSelectedCards.splice(index, 1);
					setSelectedCards(copyOfSelectedCards);
				}
			}
		}
	};

	const handleScrollToTop = () => {
		setTimeout(() => {
			const element = document.querySelector('#content-block');
			if (element) {
				element.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	const onDeleteVideo = () => {
		setOnDeleteLoading(true);
		const videoIds = selectedCards.map((card) => card.id);
		deleteVideoFromBucket(
			`${ROOT_URL}${DELETE_VIDEOS_URL}`,
			{
				bucket_id: metaData?.bucket_id,
				video_ids: videoIds,
			},
			(response) => {
				if (response.status && response.message) {
					closeDeleteConfirmationModal();
					setOnDeleteLoading(false);
					if (viewType === CONST.VIEW_TYPE.grid) {
						videoIds.forEach((video) => {
							const cardElement = document.getElementById(video);
							const element = document.getElementById(`card-wrapper-${video}`);
							element.style.visibility = 'hidden';
							element.style.transform = 'translateY(-50px)';
							element.style.opacity = 0;
							element.style.border = 'none';
							element.style.transition =
								'visibility 0.5s linear, opacity 0.5s linear, transform 0.5s linear, border 0.5s linear';
							if (cardElement) {
								cardElement.checked = false;
							}
						});
					}
					setSelectedCards([]);
					setDeleteAll(false);
					setUncheckedOnSelectOne(false);
					if (filtersApplied) {
						applyFilters();
					} else {
						resetSubSectionData(
							`${ROOT_URL}${GET_VIDEOS_URL}`,
							{
								bucket_id: metaData.bucket_id,
								offset: 0,
								perPage: 20,
								content_type: contentType,
								orderBy: orderBy.sortBy,
								orderType: orderBy.orderBy,
								orderOn: 'bv',
								// orderOn: filters.length > 0 ? 'v' : 'bv',
							},
							(updatedData) => {
								setIsLoading(false);
								handleHasMore(updatedData);
								dispatch({
									type: RESET_SUBSECTION_DATA,
									payload: updatedData,
								});
							},
						);
					}
					setTimeout(() => {
						setToastError(false);
						setShowToastMessage(response.message);
						setShowToast(true);
					}, 500);
				}
			},
		);
	};

	const handleEdit = () => {
		setEditBucket(true);
		setIsOpen(true);
	};

	const handleSortByFilter = (option) => {
		setSortBy(option);
		const optionId = option.option_id.split('-');
		const sortByOrderType = optionId[1];
		const sortBy = optionId[0];
		setOrderBy({ sortBy, orderBy: sortByOrderType });
	};

	const renderEmptyState = () => {
		return (
			<div>
				{isLoading && (
					<div className="card-grid">
						{[...Array(20)].map((i, index) => {
							return <Card key={index} loader={true} />;
						})}
					</div>
				)}
				{!isLoading && (
					<div className="empty-state-container empty-image">
						<img src={EmptyImage} alt="no results found" />
						<h3 className="no-filter-found">
							{CONST.EMPTY_STATE_MESSAGE.NO_FILTER_RESULT}
						</h3>
						<h5 className="please-change-filter">
							{CONST.EMPTY_STATE_MESSAGE.PLEASE_CHANGE_FILTER}
						</h5>
						<h5 className="please-change-filter">
							{CONST.EMPTY_STATE_MESSAGE.CRITERIA_TO_GET_RESULTS}
						</h5>
					</div>
				)}
			</div>
		);
	};

	useEffect(() => {
		if (
			directors.length > 0 ||
			movieCast.length > 0 ||
			sortBy ||
			filters.length > 0 ||
			movieCaseFilters.length > 0 ||
			[...copyOfFiltersForTags, ...copyOfMovieCastFiltersForTags].length > 0
		) {
			setIsFilterVisible(true);
		} else {
			setIsFilterVisible(false);
		}
	}, [
		directors,
		movieCast,
		setIsFilterVisible,
		filters,
		movieCaseFilters,
		copyOfFiltersForTags,
		copyOfMovieCastFiltersForTags,
		sortBy,
	]);

	useEffect(() => {
		if ([...copyOfFiltersForTags, ...copyOfMovieCastFiltersForTags].length > 0) {
			setFiltersApply(true);
		} else {
			setFiltersApply(false);
		}
	}, [setFiltersApply, copyOfFiltersForTags, copyOfMovieCastFiltersForTags]);

	const showVideoProgress = () => {
		setIsLoading(true);
		setSearchReportOffset(0);
		setVideoProgress(true);
	};

	const handleClick = (id, type) => {
		setShowVideoDetailPopup(true);
		setPopupVideoId(id);
		setContentTypeVideo(type);
	};

	const closeVideoDetailsPopup = () => {
		setShowVideoDetailPopup(false);
		setPopupVideoId(null);
		setContentTypeVideo(null);
	};

	const closeProgress = () => {
		if (offset > -1) {
			setOffset(-1);
		} else {
			setOffset((val) => val - 1);
		}
		setOnLoad('section');
		setSearchReportData([]);
		setVideoProgress(false);
	};

	useEffect(() => {
		if (videoProgress) {
			getSearchReport();
		}
	}, [videoProgress]);

	const changeViewType = (viewType) => {
		setViewType(viewType);
	};

	const getSearchReport = async () => {
		const form = {
			offset: searchReportOffset,
			perPage: 20,
			content_type: contentType || 'all',
			bucket_id: metaData?.bucket_id,
			orderType: 'desc',
			orderBy: 'updated_at',
		};
		try {
			const res = await getCommonMethod(`${ROOT_URL}${SEARCH_REPORT}`, form);
			if (res) {
				if (searchReportOffset > 0) {
					handleHasMoreReport(res);
					setSearchReportData([...searchReportData, ...res?.data]);
				} else {
					handleHasMoreReport(res);
					setSearchReportData(res?.data);
				}
			}
		} catch (error) {
			setShowToastMessage('Something Went Wrong');
			setToastError(true);
			setShowToast(true);
			setVideoProgress(false);
		}
		setIsLoading(false);
	};

	const fetchSearchData = () => {
		setSearchReportOffset(searchReportData.length);
	};

	useEffect(() => {
		if (searchReportOffset) {
			getSearchReport();
		}
	}, [searchReportOffset]);

	return (
		<div className="popular-right-container">
			<div className="header-parent-block">
				<div className="header-block">
					<div>
						<h1 className="heading">
							{/* {metaData?.name} */}
							{isRendering.current ? <ButtonLoader /> : metaData?.name}
						</h1>
						{bucketTags?.length > 0 && (
							<p className="view-tags">
								<span onClick={openTagViewPopup}>View Tags</span>
							</p>
						)}
					</div>
					<div className="dashboard-button-block">
						<IconTextButton
							buttonText="+ Create Bucket"
							handleClick={() => toggleModal()}
							disabled={enableSelection}
						/>
					</div>
				</div>
				<div className="search-box-block">
					<div />
					<div className="aside-buttons">
						<CustomSelectBox
							fromScreen="dashboard"
							handleToastDismiss={handleToastDismiss}
							updateFlag={bucketListUpdateFlag}
							idFieldName="bucket_id"
							enableDropdown={!enableSelection && !videoProgress}
							searchEnable={true}
						/>

						<Tooltip
							overlay="Edit Bucket"
							placement="bottom"
							overlayInnerStyle={overlayStyle}
							align={{
								offset: [0, -2],
							}}>
							<div>
								<EditButton
									disabled={enableSelection || videoProgress}
									handleClick={handleEdit}
								/>
							</div>
						</Tooltip>
						<Button
							onClick={selectToDelete}
							imageInfo={{
								src: DeleteBucket,
								label: 'select to edit',
							}}
							label={enableSelection ? 'Delete Videos' : 'Select To Delete'}
							className={`${enableSelection ? 'button-pink' : 'button-black'}`}
							labelClassName={`${enableSelection ? 'label-white' : 'label-grey'}`}
							disabled={videoProgress}
						/>
						{enableSelection && (
							<img
								src={CloseIcon}
								alt="close"
								onClick={unSelectAllSelectedItems}
								className="unselect-all-cards-icon"
							/>
						)}
						<Tooltip
							overlay="List View"
							placement="bottom"
							overlayInnerStyle={overlayStyle}
							align={{
								offset: [0, -2],
							}}>
							<img
								src={viewType === CONST.VIEW_TYPE.list ? ListActive : ListInactive}
								alt="List View"
								className="change-layout-icon"
								onClick={() => changeViewType(CONST.VIEW_TYPE.list)}
							/>
						</Tooltip>
						<Tooltip
							overlay="Grid View"
							placement="bottom"
							overlayInnerStyle={overlayStyle}
							align={{
								offset: [0, -2],
							}}>
							<img
								src={viewType === CONST.VIEW_TYPE.grid ? GridActive : GridInactive}
								alt="Grid View"
								className="change-layout-icon no-margin"
								onClick={() => changeViewType(CONST.VIEW_TYPE.grid)}
							/>
						</Tooltip>
					</div>
				</div>
			</div>
			{viewType === CONST.VIEW_TYPE.grid && (
				<div className="content-block">
					<FilterContainer
						onChangeFilter={onChangeFilter}
						onChangeSearchFilter={onChangeSearchFilter}
						applyFilters={applyFilters}
						filtersApplied={filtersApplied}
						searchRef={searchRef}
						movieCastRef={movieCastRef}
						isFilterVisible={isFilterVisible}
						onChangeMovieCast={onChangeMovieCast}
						clearAllFilters={clearAllFilters}
						onChangeDirectors={onChangeDirectors}
						openServicesSearch={openServicesSearch}
						toggleSearchInput={toggleSearchInput}
						handleFilter={handleSortByFilter}
						sortBy={sortBy}
						disabled={videoProgress}
						shuffle={false}
					/>
					{carouselData && carouselData.data && (
						<div id="content-block" ref={scrollRef}>
							<div className="carousel-dd">
								{videoProgress && (
									<div className="back-div" onClick={closeProgress}>
										<img src={BackIcon} alt="back-icon" />
										<p className="back-mssg">Back to Default View</p>
									</div>
								)}
								{enableSelection && carouselData?.data?.length && !videoProgress && (
									<div className="selection-button extra-margin">
										<input
											id={1}
											type="checkbox"
											onChange={selectToDeleteAll}
											alt="selection"
											checked={deleteAll && unCheckedOnSelectOne}
										/>
										<span
											className={`${
												deleteAll && unCheckedOnSelectOne
													? 'span-delete-selected'
													: 'span-delete-unselected'
											}`}>
											Select All
										</span>
									</div>
								)}
								{!videoProgress && (
									<FilterTags
										filters={[
											...copyOfFiltersForTags,
											...copyOfMovieCastFiltersForTags,
											...sortByFilter,
										]}
										removeFilter={removeFilter}
										removeFilterFromMovieCast={removeFilterFromMovieCast}
										removeSortByFilter={removeSortByFilter}
									/>
								)}
							</div>
							<InfiniteScroll
								dataLength={
									videoProgress ? searchReportData.length : carouselData.data.length
								}
								next={videoProgress ? fetchSearchData : fetchMoreData}
								hasMore={videoProgress ? hasMoreSearchReportData : hasMore}
								loader={<Loader loadMore={true} />}
								scrollableTarget="content-block">
								<div className="infinite-scroll-data">
									<div className="carousel-data-container">
										{!isLoading && carouselData?.data?.length && !videoProgress ? (
											<div className="card-grid">
												{carouselData.data.map((movieItem) => {
													return (
														<Card
															key={movieItem.video_id}
															id={movieItem.video_id}
															tileDetails={movieItem}
															handleAddButton={handleAddButton}
															enableSelection={enableSelection}
															onSelecting={onSelectingCards}
															fromRedirectionScreen={fromRedirectionScreen}
															deleteAll={deleteAll}
															setUncheckedOnSelectOne={setUncheckedOnSelectOne}
															selectedCards={selectedCards}
															carouselData={carouselData?.data}
															handleClick={() =>
																handleClick(movieItem?.video_id, movieItem?.content_type)
															}
															setDeleteAll={setDeleteAll}
														/>
													);
												})}
											</div>
										) : !isLoading && searchReportData?.length > 0 && videoProgress ? (
											<div className="card-grid">
												{searchReportData.map((movieItem) => {
													return (
														<Card
															key={movieItem.video_id}
															id={movieItem.reelgood_id}
															tileDetails={movieItem}
															handleAddButton={handleAddButton}
															enableSelection={enableSelection}
															onSelecting={onSelectingCards}
															fromRedirectionScreen={fromRedirectionScreen}
															fromProgress={videoProgress}
															handleClick={() =>
																handleClick(movieItem?.video_id, movieItem?.content_type)
															}
															setDeleteAll={setDeleteAll}
														/>
													);
												})}
											</div>
										) : (
											renderEmptyState()
										)}
									</div>
								</div>
							</InfiniteScroll>
						</div>
					)}
				</div>
			)}
			{viewType === CONST.VIEW_TYPE.list && (
				<div className="content-table-div">
					<FilterContainer
						onChangeFilter={onChangeFilter}
						onChangeSearchFilter={onChangeSearchFilter}
						applyFilters={applyFilters}
						filtersApplied={filtersApplied}
						searchRef={searchRef}
						movieCastRef={movieCastRef}
						isFilterVisible={isFilterVisible}
						onChangeMovieCast={onChangeMovieCast}
						clearAllFilters={clearAllFilters}
						onChangeDirectors={onChangeDirectors}
						openServicesSearch={openServicesSearch}
						toggleSearchInput={toggleSearchInput}
						handleFilter={handleSortByFilter}
						sortBy={sortBy}
						disabled={videoProgress || shuffle}
						shuffle={true}
					/>
					<div id="content-table-div" ref={scrollRef}>
						<div className="carousel-dd">
							{!filtersApplied &&
								!bucketDetails?.is_predefined &&
								!bucketDetails?.is_user_type && (
									<div
										className={`${
											carouselData?.data?.length ? 'shuffle-mode' : 'shuffle-mode-none'
										} `}>
										<p className="ordinary">
											{shuffle
												? CONST.SHUFFLE_CONST.reorder_done
												: CONST.SHUFFLE_CONST.reorder_message}
										</p>
										<p className="highlighted" onClick={handleShuffle}>
											&nbsp;
											{shuffle
												? CONST.SHUFFLE_CONST.click_back
												: CONST.SHUFFLE_CONST.click_shuffle}
										</p>
									</div>
								)}
							{!videoProgress && (
								<FilterTags
									filters={[
										...copyOfFiltersForTags,
										...copyOfMovieCastFiltersForTags,
										...sortByFilter,
									]}
									removeFilter={removeFilter}
									removeFilterFromMovieCast={removeFilterFromMovieCast}
									removeSortByFilter={removeSortByFilter}
								/>
							)}
						</div>
						{carouselData.data.length ? (
							<TableContainer
								tableData={carouselData.data}
								shuffle={shuffle}
								fetchMoreData={fetchMoreData}
								hasMore={hasMore}
								handleAddButton={handleAddButton}
								metaData={metaData}
								setToastError={setToastError}
								setShowToastMessage={setShowToastMessage}
								setShowToast={setShowToast}
								deleteAll={deleteAll}
								selectedCards={selectedCards}
								setSelectedCards={setSelectedCards}
								enableSelection={enableSelection}
								onSelecting={onSelectingCards}
								fromRedirectionScreen={fromRedirectionScreen}
								setUncheckedOnSelectOne={setUncheckedOnSelectOne}
								selectToDeleteAll={selectToDeleteAll}
								unCheckedOnSelectOne={unCheckedOnSelectOne}
								setDeleteAll={setDeleteAll}
							/>
						) : (
							renderEmptyState()
						)}
					</div>
				</div>
			)}
			<EditBucketModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				showToast={newBucketCreated}
				isEdit={editBucket}
				bucketId={metaData?.bucket_id}
				callback={updateResults}
			/>
			<Popup
				className="popup-content-container"
				open={isOpenDeleteComfirmation}
				closeOnDocumentClick
				onClose={closeDeleteConfirmationModal}
				contentStyle={popupContentStyleBucketDetails}>
				<DeleteConfirmation
					onClose={closeDeleteConfirmationModal}
					onDelete={onDeleteVideo}
					loading={onDeleteLoading}
					fromBucketListing={true}
					message={`Are you sure you want to delete ${
						selectedCards.length > 1 ? 'all' : ''
					} selected  ${selectedCards.length > 1 ? 'videos' : 'video'}? `}
				/>
			</Popup>
			<Popup
				className="popup-content-container"
				open={viewTagsPopup}
				closeOnDocumentClick
				onClose={closeTagViewPopup}
				contentStyle={tagsPopupStyle}>
				<TagsPopup
					data={bucketTags}
					closePopup={closeTagViewPopup}
					bucketName={metaData?.name}
					handleAddMoreTags={handleAddMoreTags}
				/>
			</Popup>
			<Popup
				className="popup-video-details"
				open={showVideoDetailPopup}
				closeOnDocumentClick
				onClose={closeVideoDetailsPopup}
				contentStyle={videoDetailsPopupStyle}>
				<VideoDetailPopup
					videoId={popupVideoId}
					closePopup={closeVideoDetailsPopup}
					contentType={contentTypeVideo}
				/>
			</Popup>
			{showToast ? (
				<Toast
					message={showToastMessage}
					onDismiss={handleToastDismiss}
					isError={toastError}
				/>
			) : null}
			{offset > 20 && !isOpen && viewType === CONST.VIEW_TYPE.grid && (
				<ScrollToTop handleScrollToTop={handleScrollToTop} />
			)}
		</div>
	);
};

export default BucketDetails;
