export const PUBLISH_MESSAGE = `Are you sure you want to publish ${process.env.REACT_APP_QEWD_DIGEST_TITLE}, if yes then select the frequency of it?`;

export const FREQUENCY = {
	// Daily: 1,
	// Weekly: 7,
	// '15 Days': 15,
	// Monthly: 30,
	Manual: 0,
	Auto: 7,
};

export const FREQUENCY_MAPPER = {
	// 1: 'Daily',
	// 7: 'Weekly',
	// 15: '15 Days',
	// 30: 'Monthly',
	0: 'Manual',
	7: 'Auto',
};

// export const FREQUENCY_LABEL = ['Daily', 'Weekly', '15 Days', 'Monthly'];
export const FREQUENCY_LABEL = ['Manual', 'Auto'];

export const CANCEL = 'Cancel';
export const PUBLISH = 'Publish';
